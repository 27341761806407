const InlineHtmlBlock = ({ config }) => {
  const { value, label = '' } = config;
  return (
    <div>
      {label}
      <div
        className="prose m-4 max-w-none prose-h1:text-3xl prose-h1:font-bold"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
};

export default InlineHtmlBlock;
