import classnames from 'classnames';
import { Tag } from './forms';

function TagList({ value, deleteTag, tagRef, className }) {
  return Boolean(value?.length) ? (
    <div
      className={classnames(
        className,
        'flex flex-wrap gap-2 border border-t-0 border-smd-gray-light p-2'
      )}
    >
      {value.map((item) => (
        <Tag key={item} tag={item} onDelete={deleteTag} ref={tagRef} />
      ))}
    </div>
  ) : (
    <></>
  );
}

export default TagList;
