import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronRight } from 'components/icons';
import { FocalPointBackground } from 'components/media';
import AspectMin from 'components/Aspect/AspectMin';

function Wrapper({ hasBackground, className, onClick, children, ...props }) {
  return hasBackground ? (
    <FocalPointBackground
      className={classNames('relative', className)}
      {...props}
    >
      {children}
    </FocalPointBackground>
  ) : (
    <div onClick={onClick} className={classNames('relative', className)}>
      {children}
    </div>
  );
}

function PopOverCard({ title, content, imageSrc, hasBackground, className }) {
  const { t } = useTranslation();
  return (
    <Wrapper
      hasBackground={hasBackground}
      url={imageSrc}
      alt={title}
      className={className}
    >
      <AspectMin ratio={16 / 9}>
        <div className="relative flex h-full flex-col justify-center break-words bg-black bg-opacity-30 py-smd-xl px-smd-xl text-white">
          <div className="space-y-smd-xxs">
            <p className="overflow-hidden text-smd-h1">{title}</p>
          </div>
          <div
            className="overflow-hidden py-smd-base text-smd-h4"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="flex items-center text-sm font-semibold uppercase">
            {t('common.read-more')}
            <ChevronRight className="ml-1 h-3 w-3" strokeWidth="4" />
          </div>
        </div>
      </AspectMin>
    </Wrapper>
  );
}

PopOverCard.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};

export default PopOverCard;
