import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import libraryArticlePropTypes from 'library/propTypes/article';
import LibraryAsset from './LibraryAsset';

export default function LibraryArticle({ config }) {
  const { t } = useTranslation();
  const { name, text, asset, credit } = config;
  return (
    <article
      className={classnames('mx-4 space-y-6 text-smd-main', config.className)}
    >
      {name && <h1 className="text-3xl font-bold">{name}</h1>}
      {asset && <LibraryAsset config={asset} />}
      <div
        className="prose max-w-none text-smd-main prose-a:inline-block"
        dangerouslySetInnerHTML={{ __html: text.html ? text.html : text }}
      />
      {credit && (
        <p className="text-sm italic text-smd-secondary">
          {t('common.source', { source: credit.name })}
        </p>
      )}
    </article>
  );
}

LibraryArticle.propTypes = {
  config: libraryArticlePropTypes.shape,
};
