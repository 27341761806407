import PropTypes from 'prop-types';
import { useMetaConfig } from './hooks';
import SEOHead from './SEOHead';

function SEOMeta({ values = {} }) {
  return <SEOHead config={useMetaConfig(values)} />;
}

SEOMeta.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default SEOMeta;
