import { useCallback, useMemo } from 'react';
import useLibraryAssetState from './useLibraryAssetState';

const FETCH_NEXT_PAGE_THRESHOLD = 5;

function useLibraryAssetRoutedModal({
  assets,
  fetchMoreAssets,
  haseMoreAssets = false,
  isFetching = false,
} = {}) {
  const { currentIndex, setNewState, updateState } = useLibraryAssetState();

  const assetsList = useMemo(() => assets || [], [assets]);
  const currentStateAssetIsAtTheEndOfTheStatedAssets =
    currentIndex + FETCH_NEXT_PAGE_THRESHOLD >= assetsList.length;

  const needToFetchMoreAssets =
    assetsList &&
    haseMoreAssets &&
    currentStateAssetIsAtTheEndOfTheStatedAssets &&
    !isFetching;

  if (needToFetchMoreAssets) {
    fetchMoreAssets?.().then(({ data: assets }) => {
      if (assets.pages) {
        assets = assets.pages.reduce(
          (prev, curr) => [...prev, ...(curr?.data || [])].filter((x) => x?.id),
          []
        );
      }
      updateState({ assets });
    });
  }

  const openLibraryAssetModal = useCallback(
    (asset) => {
      setNewState({ asset, list: assetsList });
    },
    [assetsList, setNewState]
  );

  return { openLibraryAssetModal };
}

export default useLibraryAssetRoutedModal;
