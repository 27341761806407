import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useStoryLastLocation } from 'library/hooks';
import { LibraryTopicCard } from 'library/components/topic';
import LibraryList from 'library/components/LibraryList';
import { slugifyObject } from 'utils/slug';

function LibraryTopicModule({ stories, filters }) {
  const { t } = useTranslation();
  const { setStoryLastLocation } = useStoryLastLocation();

  return (
    <LibraryList
      list={stories}
      renderListItem={(item) => {
        const storyUrl = `/story/${encodeURIComponent(
          slugifyObject(item.id, item.name)
        )}`;
        const story = {
          ...item,
          thumbnail: item?.featuredAsset?.thumbnail,
          focalPoint: item?.featuredAsset?.focalPoint,
          synonyms: item?.datetime
            ? [
                `${t('library.story-of-the-day')}: ${format(
                  new Date(item?.datetime),
                  'MMMM d, yyyy'
                )}`,
              ]
            : [],
        };
        return (
          <Link
            to={storyUrl}
            className="smd-focus-visible-primary block rounded-sm"
            onClick={() =>
              setStoryLastLocation({
                args: { state: { storyTimeFiltersState: filters } },
              })
            }
          >
            <LibraryTopicCard topic={story} />
          </Link>
        );
      }}
    />
  );
}

export default LibraryTopicModule;
