import { useTranslation } from 'react-i18next';

import { useRecentJournals } from 'journals/hooks';

import { Tag } from 'components';
import { CategoryView } from 'journals/history/components';

const MAX_HJ_TO_RENDER = 12;

function RecentJournalsSection({ onSelect, onAddTo, wrapperClassName, api }) {
  const { t } = useTranslation();

  const { recentJournalsData } = useRecentJournals(api);

  return (
    Boolean(recentJournalsData?.length) && (
      <CategoryView
        category={
          <div className="flex items-center">
            <Tag className="mr-2">{t('common.new')}</Tag>
            {t('journals.history.recently-added')}
          </div>
        }
        journals={recentJournalsData}
        onSelect={onSelect}
        onAddTo={onAddTo}
        className={wrapperClassName}
        journalsToRenderCount={MAX_HJ_TO_RENDER}
      />
    )
  );
}

export default RecentJournalsSection;
