import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks';

const arrowPositionClassNames = {
  left: '-right-4 border-l-smd-accent-tooltip',
  bottom: '-top-4 border-b-smd-accent-tooltip',
  right: '-left-4 border-r-smd-accent-tooltip',
};

function Tooltip({
  visible,
  offset,
  placement,
  delay,
  content,
  children,
  className,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [delayedIsVisible, setDelayedIsVisible] = useState(isVisible);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const debouncedIsVisible = useDebounce(delayedIsVisible, delay);

  useEffect(() => {
    setIsVisible(debouncedIsVisible);
  }, [debouncedIsVisible]);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  });

  const arrowPosition =
    arrowPositionClassNames[placement] || arrowPositionClassNames.bottom;

  const triggerElementProps = {
    //TODO: add more accessibility (keyboard focus, etc.)
    onPointerEnter: () => setDelayedIsVisible(true),
    onPointerLeave: () => {
      setDelayedIsVisible(false);
      setIsVisible(false);
    },
    ref: setReferenceElement,
  };

  return (
    <>
      {children?.(triggerElementProps, { isVisible, setIsVisible })}
      {isVisible &&
        createPortal(
          <div
            ref={setPopperElement}
            className={classnames(
              'max-w-xs select-none rounded-md bg-smd-accent-tooltip py-3 px-5 text-sm font-semibold text-smd-accent',
              className
            )}
            style={{ ...styles.popper, zIndex: 10 }}
            {...attributes.popper}
          >
            {content}
            <div
              ref={setArrowElement}
              style={styles.arrow}
              className={classnames(
                'border-10 border-transparent',
                arrowPosition
              )}
            />
          </div>,
          document.querySelector('#modal-root')
        )}
    </>
  );
}

Tooltip.propTypes = {
  visible: PropTypes.bool,
  offset: PropTypes.arrayOf(PropTypes.number), //[x,y]
  delay: PropTypes.number,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
};

Tooltip.defaultProps = {
  offset: [0, 15],
  delay: 0,
  placement: 'bottom',
};

export default Tooltip;
