import { Tooltip } from 'components';
import { useTranslation } from 'react-i18next';

function TabTooltip({ tKey, children }) {
  const { t } = useTranslation();
  return (
    <Tooltip content={t(`hints.explore.${tKey}`)} offset={[0, 20]} delay={500}>
      {(props) => <span {...props}>{children}</span>}
    </Tooltip>
  );
}

export default TabTooltip;
