export function parseJournals(journals) {
  return journals?.map((journal) => {
    const featuredAssetHeroImage = journal?.featuredAsset
      ? {
          thumbnailUrl: journal.featuredAsset?.thumbnail,
          url: journal.featuredAsset?.thumbnail,
          alt: journal.featuredAsset?.alt,
          focalPoint: journal.featuredAsset?.focalPoint,
          images: journal.featuredAsset?.images,
        }
      : {};

    const journalHeroImage = journal?.heroes ?? [
      { thumbnailUrl: journal?.thumbnail, url: journal?.thumbnail },
    ];

    const heroes = featuredAssetHeroImage?.thumbnailUrl
      ? [featuredAssetHeroImage]
      : journalHeroImage;

    return {
      ...journal,
      journalKey: journal?.key,
      preamble: {
        title: journal?.name,
        about: journal?.description,
        logos: journal?.logos?.map((logo) => ({ url: logo })),
        heroes,
      },
    };
  });
}
