import { useTranslation } from 'react-i18next';
import { Pencil } from 'components/icons';

function Edit() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-2">
      <Pencil className="h-4 w-4 stroke-2" />
      <span>{t('common.edit')}</span>
    </div>
  );
}

export default Edit;
