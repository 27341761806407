import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dropLast, last } from 'ramda';

export default function useStoreLastLocation({
  key,
  defaultOrigin = '/journeys',
  onEvent = () => {},
  locationProvider = useLocation,
}) {
  const location = locationProvider();

  useEffect(() => {
    try {
      JSON.parse(localStorage.getItem(key));
    } catch (error) {
      localStorage.setItem(key, JSON.stringify([{ pathname: defaultOrigin }]));
    }
  }, [key, defaultOrigin]);

  const origins = JSON.parse(localStorage.getItem(key)) ?? [
    { pathname: defaultOrigin },
  ];
  const setOrigins = (v) => localStorage.setItem(key, JSON.stringify(v));

  const lastLocation = last(origins) ?? { pathname: defaultOrigin };

  const methods = {
    push: ({ args = {}, eventArgs } = {}) => {
      setOrigins([...origins, { ...location, ...args }]);
      onEvent?.({ method: 'push', eventArgs, lastLocation });
    },
    set: ({ args = {}, eventArgs } = {}) => {
      setOrigins([{ ...location, ...args }]);
      onEvent?.({ method: 'set', eventArgs, lastLocation });
    },
    pop: ({ eventArgs } = {}) => {
      if (origins.length > 1) {
        setOrigins(dropLast(1, origins));
      }
      onEvent?.({ method: 'pop', eventArgs, lastLocation });
    },
  };

  return {
    lastLocation,
    ...methods,
  };
}
