import { useTranslation } from 'react-i18next';
import { slugifyObject } from 'utils/slug';
export default function useJourneyBreadcrumb(journey, collectionId) {
  const { t } = useTranslation();
  const journeyName = journey?.name;
  const collection = journey?.collections?.find?.(
    ({ id }) => id === collectionId
  );
  return [
    {
      label: t('journals.journeys.title'),
      href: '/journeys',
    },
    {
      label: journeyName,
      href: `/journey/${slugifyObject(journey?.id, journey?.name)}`,
    },
    collection && {
      label: collection?.name,
      href: `/journey/${slugifyObject(
        journey?.id,
        journey.name
      )}/${slugifyObject(collection?.id, collection?.label)}`,
    },
  ].filter(Boolean);
}
