import { useParams, Redirect } from 'react-router-dom';

import { useAuth } from 'Auth';
import { getSlugHead } from 'utils/slug';

import { useLibraryQuery } from '../../hooks';
import { LibraryPublicApiProvider } from '../../libraryApiContext';
import LibraryAssetModalViewer from './LibraryAssetModalViewer';
import LibraryAssetPageViewer from './LibraryAssetPageViewer';

function LibraryAssetViewerRoutedPage() {
  const { hashId: hashIdSlug } = useParams();
  const hashId = getSlugHead(hashIdSlug);
  const { data: asset, isLoading } = useLibraryQuery(hashId);
  if (!asset && !isLoading) {
    return <Redirect to="404" />;
  }
  return <LibraryAssetPageViewer asset={asset} />;
}

function LibraryAssetViewerFactory({ Viewer = LibraryAssetViewerRoutedPage }) {
  const { authenticated } = useAuth();

  return authenticated ? (
    <Viewer />
  ) : (
    <LibraryPublicApiProvider>
      <Viewer />
    </LibraryPublicApiProvider>
  );
}

function Page() {
  return <LibraryAssetViewerFactory Viewer={LibraryAssetViewerRoutedPage} />;
}

function Modal() {
  return <LibraryAssetViewerFactory Viewer={LibraryAssetModalViewer} />;
}

export default {
  Page,
  Modal,
};
