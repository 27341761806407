import classnames from 'classnames';
import { NotificationList, Notifier } from '../Notifications';

function MenuNotifier({ state, dispatch, isScrolled }) {
  const { isNotificationOpen } = state;
  return (
    <div>
      <Notifier
        onClick={() =>
          dispatch({
            type: isNotificationOpen ? 'close' : 'open',
            menu: 'isNotificationOpen',
          })
        }
        open={isNotificationOpen}
      />
      {isNotificationOpen && (
        <div
          className={classnames(
            'absolute left-0 right-0 bg-white lg:left-auto lg:right-10 lg:m-1 lg:w-screen lg:max-w-[30.44rem] lg:border lg:border-smd lg:shadow-md 2xl:right-48',
            isScrolled ? 'top-12' : 'top-20'
          )}
          style={{ zIndex: 70 }}
        >
          <NotificationList />
        </div>
      )}
    </div>
  );
}

export default MenuNotifier;
