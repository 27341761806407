import { createContext, useContext } from 'react';

import { LibraryApi, LibraryApiPublic } from 'api';

const LibraryApiContext = createContext(LibraryApi);

export function useLibraryApi() {
  return useContext(LibraryApiContext);
}

export function LibraryApiProvider({ value, children }) {
  return <LibraryApiContext.Provider value={value} children={children} />;
}
export function LibraryPublicApiProvider({ children }) {
  return <LibraryApiProvider value={LibraryApiPublic} children={children} />;
}
