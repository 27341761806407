import Error from 'Error';
import { useTranslation } from 'react-i18next';

function NotFound({ buttonLabel }) {
  const { t } = useTranslation();

  const defaultButtonLabel = t('common.back');

  const description = (
    <>
      {t('errors.page-not-found.description')}
      <a className="text-smd-accent" href="mailto: support@storymd.com.">
        support@storymd.com
      </a>
    </>
  );

  return (
    <Error
      title={t('errors.page-not-found.title')}
      buttonLabel={buttonLabel || defaultButtonLabel}
      description={description}
    />
  );
}

export default NotFound;
