import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import {
  useCollectionsSummary,
  useCollectionDelete,
  useCollectionReorder,
  collectionKeys,
} from 'hooks';

import { ContentContainer, Confirm } from 'components';
import { Button } from 'components/forms';
import { ChevronLeft, Pencil, Trash } from 'components/icons';
import { DragDropTable, TableActionGroup } from 'components/Table';
import { useDefaultCollectionName } from './hooks';
import { CollectionsDashboardHeader } from './components';

function CollectionsDashboard() {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const queryClient = useQueryClient();

  const { collections, isFetching } = useCollectionsSummary();
  const [collectionForDeletion, setCollectionForDeletion] = useState();

  const fallbackCollectionName = useDefaultCollectionName();

  const { deleteCollection, isLoadingDelete } = useCollectionDelete({
    onSuccess: () => {
      setCollectionForDeletion();
    },
  });

  const { reorderCollection, isLoadingReorder } = useCollectionReorder({
    onSuccess: () => {
      queryClient.invalidateQueries(collectionKeys.all());
    },
  });

  const onDelete = (collection) => setCollectionForDeletion(collection);

  const onDeleteModalClose = () =>
    !isLoadingDelete && setCollectionForDeletion();

  const onDeleteModalConfirm = () => {
    deleteCollection(collectionForDeletion);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('journals.collections.name'),
        accessor: 'name',
        Cell: ({ value }) => (!value ? fallbackCollectionName : value),
      },
      {
        Header: t('library.search.healthjournal', { count: 0 }),
        accessor: 'journalsCount',
      },
      {
        accessor: 'none',
        headerClassName: 'text-right',
        Header: () => <div className="text-right">{t('common.actions')}</div>,
        Cell: ({ row }) => {
          return (
            <TableActionGroup>
              <Button.Anchor
                flavor="anchor"
                onClick={() => history.push(`${path}/${row.original?.id}`)}
                aria-label={t('labels.collections.edit-collection', {
                  collection: row.original?.name,
                })}
              >
                <Pencil className="h-3 w-3 stroke-2" />
                <span>{t('common.edit')}</span>
              </Button.Anchor>
              <Button.Anchor
                flavor="anchor"
                disabled={row.original.defaultCollection}
                onClick={() =>
                  row.original.defaultCollection
                    ? null
                    : onDelete(row.original.id)
                }
                aria-label={t('labels.collections.delete-collection', {
                  collection: row.original?.name,
                })}
              >
                <Trash className="h-4 w-4" />
                <span>{t('common.delete')}</span>
              </Button.Anchor>
            </TableActionGroup>
          );
        },
      },
    ];
  }, [t, history, path, fallbackCollectionName]);

  return (
    <>
      <div className="flex w-full flex-grow flex-col bg-white">
        <section className="px-smd-base pt-smd-lg pb-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
          <ContentContainer>
            <div className="w-full">
              <Link
                to="/journals/collections"
                className="smd-focus-visible-primary rounded-sm"
              >
                <Button.Anchor flavor="anchor" tabIndex={-1}>
                  <ChevronLeft className="h-5 w-5 stroke-2" />
                  <span>{t('journals.collections.back-to-collections')}</span>
                </Button.Anchor>
              </Link>
            </div>

            <CollectionsDashboardHeader />

            {Boolean(collections) && (
              <DragDropTable
                columns={columns}
                data={collections}
                loading={isFetching || isLoadingDelete || isLoadingReorder}
                setDisableRowDnD={(row) => row.defaultCollection}
                onReorder={reorderCollection}
              />
            )}
          </ContentContainer>
        </section>
      </div>
      <Confirm
        cancelLabel={t('common.cancel')}
        isOpen={collectionForDeletion}
        onClose={onDeleteModalClose}
        renderConfirm={
          <Button.Alert
            size="lg"
            onClick={onDeleteModalConfirm}
            loading={isLoadingDelete}
          >
            {t('common.delete')}
          </Button.Alert>
        }
      >
        {t('journals.collections.delete-confirmation')}
      </Confirm>
    </>
  );
}

export default CollectionsDashboard;
