import { useMedia } from 'hooks';
import classnames from 'classnames';

import { Skeleton } from '../..';

function ViewerPageSkeleton() {
  return (
    <div className="space-y-6">
      <Skeleton className="h-10 w-full" />
      <SkeletonAssetContainer />
      <div className="mx-4 space-y-6">
        <SkeletonTitle />
        <SkeletonParagraph />
        <SkeletonSubTitle />
        <SkeletonParagraph />
        <SkeletonSubTitle />
        <SkeletonParagraph />
      </div>
    </div>
  );
}

function SkeletonAssetContainer() {
  const isLarge = useMedia(useMedia.LARGE);
  return (
    <div className="shadow-md">
      <Skeleton className={classnames('w-full', isLarge ? 'h-96' : 'h-52')} />
      <div className="flex flex-nowrap p-4">
        <div className="w-full pr-8">
          <div className="w-2/4">
            <Skeleton.Text className="text-smd-base" />
            <Skeleton.Text className="w-3/4 text-smd-xs" />
          </div>
        </div>
        <Skeleton.Circle className="h-8 w-8" />
      </div>
    </div>
  );
}

function SkeletonParagraph({ lines = 5 }) {
  return (
    <div>
      <Skeleton.Text lines={lines} trimLastLine className="text-smd-base" />
    </div>
  );
}

function SkeletonTitle({ lines = 2 }) {
  return (
    <div>
      <Skeleton.Text lines={lines} trimLastLine className="text-3xl" />
    </div>
  );
}

function SkeletonSubTitle({ lines = 1 }) {
  return (
    <div>
      <Skeleton.Text
        lines={lines}
        trimLastLine
        className="mt-8 w-2/3 text-smd-h3"
      />
    </div>
  );
}

export default ViewerPageSkeleton;
