import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { tag } from 'utils/analytics';

function usePageView() {
  const history = useHistory();

  useEffect(
    () =>
      history.listen((location) => {
        tag('config', 'GA_MEASUREMENT_ID', {
          page_title: location.pathname,
          page_location: location.pathname,
          send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
          cookie_domain: window.location.origin,
          cookie_prefix: 'storymd',
        });
      }),
    [history]
  );
}

export default usePageView;
