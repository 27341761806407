import { useEffect, useState } from 'react';
import { debounce } from 'utils';

function useWindowSize(delay) {
  const [windowSize, setWindowSize] = useState(() => ({
    width: window?.innerWidth,
    height: window?.innerHeight,
  }));

  useEffect(() => {
    // Debouncing the resize callback to minimize the render count
    const handleResize = debounce(function resize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, delay);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [delay]);

  return windowSize;
}

export default useWindowSize;
