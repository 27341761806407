import classnames from 'classnames';
import { useAddRequiredSuffix } from './useAddRequiredSuffix';

export function useManualInputDefaultProps(props) {
  const fieldLabel = useAddRequiredSuffix(
    props.label,
    props?.rules?.requiredLabel ??
      props?.rules?.required?.value ??
      props?.rules?.required
  );
  const newProps = {
    ...props,
    groupClassName: classnames('flex flex-col', props.groupClassName),
    name: props?.prefix ? `${props.prefix}.${props.name}` : props.name,
    label: props.label !== null ? fieldLabel : null,
  };

  return newProps;
}
