import classnames from 'classnames';
import { ContentContainer } from 'components';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { slice } from 'ramda';
import { getJournalSlugPath } from '../../utils';
import { AddToCollection } from '../../components/actions';
import { SmartJournalCard } from '../../components';

const MAX_HJ_TO_RENDER = 12;

function CategoryView({
  category,
  journals = [],
  onAddTo = () => {},
  onSelect = () => {},
  className = 'mx-smd-base mt-smd-xl lg:mx-smd-xxl lg:mt-smd-xxl',
  journalsToRenderCount,
}) {
  const journalsShown = journalsToRenderCount
    ? slice(0, MAX_HJ_TO_RENDER, journals)
    : journals;

  return (
    <section className={classnames('antialiased', className)}>
      <ContentContainer>
        <h2 className="mb-smd-base break-words text-smd-h3 font-semibold text-smd-main line-clamp-2 lg:font-bold">
          {category}
        </h2>
        <VirtualCarousel
          withNavigation
          slides={journalsShown?.map?.((journal) => (
            <Carousel.Slide key={journal.id}>
              <div className="smd-transform-duration">
                <SmartJournalCard
                  actions={[
                    {
                      onClick: () => onAddTo(journal),
                      element: <AddToCollection />,
                    },
                  ]}
                  journal={journal}
                  onClick={() => onSelect(journal)}
                  to={getJournalSlugPath(journal)}
                />
              </div>
            </Carousel.Slide>
          ))}
        />
      </ContentContainer>
    </section>
  );
}

export default CategoryView;
