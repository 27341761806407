export async function checkWebApiContextPermission(
  permission,
  onSuccess,
  onError
) {
  try {
    await navigator.permissions
      .query({ name: permission })
      .then(onSuccess)
      .catch(onError);
  } catch {
    onError();
  }
}

export function writeValueToTheClipboard(value) {
  navigator.clipboard && navigator.clipboard.writeText(value);
}
