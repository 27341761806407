import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { isEmpty } from 'ramda';
import { ContentContainer } from 'components';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useMedia } from 'hooks';
import { parseJournals } from 'landing/utils';

import { AssetsLightBox } from 'journals/components';
import HeaderSection from './HeaderSection';
import {
  JourneyHealthTopicCard,
  JourneyVideoCard,
  JourneyEventCard,
} from './JourneyCards';

const breakpoints = {
  [useMedia.xxl]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  [useMedia.lg]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  [useMedia.md]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  [useMedia.sm]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  0: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
};

const carouselNavStyles = ['grid place-items-center', 'top-1/3 lg:top-0'];
const carouselNavBtnStyles = [
  'w-6 h-6',
  'text-white lg:text-smd-main lg:stroke-2',
  'lg:-mb-24',
];

const TYPE_MAPPINGS = {
  healthTopic: {
    label: 'common.healthjournal',
    component: JourneyHealthTopicCard,
  },
  video: {
    label: 'common.video',
    component: JourneyVideoCard,
  },
  event: {
    label: 'common.story',
    component: JourneyEventCard,
  },
};

function isVideoCard(card) {
  return card?.type === 'video';
}

export default function JourneyInfo({
  name,
  description,
  featuredAsset,
  linkages,
  onShare,
}) {
  const { t } = useTranslation();

  const videoLinkage = linkages?.topics?.find?.(
    (linkage) => linkage?.type === 'video'
  );

  const cards = linkages?.topics?.flatMap((linkage) => {
    if (linkage?.type === 'healthTopic') {
      return parseJournals([linkage]);
    } else {
      return linkage;
    }
  });

  const hasCards = !isEmpty(cards);

  const { state, openLightBox } = AssetsLightBox.useAssetsLightBoxState();

  return (
    <>
      <HeaderSection
        name={name}
        description={description}
        cover={featuredAsset}
        onShare={onShare}
      />
      {hasCards && (
        <ContentContainer>
          <div className="-mt-[2rem] mb-4 px-4 lg:-mt-[12rem] lg:min-h-[11rem]  lg:px-0">
            <VirtualCarousel
              className="relative -mx-4 lg:-mx-8 lg:max-w-[72%] lg:px-6 xl:max-w-[54.5%] 2xl:max-w-[64%]"
              carouselProps={{
                breakpoints,
              }}
              slides={cards?.map((card) => {
                const Component = TYPE_MAPPINGS?.[card?.type]?.component;
                return Component ? (
                  <Carousel.Slide key={card.id}>
                    <div className="smd-transform-duration relative border-2 border-white">
                      <Component
                        card={card}
                        onClick={
                          isVideoCard(card) ? () => openLightBox() : null
                        }
                      />
                      <div className="absolute top-2 left-2 z-30 rounded-sm bg-smd-accent py-1 px-2 text-smd-sm text-white">
                        {t(`${TYPE_MAPPINGS[card.type].label}`, {
                          count: 1,
                        })}
                      </div>
                    </div>
                  </Carousel.Slide>
                ) : null;
              })}
            >
              <Carousel.Prev
                className={classnames(
                  'right-auto lg:-left-1',
                  carouselNavStyles
                )}
              >
                <ChevronLeft className={classnames(carouselNavBtnStyles)} />
              </Carousel.Prev>
              <Carousel.Next
                className={classnames(
                  'left-auto lg:-right-1',
                  carouselNavStyles
                )}
              >
                <ChevronRight className={classnames(carouselNavBtnStyles)} />
              </Carousel.Next>
            </VirtualCarousel>
          </div>
        </ContentContainer>
      )}
      {videoLinkage && (
        <AssetsLightBox assets={[videoLinkage]} lightBoxState={state} />
      )}
    </>
  );
}
