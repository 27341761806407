import { useContext, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { SettingsContext } from 'SettingsContext';
import { hasSensitiveTags } from 'components/BlurredLayer';

const KEY = 'smd-sensitive-content-blurred';

export default function useSensitveContent() {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [shouldBlur, setShouldBlur] = useLocalStorage(KEY, true);
  const [callbackFn, setCallbackFn] = useState();

  const confirmSensiveContentViewing = (onUnblurConfirmed) => {
    setConfirmationModalOpen(true);
    setCallbackFn(() => onUnblurConfirmed);
  };

  const closeConfirmationModal = () => {
    setCallbackFn(null);
    setConfirmationModalOpen(false);
  };

  const onSensiviteContentViewConfirmed = (unblurAll = false) => {
    if (unblurAll) {
      setShouldBlur(false);
    }

    if (typeof callbackFn === 'function') {
      callbackFn();
    }
    setConfirmationModalOpen(false);
    setCallbackFn(null);
  };

  return {
    shouldBlur,
    confirmationModalOpen,
    closeConfirmationModal,
    setShouldBlur,
    confirmSensiveContentViewing,
    onSensiviteContentViewConfirmed,
  };
}

export function useSensitiveAssetViewRestriction(asset) {
  const isSensitive = hasSensitiveTags(asset);
  const settingsContext = useContext(SettingsContext);
  const sensitiveContent = settingsContext?.sensitiveContent;
  const [isRestricted, setIsRestricted] = useState(
    sensitiveContent?.shouldBlur && isSensitive
  );
  return {
    isSensitive: isSensitive,
    isRestricted: isRestricted && sensitiveContent?.shouldBlur,
    setIsRestricted: setIsRestricted,
  };
}
