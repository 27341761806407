import {
  __,
  always,
  defaultTo,
  equals,
  groupBy,
  indexOf,
  join,
  map,
  pipe,
  prop,
  sortBy,
  toPairs,
  when,
  zipObj,
} from 'ramda';
import { normalizeAssetUrl } from 'journals/utils'; // TODO

const TYPE_ORDER = ['image/avif', 'image/webp', 'image/jpeg'];

const groupByType = groupBy(prop('type'));

const sourceSetMapper = pipe(
  map(({ path, width }) => `${normalizeAssetUrl(path)} ${width}w`),
  join(', ')
);

const toSourceArray = pipe(toPairs, map(zipObj(['type', 'srcSet'])));

const notFound = equals(-1);
const alwaysFound = always(Infinity);

const sortByTypePreference = sortBy(
  pipe(prop('type'), indexOf(__, TYPE_ORDER), when(notFound, alwaysFound))
);

export const getSourceSetByType = pipe(
  defaultTo([]),
  groupByType,
  map(sourceSetMapper)
);

export const getSourceProps = pipe(
  getSourceSetByType,
  toSourceArray,
  sortByTypePreference
);
