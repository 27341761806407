import classnames from 'classnames';
import { useCardSize } from 'hooks';

function ChannelCard({ channel, className }) {
  const { name, description, cover, avatar } = channel;
  const { imgHeight, bodyHeight } = useCardSize();

  return (
    <article
      className={classnames(
        'flex cursor-pointer flex-col bg-white text-smd-main antialiased shadow-md',
        className
      )}
    >
      <img
        src={cover}
        alt={name}
        style={{ height: imgHeight }}
        className="block object-cover"
      />
      <div
        className="relative flex flex-col p-smd-sm pt-smd-lg lg:p-smd-base lg:pt-smd-lg"
        style={{ height: bodyHeight }}
      >
        <h1 className="mb-2 truncate text-smd-base font-bold">{name}</h1>
        <p className="hidden text-smd-sm text-smd-secondary lg:line-clamp-3">
          {description}
        </p>
        <div className="absolute -top-12 h-16 w-16 overflow-hidden rounded-full border-2 border-smd-accent bg-white lg:-top-16 lg:h-20 lg:w-20">
          <img className="h-full w-full" src={avatar} alt={name} />
        </div>
      </div>
    </article>
  );
}

export default ChannelCard;
