import { useTranslation } from 'react-i18next';
import { Exclamation, X } from './icons';

function ErrorAlert({ error, onClose }) {
  const { t } = useTranslation();
  return (
    <div className="mx-4 mt-4 flex select-none flex-nowrap justify-between bg-smd-error-darker p-2 text-smd-sm text-white">
      <div className="flex items-center space-x-2 pl-2">
        <Exclamation className="h-5 w-5" />
        <span>{t(`my-phr.ocr.upload.errors.${error}`)}</span>
      </div>
      <button onClick={onClose}>
        <X className="h-6 w-6 stroke-2" />
      </button>
    </div>
  );
}

export default ErrorAlert;
