import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'Auth';
import { AlternatingTextColorElement, ContentContainer } from 'components';
import { Button } from 'components/forms';
import { Share } from 'components/icons';
import { GradientBackground, PictureResource } from 'components/media';
import { useUserSubscriptionStatus } from 'my-phr/hooks';
import TrackJourneyModal from './TrackJourneyModal';

export default function HeaderSection({ name, description, cover, onShare }) {
  const { t } = useTranslation();
  const { authenticated } = useAuth();
  const { isSubscriptionActive } = useUserSubscriptionStatus();

  const [trackJourneyModal, setTrackJourneyModal] = useState(false);

  const TrackJourneyComponent =
    authenticated && isSubscriptionActive ? Link : Button;

  return (
    <div className="relative h-[38rem]">
      <PictureResource
        config={cover}
        style={{
          objectPosition: 'center',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        pictureClassName="absolute inset-0"
      />
      <GradientBackground className="absolute inset-0" />
      <div className="px-4 lg:px-12">
        <ContentContainer className="relative space-y-8 pt-64 pb-8 text-white md:py-40">
          <div className="flex space-x-1 text-2xl font-bolder tracking-widest line-clamp-2 md:text-3xl">
            <i className="storymd-plus" />
            <AlternatingTextColorElement
              name={name}
              evenColor="text-smd-gray"
              oddColor="text-smd-accent-bright"
            />
          </div>
          <div
            className="line-clamp-3"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <div className="flex flex-col items-start space-y-4 sm:flex-row sm:space-x-3.5 sm:space-y-0 lg:space-x-6">
            <TrackJourneyComponent
              to="/health"
              onClick={() => setTrackJourneyModal(true)}
              className="smd-focus-visible-primary rounded-sm bg-smd-accent py-2 px-4"
              color="primary"
            >
              {t('journals.journeys.track.label')}
            </TrackJourneyComponent>
            <Button.Negative onClick={onShare}>
              <Share className="h-5 w-5 shrink-0" />
              <span>{t('common.share')}</span>
            </Button.Negative>
          </div>
        </ContentContainer>
      </div>
      <TrackJourneyModal
        isOpen={trackJourneyModal}
        onClose={() => setTrackJourneyModal(false)}
      />
    </div>
  );
}
