import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLibraryMeta } from 'library/hooks';
import { RELATED_ID } from './const';

export const usePageMeta = (journalKey, containerKey) => {
  const { t } = useTranslation();

  const { data: journalMeta } = useLibraryMeta(journalKey);
  const { data: containerMeta } = useLibraryMeta(containerKey, {
    keepPreviousData: true,
  });

  return useMemo(() => {
    const meta = containerMeta ?? journalMeta;
    if (containerKey === RELATED_ID && meta) {
      return {
        ...meta,
        title: `${t('journals.related.container')} - ${meta.title}`,
      };
    }
    return meta;
  }, [containerKey, containerMeta, journalMeta, t]);
};
