import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'Auth';
import { ContainerBlock } from 'journals/blocks';
import { AddToCollectionModal, SmartJournalCard } from 'journals/components';
import { AddToCollection } from 'journals/components/actions';
import { GridView } from 'journals/history/components';
import { getJournalSlugPath } from 'journals/utils';
import { Button } from 'components/forms';

const SHOWN_JOURNALS_COUNT = 12;

function RelatedHealthJournals({ journal }) {
  const { t } = useTranslation();
  const { authenticated } = useAuth();
  const [actionJournal, setActionJournal] = useState();

  const related = journal?.preamble?.relatedJournals;

  const [relatedJournalsShown, setRelatedJournalsShown] =
    useState(SHOWN_JOURNALS_COUNT);

  const paginatedRelatedHealthJournals = related?.slice(
    0,
    relatedJournalsShown
  );

  const hasNextPage = relatedJournalsShown < related?.length;

  const showNext = () => {
    setRelatedJournalsShown((prev) => prev + SHOWN_JOURNALS_COUNT);
  };

  return (
    <div className="mx-auto max-w-4xl space-y-6">
      <ContainerBlock label={t('journals.related.container')}>
        {related?.length && (
          <>
            <h2 className="text-smd-h4 font-semibold md:px-smd-md md:text-smd-h4 lg:py-smd-lg lg:text-smd-h3">
              {t('journals.related.heading')}
            </h2>
            <GridView
              items={paginatedRelatedHealthJournals}
              childComponent={(entry) => {
                return (
                  <div key={entry.id} className="smd-transform-duration">
                    <SmartJournalCard
                      pushLocation
                      actions={[
                        {
                          onClick: () => setActionJournal(journal),
                          element: <AddToCollection />,
                        },
                      ]}
                      journal={entry}
                      to={getJournalSlugPath(entry)}
                    />
                  </div>
                );
              }}
            />
            {hasNextPage && (
              <div className="flex justify-center">
                <Button onClick={showNext}>{t('common.show-more')}</Button>
              </div>
            )}
          </>
        )}
      </ContainerBlock>
      {authenticated && (
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      )}
    </div>
  );
}
export default RelatedHealthJournals;
