import { createContext, useContext } from 'react';

import { ChannelsApi, ChannelsApiPublic } from 'api';

const ChannelsApiContext = createContext(ChannelsApi);

export function useChannelsApi() {
  return useContext(ChannelsApiContext);
}

export function ChannelsPublicApiProvider({ children }) {
  return (
    <ChannelsApiContext.Provider value={ChannelsApiPublic}>
      {children}
    </ChannelsApiContext.Provider>
  );
}
