import Keycloak from 'keycloak-js';

import { getDistributionConfig } from 'distribution';

const ssoConfig = getDistributionConfig()?.sso;

export const keycloakConfig = Object.freeze({
  url: ssoConfig?.url ?? process.env.REACT_APP_KEYCLOAK_URL,
  realm: ssoConfig?.realm ?? process.env.REACT_APP_KEYCLOAK_REALM,
  client: ssoConfig?.client ?? process.env.REACT_APP_KEYCLOAK_CLIENT,
});

export default new Keycloak({
  url: keycloakConfig.url + '/auth/',
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.client,
});
