import classnames from 'classnames';

import { useCardSize } from 'hooks';
import Skeleton from '../../components/Skeleton';

function SkeletonCard({
  heading,
  content,
  cover,
  logos,
  additionalContent,
  className,
  headingClassName,
  ...props
}) {
  const { imgHeight, bodyHeight } = useCardSize();

  return (
    <article
      className={classnames(
        'flex flex-col bg-white text-smd-main antialiased shadow-md',
        className
      )}
      {...props}
    >
      {cover ?? (
        <Skeleton
          style={{
            height: imgHeight,
          }}
        />
      )}
      <div
        style={{
          height: bodyHeight,
        }}
        className="relative flex flex-col justify-between p-smd-sm lg:p-smd-base"
      >
        {additionalContent}
        {heading ?? (
          <h1
            className={classnames(
              'shrink-0 truncate text-smd-base font-bold',
              headingClassName
            )}
          >
            <Skeleton.Text className="w-1/2" />
          </h1>
        )}
        {content ?? (
          <div
            style={{ maxHeight: '3.75rem' }}
            className="hidden overflow-hidden text-smd-sm text-smd-secondary lg:block"
          >
            <Skeleton.Text lines="3" />
          </div>
        )}
        {logos ?? (
          <section className="flex h-4.5 shrink-0 items-center space-x-2 overflow-hidden">
            <Skeleton className="h-full w-12" />
          </section>
        )}
      </div>
    </article>
  );
}

export default SkeletonCard;
