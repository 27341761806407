import { useMemo } from 'react';
import { always } from 'ramda';
import usePublicResource from './usePublicResource';

function useTags(filter) {
  const { data, isFetched } = usePublicResource('/initiatives.json');

  const tags = useMemo(
    () => (data ? data.filter(filter || always(true)) : []),
    [data, filter]
  );

  return {
    tags,
    isFetched,
  };
}

export default useTags;
