export function tag(...args) {
  const { gtag } = window;
  gtag?.(...args);

  // TODO: After cookies consent flow is determined, bring back and modify accordingly

  // const cookiesConsent = getCookieConsentValue() === 'true';

  // if (cookiesConsent) {
  //   const { gtag } = window;
  //   gtag?.(...args);
  // }
}
