import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'hooks';

import { getAssetUrl } from 'utils';
import { CompareImage } from 'components/media';

function mapToImages(config) {
  return config?.assets?.map(({ name, url }) => ({
    src: getAssetUrl(url),
    alt: name,
  }));
}

function getCaption(config) {
  return config?.assets
    ?.map(({ name }) => name)
    .slice(0, 2)
    .join(' / ');
}

function ComparisonInteractive({ config }) {
  const images = mapToImages(config);
  const caption = getCaption(config);

  return (
    <div>
      <CompareImage images={images} imageCss={{ objectFit: 'contain' }} />
      {caption && <p className="mt-4 break-all px-4 text-center">{caption}</p>}
    </div>
  );
}

ComparisonInteractive.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    subtype: PropTypes.oneOf(['comparison', 'slide']).isRequired,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['image']).isRequired,
      })
    ),
  }),
};

export function ComparisonInteractiveWithLimitedHeight({ config, style }) {
  const images = mapToImages(config);
  const caption = getCaption(config);

  const isLarge = useMedia(useMedia.LARGE);

  const [maxWidth, setMaxWidth] = useState('unset');
  const [aspect, setAspect] = useState({ left: null, right: null });

  useEffect(() => {
    const { left, right } = aspect;
    if (left !== null && right !== null) {
      setMaxWidth(`${600 * Math.min(left, right)}px`);
    }
  }, [aspect]);

  return (
    <div style={style}>
      <div
        style={{
          maxWidth,
          minHeight: isLarge
            ? 'clamp(17.5rem, 15vw, 35rem)'
            : 'clamp(10rem, 20vw, 25rem)',
        }}
        className="relative mx-auto"
      >
        <CompareImage images={images} imageCss={{ objectFit: 'contain' }} />
      </div>
      <div className="invisible relative h-0 w-0">
        <img
          alt=""
          src={images?.[0]?.src}
          onLoad={({ target }) => {
            const aspect = target.naturalWidth / target.naturalHeight;
            setAspect(({ right }) => ({ right, left: aspect }));
          }}
        />
        <img
          alt=""
          src={images?.[1]?.src}
          onLoad={({ target }) => {
            const aspect = target.naturalWidth / target.naturalHeight;
            setAspect(({ left }) => ({ left, right: aspect }));
          }}
        />
      </div>
      {caption && (
        <p className="mt-4 break-words px-4 text-center">{caption}</p>
      )}
    </div>
  );
}

ComparisonInteractiveWithLimitedHeight.propTypes = {
  ...ComparisonInteractive.propTypes,
};

export default ComparisonInteractive;
