import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { nanoid } from 'nanoid';
import classnames from 'classnames';

import { useAuth } from 'Auth';
import { ContentContainer, CTA } from 'components';
import { ChevronRight } from 'components/icons';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { AddToCollectionModal, SmartJournalCard } from 'journals/components';
import { AddToCollection } from 'journals/components/actions';
import { getJournalSlugPath } from 'journals/utils';
import { parseJournals } from '../../utils';
function EditorsChoiceJournalsCarousel({ data, className }) {
  const { t } = useTranslation();

  const randomId = nanoid();

  const { authenticated } = useAuth();

  const [actionJournal, setActionJournal] = useState();

  if (isNil(data)) {
    return null;
  }
  const journals = parseJournals(data?.journals);
  const isCtaPropsPopulated = Object.values(data.ctaProps).some(Boolean);
  const ctaProps = isCtaPropsPopulated
    ? data.ctaProps
    : { ctaInternal: '/journeys' };

  return (
    <section
      className={classnames('py-12', className)}
      aria-labelledby={randomId}
    >
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <div className="mb-4 flex flex-col items-start lg:flex-row lg:items-baseline lg:space-x-4">
            <h2 id={randomId} className="text-smd-h1 font-bold">
              {data.title || t('landing.editors-choice')}
            </h2>
            <CTA
              color="anchor"
              size="none"
              ctaProps={ctaProps}
              data-testid="seeAllHealthJournals"
            >
              <span className="font-bold">
                {data.ctaText || t('landing.see-all-journals')}
              </span>
              <ChevronRight className="h-4 w-4 stroke-2" />
            </CTA>
          </div>

          <VirtualCarousel
            withNavigation
            slides={journals?.map((journal, index) => (
              <Carousel.Slide key={journal.id}>
                <div className="smd-transform-duration relative pb-10">
                  <SmartJournalCard
                    actions={[
                      {
                        onClick: () => setActionJournal(journal),
                        element: <AddToCollection />,
                      },
                    ]}
                    journal={journal}
                    to={getJournalSlugPath(journal)}
                  />
                  <div className="absolute bottom-10 right-4 translate-y-1/2 text-[4rem] font-black text-smd-accent-bright lg:text-[4.375rem]">
                    {index + 1}
                  </div>
                </div>
              </Carousel.Slide>
            ))}
          />
        </ContentContainer>
      </div>
      {authenticated && (
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      )}
    </section>
  );
}

export default EditorsChoiceJournalsCarousel;
