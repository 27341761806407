import { useMemo } from 'react';
import { safeDateFormat } from 'utils';
import { DATE_PRESETS } from '../const';
import { useIsShared, useSharedStoryBlock } from '../pages/Sharing/hooks';
import { useStoryBlockQuery } from './queryHooks';
import { useBarChartDataWithFeedbackColor } from './useChartDataWithFeedbackColor';
import { useRatioField } from './ratioHooks';

function useRatioChartData(dataWithStoryblock) {
  const { ratioField } = useRatioField(dataWithStoryblock?.[0]);

  const { denominator, numerator } = ratioField || {};

  const rangeData = useMemo(
    () =>
      dataWithStoryblock?.map((item) => ({
        ...item,
        date: safeDateFormat({
          date: item.effectiveTs,
          pattern: DATE_PRESETS.DATE_ONLY,
        }),
        value: item.value.map(Number),
        id: item?.id,
      })),
    [dataWithStoryblock]
  );

  const isShared = useIsShared();

  const storyblockQueryHook = isShared
    ? useSharedStoryBlock
    : useStoryBlockQuery;

  const innerStoryblocks = storyblockQueryHook([
    numerator?.id,
    denominator?.id,
  ])?.data;

  const completeRangeData = useMemo(
    () =>
      rangeData?.map((item) => ({
        ...item,
        numerator: innerStoryblocks?.find(
          (block) => block?.id === numerator?.id
        ),
        denominator: innerStoryblocks?.find(
          (block) => block?.id === denominator?.id
        ),
      })),
    [rangeData, innerStoryblocks, numerator?.id, denominator?.id]
  );

  const numeratorRanges = completeRangeData?.[0]?.numerator;
  const denominatorRanges = completeRangeData?.[0]?.denominator;

  const parsedChartData = useBarChartDataWithFeedbackColor?.(
    completeRangeData,
    numeratorRanges,
    denominatorRanges
  );

  const barColors = useMemo(
    () =>
      parsedChartData?.map((item) => [
        item?.numeratorColor,
        item?.denominatorColor,
      ]),
    [parsedChartData]
  );

  return { completeRangeData, barColors, parsedChartData };
}

export default useRatioChartData;
