import { head, isNil, last, reverse } from 'ramda';

export const calcRangePlacement = (range, min, max, thumb = 0) => {
  const width = (range.max - range.min) / (max - min);
  const start = (range.min - min) / (max - min);
  const end = (range.max - min) / (max - min);

  const position = {
    left: start * 100,
    right: (1 - end) * 100,
  };

  const error = {
    start: (0.5 - start) * thumb,
    end: (0.5 - end) * thumb,
  };

  return { start, end, width, position, error };
};

export const findRangeForValue = (ranges, value) => {
  if (isNil(value) || !ranges) return;

  const firstRange = head(ranges);
  const lastRange = last(ranges);

  if (value > lastRange.max) {
    return lastRange;
  } else if (value < firstRange.min) {
    return firstRange;
  }

  return reverse?.(ranges)?.find(
    ({ min, max }) => value >= min && value <= max
  );
};

export function roundToStep(value, step = 1) {
  return Math.round(value * (1 / step)) / (1 / step);
}

export function snapToClosestStepInRange(min, max, step, value) {
  if (value > max) return snapToClosestStepInRange(min, max, step, max);
  if (value < min) return snapToClosestStepInRange(min, max, step, min);

  const snapped = min + roundToStep(value - min, step);
  return snapped > max ? snapped - step : snapped;
}

export function snapToRangeCenter(ranges) {
  return function (min, max, step, value) {
    if (!ranges) return null;
    const range = findRangeForValue(ranges, value);
    if (!range) return null;
    return range.min + (range.max - range.min) / 2;
  };
}

const getRange = (min, max) => max - min;
const normalize = (min, max, value) => (value - min) / (max - min);
const denormalize = (min, max, value) => value * (max - min) + min;

function linToLog(min, max, value) {
  const range = getRange(min, max);
  const diff = value - min;

  if (diff <= 0) return min;
  if (diff >= range) return max;

  const normalizedLog = Math.log(diff + 1) / Math.log(range + 1);
  const logValue = denormalize(min, max, normalizedLog);
  return logValue;
}

function logToLin(min, max, value) {
  const range = getRange(min, max);
  const normalizedLog = normalize(min, max, value);
  const linValue = Math.pow(range + 1, normalizedLog) + min - 1;

  if (linValue < min) return min;
  if (linValue > max) return max;
  return linValue;
}

export const logarithmicScale = {
  apply: linToLog,
  unapply: logToLin,
};

export const linearScale = {
  apply: (min, max, value) => value,
  unapply: (min, max, value) => value,
};

const rangeColorShades = {
  normal: ['#20CC9B', '#09AB7D', '#007453', '#004A35', '#0B2517'],
  borderline: ['#ffcc00', '#cca300', '#997a00', '#665200', '#332900'],
  abnormal: ['#d83030', '#ad2626', '#821d1d', '#561313', '#2b0a0a'],
  negative: ['#4b7793', '#3c5f76', '#2d4758', '#1e303b', '#0f181d'],
  positive: ['#fe7d5d', '#cb644a', '#984b38', '#663225', '#331913'],
};

// Get index of closest normal range for the first normal - abnormal neighbors
function getBaseRangeIndex(ranges) {
  if (!ranges?.length) return;
  const flags = {};
  for (let index = 0, normalIndex = 0; index < ranges?.length; index++) {
    const { flag } = ranges[index];
    normalIndex = flag === 'normal' ? index : normalIndex;
    flags[flag] = true;
    if (flags['normal'] && flags['abnormal']) {
      return flag === 'abnormal'
        ? normalIndex + 1 // range max
        : normalIndex; // range min
    }
  }
  return 0;
}

function getRangesColors(ranges) {
  let lastFlag = null;
  let lastFlagCount = 0;
  return ranges?.map(({ flag = 'normal' }) => {
    if (lastFlag !== flag) {
      lastFlag = flag;
      lastFlagCount = 0;
    }
    const colorShades = rangeColorShades[lastFlag];
    return colorShades[lastFlagCount++ % colorShades?.length];
  });
}

export function getColorScheme(ranges = []) {
  if (!Array.isArray(ranges)) return;

  const baseIndex = getBaseRangeIndex(ranges);

  const upperRanges = ranges?.slice(baseIndex);
  const upperRangesColors = getRangesColors(upperRanges);

  const lowerRanges = ranges?.slice(0, baseIndex);
  const lowerRangesReversed = lowerRanges?.reverse() ?? [];
  const lowerRangesColorsReversed = getRangesColors(lowerRangesReversed);
  const lowerRangesColors = lowerRangesColorsReversed.reverse() ?? [];

  return [...lowerRangesColors, ...upperRangesColors];
}
