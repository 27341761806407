import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Plus } from 'components/icons';
import { isEmpty } from 'ramda';

import { AUTOCOMPLETE_CATEGORIES } from 'my-phr/layout/modules/ManualInputs/const';
import { isMeasurement, isObservationCategory } from './utils';

import theme from './HighlightedSuggestionRecord.module.css';

const getTranslationKey = (suggestion) =>
  `my-phr.types.${
    isMeasurement(suggestion)
      ? AUTOCOMPLETE_CATEGORIES.MEASUREMENT
      : isObservationCategory(suggestion)
      ? AUTOCOMPLETE_CATEGORIES[suggestion?.category[0].toUpperCase()] ??
        AUTOCOMPLETE_CATEGORIES.LAB_TEST
      : suggestion?.type?.toLowerCase?.()
  }`;

function AnotherCategoryEntry(suggestion) {
  const { t } = useTranslation();
  const { name, synonyms } = suggestion;
  const highlightedName = suggestion['name.edgengram']?.[0];
  const highlightedSynonyms = suggestion['synonyms.edgengram']?.[0];

  const translationKey = getTranslationKey(suggestion);

  return (
    <>
      {suggestion?.isFirstItem && (
        <div className="pointer-events-none text-smd-base font-semibold">
          {t('my-phr.manual-inputs.other-category-results')}
        </div>
      )}
      <div className="flex items-center space-x-2 py-3.5 pr-3 hover:cursor-pointer hover:bg-smd-gray-lightest">
        <div className="grow">
          {highlightedName ? (
            <span dangerouslySetInnerHTML={{ __html: highlightedName }} />
          ) : (
            name
          )}
          <div className="space-x-1 text-xs text-smd-gray-dark">
            {highlightedSynonyms ? (
              <span dangerouslySetInnerHTML={{ __html: highlightedSynonyms }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: synonyms?.[0] }} />
            )}
          </div>
        </div>
        <span className="pointer-events-none shrink-0 truncate rounded-sm bg-smd-accent-bright px-3 py-1 text-white">
          {suggestion?.label ?? t(translationKey, { count: 1 })}
        </span>
      </div>
    </>
  );
}

function AddCustomEntryOption({ query }) {
  const { t } = useTranslation();
  if (isEmpty(query)) return null;
  return (
    <div
      className="flex items-center space-x-2 py-3.5 pr-3 text-smd-accent hover:cursor-pointer hover:bg-smd-gray-lightest"
      data-value={query}
    >
      <Plus className="pointer-events-none h-4 w-4 shrink-0 rounded-full bg-smd-accent text-white" />
      <span className="pointer-events-none truncate">
        {t('my-phr.manual-inputs.add-custom-entry', { value: query })}
      </span>
    </div>
  );
}

function HighlightedSuggestionRecord({ suggestion, query: inputQuery }) {
  const { t } = useTranslation();
  const { name, synonyms, addsNew, isOtherCategory } = suggestion;

  const highlightedName = suggestion['name.edgengram']?.[0];
  const highlightedSynonyms = suggestion['synonyms.edgengram']?.[0];

  const translationKey = getTranslationKey(suggestion);

  if (addsNew) {
    return <AddCustomEntryOption {...suggestion} query={inputQuery} />;
  }

  if (isOtherCategory) {
    return <AnotherCategoryEntry {...suggestion} />;
  }

  return (
    <div
      className={classnames(
        'flex items-center space-x-2 py-3.5 pr-3 hover:cursor-pointer hover:bg-smd-gray-lightest',
        theme
      )}
    >
      <div className="grow">
        {highlightedName ? (
          <span dangerouslySetInnerHTML={{ __html: highlightedName }} />
        ) : (
          name
        )}
        <div className="space-x-1 text-xs text-smd-gray-dark">
          {highlightedSynonyms ? (
            <span dangerouslySetInnerHTML={{ __html: highlightedSynonyms }} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: synonyms?.[0] }} />
          )}
        </div>
      </div>
      <span className="pointer-events-none shrink-0 truncate rounded-sm bg-smd-accent-bright px-3 py-1 text-white">
        {suggestion?.label ?? t(translationKey, { count: 1 })}
      </span>
    </div>
  );
}

export default HighlightedSuggestionRecord;
