import { useTranslation } from 'react-i18next';
import { Plus } from 'components/icons';

function AddToCollection() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-2">
      <Plus className="h-6 w-6" />
      <span>{t('journals.collections.add-to')}</span>
    </div>
  );
}

export default AddToCollection;
