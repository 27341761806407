import { Spinner } from 'components';
import { useInfiniteScrollTrigger } from 'hooks';

function InfiniteScrollTrigger({ onFetch, isFetching, children }) {
  const { ref, infiniteScrollEnabled } = useInfiniteScrollTrigger(onFetch);

  if (infiniteScrollEnabled) {
    return (
      <>
        {!isFetching && <div ref={ref} className="-translate-y-[5rem]" />}
        <Spinner className="h-12 w-12" />
      </>
    );
  }

  return children;
}

export default InfiniteScrollTrigger;
