import { createContext, useContext } from 'react';

const UrlResolverContext = createContext();

export function UrlResolverProvider({ children, resolver }) {
  return <UrlResolverContext.Provider children={children} value={resolver} />;
}

export function useResolveUrl(...args) {
  return useContext(UrlResolverContext)?.(...args);
}
