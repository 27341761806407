import PropTypes from 'prop-types';
import { mergeAll } from 'ramda';

import { Skeleton } from 'components';
import {
  Clock,
  Document,
  Image as ImageIcon,
  Interactive,
  Play,
} from 'components/icons';

import { PictureResource } from 'components/media';
import { getFocalPosition } from 'utils';
import {
  BlurredLayer,
  blurredStyle,
  hasSensitiveTags,
} from 'components/BlurredLayer';

import asset from '../../propTypes/asset';
import withCdnResources from '../../withCdnResources';

function TypeIcon({ type }) {
  const styles = 'w-6 h-6 p-1.5';
  switch (type) {
    case 'image':
      return <ImageIcon className={styles} />;
    case 'video':
    case 'youtube':
    case 'vimeo':
      return <Play className={styles} />;
    case 'document':
      return <Document className={styles} />;
    case 'interactive':
      return <Interactive className={styles} />;
    default:
      return null;
  }
}

function VideoDuration({ duration }) {
  return (
    <div className="flex h-6 items-center px-1">
      <Clock className="h-3 w-3 stroke-2" />
      {duration && (
        <div className="mx-1 select-none text-smd-2xs italic leading-none">
          {duration}
        </div>
      )}
    </div>
  );
}

export function LibraryAssetCardSkeleton() {
  return (
    <div className="flex h-full w-full flex-col space-y-1">
      <Skeleton className="flex-auto" />
      <Skeleton.Text className="w-2/3 shrink-0 text-xs" />
      <Skeleton.Text className="shrink-0 text-smd-2xs" />
    </div>
  );
}

export function LibraryAssetCard({
  onThumbnailUnblur,
  asset: {
    type,
    name,
    credit,
    thumbnail,
    focalPoint,
    duration,
    images,
    alt,
    tags = [],
  } = {},
}) {
  const { position, isValid } = getFocalPosition(focalPoint);
  const pictureStyle = {
    objectPosition: isValid ? position : 'center',
    objectFit: isValid ? 'cover' : 'contain',
    width: '100%',
    height: '100%',
  };
  const isSensitive = hasSensitiveTags({ tags });
  return (
    <div
      className="flex h-full w-full flex-col space-y-1"
      data-testid="asset-card"
    >
      <div className="flex-center relative h-full flex-auto overflow-hidden bg-smd-gray-lightest">
        <BlurredLayer
          withDescription={false}
          isSensitive={isSensitive}
          onUnblur={onThumbnailUnblur}
        >
          {({ isBlurred }) => (
            <>
              <PictureResource
                config={{
                  url: thumbnail,
                  alt: alt || name,
                  images,
                }}
                style={
                  isBlurred
                    ? mergeAll([pictureStyle, blurredStyle])
                    : pictureStyle
                }
                loading="lazy"
                title={alt || name}
              />
              <div
                className="absolute bottom-1 left-1 bg-black bg-opacity-60 text-white"
                data-testid="type-icon"
              >
                <TypeIcon type={type} />
              </div>
              <div
                className="absolute bottom-1 right-1 bg-black bg-opacity-60 text-white"
                data-testid="duration"
              >
                {(type === 'video' ||
                  type === 'youtube' ||
                  type === 'vimeo') && <VideoDuration duration={duration} />}
              </div>
            </>
          )}
        </BlurredLayer>
      </div>
      <div
        className="empty shrink-0 truncate text-xs text-smd-main"
        data-testid="card-name"
        dangerouslySetInnerHTML={{ __html: name }}
      ></div>
      <div
        className="empty shrink-0 truncate text-smd-2xs italic text-smd-gray-dark"
        data-testid="credit-name"
      >
        {credit?.name}
      </div>
    </div>
  );
}

LibraryAssetCard.propTypes = {
  asset: asset.shape,
  onThumbnailUnblur: PropTypes.func.isRequired,
  isSensitive: PropTypes.bool.isRequired,
};

export default withCdnResources(['asset.thumbnail'], LibraryAssetCard);
