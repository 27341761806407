import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { ChatFilled, EyeFilled, UserFilled } from 'components/icons';
import { normalizeAssetUrl } from 'journals/utils';
import useFocalPointBackground from 'hooks/useFocalPointBackground';
import CommunityFollowButton from '../CommunityFollowButton';
import { useCommunityLastLocation } from './../hooks';

export default function CommunityCard({
  id,
  name,
  content,
  backgroundImage,
  totalPosterCount,
  postCount,
  topicCount,
  className,
  followed,
  onToggleFollow,
  isFollowLoading,
  ...rest
}) {
  const { t } = useTranslation();

  const style = useFocalPointBackground({
    url: normalizeAssetUrl(backgroundImage?.url),
  });
  const { pushCommunityLastLocation } = useCommunityLastLocation();

  const stats = [
    {
      Icon: UserFilled,
      label: `${totalPosterCount} ${t('communities.member', {
        count: totalPosterCount,
      })}`,
    },
    {
      Icon: ChatFilled,
      label: `${postCount} ${t('common.reply', {
        count: postCount,
      })}`,
    },
    {
      Icon: EyeFilled,
      label: `${topicCount} ${t('communities.topic', {
        count: topicCount,
      })}`,
    },
  ];

  const communityUrl = `/communities/${id}`;

  return (
    <Link
      to={communityUrl}
      onClick={() => pushCommunityLastLocation()}
      className={classnames(
        'smd-focus-visible-primary block rounded-sm',
        className
      )}
      aria-label={`${name}, ${content}`}
      {...rest}
    >
      <div className="flex-no-wrap my-3 flex w-full cursor-pointer flex-col items-center bg-white shadow-md hover:scale-1025 md:transform md:flex-row md:duration-100">
        <div
          className="h-44 w-full bg-gray-200 md:h-64 md:w-72 xl:h-52"
          style={style}
        />
        <div className="flex w-full flex-1 flex-col space-y-2 space-x-0 p-4 md:p-6 xl:flex-row xl:space-y-0 xl:space-x-2">
          <div className="flex-row self-end xl:absolute xl:top-0 xl:right-0 xl:mr-4 xl:mt-4">
            <CommunityFollowButton
              following={followed}
              isLoading={isFollowLoading}
              onClick={(e) => {
                e.preventDefault();
                onToggleFollow({ communityId: id, following: followed });
              }}
            />
          </div>
          <div className="flex-1">
            <h2 className="mb-4 text-smd-h4 font-bold text-smd-gray-darker line-clamp-2 md:text-smd-h2">
              {name}
            </h2>
            <p
              className="list-container text-smd-sm text-smd-gray-dark line-clamp-3"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="flex flex-1 flex-wrap items-center justify-evenly space-x-5 pt-4 md:justify-start xl:px-20 xl:pt-0">
            {stats.map(({ Icon, label }, index) => (
              <div
                className="flex flex-col items-center space-y-2 text-smd-gray-dark sm:flex-row sm:space-y-0 sm:space-x-2 xl:flex-grow"
                key={index}
              >
                <Icon className="w-5" />
                <span className="whitespace-nowrap text-smd-xs">{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
}

CommunityCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  totalPosterCount: PropTypes.number,
  postCount: PropTypes.number,
  topicCount: PropTypes.number,
};

CommunityCard.defaultProps = {
  totalPosterCount: 0,
  postCount: 0,
  topicCount: 0,
};
