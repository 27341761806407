import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { isFormElement } from './utils';
import FormCheckbox from './FormCheckbox';
import FormController from './FormController';
import FormInput from './FormInput';
import FormInputWrapper from './FormInputWrapper';
import FormSelect from './FormSelect';
import FormSearchDropdown from './FormSearchDropdown';
import FormInputDate from './FormInputDate';
import FormSelectSex from './FormSelectSex';
import FormTagsInput from './FormTagsInput';
import FormSelectRole from './FormSelectRole';
import FormTextArea from './FormTextArea';
import FormSuggestions from './FormSuggestions';
import FormTagsStackInput from './FormTagsStackInput';
import FormMultiSelect from './FormMultiSelect';
import FormQuillInput from './FormQuillInput';
import FormSelectUnit from './FormSelectUnit';
import FormInputDateTime from './FormInputDateTime';

//Pass react-form-hook functions and default values
export default function Form({
  defaultValues,
  resetFormOnSuccess = false,
  children,
  onSubmit,
  onFormIsDirty,
  ...props
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful, isDirty },
    watch,
    reset,
    resetField,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (resetFormOnSuccess && isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [resetFormOnSuccess, isSubmitSuccessful, defaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onFormIsDirty?.(isDirty);
    }
  }, [isDirty, onFormIsDirty]);

  const onFormSubmit = (options) => (data, event) =>
    onSubmit(data, event, options);

  return (
    <form onSubmit={handleSubmit(onFormSubmit({ setError }))} {...props}>
      {React.Children.map(children, (child) => {
        if (isFormElement(child)) {
          return React.cloneElement(child, {
            register,
            control,
            errors,
            getValues,
            defaultValues,
            setValue,
            setError,
            watch,
            resetField,
            clearErrors,
          });
        }
        return child;
      })}
    </form>
  );
}

Form.Input = FormInput;
Form.InputDate = FormInputDate;
Form.InputDateTime = FormInputDateTime;
Form.Checkbox = FormCheckbox;
Form.TextArea = FormTextArea;
Form.SelectSex = FormSelectSex;
Form.SelectUnit = FormSelectUnit;
Form.SelectRole = FormSelectRole;
Form.Select = FormSelect;
Form.SearchDropdown = FormSearchDropdown;
Form.MultiSelect = FormMultiSelect;
Form.Suggestions = FormSuggestions;
Form.Controller = FormController;
Form.InputWrapper = FormInputWrapper;
Form.TagsInput = FormTagsInput;
Form.TagsStack = FormTagsStackInput;
Form.QuillInput = FormQuillInput;
