import { useCallback, useEffect, useState } from 'react';

import { Modal } from 'components';
import { JournalsPublicApi } from 'api';
import { addGoogleAnalyticsTracking } from '../helpers';
import Viewer from '../Viewer';

const journalApi = addGoogleAnalyticsTracking({
  getJournalByKey: JournalsPublicApi.getDefinitionByKey,
  getJournalTocByKey: JournalsPublicApi.getDefinitionTocByKey,
  getJournalContainerByKey: JournalsPublicApi.getDefinitionContainerByKey,
  markJournalContainerAsVisited: () => Promise.resolve(),
});

function ModalJournalViewer({ journalKey: key, ...props }) {
  const [journalKey, setJournalKey] = useState(key);
  const [containerKey, setContainerKey] = useState();

  const navigateToJournal = useCallback(({ journalKey }) => {
    setContainerKey();
    setJournalKey(journalKey);
  }, []);

  useEffect(
    () => navigateToJournal({ journalKey: key }),
    [key, navigateToJournal]
  );

  const { isOpen, onRequestClose } = props;

  return (
    <Modal {...props} contentWrapper={null}>
      <div className="min-w-5xl mx-auto h-full max-w-5xl lg:py-8">
        {isOpen && journalKey && (
          <Viewer
            journalKey={journalKey}
            containerKey={containerKey}
            onNavigate={({ key }) => setContainerKey(key)}
            onSelectRelated={navigateToJournal}
            journalApi={journalApi}
            onClose={onRequestClose}
          />
        )}
      </div>
    </Modal>
  );
}

export default ModalJournalViewer;
