import { useCallback, useEffect, useState } from 'react';
import {
  checkWebApiContextPermission,
  writeValueToTheClipboard,
} from 'utils/navigator';

export default function useCopyToClipboard() {
  const [isSupported, setIsSupported] = useState();
  useEffect(() => {
    checkWebApiContextPermission(
      'clipboard-write',
      ({ state }) => setIsSupported(state === 'granted'),
      () => setIsSupported(true)
    );
  }, []);

  const copy = useCallback(
    (value) => {
      if (isSupported) {
        writeValueToTheClipboard(value);
      }
    },
    [isSupported]
  );

  return { copy, isSupported };
}
