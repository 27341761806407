import { useState } from 'react';
import debounce from 'lodash.debounce';
import { isEmpty } from 'ramda';

export function buildConversationPayload({
  title,
  content,
  tagsArray,
  additionalFields,
}) {
  const payload = { title, content };
  if (Array.isArray(tagsArray) && tagsArray.length > 0) {
    payload.tags = tagsArray;
  }
  if (additionalFields) {
    payload.additionalFields = additionalFields;
  }
  return payload;
}

export function useInfinitePagination(items, limit = 30) {
  const [currentPage, setCurrentPage] = useState(1);

  const showNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const paginated = items.slice(0, currentPage * limit);

  return {
    hasNextPage: paginated.length < items.length,
    totalItems: items?.length || 0,
    items: paginated,
    showNext,
  };
}

export function useDebouncedSearch(time = 500) {
  const [searchTerm, setSearchTerm] = useState('');

  const setSearchTermDebounced = debounce((value) => {
    setSearchTerm(value);
  }, time);

  const onSearchQueryChanged = (value) => {
    setSearchTermDebounced(value);
  };
  const isSearchEmpty = isEmpty(searchTerm);
  return { searchTerm, onSearchQueryChanged, isSearchEmpty };
}
