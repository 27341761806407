import PropTypes from 'prop-types';
import SelectedLibraryAttachmentCard from './SelectedLibraryAttachmentCard';

export default function SelectedLibraryAttachments({
  attachments,
  onRemoveAsset,
}) {
  return (
    <div className="flex h-full w-full max-w-[90vw] overflow-x-scroll border-t lg:flex-wrap">
      {attachments.map((asset) => (
        <SelectedLibraryAttachmentCard
          key={asset.id}
          assetType={
            asset?.parents?.[0]?.subtype ?? asset?.subtype ?? asset?.type
          }
          thumbnail={asset?.featuredAsset?.thumbnail}
          {...asset}
          onRemoveAsset={() => onRemoveAsset(asset.id)}
        />
      ))}
    </div>
  );
}

SelectedLibraryAttachments.propTypes = {
  onRemoveAsset: PropTypes.func.isRequired,
  assets: PropTypes.array,
};

SelectedLibraryAttachments.defaultProps = {
  assets: [],
};
