import { useMemo } from 'react';
import { isNil, head } from 'ramda';
import { parseCtaAction } from 'landing/helpers/helpers';
import { getAssetUrl } from 'utils';

function useParseHomePageData(data) {
  const parsedHomeData = useMemo(() => {
    if (isNil(data)) {
      return [];
    }

    let sectionData = [];

    for (const { order, sectionKey, sectionType } of data.sections) {
      switch (sectionType) {
        case 'story': {
          sectionData.push({
            data: parseStoryOfTheDaySection(head(data[sectionKey] ?? [])),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        case 'carousel': {
          sectionData.push({
            data: parseEditorsChoiceSection(head(data[sectionKey] ?? [])),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        case 'communities': {
          sectionData.push({
            data: parseCommunitiesSection(head(data[sectionKey] ?? [])),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        case 'slideshow': {
          sectionData.push({
            data: parseSlideshowSection(data[sectionKey] ?? []),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        case 'update': {
          sectionData.push({
            data: parseNewsletterSection(head(data[sectionKey] ?? [])),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        case 'expand': {
          sectionData.push({
            data: parseExpandSection(head(data[sectionKey] ?? [])),
            order,
            sectionKey,
            sectionType,
          });
          break;
        }
        default: {
          break;
        }
      }
    }

    return sectionData.filter(Boolean).sort((x, y) => x.order - y.order);
  }, [data]);

  return parsedHomeData;
}

function parseStoryOfTheDaySection(data) {
  if (!data) {
    return null;
  }

  return {
    story: {
      hashId: data.event?.id,
      title: data.event?.name,
      description: data.event?.description,
      linkText: data.event?.links?.story?.name,
      linkUrl: data.event?.links?.story?.url,
      shareText: data.event?.links?.share?.name,
      shareUrl: data.event?.links?.share?.url,
      asset: data.event?.featuredAsset,
      sponsors: [
        Boolean(data.event?.partner?.logo) ? data.event?.partner : null,
      ].filter(Boolean),
      credit: {
        name: data.event?.featuredAsset?.name,
        author: data.event?.featuredAsset?.credit?.name,
      },
    },
    journals: {
      ctaText: data.event?.links?.explore?.name,
      ctaProps:
        Boolean(data.event?.links?.explore?.url) &&
        parseCtaAction({
          'cta-internal': data.event?.links?.explore?.url,
        }),
      journals: data.event?.journals,
    },
  };
}

function parseEditorsChoiceSection(data) {
  if (!data) {
    return null;
  }

  return {
    title: data.event?.name,
    ctaText: data.event?.links?.explore?.name,
    ctaProps: parseCtaAction({
      'cta-internal': data.event?.links?.explore?.url,
    }),
    journals: data.event?.journals,
  };
}

function parseCommunitiesSection(data) {
  if (!data) {
    return null;
  }

  return {
    title: data?.['section-title'],
    description: data?.['section-description'],
    ctaText: data?.['cta-text'],
    ctaProps: parseCtaAction(data),
    communityCard: {
      id: data?.['community-id'],
      name: data?.['community-title'],
      content: data?.['community-description'],
      backgroundImage: {
        url: getAssetUrl(data?.key),
        name: data?.['community-title'],
        source: 'TheVisualMD',
      },
      postCount: data?.['community-replies'],
      topicCount: data?.['community-topics'],
      totalPosterCount: data?.['community-members'],
    },
  };
}

function parseSlideshowSection(data) {
  if (!data) {
    return null;
  }

  return data.map((slide) => ({
    key: slide.key,
    thumbnail: slide.thumbnail,
    source: 'TheVisualMD',
    type: slide.type,
    featuredAsset: slide?.featuredAsset,
    section: {
      title: slide.title,
      description: slide.text,
      ctaText: slide['cta-text'],
      ctaProps: parseCtaAction(slide),
    },
  }));
}

function parseNewsletterSection(data) {
  if (!data) {
    return null;
  }

  return {
    title: data.title,
    subTitle: data['sub-title'],
    description: data.description,
    key: getAssetUrl(data.key),
    featuredAsset: data?.featuredAsset,
    ctaText: data['cta-text'],
  };
}

function parseExpandSection(data) {
  if (!data) {
    return null;
  }

  return {
    title: data.title,
    text: data.text,
    subTitle: data['section-title'],
    description: data['section-description'],
    key: getAssetUrl(data.key),
    featuredAsset: data?.featuredAsset,
    ctaText: data['cta-text'],
    ctaInternal: data['cta-internal'],
    isOpen: Boolean(data?.isOpen === 'true'),
    version: data?.version,
  };
}

export default useParseHomePageData;
