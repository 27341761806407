export const DEFAULT_CARD_THEME = 'light';
export const DEFAULT_TOPBAR_THEME = 'dark';
export const LIGHT_URL = 'https://light_url.com/light.jpg';
export const DARK_URL = 'https://dark_url.com/dark.jpg';

export const journalsPath = Object.freeze({
  JOURNALS: 'journals',
});

export const OG_IMAGES = {
  CHANNELS: 'https://cdn.storymd.com/optimized/lqLg7LiKdX/original.jpg',
};
