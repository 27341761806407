import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCollections } from 'hooks';

import { useDefaultCollectionName } from 'journals/collections';
import { SquareButton } from 'components/forms';
import { Modal } from 'components';
import { X } from 'components/icons';
import { JournalCollectionsAllocator } from 'journals/collections/components';
import preamblePropTypes from '../propTypes/preamble';

function AddToCollectionModal({ journal, onClose }) {
  const { t } = useTranslation();

  const { collections } = useCollections();
  const journalKey = journal?.journalKey;

  const fallbackCollectionName = useDefaultCollectionName();

  const collectionNamesWithDefaultNameFallback = collections?.map(
    (collection) => {
      if (!collection.name) {
        collection.name = fallbackCollectionName;
      }
      return collection;
    }
  );

  return (
    <Modal isOpen={journal} onRequestClose={onClose}>
      <div
        className="flex min-h-screen w-screen flex-col bg-white p-4 text-black md:min-h-full md:max-w-md md:p-8"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center space-x-4">
          <h1 className="w-full text-smd-h3 font-semibold">
            {t('journals.collections.add-journal-to')}
          </h1>
          <SquareButton onClick={onClose} aria-label={t('labels.common.close')}>
            <X strokeWidth="3" />
          </SquareButton>
        </div>
        <JournalCollectionsAllocator
          journalKey={journalKey}
          collections={collectionNamesWithDefaultNameFallback}
        />
      </div>
    </Modal>
  );
}

AddToCollectionModal.propTypes = {
  journal: PropTypes.shape({
    preamble: preamblePropTypes.shape,
  }),
  onClose: PropTypes.func,
};

export default AddToCollectionModal;
