import { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import classes from './Toc.module.css';
import TocNode from './TocNode';

import useTocState from './useTocState';

function TableOfContent({
  root,
  activeId,
  secondaryActiveId,
  title,
  onSelect,
  disableNavigation,
  tracksCompletion = true,
  className,
  ...props
} = {}) {
  const {
    openIds: openIdsState,
    completeIds: completeIdsState,
    selectNode,
  } = useTocState(root, activeId);
  const hasOpenItems = activeId || secondaryActiveId;

  const completeIds = useMemo(
    () => (tracksCompletion ? completeIdsState : []),
    [tracksCompletion, completeIdsState]
  );

  const openIds = useMemo(
    () => (hasOpenItems ? openIdsState : []),
    [hasOpenItems, openIdsState]
  );

  return (
    <div className={classNames(classes.toc, className)} {...props}>
      {title ? title : null}
      <ul>
        {root.children?.map((child) => (
          <TocNode
            key={child.id}
            node={child}
            onSelect={(node) => {
              selectNode(node);
              onSelect?.(node);
            }}
            disableNavigation={disableNavigation}
            activeId={activeId}
            secondaryActiveId={secondaryActiveId}
            openIds={openIds}
            completeIds={completeIds}
          />
        ))}
      </ul>
    </div>
  );
}

const NodePropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  complete: PropTypes.func,
  completed: PropTypes.bool,
});

NodePropType.children = PropTypes.arrayOf(NodePropType);

TableOfContent.propTypes = {
  root: NodePropType,
  activeId: PropTypes.string,
  onSelect: PropTypes.func,
};

export default TableOfContent;
