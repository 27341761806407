import React, { Children, useState } from 'react';
import { compose, equals, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Menu as MenuIcon, X as CloseIcon } from 'components/icons';

const ofType = (type) => compose(equals(type), prop('type'));

const Logo = ({ children }) => children;
const Center = ({ children }) => children;
const End = ({ children }) => children;

function Menu(props) {
  const { t } = useTranslation();
  const children = Children.toArray(props.children);

  const [open, setOpen] = useState(false);

  return (
    <div className="text-smd-main">
      <div className="mx-auto flex flex-col px-4 shadow-md md:flex-row md:items-center md:justify-between md:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between p-4">
          <div className="-mt-1 md:mt-auto">
            {children.filter(ofType(Logo))}
          </div>
          <button
            onClick={() => setOpen((open) => !open)}
            className="focus:outline-none md:hidden"
            aria-label={
              open ? t('labels.common.close') : t('labels.common.menu')
            }
          >
            {open ? (
              <CloseIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
        </div>

        <nav
          className={`${
            open ? 'flex' : 'hidden'
          } flex-col self-stretch md:flex md:flex-row`}
        >
          {children.filter(ofType(Center))}
        </nav>

        <nav
          className={`${
            open ? 'flex' : 'hidden'
          } mb-2 flex-col md:mb-0 md:flex md:flex-row md:items-center`}
        >
          {children.filter(ofType(End))}
        </nav>
      </div>
    </div>
  );
}

export default Object.assign(Menu, { Logo, Center, End });
