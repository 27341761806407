import { useMutation, useQueryClient } from 'react-query';
import { CommunitiesApi } from 'api';
import { communitiesKeys } from '../keys';

export function useCommunityVoting({ communityId, conversationId }) {
  const queryClient = useQueryClient();

  const { mutate: togglePostVote } = useMutation(
    async ({ postId, isUpVoted }) => {
      if (isUpVoted) {
        return CommunitiesApi.downVotePost(postId).then(() => ({ postId }));
      }
      return CommunitiesApi.upVotePost(postId).then(() => ({ postId }));
    },
    {
      onMutate: async ({ postId, isUpVoted: shouldDownvote }) => {
        const queryKeys = communitiesKeys.postsByConversationId(
          communityId,
          conversationId
        );
        await queryClient.cancelQueries(queryKeys);
        const previousState = queryClient.getQueryData(queryKeys);
        queryClient.setQueryData(queryKeys, ({ pages, pageParams }) => {
          const updatedPages = pages.map(({ items, ...rest }) => {
            const updatedItems = items.map((item) => {
              if (item.postId === postId) {
                return {
                  ...item,
                  upVoted: !shouldDownvote,
                  upVotes: shouldDownvote ? --item.upVotes : ++item.upVotes,
                };
              }
              return item;
            });
            return {
              items: updatedItems,
              ...rest,
            };
          });
          return { pageParams, pages: updatedPages };
        });
        return { previousState };
      },
      onError: console.error,
      onSettled: () => {
        queryClient.invalidateQueries(
          communitiesKeys.postsByConversationId(communityId, conversationId)
        );
      },
    }
  );

  const { mutate: toggleConversationVote } = useMutation(
    async ({ postId, isUpVoted }) => {
      if (isUpVoted) {
        return CommunitiesApi.downVotePost(postId).then(() => ({ postId }));
      }
      return CommunitiesApi.upVotePost(postId).then(() => ({ postId }));
    },
    {
      onMutate: async ({ postId, isUpVoted: shouldDownvote }) => {
        await queryClient.cancelQueries(
          communitiesKeys.conversationSummaryById(communityId, conversationId)
        );
        const previousState = queryClient.getQueryData([
          communitiesKeys.conversationSummaryById(communityId, conversationId),
        ]);
        queryClient.setQueryData(
          communitiesKeys.conversationSummaryById(communityId, conversationId),
          (oldConversation) => {
            const updatedPosts = oldConversation?.posts?.map((oldPost) => {
              if (oldPost.postId === postId) {
                return {
                  ...oldPost,
                  upVoted: !shouldDownvote,
                  upVotes: shouldDownvote
                    ? --oldPost.upVotes
                    : ++oldPost.upVotes,
                };
              }
              return oldPost;
            });

            return { ...oldConversation, posts: updatedPosts };
          }
        );
        return { previousState };
      },
      onError: console.error,
      onSettled: () => {
        queryClient.invalidateQueries(
          communitiesKeys.conversationSummaryById(communityId, conversationId)
        );
      },
    }
  );

  const { mutate: toggleConversationListVote } = useMutation(
    async ({ mainPostId, isUpVoted }) => {
      if (isUpVoted) {
        return CommunitiesApi.downVotePost(mainPostId).then(() => ({
          mainPostId,
        }));
      }
      return CommunitiesApi.upVotePost(mainPostId).then(() => ({ mainPostId }));
    },
    {
      onMutate: ({ mainPostId, isUpVoted: shouldDownvote }) => {
        queryClient.setQueryData(
          communitiesKeys.conversationsByCommunityId(communityId),
          ({ pages, pageParams }) => {
            const updatedPages = pages.map(({ items, ...rest }) => {
              const updatedItems = items.map((item) => {
                if (item?.teaser?.postId === mainPostId) {
                  return {
                    ...item,
                    upVoted: !shouldDownvote,
                    upVotes: shouldDownvote ? --item.upVotes : ++item.upVotes,
                  };
                }
                return item;
              });
              return {
                items: updatedItems,
                ...rest,
              };
            });
            return { pageParams, pages: updatedPages };
          }
        );
      },
      onError: (error) => {
        console.error(error);
        queryClient.invalidateQueries(
          communitiesKeys.conversationsByCommunityId(communityId)
        );
      },
    }
  );
  return { togglePostVote, toggleConversationVote, toggleConversationListVote };
}
