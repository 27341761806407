import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  // logo: PropTypes.shape({
  //   url: PropTypes.string.isRequired,
  //   focalPoint: PropTypes.shape({
  //     x: PropTypes.number.isRequired,
  //     y: PropTypes.number.isRequired,
  //   }),
  // })
});

const defaults = {};

export default {
  shape,
  defaults,
};
