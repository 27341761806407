import classnames from 'classnames';
import { Search } from 'components/forms';

function SearchBarSection({
  onChange,
  placeholder = '',
  className,
  withClearButton,
  children,
  label,
}) {
  return (
    <div
      className={classnames(
        'relative flex w-full flex-col items-start justify-start space-y-2 md:flex-row md:items-center md:space-y-0',
        className
      )}
    >
      <div className="flex w-full md:flex-1">
        <Search
          className="h-9 w-full md:max-w-sm"
          placeholder={placeholder}
          onChange={onChange}
          withClearButton={withClearButton}
          label={label}
        />
      </div>
      <div>{children}</div>
    </div>
  );
}

export default SearchBarSection;
