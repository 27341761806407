import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import './DropMenu.css';

import { Checkbox } from 'components/forms';
import { cn } from '@/lib/utils';
import { ChevronDown } from '@/components/icons';

function DropMenu({ menuButton, children, ...rest }) {
  return (
    <Menu
      menuButton={menuButton}
      arrow={false}
      boundingBoxPadding="1rem"
      direction="left"
      viewScroll="close"
      initialMounted={false}
      unmountOnClose
      menuClassName="smd-drop-menu"
      {...rest}
    >
      {children}
    </Menu>
  );
}

function DropMenuItem(props) {
  return <MenuItem className="smd-drop-menu-item" {...props} />;
}

const minAccordionItems = 4;

function DropMenuAccordion({ businessIds, setBusinessIds, ...props }) {
  const { source } = props;
  const { t } = useTranslation();
  const sourceCheckboxRef = useRef();
  const [isAccordionOpen, setIsAccordionOpen] = useState(
    businessIds.length < minAccordionItems
  );

  const indeterminate = useMemo(
    () =>
      source.selected ? false : businessIds.some(({ selected }) => selected),
    [businessIds, source.selected]
  );

  useEffect(() => {
    sourceCheckboxRef.current.indeterminate = indeterminate;
  }, [sourceCheckboxRef, indeterminate]);

  const showAccordion = businessIds.length >= minAccordionItems;

  function toggleAccordion() {
    setIsAccordionOpen((prev) => !prev);
  }

  return (
    <>
      <div className="flex flex-row flex-nowrap">
        <MenuItem className="smd-drop-menu-item grow" {...props}>
          <label className="flex cursor-pointer flex-row flex-nowrap items-center gap-x-2">
            <Checkbox
              checked={source.selected || false}
              ref={sourceCheckboxRef}
            />
            <span>{t(source.label)}</span>
          </label>
        </MenuItem>

        <div
          className={cn(
            'flex size-11 cursor-pointer items-center justify-around',
            !showAccordion && 'hidden'
          )}
          onClick={toggleAccordion}
        >
          <ChevronDown
            strokeWidth="2"
            className={cn(
              'pointer-events-none ml-1 size-11 h-5 w-5 transform text-smd-main transition-transform duration-200',
              { 'rotate-180': isAccordionOpen }
            )}
          />
        </div>
      </div>

      <div className={cn(showAccordion && !isAccordionOpen && 'hidden')}>
        {businessIds.map((sourceOption) => (
          <MenuItem
            key={sourceOption.value}
            className="smd-drop-menu-item"
            onClick={(e) => {
              e.stopPropagation = true;
              setBusinessIds(sourceOption.value, sourceOption.type);
            }}
          >
            <label className="flex cursor-pointer flex-row flex-nowrap items-center gap-x-2 pl-6">
              <Checkbox
                className="cursor-pointer"
                checked={sourceOption.selected || false}
                onChange={() => {}}
              />
              <span className="line-clamp-2 max-w-[16.875rem]">
                {sourceOption.name}
              </span>
            </label>
          </MenuItem>
        ))}
      </div>
    </>
  );
}

DropMenu.Button = MenuButton;
DropMenu.Item = DropMenuItem;
DropMenu.Accordion = DropMenuAccordion;

export default DropMenu;
