import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function usePrintContent() {
  const printContainerRef = useRef();
  const printContentRef = useRef();

  const print = () => {
    const content = printContainerRef.current;
    const pri = printContentRef.current.contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const printContainerProps = {
    ref: printContainerRef,
    iframeProps: {
      ref: printContentRef,
    },
  };

  const PrintContainerWithProps = (props) => (
    <PrintContainer {...printContainerProps} {...props} />
  );

  return {
    print,
    PrintContainer: PrintContainerWithProps,
  };
}

const PrintContainer = forwardRef(({ children, iframeProps, ...rest }, ref) => {
  const { t } = useTranslation();
  return (
    <>
      <iframe
        title={iframeProps.title ?? t('labels.common.print-container')}
        className="absolute h-0 w-0"
        {...iframeProps}
      />
      <div {...rest} ref={ref}>
        {children}
      </div>
    </>
  );
});

export default usePrintContent;
