import { forwardRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function withFallbackImage(Image) {
  const Wrapper = forwardRef(function Wrapper(
    { fallbackSrc, src = '', onError, ...props },
    ref
  ) {
    const [failed, setFailed] = useState(false);

    useEffect(() => setFailed(false), [src]);

    const handleOnError = useCallback(
      (...args) => {
        setFailed(true);
        onError?.(...args);
      },
      [onError]
    );

    return (
      <Image
        ref={ref}
        src={failed ? fallbackSrc ?? src : src}
        onError={handleOnError}
        {...props}
      />
    );
  });

  Wrapper.propTypes = {
    fallbackSrc: PropTypes.string,
  };

  Wrapper.defaultProps = {
    fallbackSrc: '/images/pages.png',
  };

  return Wrapper;
}

export default withFallbackImage;
