import { ErrorBoundary } from 'react-error-boundary';

import libraryAssetPropTypes from 'library/propTypes/asset';

import { useLibraryAssetRoutedModal } from 'library/hooks';
import AssetContainer from '../AssetContainer';
import InteractiveAsset from '../LibraryInteractive/InteractiveAsset';
import DocumentAsset from './DocumentAsset';
import ImageAsset from './ImageAsset';
import VideoAsset from './VideoAsset';

const assetTypeMap = {
  document: {
    assetComponent: DocumentAsset,
    canExpand: true,
  },
  image: {
    assetComponent: ImageAsset,
    canExpand: true,
  },
  video: {
    assetComponent: VideoAsset,
    canExpand: false,
  },
  interactive: {
    assetComponent: InteractiveAsset,
    canExpand: true,
  },
};

export const assetTypes = Object.keys(assetTypeMap);

export default function LibraryAsset({ config }) {
  const { openLibraryAssetModal } = useLibraryAssetRoutedModal();

  const { type } = config;

  const { assetComponent: AssetType, canExpand } = assetTypeMap[type];

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <AssetContainer
        config={config}
        onExpandClick={canExpand ? () => openLibraryAssetModal(config) : null}
        showShare
      >
        <div className="h-full overflow-hidden">
          <AssetType config={config} />
        </div>
      </AssetContainer>
    </ErrorBoundary>
  );
}

LibraryAsset.propTypes = {
  config: libraryAssetPropTypes.shape,
};
