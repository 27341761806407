import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { mergeRefs } from 'utils';

import { useContentOverflowScroll, useMouseDrag } from 'hooks';
import { ChevronLeft, ChevronRight, Feature, X } from 'components/icons';
import NoScrollContainer from 'components/NoScrollContainer';
import { useJourneyLastLocation } from 'journals/hooks';

function findActiveTagStart(tagRefs, tagKey) {
  if (Boolean(tagRefs.current.length === 0)) return;
  const activeTagRef = tagRefs.current?.find((tag) => tag.id === tagKey);
  return activeTagRef?.offsetLeft;
}

const navClasses = 'absolute inset-0 w-10 h-full from-white via-white z-10';

function InitiativesTags({
  tags,
  activeTag,
  allTagsAsActive,
  hideLabel,
  onChange,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const tagRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !tagRefs.current.includes(el)) {
      tagRefs.current.push(el);
    }
  };

  const { dragContainerRef } = useMouseDrag();

  const { journeyLastLocation, setJourneyLastLocation } =
    useJourneyLastLocation();
  const previousJourneys = journeyLastLocation?.pathname?.includes('journeys');

  const {
    containerRef,
    isBeginning,
    isEnd,
    scrollTo,
    scrollLeft,
    scrollRight,
  } = useContentOverflowScroll([tags, activeTag], {
    scrollContainerSafeZoneMultiplier: 0.1,
    smoothScrollCss: false,
  });

  useEffect(() => {
    scrollTo(findActiveTagStart(tagRefs, activeTag));
  }, [activeTag, scrollTo]);

  if (!tags) {
    return null;
  }

  if (!Array.isArray(tags)) {
    throw new Error('tags prop is not array');
  }

  return (
    <div
      data-testid="initiatives-tags"
      className="isolate flex w-full flex-col space-y-2 lg:inline-flex lg:w-auto lg:flex-row lg:items-center lg:space-x-4 lg:space-y-0"
    >
      {!hideLabel && (
        <div
          data-testid="initiatives-label"
          className="inline-flex shrink-0 items-center space-x-1 px-4 lg:px-0"
        >
          <Feature className="w-4" />
          <div className="font-semibold italic">
            {t('library.search.journeys')}
          </div>
        </div>
      )}
      <div className="relative grid grow">
        {!isBeginning && (
          <button
            className={classnames(
              navClasses,
              'right-auto bg-gradient-to-r pr-4'
            )}
            onClick={scrollLeft}
            type="button"
            aria-label={t('labels.carousel.prev')}
            tabIndex={-1}
          >
            <ChevronLeft className="h-full w-6" />
          </button>
        )}
        {!isEnd && (
          <button
            className={classnames(
              navClasses,
              'left-auto bg-gradient-to-l pl-4'
            )}
            onClick={scrollRight}
            type="button"
            aria-label={t('labels.carousel.next')}
            tabIndex={-1}
          >
            <ChevronRight className="h-full w-6" />
          </button>
        )}
        {/* Due to the usage of mouse drag with the initiatives, removed the smooth scrolling */}
        {/* snap-x snap-mandatory scroll-smooth */}
        <NoScrollContainer
          className="flex scroll-px-14 items-center py-1 px-4 lg:px-0"
          ref={mergeRefs([containerRef, dragContainerRef])}
        >
          {tags.map((tag) => {
            const isActive = allTagsAsActive ?? activeTag === tag.tag;
            const handleClick = () => {
              setJourneyLastLocation();
              if (isActive && previousJourneys) {
                history.goBack();
                return;
              }
              onChange?.(!isActive || allTagsAsActive ? tag : undefined);
            };
            return (
              <div className="pr-2 last:pr-0.5" key={tag.tag}>
                <button
                  className={classnames(
                    'relative cursor-pointer select-none snap-start whitespace-nowrap rounded-full border py-1 px-8 text-smd-sm focus-visible:ring-offset-0',
                    isActive
                      ? 'smd-focus-visible-secondary border-smd-accent bg-smd-accent text-white'
                      : 'smd-focus-visible-primary bg-white text-smd-gray-darker '
                  )}
                  type="button"
                  aria-label={t('labels.channels.go-to-initiative', {
                    initiative: tag.name,
                  })}
                  data-active={isActive}
                  id={tag.tag}
                  onClick={handleClick}
                  ref={addToRefs}
                >
                  {tag.name}
                  {isActive && (
                    <X className="absolute right-2 top-1 w-5 stroke-2" />
                  )}
                </button>
              </div>
            );
          })}
        </NoScrollContainer>
      </div>
    </div>
  );
}

InitiativesTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  activeTag: PropTypes.string,
  hideLabel: PropTypes.bool,
  allTagsAsActive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InitiativesTags;
