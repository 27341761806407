import { useCardSize } from 'hooks';
import { extractWebpThumbnail } from 'utils';
import { normalizeAssetUrl } from '../utils';

import preamblePropTypes from '../propTypes/preamble';
import { Card } from '../../components';

function JournalCard({ preamble, ...rest }) {
  const { imgHeight, bodyHeight } = useCardSize();

  const props = {
    heading: preamble?.title,
    content: preamble?.about,
    logos: preamble?.logos,
    theme: preamble?.theme,
    imgStyle: {
      height: imgHeight,
    },
    bodyStyle: {
      height: bodyHeight,
    },
  };

  if (preamble?.heroes?.length) {
    const hero = preamble.heroes[0];

    const webpThumbnail = extractWebpThumbnail(hero?.images);

    props.cover = {
      url:
        normalizeAssetUrl(webpThumbnail?.path) ||
        normalizeAssetUrl(hero?.thumbnailUrl) ||
        normalizeAssetUrl(hero?.url),
      alt: hero.alt,
      name: hero.label || hero.name,
      credit: hero.credit?.name,
      focalPoint: hero.focalPoint,
      tags: hero.tags,
      images: hero.images?.filter(({ path }) => path.search('thumbnail') >= 0), // fetch only thumbnails
    };
  }

  return (
    <Card
      preamble={preamble}
      {...props}
      {...rest}
      aria-label={`${preamble?.title}, ${preamble?.about}`}
    />
  );
}

JournalCard.propTypes = {
  preamble: preamblePropTypes.shape.isRequired,
};

JournalCard.defaultProps = {
  preamble: preamblePropTypes.defaults,
};

export default JournalCard;
