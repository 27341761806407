import { useTranslation } from 'react-i18next';

import { Brain, Share } from 'components/icons';
import ViewerPageTopBar from './ViewerPageTopBar';

function ViewerPageContainer({ label, onShareClick, children }) {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <ViewerPageTopBar
        left={
          <>
            <Brain className="w-5" />
            <h2 className="text-sm font-semibold sm:text-base">{label}</h2>
          </>
        }
        right={
          onShareClick && (
            <button
              className="smd-focus-visible-secondary w-5 rounded-sm"
              onClick={onShareClick}
              aria-label={t('labels.common.share')}
            >
              <Share />
            </button>
          )
        }
      />
      {children}
    </div>
  );
}

export default ViewerPageContainer;
