import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { find, propEq } from 'ramda';

function ShareSocial({ socials, url, className }) {
  const { color: twitterColor, icon: Twitter } = find(
    propEq('type', 'twitter')
  )(socials);
  const { color: facebookColor, icon: Facebook } = find(
    propEq('type', 'facebook')
  )(socials);

  return (
    <div
      className={classnames('flex w-full justify-start space-x-4', className)}
    >
      <FacebookShareButton
        data-testid="fb-share-btn"
        url={url}
        style={{ color: facebookColor }}
      >
        <Facebook data-testid="fb-icon" className="h-10 w-10 fill-current" />
      </FacebookShareButton>
      <TwitterShareButton
        data-testid="twitter-share-btn"
        url={url}
        style={{ color: twitterColor }}
      >
        <Twitter
          data-testid="twitter-icon"
          className="h-10 w-10 fill-current"
        />
      </TwitterShareButton>
    </div>
  );
}

ShareSocial.propTypes = {
  socials: PropTypes.arrayOf(
    PropTypes.shape({ color: PropTypes.string, icon: PropTypes.elementType })
  ).isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShareSocial;
