import { A11y, Keyboard, Virtual } from 'swiper';

const mainProperties = {
  keyboard: true,
  noSwipingClass: 'prevent-swiper',
  loop: false,
};

export const carouselBaseConfig = {
  ...mainProperties,
  modules: [Keyboard, A11y],
  observer: true,
  autoHeight: true,
  observeParents: true,
};

export const virtualCarouselConfig = {
  ...mainProperties,
  modules: [Virtual, Keyboard, A11y],
  virtual: true,
};
