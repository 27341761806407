import axios from './axios';

const AUTHOR_DRAFTS_ENDPOINT = '/definitions/editor/drafts';
const AUTHOR_PUBLISHED_ENDPOINT = '/definitions/editor/published';

const parsePayload = (payload) => ({
  journalDefinition:
    typeof payload === 'object' ? JSON.stringify(payload) : payload,
});

export default {
  getAllDraftsInProgress() {
    return axios.get(AUTHOR_DRAFTS_ENDPOINT).then(({ data }) => data);
  },
  createDraft(draft) {
    return axios
      .post(AUTHOR_DRAFTS_ENDPOINT, parsePayload(draft))
      .then(({ data }) => data);
  },
  getDraftByJournalKey(key) {
    return axios
      .get(`${AUTHOR_DRAFTS_ENDPOINT}/search`, {
        params: {
          journalKey: key,
        },
      })
      .then(({ data }) => data);
  },
  getDraftById(id) {
    return axios
      .get(`${AUTHOR_DRAFTS_ENDPOINT}/${id}`)
      .then(({ data }) => data);
  },
  updateDraft(id, patch) {
    return axios
      .put(`${AUTHOR_DRAFTS_ENDPOINT}/${id}`, parsePayload(patch))
      .then(({ data }) => data);
  },
  deleteDraft(id) {
    return axios
      .delete(`${AUTHOR_DRAFTS_ENDPOINT}/${id}`)
      .then(({ data }) => data);
  },
  getAllPublishedJournals() {
    return axios.get(AUTHOR_PUBLISHED_ENDPOINT).then(({ data }) => data);
  },
  publish(journal) {
    return axios
      .post(AUTHOR_PUBLISHED_ENDPOINT, journal)
      .then(({ data }) => data);
  },
  deletePublishedJournal(id) {
    return axios
      .delete(`${AUTHOR_PUBLISHED_ENDPOINT}/${id}`)
      .then(({ data }) => data);
  },
};
