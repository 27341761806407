import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'components/navigation';

// TODO: refactor to use hierarchical query keys

function TabsNavigation(props) {
  const { t } = useTranslation();

  const tabRoutes = useMemo(
    () =>
      [
        {
          key: '/journals/collections',
          label: (
            <TabEntry
              iconClassName="stroke-2"
              testId="myCollectionsSubTab"
              label={t('journals.collections.my-collections')}
            />
          ),
        },
        {
          key: '/journals/authored',
          label: (
            <TabEntry
              iconClassName="stroke-2"
              testId="authoredSubTab"
              label={t('journals.collections.storymd-author')}
            />
          ),
        },
        // {
        //   key: '/journals/history',
        //   label: (
        //     <TabEntry
        //       iconClassName="stroke-2"
        //       testId="historySubTab"
        //       label={t('common.history')}
        //     />
        //   ),
        // },
      ].filter((v) => v),
    [t]
  );

  return (
    <Tabs.Router
      tabs={tabRoutes}
      fullWidth
      className="mb-8"
      tabClassName="text-smd-base py-4 px-6"
      {...props}
    />
  );
}

function TabEntry({ label, testId }) {
  return (
    <div className="flex items-center space-x-4" data-testid={testId}>
      <span>{label}</span>
    </div>
  );
}

export default TabsNavigation;
