import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useContentOverflowScroll, useMedia, useMouseDrag } from 'hooks';
import NoScrollContainer from 'components/NoScrollContainer';
import { mergeRefs } from 'utils';

const navClasses =
  'absolute top-2 inset-0 w-12 h-full from-white via-white z-10';

function JourneysNavBar({ journeys, featured, onClick }) {
  const { t } = useTranslation();
  const isMedium = useMedia(useMedia.MEDIUM);

  const { dragContainerRef } = useMouseDrag();

  const groupedSectionLabels = Object.keys(journeys);
  const allSectionsLabels = useMemo(
    () => [t('journals.journeys.title'), ...groupedSectionLabels],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const typeRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !typeRefs.current.includes(el)) {
      typeRefs.current.push(el);
    }
  };

  const navigationData = [
    {
      label: (
        <div className="flex shrink-0 items-center space-x-2 border-r-4 pr-4 font-bold text-smd-accent md:border-0">
          <span>{t('journals.journeys.title')}</span>
          {isMedium ? <ChevronRight className="h-5 w-5" /> : null}
        </div>
      ),
      onClick: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
    ...groupedSectionLabels
      .sort((a, b) => a.localeCompare(b))
      .map((x) => ({
        label: x,
        onClick: () => {
          onClick(x);
        },
        type: x,
      })),
  ];

  const { containerRef, isBeginning, isEnd, scrollLeft, scrollRight } =
    useContentOverflowScroll([allSectionsLabels]);

  if (!allSectionsLabels) {
    return null;
  }

  return (
    <div className="sticky top-10 z-[25] flex h-20 items-center space-x-8 overflow-hidden border-b bg-white pt-4 font-semibold text-smd-gray-dark md:px-12">
      {!isBeginning && (
        <button
          className={classnames(navClasses, 'right-auto bg-gradient-to-r pr-4')}
          onClick={scrollLeft}
          type="button"
          aria-label={t('labels.carousel.prev')}
          tabIndex={-1}
        >
          <ChevronLeft className="h-full w-6" />
        </button>
      )}
      {!isEnd && (
        <button
          className={classnames(navClasses, 'left-auto bg-gradient-to-l pl-4')}
          onClick={scrollRight}
          type="button"
          aria-label={t('labels.carousel.next')}
          tabIndex={-1}
        >
          <ChevronRight className="h-full w-6" />
        </button>
      )}
      <NoScrollContainer
        className="flex scroll-px-16 items-center space-x-8 py-1"
        ref={mergeRefs([containerRef, dragContainerRef])}
      >
        {navigationData
          .filter((x) => x)
          .map((item, index) => {
            return (
              <button
                key={index}
                onClick={item.onClick}
                ref={addToRefs}
                className="shrink-0 snap-start"
                id={item.label}
              >
                {item.label}
              </button>
            );
          })}
      </NoScrollContainer>
    </div>
  );
}

export default JourneysNavBar;
