import { ContentContainer, CTA } from 'components';
import { getAssetUrl } from 'utils';

function GetStartedSection({ content }) {
  return (
    <section
      className="relative py-smd-3xl text-white md:py-32"
      style={{
        backgroundImage: `url(${getAssetUrl(content?.key)})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
      }}
    >
      <div className="absolute inset-0 z-10 h-full w-full bg-black opacity-70" />
      <ContentContainer className="z-20 px-4 text-center">
        <h1 className="mb-smd-xl text-center text-smd-h3 font-semibold md:text-smd-h1">
          {content?.title}
        </h1>
        <p className="mx-auto mb-smd-xxl text-smd-base leading-relaxed md:max-w-3xl md:text-smd-h4">
          {content?.text}
        </p>
        <CTA
          color="primary"
          ctaProps={content?.ctaProps}
          className="font-semibold"
        >
          {content?.ctaText}
        </CTA>
      </ContentContainer>
    </section>
  );
}

export default GetStartedSection;
