import { useState } from 'react';
import { useClick, useHover } from '@szhsin/react-menu';

const HOOVER_CLOSE_DELAY = 300;

const HOOVER_OPEN_DELAY = HOOVER_CLOSE_DELAY - 20; //open little before close to prevent blinking when moving fast from menu to menu
export const constantas = { HOOVER_CLOSE_DELAY, HOOVER_OPEN_DELAY };
export function useJourneysHover() {
  const [isJourneyOpen, setJourneyOpen] = useState(false);
  const {
    anchorProps: hoverJourneyAnchorProps,
    hoverProps: hoverJourneyProps,
  } = useHover(isJourneyOpen, setJourneyOpen, {
    openDelay: HOOVER_OPEN_DELAY,
    closeDelay: HOOVER_CLOSE_DELAY,
  });
  const anchorProps = useClick(isJourneyOpen, setJourneyOpen);

  return {
    isJourneyOpen,
    setJourneyOpen,
    anchorProps,
    hoverJourneyAnchorProps,
    hoverJourneyProps,
  };
}

export function useDelayedTaskWithCommonReset(
  onClose,
  delay = HOOVER_CLOSE_DELAY
) {
  const [timers, setCloseTimers] = useState({});
  function resetTimer() {
    Object.keys(timers)?.map(clearTimeout);
    setCloseTimers({});
  }

  const delayTask = () => {
    const timer = setTimeout(() => {
      onClose();
    }, delay);
    setCloseTimers((x) => {
      x[timer] = true;
      return x;
    });
  };
  return { delayTask, resetTimer };
}
