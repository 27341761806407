import { useTranslation } from 'react-i18next';
import { PrivatePageGuestView } from 'components';

import { useHelpCenterResources } from 'hooks';

function PublicJournals() {
  const { data } = useHelpCenterResources();

  const video = data?.introduction[0];
  const { t } = useTranslation();
  const title = t('journals.public-journals.title');
  const text = t('journals.public-journals.text');
  const buttonText = t('journals.public-journals.button');
  return (
    <PrivatePageGuestView
      title={title}
      text={text}
      buttonText={buttonText}
      video={video}
      testId="startAddingHealthJournals"
    />
  );
}

export default PublicJournals;
