import PropTypes from 'prop-types';

import partnerPropTypes from './partner';

const shape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.string,
  // cover: PropTypes.shape({
  //   url: PropTypes.string.isRequired,
  //   focalPoint: PropTypes.shape({
  //     x: PropTypes.number.isRequired,
  //     y: PropTypes.number.isRequired,
  //   }),
  // })
  partner: partnerPropTypes.shape.isRequired,
  subscribed: PropTypes.bool,
});

const defaults = {};

export default {
  shape,
  defaults,
};
