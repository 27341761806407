import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  date: PropTypes.string,
});

const defaults = {};

export default {
  shape,
  defaults,
};
