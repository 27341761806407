import { lazy, Suspense } from 'react';
import { registerFormElement } from 'components/forms/Form';

const QuillInput = lazy(() => import('./QuillInput'));

function QuillInputLazy(props) {
  return (
    <Suspense fallback={'...'}>
      <QuillInput {...props} />
    </Suspense>
  );
}

registerFormElement(QuillInputLazy);

export default QuillInputLazy;
