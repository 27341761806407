import { useState } from 'react';
import Autosuggest from 'react-autosuggest';

import { includes, prop } from 'ramda';
import { Form, Label } from 'components/forms';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Plus } from 'components/icons';
import { useManualInputDefaultProps } from '../hooks';
import theme from './TypeaheadInput.module.css';

function Option({ label, addsNew, value }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'flex items-center space-x-2 p-2 hover:cursor-pointer hover:bg-smd-gray-lightest',
        {
          'text-smd-accent': addsNew,
        }
      )}
    >
      {addsNew ? (
        <>
          <Plus className="pointer-events-none h-4 w-4 shrink-0 rounded-full bg-smd-accent text-white" />
          <span className="truncate">
            {t('my-phr.manual-inputs.add-custom-entry', { value })}
          </span>
        </>
      ) : (
        <span className="pointer-events-none truncate">{label}</span>
      )}
    </div>
  );
}

export default function TypeaheadInput({
  options = [],
  groupProps,
  groupClassName,
  setValue: setFormFieldValue,
  id,
  labelProps,
  as,
  ...rest
}) {
  const { t } = useTranslation();

  const newProps = useManualInputDefaultProps(rest);

  const defaultValue = newProps?.defaultValues?.[newProps?.name] || '';

  const [value, setValue] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);

  const inputProps = {
    placeholder: t('my-phr.manual-inputs.select-type-strength'),
    value,
    onChange: (_, { newValue }) => {
      setValue(newValue);
    },
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.target.blur();
        const selectedSuggestion = suggestions.find(
          (suggestion) => suggestion.label === value
        );
        if (selectedSuggestion) {
          setFormFieldValue(newProps?.name, selectedSuggestion.value);
        } else {
          setFormFieldValue(newProps?.name, value);
        }
      }
    },
    className:
      'w-full leading-normal smd-input-focus-primary border-smd-gray-light placeholder:text-smd-tertiary border-smd focus:border-smd-accent',
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const matches = options?.filter?.((record) =>
      includes(value?.toLowerCase(), record?.label?.toLowerCase())
    );

    const addNewOption = {
      label: value,
      addsNew: true,
      value,
    };

    const isExactMatch = matches.some((entry) => entry?.label === value);

    if (Boolean(value?.length) && !isExactMatch) {
      matches.push(addNewOption);
    }

    setSuggestions(matches);
  };

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(_, { suggestionValue }) {
    setFormFieldValue(`${newProps?.name}`, suggestionValue);
  }

  return (
    <Form.Controller
      {...newProps}
      render={() => {
        return (
          <>
            {newProps.label ? (
              <Label htmlFor={id} {...labelProps}>
                {newProps.label}
              </Label>
            ) : (
              <></>
            )}
            <Autosuggest
              theme={theme}
              suggestions={suggestions}
              onSuggestionSelected={onSuggestionSelected}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={prop('label')}
              renderSuggestion={(props) => <Option {...props} />}
              shouldRenderSuggestions={() => true}
              inputProps={inputProps}
            />
          </>
        );
      }}
    />
  );
}
