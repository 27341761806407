import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { concat, pipe, pluck, prop, reduce, uniqBy } from 'ramda';
import classnames from 'classnames';

import { useAuth } from 'Auth';
import CHANNELS from 'hooks/channels';
import { useIsEmbedded, useMedia } from 'hooks';
import {
  Avatar,
  ContentContainer,
  CTA,
  Spinner,
  Tag,
  Topbar,
} from 'components';
import { useScroll } from 'components/Scroll';
import { Search } from 'components/forms';
import { FocalPointBackground } from 'components/media';
import { X as Close } from 'components/icons';
import { SEOMeta } from 'components/SEO';
import { getJournalSlugPath } from '../utils';
import {
  useChannelLastLocation,
  useJournalsCategoryFiltering,
  useJournalsFiltering,
} from '../hooks';
import { AddToCollectionModal, SmartJournalCard } from '../components';
import { AddToCollection } from '../components/actions';
import { NoSearchResults } from '../components';
import { CategoryView, GridView, ViewSelector } from '../history/components';
import SubscriptionButton from './SubscriptionButton';
import ChannelContactInfo from './ChannelContactInfo';

export default function RouterChannelView() {
  const { id } = useParams();
  const { authenticated } = useAuth();

  const { channel, isLoading, channelNotFound } = CHANNELS.useChannelView(id);
  const { subscriptionsById } = CHANNELS.useChannelsSubscriptions({
    enabled: Boolean(authenticated),
  });

  if (channelNotFound) {
    return <Redirect to="/404" />;
  }

  if (isLoading) {
    return (
      <div className="flex-center h-80 grow">
        <Spinner className="h-16 w-16" />
      </div>
    );
  }
  return (
    <>
      <ChannelMeta channel={channel} />
      <ChannelView channel={channel} subscriptionIds={subscriptionsById} />
    </>
  );
}

function ChannelMeta({ channel }) {
  return (
    <SEOMeta
      values={{
        title: channel?.name,
        image: channel?.avatar,
        description: channel?.description,
      }}
    />
  );
}

function ChannelStickyBar({ title }) {
  const { t } = useTranslation();
  const isEmbedded = useIsEmbedded();
  const { isScrolled } = useScroll();

  const history = useHistory();

  const { popChannelLastLocation } = useChannelLastLocation({
    onEvent: ({ method, lastLocation }) => {
      if (method === 'pop') {
        history.push(lastLocation);
      }
    },
  });

  return (
    <div
      className={classnames(
        'fixed top-0 z-30 w-full shrink-0',
        !isEmbedded &&
          `lg:sticky ${
            isScrolled
              ? 'lg:top-smd-sticky-spacer-small'
              : 'lg:top-smd-sticky-spacer-large'
          }`
      )}
    >
      <Topbar
        left={
          <div className="flex flex-grow flex-row-reverse items-center lg:flex-row">
            {!isEmbedded && (
              <Topbar.IconButton
                icon={Close}
                label={t('common.close')}
                className="flex-row"
                onClick={() => popChannelLastLocation()}
              />
            )}

            <div className="flex-grow">
              <Topbar.Heading>{title}</Topbar.Heading>
            </div>
          </div>
        }
      />
    </div>
  );
}

ChannelStickyBar.propTypes = {
  title: PropTypes.string.isRequired,
};

function TopBar({ onSearch }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-center relative py-smd-base lg:px-smd-xxl lg:py-smd-lg">
        <Search
          className="h-9 w-full max-w-sm"
          placeholder={t('journals.channels.search-healthjournals')}
          onChange={onSearch}
          label={t('labels.common.search-button', {
            type: t('common.healthjournal_other'),
          })}
        />
      </div>
    </>
  );
}

const flattenJournalCategories = pipe(
  pluck('journals'),
  reduce(concat, []),
  uniqBy(prop('id'))
);

export function ChannelView({ channel, subscriptionIds = [] }) {
  const { t } = useTranslation();

  const isLarge = useMedia(useMedia.LARGE);
  const hasOneCollectionOnly = Boolean(channel?.categories?.length === 1);

  const isAutoGenerated = Boolean(channel?.external) && !hasOneCollectionOnly;

  const [viewModes, setViewModes] = useLocalStorage(`channels-view-mode`, {});

  const setView = (viewMode) => {
    setViewModes((prev) => ({ ...prev, [channel.id]: viewMode }));
  };

  const view = viewModes[channel.id] || (isAutoGenerated ? 'list' : 'grid');

  const showGridView =
    (isLarge && view === 'grid') || (!isLarge && !isAutoGenerated);
  const showListView = (!isLarge && isAutoGenerated) || view === 'list';

  const { authenticated } = useAuth();

  const [actionJournal, setActionJournal] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const recent = useMemo(
    () => [
      {
        label: t('common.recently-added'),
        journals: channel?.recent,
      },
    ],
    [channel?.recent, t]
  );

  const [recentJournals] = useJournalsFiltering(recent, searchTerm);
  const groupedByCategory = useJournalsCategoryFiltering(
    channel.categories,
    searchTerm
  );

  const uniqueJournals = flattenJournalCategories(groupedByCategory);
  const sortedJournals = uniqueJournals.sort((a, b) =>
    a?.preamble?.title?.localeCompare(b?.preamble?.title)
  );

  const noSearchResults =
    searchTerm.length > 0 &&
    recentJournals?.length + groupedByCategory.length === 0;

  const { changeSubscription, isLoading } = CHANNELS.useSubscription();
  function handleSubscription(subscription) {
    const id = channel.id;
    changeSubscription({ id, subscription });
  }

  function Actions({ className }) {
    const subscribed = subscriptionIds[channel.id];
    return (
      <div className={`${className || ''}`}>
        <SubscriptionButton
          loading={isLoading}
          subscribed={subscribed}
          onClick={() => handleSubscription(!subscribed)}
        />
      </div>
    );
  }

  return (
    <div className="flex grow flex-col pb-smd-xl">
      <ChannelStickyBar title={channel?.name} />
      <div className="mb-smd-xl lg:mb-smd-md">
        <FocalPointBackground
          className="w-full space-y-smd-base"
          url={channel.cover}
        >
          <section className="flex h-48 items-center bg-gradient-to-r from-smd-gray-darker px-smd-base antialiased lg:h-[30rem] lg:px-smd-xxl">
            <ContentContainer className="flex flex-col md:flex-row md:space-x-11">
              <div className="h-20 w-20 shrink-0 overflow-hidden rounded-full border-2 border-smd-accent lg:h-32 lg:w-32">
                <Avatar
                  className="h-full w-full rounded-full"
                  src={channel.avatar}
                  alt={channel.name}
                />
              </div>
              <div className="flex justify-between">
                <div className="w-full">
                  <div className="xl:max-w-4/6 mt-2 break-all text-smd-h3 font-semibold text-white line-clamp-2 lg:text-smd-h1">
                    {channel.name}
                  </div>
                  <div
                    className="mt-smd-sm hidden w-3/5 text-white lg:block"
                    dangerouslySetInnerHTML={{ __html: channel.description }}
                  />
                </div>
              </div>
            </ContentContainer>
          </section>
        </FocalPointBackground>
      </div>
      <section className="px-smd-base antialiased lg:px-smd-xxl">
        <ContentContainer className="mb-4 flex flex-col justify-between space-y-3 lg:flex-row lg:items-center">
          <div className="block w-full lg:hidden">{channel.description}</div>
          <div className="text-smd-md flex items-center space-y-4 truncate text-smd-gray-darker lg:flex lg:space-x-4">
            <ChannelContactInfo channel={channel} />
          </div>
          {authenticated ? (
            <Actions className="shrink-0 space-x-smd-base" />
          ) : (
            <CTA
              ctaProps={{ ctaAction: 'register' }}
              color="primary"
              aria-label={t('labels.channels.follow')}
            >
              {t('common.follow')}
            </CTA>
          )}
        </ContentContainer>
      </section>
      <section className="px-smd-base antialiased lg:px-smd-xxl">
        <ContentContainer>
          <TopBar
            onSearch={(search) => setSearchTerm(search.trim().toLowerCase())}
          />

          {isAutoGenerated && <ViewSelector view={view} onChange={setView} />}

          {showGridView && (
            <GridView
              items={sortedJournals}
              childComponent={(entry) => (
                <div key={entry.id} className="smd-transform-duration">
                  <SmartJournalCard
                    actions={[
                      {
                        onClick: () => setActionJournal(entry),
                        element: <AddToCollection />,
                      },
                    ]}
                    journal={entry}
                    to={getJournalSlugPath(entry)}
                  />
                </div>
              )}
              className="mt-smd-xl lg:mt-smd-xxl"
            />
          )}

          {showListView && (
            <div className="-mx-4 lg:-mx-12">
              {recentJournals?.length > 0 && (
                <CategoryView
                  category={
                    <div className="-mb-2 flex items-center">
                      <Tag className="mr-2">{t('common.new')}</Tag>
                      {t('common.recently-added')}
                    </div>
                  }
                  journals={recentJournals}
                  onAddTo={setActionJournal}
                />
              )}

              {groupedByCategory?.map(({ category, journals }) => (
                <CategoryView
                  key={category.id}
                  category={category.name}
                  journals={journals}
                  onAddTo={setActionJournal}
                />
              ))}
            </div>
          )}

          {noSearchResults && (
            <NoSearchResults title={t('journals.channels.no-search-results')} />
          )}
        </ContentContainer>
      </section>

      {authenticated && (
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      )}
    </div>
  );
}
