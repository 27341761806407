import { useStoreLastLocation } from 'hooks';

export default function useChannelLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'channel-navigation-origin',
  });

  return {
    setChannelLastLocation: set,
    pushChannelLastLocation: push,
    popChannelLastLocation: pop,
    channelLastLocation: lastLocation,
  };
}
