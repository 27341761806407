// Sizing used from here: https://www.iab.com/wp-content/uploads/2019/04/IABNewAdPortfolio_LW_FixedSizeSpec.pdf
// Warning: Ad manager works with predefined sizes, these are pretty much all valid sizes

export const AD_SIZE = Object.freeze({
  HORIZONTAL: {
    BILLBOARD: { width: 970, height: 250 },
    SUPER_LEADER_BOARD: { width: 970, height: 90 },
    LEADER_BOARD: { width: 728, height: 90 },
    SMART_PHONE_BANNER_SMALL: { width: 120, height: 60 },
    SMART_PHONE_BANNER_MEDIUM: { width: 300, height: 50 },
    SMART_PHONE_BANNER_LARGE: { width: 320, height: 50 },
    PHONE_BANNER_SMALL: { width: 120, height: 20 },
    PHONE_BANNER_MEDIUM: { width: 168, height: 28 },
    PHONE_BANNER_LARGE: { width: 1216, height: 36 },
  },
  PORTRAIT: {
    MEDIUM: { width: 120, height: 600 },
    LARGE: { width: 300, height: 600 },
    XLARGE: { width: 300, height: 1050 },
    SKYSCRAPER: { width: 160, height: 600 },
    RECTANGLE: { width: 300, height: 250 },
    FULL_SCREEN_SMALL: { width: 640, height: 1136 },
    FULL_SCREEN_MEDIUM: { width: 750, height: 1334 },
    FULL_SCREEN_LARGE: { width: 1080, height: 1920 },
  },
});
