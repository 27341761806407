import { ContentContainer, Skeleton } from 'components';
import { useMedia } from 'hooks';
import { SkeletonCardGrid } from 'journals/components';

export default function JourneyInfoSkeleton() {
  const isMedium = useMedia(useMedia.MEDIUM);
  return (
    <ContentContainer className="w-full px-6 pb-8">
      <div className="flex h-[38rem] w-full flex-col space-y-4 pt-44">
        <Skeleton.Text className="w-3/4 text-smd-h1 md:w-2/5" />
        <Skeleton.Text
          trimLastLine
          className="w-full text-smd-h4 md:w-4/5"
          lineHeight={1}
          lines={4}
        />
      </div>
      <SkeletonCardGrid
        cardsCount={isMedium ? 3 : 1}
        title={<span />}
        className="lg:-mt-[12rem]"
      />
    </ContentContainer>
  );
}
