import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from 'react-cookie-consent';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { X } from 'components/icons';

import { tag } from 'utils/analytics';

function declineCookies() {
  window['ga-disable-MEASUREMENT_ID'] = true;

  tag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
  });

  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const googleTagCookiesRegEx = new RegExp(/(_g[a-z]?)/gs);
    if (googleTagCookiesRegEx.test(cookieName)) {
      Cookies.remove(cookieName);
    }
  });
}

function CookiesPrompt() {
  const { t } = useTranslation('translation', { keyPrefix: 'legal.cookies' });

  const cookiesConsent = getCookieConsentValue() === 'true';
  const [showPrompt, setShowPrompt] = useState(() => cookiesConsent);

  const { pathname } = useLocation();
  const timerRef = useRef(null);
  const delay = useRef(2500);
  const timestamp = useRef(Date.now());

  useEffect(() => {
    if (!cookiesConsent) {
      timerRef.current = setTimeout(() => setShowPrompt(true), delay.current);

      return () => {
        clearTimeout(timerRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const diff = Date.now() - timestamp.current;
        delay.current = Math.max(delay.current - diff, 0);
      };
    }
  }, [cookiesConsent, pathname]);

  if (cookiesConsent) {
    return null;
  }

  const handleDecline = () => {
    setShowPrompt(false);
    declineCookies();
  };

  const handleAccept = () => {
    setShowPrompt(false);
  };

  return (
    <div data-nosnippet role="alertdialog">
      <CookieConsent
        location="none"
        containerClasses={classnames(
          'fixed bottom-10 md:-translate-y-full right-0 md:max-w-sm w-full md:rounded-lg bg-smd-accent-light p-6 duration-100 border-smd-accent border-1 z-[25]',
          showPrompt ? 'md:-translate-x-10' : 'translate-x-full'
        )}
        disableStyles
        buttonClasses="py-2 px-6 bg-smd-accent text-white rounded hover:bg-smd-accent-dark whitespace-nowrap mt-5 rounded"
        buttonText={t('accept')}
        declineButtonClasses="absolute top-6 right-4"
        declineButtonText={<X className="h-5 w-5 stroke-2" />}
        enableDeclineButton
        flipButtons
        onDecline={handleDecline}
        onAccept={handleAccept}
        visible={showPrompt ? 'show' : 'hidden'}
      >
        <div data-testid="banner" className="pr-3 text-sm">
          {t('banner-content')}{' '}
          <a
            href={`${window.location.origin}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-smd-accent"
          >
            {' '}
            {t('banner-link-content')}
          </a>
        </div>
      </CookieConsent>
    </div>
  );
}

export default CookiesPrompt;
