import { filter, head, identity, isNil, props } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';

import { useLibraryApi } from '../libraryApiContext';

const getQueryKey = (query) => ['library', 'items', query];

export function useLibraryQuery(query, options) {
  const api = useLibraryApi();
  const queryClient = useQueryClient();

  const isMulti = Array.isArray(query);
  const hashIds = isMulti ? query : [query];

  return useQuery(
    getQueryKey(hashIds),
    () =>
      api
        .getLibraryContentByIds(hashIds)
        .then(props(hashIds))
        .then(filter(Boolean)),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: hashIds?.filter(Boolean).length > 0,
      select: isMulti ? identity : head,
      onSuccess: (data) => {
        if (isMulti) {
          data.filter(Boolean).forEach((item) => {
            const key = getQueryKey([item.id]);
            if (isNil(queryClient.getQueryData(key))) {
              queryClient.setQueryData(key, [item]);
            }
          });
        }
      },
      ...options,
    }
  );
}

export function useLibraryQueryMap(query, options) {
  const api = useLibraryApi();

  const isMulti = Array.isArray(query);
  const hashIds = isMulti ? query : [query];

  return useQuery(
    getQueryKey(hashIds),
    () => api.getLibraryBySearchIndexContentByIds(['search'], hashIds),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: hashIds?.filter(Boolean).length > 0,
      select: isMulti ? identity : head,
      ...options,
    }
  );
}
