import { useCallback, useMemo, useState } from 'react';
import { validUcumUnitSuggestions } from 'my-phr/utils/conversion';

export function useUnitSuggestions() {
  const [search, setSearch] = useState('');

  const suggestions = useMemo(() => {
    const suggestions = validUcumUnitSuggestions(search);

    if (search?.length === 0) return [];

    return suggestions.map((unit) => ({
      name: `${unit?.[1]} - ${unit?.[0]}`,
      code: unit?.[0],
    }));
  }, [search]);

  const fetch = useCallback(({ value }) => setSearch(value), []);

  const clear = useCallback(() => setSearch(''), []);

  return { suggestions, fetch, clear };
}
