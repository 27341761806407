import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ContentContainer } from 'components';
import { useMedia } from 'hooks';

//TODO: Implement react-datepicker

function DatePickerTopbar({ value, onChange }) {
  const { t } = useTranslation();
  const isMedium = useMedia(useMedia.MEDIUM);
  return (
    <div className="w-full bg-smd-accent-topbar text-white">
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <div className="flex h-14 items-center justify-center space-x-2 lg:justify-start">
            <div className="hidden text-sm font-semibold sm:font-bold md:block md:text-base">
              {t('library.story-of-the-day')}:
            </div>
            <p className="py-2">
              {format(new Date(), isMedium ? 'EEEE, MMMM d, y' : 'MMMM d, y')}
            </p>
          </div>
        </ContentContainer>
      </div>
    </div>
  );
}

export default DatePickerTopbar;
