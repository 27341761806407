import { useState } from 'react';
import { AddToCollectionModal } from 'journals/components';
import { useJournalsApi } from 'journals/context';
import { GroupedJournalsSection } from 'search/sections';
import { ContentContainer } from 'components';

function POCView() {
  const journalsApi = useJournalsApi();
  const [actionJournal, setActionJournal] = useState();

  return (
    <main className="grow px-4 pt-12 lg:px-smd-xxl">
      <ContentContainer maxWidthClassName="max-w-screen-2xl">
        <div className="flex w-full flex-col space-y-8">
          <GroupedJournalsSection
            onAddTo={setActionJournal}
            api={{
              ...journalsApi,
              getAllJournalsGroupedByCategory:
                journalsApi.getAllPOCJournalsGroupedByCategory,
            }}
            dataType="poc"
            wrapperClassName=""
          />
        </div>
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      </ContentContainer>
    </main>
  );
}

export default POCView;
