import { dissoc, equals } from 'ramda';
import { Roles, useAuth } from 'Auth';
import { useFiltersProvider } from 'my-phr/context';
import {
  getEnabledPlatformSourceTypes,
  initialStateFiltersProvider,
} from 'my-phr/context/FiltersProvider';

export function useTimelineFilterState() {
  const [{ filters }] = useFiltersProvider();
  const { hasRole } = useAuth();
  const allSourcesSelected =
    filters?.sources?.length ===
    getEnabledPlatformSourceTypes(hasRole(Roles.ADMIN))?.length;
  const noSourcesSelected = filters?.sources?.length === 0;
  const filtersInDefaultState = equals(
    dissoc('sources', initialStateFiltersProvider),
    dissoc('sources', filters)
  );

  const inDefaultState =
    filtersInDefaultState && (allSourcesSelected || noSourcesSelected);

  return { inDefaultState, allSourcesSelected };
}
