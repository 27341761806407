import usePublicResource from './usePublicResource';

function useJourneys() {
  const { data: journeys, isFetched } = usePublicResource('/initiatives.json');

  const featured = journeys?.find((item) => item.isFeatured);
  const allJourneys = journeys?.filter((journey) => !journey.isFeatured);

  return { journeys, allJourneys, featured, isFetched };
}

export default useJourneys;
