import { Skeleton } from 'components';
import { ChatFilled, EyeFilled, UserFilled } from 'components/icons';

function SkeletonCommunityCard() {
  const stats = [
    { Icon: UserFilled },
    { Icon: ChatFilled },
    { Icon: EyeFilled },
  ];

  return (
    <div className="flex-no-wrap my-4 flex w-full flex-col bg-white shadow-md md:flex-row">
      <div className="h-44 w-full md:h-auto md:w-72">
        <Skeleton solid className="h-full w-full" />
      </div>
      <div className="flex w-full flex-1 flex-col space-y-2 space-x-0 p-4 md:p-6 xl:flex-row xl:space-y-0 xl:space-x-2">
        <div className="flex-1">
          <Skeleton.Text
            lines={2}
            trimLastLine
            lineHeight={1.2}
            className="mb-2 text-smd-h2"
          />
          <Skeleton.Text
            lines={3}
            trimLastLine
            lineHeight={1}
            className="mt-4 text-smd-sm"
          />
        </div>
        <div className="flex flex-1 flex-wrap items-center justify-evenly space-x-5 pt-4 md:justify-start xl:px-20 xl:pt-0">
          {stats.map(({ Icon }, index) => (
            <div
              className="flex flex-col items-center space-y-2 text-smd-gray sm:flex-row sm:space-y-0 sm:space-x-2 xl:flex-grow"
              key={index}
            >
              <Icon className="w-5" />
              <Skeleton className="h-4 w-14" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SkeletonCommunityCard;
