import { Button } from 'components/forms';
import Form from 'components/forms/Form';
import { Trash } from 'components/icons';
import { useMedia } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDefaultCollectionName } from '..';

const MAX_LENGTH = 100;

function EditCollectionForm({
  onSubmit,
  onCancel,
  onDelete,
  defaultValues,
  isLoadingUpdate,
}) {
  const { t } = useTranslation();
  const isLarge = useMedia(useMedia.LARGE);
  const fallbackCollectionName = useDefaultCollectionName();

  const defaultCollection = defaultValues?.defaultCollection;
  const collectionValues = {
    ...defaultValues,
    name:
      !defaultValues?.name && defaultCollection
        ? fallbackCollectionName
        : defaultValues?.name,
  };

  const actions = () => (
    <>
      <Button.Ghost
        type="button"
        disabled={defaultCollection}
        onClick={defaultCollection ? () => {} : onDelete}
        aria-label={t('labels.collections.delete-collection', {
          collection: collectionValues.name,
        })}
      >
        <Trash className="h-6 w-6" />
        <span className="hidden lg:inline">
          {t('journals.collections.delete')}
        </span>
      </Button.Ghost>
      <Button.Tertiary type="button" onClick={onCancel}>
        {t('common.cancel')}
      </Button.Tertiary>
      <Button.Primary type="submit" loading={isLoadingUpdate}>
        {t('common.save')}
      </Button.Primary>
    </>
  );

  return (
    <Form
      className="w-full"
      onSubmit={onSubmit}
      defaultValues={collectionValues}
    >
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-smd-h1 font-bold">
          {t('journals.collections.edit')}
        </h1>
        {isLarge && (
          <div className="flex items-center space-x-4">{actions()}</div>
        )}
      </div>
      <Form.Input
        groupClassName="flex flex-col max-w-md"
        label={t('journals.collections.name')}
        name="name"
        rules={{
          required: t('errors.required'),
          maxLength: {
            value: MAX_LENGTH,
            message: t('errors.max-length', {
              field: t('journals.collections.create'),
              value: MAX_LENGTH,
            }),
          },
        }}
      />
      <>
        {!isLarge && (
          <div className="mt-4 flex flex-wrap items-start space-x-4">
            {actions()}
          </div>
        )}
      </>
    </Form>
  );
}

export default EditCollectionForm;
