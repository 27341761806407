import { useCallback, useEffect, useRef, useState } from 'react';
import { useTable } from 'react-table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { LoadingOverlay } from 'components';
import { Move } from 'components/icons';

function DragDropTable({
  columns,
  data: records,
  loading,
  initialLoading,
  disableDnD,
  setDisableRowDnD,
  onReorder,
}) {
  const [data, setData] = useState(records);

  useEffect(() => {
    if (records) {
      setData(records);
    }
  }, [records]);

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data,
      columns,
      getRowId,
    });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex];
    setData(() => {
      const copy = data.slice();
      copy.splice(dragIndex, 1);
      copy.splice(hoverIndex, 0, dragRecord);
      return copy;
    });
  };

  const renderHeader = (headerGroup) => {
    return (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        className="border-b border-smd-gray-lighter bg-smd-gray-lightest"
      >
        {!disableDnD && <th />}
        {headerGroup.headers.map((column) => (
          <th
            {...column.getHeaderProps()}
            className={classnames('whitespace-nowrap p-4 font-semibold')}
          >
            {column.render('Header')}
          </th>
        ))}
      </tr>
    );
  };

  return (
    <LoadingOverlay
      isLoading={initialLoading}
      className={classnames(loading && 'cursor-wait')}
    >
      <div
        className={classnames(
          'relative w-full overflow-x-auto overflow-y-visible',
          loading && 'pointer-events-none'
        )}
      >
        <DndProvider backend={HTML5Backend}>
          <table
            {...getTableProps()}
            className="w-full table-auto text-left text-smd-accent-dark"
          >
            <thead>
              {headerGroups.map((headerGroup) => renderHeader(headerGroup))}
            </thead>
            <tbody {...getTableBodyProps()} className="text-sm">
              {rows.map((row, index) => {
                return (
                  prepareRow(row) || (
                    <Row
                      index={index}
                      row={row}
                      moveRow={moveRow}
                      onRowDrop={() => onReorder?.(data)}
                      disableDnD={disableDnD}
                      disableHandlers={
                        setDisableRowDnD?.(row.original) ?? false
                      }
                      {...row.getRowProps()}
                    />
                  )
                );
              })}
            </tbody>
          </table>
        </DndProvider>
      </div>
    </LoadingOverlay>
  );
}

DragDropTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.func,
      ]).isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  initialLoading: PropTypes.bool,
  setDisableRowDnD: PropTypes.func,
  disableDnD: PropTypes.bool,
  onReorder: PropTypes.func,
};

DragDropTable.defaultProps = {
  disableDnD: false,
};

const DND_ITEM_TYPE = 'row';

const Row = ({
  row,
  index,
  moveRow,
  onRowDrop,
  disableDnD,
  disableHandlers,
}) => {
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop: onRowDrop,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      {...row.getRowProps()}
      className={classnames(
        'border-b border-smd-gray-lighter hover:bg-smd-gray-lightest',
        isDragging && 'opacity-0'
      )}
      ref={dropRef}
    >
      {!disableDnD && (
        <>
          {disableHandlers ? (
            <td className="sticky left-0 w-14 border-r-1 bg-white p-4" />
          ) : (
            <td
              ref={dragRef}
              className="sticky left-0 w-14 cursor-move border-r-1 bg-white p-4"
            >
              <Move className="h-6 w-6 text-smd-gray" />
            </td>
          )}
        </>
      )}
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()} className="p-4">
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default DragDropTable;
