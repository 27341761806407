import SliderNavigation from './SliderNavigation';

function SliderCollectionNavigation({ items, currentItem, onChange }) {
  const currentItemIndex =
    items && items.length > 0 ? items.indexOf(currentItem) : 0;
  const handleChange = (value) => {
    if (onChange) {
      onChange(items[value], value);
    }
  };
  if (!(items && items.length)) return null;
  return (
    <SliderNavigation
      count={items.length}
      initialValueState={currentItemIndex}
      onChange={handleChange}
    />
  );
}

export default SliderCollectionNavigation;
