import { useHelpCenterResources } from 'hooks';
import { useTranslation } from 'react-i18next';
import LibraryCarousel from 'components/LibraryCarousel';
import { parseHelpCenterArticles } from 'utils';

export const COLLECTIONS_HELP_KEY = 'my-collections';
export const AUTHORED_HELP_KEY = 'storymd-author';

export default function EducationalVideos({ renderKey }) {
  const { data } = useHelpCenterResources();
  const { t } = useTranslation();
  const records = data?.[renderKey] || [];

  const assets = parseHelpCenterArticles(records).filter(
    (asset) => asset?.type === 'video'
  );

  const title = records?.[0]?.['section-text'] || t('common.getting-started');

  return (
    <LibraryCarousel
      pageSize={6}
      elementsWrapperClassName="grid-cols-6"
      displayChildrenCount={false}
      config={{
        label: title,
        value: JSON.stringify(assets),
      }}
      withModalNavigation={false}
    />
  );
}

EducationalVideos.defaultProps = {
  renderKey: COLLECTIONS_HELP_KEY,
};
