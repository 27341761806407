import { useState, useEffect } from 'react';
import { path } from 'ramda';
import classnames from 'classnames';
import { X } from '../../icons';
import { ErrorLabel } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

function FormTagsInput(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    control,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    setValue,
    setError,
    getValues,
    emptyTagsPlaceholder,
    placeholder,
    watch,
    resetField,
    ...rest
  } = props;

  const base = [
    'border-0',
    'p-2',
    'leading-normal',
    'placeholder:text-smd-secondary',
    'border-transparent focus:border-transparent focus:ring-0',
  ];

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const defaultTags = getValues(id)?.length > 0 ? [...getValues(id)] : [];
  const [tags, setTags] = useState(defaultTags);

  const backgroundStyle = {
    background: '#FFEBEB',
    color: '#FE7D5D',
  };

  useEffect(() => {
    setValue('tagsArray', [...tags]);
  }, [tags, setValue]);

  useEffect(() => {
    setValue(id, '');
  }, [id, setValue]);

  const keyPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = getValues(id);
      if (!tags.includes(value) && value?.trim()?.length > 0 && !error) {
        setTags((previousTags) => [...previousTags, value]);
        setValue(id, '');
      }
    }
  };

  const TagComponent = ({ tag }) => {
    return (
      <div
        style={backgroundStyle}
        className="ml-2 flex grow-0 self-center rounded-md pr-2 pl-2 text-smd-red"
      >
        <span className="mr-2 ">{tag}</span>
        <X
          strokeWidth="2"
          className="text-bold h-3 w-3 cursor-pointer self-center"
          onClick={() => {
            setTags((previous) => [...previous.filter((tg) => tg !== tag)]);
          }}
        />
      </div>
    );
  };
  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div
        className={classnames(
          'flex flex-wrap border border-smd-gray-light',
          'smd-input-focus-within-primary'
        )}
      >
        {tags && tags.map((tag) => <TagComponent key={tag} tag={tag} />)}
        <input
          type="text"
          id={id}
          placeholder={tags.length > 0 ? placeholder : emptyTagsPlaceholder}
          className={classnames(className, base)}
          onKeyPress={keyPressed}
          {...register(id, rules)}
          {...rest}
        />
      </div>

      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormTagsInput);

export default FormTagsInput;
