import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useURL } from 'hooks';

import { ContentContainer, CTA } from 'components';
import { LogoLight, Play } from 'components/icons';
import { Button } from 'components/forms';

import { getAssetUrl } from 'utils';
import FocusVideoModal from './components/FocusVideoModal';

function IntroSection({ videos: { background, full } }) {
  const query = useURL.useGetURLQuery();
  const autoPlayVideo = Boolean(query.get('video') === 'true');
  const clearURLQueries = useURL.useDeleteAllURLQuery();

  const { t } = useTranslation();

  const [openFullVideo, setOpenFullVideo] = useState(autoPlayVideo);
  const openModal = () => setOpenFullVideo(true);
  const closeModal = () => {
    if (autoPlayVideo) {
      clearURLQueries();
    }
    setOpenFullVideo(false);
  };

  const loopVideo = {
    src: getAssetUrl(background?.key),
  };

  return (
    <section className="mt-6 md:mt-10">
      <div className="relative overflow-hidden py-[20vh]">
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 z-0 min-h-full min-w-full object-cover"
        >
          <source {...loopVideo} />
        </video>

        <div className="absolute inset-0 z-10 h-full w-full bg-black opacity-80" />

        <ContentContainer className="z-20 grid h-full items-center px-4 text-white">
          <div className="mx-auto flex max-w-2/3 flex-col items-center justify-center space-y-smd-xl py-16">
            <LogoLight className="h-6" />
            <h1 className="mb-4 text-center text-smd-h3 font-semibold md:text-smd-h1">
              {background?.title}
            </h1>
            <CTA
              size="lg"
              color="primary"
              ctaProps={background?.ctaProps}
              className="font-semibold"
            >
              {background?.ctaText}
            </CTA>
            <Button.Negative
              size="lg"
              onClick={openModal}
              className="font-semibold"
            >
              <Play className="h-3 w-3" />
              <span> {t('common.watch-video')}</span>
            </Button.Negative>
          </div>
        </ContentContainer>
      </div>

      <FocusVideoModal
        isOpen={openFullVideo}
        onClose={closeModal}
        autoPlayMuted={autoPlayVideo}
        fileKey={full?.key}
      />
    </section>
  );
}

export default IntroSection;
