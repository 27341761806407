import PropTypes from 'prop-types';

import Modal from './Modal';
import Button from './forms/Button';
export default function Confirm({
  isOpen,
  onClose,
  onConfirm,
  confirmLabel,
  renderConfirm,
  cancelLabel,
  renderCancel,
  children,
  isLoading,
  className,
}) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.ResponsiveContainer className={className}>
        <div
          className="flex-col-center w-screen bg-white p-8 md:w-full md:max-w-3xl"
          role="dialog"
          aria-modal="true"
        >
          <p className="max-w-full break-words text-center">{children}</p>
          <div className="mt-12 flex flex-col space-y-2 md:mt-8 md:flex-row md:space-y-0 md:space-x-2">
            {renderConfirm ?? (
              <Button.Primary size="lg" loading={isLoading} onClick={onConfirm}>
                {confirmLabel}
              </Button.Primary>
            )}
            {renderCancel ?? (
              <Button.Tertiary size="lg" onClick={onClose}>
                {cancelLabel}
              </Button.Tertiary>
            )}
          </div>
        </div>
      </Modal.ResponsiveContainer>
    </Modal>
  );
}

Confirm.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

Confirm.defaultProps = {
  isOpen: false,
  onClose: null,
  onConfirm: null,
  confirmLabel: null,
  cancelLabel: null,
};
