import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, includes } from 'ramda';

import { useCollectionJournals, useCardSize, useWindowSize } from 'hooks';
import { LibraryJournalModule } from 'search/components/modules';
import { Button } from 'components/forms';
import { ChevronDown, ChevronUp } from 'components/icons';

import { SkeletonCardGrid } from '../../components';
import CollectionPlaceholder from './CollectionPlaceholder';

function CollectionJournals({ collection, searchTerm }) {
  const {
    collectionJournals,
    // hasNextPage,
    // fetchNextPage,
    isFetched,
    isFetching,
  } = useCollectionJournals(collection.id);

  const shouldFilter =
    isFetched && !isEmpty(collectionJournals) && !isEmpty(searchTerm?.trim?.());

  const gridRef = useRef();

  const filteredJournals = shouldFilter
    ? collectionJournals.filter((journal) =>
        includes(
          searchTerm.toLowerCase(),
          journal?.preamble.title.toLowerCase?.()
        )
      )
    : collectionJournals;

  if (isFetching && !isFetched) {
    return (
      <CollectionJournalsCollapseReveal hideBtn>
        <SkeletonCardGrid
          textProps={{ lineHeight: '1.4', fontSize: '2rem' }}
          cardsCount={5}
          className=""
          gridClassName=""
          title=""
        />
      </CollectionJournalsCollapseReveal>
    );
  }

  if (collectionJournals?.length === 0) {
    return (
      <CollectionPlaceholder
        defaultCollection={collection?.defaultCollection}
      />
    );
  }

  return (
    <CollectionJournalsCollapseReveal
      gridRef={gridRef}
      journals={filteredJournals}
    >
      <LibraryJournalModule gridRef={gridRef} journals={filteredJournals} />
    </CollectionJournalsCollapseReveal>
  );
}

function CollectionJournalsCollapseReveal({
  children,
  journals,
  hideBtn,
  gridRef,
}) {
  const { t } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(!hideBtn);
  const [isOpen, setIsOpen] = useState();
  const width = useWindowSize(200);

  const { height } = useCardSize();

  useEffect(() => {
    if (gridRef?.current) {
      const styles = window.getComputedStyle(gridRef.current);
      const colCount = styles.gridTemplateColumns.split(' ').length;
      setIsButtonVisible(journals.length > colCount);
    }
  }, [gridRef, journals, width]);

  return (
    <>
      <div
        style={{
          maxHeight: isOpen ? 'unset' : `calc(${height}px + 1.25rem)`,
        }}
        className="-mx-2 overflow-hidden p-2"
      >
        {children}
      </div>
      {isButtonVisible && (
        <div className="flex w-full justify-center py-4">
          <Button.Ghost
            onClick={() => setIsOpen((prev) => !prev)}
            className="font-semibold"
            tabIndex={-1}
          >
            <span>{t(isOpen ? 'common.show-less' : 'common.show-more')}</span>
            {isOpen ? (
              <ChevronUp className="h-5 w-5" strokeWidth="3" />
            ) : (
              <ChevronDown className="h-5 w-5" strokeWidth="3" />
            )}
          </Button.Ghost>
        </div>
      )}
    </>
  );
}

export default CollectionJournals;
