import { createContext, useContext, useMemo, useEffect } from 'react';
import { useLocalStorage } from 'react-use';

const PartnerContext = createContext();

export const currentPartnerLocalStorageKey = 'current-partner';

export function PartnerProvider({ children }) {
  const [currentPartner, setCurrentPartner, removeCurrentPartner] =
    useLocalStorage(currentPartnerLocalStorageKey);

  useEffect(() => {
    if (process.env.REACT_APP_PRO_ACCOUNTS_STATE === 'DISABLED') {
      removeCurrentPartner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({ currentPartner, setCurrentPartner, removeCurrentPartner }),
    [currentPartner, setCurrentPartner, removeCurrentPartner]
  );

  return (
    <PartnerContext.Provider value={contextValue}>
      {children}
    </PartnerContext.Provider>
  );
}

export function usePartner() {
  const context = useContext(PartnerContext);
  if (!context) {
    throw new Error('usePartner must be used within a PartnerProvider');
  }
  return context;
}
