import { nanoid } from 'nanoid';

const sessionIdKey = 'smd-session-id';
let id = localStorage.getItem(sessionIdKey);
export function getSessionId() {
  if (!id) {
    id = nanoid();
    localStorage.setItem(sessionIdKey, id);
  }
  return id;
}
