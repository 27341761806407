import classnames from 'classnames';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

function SuggestionRecord({ suggestion, query }) {
  const matches = AutosuggestHighlightMatch(suggestion.name, query);
  const parts = AutosuggestHighlightParse(suggestion.name, matches);
  return (
    <span aria-label={suggestion.name} tabIndex={0} className="block py-1 px-4">
      {parts.map(({ text, highlight }, index) => (
        <span
          key={index}
          className={classnames({
            'text-smd-accent': highlight,
          })}
        >
          {text}
        </span>
      ))}
    </span>
  );
}

export default SuggestionRecord;
