import React from 'react';
import PropTypes from 'prop-types';
import styles from './Slider.module.css';

function Slider(props) {
  const { min, max, step } = props;
  const { onChange, value } = props;

  return (
    <div className="relative flex h-2 w-full flex-col bg-smd-accent text-smd-accent">
      <input
        type="range"
        min={min}
        max={max}
        step={(step ?? 1) / 100}
        value={value}
        onChange={({ target: { value } }) => onChange?.(parseFloat(value))}
        className={`${styles.slider}`}
      />
    </div>
  );
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
};

export default Slider;
