import classnames from 'classnames';
import SearchSuggestions from 'components/SearchSuggestions';
import { PHR_DATA_TYPE } from 'my-phr/const';
import { Label, Form } from '..';
import { registerFormElement } from './utils';

function FormSuggestions(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    className,
    errors,
    withError = true,
    setValue,
    getValues,
    setError,
    prefix,
    watch,
    resetField,
    searchSuggestionsProps,
    customContainer,
    placeholder,
    getInputValue,
    defaultSuggestion,
    inEditMode,
    ...rest
  } = props;

  function resetFormValue() {
    setValue?.(`${prefix}.value`, null);
    setValue?.(`${prefix}.unit`, null);
  }

  function updateForeignCategoryValue(manualInputType, suggestion) {
    setValue(`${manualInputType}.name`, {
      suggestion,
      value: suggestion?.name,
    });
    manualInputType !== PHR_DATA_TYPE.OBSERVATION && resetField(id);
  }

  return (
    <Form.Controller
      {...rest}
      errors={errors}
      withError={withError}
      name={name}
      defaultValue={{ value: '', suggestion: null }}
      render={({ field: { value, ref, ...restField }, ...rest }) => {
        const error = rest?.fieldState?.error;
        const border = error
          ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
          : 'border-smd focus:border-smd-accent';

        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <SearchSuggestions.Simple
              {...restField}
              id={id}
              value={value?.value}
              placeholder={placeholder}
              onAddNew={resetFormValue}
              inEditMode={inEditMode}
              onForeignCategoryItemSelect={updateForeignCategoryValue}
              getInputValue={getInputValue}
              inputClassName={classnames(border, className)}
              inputRef={ref}
              customContainerStyle={customContainer}
              renderSuggestionsContainer={(props) => (
                <SearchSuggestions.SuggestionContainer
                  {...props}
                  className="z-50 max-h-72 w-full"
                />
              )}
              defaultSuggestion={defaultSuggestion}
              {...searchSuggestionsProps}
            />
          </>
        );
      }}
    />
  );
}

registerFormElement(FormSuggestions);

export default FormSuggestions;
