import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import CHANNELS from 'hooks/channels';
import { ContentContainer, Tooltip } from 'components';
import { Info } from 'components/icons';
import { useChannelLastLocation, useChannelsFiltering } from '../hooks';

import {
  AddToCollectionModal,
  ChannelCard,
  NoSearchResults,
  SearchBarSection,
  SkeletonCardGrid,
} from '../components';
import { GridView } from '../history/components';

function Channels() {
  const { t } = useTranslation();

  const { setChannelLastLocation } = useChannelLastLocation();

  const [searchTerm, setSearchTerm] = useState('');

  const {
    subscriptions,
    isLoading: isLoadingSubscriptions,
    isLoaded: isLoadedSubscriptions,
  } = CHANNELS.useChannelsSubscriptions();

  const [actionJournal, setActionJournal] = useState();

  const filteredSubscriptions = useChannelsFiltering(subscriptions, searchTerm);

  const showSubscriptionsSection = Boolean(
    !searchTerm || filteredSubscriptions?.length
  );

  const noSubscriptionsSearchResults = Boolean(
    searchTerm.length && !filteredSubscriptions.length
  );

  const noSearchResults = Boolean(noSubscriptionsSearchResults);

  return (
    <section className="grow px-smd-base pb-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
      <ContentContainer>
        <SearchBarSection
          withClearButton
          placeholder={t('journals.channels.search-collections')}
          onChange={(search) => setSearchTerm(search.trim().toLowerCase())}
          label={t('labels.common.search-button', {
            type: t('common.collection_other'),
          })}
        />

        {!noSearchResults && (
          <h1 className="my-smd-xl flex items-center space-x-2 text-smd-h3 font-bold">
            <span>{t('journals.channels.heading')}</span>
            <div className="items center -mb-1 ml-2 flex select-none space-x-1 text-smd-xs text-smd-gray">
              <Tooltip content={t('hints.channel-subscriptions')}>
                {(props, { isVisible }) => (
                  <Info
                    className={classnames(
                      'h-4 w-4 shrink-0 cursor-help stroke-2',
                      isVisible ? 'text-smd-accent' : 'text-smd-gray-dark'
                    )}
                    {...props}
                  />
                )}
              </Tooltip>
            </div>
          </h1>
        )}

        {noSearchResults && (
          <NoSearchResults title={t('journals.channels.no-search-results')} />
        )}

        {isLoadingSubscriptions && !isLoadedSubscriptions && (
          <SkeletonCardGrid
            gridClassName=""
            className=""
            title=""
            cardsCount={3}
            isChannelCards
          />
        )}

        {isLoadedSubscriptions && showSubscriptionsSection && (
          <>
            {filteredSubscriptions?.length ? (
              <GridView
                items={filteredSubscriptions}
                childComponent={(entry) => (
                  <Link
                    key={entry.id}
                    className="smd-focus-visible-primary smd-transform-duration rounded-sm"
                    to={`/collections/${entry.id}`}
                    onClick={() => setChannelLastLocation()}
                    aria-label={t('labels.channels.go-to-collection', {
                      channel: entry.name,
                    })}
                  >
                    <ChannelCard channel={entry} />
                  </Link>
                )}
              />
            ) : (
              <>
                <h1 className="text-smd-h3 font-semibold">
                  {t('common.channel', { count: 0 })}
                </h1>
                <p className="my-smd-lg max-w-xl text-smd-secondary">
                  {t('journals.channels.begin-hint')}
                </p>
              </>
            )}
          </>
        )}
      </ContentContainer>

      <AddToCollectionModal
        journal={actionJournal}
        onClose={() => setActionJournal()}
      />
    </section>
  );
}

export default Channels;
