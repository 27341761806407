import { useMutation } from 'react-query';
import axios from 'axios';
import { Headers } from 'api/axios';

function useNewsletter(config) {
  const { mutate: subscribe, isLoading } = useMutation((data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (value) {
        formData.append(key, value);
      }
    }

    return axios.post(
      `${process.env.REACT_APP_LIBRARY_URL}/ajax/subscribe`,
      formData,
      {
        headers: Headers.FORMDATA,
      }
    );
  }, config);

  return {
    subscribe,
    isLoading,
  };
}

export default useNewsletter;
