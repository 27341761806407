import { forwardRef } from 'react';
import { isEmpty, path } from 'ramda';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter } from 'date-fns';
import { isFunction } from 'utils';
import { Calendar, X as RemoveIcon } from 'components/icons';
import { useMedia } from 'hooks';
import { cn } from 'lib/utils';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormController from './FormController';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'w-full',
  'placeholder:text-smd-tertiary',
  'smd-input-focus-primary',
];

export const CustomDateInput = forwardRef(
  (
    { value, onClick, placeholder, isFullWidth, onRemoveValue, isRequired },
    ref
  ) => {
    const widths = isFullWidth
      ? { icon: 'right-3', input: 'w-full' }
      : { input: 'h-11 w-[11.5rem] md:w-64', icon: 'left-[9.5rem] md:left-56' };
    return (
      <div className="relative">
        <input
          value={value}
          className={cn(
            'smd-input-focus-primary  border-1 border-smd-gray-light bg-white px-2',
            widths.input
          )}
          onClick={() => onClick()}
          placeholder={placeholder}
          ref={ref}
        />
        {!isRequired && !isEmpty(value) && (
          <RemoveIcon
            onClick={onRemoveValue}
            className="absolute left-[7.4rem] top-2/4 h-6 w-6 -translate-y-2/4 transform stroke-2 text-smd-accent hover:cursor-pointer md:left-[12.2rem]"
          />
        )}
        <Calendar
          className={cn(
            'pointer-events-none absolute top-2/4 h-5 w-5 -translate-y-2/4 transform text-smd-accent',
            widths.icon
          )}
        />
      </div>
    );
  }
);

function FormInputDate(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    dateFormat,
    placeholder,
    errors,
    showYearDropdown = false,
    showMonthDropdown = false,
    defaultValue,
    customDateParser,
    wrapperClassName = '!w-fit',
    isFullWidth = false,
    popperPlacement = 'auto',
    min,
    max,
    ...rest
  } = props;

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';

  const classes = base.concat(border, className).join(' ');

  const handleKeyDownEvent = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    }
  };

  const filterTime = (time) => (min ? isAfter(time, min) : true);

  const isMedium = useMedia(useMedia.MEDIUM);

  const onRemove = () => rest?.setValue?.(name, null);

  return (
    <FormController
      {...rest}
      id={id}
      errors={errors}
      rules={rules}
      withError={error}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, onBlur, ref } }) => {
        const pickerValue = isFunction(customDateParser)
          ? customDateParser(value)
          : value;

        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <ReactDatePicker
              ref={(element) => {
                element && ref(element.input);
              }}
              showYearDropdown={showYearDropdown}
              showMonthDropdown={showMonthDropdown}
              wrapperClassName={wrapperClassName}
              dropdownMode="select"
              showPopperArrow={false}
              selected={pickerValue}
              onChange={onChange}
              onBlur={onBlur}
              timeIntervals={60}
              withPortal={!isMedium}
              dateFormat={dateFormat}
              className={classes}
              placeholderText={placeholder}
              onKeyDown={handleKeyDownEvent}
              minDate={min}
              maxDate={max}
              filterTime={filterTime}
              popperPlacement={popperPlacement}
              customInput={
                <CustomDateInput
                  isRequired={rules?.required?.value}
                  onRemoveValue={onRemove}
                  isFullWidth={isFullWidth}
                />
              }
            />
          </>
        );
      }}
    />
  );
}

registerFormElement(FormInputDate);

export default FormInputDate;
