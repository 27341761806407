import { useTranslation } from 'react-i18next';
import { Button } from 'components/forms';
import { AIRobot, DoubleDoc, RightArrowRead } from 'components/icons';

function GettingStartedOcr({ onClick, isLoading }) {
  const { t } = useTranslation();

  const iconClassName = 'h-6 w-6';

  const gettingStartedItems = [
    {
      translationTitle: 'my-phr.ocr.get-started.ai-title',
      translationDescription: 'my-phr.ocr.get-started.ai-description',
      icon: (props) => <AIRobot {...props} />,
    },
    {
      translationTitle: 'my-phr.ocr.get-started.read-title',
      translationDescription: 'my-phr.ocr.get-started.read-description',
      icon: (props) => <RightArrowRead {...props} />,
    },
    {
      translationTitle: 'my-phr.ocr.get-started.understand-title',
      translationDescription: 'my-phr.ocr.get-started.understand-description',
      icon: (props) => <DoubleDoc {...props} />,
    },
  ];
  return (
    <>
      <div>
        <header className="border-b-2 pl-4 pr-10 pb-2 text-smd-h4 font-semibold">
          {t('my-phr.ocr.get-started.title')}
        </header>
      </div>
      <div className="flex grow flex-col ">
        <ul className="space-y-4 py-4">
          {gettingStartedItems.map(
            (
              { icon: Icon, translationTitle, translationDescription },
              index
            ) => {
              return (
                <div className="flex items-center space-x-3 px-4" key={index}>
                  <Icon className={iconClassName} />
                  <div className="flex flex-col">
                    <span className="text-smd-sm">{t(translationTitle)}</span>
                    <span className="text-smd-xs">
                      {t(translationDescription)}
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </ul>
      </div>
      <div className="sticky bottom-0 flex justify-end border-t-2 px-4 pt-4">
        <Button.Primary onClick={onClick} loading={isLoading}>
          {t('landing.get-started')}
        </Button.Primary>
      </div>
    </>
  );
}

export default GettingStartedOcr;
