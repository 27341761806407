import { Check } from 'components/icons';
import asset from 'library/propTypes/asset';
import PropTypes from 'prop-types';

export default function ElementWrapper({
  children,
  onClick,
  selectedAssets,
  asset,
  hasNumericMarking,
}) {
  const selectedElement = selectedAssets.find(({ id }) => asset.id === id);
  return (
    <button
      className="smd-focus-visible-primary absolute h-full w-full appearance-none rounded-sm text-left"
      onClick={(e) => {
        e.preventDefault();
        onClick(asset);
      }}
    >
      {children}
      {selectedElement && (
        <div className="absolute top-0 right-0 h-full w-full bg-white bg-opacity-50 text-white">
          <div className="text-semibold absolute top-2 right-2 h-8 w-8 bg-smd-accent p-1 text-center">
            {hasNumericMarking ? selectedElement.order : <Check />}
          </div>
        </div>
      )}
    </button>
  );
}

ElementWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedAssets: PropTypes.array.isRequired,
  hasNumericMarking: PropTypes.bool,
  asset: asset.shape,
};

ElementWrapper.defaultProps = {
  hasNumericMarking: true,
};
