import { useJournalLastLocation } from 'journals/hooks';
import { useStoryLastLocation, useTopicLastLocation } from 'library/hooks';

function useHandleArticleLastLocation() {
  const { setTopicLastLocation } = useTopicLastLocation();
  const { setJournalLastLocation } = useJournalLastLocation();
  const { setStoryLastLocation } = useStoryLastLocation();

  const handleOnClick = (articleUrl) =>
    articleUrl && articleUrl?.startsWith('/journal')
      ? setJournalLastLocation()
      : articleUrl?.startsWith('/topic')
      ? setTopicLastLocation()
      : setStoryLastLocation();

  return { handleOnClick };
}

export default useHandleArticleLastLocation;
