import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'hooks';
import DropMenu from 'components/DropMenu';
import { DotsVertical } from 'components/icons';

const MENU_ROOT = document.getElementById('modal-root');

function TableActionGroup({ children, ...props }) {
  const isLarge = useMedia(useMedia.LARGE);

  return (
    <div className="relative flex justify-end space-x-6" {...props}>
      {isLarge ? children : <TableActionDropMenu children={children} />}
    </div>
  );
}

function TableActionDropMenu({ children }) {
  const { t } = useTranslation();
  return (
    <DropMenu
      portal={{
        target: MENU_ROOT,
      }}
      menuButton={
        <DropMenu.Button
          className="smd-focus-visible-primary rounded-sm px-2"
          aria-label={t('labels.admin.actions')}
        >
          <DotsVertical className="h-6 w-6 text-smd-accent" />
        </DropMenu.Button>
      }
    >
      {React.Children.map(children, (child) => (
        <DropMenu.Item onClick={child.props.onClick}>
          {React.cloneElement(child, {
            color: 'none',
          })}
        </DropMenu.Item>
      ))}
    </DropMenu>
  );
}

export default TableActionGroup;
