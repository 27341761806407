import { createContext, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { useScrollResetOnNavigate } from 'hooks';
import { Tooltip } from 'components';
import { Button } from 'components/forms';
import { ChevronUp } from 'components/icons';

const ScrollContext = createContext();

export default function ScrollProvider({
  children,
  disableResetScrollOnNavigate,
  disableBackToTopButton,
  disableOnScroll,
}) {
  const { t } = useTranslation();

  const { ref: targetRef, inView } = useInView({
    initialInView: true,
    rootMargin: `${window.innerHeight}px 0px 0px 0px`,
  });

  const { ref: headerRef, inView: bigHeaderView } = useInView({
    initialInView: false,
  });

  const isScrolled = !bigHeaderView;

  const backToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useScrollResetOnNavigate(disableResetScrollOnNavigate ? null : window);

  const value = useMemo(
    () => ({ backToTop, inView, isScrolled }),
    [backToTop, inView, isScrolled]
  );

  return (
    <ScrollContext.Provider value={value}>
      <div ref={targetRef} />
      {!disableOnScroll && <div ref={headerRef} />}
      {children}
      {!inView && !disableBackToTopButton && (
        <Tooltip
          content={t('common.back-to-top')}
          placement="left"
          offset={[0, 10]}
        >
          {(props) => (
            <div
              data-testid="scroll-to-top-container"
              className="fixed right-5 bottom-16 z-30 lg:right-10"
              {...props}
            >
              <Button.Primary
                flavor="round"
                size="lg"
                onClick={backToTop}
                aria-label={t('labels.common.back-to-top')}
              >
                <ChevronUp className="h-6 w-6 cursor-pointer stroke-2" />
              </Button.Primary>
            </div>
          )}
        </Tooltip>
      )}
    </ScrollContext.Provider>
  );
}

export function useScroll() {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
}
