import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames';
import { always, isNil, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Contents, X } from 'components/icons';
import { ViewerTableOfContent } from 'components/viewer';

import { useLibraryQuery } from '../../../hooks';
import LibraryTopicCover from '../LibraryTopicCover';

import LibraryTopicModalNavbar, { Nav } from './LibraryTopicModalNavbar';
import TopicAssets from './sections/TopicAssets';
import TopicContent from './sections/TopicContent';
import TopicRelated from './sections/TopicRelated';

function ModalButton({ className, ...props }) {
  return (
    <button
      className={classnames(
        'h-10 w-10 p-1',
        'text-white',
        'bg-black bg-opacity-50',
        'focus:outline-none focus-visible:outline-none',
        className
      )}
      {...props}
    />
  );
}

function NavbarButton({ className, ...props }) {
  return (
    <button
      className={classnames(
        'h-8 w-8 p-1',
        'text-white',
        'focus:outline-none focus-visible:outline-none',
        className
      )}
      {...props}
    />
  );
}

function LibraryTopicModalContainer({ hashId, onClose }) {
  const { t } = useTranslation();
  const { data: topic, isFetching: isFetchingTopic } = useLibraryQuery(hashId);

  const articleHashIds = topic?.linkages?.articles?.map(prop('id'));
  const imageHashIds = topic?.linkages?.images?.map(prop('id'));
  const videoHashIds = topic?.linkages?.videos?.map(prop('id'));

  const { data: articles, isFetching: isFetchingArticles } =
    useLibraryQuery(articleHashIds);

  const [open, setOpen] = useState(false);

  const [section, setSection] = useState('topic');

  const showTocButton = section === 'topic';

  const { ref, inView } = useInView({ initialInView: true });

  const [tocSelected, setTocSelected] = useState(); // selected
  const [tocActive, setTocActive] = useState(); // highlighted in ToC
  const [tocRoot, setTocRoot] = useState();

  const scrollToRef = useRef();
  const scrollToTop = useCallback(
    () =>
      scrollToRef.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      }),
    []
  );

  useEffect(() => {
    if (tocSelected?.dataRef?.subarticleId) return;
    setTocActive(tocSelected);
    scrollToTop();
  }, [tocSelected, scrollToTop]);

  useEffect(() => {
    scrollToTop();
  }, [section, tocActive, scrollToTop]);

  useEffect(() => {
    if (isNil(topic) || isFetchingArticles) return;

    const children = [];

    if (topic.ranges?.length) {
      children.push({
        id: 'ranges',
        label: t('library.topic.range', { count: topic.ranges.length }),
        dataRef: { ranges: topic.ranges },
        complete: always(false),
      });
    }

    if (topic.sections?.length) {
      children.push({
        id: 'sections',
        label: t('library.topic.sections'),
        dataRef: { sections: topic.sections },
        complete: always(false),
      });
    }

    if (articles?.length) {
      const articleChildren = articles.map((article) => {
        const subarticles = article.linkages?.subarticles;

        let subarticleChildren;
        if (subarticles?.length) {
          subarticleChildren = subarticles.map((subarticle) => ({
            id: `${article.id}.${subarticle.id}`,
            label: subarticle.label,
            dataRef: { article, subarticleId: subarticle.id },
            complete: always(false),
          }));
        }

        return {
          id: article.id,
          label: article.name,
          dataRef: { article },
          complete: always(false),
          children: subarticleChildren,
        };
      });

      children.push(...articleChildren);
    }

    if (topic.references?.length) {
      children.push({
        id: 'references',
        label: t('library.topic.references'),
        dataRef: { references: topic.references },
        complete: always(false),
      });
    }

    setTocRoot({ id: 'root', children });
    setTocSelected(children[0]);
  }, [topic, articles, isFetchingArticles, t]);

  if (isFetchingTopic) {
    return 'Loading...';
  }

  return (
    <div className="h-full w-full overflow-y-auto bg-white antialiased">
      <div className="relative" ref={ref}>
        <LibraryTopicCover topic={topic} />

        <ModalButton
          onClick={onClose}
          className="absolute left-4 top-4 flex space-x-4"
          aria-label={t('common.close')}
        >
          <X strokeWidth="2" className="h-full w-full" />
        </ModalButton>

        {showTocButton && (
          <ModalButton
            className="absolute right-4 top-4 flex space-x-4 p-2 lg:hidden"
            onClick={() => setOpen(true)}
            aria-label={t('labels.journals.toc')}
          >
            <Contents strokeWidth="1.5" className="h-full w-full" />
          </ModalButton>
        )}
      </div>

      <div ref={scrollToRef} />

      <LibraryTopicModalNavbar
        showTopSection={!inView}
        left={
          <div className="flex h-full items-center justify-between pl-4 pr-1.5 text-white">
            <h1 className="truncate font-semibold">{topic?.name}</h1>
            <div className="flex shrink-0 lg:hidden">
              {showTocButton && (
                <NavbarButton
                  className="p-1.5"
                  onClick={() => setOpen(true)}
                  aria-label={t('labels.journals.toc')}
                >
                  <Contents strokeWidth="1.5" className="p-0.5" />
                </NavbarButton>
              )}
              <NavbarButton onClick={onClose} aria-label={t('common.close')}>
                <X strokeWidth="2" />
              </NavbarButton>
            </div>
          </div>
        }
        right={
          <Nav active={section} onSelect={(name) => setSection(name)}>
            <Nav.Item name="topic">{t('common.topic')}</Nav.Item>
            {videoHashIds?.length > 0 && (
              <Nav.Item name="videos">{t('common.video_other')}</Nav.Item>
            )}
            {imageHashIds?.length > 0 && (
              <Nav.Item name="images">{t('common.image_other')}</Nav.Item>
            )}
          </Nav>
        }
      />

      {open && (
        <div className="absolute inset-0 z-20 flex items-start justify-end py-8 pt-16 pl-8 lg:hidden">
          <div className="absolute inset-0" onClick={() => setOpen(false)} />
          <div className="relative z-20 max-h-full min-h-full w-72 max-w-full overflow-y-auto bg-white p-6 shadow-xl">
            {tocRoot && (
              <>
                <div className="flex justify-between pb-4">
                  <span className="text-smd-sm font-bold text-smd-accent">
                    {t('common.table-of-contents')}
                  </span>
                  <X
                    strokeWidth="2"
                    className="h-6 w-6"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <ViewerTableOfContent
                  root={tocRoot}
                  activeId={tocActive?.id}
                  onSelect={setTocSelected}
                />
              </>
            )}
          </div>
        </div>
      )}
      {/* two bars, 3.5 rem each */}
      <div className="flex" style={{ minHeight: 'calc(100vh - 7rem)' }}>
        {section === 'topic' && (
          <TopicContent
            tocRoot={tocRoot}
            tocActive={tocActive}
            tocSelected={tocSelected}
            setTocActive={setTocSelected}
          />
        )}
        {section === 'videos' && (
          <TopicAssets section="video" hashIds={videoHashIds} />
        )}
        {section === 'images' && (
          <TopicAssets section="image" hashIds={imageHashIds} />
        )}
        {section === 'related' && <TopicRelated />}
      </div>
    </div>
  );
}

LibraryTopicModalContainer.propTypes = {
  hashId: PropTypes.string.isRequired,
};

export default LibraryTopicModalContainer;
