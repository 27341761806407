import PropTypes from 'prop-types';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useTranslation } from 'react-i18next';

function SlideshowPagination({
  items = [],
  currentItem,
  onSelect,
  withArrows,
}) {
  const { t } = useTranslation();
  const changeItem = (item) => {
    if (item !== currentItem && onSelect) {
      onSelect(item);
    }
  };
  const currentIndex = items.indexOf(currentItem);

  return (
    <div className="mx-auto flex w-full items-center justify-between py-8">
      <div className="max-w-1/3">
        {withArrows && (
          <button
            onClick={() => changeItem(items[currentIndex - 1])}
            className={`${
              currentIndex === 0 && 'invisible'
            } smd-focus-visible-primary flex items-center space-x-2`}
            aria-label={t('labels.carousel.prev')}
          >
            <ChevronLeft
              strokeWidth="2"
              className="h-8 w-4 shrink-0 align-middle "
            />
          </button>
        )}
      </div>
      <div className="max-w-1/3 flex items-center">
        <div className="p-2">
          {t('common.of', { current: currentIndex + 1, total: items.length })}
        </div>
      </div>
      <div className="max-w-1/3">
        {withArrows && (
          <button
            onClick={() => changeItem(items[currentIndex + 1])}
            className={`${
              currentIndex === items.length - 1 && 'invisible'
            } smd-focus-visible-primary flex items-center space-x-2`}
            aria-label={t('labels.carousel.next')}
          >
            <ChevronRight
              strokeWidth="2"
              className="h-8 w-4 shrink-0 align-middle "
            />
          </button>
        )}
      </div>
    </div>
  );
}

SlideshowPagination.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentItem: PropTypes.any,
  onSelect: PropTypes.func,
  withArrows: PropTypes.bool,
};

SlideshowPagination.defaultProps = {
  withArrows: true,
};

export default SlideshowPagination;
