import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Favorite, Smile, Speaker } from 'components/icons';

const sectionBaseStyles = 'px-6 flex flex-nowrap';
const accentSectionStyles = classnames(
  sectionBaseStyles,
  'bg-smd-accent text-white py-3 items-center'
);
const infoSectionStyles = classnames(
  sectionBaseStyles,
  'bg-white text-smd-main py-5'
);

const iconBaseStyles = 'w-5 h-5 mr-3';
const accentIconStyles = classnames(iconBaseStyles, 'fill-smd-accent');

const textBaseStyles = 'text-smd-sm flex-1';
const boldTextStyles = classnames(textBaseStyles, 'font-bold');
const semiboldTextStyles = classnames(textBaseStyles, 'font-semibold');

function BetaStateDropdown(props, ref) {
  const { className, ...rest } = props;

  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        'absolute left-0 flex w-full flex-col overflow-hidden bg-white lg:left-32 lg:m-1 lg:max-w-xs lg:rounded lg:shadow-md',
        props.isScrolled ? 'top-12' : 'top-20'
      )}
      {...rest}
      ref={ref}
    >
      <BetaStateDropdownItem isAccentBg icon={Smile}>
        {t('beta.current-state')}
      </BetaStateDropdownItem>

      <BetaStateDropdownItem icon={Favorite}>
        {t('beta.enjoy-content')}
      </BetaStateDropdownItem>

      <BetaStateDropdownSeparator />

      <BetaStateDropdownItem icon={Speaker}>
        {t('beta.feedback-recommendations')}
      </BetaStateDropdownItem>

      <div className="pb-4 pl-14 pr-6">
        <p className="text-smd-sm">
          {t('beta.contact')}{' '}
          <a
            href="https://storymd.com/contact-us"
            target="blank"
            className="smd-focus-visible-primary rounded-sm text-smd-sm font-semibold text-smd-accent hover:underline"
            aria-label={t('labels.common.contact-us')}
          >
            {t('beta.here')}
          </a>
        </p>
      </div>
    </div>
  );
}

function BetaStateDropdownSeparator() {
  return (
    <div className="w-full px-6 ">
      <div className="border-b-1 bg-smd-gray-lighter" />
    </div>
  );
}

function BetaStateDropdownItem({ icon: Icon, isAccentBg, children }) {
  return (
    <div className={isAccentBg ? accentSectionStyles : infoSectionStyles}>
      <Icon className={isAccentBg ? iconBaseStyles : accentIconStyles} />
      <span className={isAccentBg ? boldTextStyles : semiboldTextStyles}>
        {children}
      </span>
    </div>
  );
}

export default forwardRef(BetaStateDropdown);
