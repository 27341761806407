import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Image } from 'components/media';
import { normalizeAssetUrl } from 'journals/utils';

export default function SharePreview({
  thumbnail,
  name,
  type,
  credit,
  description,
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <p className="my-6 max-w-xs text-center text-smd-sm text-smd-tertiary">
        {t('journals.share.send', {
          type: t(`common.${type}_one`).toLowerCase(),
        })}
      </p>
      <article className="flex w-full flex-col text-smd-main antialiased shadow-md">
        <Image
          className="h-64 w-full bg-smd-gray-lighter object-contain"
          src={normalizeAssetUrl(thumbnail)}
          alt={name}
        />
        <div className="flex flex-col p-smd-xs">
          {name && (
            <h1
              className="shrink-0 pr-8 text-smd-xs font-semibold line-clamp-1 lg:pr-0"
              dangerouslySetInnerHTML={{ __html: name }}
            />
          )}

          {description && (
            <p
              className={classnames(
                'text-smd-xs text-smd-secondary line-clamp-3'
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {credit && credit.name && (
            <p className="text-smd-xs italic text-smd-gray">{credit.name}</p>
          )}
        </div>
      </article>
    </div>
  );
}
