import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFocalPointBackground } from 'hooks';

const DEFAULT_FALLBACK_IMAGE = '/images/pages.png';

function setImage(_url, fallbackImage, onError) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onerror = () => {
      onError?.();
      resolve(fallbackImage);
    };
    image.onload = () => {
      resolve(_url);
    };
    image.src = _url;
  });
}

export default function FocalPointBackground({
  url: _url,
  lofiUrl,
  focalPoint,
  backgroundSize,
  fallbackImage = DEFAULT_FALLBACK_IMAGE,
  as: Wrapper,
  style,
  alt = 'Image StoryMD',
  onError,
  ...rest
}) {
  const [url, setUrl] = useState(lofiUrl ?? _url ?? fallbackImage);
  useEffect(() => {
    if (_url) {
      setImage(_url, fallbackImage, onError).then(setUrl);
    }
  }, [_url, fallbackImage, onError]);

  const background = useFocalPointBackground({
    url,
    focalPoint,
    backgroundSize,
  });

  return (
    <Wrapper
      role="img"
      aria-label={alt}
      style={{ ...background, ...style }}
      {...rest}
    />
  );
}

FocalPointBackground.propTypes = {
  backgroundSize: PropTypes.oneOf(['cover', 'contain']),
  url: PropTypes.string,
  focalPoint: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  as: PropTypes.string,
};

FocalPointBackground.defaultProps = {
  as: 'div',
};
