import { useCallback, useState } from 'react';

export default function useContentOverflow(dependencies = []) {
  const [isContentOverflow, setContentOverflow] = useState(false);

  const ref = useCallback((node) => {
    const isContentOverflow = node?.clientHeight < node?.scrollHeight;
    setContentOverflow(isContentOverflow);
    //eslint-disable-next-line
  }, dependencies);

  return { isContentOverflow, ref };
}
