import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { usePrintContent } from 'hooks';
import { ContentContainer, LoadingOverlay } from 'components';
import { Button } from 'components/forms';

function Document() {
  const location = useLocation();
  const docType = location.pathname.replace('/', '');

  const { t } = useTranslation();

  const { print, PrintContainer } = usePrintContent();

  const { data, isFetching } = useQuery(
    ['document', docType],
    async ({ queryKey }) => {
      const type = queryKey?.[1]?.split('-')?.[0];
      const { data } = await axios.get(
        `${process.env.REACT_APP_LIBRARY_URL}/api/document/${type}`,
        {
          headers: {
            'x-api-secret': process.env.REACT_APP_API_SECRET,
          },
        }
      );
      return data;
    },
    {
      onError: (err) => console.log(err),
    }
  );

  return (
    <ContentContainer className="grow px-4">
      <LoadingOverlay isLoading={isFetching}>
        <div className="my-4 flex w-full flex-wrap items-center lg:flex-nowrap">
          <div className="mb-4 w-full md:mb-0 md:w-auto md:flex-1">
            <h1
              className="text-smd-h3 md:text-smd-h1"
              data-testid={`${docType}DocumentTitle`}
            >
              {t(`landing.${docType}`)}
            </h1>
          </div>
          <Button.Tertiary onClick={print}>{t('legal.print')}</Button.Tertiary>
        </div>
        <div className="py-8 pb-16">
          <PrintContainer
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: data?.html }}
          />
        </div>
      </LoadingOverlay>
    </ContentContainer>
  );
}

export default Document;
