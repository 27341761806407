import classnames from 'classnames';
import { X } from 'components/icons';

function Alert({ res, destroy }) {
  return (
    <div
      className={classnames(
        'my-4 flex w-full select-none flex-nowrap p-2 text-smd-base text-white',
        {
          'bg-red-600': res?.type === 'error',
          'bg-green-600': res?.type === 'success',
          'bg-smd-accent': res?.type === 'info',
        }
      )}
    >
      <div className="flex-1" data-testid="alertMessage">
        {res.msg}
      </div>
      <div
        className={classnames(
          'flex-auto grow-0 cursor-pointer p-1 text-white',
          {
            'hover:bg-red-700': res?.type === 'error',
            'hover:bg-green-700': res?.type === 'success',
            'hover:bg-smd-accent-dark': res?.type === 'info',
          }
        )}
        onClick={destroy}
      >
        <X strokeWidth="2" className="h-4 w-4" />
      </div>
    </div>
  );
}

export default Alert;
