import { useTranslation } from 'react-i18next';
import { PrivatePageGuestView } from 'components';

function PublicCommunities() {
  const { t } = useTranslation();

  const title = t('communities.public-communities.title');
  const text = t('communities.public-communities.text');
  const buttonText = t('communities.public-communities.button');
  const image = 'https://cdn.storymd.com/optimized/2dZwQ7TjqG/original.png';
  return (
    <PrivatePageGuestView
      title={title}
      text={text}
      buttonText={buttonText}
      image={image}
      testId="joinCommunities"
    />
  );
}

export default PublicCommunities;
