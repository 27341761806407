import { useTranslation } from 'react-i18next';

function UploadAlert() {
  const { t } = useTranslation();
  return (
    <div className="mx-4 mt-4 flex select-none flex-nowrap justify-between bg-smd-accent-light p-2 text-smd-sm">
      <div className="flex items-center space-x-2 pl-2">
        {t('my-phr.ocr.upload.in-progress')}
      </div>
    </div>
  );
}

export default UploadAlert;
