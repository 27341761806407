import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { libraryPath } from 'library/const';
import debounce from 'lodash.debounce';
import { slugifyObject } from 'utils/slug';

const initialData = {
  previousLocation: null,
  currentAsset: null,
  assets: [],
};
const queryKey = 'library-asset-state';
export function useBackgroud() {
  return useQueryClient().getQueryState(queryKey)?.data?.previousLocation;
}
function useLibraryAssetState() {
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { data: state } = useQuery(queryKey, () => initialData, {
    initialData,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  let currentAsset = state?.currentAsset;
  let assets = useMemo(() => {
    return state?.assets?.length
      ? state.assets
      : currentAsset
      ? [currentAsset]
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assets]);

  const setNewState = useCallback(
    ({ asset, list }) => {
      const state = {
        previousLocation: location,
        currentAsset: asset,
        assets: list,
      };
      queryClient.setQueryData(queryKey, state);
      history.push(
        `/${libraryPath.ASSET}/${encodeURIComponent(
          slugifyObject(asset.id, asset.name)
        )}`
      );
    },
    [location, history, queryClient]
  );
  const updateState = useCallback(
    (updatedProps) => {
      if (state?.currentAsset) {
        const newState = {
          ...state,
          ...updatedProps,
        };
        queryClient.setQueryData(queryKey, newState);
        history.replace(
          `/${libraryPath.ASSET}/${encodeURIComponent(
            slugifyObject(newState.currentAsset.id, newState.currentAsset.name)
          )}`
        );
      }
    },
    [history, state, queryClient]
  );
  const debouncedUpdateState = debounce(updateState, 50);

  //Handles back button click
  useEffect(() => {
    return () => {
      queryClient.removeQueries(queryKey);
    };
  }, [queryClient]);

  const clearState = useCallback(() => {
    queryClient.removeQueries(queryKey);
    history.goBack();
  }, [history, queryClient]);

  return {
    currentIndex: assets.findIndex((x) => x.id === currentAsset.id),
    assets,
    setNewState,
    updateState: debouncedUpdateState,
    clearState,
  };
}

export default useLibraryAssetState;
