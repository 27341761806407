import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { is } from 'ramda';

import { Minus, Plus } from './icons';

import styles from './NumericField.module.css';

function SquareButton(props) {
  return (
    <button
      className="smd-focus-visible-primary h-10 w-10 rounded-xl p-0.5 text-smd-accent-bright md:h-7 md:w-7 md:rounded"
      style={{ backgroundColor: '#CEEDEF' }}
      {...props}
    />
  );
}

const numFieldStyles = [
  'w-24',
  'text-xl font-bold leading-none',
  'bg-smd-gray-lighter bg-opacity-50',
  'rounded-xl border-none',
  'focus:ring-0',
].join(' ');

function NumericField({ value, step, onChange }) {
  const { t } = useTranslation();
  const ref = useRef();
  const handleDecrement = () => {
    if (is(Function, ref.current.stepDown)) {
      ref.current.stepDown();
      onChange(Number.parseFloat(ref.current.value));
    } else {
      onChange(value - step);
    }
  };

  const handleIncrement = () => {
    if (is(Function, ref.current.stepUp)) {
      ref.current.stepUp();
      onChange(Number.parseFloat(ref.current.value));
    } else {
      onChange(value + step);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <SquareButton
        onClick={handleDecrement}
        aria-label={t('labels.journals.decrement')}
      >
        <Minus />
      </SquareButton>

      <input
        type="number"
        ref={ref}
        value={value}
        step={step}
        onChange={({ target }) => onChange(target.value)}
        className={`${styles.input} ${numFieldStyles}`}
      />

      <SquareButton
        onClick={handleIncrement}
        aria-label={t('labels.journals.increment')}
      >
        <Plus />
      </SquareButton>
    </div>
  );
}

NumericField.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

NumericField.defaultProps = {
  value: 0,
  step: 1,
  onChange: () => {},
};

export default NumericField;
