import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnEsc, useOutsideClick } from 'hooks';
import { callAll } from 'utils';
import { SettingsContext } from 'SettingsContext';
import { Button } from '../forms';
import Confirm from '../Confirm';

function OutsideClickConfirmationModal({
  children,
  onConfirm,
  onCancel,
  message,
  renderConfirm,
  skip,
  isOpen,
  className,
}) {
  const { t } = useTranslation();
  const settingsContext = useContext(SettingsContext);
  const sensitiveContent = settingsContext?.sensitiveContent;
  const isControlled = isOpen !== undefined;
  const [opened, setOpened] = useState(() => (isControlled ? isOpen : false));

  const isSensitiveUnblurModalActive = sensitiveContent?.confirmationModalOpen;

  useEffect(() => {
    if (isControlled && !isSensitiveUnblurModalActive) {
      setOpened(isOpen);
    }
  }, [isOpen, isControlled, isSensitiveUnblurModalActive]);

  const close = () => setOpened(false);

  const openConfirmOnOutsideClick = useCallback(() => {
    if (skip && !isSensitiveUnblurModalActive) return onConfirm();
    if (!opened && !isSensitiveUnblurModalActive) {
      setOpened(true);
    }
  }, [opened, skip, onConfirm, isSensitiveUnblurModalActive]);

  const ref = useOutsideClick(openConfirmOnOutsideClick, 'pointerdown');

  useOnEsc(() => {
    if (skip && !isSensitiveUnblurModalActive) return onConfirm();
    if (!opened && !isSensitiveUnblurModalActive) {
      setOpened(true);
    }
  });

  return (
    <>
      <div ref={ref} className={className}>
        {children}
      </div>
      <Confirm
        isOpen={opened}
        cancelLabel={t('common.cancel')}
        renderConfirm={
          renderConfirm ?? (
            <Button.Primary size="lg" onClick={callAll(close, onConfirm)}>
              {t('common.yes')}
            </Button.Primary>
          )
        }
        renderCancel={
          <Button.Tertiary size="lg" onClick={callAll(close, onCancel)}>
            {t('common.cancel')}
          </Button.Tertiary>
        }
        onClose={close}
      >
        {message}
      </Confirm>
    </>
  );
}

export default OutsideClickConfirmationModal;
