import { Link } from 'react-router-dom';

import { useCardSize } from 'hooks';
import { Card, ContentContainer } from 'components';

import { Carousel, VirtualCarousel } from 'components/Carousel';
import { useJournalLastLocation } from 'journals/hooks';
import { getJournalSlugPath } from 'journals/utils';
import { getDistributionLogos } from 'journals/utils/distribution';

function ExploreSection({ journalSection, journals }) {
  const { imgHeight, bodyHeight } = useCardSize();
  const { setJournalLastLocation } = useJournalLastLocation();

  const cardStyleProps = {
    imgStyle: {
      height: imgHeight,
    },
    bodyStyle: {
      height: bodyHeight,
    },
  };

  return (
    <section className="bg-smd-gray-lightest py-12 md:py-smd-3xl">
      <ContentContainer className="px-4">
        {journalSection && (
          <div className="text-center">
            <h1 className="relative text-smd-h3 font-semibold md:text-smd-h1">
              {journalSection?.title}
            </h1>
            <p className="mx-auto pt-smd-xl pb-smd-xl text-smd-base md:max-w-3xl md:pb-smd-3xl md:text-smd-h4">
              {journalSection?.text}
            </p>
          </div>
        )}
        <div className="w-full pb-3 md:px-8">
          {journals?.length > 0 ? (
            <VirtualCarousel
              withNavigation
              carouselProps={{
                centerInsufficientSlides: true,
              }}
              slides={journals.map((journal) => (
                <Carousel.Slide key={journal.journalKey}>
                  <Link
                    className="smd-transform-duration group cursor-pointer outline-none"
                    onClick={() => setJournalLastLocation()}
                    to={getJournalSlugPath({
                      journalKey: journal.journalKey,
                      preamble: { title: journal.heading },
                    })}
                    aria-label={journal?.heading}
                  >
                    <Card
                      heading={journal?.heading}
                      content={journal?.content}
                      cover={journal?.cover}
                      logos={
                        getDistributionLogos(journal.journalKey) ??
                        journal.logos
                      }
                      theme={journal?.theme}
                      {...cardStyleProps}
                    />
                  </Link>
                </Carousel.Slide>
              ))}
            />
          ) : (
            <div className="text-center">
              <h1 className="text-smd-h3">No Data</h1>
            </div>
          )}
        </div>
      </ContentContainer>
    </section>
  );
}

export default ExploreSection;
