import { useTranslation } from 'react-i18next';

import { useDefaultToRandomId } from 'hooks';
import { Admin } from 'components/icons';

import { ErrorLabel, Radio, Label } from '..';
import FormInputWrapper from './FormInputWrapper';
import { registerFormElement } from './utils';

function FormSelectRole(props) {
  const { t } = useTranslation();
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    getValues,
    setValue,
    setError,
    watch,
    resetField,
    ...rest
  } = props;

  const error = errors[name];

  const admin = useDefaultToRandomId();

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      <Label htmlFor={id} {...labelProps}>
        <div className="flex flex-col space-y-2 py-1 sm:flex-row sm:space-y-0 sm:space-x-8">
          <Label className="flex items-center" htmlFor={admin}>
            <Radio
              id={admin}
              className="mr-4"
              value="admin"
              aria-label={t('labels.admin.role-admin')}
              {...register(name, rules)}
              {...rest}
            />
            <div className="flex items-center text-smd-sm text-smd-main">
              <Admin className="mr-3 h-4 w-4" />
              <span className="mr-1 font-semibold">
                {t('account-settings.users.roles.admin.label')}
              </span>
              <span className="text-smd-secondary">
                - {t('account-settings.users.roles.admin.description')}
              </span>
            </div>
          </Label>
        </div>
        {withError && <ErrorLabel error={error} />}
      </Label>
    </FormInputWrapper>
  );
}

registerFormElement(FormSelectRole);

export default FormSelectRole;
