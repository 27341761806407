import classnames from 'classnames';
import { times } from 'ramda';

function Skeleton({
  width,
  height,
  style,
  solid = false,
  className,
  children,
  ...props
}) {
  return (
    <div
      style={{
        width,
        height,
        ...style,
      }}
      className={classnames(
        'relative inline-block overflow-hidden',
        { 'animate-pulse': !solid },
        className
      )}
      {...props}
    >
      {children}
      <div className="absolute inset-0 bg-gray-200" />
    </div>
  );
}

function SkeletonCircle({ width, height, className, ...props }) {
  const size = width ?? height;
  return (
    <Skeleton
      width={size}
      height={size}
      className={classnames('rounded-full', className)}
      {...props}
    />
  );
}

function SkeletonText({
  lines = 1,
  trimLastLine = false,
  fontSize,
  lineHeight,
  style,
  ...props
}) {
  return times((i) => {
    const shortLine = trimLastLine && lines > 1 && i === lines - 1;
    return (
      <p
        key={i}
        style={{
          fontSize,
          lineHeight,
          ...style,
        }}
        {...props}
      >
        &zwnj;
        <span
          className={classnames(
            'inline-block animate-pulse rounded bg-gray-200 leading-none',
            shortLine ? 'w-3/4' : 'w-full'
          )}
        >
          &zwnj;
        </span>
      </p>
    );
  }, lines);
}

export default Skeleton;
Skeleton.Square = Skeleton;
Skeleton.Circle = SkeletonCircle;
Skeleton.Text = SkeletonText;
