import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useLibraryAssetRoutedModal } from 'library/hooks';
import { ChevronLeft, ChevronRight } from 'components/icons';

import { AssetsLightBox } from 'journals/components';
import { SlideshowPagination, CarouselPagination } from 'components/navigation';
import LibraryAssetCard from 'library/components/asset/LibraryAssetCard';
import { useSensitiveAssetViewRestriction } from 'hooks';

function ElementContainer({ openLightbox, index, asset }) {
  const { isRestricted, setIsRestricted } =
    useSensitiveAssetViewRestriction(asset);
  return (
    <button
      type="button"
      className="smd-focus-visible-primary aspect-w-5 aspect-h-4 cursor-pointer rounded-sm text-left"
      onClick={isRestricted ? () => {} : () => openLightbox(index)}
      aria-label={`${asset.name} - ${asset.type}`}
    >
      <LibraryAssetCard
        asset={asset}
        onThumbnailUnblur={() => setIsRestricted(false)}
      />
    </button>
  );
}

function LibraryCarousel({
  config,
  elementsWrapperClassName,
  pageSize,
  displayChildrenCount,
  withModalNavigation = true,
  shouldRenderLabel,
}) {
  const { t } = useTranslation();

  const { label, value } = config;
  const assets = JSON.parse(value);
  const { openLibraryAssetModal } = useLibraryAssetRoutedModal({ assets });
  const { state, openLightBox } = AssetsLightBox.useAssetsLightBoxState();

  const pageCount = Math.ceil(assets.length / pageSize);
  const slideshowPaginationUsageThreshold = 20;
  const shouldUseSlideshowPagination =
    pageCount >= slideshowPaginationUsageThreshold;

  const [page, setPage] = useState(0);
  const pageStartIndex = page * pageSize;
  const pageEndIndex = pageStartIndex + pageSize;

  const openLightbox = (index) => {
    if (withModalNavigation) {
      const asset = assets[index];
      openLibraryAssetModal(asset);
    } else {
      openLightBox(index);
    }
  };

  if (assets.length === 0) return null;
  return (
    <div>
      {shouldRenderLabel && (
        <h3 className="mb-3 font-semibold lg:mb-5 lg:text-xl">
          {label ?? t('journals.visual-editor.additional-assets')}
          {displayChildrenCount ? ` (${assets.length}) ` : ''}
        </h3>
      )}

      <div className="relative hidden px-6 lg:block ">
        <div
          className={classnames(
            'grid h-full grid-rows-1 gap-5',
            elementsWrapperClassName
          )}
        >
          {assets.map((asset, index) => {
            return index >= pageStartIndex && index < pageEndIndex ? (
              <ElementContainer
                key={index}
                index={index}
                asset={asset}
                openLightbox={openLightbox}
              />
            ) : null;
          })}
        </div>
        {page > 0 && (
          <button
            type="button"
            className="smd-focus-visible-primary absolute top-0 bottom-0 left-0 w-6 rounded-sm p-0.5"
            aria-label={t('labels.carousel.prev')}
            onClick={() => setPage(page - 1)}
          >
            <ChevronLeft strokeWidth="2" />
          </button>
        )}
        {page < pageCount - 1 && (
          <button
            type="button"
            className="smd-focus-visible-primary absolute top-0 bottom-0 right-0 w-6 rounded-sm p-0.5"
            aria-label={t('labels.carousel.next')}
            onClick={() => setPage(page + 1)}
          >
            <ChevronRight strokeWidth="2" />
          </button>
        )}
      </div>

      <div className="-mx-4 flex space-x-5 overflow-x-auto px-4 pb-2 lg:hidden">
        {assets.map((asset, index) => (
          <button
            type="button"
            key={index}
            className="smd-focus-visible-primary h-48 w-56 shrink-0 rounded-sm text-left"
            onClick={() => openLightbox(index)}
          >
            <LibraryAssetCard asset={asset} />
          </button>
        ))}
      </div>

      {pageCount > 1 && (
        <div className="hidden justify-center lg:flex ">
          {shouldUseSlideshowPagination ? (
            <SlideshowPagination
              withArrows={false}
              currentItem={page}
              items={Array.from(Array(pageCount).keys())}
            />
          ) : (
            <CarouselPagination
              items={Array.from(Array(pageCount).keys())}
              currentItem={page}
              onSelect={setPage}
            />
          )}
        </div>
      )}

      {!withModalNavigation && (
        <AssetsLightBox assets={assets} lightBoxState={state} />
      )}
    </div>
  );
}

LibraryCarousel.defaultProps = {
  displayChildrenCount: true,
  shouldRenderLabel: true,
  pageSize: 4,
  elementsWrapperClassName: 'grid-cols-4',
};

export default LibraryCarousel;
