import React from 'react';
import classnames from 'classnames';

export default function Backdrop({ className, ...props }) {
  return (
    <div
      className={classnames(
        'bg-blur fixed inset-0 z-30 bg-black bg-opacity-50',
        className
      )}
      {...props}
    />
  );
}
