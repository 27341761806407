import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const conversationTips = [
  'make-your-goal',
  'understanding-topic',
  'supporting-others',
  'encourage',
  'be-kind',
  'no-trolling',
  'no-anecdotal-negativity',
  'safe-environment',
  'no-identity-based-bias',
  'positive-intentions',
];

const getRandomTip = () => {
  const index = Math.floor(Math.random() * conversationTips.length);
  return conversationTips[index];
};

function useRandomTip() {
  const { t } = useTranslation();
  const [conversationTip, setConversationTip] = useState(null);

  useEffect(() => {
    setConversationTip(t(`communities.conversation-tip.${getRandomTip()}`));
  }, [t]);

  return conversationTip;
}

export default Object.assign(useRandomTip, { TIPS: conversationTips });
