import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useScrollIntoView } from 'hooks';
import { LoadingOverlay } from 'components';
import JourneysCategoryView from 'journals/history/components/JourneysCategoryView';
import { FeaturedJourneysCarousel, JourneysNavBar } from './components';
import { useJourneysData } from './hooks';

const urlFormatter = (sectionName) =>
  sectionName?.replace(/ /g, '-').toLowerCase();

function JourneysView() {
  const location = useLocation();

  const {
    journeys,
    isLoading,
    isFetched,
    featured,
    groupedJourneysByCategory,
  } = useJourneysData();

  const sectionRefs = useRef({});
  const featuredRef = useRef({});

  const scrollIntoView = useScrollIntoView();

  useEffect(() => {
    if (journeys && !isLoading && location.search) {
      const sectionName = location.search.replace('?category=', '');
      const sectionRefKey = Object.keys(sectionRefs.current).find(
        (key) => urlFormatter(key) === sectionName.toLowerCase()
      );
      const sectionEl = sectionRefs.current[sectionRefKey];

      if (sectionEl) {
        setTimeout(() => {
          scrollIntoView(sectionEl);
        });
      }
    }
  }, [isLoading, journeys, location.search, scrollIntoView]);

  const handleMenuItemClick = (sectionName) => {
    const targetElement =
      sectionName === 'featured'
        ? featuredRef?.current
        : sectionRefs?.current[sectionName];

    if (targetElement) {
      scrollIntoView(targetElement);
      window.history.replaceState(
        {},
        1,
        `/journeys?category=${urlFormatter(sectionName)}`
      );
    }
  };

  return (
    <LoadingOverlay isLoading={isLoading}>
      {isFetched && (
        <JourneysNavBar
          {...{
            journeys: groupedJourneysByCategory,
            featured,
          }}
          onClick={handleMenuItemClick}
        />
      )}
      {isFetched && Boolean(featured?.length) && (
        <FeaturedJourneysCarousel journeys={featured} />
      )}
      <section className="mx-auto max-w-7xl grow px-smd-base py-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
        <div className="flex flex-col space-y-4">
          {Object.entries(groupedJourneysByCategory).map(
            ([category, journeys], index) => (
              <div
                className="scroll-m-32"
                ref={(el) => (sectionRefs.current[category] = el)}
                key={index}
                id={urlFormatter(category)}
              >
                <JourneysCategoryView
                  category={category}
                  journeys={journeys}
                  id={category}
                />
              </div>
            )
          )}
        </div>
      </section>
    </LoadingOverlay>
  );
}

export default JourneysView;
