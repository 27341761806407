import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isLocalDevEnvironment = process.env.REACT_APP_ENVIRONMENT === 'local';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_API_URL],
    }),
  ],
  tracesSampleRate: 0.25,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Non-Error promise rejection captured',
  ],
  enabled: !isLocalDevEnvironment,
});

export default Sentry;
