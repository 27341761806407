import PropTypes from 'prop-types';

import { getLinkagesShape } from './utils';

import asset from './asset';
import range from './range';
import reference from './reference';

const shape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['topic']),
  // used only in the front-end
  //   all have similar ES structure
  //   all have type of topic
  subtype: PropTypes.oneOf(['healthtopic', 'biomarker', 'drug', 'lab']),
  name: PropTypes.string,
  description: PropTypes.string, // html

  keywords: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.string),
  references: PropTypes.arrayOf(reference.shape),
  disclaimer: PropTypes.string,

  thumbnail: PropTypes.string,
  featuredAsset: asset.shape,

  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string, // html
    })
  ),
  ranges: PropTypes.arrayOf(range.shape),
  linkages: PropTypes.shape({
    articles: getLinkagesShape({
      label: PropTypes.string,
    }),
    images: getLinkagesShape(),
    videos: getLinkagesShape(),
    topics: getLinkagesShape(),
  }),

  // published: PropTypes.bool,
  // etag: PropTypes.string,
});

const defaults = {};

export default {
  shape,
  defaults,
};
