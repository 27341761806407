import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { callAll } from 'utils';
import CarouselProvider from './context';
import {
  CarouselBaseNext,
  CarouselBasePrev,
  CarouselNext,
  CarouselPrev,
} from './CarouselNavigation';
import { CarouselPagination } from './CarouselPagination';

import 'swiper/css';

function Carousel({ className, children, slides, carouselProps }) {
  const [carousel, setCarousel] = useState({
    carousel: {},
    slidesCount: Number(slides?.length ?? 0),
  });

  useEffect(() => {
    setCarousel((prev) => ({
      ...prev,
      slidesCount: Number(slides?.length ?? 0),
    }));
  }, [slides]);

  return (
    <CarouselProvider value={carousel}>
      <div className={className}>
        <Swiper
          {...{
            onSwiper: callAll(
              (x) => setCarousel((prev) => ({ ...prev, carousel: x })),
              carouselProps?.onSwiper
            ),
            onSlideChange: callAll(
              (x) => setCarousel((prev) => ({ ...prev, carousel: x })),
              carouselProps?.onSlideChange
            ),
            ...carouselProps,
          }}
        >
          {slides}
        </Swiper>
        {children}
      </div>
    </CarouselProvider>
  );
}

Carousel.BasePrev = CarouselBasePrev;
Carousel.BaseNext = CarouselBaseNext;
Carousel.Prev = CarouselPrev;
Carousel.Next = CarouselNext;
Carousel.Pagination = CarouselPagination;
Carousel.Slide = SwiperSlide;

export default Carousel;

Carousel.propTypes = {
  className: PropTypes.string,
  elements: PropTypes.node,
  carouselProps: PropTypes.object,
};
