import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  scale: PropTypes.oneOf(['lin', 'log']),
  hideunits: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      printSymbol: PropTypes.string,
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      label: PropTypes.shape({
        long: PropTypes.string,
        short: PropTypes.string,
        orientation: PropTypes.string,
      }),
      flag: PropTypes.string,
      text: PropTypes.string,
      conditions: PropTypes.arrayOf(PropTypes.string), // html
    })
  ),
});

const defaults = {};

export default {
  shape,
  defaults,
};
