import React from 'react';

function OverlappingElementsGrid(props) {
  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      style: {
        gridColumnStart: '1',
        gridColumnEnd: 'auto',
        gridRowStart: '1',
        gridRowEnd: 'auto',
      },
    });
  });

  return <div className="grid">{children}</div>;
}

export default OverlappingElementsGrid;
