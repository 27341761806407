import PropTypes from 'prop-types';

import CarouselPaginationButton from './CarouselPaginationButton';

function CarouselPagination({ items = [], currentItem, onSelect }) {
  const handleClick = (item, index) => {
    if (item !== currentItem && onSelect) {
      onSelect(item, index);
    }
  };
  return (
    <ul className="flex flex-wrap">
      {items.map((e, i) => (
        <li className="px-1.5" key={i}>
          <CarouselPaginationButton
            active={e === currentItem}
            onClick={() => handleClick(e, i)}
            index={i + 1}
          />
        </li>
      ))}
    </ul>
  );
}

CarouselPagination.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentItem: PropTypes.any,
  onSelect: PropTypes.func,
};

export default CarouselPagination;
