import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import { FormAttachments } from '../components/forms';

export function AttachmentsInput(props) {
  const newProps = useManualInputDefaultProps(props);
  return <FormAttachments {...newProps} />;
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.ATTACHMENTS, AttachmentsInput);
