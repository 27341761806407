import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCommunityLastLocation } from 'communities/hooks';

export default function SeeConversation({ categoryId, topicId }) {
  const { t } = useTranslation();
  const { pushCommunityLastLocation } = useCommunityLastLocation();

  return (
    <>
      <div className="flex-grow">{t('communities.people-talking')}</div>
      <div className="my-4 flex-none md:m-2">
        <Link
          to={`/communities/${categoryId}/conversation/${topicId}`}
          onClick={() =>
            pushCommunityLastLocation({
              args: { backButtonLabel: t('communities.back-to-hj') },
            })
          }
          as="button"
          className="mt-4 rounded bg-smd-accent px-4 py-3 text-sm text-white hover:bg-smd-accent-dark md:m-0"
        >
          {t('communities.see-conversation')}
        </Link>
      </div>
    </>
  );
}

SeeConversation.propTypes = {
  categoryId: PropTypes.number.isRequired,
  topicId: PropTypes.number.isRequired,
  users: PropTypes.array,
};
SeeConversation.defaultProps = {
  users: [],
};
