import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useIsEmbedded } from 'hooks';
import { ChevronLeft, ChevronRight } from 'components/icons';
import usePrevNextNavigation from './usePrevNextNavigation';

function ViewerPageNavigation(
  { prev: prevParsed, next: nextParsed, nodes, activeId, prevLink, nextLink },
  ref
) {
  const { t } = useTranslation();
  const { prev: prevNode, next: nextNode } = usePrevNextNavigation(
    nodes,
    activeId
  );

  const isEmbedded = useIsEmbedded();

  const prev = prevNode ?? prevParsed;
  const next = nextNode ?? nextParsed;

  const changeToEmbedIfNeeded = (link) => {
    if (!isEmbedded) return link;
    return link.replace('journal', 'embed');
  };

  return (
    <div className="mx-auto flex max-w-4xl justify-between py-8" ref={ref}>
      <div className="max-w-1/2">
        {prev && prevLink && (
          <Link
            to={changeToEmbedIfNeeded(prevLink)}
            className="smd-focus-visible-primary flex items-center space-x-2 rounded-sm text-smd-accent"
            aria-label={t('labels.journals.article-prev', {
              article: prev?.label,
            })}
          >
            <ChevronLeft
              strokeWidth="2"
              className="h-10 w-10 shrink-0 rounded-full border border-smd p-2 shadow-md"
            />
            <div className="grid pr-1 text-left text-xs lg:pr-10">
              <p className="truncate uppercase text-smd-secondary">
                {t('common.previous')}
              </p>
              <p className="truncate font-semibold">{prev.label}</p>
            </div>
          </Link>
        )}
      </div>
      <div className="max-w-1/2">
        {next && nextLink && (
          <Link
            to={changeToEmbedIfNeeded(nextLink)}
            className="smd-focus-visible-primary flex items-center space-x-2 rounded-sm text-smd-accent"
            aria-label={t('labels.journals.article-next', {
              article: next?.label,
            })}
          >
            <div className="grid pl-1 text-right text-xs">
              <p className="truncate uppercase text-smd-secondary">
                {t('common.next')}
              </p>
              <p className="truncate font-semibold">{next.label}</p>
            </div>
            <ChevronRight
              strokeWidth="2"
              className="h-10 w-10 shrink-0 rounded-full border border-smd p-2 shadow-md"
            />
          </Link>
        )}
      </div>
    </div>
  );
}

export default forwardRef(ViewerPageNavigation);
