import { useTranslation } from 'react-i18next';

import { useIsFeatureEnabled, useSocialMediaProviders } from 'hooks';
import { SquareButton } from 'components/forms';
import { X } from 'components/icons';
import Modal from 'components/Modal';
import ShareUrl from './ShareUrl';
import ShareSocial from './ShareSocial';

function ShareModal({
  isOpen,
  onClose,
  title,
  shareUrl,
  children,
  socialMediaSharing = true,
  linkSharing = true,
  withNewsletterParam = true,
}) {
  const { t } = useTranslation();
  const socials = useSocialMediaProviders();

  const isNewsletterEnabled = useIsFeatureEnabled(
    useIsFeatureEnabled.NEWSLETTER
  );

  const query = isNewsletterEnabled ? '?newsletter=show' : '';

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.ResponsiveContainer
        className="relative md:max-w-md"
        role="dialog"
        aria-modal="true"
      >
        <div className="w-screen flex-grow bg-white p-4 md:p-8">
          <div className="relative flex w-full flex-col items-center">
            <SquareButton
              className="absolute top-0 right-0"
              onClick={onClose}
              aria-label={t('labels.common.close')}
            >
              <X strokeWidth="3" />
            </SquareButton>
            <h1 className="max-w-xs text-center text-smd-sm font-semibold uppercase">
              {title || t('journals.share.share-with-others')}
            </h1>
            {children}
            {shareUrl && socialMediaSharing && (
              <ShareSocial socials={socials} className="mt-6" url={shareUrl} />
            )}
            {shareUrl && linkSharing && (
              <ShareUrl
                url={withNewsletterParam ? `${shareUrl}${query}` : shareUrl}
              />
            )}
          </div>
        </div>
      </Modal.ResponsiveContainer>
    </Modal>
  );
}

export default ShareModal;
