import { useStoreLastLocation } from 'hooks';

export default function useJourneyLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'journey-navigation-origin',
  });

  return {
    setJourneyLastLocation: set,
    pushJourneyLastLocation: push,
    popJourneyLastLocation: pop,
    journeyLastLocation: lastLocation,
  };
}
