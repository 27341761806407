export const OCR_STATUS = Object.freeze({
  DRAFT: 'DRAFT',
});

export const FILE_STATUS = Object.freeze({
  UPLOADING: 'UPLOADING',
  IN_PROGRESS: 'IN_PROGRESS',
  OCR_COMPLETED: 'OCR_COMPLETED',
  COMPLETED: 'COMPLETED',
});

export const STEPS = Object.freeze({
  GET_STARTED: 'GET_STARTED',
  UPLOAD_FILE: 'UPLOAD_FILE',
  RESULT_PREVIEW: 'RESULT_PREVIEW',
  EDIT_FORM: 'EDIT_FORM',
});
