import { path } from 'ramda';
import classnames from 'classnames';
import { ErrorLabel } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

const base = [
  'checked:bg-smd-accent hover:checked:bg-smd-accent checked:ring-smd-accent-light smd-focus-visible-primary',
  'mr-2',
];

function FormCheckbox(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    setValue,
    getValues,
    setError,
    watch,
    resetField,
    ...rest
  } = props;
  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const border = error
    ? 'border-red-500 focus:border-red-500'
    : 'border-smd focus:border-smd-accent';

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      <Label
        htmlFor={id}
        {...labelProps}
        className="inline-flex cursor-pointer select-none items-center space-x-2 hover:underline"
        data-testid="doctorOrCoachCheckBox"
      >
        <input
          type="checkbox"
          id={id}
          className={classnames(base, border, className)}
          {...register(name, rules)}
          {...rest}
        />
        {label}
      </Label>
      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormCheckbox);

export default FormCheckbox;
