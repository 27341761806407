import {
  BookOpen,
  Healthjournal,
  Medication,
  Microscope,
} from 'components/icons';

const icons = {
  healthtopic: Healthjournal,
  event: BookOpen,
  biomarker: Microscope,
  drug: Medication,
};

function useArticleInfo(article) {
  const {
    parents,
    title: articleTitle,
    subtype,
    action,
    link,
    url: articleUrl,
  } = article;

  const parent = parents?.[0] ?? article?.parent;
  const isHealthTopic =
    parent?.subtype === 'healthtopic' || subtype === 'healthtopic';
  const title = parent?.title ?? articleTitle;
  const url = link ?? parent?.url ?? articleUrl;

  const Icon = icons[parent?.subtype ?? subtype];
  return { isHealthTopic, parent, title, url, Icon, action };
}

export default useArticleInfo;
