import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { useMedia, useWindowSize } from 'hooks';

const BEZIER_RADIUS = 100;

function extractChildrenRect(children) {
  const childRects = [];
  for (const child of children) {
    childRects.push(child?.getBoundingClientRect());
  }
  return childRects;
}

function anglePointEntry({ xStart, xEnd, yStart, yEnd }, isEven = false) {
  return {
    output: isEven
      ? [
          `L${xStart},${yEnd - BEZIER_RADIUS}`,
          `Q${xStart},${yEnd} ${xStart - BEZIER_RADIUS},${yEnd}`,
          `L${xEnd + BEZIER_RADIUS},${yEnd}`,
          `Q${xEnd},${yEnd} ${xEnd},${yEnd + BEZIER_RADIUS}`,
        ].join(' ')
      : [
          `L${xStart},${yEnd - BEZIER_RADIUS}`,
          `Q${xStart},${yEnd} ${xStart + BEZIER_RADIUS},${yEnd}`,
          `L${xEnd - BEZIER_RADIUS},${yEnd}`,
          `Q${xEnd},${yEnd} ${xEnd},${yEnd + BEZIER_RADIUS}`,
        ].join(' '),
    coords: {
      xStart,
      xEnd,
      yStart,
      yEnd,
    },
  };
}

function singleAnglePointEntry({ xStart, yEnd }) {
  return {
    output: `L${xStart},${yEnd}`,
    coords: {
      xStart,
      yEnd,
    },
  };
}

function generateSVGPath(container) {
  const children = container.children;
  const childRects = extractChildrenRect(children);

  let pathStart = 'M0,0';
  const svgSegments = [];

  for (let i = 0; i < childRects.length; i++) {
    const isFirst = i === 0;
    const isLast = i === childRects.length - 1;
    const isEven = (i + 1) % 2 === 0;

    const elRect = childRects[i];
    const xStart = parseInt(elRect?.width * 0.25);
    const xEnd = parseInt(elRect?.width * 0.75);
    const yStart = parseInt(elRect?.height * 0.5);
    const yEnd = parseInt(elRect?.height);

    if (isFirst) {
      pathStart = `M${xStart},${yStart}`;
      svgSegments.push(anglePointEntry({ xStart, xEnd, yStart, yEnd }));
    } else if (isLast) {
      const prevCoords = svgSegments[i - 1]?.coords;
      svgSegments.push(
        singleAnglePointEntry({
          xStart: prevCoords.xEnd,
          yEnd: prevCoords.yEnd + yStart,
        })
      );
    } else {
      const prevCoords = svgSegments[i - 1]?.coords;
      svgSegments.push(
        anglePointEntry(
          {
            xStart: prevCoords.xEnd,
            xEnd: prevCoords.xStart,
            yStart: prevCoords.yStart + yStart,
            yEnd: prevCoords.yEnd + yEnd,
          },
          isEven
        )
      );
    }
  }

  return `${pathStart} ${svgSegments
    .map((point) => point.output.trim())
    .join(' ')}`;
}

function FeatureConnector({ containerRef, className, style, ...rest }) {
  const isMedium = useMedia(useMedia.MEDIUM);
  const { width } = useWindowSize();

  const [containerBoundaries, setContainerBoundaries] = useState({});
  const [path, setPath] = useState('');

  useEffect(() => {
    const container = containerRef?.current;

    if (container) {
      setContainerBoundaries(() => container?.getBoundingClientRect());
      setPath(() => generateSVGPath(container));
    }
  }, [containerRef, width]);

  const svgStyle = {
    strokeWidth: 1,
    fill: 'none',
    ...style,
  };

  if (!isMedium) return null;

  return (
    <svg
      viewBox={`0 0 ${containerBoundaries?.width ?? 0} ${
        containerBoundaries?.height ?? 0
      }`}
      style={svgStyle}
      className={classnames(
        'pointer-events-none absolute inset-0 select-none stroke-current text-smd-accent',
        className
      )}
      {...rest}
    >
      <path vectorEffect="non-scaling-stroke" strokeLinecap="round" d={path} />
    </svg>
  );
}

export default FeatureConnector;
