import { useTranslation } from 'react-i18next';
import { Modal, Video } from 'components';
import { SquareButton } from 'components/forms';
import { X } from 'components/icons';
import { useModalClose } from 'components/Modal/useModalClose';
import { getAssetUrl } from 'utils';

function FocusVideoModal({ isOpen, onClose, autoPlayMuted, fileKey }) {
  const { t } = useTranslation();
  const closeProps = useModalClose(onClose);

  const modalVideo = {
    controls: true,
    thumbnail: '',
    url: getAssetUrl(fileKey),
    width: '100%',
    height: '100%',
    playPause: { playing: isOpen },
    autoPlay: true,
    autoPlayMuted,
    wrapperClassName: 'bg-smd-gray-darker',
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="z-50">
      <SquareButton
        className="absolute top-0 right-0 z-10"
        onClick={onClose}
        aria-label={t('labels.common.close')}
      >
        <X strokeWidth="3" />
      </SquareButton>
      <div
        className="flex h-screen w-screen max-w-7xl items-center justify-center overflow-hidden p-6 lg:p-20"
        role="dialog"
        aria-modal="true"
        {...closeProps}
      >
        <div className="relative h-full w-full">
          <Video {...modalVideo} />
        </div>
      </div>
    </Modal>
  );
}

export default FocusVideoModal;
