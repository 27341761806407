import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Avatar } from 'components';
import { ChevronDown, X as Close } from 'components/icons';
import { usePartner } from 'PartnerContext';

function UserMenuToggle({ open, profileName, profileAvatarUrl, ...props }) {
  const { t } = useTranslation();
  const { currentPartner } = usePartner();

  return (
    <button
      type="button"
      className="smd-focus-visible-primary flex max-w-[25ch] items-center space-x-2 rounded-sm p-1"
      aria-label={
        open ? t('labels.profile.close-menu') : t('labels.profile.open-menu')
      }
      aria-haspopup="menu"
      aria-expanded={open}
      {...props}
    >
      <div
        className={classnames('relative', {
          'rounded border-1 px-2': Boolean(currentPartner),
          'flex py-1': process.env.REACT_APP_PRO_ACCOUNTS_STATE === 'ENABLED',
        })}
      >
        {currentPartner && (
          <Avatar className="mr-3 h-8 w-8" src={currentPartner.lightLogo} />
        )}
        <Avatar className="h-8 w-8" src={profileAvatarUrl} />
        {open && (
          <div className="absolute -inset-1 grid place-content-center rounded bg-white 2xl:hidden">
            <Close className="h-6 w-6 stroke-2" />
          </div>
        )}
      </div>

      <div className="hidden items-center overflow-hidden 2xl:flex">
        {process.env.REACT_APP_PRO_ACCOUNTS_STATE === 'DISABLED' && (
          <div className="shrink truncate">{profileName}</div>
        )}
        <ChevronDown
          strokeWidth="2"
          className={`ml-1 h-5 w-5 shrink-0 transform transition-transform duration-200 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>
    </button>
  );
}

export default UserMenuToggle;
