import React from 'react';

import { Logo } from 'components/icons';
import { useDistribution } from 'hooks';

function LoadingComponent() {
  const customLogo = useDistribution(['mainLogo']);

  return (
    <div className="flex-center h-screen w-screen bg-smd-gray-lighter bg-opacity-25">
      {customLogo?.src ? (
        <img
          className="w-1/2 md:w-1/4 lg:w-1/6"
          src={customLogo.src}
          alt={customLogo.alt ?? 'logo'}
        />
      ) : (
        <Logo className="w-1/2 md:w-1/4 lg:w-1/6" />
      )}
    </div>
  );
}

export default LoadingComponent;
