import React from 'react';
import classnames from 'classnames';

import NoScrollContainer from 'components/NoScrollContainer';

const NavContext = React.createContext();
export function Nav({ active, onSelect, ...props }) {
  return (
    <NavContext.Provider value={{ active, onSelect }}>
      <ul
        className="flex h-full items-center px-4 text-smd-gray-light"
        {...props}
      />
    </NavContext.Provider>
  );
}

Nav.Item = function NavItem({ name, onClick, ...props }) {
  const { active, onSelect } = React.useContext(NavContext);

  const isActive = active === name;

  return (
    <li className="shrink-0">
      <button
        className={classnames(
          'px-4 py-3',
          'border-b-2 border-transparent',
          {
            'font-bold': isActive,
            'text-white': isActive,
            'border-current': isActive,
          },
          'focus:outline-none'
        )}
        onClick={(...args) => {
          onSelect?.(name);
          onClick?.(...args);
        }}
        {...props}
      />
    </li>
  );
};

function LibraryTopicModalNavbar({ showTopSection, left, right }) {
  return (
    <div className="sticky top-0 z-10 lg:flex">
      <div className="h-14 flex-1 bg-smd-accent">{left}</div>

      <NoScrollContainer
        className={classnames(
          'absolute inset-0 h-14 transform bg-smd-accent transition-transform lg:static lg:inset-auto lg:transition-none',
          {
            'translate-y-14': showTopSection,
            'lg:translate-y-0': showTopSection,
          }
        )}
      >
        {right}
      </NoScrollContainer>
    </div>
  );
}

export default LibraryTopicModalNavbar;
