import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { CTA } from 'components';
import { useMedia } from 'hooks';
import useEnabledFeatures from '../useEnabledFeatures';

function AuthMenuItems({ profile, isScrolled }) {
  const { t } = useTranslation();

  const { isSignUpEnabled } = useEnabledFeatures();

  return (
    isSignUpEnabled &&
    !profile && (
      <div className="flex flex-col space-y-2 2xs:space-y-0 lg:p-4">
        <div>
          <SignInButton
            isScrolled={isScrolled}
            className="block w-full 3xs:hidden"
          />
        </div>
        <CTA
          color="primary"
          className="font-semibold"
          linkClassName="smd-focus-visible-primary"
          ctaProps={{ ctaAction: 'register' }}
          aria-label={t('labels.menu.register')}
        >
          {t('common.signup')}
        </CTA>
      </div>
    )
  );
}

export function SignInButton({ isScrolled, className }) {
  const { t } = useTranslation();

  const isSmall = useMedia(useMedia.SMALL);
  const isLarge = useMedia(useMedia.LARGE);
  const isNotScrolledOnLargeScreen = isLarge && !isScrolled;

  return (
    <CTA
      color={isNotScrolledOnLargeScreen || isSmall ? 'ghost' : 'primary'}
      ctaProps={{ ctaAction: 'login' }}
      className={classnames(
        'truncate font-semibold',
        className ||
          'hidden h-8 text-smd-xs 3xs:block sm:h-full sm:text-smd-base'
      )}
      linkClassName="smd-focus-visible-primary rounded-sm"
      aria-label={t('labels.menu.sign-in')}
      data-testid="menuSignInLink"
    >
      {t('common.signin')}
    </CTA>
  );
}

export default AuthMenuItems;
