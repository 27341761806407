import PropTypes from 'prop-types';

import { useSensitiveAssetViewRestriction } from 'hooks';
import asset from '../../propTypes/asset';
import LibraryAssetCard, { LibraryAssetCardSkeleton } from './LibraryAssetCard';

export function LibraryAssetListSkeleton({ count, isLarge }) {
  return (
    <LibraryAssetList
      AssetCard={LibraryAssetCardSkeleton}
      assets={Array(count).fill()}
      gridTemplateColumns={
        isLarge && 'repeat(auto-fill, minmax(min(280px, 100%), 1fr))'
      }
    />
  );
}

LibraryAssetListSkeleton.propTypes = {
  count: PropTypes.number,
};

LibraryAssetListSkeleton.defaultProps = {
  count: 3,
};

function SensitiveAssetWrapper({ onClick, AssetCard, asset }) {
  const { isRestricted, setIsRestricted } =
    useSensitiveAssetViewRestriction(asset);
  return (
    <li className="aspect-w-6 aspect-h-5 cursor-pointer">
      <button
        className="smd-focus-visible-primary appearance-none rounded-sm text-left"
        onClick={isRestricted ? () => {} : onClick}
      >
        <AssetCard
          asset={asset?.featuredAsset}
          onThumbnailUnblur={setIsRestricted}
        />
      </button>
    </li>
  );
}

function LibraryAssetList({ assets, onClick, AssetCard, gridTemplateColumns }) {
  return (
    <ul
      style={{
        display: 'grid',
        gridTemplateColumns,
        gap: '1rem',
      }}
    >
      {assets?.map((asset, index) => {
        return (
          <SensitiveAssetWrapper
            key={index}
            index={index}
            onClick={() => onClick(asset, index)}
            AssetCard={AssetCard}
            asset={asset}
          />
        );
      })}
    </ul>
  );
}

LibraryAssetList.propTypes = {
  assets: PropTypes.arrayOf(asset.shape),
  onClick: PropTypes.func,
  AssetCard: PropTypes.elementType,
  gridTemplateColumns: PropTypes.string.isRequired,
};

LibraryAssetList.defaultProps = {
  assets: [],
  onClick: () => {},
  AssetCard: LibraryAssetCard,
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
};

export default LibraryAssetList;
