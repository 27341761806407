import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { prop } from 'ramda';

import { useLibraryApi } from 'library/libraryApiContext';
import { useTopicLastLocation } from 'library/hooks';
import { useJournalLastLocation } from 'journals/hooks';
import { OutsideClickContainer } from 'components';
import { Play } from 'components/icons';

import { useJournalsApi } from 'journals/context/journalsApiContext';
import { getAssetUrl } from 'utils';
import { isAbsolute } from 'journals/utils';
import Portal from 'components/Portal';
import { useParentElementContext } from 'journals/viewer/ParentContext';
import { LibraryTopicModal } from 'library/components/topic';
import { LibraryGlossaryModal } from 'library/components/glossary';

import { getSlugHead, slugifyObject } from 'utils/slug';
import { safeHead } from 'utils';
import { libraryPath } from 'library/const';
import { viewerPath } from 'journals/viewer/const';

import PopOverCard from './PopOverCard';

const popOverWidthInPx = 560; //35rem
const initState = {
  top: 0,
  left: 0,
  upside: false,
  markerLeft: 0,
  width: 0,
  displayPopover: false,
};

function init(payload = initState) {
  return { ...payload };
}

function getPopOverPosition(target, parent, popOverHeight) {
  const targetLeftCorner = target.x - parent.x;

  const targetYaxisBottomPosition = target.y - parent.y;

  let width = Math.min(parent.width, popOverWidthInPx);
  let top = targetYaxisBottomPosition + target.height;
  const upside =
    target.y + popOverHeight > document.documentElement.offsetHeight;
  if (upside) {
    top = targetYaxisBottomPosition - popOverHeight;
  }
  let left;
  let markerLeft;
  if (parent.width > targetLeftCorner + width) {
    left = targetLeftCorner;
    markerLeft = 0;
  } else {
    left = parent.width - width;
    markerLeft = width - (parent.width - targetLeftCorner);
  }

  return {
    top,
    left,
    width,
    upside,
    markerLeft,
    displayPopover: true,
  };
}

function reducer(state, action) {
  const { target, parent, popOverHeight } = action.payload || {};

  switch (action.type) {
    case 'showPopover': {
      return target && parent
        ? getPopOverPosition(target, parent, popOverHeight)
        : init();
    }
    case 'hidePopover':
    default: {
      return init();
    }
  }
}

function Marker({ left, upside }) {
  return (
    <Play
      style={{ marginLeft: left }}
      className={`h-3.5 w-3.5 transform opacity-30 ${
        upside ? 'rotate-90' : '-rotate-90'
      }`}
    />
  );
}

function getDataLibrary(event) {
  return event?.target?.getAttribute('data-library');
}

function getLibraryHref(event) {
  return event?.target?.getAttribute('href');
}

function LibraryCardPopOver({ children }) {
  const history = useHistory();

  const api = useLibraryApi();
  const journalsApi = useJournalsApi();

  const parent = useParentElementContext();
  const [popOverPositionState, dispatch] = useReducer(reducer, initState, init);
  const { top, left, upside, markerLeft, width, displayPopover } =
    popOverPositionState;
  const [currentTarget, setCurrentTarget] = useState(null);
  const [libraryAsset, setLibraryAsset] = useState({});
  const [modalLibrary, setModalLibrary] = useState(null);
  const libraryCardWrapperRef = useRef();

  const { pushTopicLastLocation } = useTopicLastLocation({
    onEvent: ({ method, eventArgs }) => {
      if (method === 'push') {
        history.push(`/${libraryPath.TOPIC}/${eventArgs.slug}`);
      }
    },
  });
  const { pushJournalLastLocation } = useJournalLastLocation({
    onEvent: ({ method, eventArgs }) => {
      if (method === 'push') {
        history.push(`/${viewerPath.JOURNAL}/${eventArgs.slug}`);
      }
    },
  });

  function clearState() {
    if (displayPopover) {
      setCurrentTarget(null);
      dispatch({ type: 'hidePopover' });
    }
  }

  function hideModal() {
    setModalLibrary(null);
  }

  function showModal(libraryAsset) {
    setModalLibrary(libraryAsset);
  }

  const dataLibraryCache = useRef({});
  const journalsCache = useRef({});

  function extractLibrary(dataLibrary, action) {
    if (dataLibrary) {
      if (!dataLibraryCache.current[dataLibrary]) {
        dataLibraryCache.current[dataLibrary] = api.getLibraryContentByIds([
          dataLibrary,
        ]);
      }
      dataLibraryCache.current[dataLibrary].then((data) => {
        const library = data?.[dataLibrary];
        if (library) action(library);
      });
    }
  }

  function extractJournal(journalKey, action) {
    if (journalKey) {
      if (!journalsCache.current[journalKey]) {
        journalsCache.current[journalKey] =
          journalsApi.getDefinitionByKey(journalKey);
      }
      journalsCache.current[journalKey].then((data) => {
        if (data) action(data);
      });
    }
  }

  useEffect(() => {
    if (currentTarget)
      dispatch({
        type: 'showPopover',
        payload: {
          target: currentTarget.getBoundingClientRect(),
          parent: parent.current.getBoundingClientRect(),
          popOverHeight:
            libraryCardWrapperRef.current.getBoundingClientRect().height,
        },
      });
  }, [currentTarget, libraryAsset, parent]);
  const currentDataLibrary = useRef();

  function extractHref(e) {
    const libraryHref = getLibraryHref(e);

    const [type, slug] = libraryHref
      ? libraryHref?.split('/').filter(Boolean)
      : [];

    return { type, slug, link: libraryHref };
  }

  function handleMouseOver(e) {
    const { type, slug } = extractHref(e);
    if (!e.disablePopOver && !displayPopover && type === 'journal') {
      const journalKey = getSlugHead(slug);
      currentDataLibrary.current = journalKey;
      extractJournal(journalKey, (journal) => {
        if (journalKey === currentDataLibrary.current) {
          setCurrentTarget(e.target);
          setLibraryAsset({
            type: 'journal',
            id: journal.id,
            journalSlug: slugifyObject(journal.journalKey, journal.name),
            description: journal.preamble.about,
            label: '',
            name: journal?.name,
            thumbnail: safeHead(journal.preamble.heroes)?.thumbnailUrl,
          });
        }
      });
    } else if (!e.disablePopOver && !displayPopover) {
      let dataLibrary = getDataLibrary(e);
      currentDataLibrary.current = dataLibrary;
      extractLibrary(dataLibrary, (libraryAsset) => {
        if (dataLibrary === currentDataLibrary.current) {
          setCurrentTarget(e.target);
          setLibraryAsset({
            ...libraryAsset,
            topicSlug:
              type === 'topic'
                ? slugifyObject(libraryAsset.id, libraryAsset.name)
                : null,
          });
        }
      });
    } else if (!libraryCardWrapperRef.current?.contains(e.target)) {
      clearState();
    }
  }

  function extractHrefAndNavigate(e) {
    const { type, slug, link } = extractHref(e);
    // keep link behavior and return if control / command key is pressed during click for navigating to new tab
    if (e.metaKey || e.ctrlKey) return;
    // if not a journal or topic always prevent default for links
    e.preventDefault();

    // open the external link  in a new tab
    if (isAbsolute(link)) {
      return window.open(link);
    }
    // open the journal / topic in a new page
    if (type === 'journal') {
      return pushJournalLastLocation({ eventArgs: { slug } });
    }
    if (type === 'topic') {
      return pushTopicLastLocation({ eventArgs: { slug } });
    }
  }

  function handleClick(e) {
    if (
      e.target.tagName.toLowerCase() === 'a' &&
      Boolean(e.target.getAttribute('href')) &&
      !e.target.dataset.reference
    ) {
      return extractHrefAndNavigate(e);
    }
    if (!e.disablePopOver) {
      let dataLibrary = getDataLibrary(e);
      currentDataLibrary.current = dataLibrary;
      extractLibrary(dataLibrary, (libraryAsset) => {
        if (dataLibrary === currentDataLibrary.current) {
          return showModal(libraryAsset);
        }
      });
    }
  }

  const popOverAnimationClasses = `relative z-10 ${
    displayPopover
      ? upside
        ? 'animate-fade-in-down'
        : 'animate-fade-in-up'
      : 'hidden'
  }`;

  return (
    <div onClick={handleClick} onMouseOver={handleMouseOver}>
      {children}
      <Portal container={parent?.current}>
        <OutsideClickContainer
          onOutsideClick={clearState}
          style={{ top, left, width }}
          className={'absolute'}
          ref={libraryCardWrapperRef}
        >
          <div
            data-testid="popover-container"
            key={libraryAsset?.id}
            className={popOverAnimationClasses}
            onClick={() => {
              // Navigate to new page for journals or topics if href is provided
              // Otherwise open modal for topic or glossary
              if (
                libraryAsset?.type === 'journal' &&
                libraryAsset?.journalSlug
              ) {
                return pushJournalLastLocation({
                  eventArgs: { slug: libraryAsset?.journalSlug },
                });
              }
              if (libraryAsset?.type === 'topic' && libraryAsset?.topicSlug) {
                return pushTopicLastLocation({
                  eventArgs: { slug: libraryAsset?.topicSlug },
                });
              }
              return showModal(libraryAsset);
            }}
          >
            {!upside && <Marker top={0} left={markerLeft} />}
            {libraryAsset && (
              <PopOverContent
                type={libraryAsset.type}
                content={libraryAsset.description}
                label={libraryAsset.label}
                title={libraryAsset.name}
                imageSrc={
                  libraryAsset.thumbnail && getAssetUrl(libraryAsset.thumbnail)
                }
              />
            )}
            {upside && <Marker top={0} left={markerLeft} upside={true} />}
          </div>
        </OutsideClickContainer>
      </Portal>

      <div
        onMouseOver={(e) => (e.disablePopOver = true)}
        onClick={(e) => (e.disablePopOver = true)}
      >
        <ModalComponent
          type={modalLibrary?.type}
          isOpen={modalLibrary}
          hashId={modalLibrary?.id}
          onRequestClose={hideModal}
        />
      </div>
    </div>
  );
}

function PopOverContent({ type, ...props }) {
  switch (type) {
    case 'journal':
    case 'topic':
      return (
        <PopOverCard
          hasBackground={true}
          className="cursor-pointer bg-black"
          {...props}
        />
      );
    case 'glossary':
      return <PopOverCard className="cursor-pointer bg-white" {...props} />;
    default:
      return null;
  }
}

function ModalComponent({ type, ...props }) {
  return type === 'topic' ? (
    <LibraryTopicModal {...props} />
  ) : type === 'glossary' ? (
    <LibraryGlossaryModal {...props} />
  ) : null;
}
const LibraryPopOverContext = createContext();

function LibraryPopOverProvider({ children }) {
  const childrenContent = useCallback(
    (disable) => (props) => {
      return React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          onMouseOver: (e) => {
            e.disablePopOver = disable;
          },
          onClick: (e) => {
            e.disablePopOver = disable;
          },
        });
      });
    },
    []
  );
  return (
    <LibraryPopOverContext.Provider value={childrenContent}>
      <LibraryCardPopOver>{children}</LibraryCardPopOver>
    </LibraryPopOverContext.Provider>
  );
}

const Identity = prop('children');

export function useLibraryPopOver(disable) {
  let libraryPopOverProvider = useContext(LibraryPopOverContext);
  if (!libraryPopOverProvider) {
    libraryPopOverProvider = disable
      ? () => Identity
      : () => LibraryPopOverProvider;
  }
  return libraryPopOverProvider(disable);
}

export default {
  useLibraryPopOver,
};
