import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from './keycloak';

const initOptions = {
  onLoad: 'check-sso',
  pkceMethod: 'S256',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
};

function getRedirectUri(locationUrl) {
  const myUrlWithParams = new URL(locationUrl);
  myUrlWithParams.searchParams.delete('idpHint');
  return myUrlWithParams.href;
}

function AuthProvider({ fallback = null, children, ...props }) {
  const [authReady, setAuthReady] = useState(false);

  const onReadyHandler = () => {
    if (window.location.pathname.includes('/signup')) {
      const signAs = new URLSearchParams(window.location.search).get('sign-as');
      const shortHealthUrl =
        window.location.search.includes('?smd-health=true');
      const redirectUri = signAs
        ? `${window.location.origin}/journeys?sign-as=${signAs}`
        : shortHealthUrl
        ? `${window.location.origin}/health/setup/profile`
        : `${window.location.origin}/journeys`;

      return keycloak.register({ redirectUri });
    }
    if (window.location.pathname === '/signin') {
      return keycloak.login({
        redirectUri: `${window.location.origin}/journeys`,
      });
    }

    const idpHint = new URLSearchParams(window.location.search).get('idpHint');
    if (idpHint) {
      return keycloak.login({
        redirectUri: getRedirectUri(window.location.href),
        idpHint,
      });
    }

    setAuthReady(true);
  };

  const handleTokenExpired = () =>
    keycloak.updateToken().catch(forceUpdateToken);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      autoRefreshToken={false}
      onEvent={(event) => {
        if (event === 'onReady') {
          return onReadyHandler();
        }
        if (event === 'onTokenExpired') {
          return handleTokenExpired();
        }
      }}
      {...props}
    >
      {authReady ? children : fallback}
    </ReactKeycloakProvider>
  );
}

export function useAuth() {
  const { keycloak, initialized } = useKeycloak();
  return {
    attributes: keycloak.tokenParsed,
    initialized: initialized,
    authenticated: Boolean(keycloak.authenticated),
    login: keycloak.login,
    // redirecting on logout to the root, removing state from the history
    // (problem that is caused by not passing a redirectUri, on explore page,
    // the channel tab gets pre-selected for unregistered users and it triggers an error)
    logout: () =>
      keycloak.logout({ redirectUri: `${window.location.origin}/` }),
    hasRole: (role) =>
      keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role),
  };
}

export function ProtectedRoute({ components = {}, roles, ...rest }) {
  const { authenticated, hasRole } = useAuth();

  const { private: PrivateComponent, public: PublicComponent } = components;

  const isAuthorized = (roles) => {
    return roles ? authenticated && roles.some(hasRole) : authenticated;
  };

  // const renderWithProps = (Comp, props) =>
  //   typeof Comp === 'function' ? Comp(props) : <Comp {...props} />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (PrivateComponent && isAuthorized(roles)) {
          return <PrivateComponent {...props} />;
        }
        if (PublicComponent) {
          return <PublicComponent {...props} />;
        }
        return <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
}

export const Roles = {
  ADMIN: 'administrator',
  PATIENT: 'patient',
  HP: 'health_professional',
};

export const forceUpdateToken = () => keycloak.updateToken(Infinity);
export const getToken = () => keycloak.token;
export const doLogout = () =>
  keycloak.logout({ redirectUri: window.location.origin });

export default {
  Provider: AuthProvider,
};
