import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useJourneys } from 'hooks';
import { extractWebpThumbnail, getFocalPosition } from 'utils';

import { AlternatingTextColorElement, ContentContainer } from 'components';
import { Button } from 'components/forms';
import { GradientBackground, PictureResource } from 'components/media';

import { useJourneyLastLocation } from 'journals/hooks';
import { NewsletterSubscribeButton } from 'components/Newsletter';

function FeaturedJourney() {
  const { t } = useTranslation();

  const { setJourneyLastLocation } = useJourneyLastLocation();

  const { featured, isFetched } = useJourneys();

  const journeyAsset = featured?.featuredAsset ?? {};

  const { position, isValid } = getFocalPosition(journeyAsset.focalPoint);
  const webpThumbnail = extractWebpThumbnail(journeyAsset?.images);

  return (
    isFetched && (
      <div className="relative">
        <PictureResource
          config={{
            url:
              webpThumbnail?.path || journeyAsset.thumbnail || journeyAsset.url,
            alt: journeyAsset.alt,
            images: journeyAsset.images,
          }}
          style={{
            objectPosition: isValid ? position : 'center',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          loading="eager"
          pictureClassName="absolute inset-0"
        />
        <GradientBackground className="absolute inset-0" />
        <div className="px-4 lg:px-12">
          <ContentContainer className="relative space-y-8 pt-64 pb-8 text-white md:py-32">
            <div className="italic">{t('journals.journeys.featured')}</div>
            <div className="flex space-x-1 text-2xl font-bolder tracking-widest md:text-3xl">
              <i className="storymd-plus" />
              <AlternatingTextColorElement
                name={featured?.name}
                evenColor="text-smd-gray"
                oddColor="text-smd-accent-bright"
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: featured?.description }} />
            <div className="flex flex-row items-start space-x-3.5 lg:space-x-6">
              <Link
                to={`/collections?tag=${featured?.tag}`}
                onClick={() => setJourneyLastLocation()}
                className="smd-focus-visible-primary rounded"
              >
                <Button.Primary tabIndex={-1}>
                  {t('main-menu.explore')}
                </Button.Primary>
              </Link>
              <NewsletterSubscribeButton isFeatured />
            </div>
          </ContentContainer>
        </div>
      </div>
    )
  );
}

export default FeaturedJourney;
