import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, Form, Label } from 'components/forms';
import { EMOJI_REGEXP, SCRIPT_REGEXP } from '../utils';
import QuillInput from '../QuillInput/QuillInputLazy';

const MAX_MESSAGE_LENGTH = 5500;
const MAX_TITLE_LENGTH = 550;
const MIN_TITLE_LENGTH = 3;

export default function ConversationForm({
  onSubmit,
  defaultValues,
  inEditMode,
  isLoading,
  onClose,
  handleLibraryPickButtonClick,
  className,
  libraryAssets,
  onRemoveAsset,
}) {
  const { t } = useTranslation();
  const formClasses = classNames('w-full', className);
  return (
    <Form
      className={formClasses}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <Form.Input
        className="mt-2 mb-4 w-full border px-2 py-2"
        label={`${t('communities.title')}*`}
        autoFocus
        name="title"
        placeholder={t('communities.ask-something')}
        rules={{
          required: t('communities.forms.required-title'),
          maxLength: {
            value: MAX_TITLE_LENGTH,
            message: t('errors.max-length', {
              field: t('communities.title'),
              value: MAX_TITLE_LENGTH,
            }),
          },
          pattern: {
            value: EMOJI_REGEXP,
            message: t('communities.forms.emoji-not-allowed'),
          },
          validate: {
            notBlank: (value) => {
              return (
                value?.trim().length > 0 ||
                t('communities.forms.required-title')
              );
            },
            minLength: (value) => {
              return (
                value?.trim().length >= MIN_TITLE_LENGTH ||
                t('communities.forms.min-length-title')
              );
            },
          },
        }}
      />
      <Label>{t('communities.message')}</Label>
      <Form.InputWrapper>
        <QuillInput
          handleLibraryPickButtonClick={handleLibraryPickButtonClick}
          className="w-full"
          placeholder={t('communities.share-or-ask')}
          name="content"
          rules={{
            required: t('communities.forms.required-message'),
            maxLength: {
              value: MAX_MESSAGE_LENGTH,
              message: t('errors.max-length', {
                field: t('communities.message'),
                value: MAX_MESSAGE_LENGTH,
              }),
            },
            validate: {
              htmlScriptValidation: (value) => {
                const decode = (input) =>
                  input.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
                return (
                  new RegExp(SCRIPT_REGEXP).test(decode(value)) ||
                  t('communities.forms.script-not-allowed')
                );
              },
            },
          }}
          libraryAssets={libraryAssets}
          onRemoveAsset={onRemoveAsset}
        />
      </Form.InputWrapper>
      <Form.TagsInput
        groupClassName="mt-4"
        className="flex-grow px-2 py-2"
        label={`${t('communities.add-a-tag')}`}
        placeholder={t('communities.add-another-tag')}
        emptyTagsPlaceholder={t('communities.add-a-tag')}
        name="tags"
        rules={{
          pattern: {
            value: EMOJI_REGEXP,
            message: t('communities.forms.emoji-not-allowed'),
          },
        }}
      />
      <p className="mt-3 w-full pb-3 text-xs text-smd-gray-dark">
        {t('communities.agreement')}{' '}
        <Link
          target="_blank"
          className="smd-focus-visible-primary rounded-sm text-smd-accent"
          to="/account/legal"
        >
          {t('common.terms-of-use')}
        </Link>
      </p>
      <Button.Primary type="submit" loading={isLoading} disabled={isLoading}>
        {inEditMode ? t('common.update') : t('common.post')}
      </Button.Primary>
      <Button.Tertiary type="button" onClick={onClose} className="ml-2">
        {t('common.cancel')}
      </Button.Tertiary>
    </Form>
  );
}
