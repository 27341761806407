import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { slugifyObject } from 'utils/slug';
import { ContentContainer } from 'components';
import { useJourneyLastLocation } from 'journals/hooks';
import { JourneyCard } from 'journals/journeys/components';
import GridView from './GridView';

function JourneysCategoryView({ category, journeys, className }) {
  const { setJourneyLastLocation } = useJourneyLastLocation();

  return (
    <section className={classnames('antialiased', className)}>
      <ContentContainer>
        <h2 className="mb-smd-base break-words text-smd-h3 font-semibold text-smd-main line-clamp-2 lg:font-bold">
          {category}
        </h2>
        <GridView
          items={journeys}
          childComponent={(entry) => (
            <Link
              to={`/journey/${slugifyObject(entry.id, entry.name)}`}
              onClick={() => setJourneyLastLocation()}
              className="smd-focus-visible-primary rounded-sm"
              key={entry.id}
            >
              <JourneyCard
                journey={entry}
                className="smd-transform-duration h-96"
                showDescription
              />
            </Link>
          )}
          className="mx-auto gap-x-14 gap-y-12"
        />
      </ContentContainer>
    </section>
  );
}

export default JourneysCategoryView;
