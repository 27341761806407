import { useCollectionCreate } from 'hooks';
import CreateCollectionForm from './CreateCollectionForm';

function CreateCollectionWizard({ isVisible, onHide }) {
  const { createCollection, isLoadingCreate } = useCollectionCreate({
    onSuccess: onHide,
  });

  return isVisible ? (
    <CreateCollectionForm
      onCancel={onHide}
      onSubmit={createCollection}
      loading={isLoadingCreate}
    />
  ) : null;
}

export default CreateCollectionWizard;
