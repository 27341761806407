import { useParams } from 'react-router-dom';

import { JournalsApi, JournalsPublicApi } from 'api';
import RouteJournalViewer from './RouteJournalViewer';

function getJournalApi(isPublic) {
  const api = isPublic ? JournalsPublicApi : JournalsApi;
  return {
    ...api,
    getJournalByKey: api.getDefinitionByLinkId,
    getJournalTocByKey: api.getDefinitionTocByLinkId,
    getJournalContainerByKey: api.getDefinitionContainerByLinkId,
    markJournalContainerAsVisited: isPublic
      ? () => Promise.resolve()
      : api.markJournalContainerAsVisitedByLinkId,
  };
}

function POCRouteJournalViewer({ isPublic, ...props }) {
  const { journalLinkId } = useParams();

  const journalApi = getJournalApi(isPublic);

  return (
    <RouteJournalViewer
      journalKey={journalLinkId}
      journalApi={journalApi}
      isSharedJournal
      {...props}
    />
  );
}

export default POCRouteJournalViewer;
