import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: { emoji: function () {} },
  },
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: () => true,
      },
    },
  },
  'emoji-toolbar': true,
};

export const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'background',
  'list',
  'bullet',
  'link',
  'color',
  'emoji',
  'image',
];

const QuillToolbar = forwardRef(({ id = 'toolbar', className }, ref) => {
  const { t } = useTranslation();
  return (
    <div id={id} ref={ref} className={className}>
      <span className="ql-formats">
        <button
          className="ql-bold smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.bold')}
        />
        <button
          className="ql-italic smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.italic')}
        />
        <button
          className="ql-underline smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.underline')}
        />
        <button
          className="ql-strike smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.strike')}
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-list smd-focus-visible-primary rounded-sm"
          value="ordered"
          aria-label={t('labels.editor.ordered')}
        />
        <button
          className="ql-list smd-focus-visible-primary rounded-sm"
          value="bullet"
          aria-label={t('labels.editor.bullet')}
        />
      </span>
      <span className="ql-formats focus-visible:outline-none">
        <select className="ql-color smd-focus-within-primary rounded-sm" />
        <select className="ql-background smd-focus-within-primary rounded-sm" />
        <button
          className="ql-link smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.link')}
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-emoji smd-focus-visible-primary rounded-sm"
          aria-label={t('labels.editor.emoji')}
        />
      </span>
    </div>
  );
});

export default QuillToolbar;
