import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OutsideClickContainer } from 'components';

function ContextMenuContainer({ children, toggler, position, contentClasses }) {
  const [open, setOpen] = useState(false);

  const classes = classNames(
    'absolute',
    'shadow-xl',
    'whitespace-nowrap',
    'bg-white',
    {
      hidden: !open,
      'right-0': position === 'right',
      'left-0': position === 'left',
    },
    contentClasses
  );

  return (
    <OutsideClickContainer onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        {toggler({ toggle: () => setOpen((open) => !open) })}
        <div className={classes}>{children}</div>
      </div>
    </OutsideClickContainer>
  );
}

ContextMenuContainer.defaultProps = {
  position: 'left',
};

ContextMenuContainer.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
};

export default ContextMenuContainer;
