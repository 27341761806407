import { path } from 'ramda';
import { Controller } from 'react-hook-form';
import { ErrorLabel } from '..';
import { registerFormElement } from './utils';

function FormController(props) {
  const {
    groupClassName,
    groupProps,
    control,
    name,
    rules,
    render,
    errors,
    watch,
    resetField,
    withError = true,
    ...rest
  } = props;

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  return (
    <div className={groupClassName} {...groupProps}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ ...inputProps }) => render(inputProps)}
        {...rest}
      />
      {withError && <ErrorLabel error={error} />}
    </div>
  );
}

registerFormElement(FormController);

export default FormController;
