import React, { useState } from 'react';
import { AssetsLightBox } from 'journals/components';
import { Share } from 'components/icons';
import { SharePreview, ShareModal } from 'components/Share';
import { useLibraryAssetState } from '../../hooks';

function LibraryAssetModalViewer() {
  const { assets, currentIndex, updateState, clearState } =
    useLibraryAssetState();

  const [share, setShare] = useState(false);

  if (!assets) {
    throw new Error('Empty library state!');
  }

  function onSlideChange({ activeIndex }) {
    const asset = assets[activeIndex];
    if (asset && currentIndex !== activeIndex)
      updateState({ currentAsset: asset });
  }

  const lightBoxState = {
    toggler: true,
    index: currentIndex || 0,
  };
  function onShare() {
    setShare(true);
  }

  const additionalActions = (
    <Share
      className="w-5 cursor-pointer  text-white"
      strokeWidth="2"
      onClick={onShare}
    />
  );

  return (
    <>
      <AssetsLightBox
        assets={assets}
        lightBoxState={lightBoxState}
        onClose={clearState}
        carouselProps={{ onSlideChange }}
        additionalActions={additionalActions}
      />
      <ShareModal
        isOpen={share}
        onClose={() => setShare(false)}
        shareUrl={window.location.href}
      >
        <SharePreview {...assets?.[currentIndex]} description={null} />
      </ShareModal>
    </>
  );
}

export default React.memo(LibraryAssetModalViewer);
