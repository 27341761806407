import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Pipe } from 'components/icons';

function useExpandState(itemsCount, initialOpenIndex = 0) {
  const [open, setOpen] = useState({});

  useEffect(() => {
    setOpen({ [initialOpenIndex]: true });
  }, [itemsCount, initialOpenIndex]);

  const toggle = (index) =>
    setOpen((open) => ({
      ...open,
      [index]: !open[index],
    }));

  const expandAll = () => {
    const state = {};
    for (let i = 0; i < itemsCount; i++) {
      state[i] = true;
    }
    setOpen(state);
  };

  const collapseAll = () => {
    setOpen({});
  };

  return {
    open,
    toggle,
    expandAll,
    collapseAll,
  };
}

function getInitialOpenIndex(children, initialOpenIndex) {
  if (!children[initialOpenIndex]) {
    for (let i = 0; i < children.length; i++) {
      if (children[i]) return i;
    }
  }
  return initialOpenIndex;
}

function ExpandableSectionList(props) {
  const { disclaimer, initialOpenIndex } = props;
  const { t } = useTranslation();
  const itemsCount =
    props.children && props.children.length ? props.children.flat().length : 0;
  const { open, toggle, expandAll, collapseAll } = useExpandState(
    itemsCount,
    getInitialOpenIndex(props.children, initialOpenIndex)
  );
  const children = React.Children.map(props.children, (child, index) => {
    if (!child) return null;
    return (
      <li key={index}>
        {React.cloneElement(child, {
          open: open[index],
          onClick: () => toggle(index),
        })}
      </li>
    );
  });

  const showExpandAll =
    children.length > 1 &&
    Object.values(open).filter((x) => x).length < itemsCount;

  const showCollapseAll =
    children.length > 1 && Object.values(open).filter((x) => x).length > 0;

  return (
    <div className={classnames('relative px-4 md:py-12 ', props.className)}>
      <div className="absolute top-3 right-4 hidden space-x-2 text-xs leading-6 text-smd-accent md:flex">
        <button
          className={classnames(
            'smd-focus-visible-primary rounded-sm text-smd-base font-semibold',
            { hidden: !showExpandAll }
          )}
          onClick={expandAll}
          aria-label={t('labels.journals.expand-all')}
        >
          {t('journals.blocks.html.library-card.expand-all')}
        </button>
        {showExpandAll && showCollapseAll && <Pipe className="h-5" />}
        <button
          className={classnames(
            'smd-focus-visible-primary rounded-sm text-smd-base font-semibold',
            { hidden: !showCollapseAll }
          )}
          onClick={collapseAll}
          aria-label={t('labels.journals.collapse-all')}
        >
          {t('journals.blocks.html.library-card.collapse-all')}
        </button>
      </div>
      <ul className="space-y-8">{children}</ul>
      {disclaimer && (
        <p className="mt-4 text-sm italic text-smd-gray">{disclaimer}</p>
      )}
    </div>
  );
}

ExpandableSectionList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.func.isRequired,
    })
  ),
  disclaimer: PropTypes.string,
  initialOpenIndex: PropTypes.number,
};

ExpandableSectionList.defaultProps = {
  sections: [],
};

export default ExpandableSectionList;
