import { useEffect, useState } from 'react';

import { ExternalResource } from 'api';
import { getPublicResourceUrl } from 'utils';
import { getAssetUrl } from 'utils';

const Theme = {
  LIGHT: '/light.json',
  DARK: '/dark.json',
};

const defaultImage = {
  key: '/images/empty-gallery.png',
  alt: null,
  name: 'Woman Month One to Four',
  credit: 'TheVisualMD',
};

const themePromises = {
  [Theme.LIGHT]: null,
  [Theme.DARK]: null,
};

function manifest(theme) {
  if (themePromises[theme] === null) {
    const manifestUrl = getPublicResourceUrl(theme);
    themePromises[theme] = ExternalResource.get(manifestUrl).then(
      ({ data }) => data
    );
  }
  return themePromises[theme];
}

function useRandomStaticResource(section, theme = Theme.LIGHT) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    manifest(theme)
      .then((manifest) => {
        const randomIndex = Math.floor(
          Math.random() * manifest[section].length
        );
        const image = manifest[section][randomIndex];
        setImage({
          ...image,
          key: getAssetUrl(image.key),
          name: image.name,
          credit: image.credit,
        });
      })
      .catch(() => {
        setImage(defaultImage);
      });
  }, [section, theme]);

  return image;
}

export default Object.assign(useRandomStaticResource, { Theme });
