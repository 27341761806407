import axios from 'axios';
import { useQuery } from 'react-query';
import { getPublicResourceUrl } from 'utils';

export function useHelpCenterResources(options) {
  return useQuery(
    ['helpCenter'],
    async () =>
      axios
        .get(getPublicResourceUrl('/help-center.json'))
        .then(({ data }) => data),
    options
  );
}
