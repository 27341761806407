import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Avatar as AvatarPlaceholder } from './icons';

function RoundContainer({ className, ...props }) {
  return (
    <div
      className={classnames(
        'aspect-w-1 aspect-h-1 overflow-hidden rounded-full',
        className
      )}
      {...props}
    />
  );
}

export default function Avatar({ src, ...props }) {
  const [error, setError] = useState();

  useEffect(() => setError(), [src]);

  return (
    <div {...props}>
      <RoundContainer className="bg-smd-gray-lighter bg-opacity-25 shadow">
        {src && !error ? (
          <img
            src={src}
            alt="avatar"
            onError={setError}
            className="h-full w-full object-cover object-center"
          />
        ) : (
          <div className="flex-center">
            <AvatarPlaceholder className="h-full w-full text-smd-secondary" />
          </div>
        )}
      </RoundContainer>
    </div>
  );
}

Avatar.propTypes = {
  url: PropTypes.string,
};
