import { path } from 'ramda';
import { ErrorLabel } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'placeholder:text-smd-tertiary',
  'smd-input-focus-primary',
];

function FormTextArea(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    setValue,
    setError,
    getValues,
    watch,
    resetField,
    ...rest
  } = props;
  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';
  const classes = base.concat('resize-y', border, className).join(' ');

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label ? (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      ) : (
        <></>
      )}
      <textarea
        id={id}
        className={classes}
        {...register(name, rules)}
        {...rest}
      />
      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormTextArea);

export default FormTextArea;
