import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { set, sub } from 'date-fns';

import { useLibraryAssetRoutedModal } from 'library/hooks';
import { useParseSearch, useSearch } from 'search/hooks';

import { InfiniteScrollTrigger } from 'components';
import { Button } from 'components/forms';
import { ChevronDown } from 'components/icons';
import { LibraryAssetListSkeleton } from 'library/components/asset/LibraryAssetList';
import { LibraryListSkeleton } from 'library/components/LibraryList';
import NoContentLayout from 'NoContentLayout';

import {
  LibraryAssetModule,
  LibraryStoryModule,
  LibraryTopicModule,
} from './modules';
import StoryTimeFilter from './StoryTimeFilter';

function subtractDaysFromToday(toSubtract) {
  const days = Number(toSubtract);
  if (typeof days === 'number') {
    return sub(new Date(), { days });
  }
}

function resetClock(date) {
  if (date instanceof Date) {
    return set(date, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
}

function ExploreLibraryResources({ type }) {
  const { t } = useTranslation();

  const { state, pathname, search } = useLocation();
  const history = useHistory();
  const storyTimeFiltersState = state?.storyTimeFiltersState;

  const [timeFilter, setTimeFilter] = useState();

  useEffect(() => {
    if (storyTimeFiltersState) {
      setTimeFilter(storyTimeFiltersState);
    }
  }, [storyTimeFiltersState]);

  useEffect(() => {
    if (type !== 'event') {
      setTimeFilter();
    }
  }, [type]);

  const {
    searchResults,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
  } = useSearch({
    type,
    startDate: timeFilter?.startDate,
    endDate: timeFilter?.endDate,
  });
  const parsedSearchResults = useParseSearch(searchResults, { type });

  const { openLibraryAssetModal } = useLibraryAssetRoutedModal({
    assets: parsedSearchResults,
    fetchMoreAssets: fetchNextPage,
    haseMoreAssets: hasNextPage,
    isFetching: isFetching,
  });

  const isLoading = isFetching && !isFetched;
  const isAsset = type === 'image' || type === 'video';
  const isTopic = type === 'drug' || type === 'biomarker';
  const isEvent = type === 'event';

  const onTimeFilterChange = ({ target: { value } }) => {
    if (value !== 'custom') {
      history.replace({ pathname, search });
    }
    const startDate =
      value === 'all' ? null : resetClock(subtractDaysFromToday(value));

    setTimeFilter({
      value,
      startDate,
      endDate: new Date(),
    });
  };

  const showStoriesNotFound =
    !isFetching && type === 'event' && parsedSearchResults?.length === 0;

  return (
    <>
      {isAsset ? (
        <LibraryAssetModule
          key={type}
          assets={parsedSearchResults}
          onAssetClick={openLibraryAssetModal}
        />
      ) : isEvent ? (
        <>
          <StoryTimeFilter
            value={timeFilter?.value}
            state={timeFilter}
            onChange={onTimeFilterChange}
          />
          {showStoriesNotFound && (
            <NoContentLayout>
              <div className="space-y-smd-sm text-left antialiased">
                <h1 className="text-smd-h2 font-semibold">
                  {t('common.no-results')}
                </h1>
              </div>
            </NoContentLayout>
          )}
          <LibraryStoryModule
            stories={parsedSearchResults}
            filters={timeFilter}
          />
        </>
      ) : (
        <LibraryTopicModule key={type} topics={parsedSearchResults} />
      )}

      {isLoading && isAsset && <LibraryAssetListSkeleton count={8} isLarge />}
      {isLoading && (isTopic || isEvent) && <LibraryListSkeleton.Wide />}

      <div className="py-8 text-center">
        {hasNextPage && (
          <InfiniteScrollTrigger
            onFetch={fetchNextPage}
            isFetching={isFetchingNextPage}
          >
            <Button.Ghost
              onClick={fetchNextPage}
              loading={isFetchingNextPage}
              className="font-semibold"
            >
              <span>{t('common.show-more')}</span>

              <ChevronDown className="h-5 w-5" strokeWidth="3" />
            </Button.Ghost>
          </InfiniteScrollTrigger>
        )}
      </div>
    </>
  );
}

export default ExploreLibraryResources;
