function useScrollIntoView(
  options = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  }
) {
  return (el) => {
    if (el) {
      el.scrollIntoView(options);
    }
  };
}

export default useScrollIntoView;
