import { Link } from 'react-router-dom';
import {
  formatRelative,
  parseISO,
  isToday,
  isYesterday,
  format,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/forms';
import { useCommunityLastLocation } from 'communities/hooks';

function NotificationBody({ author, type, categoryName }) {
  const { t } = useTranslation();
  const actionLabel = t(`notifications.actions.${type}`);
  const notificationLinkLabel = t(`notifications.actions.${type}_target`);
  return (
    <div className="text-smd-sm">
      <span>
        {author?.displayName} {actionLabel} {notificationLinkLabel}
      </span>
      {categoryName && (
        <>
          <span>{` ${t('common.in')} `}</span>
          <span className="font-semibold text-smd-accent">{categoryName}</span>
        </>
      )}
    </div>
  );
}

export default function Notification({
  onDelete,
  created,
  notificationId,
  categoryId,
  topicId,
  isRead,
  ...rest
}) {
  const { t } = useTranslation();
  const date = parseISO(created);
  const notificationLink = `/communities/${categoryId}/conversation/${topicId}`;
  const shouldUseRelativeFormat = isToday(date) || isYesterday(date);

  const { pushCommunityLastLocation } = useCommunityLastLocation();
  return (
    <div className="flex w-full items-center">
      <Link
        className="smd-focus-visible-primary flex grow flex-col space-y-1 rounded-sm antialiased"
        to={notificationLink}
        onClick={() => {
          onDelete(notificationId);
          pushCommunityLastLocation();
        }}
      >
        <NotificationBody {...rest} />
        <div className="text-smd-xs text-smd-gray-dark first-letter:uppercase">
          {shouldUseRelativeFormat
            ? formatRelative(date, new Date())
            : format(date, 'dd MMM uuuu, h:mm a')}
        </div>
      </Link>

      {!isRead && (
        <div className="grow-0 cursor-pointer pl-1">
          <Button
            color="none"
            size="none"
            className="!justify-end"
            onClick={() => onDelete(notificationId)}
            aria-label={t('labels.notifications.mark-one')}
          >
            <span className="h-2.5 w-2.5 rounded-full bg-smd-accent"></span>
          </Button>
        </div>
      )}
    </div>
  );
}
