import { Input } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

export function HiddenInput(props) {
  const { register, name, value } = useManualInputDefaultProps(props);
  return <Input {...register(name, { value })} type="hidden" />;
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.HIDDEN, HiddenInput);
