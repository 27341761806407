import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsEmbedded, useMedia } from 'hooks';
import { Contents, Share, X as Close } from 'components/icons';
import { useIsInModal } from 'components/Modal';
import { Topbar } from 'components';

function ViewerTopbar({
  title,
  url,
  logos,
  onMenuToggle,
  onShareClick,
  onCloseClick,
}) {
  const { t } = useTranslation();

  const isEmbedded = useIsEmbedded();
  const isInModal = useIsInModal();

  const isLarge = useMedia(useMedia.LARGE);

  const logo = logos
    ?.filter(
      ({ sponsor, author }) =>
        !Boolean(sponsor) && (!Boolean(author) || author === 'null')
    )
    ?.map(({ url, alt }, id) => (
      <img className="h-full" key={id} alt="" src={url} />
    ));

  return (
    <Topbar
      left={
        <>
          {isInModal ? (
            <Topbar.IconButton
              icon={Close}
              onClick={onCloseClick}
              label={t('common.close')}
            />
          ) : (
            !isEmbedded &&
            isLarge && (
              <Topbar.IconButton
                icon={Close}
                label={t('common.close')}
                onClick={onCloseClick}
              />
            )
          )}
          <Link
            to="/journeys"
            className="block h-8 p-2 lg:hidden"
            aria-label={t('labels.menu.journeys')}
          >
            {logo}
          </Link>
          <Topbar.Heading>
            {url ? (
              <Link
                to={url}
                className="cursor-pointer"
                aria-label={t('labels.journals.go-to-front', {
                  journal: title,
                })}
              >
                {title}
              </Link>
            ) : (
              <span>{title}</span>
            )}
          </Topbar.Heading>
          <div className="flex-center space-x-2 lg:hidden">
            {onShareClick && (
              <button
                className="smd-focus-visible-secondary m-2 w-5 rounded-sm lg:m-0"
                onClick={onShareClick}
                aria-label={t('labels.common.share')}
              >
                <Share />
              </button>
            )}
            {onMenuToggle && (
              <button
                className="smd-focus-visible-secondary block rounded-sm p-2 lg:hidden lg:p-0 fine:hover:bg-smd-gray-light fine:hover:bg-opacity-25"
                onClick={onMenuToggle}
                aria-label={t('labels.journals.toc')}
              >
                <Contents strokeWidth="1.5" className="h-5 w-5" />
              </button>
            )}
            {!isEmbedded && !isLarge && (
              <Topbar.IconButton
                icon={Close}
                label={t('common.close')}
                onClick={onCloseClick}
              />
            )}
          </div>
        </>
      }
      right={logo}
    />
  );
}

export default Object.assign(ViewerTopbar, {
  smdLogo: { url: 'https://storymd.com/images/logo-white.svg', alt: 'StoryMD' },
});
