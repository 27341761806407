import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

function timeFilterOptions(t) {
  return [
    { value: 'all', label: t('library.search.filters.anytime') },
    { value: '1', label: t('library.search.filters.past-day') },
    { value: '7', label: t('library.search.filters.past-week') },
    { value: '30', label: t('library.search.filters.past-month') },
    { value: '365', label: t('library.search.filters.past-year') },
  ];
}

export default function StoryTimeFilter({ value, onChange, state }) {
  const { t } = useTranslation();

  return (
    <select
      className="mb-4 border-none text-sm font-bold text-smd-gray-dark focus:ring-smd-accent"
      value={value ?? timeFilterOptions(t)[0].value}
      onChange={onChange}
    >
      {timeFilterOptions(t).map((option) => (
        <option value={option.value} key={`storyFilterOption${option.value}`}>
          {option.label}
        </option>
      ))}
      {value === 'custom' && (
        <option value="custom" data-testid="story-custom-range-option">
          {format(new Date(state?.startDate), 'MM/dd/yyyy')} -{' '}
          {format(new Date(state?.endDate), 'MM/dd/yyyy')}
        </option>
      )}
    </select>
  );
}
