import { useReducer } from 'react';

const initialMenuState = {
  isUserOpen: false,
  isListOpen: false,
  isNotificationOpen: false,
  isJourneysOpen: false,
  isBetaOpen: false,
};

function menuReducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        ...initialMenuState,
        [action.menu]: true,
      };
    case 'state': {
      return {
        ...state,
        ...action.menu,
      };
    }
    case 'close':
      return {
        ...initialMenuState,
      };
    default:
      throw new Error(`Invalid action type ${action.type}`);
  }
}

export default function useMenuReducer() {
  return useReducer(menuReducer, initialMenuState);
}
