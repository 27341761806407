const manualInputsRegistry = {};

function resolve(key) {
  if (!manualInputsRegistry[key]) {
    console.warn(`Trying to get invalid input with key: ${key}`);
  }
  return manualInputsRegistry[key];
}

function register(key, Component) {
  manualInputsRegistry[key] = Component;
}

const registry = { resolve, register };

export default registry;
