import { useMemo, useState } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { callAll } from 'utils';
import { addDefaultIntentianalDelay } from 'api/utils';
import { CollectionsApi } from '../api';

const COLLECTION_JOURNALS_QUERY_SIZE = 10000;

const defaultQueryOptions = {
  onError: console.log,
};

export const collectionKeys = {
  all: () => ['collections'],
  summary: () => [...collectionKeys.all(), 'summary'],
  collectionById: (id) => [...collectionKeys.all(), id],
  allByJournal: (journalId) => [...collectionKeys.all(), 'journal', journalId],
  collectionJournalsById: (id) => [
    ...collectionKeys.collectionById(id),
    'journals',
  ],
};

export function useCollectionsSummary() {
  const { data: collections, ...rest } = useQuery(
    collectionKeys.summary(),
    async () => CollectionsApi.getAllCollectionsSummary(),
    {
      ...defaultQueryOptions,
      keepPreviousData: true,
    }
  );

  return { collections, ...rest };
}

export function useCollections() {
  const { data: collections, ...rest } = useQuery(
    collectionKeys.all(),
    async () => CollectionsApi.getCollections(),
    {
      keepPreviousData: true,
    }
  );

  return { collections, ...rest };
}

export function useCollectionsByJournal(journalId) {
  const { data: collections, ...rest } = useQuery(
    collectionKeys.allByJournal(journalId),
    async () => CollectionsApi.getCollections(journalId),
    {
      ...defaultQueryOptions,
      keepPreviousData: true,
    }
  );

  return { collections, ...rest };
}

export function useCollection(id) {
  const { data: collection, ...rest } = useQuery(
    collectionKeys.collectionById(id),
    async () => CollectionsApi.get(id),
    {
      ...defaultQueryOptions,
      keepPreviousData: true,
    }
  );

  return { collection, ...rest };
}

export function useCollectionJournals(id) {
  const { data, isFetched, hasNextPage, ...rest } = useInfiniteQuery(
    collectionKeys.collectionJournalsById(id),
    async ({ pageParam }) => {
      const queryParams = {
        collectionId: id,
        size: COLLECTION_JOURNALS_QUERY_SIZE,
        page: pageParam,
      };
      return CollectionsApi.getJournalsByCollectionId(queryParams);
    },
    {
      getNextPageParam: (lastPage) => {
        const { last, totalPages, number } = lastPage;
        const nextPageNumber = number + 1;

        if (!last && totalPages > nextPageNumber) {
          return nextPageNumber;
        }
        return undefined;
      },
      ...defaultQueryOptions,
    }
  );

  const collectionJournals = useMemo(() => {
    if (data) {
      return data?.pages?.reduce(
        (prev, curr) => [...prev, ...curr?.content],
        []
      );
    }
    return [];
  }, [data]);

  const hasNoCollectionJournals = isFetched && data?.pages?.length === 0;
  const hasNoMoreCollectionJournals =
    isFetched && !hasNextPage && data?.pages?.length > 1;

  return {
    collectionJournals,
    ...rest,
    isFetched,
    hasNextPage,
    hasNoCollectionJournals,
    hasNoMoreCollectionJournals,
  };
}

export function useCollectionCreate({ onSuccess, ...config }) {
  const queryClient = useQueryClient();

  const {
    mutate: createCollection,
    isLoading: isLoadingCreate,
    ...rest
  } = useMutation(async (collection) => CollectionsApi.create(collection), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(collectionKeys.all()),
      onSuccess
    ),
    ...defaultQueryOptions,
    ...config,
  });

  return { createCollection, isLoadingCreate, ...rest };
}

export function useCollectionDelete(config) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteCollection,
    isLoading: isLoadingDelete,
    ...rest
  } = useMutation(async (collectionId) => CollectionsApi.delete(collectionId), {
    ...defaultQueryOptions,
    ...config,
    onSuccess: () => {
      queryClient.resetQueries(collectionKeys.all());
      config?.onSuccess();
    },
  });

  return { deleteCollection, isLoadingDelete, ...rest };
}

export function useCollectionReorder(config) {
  const {
    mutate: reorderCollection,
    isLoading: isLoadingReorder,
    ...rest
  } = useMutation(
    async (collectionList) => {
      const orderMap = collectionList?.reduce((prev, curr, index) => {
        return { ...prev, [curr.id]: index };
      }, {});
      return CollectionsApi.reorder(orderMap);
    },
    {
      ...defaultQueryOptions,
      ...config,
    }
  );

  return { reorderCollection, isLoadingReorder, ...rest };
}

export function useCollectionUpdate({ onSuccess, ...config }) {
  const queryClient = useQueryClient();

  const {
    mutate: updateCollection,
    isLoading: isLoadingUpdate,
    ...rest
  } = useMutation(async (collection) => CollectionsApi.edit(collection), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(collectionKeys.all()),
      onSuccess
    ),
    ...defaultQueryOptions,
    ...config,
  });

  return { updateCollection, isLoadingUpdate, ...rest };
}

const add = addDefaultIntentianalDelay(CollectionsApi.addJournal);

function useAddJournalToCollection(config = {}) {
  const { mutate: addJournalToCollection, ...rest } = useMutation(
    async ({ collectionId, journalKey }) => add(collectionId, journalKey),
    {
      ...defaultQueryOptions,
      ...config,
    }
  );

  return { addJournalToCollection, ...rest };
}

const remove = addDefaultIntentianalDelay(CollectionsApi.removeJournal);

export function useRemoveJournalFromCollection(config = {}) {
  const queryClient = useQueryClient();

  const { mutate: removeJournalFromCollection, ...rest } = useMutation(
    async ({ collectionId, journalKey }) => remove(collectionId, journalKey),
    {
      ...defaultQueryOptions,
      ...config,
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          collectionKeys.collectionJournalsById(res.id)
        );
        config?.onSuccess?.(res);
      },
    }
  );

  return { removeJournalFromCollection, ...rest };
}

export function useJournalAllocation(journalKey) {
  const queryClient = useQueryClient();

  const [loadingCollections, setLoadingCollections] = useState({});

  const { addJournalToCollection, isLoading: isLoadingAdd } =
    useAddJournalToCollection({
      onMutate: ({ collectionId }) =>
        setLoadingCollections((prev) => ({ ...prev, [collectionId]: true })),
      onSettled: (_data, _error, { collectionId }) =>
        setLoadingCollections((prev) => ({ ...prev, [collectionId]: false })),
      onSuccess: (res) => {
        queryClient.setQueryData(
          collectionKeys.allByJournal(journalKey),
          (prev) => prev.concat(res)
        );
        queryClient.invalidateQueries(
          collectionKeys.collectionJournalsById(res.id)
        );
      },
      onError: () =>
        queryClient.invalidateQueries(collectionKeys.allByJournal(journalKey)),
    });

  const { removeJournalFromCollection, isLoading: isLoadingRemove } =
    useRemoveJournalFromCollection({
      onMutate: ({ collectionId }) =>
        setLoadingCollections((prev) => ({ ...prev, [collectionId]: true })),
      onSettled: (_data, _error, { collectionId }) =>
        setLoadingCollections((prev) => ({ ...prev, [collectionId]: false })),
      onSuccess: (res) => {
        queryClient.setQueryData(
          collectionKeys.allByJournal(journalKey),
          (prev) => prev.filter((entry) => entry.id !== res.id)
        );
        queryClient.invalidateQueries(
          collectionKeys.collectionJournalsById(res.id)
        );
      },
      onError: () =>
        queryClient.invalidateQueries(collectionKeys.allByJournal(journalKey)),
    });

  return {
    addJournalToCollection,
    removeJournalFromCollection,
    isLoading: isLoadingAdd || isLoadingRemove,
    loadingCollections,
  };
}
