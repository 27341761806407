import { useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useQuery, useQueryClient } from 'react-query';
import { useIsEmbedded, useIsFeatureEnabled } from 'hooks';
import { JournalsApi } from 'api';
import { ViewerPageSkeleton } from 'components/viewer';
import JournalShareModal from '../components/JournalShareModal';
import Builder from '../blocks/Builder';
import ParentContext from './ParentContext';
import ConversationBlock from './ConversationBlock';

function Container({
  journalKey,
  containerKey,
  journal,
  journalApi = JournalsApi,
}) {
  const [shareOpen, setShareOpen] = useState(false);

  const queryClient = useQueryClient();

  const { data: container } = useQuery(
    ['journalContainerByKey', journalKey, containerKey],
    async ({ queryKey }) => {
      const journalKey = queryKey[1];
      const containerKey = queryKey[2];
      return journalApi.getJournalContainerByKey(journalKey, containerKey).then(
        //Added conditional .then on markAsVisited method due to unavailability on public api
        journalApi
          .markJournalContainerAsVisited(journalKey, containerKey)
          ?.then((container) => {
            queryClient.setQueryData(
              ['journalByKey', journalKey],
              (journal) => ({
                ...journal,
                currentContainerKey: containerKey,
              })
            );
            return container;
          }),
        console.error
      );
    },
    {
      enabled: true,
      onError: (err) => console.log(err?.message),
    }
  );

  const isEmbed = useIsEmbedded();

  const isPOCJournal = Boolean(journal.preamble?.parent);
  const hasCommunity = Boolean(journal.preamble?.hasCommunity);
  const isCommunitiesEnabled =
    useIsFeatureEnabled(useIsFeatureEnabled.COMMUNITIES) &&
    !isPOCJournal &&
    hasCommunity;

  return (
    <ErrorBoundary>
      <ParentContext.Provider className="relative">
        <div className="mx-auto max-w-4xl space-y-6">
          {container ? (
            Builder.fromJson({
              ...container,
              onShare: isPOCJournal ? undefined : setShareOpen,
            })
          ) : (
            <ViewerPageSkeleton />
          )}
          {!isEmbed && isCommunitiesEnabled && journal && container && (
            <ConversationBlock journal={journal} container={container} />
          )}
        </div>
        <JournalShareModal
          isOpen={shareOpen}
          journal={journal}
          container={container}
          onClose={() => setShareOpen(false)}
        />
      </ParentContext.Provider>
    </ErrorBoundary>
  );
}

export default Container;
