import { ContentContainer, Skeleton } from 'components';
import { SkeletonCardGrid } from 'journals/components';

function CollectionContentSkeleton() {
  return (
    <ContentContainer className="px-8 pt-8">
      <Skeleton.Text className="w-2/6 text-smd-h2" />
      <div className="h-1 w-full border-b pb-4" />
      <SkeletonCardGrid containerClassName="w-full" className="my-6" />
    </ContentContainer>
  );
}

export default CollectionContentSkeleton;
