import { useTranslation } from 'react-i18next';
import { useProfileState } from 'ProfileContext';

function useDefaultCollectionName() {
  const { t } = useTranslation();

  const profile = useProfileState();
  const firstName = profile?.firstName ?? '';

  return t('journals.collections.owner-journals', {
    user: firstName,
  });
}

export default useDefaultCollectionName;
