import { useRef } from 'react';

export function useModalClose(callback = () => {}) {
  const ref = useRef();
  const onMouseDown = ({ target }) => {
    if (target === ref.current) {
      callback();
    }
  };
  return { ref, onMouseDown };
}
