import api, { publicApi } from './axios';
import channelMock from './mock/channel';

const buildChannelsApi = (axios) => ({
  create(channel) {
    return axios
      .post('/channels', getChannelFormData(channel), {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },

  edit(channel) {
    return axios
      .put(`/channels`, getChannelFormData(channel), {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },

  getAll(params) {
    return axios.get(`/channels`, { params }).then(({ data }) => data);
  },

  get(id) {
    return axios.get(`/channels/${id}`).then(({ data }) => data);
  },
  getSubscriptions() {
    return axios.get(`/channels/subscriptions`).then(({ data }) =>
      data.map((x) => {
        const mock = channelMock.create();
        return { ...mock, ...x };
      })
    );
  },
  deleteChannel(channelId) {
    return axios.delete(`/channels/${channelId}`).then(({ data }) => data);
  },

  removeJournal(channelId, journalKey) {
    return axios
      .delete(`/channels/${channelId}/journals/${journalKey}/`)
      .then(({ data }) => data);
  },

  addJournal(channelId, journalKey) {
    return axios
      .put(`/channels/${channelId}/journals/${journalKey}/`)
      .then(({ data }) => data);
  },

  subscribe(channelId) {
    return axios
      .put(`/channels/${channelId}/subscriptions`)
      .then(({ data }) => {
        const mock = channelMock.create();
        return { ...mock, ...data };
      });
  },
  unsubscribe(channelId) {
    return axios
      .delete(`/channels/${channelId}/subscriptions`)
      .then(({ data }) => {
        const mock = channelMock.create();
        return { ...mock, ...data };
      });
  },
});

function getChannelFormData(channel) {
  const { cover, avatar, ...rest } = channel;

  const formData = new FormData();
  if (channel.avatar instanceof Blob) formData.append('avatar', channel.avatar);
  if (channel.cover instanceof Blob) formData.append('cover', channel.cover);
  const body = new Blob([JSON.stringify(rest)], {
    type: 'application/json',
  });
  formData.append('body', body);
  return formData;
}

export const ChannelsApiPublic = buildChannelsApi(publicApi);

export default buildChannelsApi(api);
