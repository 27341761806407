import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia, useSensitiveAssetViewRestriction } from 'hooks';
import { Tooltip } from 'components';
import { Expand, Share } from 'components/icons';
import { ShareModal, SharePreview } from 'components/Share';
import { slugifyObject } from 'utils/slug';
import { BlurredLayer, blurredStyle } from 'components/BlurredLayer';

export default function AssetContainer({
  config,
  renderAssetInfo,
  onExpandClick,
  showShare,
  children,
}) {
  const { type, name, credit, id } = config;
  const { t } = useTranslation();

  const isLarge = useMedia(useMedia.LARGE);

  const [shareOpen, setShareOpen] = useState(false);
  const shareUrl = `${window.location.origin}/asset/${encodeURIComponent(
    slugifyObject(id, name)
  )}`;

  function handleExpandClick() {
    onExpandClick?.();
  }

  const { isSensitive, isRestricted, setIsRestricted } =
    useSensitiveAssetViewRestriction(config);

  return (
    <div className="border border-smd shadow-md">
      <div
        style={{
          minHeight: isLarge
            ? 'clamp(17.5rem, 15vw, 35rem)'
            : 'clamp(7.5rem, 20vw, 25rem)',
        }}
        className="grid content-center"
      >
        <BlurredLayer
          isSensitive={isSensitive}
          className="relative"
          onUnblur={() => setIsRestricted(false)}
        >
          {({ isBlurred }) =>
            cloneElement(children, { style: isBlurred ? blurredStyle : {} })
          }
        </BlurredLayer>
      </div>
      <div className="items-center justify-between space-y-2 p-4 lg:flex lg:space-x-2 lg:space-y-0">
        {renderAssetInfo ? (
          renderAssetInfo(config)
        ) : (
          <div>
            {name && (
              <p
                className="break-words text-lg font-semibold text-smd-main"
                dangerouslySetInnerHTML={{ __html: name }}
              />
            )}
            {credit && Boolean(credit?.name?.trim?.()) && (
              <p
                className="break-all text-smd-sm italic text-smd-secondary line-clamp-1"
                title={credit.name}
              >
                {t(`journals.asset.source.label-${type}`) + ' ' + credit.name}
              </p>
            )}
          </div>
        )}
        <div className="flex items-center justify-end space-x-2">
          {showShare && (
            <>
              {isRestricted ? (
                <Tooltip
                  content={t('common.sharing-sensitive-media')}
                  placement="left"
                >
                  {(props) => (
                    <button
                      {...props}
                      className="flex-center smd-focus-visible-primary h-8 w-8 cursor-pointer rounded-full border border-smd text-smd-gray shadow-md"
                      aria-label={t('labels.common.share-asset', {
                        asset: name,
                      })}
                    >
                      <Share className="w-4" />
                    </button>
                  )}
                </Tooltip>
              ) : (
                <button
                  className="flex-center smd-focus-visible-primary h-8 w-8 cursor-pointer rounded-full border border-smd text-smd-accent shadow-md"
                  onClick={() => setShareOpen(true)}
                  aria-label={t('labels.common.share-asset', {
                    asset: name,
                  })}
                >
                  <Share className="w-4" />
                </button>
              )}
            </>
          )}
          {onExpandClick && (
            <>
              {isRestricted ? (
                <Tooltip
                  content={t('common.opening-sensitive-media')}
                  placement="left"
                >
                  {(props) => (
                    <button
                      {...props}
                      className="flex-center smd-focus-visible-primary h-8 w-8 cursor-pointer rounded-full border border-smd text-smd-gray shadow-md"
                      aria-label={t('labels.common.expand')}
                    >
                      <Expand className="w-4" />
                    </button>
                  )}
                </Tooltip>
              ) : (
                <button
                  onClick={handleExpandClick}
                  className="flex-center smd-focus-visible-primary h-8 w-8 cursor-pointer rounded-full border border-smd text-smd-accent shadow-md"
                  aria-label={t('labels.common.expand')}
                >
                  <Expand className="w-4" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <ShareModal
        isOpen={shareOpen}
        onClose={() => setShareOpen(false)}
        shareUrl={shareUrl}
      >
        <SharePreview {...config} description={null} />
      </ShareModal>
    </div>
  );
}
