import { useChannelsApi } from 'journals/channels/channelsApiContext';
import { useMemo } from 'react';
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { ChannelsApi } from '../api';

export const channelKeys = {
  all: () => ['channels'],
  paginated: () => [...channelKeys.all(), 'paginated'],
  paginatedSearch: (search, tag) => [
    ...channelKeys.all(),
    'paginated',
    search,
    tag,
  ],
  paginatedConfig: (page, size, searchTerm) => [
    ...channelKeys.paginated(),
    { page, size, searchTerm },
  ],
  subscriptions: () => [...channelKeys.all(), 'subscriptions'],
  channelView: (id) => [...channelKeys.all(), id],
};

const PAGINATED_CHANNELS_SIZE = 5;

const defaultQueryOptions = {
  onError: console.log,
};

function useChannelsInvalidator() {
  const queryClient = useQueryClient();
  return {
    invalidateChannels: () => queryClient.invalidateQueries(channelKeys.all()),
  };
}

function useChannels(params) {
  const api = useChannelsApi();
  const { data, isFetching: isFetchingChannels } = useQuery(
    channelKeys.paginatedConfig(params?.page, params?.size, params?.searchTerm),
    async () => {
      const queryParams = {
        size: params?.size,
        page: params?.page,
        searchTerm: params?.searchTerm,
      };
      return api.getAll(queryParams);
    },
    {
      keepPreviousData: true,
      ...defaultQueryOptions,
    }
  );

  return {
    channels: data,
    isFetchingChannels,
  };
}

function usePaginatedChannels({ searchTerm, active, tag }) {
  const api = useChannelsApi();
  const queryClient = useQueryClient();

  function removeQueryByTag(tag) {
    queryClient.removeQueries({
      queryKey: channelKeys.paginatedSearch(searchTerm, tag),
    });
  }
  const { data, isFetched, hasNextPage, ...rest } = useInfiniteQuery(
    channelKeys.paginatedSearch(searchTerm, tag),
    async ({ pageParam }) => {
      const queryParams = {
        size: PAGINATED_CHANNELS_SIZE,
        page: pageParam,
        searchTerm,
        active,
        tag,
      };
      return api.getAll(queryParams);
    },
    {
      getNextPageParam: (lastPage) => {
        const { last, totalPages, number } = lastPage;
        const nextPageNumber = number + 1;

        if (!last && totalPages > nextPageNumber) {
          return nextPageNumber;
        }
        return undefined;
      },
      ...defaultQueryOptions,
    }
  );

  const channelsData = useMemo(() => {
    if (data) {
      return data?.pages?.reduce(
        (prev, curr) => [...prev, ...curr?.content],
        []
      );
    }
    return [];
  }, [data]);

  const hasNoChannels = isFetched && data?.pages?.length === 0;
  const hasNoMoreChannels =
    isFetched && !hasNextPage && data?.pages?.length > 1;

  return {
    channelsData,
    isFetched,
    hasNextPage,
    hasNoChannels,
    hasNoMoreChannels,
    removeQueryByTag,
    ...rest,
  };
}

function useChannelView(id) {
  const api = useChannelsApi();

  const {
    data: channel,
    isFetching: isLoading,
    error,
    isFetched,
    ...rest
  } = useQuery(channelKeys.channelView(id), async () => api.get(id), {
    keepPreviousData: true,
    ...defaultQueryOptions,
  });

  const channelNotFound = isFetched && error?.response?.status === 404;

  return { channel, isLoading, isFetched, channelNotFound, ...rest };
}

function useChannelsSubscriptions(config) {
  const {
    data: subscriptions,
    isFetching: isLoading,
    isFetched: isLoaded,
    ...rest
  } = useQuery(
    channelKeys.subscriptions(),
    async () => ChannelsApi.getSubscriptions(),
    { ...defaultQueryOptions, ...config }
  );
  const subscriptionsById = subscriptions?.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
  return { subscriptions, subscriptionsById, isLoading, isLoaded, ...rest };
}

function useSubscription() {
  const queryClient = useQueryClient();
  const { mutate: changeSubscription, ...rest } = useMutation(
    async ({ id, subscription }) => {
      if (subscription) {
        return ChannelsApi.subscribe(id);
      } else {
        return ChannelsApi.unsubscribe(id);
      }
    },
    {
      onMutate: async ({ id, subscription }) => {
        await queryClient.cancelQueries(channelKeys.channelView(id));

        const previousChannelState = queryClient.getQueryData(
          channelKeys.channelView(id)
        );
        if (previousChannelState) {
          queryClient.setQueryData(channelKeys.channelView(id), (old) => ({
            ...old,
            subscribed: subscription,
            subscribersCount: subscription
              ? previousChannelState.subscribersCount + 1
              : previousChannelState.subscribersCount - 1,
          }));

          return { previousChannelState };
        }
      },

      onSuccess: (response, { id, subscription }) => {
        queryClient.setQueryData(channelKeys.subscriptions(), (old) => {
          const result = old?.filter((x) => x.id !== id) || [];

          if (subscription) {
            return [...result, response];
          } else {
            return result;
          }
        });
      },
      onError: (err, newChannel, context) => {
        if (context?.previousChannelState) {
          queryClient.setQueryData(
            [channelKeys.channelView(), context.previousChannelState.id],
            context.previousChannelState
          );
        }
      },
    }
  );
  return { changeSubscription, ...rest };
}

function useDeleteChannel(onSuccess) {
  const { invalidateChannels } = useChannelsInvalidator();
  const { mutate: deleteChannel, isLoading: isLoadingDelete } = useMutation(
    async (id) => ChannelsApi.deleteChannel(id),
    {
      onSuccess: () => {
        invalidateChannels();
        onSuccess?.();
      },
      onError: (err) => console.log(err?.message),
    }
  );
  return { deleteChannel, isLoadingDelete };
}

export default {
  useChannels,
  usePaginatedChannels,
  useChannelsSubscriptions,
  useSubscription,
  useChannelView,
  useDeleteChannel,
};
