import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@szhsin/react-menu';
import classnames from 'classnames';

import { ChevronLeft, ChevronRight } from 'components/icons';
import { useHoverMenu, useMedia } from 'hooks';
import { JourneyCard } from 'journals/journeys/components';

import ListMenu from 'ui/Menu/ListMenu';
import classes from './DropDown.module.css';
import DropDownContainer from './DropDownContainer';

function JourneysDropDown({ onClick }) {
  const { t } = useTranslation();
  const isLarge = useMedia(useMedia.LARGE);

  const data = useHoverMenu();
  const journeys = data?.menu?.journeys;

  const alphabeticallyOrderedCategories = journeys?.categories?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <DropDownContainer className="overflow-auto p-2 pb-5">
      {!isLarge && (
        <>
          <header className="flex items-center border-b-1 py-2 px-2">
            <MenuItem
              onClick={onClick}
              className={classnames(classes['szh-menu__item'], 'space-x-2')}
            >
              <ChevronLeft className="h-5 w-5 stroke-2" />
              <span>{t('common.go-back')}</span>
            </MenuItem>
          </header>
          <MenuItem className={classes['szh-menu__item']}>
            <Link to={journeys?.button?.url}>
              <div className="p-4 text-smd-h3.5 font-bold text-smd-accent">
                {t('main-menu.journeys')}
              </div>
            </Link>
          </MenuItem>
        </>
      )}
      <div className="px-4 text-smd-base font-normal lg:py-4 lg:px-0">
        {t('journals.journeys.info-title')}
      </div>
      <div className="lg:flex lg:space-x-8">
        <div className="border-b-1 px-4 py-4 lg:w-1/4 lg:border-r-1 lg:border-b-0 lg:px-0">
          <div className="flex items-center justify-between space-x-5 py-4 font-bold lg:justify-start xl:space-x-8">
            <div className="text-smd-h3.5">
              {t('journals.journeys.categories')}
            </div>
            <MenuItem className={classes['szh-menu__item']}>
              <Link
                className="flex items-center space-x-1 text-smd-accent hover:underline"
                to={journeys?.button?.url || ''}
              >
                <span>{t('journals.journeys.see-all')}</span>
                <ChevronRight className="h-4 w-4 stroke-2" />
              </Link>
            </MenuItem>
          </div>
          <ul className="space-y-2 font-semibold">
            {alphabeticallyOrderedCategories?.map((item, index) => (
              <MenuItem
                className={classes['szh-menu__item']}
                key={`${item.name}_${index}`}
              >
                <Link to={item.url} className="w-full">
                  {item.name}
                </Link>
              </MenuItem>
            ))}
          </ul>
        </div>

        <div className="p-4 lg:w-3/4 lg:p-0">
          <div className="py-4 text-smd-h3.5 font-bold">
            {t('journals.journeys.featuring')}
          </div>
          <div className="lg:flex lg:space-x-4 xl:space-x-16">
            {journeys?.featuring?.cards?.map((item, index) => (
              <MenuItem
                className={classes['szh-menu__item']}
                key={`${item.name}_${index}`}
              >
                <Link className="smd-transform-duration w-60" to={item.url}>
                  <JourneyCard
                    journey={item}
                    className="shadow-none"
                    key={item.journeyKey}
                  />
                </Link>
              </MenuItem>
            ))}
          </div>
        </div>
      </div>
      {!isLarge && (
        <div className="px-4">
          <ListMenu.Footer />
        </div>
      )}
    </DropDownContainer>
  );
}

export default JourneysDropDown;
