import { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useGetURLQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

function useDeleteAllURLQuery() {
  const history = useHistory();
  // using history.replace because safari does not fully suport URLSearchParams delete
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/delete
  return useCallback(() => history.replace({ search: null }), [history]);
}

function useSetURLQuery(key, initialValue) {
  const history = useHistory();
  const params = useGetURLQuery();

  const [value, setValue] = useState(() => {
    if (initialValue) return initialValue ?? null;
    if (params.get(key)) return params.get(key);
    return null;
  });

  const setValuePublic = useCallback(
    (value, withReplace) => {
      setValue(value ?? null);

      const params = new URLSearchParams(history.location.search);

      if (value) {
        if (params.has(key)) {
          params.set(key, value);
        } else {
          params.append(key, value);
        }
      } else {
        params.delete(key);
      }
      const setParams = withReplace ? history.replace : history.push;
      setParams({ search: params.toString() });
    },
    [history, key]
  );

  useEffect(() => {
    setValue(params.get(key));
  }, [params, key, setValue]);

  return [value, setValuePublic];
}

export default { useSetURLQuery, useGetURLQuery, useDeleteAllURLQuery };
