import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCollectionDelete } from 'hooks/collections';

import { Confirm } from 'components';
import { Button } from 'components/forms';
import { DragDropTable, TableActionGroup } from 'components/Table';
import { Trash } from 'components/icons';

import EditCollectionForm from './EditCollectionForm';

function EditCollectionWizard({
  collection,
  collectionJournals,
  onCancel,
  onUpdate,
  isLoadingUpdate,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [collectionForDeletion, setCollectionForDeletion] = useState();
  const [collectionJournalsEdit, setCollectionJournalsEdit] = useState([]);

  useEffect(() => {
    setCollectionJournalsEdit(collectionJournals);
  }, [collectionJournals]);

  const { deleteCollection, isLoadingDelete } = useCollectionDelete({
    onSuccess: () => history.replace('/journals/collections/manage'),
  });

  const onDelete = (collection) => setCollectionForDeletion(collection);

  const onDeleteModalClose = () =>
    !isLoadingDelete && setCollectionForDeletion();

  const onDeleteModalConfirm = () => {
    deleteCollection(collection.id);
  };

  function removeJournalFromCollection(journalKey) {
    setCollectionJournalsEdit((collection) =>
      collection.filter((x) => x.journalKey !== journalKey)
    );
  }

  function reorder(collection) {
    setCollectionJournalsEdit(collection);
  }

  function handleSubmit(newCollection) {
    newCollection.journals = collectionJournalsEdit?.map((x) => x.journalKey);

    return onUpdate(newCollection);
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('library.search.healthjournal_one'),
        accessor: 'name',
      },
      {
        accessor: 'none',
        headerClassName: 'text-right',
        Header: () => <div className="text-right">{t('common.actions')}</div>,
        Cell: ({ row }) => {
          return (
            <TableActionGroup>
              <Button.Anchor
                flavor="anchor"
                onClick={() =>
                  removeJournalFromCollection(row.original.journalKey)
                }
                aria-label={t('labels.collections.delete-collection', {
                  collection: row.original.name,
                })}
              >
                <Trash className="h-4 w-4" />
                <span>{t('common.delete')}</span>
              </Button.Anchor>
            </TableActionGroup>
          );
        },
      },
    ];
  }, [t]);

  return Boolean(collection) ? (
    <>
      <div className="pb-8">
        <EditCollectionForm
          defaultValues={collection}
          onCancel={onCancel}
          onDelete={onDelete}
          onSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
        />
      </div>
      <DragDropTable
        columns={columns}
        data={collectionJournalsEdit}
        onReorder={reorder}
      />

      <Confirm
        cancelLabel={t('common.cancel')}
        isOpen={collectionForDeletion}
        onClose={onDeleteModalClose}
        renderConfirm={
          <Button.Alert
            size="lg"
            onClick={onDeleteModalConfirm}
            loading={isLoadingDelete}
          >
            {t('common.delete')}
          </Button.Alert>
        }
      >
        {t('journals.collections.delete-confirmation')}
      </Confirm>
    </>
  ) : null;
}

export default EditCollectionWizard;
