import axios, { Headers } from './axios';

const ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT = '/admin/definitions';
const POC_JOUNRAL_AUDIT_ENDPOINT = '/poc/audit';
export default {
  getAllJournals(params) {
    return axios
      .get(ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT, { params })
      .then(({ data }) => data);
  },
  uploadJournal(file, partner, description) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('partner', partner);
    formData.append('description', description);

    return axios
      .post(ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT, formData, {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },
  reUploadJournal(file, id) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);

    return axios
      .put(ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT, formData, {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },
  updateJournal(id, patch) {
    return axios
      .put(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/${id}`, patch)
      .then(({ data }) => data);
  },
  deleteJournal(id) {
    return axios
      .delete(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/${id}`)
      .then(({ data }) => data);
  },
  bulkDeleteJournals(ids) {
    return axios
      .put(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/bulk-delete`, { ids })
      .then(({ data }) => data);
  },
  getJournalDefinition(id) {
    return axios
      .get(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/${id}/structure`)
      .then(({ data }) => data);
  },
  getPocVisitStatisticsCsv() {
    return axios
      .get(`${POC_JOUNRAL_AUDIT_ENDPOINT}/download`)
      .then(({ data }) => data);
  },
  generateReferralCode(id) {
    return axios
      .put(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/${id}/referral-code`)
      .then(({ data }) => data);
  },
  changeDeployStatus(id, deploy) {
    return axios
      .put(`${ADMIN_JOUNRAL_DEFINITIONS_ENDPOINT}/${id}/status`, deploy)
      .then(({ data }) => data);
  },
};
