export type TGuideContext = {
  getGuideState: (name: string, pathname: string) => void;
  startGuide: () => void;
  isCompleted: boolean;
  isReady: boolean;
};

export type TGuideStep = TGuideStepBase & TGuideStepAssets;

export enum EGuideStepType {
  TOOLTIP = 'tooltip',
  MODAL = 'modal',
}

type TGuideStepBase = {
  type: EGuideStepType;
  targetId?: string;
  isLastStep?: boolean;
  align: EGuideStepAlign;
  position: {
    DEFAULT: EGuideStepPosition;
    xs?: EGuideStepPosition;
    sm?: EGuideStepPosition;
    md?: EGuideStepPosition;
    lg?: EGuideStepPosition;
    xl?: EGuideStepPosition;
    xxl?: EGuideStepPosition;
  };
};

type TGuideStepAssets = TGuideStepText | TGuideStepVideo | TGuideStepImage;

type TGuideStepText = {
  title?: string;
  text: string;
  videoKey?: never;
  imageKey?: never;
};

type TGuideStepVideo = {
  title?: string;
  text?: string;
  videoKey: string;
  imageKey?: never;
};

type TGuideStepImage = {
  title?: string;
  text?: string;
  videoKey?: never;
  imageKey: string;
};

export enum EGuideStepPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum EGuideStepAlign {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum EGuideStepAlignDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type TGuideStepDirection = EGuideStepAlignDirection | null;

export type TUseGuideReturn = {
  startGuide: TGuideContext['startGuide'];
  isCompleted: boolean;
  isReady: boolean;
};

export enum ETargetIds {
  TIMELINE_BUTTON = 'timeline-button',
  DASHBOARD_BUTTON = 'dashboard-button',
  SHARING_BUTTON = 'sharing-button',
  CONNECTIONS_BUTTON = 'connections-button',
  ADD_MANUAL_ENTRY_BUTTON = 'add-manual-entry-button',
  FAVORITES_SECTION = 'favorites-section',
}

export enum EGuideNames {
  TIMELINE_INTRO = 'timelineIntro',
  DASHBOARD_FIRST_CARD = 'firstCardDashboard',
}

export type TImageAsset = {
  key: string;
  title: string;
  type: 'image';
  featuredAsset: TImageFeaturedAsset;
};

export type TVideoAsset = {
  duration: string;
  key: string;
  poster: string;
  thumbnail: string;
  type: 'video';
  featuredAsset: TVideoFeaturedAsset;
};

type TFeaturedAssetBase = {
  credit: unknown;
  description?: string;
  id: string;
  images: TImageInfo[];
  keywords?: unknown;
  mimetype: string;
  name: string;
  preview: string;
  published: boolean;
  tags: unknown;
  thumbnail: string;
  type: string;
  url: string;
};

type TImageFeaturedAsset = TFeaturedAssetBase & {
  alt: string;
  focalPoint: unknown[];
};

type TVideoFeaturedAsset = TFeaturedAssetBase & {
  duration: string;
  poster: string;
  focalPoint: unknown;
  alt: unknown;
};

type TImageInfo = {
  path: string;
  width: number;
  height: number;
  type: string;
  format: string;
};
