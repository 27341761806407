import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTop({ as: Wrapper = 'div', ...props }) {
  const wrapperRef = useRef();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => wrapperRef.current?.scrollTo(0, 0));
  }, [history]);

  return <Wrapper ref={wrapperRef} {...props} />;
}

export default ScrollToTop;
