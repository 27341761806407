import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // TODO: expose click handlers
import classNames from 'classnames';
import { ChevronLeft } from './icons';
function Topbar({ left, right }) {
  return (
    <>
      <div className="relative flex h-14 items-center justify-between bg-smd-accent-topbar px-2">
        <div className="flex flex-1 items-center justify-between text-white lg:justify-start">
          {left}
        </div>
        <div className="hidden h-4.5 items-center space-x-2 lg:flex">
          {right}
        </div>
      </div>
    </>
  );
}

function IconButton({ icon: Icon, label, onClick, className }) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'smd-focus-visible-secondary flex shrink-0 items-center rounded-sm p-2 text-xs font-light uppercase lg:mr-8 lg:p-0',
        className
      )}
      aria-label={label}
    >
      <Icon strokeWidth="2" className="h-5 w-5" />{' '}
      <span className="hidden lg:inline">{label}</span>
    </button>
  );
}

function BackLink({ to, label, onClick }) {
  const { t } = useTranslation();
  return (
    <Link
      to={to}
      onClick={onClick}
      className="smd-focus-visible-secondary flex shrink-0 items-center rounded-sm p-2 text-xs font-light uppercase lg:mr-8 lg:p-0"
    >
      <ChevronLeft strokeWidth="2" className="h-5 w-5" />{' '}
      <span className="hidden lg:inline">{label || t('common.back')}</span>
    </Link>
  );
}

function Heading({ children }) {
  return (
    <div className="smd-focus-within-secondary grid rounded-sm text-sm font-semibold sm:font-bold md:text-base lg:mr-6">
      <div className="truncate">{children}</div>
    </div>
  );
}

Topbar.IconButton = IconButton;
Topbar.BackLink = BackLink;
Topbar.Heading = Heading;

export default Topbar;
