import { getIdMock, getParagraphMock, getImageMock } from './utils';

function create() {
  return {
    id: getIdMock(),
    name: getParagraphMock(1, 3),
    logo: getImageMock(200, 200),
  };
}

export default { create };
