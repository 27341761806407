import { useRouteMatch } from 'react-router-dom';
import { useIsEmbedded, useURL } from 'hooks';
import { useLibraryQuery } from 'library/hooks';
import { filterThemeLogos } from 'utils';
import { ViewerTopbar } from 'components/viewer';
import { normalizeAssetUrl } from 'journals/utils';
import { applyDistributionConfig } from 'journals/utils/distribution';

import { DEFAULT_TOPBAR_THEME } from '../const';

export default function JournalViewerTopbar({
  journal,
  onMenuToggle,
  onCloseClick,
  onShareClick,
}) {
  const { url } = useRouteMatch();

  const customizedJournal = applyDistributionConfig(journal);

  const logos = usePartnerLogos(customizedJournal);

  return (
    <ViewerTopbar
      title={customizedJournal?.preamble?.title}
      url={url}
      logos={logos}
      onMenuToggle={onMenuToggle}
      onShareClick={onShareClick}
      onCloseClick={onCloseClick}
    />
  );
}

function usePartnerLogos(journal) {
  const isEmbedded = useIsEmbedded();

  const urlQuery = useURL.useGetURLQuery();
  const partner = urlQuery.get('partner');
  const hasUrlQuery = Boolean(partner && isEmbedded);
  const extractedLogoIds = hasUrlQuery ? partner.trim().split(',') : [];

  const { data: partnerLogos, isLoading } = useLibraryQuery(extractedLogoIds, {
    enabled: hasUrlQuery,
    select: (items) =>
      items?.map(({ url }) => ({
        url: normalizeAssetUrl(url),
        alt: '',
      })),
  });

  const journalLogos = filterThemeLogos(
    journal?.preamble?.logos,
    journal?.preamble?.theme ?? DEFAULT_TOPBAR_THEME
  );

  if (isLoading) {
    return null;
  }

  return partnerLogos ?? journalLogos;
}
