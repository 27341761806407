import { useTranslation } from 'react-i18next';

import libraryTopicPropTypes from 'library/propTypes/article';
import { LibraryReferences } from 'components';
import { LibraryTopicCover } from 'library/components/topic';
import { LibraryRange } from 'library/components/range';
import React from 'react';
import { ExpandableSection, ExpandableSectionList } from 'components';
import LibraryArticle from '../../HtmlBlock/LibraryArticle';

function LibraryTopic({ config }) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6 bg-white text-smd-main">
      <LibraryTopicCover topic={config} />

      <ExpandableSectionList disclaimer={config?.disclaimer}>
        {config?.ranges?.map((range, index) => (
          <ExpandableSection key={index} title={range?.label}>
            <LibraryRange withLinks range={range} />
          </ExpandableSection>
        ))}

        {config?.sections
          ?.filter((x) => x)
          .map((item, index) => (
            <ExpandableSection
              key={index}
              title={item.name ? item.name : item.title}
            >
              <LibraryArticle
                config={{
                  text: { html: item.text },
                  credit: item.credit,
                  asset: item.asset,
                  className: 'ml-0',
                }}
              />
            </ExpandableSection>
          ))}

        {config?.references && (
          <ExpandableSection
            title={t('journals.blocks.html.library-card.references')}
          >
            {config?.references && (
              <LibraryReferences references={config?.references} />
            )}
          </ExpandableSection>
        )}
      </ExpandableSectionList>
    </div>
  );
}

LibraryTopic.propTypes = {
  config: libraryTopicPropTypes.shape,
};

export default LibraryTopic;
