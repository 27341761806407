import { ControlledMenu } from '@szhsin/react-menu';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useMedia } from 'hooks';
import { ChevronDown } from 'components/icons';
import { JourneysDropDown } from 'components/DropdownMenuSections';
import {
  useJourneysHover,
  useDelayedTaskWithCommonReset,
  constantas,
} from './hooks';

const linkClassName =
  'smd-focus-visible-primary flex items-center truncate rounded-sm py-1 font-semibold lg:px-2';

export function HoverMenu({ children }) {
  return children;
}

function HoverMenuJourneys({ journeyClassName, onOpen, onClose }) {
  const { t } = useTranslation();
  const isLargeScreen = useMedia(useMedia.LARGE);
  const {
    anchorProps,
    hoverJourneyAnchorProps,
    hoverJourneyProps,
    isJourneyOpen,
    setJourneyOpen,
  } = useJourneysHover();

  const JourneyComponent = isLargeScreen ? 'a' : 'button';

  const { delayTask: delayedOnOpen, resetTimer: resetOnOpen } =
    useDelayedTaskWithCommonReset(onOpen, constantas.HOOVER_OPEN_DELAY);

  const { delayTask: delayedOnClose, resetTimer: resetOnClose } =
    useDelayedTaskWithCommonReset(onClose, constantas.HOOVER_CLOSE_DELAY);

  function pointLeaved() {
    resetOnOpen();
    delayedOnClose();
  }

  function pointEntered() {
    resetOnClose();
    delayedOnOpen();
  }

  return (
    <div onPointerEnter={pointEntered} onPointerLeave={pointLeaved}>
      <JourneyComponent
        href="/journeys"
        rel="noopener noreferrer"
        className={classnames(journeyClassName, linkClassName)}
        aria-label={t('labels.menu.journeys')}
        data-testid="menuJourneysLink"
        type="button"
        {...(isLargeScreen && hoverJourneyAnchorProps)}
        {...anchorProps}
      >
        <div className="flex items-center space-x-2 hover:underline">
          <span className="truncate">{t('main-menu.journeys')}</span>
          <ChevronDown className="h-4 w-4 stroke-2" />
        </div>
      </JourneyComponent>

      <ControlledMenu
        {...(isLargeScreen && hoverJourneyProps)}
        state={isJourneyOpen ? 'open' : 'closed'}
        anchorPoint={{ x: 0, y: 200 }} //hide a hover element behind the menu
        onClose={() => setJourneyOpen(false)}
      >
        <JourneysDropDown />
      </ControlledMenu>
    </div>
  );
}

HoverMenu.Journeys = HoverMenuJourneys;
