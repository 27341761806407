import React from 'react';

import InlineHtmlBlock from './InlineHtmlBlock';
import LibraryInteractive from './LibraryInteractive';
import LibraryArticle from './LibraryArticle';
import LibraryTopic, { useLibraryPopOver } from './LibraryTopic';
import LibraryAsset, { assetTypes } from './LibraryAsset';

export const createLibraryItem = (item) => {
  if ('article' === item.type) {
    return <LibraryArticle key={item.id} config={item} />;
  }
  if ('topic' === item.type) {
    return <LibraryTopic key={item.id} config={item} />;
  }
  if ('interactive' === item.type) {
    return <LibraryInteractive key={item.id} config={item} />;
  }
  if (assetTypes.includes(item.type)) {
    return <LibraryAsset key={item.id} config={item} />;
  }
  return null;
};

const HtmlBlock = ({ config }) => {
  const { format } = config;

  const PopoverProvider = useLibraryPopOver();

  switch (format) {
    case 'article':
      try {
        return (
          <PopoverProvider>
            {JSON.parse(config.value).map(createLibraryItem)}
          </PopoverProvider>
        );
      } catch {
        return null;
      }
    default:
      return <InlineHtmlBlock config={config} />;
  }
};

export default HtmlBlock;
