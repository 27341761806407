import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

function Portal({ children, prepend, container = document.body }) {
  const [wrapper] = useState(() => document.createElement('div'));

  useLayoutEffect(() => {
    prepend ? container.prepend(wrapper) : container?.append(wrapper);
    return () => container?.removeChild(wrapper);
  }, [wrapper, prepend, container]);

  return createPortal(children, wrapper);
}

export default Portal;
