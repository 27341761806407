import { forwardRef, useEffect, useRef, useState } from 'react';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from './Button';
import Input from './Input';
import Checkbox from './Checkbox';
import styles from './MultiSelect.module.css';

function MultiSelect(props) {
  const { t } = useTranslation();

  const { value = [], onChange, onCancel, options = [], ...rest } = props;
  const inputRef = useRef();

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const [isOpen, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value);

  const handleOnItemClick = (e) => {
    const clickedItemValue = e.value.label;
    e.keepOpen = true;
    setSelectedOptions((prev) => {
      const exists = prev.some((x) => x === clickedItemValue);
      let selectedValues = [...prev, e.value.label];
      if (exists) {
        selectedValues = prev.filter((x) => x !== clickedItemValue);
      }
      onChange?.(selectedValues);
      return selectedValues;
    });
  };

  return (
    <>
      <Input
        type="text"
        ref={inputRef}
        onClick={() => setOpen(true)}
        className="relative"
        placeholder={t('my-phr.manual-inputs.placeholders.select-reactions')}
        value=""
        {...rest}
      />
      <>
        <ControlledMenu
          state={isOpen ? 'open' : 'closed'}
          anchorRef={inputRef}
          onClose={() => setOpen(false)}
          onItemClick={handleOnItemClick}
          className={classnames(styles.multiSelect, styles['szh-menu'])}
        >
          {options.map((option) => {
            const isChecked = selectedOptions?.find((x) => x === option.label);
            return (
              <MenuItem type="checkbox" value={option} key={option.value}>
                <div className="flex items-center space-x-2">
                  <Checkbox checked={isChecked} />
                  <span>{option.label}</span>
                </div>
              </MenuItem>
            );
          })}
          <div className="sticky -bottom-4 -mb-4 flex shrink-0 items-center justify-end gap-2 border-t bg-white p-4">
            <Button.Tertiary
              onClick={(e) => {
                e.preventDefault();
                setSelectedOptions([]);
                onCancel();
              }}
            >
              {t('common.clear')}
            </Button.Tertiary>
            <Button.Primary
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              {t('common.ok')}
            </Button.Primary>
          </div>
        </ControlledMenu>
      </>
    </>
  );
}

export default forwardRef(MultiSelect);
