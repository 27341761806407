import { useTranslation } from 'react-i18next';
import { ContentContainer } from 'components';
import { PictureResource } from 'components/media';
import { forwardRef } from 'react';

function CollectionCard({ name, thumbnail, logo, id: collectionId, onClick }) {
  const { t } = useTranslation();
  return (
    <article
      onClick={() => onClick(collectionId)}
      className="smd-transform-duration cursor-pointer border border-smd p-4"
    >
      <div className="flex items-center justify-start">
        <div>
          <PictureResource
            config={{ url: logo }}
            className="h-8 w-8 grow-0 rounded-full"
          />
        </div>
        <span className="text-smd-sm">{t('common.storymd')}</span>
      </div>
      <h3 className="py-2 text-smd-h4 font-bold line-clamp-2">{name}</h3>
      <PictureResource
        config={{ url: thumbnail }}
        className="h-40	w-full object-cover object-center"
      />
    </article>
  );
}

function JourneyCollections({ collections = [], onCollectionSelected }, ref) {
  const { t } = useTranslation();
  return (
    <>
      <div ref={ref} />
      <ContentContainer className="flex justify-start pb-12 lg:pb-0">
        <main className="w-full space-y-4 p-8">
          <div className="flex flex-col lg:flex-row">
            <h3 className="text-smd-h2.5 font-bold">
              {t('common.collection', { count: Number.MAX_VALUE })}
            </h3>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 2xl:grid-cols-4">
            {collections.map((collection) => (
              <CollectionCard
                onClick={() =>
                  onCollectionSelected(collection.id, collection.name)
                }
                key={collection.id}
                {...collection}
              />
            ))}
          </div>
        </main>
      </ContentContainer>
    </>
  );
}

export default forwardRef(JourneyCollections);
