import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRandomStaticResource } from 'hooks';

function NoContentLayout({ children }) {
  const { t } = useTranslation();

  const heroImage = useRandomStaticResource('heroes');
  return (
    <div className="flex grow flex-col items-center bg-white lg:flex-row">
      <div className="m-6 flex shrink-0 justify-center md:m-8 lg:m-10 lg:w-1/3 ">
        <div className="max-w-sm space-y-4 text-center font-light">
          {children}
        </div>
      </div>
      <div className="flex shrink-0 flex-col justify-center lg:h-full lg:shrink lg:flex-grow">
        <img
          className="flex-grow object-contain"
          src={heroImage?.key}
          alt={heroImage?.name}
        />
        <div className="hidden pr-10 pb-10 text-right text-sm text-smd-secondary antialiased lg:block">
          <p>{heroImage?.name}</p>
          {heroImage?.credit && (
            <p className="italic">
              {t('errors.layout.label-image') + ' '}
              {heroImage.credit}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default NoContentLayout;
