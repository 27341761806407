import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'components/icons';
import LibraryAsset from 'journals/blocks/output/HtmlBlock/LibraryAsset';
import { useLibraryQuery } from 'library/hooks';
import { useTranslation } from 'react-i18next';

function Glossary({ onClose, hashId }) {
  const { t } = useTranslation();

  const { data: glossary, isFetched } = useLibraryQuery(hashId);
  const { name, featuredAsset, synonyms, description, credit } = glossary || {};
  return isFetched && glossary ? (
    <div className="flex h-screen flex-col overflow-hidden lg:h-auto lg:max-h-90vh">
      <div className="relative flex grow-0 items-center bg-smd-accent p-4">
        <h1
          onClick={onClose}
          className="grow font-semibold text-white line-clamp-1"
        >
          {name}
        </h1>
        <button
          onClick={onClose}
          className="shrink-0 text-white"
          aria-label={t('common.close')}
        >
          <X className="w-6" strokeWidth="1.5" />
        </button>
      </div>
      <article className="grow overflow-y-auto bg-white antialiased">
        <div className="px-4 py-smd-xl lg:px-smd-xl">
          {featuredAsset && (
            <div className="pb-smd-base">
              <LibraryAsset config={{ ...featuredAsset }} />
            </div>
          )}
          <h2 className="mb-smd-base break-all text-smd-h2 font-semibold">
            {name}
          </h2>
          {synonyms?.length > 0 && (
            <p
              data-testid="synonyms"
              className="mb-smd-xs text-smd-sm text-smd-tertiary"
            >
              {synonyms.join(', ')}
            </p>
          )}
          <div
            className="prose mb-smd-base w-full max-w-none text-smd-base "
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {credit && (
            <div className="flex text-smd-sm italic  text-smd-tertiary">
              {t('common.source', { source: credit.name })}
            </div>
          )}
        </div>
      </article>
    </div>
  ) : null;
}

Glossary.propTypes = {
  hashId: PropTypes.string.isRequired,
};

export default Glossary;
