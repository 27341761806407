export const RESULTS_LARGE_CONTENT_CONTAINERS = [
  'healthtopic',
  'poc',
  'channel',
  'image',
  'video',
];

export const LIBRARY_SEARCH_KEY = {
  all: 'all',
  healthtopic: 'healthjournals',
  poc: 'poc',
  event: 'stories',
  biomarker: 'tests',
  drug: 'drugs',
  image: 'images',
  video: 'videos',
};
