import { forwardRef } from 'react';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal text-smd-base',
  'smd-input-focus-primary',
];

export default forwardRef(function Select({ className, error, ...props }, ref) {
  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';
  const classes = base.concat(border, className).join(' ');

  return <select ref={ref} className={classes} {...props} />;
});
