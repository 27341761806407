import { minutesToMilliseconds } from 'date-fns';
import { doLogout, getToken } from 'Auth';
import { curry } from 'ramda';

import { currentPartnerLocalStorageKey } from 'PartnerContext';

export const defaultQueryStaleTimeInMinutes = minutesToMilliseconds(5);
export const defaultQueryCacheTimeInMinutes = minutesToMilliseconds(5);

export const bearerInterceptor = (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const partnerInterceptor = (config) => {
  const currentPartner = JSON.parse(
    localStorage.getItem(currentPartnerLocalStorageKey)
  );

  if (currentPartner) {
    config.headers['x-ba'] = currentPartner?.id;
  }
  return config;
};

export const unauthorizedInterceptor = (err) => {
  if (err?.response?.status === 401) {
    doLogout();
  }
  return Promise.reject(err);
};

export const phrPaymentRequiredInterceptor = (err) => {
  if (err?.response?.status === 402) {
    window.location.href = '/health';
  }
  return Promise.reject(err);
};

export const addIntentionalDelay = curry((delay, fn) => {
  return (...args) => {
    const start = Date.now();
    return fn(...args).then((result) => {
      const now = Date.now();
      const elapsed = now - start;
      if (elapsed < delay) {
        return new Promise((resolve) =>
          setTimeout(() => resolve(result), delay - elapsed)
        );
      }
      return result;
    });
  };
});

export const addDefaultIntentianalDelay = addIntentionalDelay(500);

export const getUpdateFormData = (input = {}) => {
  const formData = new FormData();
  const { files, ...rest } = input;

  if (files) {
    files?.forEach?.((file) => formData.append('files', file));
  }

  const data = new Blob([JSON.stringify(rest)], {
    type: 'application/json',
  });
  formData.append('data', data);
  return formData;
};
