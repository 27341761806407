import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'components/forms';

function CollectionPlaceholder({ defaultCollection = false }) {
  const { t } = useTranslation();

  return (
    <div>
      {defaultCollection ? (
        <>
          <p className="my-smd-lg mb-8 max-w-xl text-smd-secondary">
            {t('journals.collections.add-journals')}
          </p>
          <p className="my-smd-lg max-w-xl text-smd-secondary">
            {t('journals.collections.organize-journals')}
          </p>
        </>
      ) : (
        <>
          <p className="my-smd-lg max-w-xl text-smd-secondary">
            {t('journals.collections.begin-hint')}
          </p>
        </>
      )}
      <Link to="/explore" className="smd-focus-visible-primary rounded-sm py-2">
        <Button.Primary tabIndex={-1}>
          {t('journals.explore-library')}
        </Button.Primary>
      </Link>
    </div>
  );
}

export default CollectionPlaceholder;
