import axios from 'axios';

import {
  bearerInterceptor,
  phrPaymentRequiredInterceptor,
  partnerInterceptor,
  unauthorizedInterceptor,
} from './utils';
import { getSessionId } from './session';

export const Headers = {
  FORMDATA: { 'Content-Type': 'multipart/form-data' },
  XML: { 'Content-Type': 'application/xml' },
};

export const external = axios.create();

//PUBLIC
export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'x-smd-session-id': getSessionId(),
  },
});

//JOURNALS
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.request.use(bearerInterceptor);
instance.interceptors.response.use(null, unauthorizedInterceptor);
if (process.env.REACT_APP_PRO_ACCOUNTS_STATE === 'ENABLED') {
  instance.interceptors.request.use(partnerInterceptor);
}

//COMMUNITIES
export const communitiesApi = axios.create({
  baseURL: process.env.REACT_APP_COMMUNITIES_API_URL,
});
communitiesApi.interceptors.request.use(bearerInterceptor);

//PHR
export const phr = axios.create({
  baseURL: process.env.REACT_APP_PHR_URL,
});
phr.interceptors.request.use(bearerInterceptor);
phr.interceptors.response.use(null, unauthorizedInterceptor);
phr.interceptors.response.use(null, phrPaymentRequiredInterceptor);

// PAYMENT
export const paymentApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
});
paymentApi.interceptors.request.use(bearerInterceptor);

export default instance;
