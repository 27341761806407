import { useStoreLastLocation } from 'hooks';

export default function useStoryLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'story-navigation-origin',
  });

  return {
    setStoryLastLocation: set,
    pushStoryLastLocation: push,
    popStoryLastLocation: pop,
    storyLastLocation: lastLocation,
  };
}
