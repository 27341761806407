import { useQuery } from 'react-query';

import axios, { external } from 'api/axios';

const signerUrl = process.env.REACT_APP_CDN_SIGNER_URL;

function fetchSignedCookie() {
  if (signerUrl) {
    return external.get(`${signerUrl}/users/auth-resources`, {
      withCredentials: true,
    });
  }
  return axios.get('/users/auth-resources', { withCredentials: true });
}

export default function useInitCdn(authenticated) {
  const { isLoading, isSuccess } = useQuery(
    'cdnSignedCookie',
    fetchSignedCookie,
    {
      enabled: authenticated,
      onError: (error) => console.error('Failed to initialize CDN', error),
    }
  );

  return { isInitialized: isSuccess, isInitializing: isLoading };
}
