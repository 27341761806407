import { BroadcastChannel } from 'broadcast-channel';
const PHR_AUTH_SUCCESS_CHANEL_NAME = 'PHR_AUTH_SUCCESS';
const broadcastChannel = new BroadcastChannel(PHR_AUTH_SUCCESS_CHANEL_NAME);

export default function usePhrSourceAuthSuccessBroadcastChannel() {
  return {
    postMessage: () =>
      broadcastChannel.postMessage(PHR_AUTH_SUCCESS_CHANEL_NAME),
    addNewListener: (onMessage) => {
      new BroadcastChannel(PHR_AUTH_SUCCESS_CHANEL_NAME).onmessage = onMessage;
    },
  };
}
