import { useCallback, useEffect, useRef, useState } from 'react';
import { calculateScrollPosition, debounce } from 'utils';
import useWindowSize from './useWindowSize';

const SCROLL_THRESHOLD = 150;
const SCROLL_CONTAINER_SAFEZONE_MULTIPLIER = 0.33;
const SCROLL_EVENT_DEBOUNCE = 50;
const RESIZE_EVENT_DEBOUNCE = 500;

function useContentOverflowScroll(
  deps,
  {
    scrollThreshold = SCROLL_THRESHOLD,
    scrollContainerSafeZoneMultiplier = SCROLL_CONTAINER_SAFEZONE_MULTIPLIER,
    scrollEventDebounce = SCROLL_EVENT_DEBOUNCE,
    resizeEventDebounce = RESIZE_EVENT_DEBOUNCE,
    smoothScrollCss = true,
  } = {}
) {
  const containerRef = useRef();

  const { width } = useWindowSize(resizeEventDebounce);

  const [positionState, setPositionState] = useState({
    isBeginning: false,
    isEnd: false,
  });

  useEffect(() => {
    const containerEl = containerRef.current;
    if (containerEl) {
      const scrollHandler = debounce(function scroll() {
        setPositionState(() => calculateScrollPosition(containerEl));
      }, scrollEventDebounce);

      containerEl.addEventListener('scroll', scrollHandler, {
        passive: true,
      });

      return () => {
        containerEl.removeEventListener('scroll', scrollHandler, {
          passive: true,
        });
      };
    }
  }, [scrollEventDebounce]);

  useEffect(() => {
    setPositionState(() => calculateScrollPosition(containerRef.current));
    return () => {
      setPositionState({
        isBeginning: false,
        isEnd: false,
      });
    };
    // passing anything to the hook that needs to trigger recalculation
  }, [width, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  // elStart is the active tab beginning in pixels
  const scrollTo = useCallback(
    (elStart) => {
      const scrollEl = containerRef.current;
      if (!scrollEl) return;
      // add some spacing on the left of a selected option to not overlap with nav indicators
      const scrollElSafeZone = parseInt(
        scrollEl.getBoundingClientRect().width *
          scrollContainerSafeZoneMultiplier
      );
      if (smoothScrollCss) {
        scrollEl.scrollLeft = elStart - scrollElSafeZone;
      } else {
        scrollEl.scroll?.({
          left: elStart - scrollElSafeZone,
          behavior: 'smooth',
        });
      }
    },
    [scrollContainerSafeZoneMultiplier, smoothScrollCss]
  );

  const scrollLeft = () => {
    if (!containerRef.current) return;
    if (smoothScrollCss) {
      containerRef.current.scrollLeft -= scrollThreshold;
    } else {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft - scrollThreshold,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (!containerRef.current) return;
    if (smoothScrollCss) {
      containerRef.current.scrollLeft += scrollThreshold;
    } else {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft + scrollThreshold,
        behavior: 'smooth',
      });
    }
  };

  return {
    containerRef,
    scrollLeft,
    scrollRight,
    scrollTo,
    ...positionState,
  };
}

export default useContentOverflowScroll;
