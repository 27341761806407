import { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import classes from './Fade.module.css';

const DURATION = 300;

const {
  fadeIn: fadeInClassName,
  fadeOut: fadeOutClassName,
  ...classNames
} = classes;

function Fade({ in: fadeIn, out: fadeOut, show, children }) {
  const nodeRef = useRef(null);

  if (fadeIn) classNames.enterActive = fadeInClassName;
  if (fadeOut) classNames.exitActive = fadeOutClassName;

  return (
    <CSSTransition
      in={Boolean(show)}
      timeout={{
        enter: fadeIn ? DURATION : 0,
        exit: fadeOut ? DURATION : 0,
      }}
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
      classNames={classNames}
    >
      {children}
    </CSSTransition>
  );
}

Fade.propTypes = {
  in: PropTypes.bool,
  out: PropTypes.bool,
  show: PropTypes.any,
};

Fade.defaultProps = {
  in: false,
  out: false,
  show: false,
};

export default Fade;
