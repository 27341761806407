import { useTranslation } from 'react-i18next';
import NoContentLayout from '../../NoContentLayout';

export function NoSearchResults({ title, children, showSuggestions = true }) {
  const { t } = useTranslation();

  return (
    <div className="flex-1">
      <NoContentLayout>
        <div className="text-left antialiased">
          <h1 className="mb-12 text-smd-h3 font-semibold">{title}</h1>
          {children ?? (
            <>
              {showSuggestions && (
                <>
                  <h2 className="text-smd-lg mb-2 font-semibold">
                    {t('library.search.no-results.suggestions')}
                  </h2>
                  <ul className="list-disc space-y-smd-xs pl-smd-base">
                    <li>{t('library.search.no-results.different-keywords')}</li>
                    <li>{t('library.search.no-results.check-spelling')}</li>
                    <li>{t('library.search.no-results.check-language')}</li>
                  </ul>
                </>
              )}
            </>
          )}
        </div>
      </NoContentLayout>
    </div>
  );
}
