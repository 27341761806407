import { useHistory, useParams } from 'react-router-dom';

import {
  useCollection,
  useCollectionJournals,
  useCollectionUpdate,
} from 'hooks';

import { ContentContainer } from 'components';
import { EditCollectionWizard } from './components';

function CollectionEditDashboard() {
  const history = useHistory();
  const { id } = useParams();

  const { collection, isFetching: collectionLoading } = useCollection(id);
  const { collectionJournals, isFetching: collectionJournalsLoading } =
    useCollectionJournals(id);

  const { updateCollection, isLoadingUpdate } = useCollectionUpdate({
    onSuccess: () => history.replace('/journals/collections/manage'),
  });
  const isNotLoading = !(collectionJournalsLoading || collectionLoading);
  return (
    isNotLoading && (
      <div className="flex w-full flex-grow flex-col bg-white">
        <section className="px-smd-base pt-smd-lg pb-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
          <ContentContainer>
            <EditCollectionWizard
              collection={collection}
              collectionJournals={collectionJournals}
              onCancel={() => history.push('/journals/collections/manage')}
              onUpdate={updateCollection}
              isLoadingUpdate={isLoadingUpdate}
            />
          </ContentContainer>
        </section>
      </div>
    )
  );
}

export default CollectionEditDashboard;
