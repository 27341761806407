import { forwardRef } from 'react';
import classnames from 'classnames';

const Button = forwardRef(function Button(
  { children, active, className, ...props },
  ref
) {
  return (
    <button
      className={classnames(
        className ||
          'flex-col-center smd-focus-visible-primary h-12 w-16 cursor-pointer rounded-sm leading-tight',
        { 'text-smd-accent': active }
      )}
      {...props}
      ref={ref}
    >
      {children}
    </button>
  );
});

function ViewerSidebar({ children, className }) {
  return (
    <div
      className={classnames(
        className ||
          'w-18 mx-2 flex flex-col items-center space-y-1 py-2 text-smd-2xs text-smd-main'
      )}
    >
      {children}
    </div>
  );
}

export default Object.assign(ViewerSidebar, { Button });
