import React from 'react';
import { isNil } from 'ramda';
import { ErrorBoundary } from '@sentry/react';
import LibraryCarousel from 'components/LibraryCarousel';

const LibraryBlock = ({ config }) => {
  const { format, value } = config;

  if (isNil(value)) return null;

  switch (format) {
    case 'carousel':
    default:
      return (
        <ErrorBoundary fallback={null}>
          <LibraryCarousel config={config} />
        </ErrorBoundary>
      );
  }
};

export default LibraryBlock;
