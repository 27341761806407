import * as propTypes from './propTypes';
import { getPadding } from './utils';

export default function AspectFixed({
  ratio,
  className,
  style,
  children,
  ...props
}) {
  const paddingTop = getPadding(ratio);

  return (
    <div
      style={{ paddingTop, ...style }}
      className={`relative h-0 overflow-auto ${className}`}
      {...props}
    >
      <div className="absolute inset-0">{children}</div>
    </div>
  );
}

AspectFixed.propTypes = {
  ratio: propTypes.ratio,
};

AspectFixed.defaultProps = {
  ratio: 1,
  className: '',
  style: {},
};
