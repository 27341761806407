import { nanoid } from 'nanoid';
import { loremIpsum } from 'lorem-ipsum';

export function getRandomInt(min = 0, max = 1e6) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomEntity(entities) {
  if (entities?.length) {
    return entities[getRandomInt(0, entities.length - 1)];
  }
  return null;
}

export function getRandomBool() {
  return Math.random() < 0.5;
}

export function getIdMock(length = 10) {
  return nanoid(length);
}

export function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export function getDateMock() {
  return randomDate(new Date(0), new Date());
}

export function getParagraphMock(min, max) {
  return loremIpsum({
    paragraphLowerBound: min,
    paragraphUpperBound: max,
  });
}

export function getImageMock(width = 600, height = 400) {
  return `https://picsum.photos/${width}/${height}?random=${getRandomInt()}`;
}
