const classes = [
  'text-smd-base',
  'px-smd-lg py-smd-xs',
  'rounded-full',
  'text-smd-accent bg-smd-accent-light',
].join(' ');

function Tag({ className, ...props }) {
  return <span className={`${className ?? ''} ${classes}`} {...props} />;
}

export default Tag;
