import { forwardRef } from 'react';
import classnames from 'classnames';

import classes from './Switch.module.css';

export default forwardRef(function Switch({ className, type, ...props }, ref) {
  return (
    <label
      className={classnames(
        classes.switch,
        'smd-focus-within-primary rounded-sm',
        className
      )}
    >
      <input type="checkbox" ref={ref} {...props} />
      <span className={classnames(classes.slider, classes.round)}></span>
    </label>
  );
});
