import PropTypes from 'prop-types';

export const range = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  label: PropTypes.shape({
    content: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  }),
  color: PropTypes.string,
});
