import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { head, isNil } from 'ramda';
import classnames from 'classnames';

import { Numeric, Slider } from 'components/range';
import { findRangeForValue, getColorScheme } from 'components/range/utils';
import { replaceSupTag } from 'utils';

function initialize(ranges, initialValue) {
  if (isNil(initialValue)) {
    const initialRange = head(ranges);
    return { value: initialRange.min, range: initialRange };
  }
  const initialRange = findRangeForValue(ranges, initialValue);
  return { value: initialValue, range: initialRange };
}

function LibraryRange({
  withLinks,
  range: {
    title,
    items,
    units,
    value: initialValue,
    step,
    hideunits: hideUnits,
    scale,
  },
}) {
  const hasIcons = items.some((item) => item.icon);
  const ranges = useMemo(() => {
    const colorScheme = getColorScheme(items);

    return items.map((item, index) => {
      const { values, icon } = item;
      const { min, max } = values;

      const label = hideUnits
        ? {
            content: item.label?.short,
            orientation: item.label?.orientation,
          }
        : {
            orientation: item.label?.orientation,
          };

      return {
        min,
        max,
        label,
        icon,
        color: colorScheme[index],
        output: {
          result: item.label?.long,
          resultShort: item.label?.short,
          description: item.text,
          conditions: item.conditions,
        },
      };
    });
  }, [items, hideUnits]);

  const logScale = scale === 'log';

  const [{ value, range: currentRange }, setCurrent] = useState(() =>
    initialize(ranges, initialValue)
  );

  const handleChange = (value, range) => setCurrent({ value, range });

  const { t } = useTranslation();

  return (
    <div className="antialiased">
      {title && <p className="text-lg font-semibold">{title}</p>}
      <p className="mb-4 text-sm italic text-smd-gray-dark">
        {t('journals.blocks.html.range.subtitle')}
      </p>

      <div className="flex flex-col items-center space-y-2 md:flex-row md:space-y-0 md:space-x-6">
        {!hideUnits && (
          <div>
            <Numeric
              ranges={ranges}
              value={value}
              step={step}
              onChange={handleChange}
            />
            <p
              title={units?.[0]?.name}
              className=" cursor-default text-center text-sm text-smd-gray-dark"
            >
              {replaceSupTag(units?.[0]?.printSymbol)}
            </p>
          </div>
        )}
        <div
          className={classnames(hasIcons ? 'pb-4 sm:pb-0' : 'pb-1', 'w-full')}
        >
          <Slider
            ranges={ranges}
            value={value}
            step={step}
            logScale={logScale}
            centerLabels={hideUnits || hasIcons}
            snapToCenter={hideUnits}
            onChange={handleChange}
            isTopicPage
          />
        </div>
      </div>

      <p className="mt-4 mb-2 font-semibold">
        <Trans
          i18nKey="journals.blocks.html.range.result"
          values={{ result: currentRange?.output?.result }}
          components={{
            result: (
              <span
                className="font-semibold uppercase"
                style={{ color: currentRange?.color }}
              />
            ),
          }}
        />
      </p>
      {currentRange && (
        <div
          className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-12"
          style={{ minHeight: 100 }}
        >
          <div
            className="text-smd-sm md:w-1/2 lg:w-2/3"
            dangerouslySetInnerHTML={{
              __html: currentRange?.output?.description,
            }}
          />
          <div className="md:w-1/2 lg:w-1/3">
            {currentRange?.output?.conditions?.length > 0 && (
              <>
                <p className="mb-2 font-semibold">
                  {t('journals.blocks.html.range.conditions')}
                </p>
                <ul
                  className={classnames('prose prose-sm', {
                    'prose-no-anchors': !withLinks,
                  })}
                >
                  {currentRange?.output?.conditions.map((cond, index) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: cond }}
                    />
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

LibraryRange.propTypes = {
  title: PropTypes.string,
  step: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      flag: PropTypes.oneOf([
        'normal',
        'borderline',
        'abnormal',
        'positive',
        'negative',
      ]).isRequired,
      values: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      label: PropTypes.shape({
        short: PropTypes.string,
        long: PropTypes.string,
        orientation: PropTypes.oneOf(['horizontal', 'vertical']),
      }),
      text: PropTypes.string,
      conditions: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  units: PropTypes.arrayOf(
    PropTypes.shape({
      printSymbol: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  hideUnits: PropTypes.bool,
};

export default LibraryRange;
