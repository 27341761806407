import { useTranslation } from 'react-i18next';

function NoDataCell(props) {
  const { t } = useTranslation();
  return (
    <td className="p-4 text-center" colSpan={15} {...props}>
      {t('common.no-results')}
    </td>
  );
}

export default NoDataCell;
