import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { NotificationsApi } from 'api';

const notificationKeys = {
  all: () => ['notifications'],
  configuration: () => ['notifications-configuration'],
  unread: () => [...notificationKeys.all(), 'unread'],
  list: () => [...notificationKeys.all(), 'list'],
};

const PER_PAGE = 10;

export function useHasUnreadNotifications(options) {
  return useQuery(
    notificationKeys.unread(),
    NotificationsApi.getUnread,
    options
  );
}

export function useNotifications(options) {
  return useInfiniteQuery(
    notificationKeys.list(),
    async ({ pageParam = 0 }) => {
      const queryParams = {
        size: PER_PAGE,
        page: pageParam,
      };
      return NotificationsApi.getAll(queryParams);
    },
    {
      getNextPageParam: (currentState) => {
        const hasNext =
          currentState.totalPages > 1 &&
          currentState.currentPage < currentState.totalPages;
        return hasNext ? currentState.currentPage + 1 : undefined;
      },
      ...options,
    }
  );
}

export function useNotificationConfiguration() {
  return useQuery(notificationKeys.configuration(), NotificationsApi.getConfig);
}

export function useNotificationConfigurationUpdate() {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(NotificationsApi.updateConfig, {
    onSuccess: () =>
      queryClient.invalidateQueries(notificationKeys.configuration()),
  });
  return {
    updateConfig: mutate,
  };
}

export function useTogglePlatformNotifications() {
  const queryClient = useQueryClient();
  const { mutate: toggleNotifications } = useMutation(
    NotificationsApi.toggleNotifications,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(notificationKeys.configuration()),
    }
  );
  return {
    toggleNotifications,
  };
}

export function useNotificationDelete() {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries(notificationKeys.all());
  const { mutate: deleteAllNotifications } = useMutation(
    NotificationsApi.deleteAll,
    {
      onSuccess,
    }
  );
  const { mutate: deleteNotification } = useMutation(NotificationsApi.delete, {
    onSuccess,
  });
  return {
    deleteAllNotifications,
    deleteNotification,
  };
}
