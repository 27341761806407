import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import useFileImageAsUrl from '../hooks/useFileImageAsUrl';

const returnTypeProcessors = {
  blob: (canvas, fileName) => {
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  },
  url: (canvas) => {
    return new Promise((resolve) => {
      resolve(canvas.toDataURL());
    });
  },
};

export default function CropImageFromFile({ file, ...props }) {
  const src = useFileImageAsUrl(file);
  return src ? <CropImage src={src} {...props} /> : null;
}

function CropImage({ src, initialCrop, onChange, returnType = 'blob' }) {
  const [crop, setCrop] = useState(initialCrop);
  const [completeCrop, setCompleteCrop] = useState(null);

  const imageRef = useRef();

  const handleImageLoaded = (image) => {
    imageRef.current = image;
    setCrop(initialCrop);
  };

  useEffect(() => {
    setCrop(initialCrop);
  }, [initialCrop, src]);

  useEffect(() => {
    let isSubscribed = true;
    const image = imageRef.current;
    const crop = completeCrop;

    if (image && crop && onChange) {
      cropImage(image, crop, 'avatar', returnType).then((res) => {
        if (isSubscribed) {
          onChange(res);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [completeCrop, onChange, returnType]);

  return (
    <ReactCrop
      src={src}
      crop={crop}
      circularCrop
      keepSelection
      onChange={setCrop}
      onComplete={setCompleteCrop}
      onImageLoaded={handleImageLoaded}
    />
  );
}

function cropImage(image, crop, fileName, returnType) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // blob or url
  return returnTypeProcessors[returnType](canvas, fileName);
}
