import classnames from 'classnames';

import { useAds, useIsEmbedded, useMedia } from 'hooks';
import { Ad, AD_SIZE } from 'components/Ad';

const asideBaseClassName = 'z-20 shrink-0 bg-white shadow-2xl';

export default function ViewerLayout({
  sidebarClassName,
  asideClassName,
  className,
  sidebar,
  topbar,
  footer,
  hero,
  aside,
  breadcrumb,
  main,
}) {
  const isEmbedded = useIsEmbedded();

  const ad = useAds(['placements', 'journal', 'page', 'top']);
  const isLarge = useMedia(useMedia.LARGE);
  const withTopbar = topbar || breadcrumb ? 'top-28' : 'top-14';
  const embeddedWithTopbar = topbar ? 'top-14' : 'top-0';
  const stickyTopOffsetClassName = isEmbedded ? embeddedWithTopbar : withTopbar;

  return (
    <div
      className={classnames(
        'relative flex h-full grow flex-col bg-white',
        className
      )}
    >
      {ad?.enabled && (
        <Ad
          className="flex shrink-0 justify-center py-2"
          slot={ad?.slot}
          dimensions={{
            base: AD_SIZE.HORIZONTAL.PHONE_BANNER_MEDIUM,
            md: AD_SIZE.HORIZONTAL.LEADER_BOARD,
            // lg: AD_SIZE.HORIZONTAL.SUPER_LEADER_BOARD,
          }}
        />
      )}
      {breadcrumb ?? null}
      {topbar && (
        <div
          className={classnames(
            'fixed top-0 z-40 w-full shrink-0',
            !isEmbedded && 'lg:sticky lg:top-smd-sticky-spacer-small'
          )}
        >
          {topbar}
        </div>
      )}
      {hero && <div>{hero}</div>}
      <div className="flex grow">
        {sidebar && (
          <div
            className={classnames(
              sidebarClassName ||
                'z-30 hidden shrink-0 bg-white shadow-xl lg:block'
            )}
          >
            <div className={classnames('sticky', stickyTopOffsetClassName)}>
              {sidebar}
            </div>
          </div>
        )}
        {aside && (
          <div
            className={classnames(
              asideClassName || [
                !isLarge && 'fixed bottom-0 right-0 top-14 overflow-auto',
                asideBaseClassName,
              ]
            )}
          >
            <div
              className={classnames(
                'lg:sticky',
                isLarge && stickyTopOffsetClassName
              )}
            >
              {aside}
            </div>
          </div>
        )}
        <div className="h-full w-full">{main}</div>
      </div>
      {footer || null}
    </div>
  );
}
