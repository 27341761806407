import { A11y, Autoplay, Keyboard } from 'swiper';
import classnames from 'classnames';

import { Carousel, carouselBaseConfig } from 'components/Carousel';
import { ChevronLeft, ChevronRight } from 'components/icons';
import HeroContentItem from './HeroContentItem';

const classes = [
  'w-10',
  'disabled:hidden grid justify-center',
  'cursor-pointer smd-focus-visible-primary text-smd-gray',
];

const chevronClasses = 'w-6 h-6';

function HeroCarousel({
  slides = [],
  className,
  carouselProps,
  slideComponent: SlideComponent = HeroContentItem,
  ...props
}) {
  return (
    <div className={classnames('relative', className)}>
      <Carousel
        carouselProps={{
          ...carouselBaseConfig,
          modules: [Autoplay, Keyboard, A11y],
          id: 'hero',
          autoplay: {
            delay: 5000,
          },
          rewind: true,
          spaceBetween: 30,
          ...carouselProps,
        }}
        slides={slides.map((slide) => (
          <Carousel.Slide key={slide?.key}>
            <SlideComponent content={slide} {...props} />
          </Carousel.Slide>
        ))}
      >
        {slides.length > 1 && (
          <div className="flex items-center justify-center pb-4">
            <Carousel.BasePrev className={classes} tabIndex={-1}>
              <ChevronLeft className={chevronClasses} />
            </Carousel.BasePrev>
            <Carousel.Pagination className="shrink-0" />
            <Carousel.BaseNext className={classes} tabIndex={-1}>
              <ChevronRight className={chevronClasses} />
            </Carousel.BaseNext>
          </div>
        )}
      </Carousel>
    </div>
  );
}

export default HeroCarousel;
