import { useTranslation } from 'react-i18next';
import { path } from 'ramda';
import { useDefaultToRandomId } from 'hooks';
import { ErrorLabel } from '..';
import { Radio } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

const selectSexBase = [
  'flex flex-col space-y-2',
  'sm:flex-row sm:space-y-0 sm:space-x-8',
  'py-1',
];

function FormSelectSex(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    getValues,
    setValue,
    setError,
    watch,
    resetField,
    ...rest
  } = props;

  const { t } = useTranslation();
  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const male = useDefaultToRandomId();
  const female = useDefaultToRandomId();
  const other = useDefaultToRandomId();

  const { ref: sexInputRef, ...sexInputProps } = register(name, rules);

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={selectSexBase.concat(className).join(' ')}>
        <label className="flex items-center" htmlFor={male}>
          <Radio
            ref={sexInputRef}
            {...sexInputProps}
            value="male"
            id={male}
            className="mr-1"
            aria-label={t('labels.profile.select-sex', {
              sex: t('account-settings.manage-profile.sex.male'),
            })}
            {...rest}
          />
          {t('account-settings.manage-profile.sex.male')}
        </label>
        <label className="flex items-center" htmlFor={female}>
          <Radio
            ref={sexInputRef}
            {...sexInputProps}
            value="female"
            id={female}
            className="mr-1"
            aria-label={t('labels.profile.select-sex', {
              sex: t('account-settings.manage-profile.sex.female'),
            })}
            {...rest}
          />
          {t('account-settings.manage-profile.sex.female')}
        </label>
        <label className="flex items-center" htmlFor={other}>
          <Radio
            ref={sexInputRef}
            {...sexInputProps}
            value="other"
            id={other}
            className="mr-1"
            aria-label={t('labels.profile.select-sex', {
              sex: t('account-settings.manage-profile.sex.other'),
            })}
            {...rest}
          />
          {t('account-settings.manage-profile.sex.other')}
        </label>
      </div>
      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormSelectSex);

export default FormSelectSex;
