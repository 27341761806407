export const PHR_TYPE = Object.freeze({
  EMR: 'EMR',
  WEARABLE: 'WEARABLE',
  MANUAL: 'MANUAL',
});

export const LABEL_TYPE_CLASSES = {
  WEARABLE: 'bg-smd-sources-wearable text-white',
  MANUAL: 'bg-smd-sources-manual text-white',
  EMR: 'bg-smd-sources-emr text-white',
  LAB_REPORT: 'bg-smd-sources-emr text-white',
};

export const SEVERITY_VALUES = {
  MILD: 'mild',
  MODERATE: 'moderate',
  SEVERE: 'severe',
};

export const SEVERITY_COLOR_VALUES = {
  [SEVERITY_VALUES.MILD]: 'bg-amber-300',
  [SEVERITY_VALUES.MODERATE]: 'bg-orange-400',
  [SEVERITY_VALUES.SEVERE]: 'bg-red-600',
};

export const PHR_DATA_TYPE = Object.freeze({
  ALLERGY: 'ALLERGY',
  VACCINE: 'VACCINE',
  CONDITION: 'CONDITION',
  PROCEDURE: 'PROCEDURE',
  OBSERVATION: 'OBSERVATION',
  OBSERVATION_PANEL: 'OBSERVATION_PANEL',
  PANEL: 'PANEL',
  MEASUREMENT: 'MEASUREMENT',
  MEDICATION: 'MEDICATION',
});

export const PHR_ENTRY_TYPE = Object.freeze({
  ...PHR_DATA_TYPE,
  OBSERVATION_PANEL: 'OBSERVATION_PANEL',
  NOTE: 'NOTE',
});

export const CHART_TYPES = Object.freeze({
  LINE: 'LINE',
  BAR: 'BAR',
  PIE: 'PIE',
});

export const DATE_PRESETS = {
  DATE_ONLY_DOTS: 'MM.dd.yyyy',
  DATE_ONLY: 'MM/dd/Y',
  DATE_TIME: 'MM/dd/Y, h:mmaaa',
  DATE_SHORT_MONTH: 'dd MMM uuuu',
  DATE_LONG_MONTH: 'MMMM dd, Y',
  DATE_SHORT_MONTH_TIME: 'dd MMM uuuu, h:mmaaa',
  LOCAL_SHORT_DATE: 'P',
  TIMESTAMP: 'yyyy-MM-dd',
  TIME: 'h:mmaaa',
};

export const RESULT_STATUS = {
  NORMAL: 'NORMAL',
  BORDERLINE: 'BORDERLINE',
  ABNORMAL: 'ABNORMAL',
  NEGATIVE: 'NEGATIVE',
  POSITIVE: 'POSITIVE',
  INDETERMINATE: 'INDETERMINATE',
};

export const DASHBOARD_PHR_CARD_VIEW_MODE = Object.freeze({
  CHART: 'CHART',
  VALUE: 'VALUE',
});

export const DASHBOARD_PHR_CARD_TYPE = Object.freeze({
  MEASUREMENT: 'MEASUREMENT',
  LIST: 'LIST',
  PANEL: 'PANEL',
});

export const DASHBOARD_PHR_ACCEPT_DND_CARD_TYPE = 'CARD';

export const SYNC_STATUSES = {
  CONNECTED: 'CONNECTED',
  SYNCED: 'SYNCED',
  SYNCING: 'SYNCING',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
  DISCONNECTED: 'DISCONNECTED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'ACTIVE',
  ON_HOLD: 'ON_HOLD',
  NOT_PRESENT: 'NOT_PRESENT',
  CLOSED: 'CLOSED',
};

const prefix = '/health';

export const PHR_ROUTES = Object.freeze({
  ROOT: prefix,
  ACTIVATE: `${prefix}/activate`,
  SOURCES: `${prefix}/sources`,
  SETUP_PROFILE: `${prefix}/setup/profile`,
  SETUP_PAYMENT_METHOD: `${prefix}/setup/payment-method`,
  SETUP_PAYMENT: `${prefix}/setup/payment`,
  SETUP_REDEEM: `${prefix}/setup/redeem-code`,
  SETUP_REDEEM_SUCCESS: `${prefix}/setup/success-redeem-code`,
  SETUP_SOURCES: `${prefix}/setup/sources`,
  DASHBOARD: `${prefix}/dashboard`,
  TIMELINE: `${prefix}/timeline`,
  SHARING: `${prefix}/sharing`,
  SHARING_VIEW_LINK: `${prefix}/sharing/view/:id`,
  DETAILS_ID: `${prefix}/details/:type/:id`,
  DETAILS: `${prefix}/details/:type`,
  AUTH: `${prefix}/auth/:type/:status?`,
  SHARED_DASHBOARD: `${prefix}/sharing/view/:shareId/dashboard`,
  SHARED_DETAILS: `${prefix}/sharing/view/:shareId/details/:type/:id`,
});

export const STORYBLOCK_ENDPOINT = '/library/autocomplete/storyblock';

export const phrApiEndpointMap = {
  [PHR_ENTRY_TYPE.NOTE]: 'notes',
  [PHR_ENTRY_TYPE.ALLERGY]: 'allergies',
  [PHR_ENTRY_TYPE.CONDITION]: 'conditions',
  [PHR_ENTRY_TYPE.MEDICATION]: 'medications',
  [PHR_ENTRY_TYPE.OBSERVATION]: 'observations',
  [PHR_ENTRY_TYPE.OBSERVATION_PANEL]: 'observation-panels',
  [PHR_ENTRY_TYPE.MEASUREMENT]: 'observations',
  [PHR_ENTRY_TYPE.PROCEDURE]: 'procedures',
  [PHR_ENTRY_TYPE.VACCINE]: 'vaccines',
};

export const ACTIONS = {
  ADD_TO_HIGHLIGHTS: 'my-phr.dashboard.add-to-highlights',
  ADD_VALUE: 'my-phr.dashboard.add-value',
  MOVE: 'my-phr.dashboard.move',
  REMOVE: 'common.remove',
  REMOVE_FROM_HIGHLIGHTS: 'my-phr.dashboard.remove-from-highlights',
};

export const DEFAULT_STORYBLOCK_SUFFIX = '_definition';

export const DEFAULT_RANGE_STEP = 0.01;
