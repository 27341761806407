import { paymentApi } from './axios';

export default {
  getSubscriptionsPlans() {
    return paymentApi
      .get(`/payments/subscription-plans`)
      .then(({ data }) => data);
  },

  createCustomer() {
    return paymentApi.post(`/payments/customer`);
  },

  createSubscription(data) {
    return paymentApi.post(`payments/subscriptions`, data);
  },

  redeemSubscriptionCode(accessCode) {
    return paymentApi.post(`payments/redeem`, { accessCode });
  },

  requestSubscriptionCode(email) {
    return paymentApi
      .post(`payments/code-request`, {
        email,
      })
      .then(({ data }) => data);
  },

  deleteSubscription(id) {
    return paymentApi.delete(`payments/subscriptions/${id}`);
  },

  getSubscriptions() {
    return paymentApi.get(`payments/subscriptions`).then(({ data }) => data);
  },

  updateSubscriptionStatus(data) {
    return paymentApi.put(`payments/subscription-status`, data);
  },

  getSubscriptionStatus() {
    return paymentApi
      .get(`payments/subscription-status`)
      .then(({ data }) => data);
  },
};
