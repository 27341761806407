import { useRef } from 'react';
import { removeWhiteSpacesBetweenWords } from 'utils';

const FOCUSED_TAG_CLASS = ['!bg-smd-accent', '!text-white'];

function getTagById(tagRefs, value) {
  if (Boolean(tagRefs.current.length === 0)) return;
  const el = tagRefs.current?.find((type) => type?.id === value);
  return el;
}

function addFocusClassesToTag(tagsRefs, value) {
  const duplicateTag = getTagById(tagsRefs, value);
  duplicateTag.classList.add(...FOCUSED_TAG_CLASS);
}

function removeFocusClassesFromAllTags(tagsRefs) {
  for (const el of tagsRefs.current) {
    if (el) {
      el.classList.remove(...FOCUSED_TAG_CLASS);
    }
  }
}

function useTagsController(getValues, setValue, name) {
  const inputRef = useRef();
  const tagsRefs = useRef([]);

  const tagAlreadyExists = (name, value) => {
    if (getValues(name).includes(value)) {
      addFocusClassesToTag(tagsRefs, value);
      return true;
    }
  };

  const addToRefs = (el) => {
    if (el && !tagsRefs.current.includes(el)) {
      tagsRefs.current.push(el);
    }
  };

  const addTag = (onChange) => {
    removeFocusClassesFromAllTags(tagsRefs);
    const value = removeWhiteSpacesBetweenWords(inputRef.current.value);

    if (tagAlreadyExists(name, value)) return;

    if (value.length > 0) {
      onChange([...getValues(name), value]);
      inputRef.current.value = '';
      inputRef.current?.focus();
    }
  };

  const addExternalValue = (value, onChange) => {
    removeFocusClassesFromAllTags(tagsRefs);

    if (tagAlreadyExists(name, value)) return;

    if (value.length > 0) {
      onChange([...getValues(name), value]);
    }
  };

  const deleteTag = (tag) =>
    setValue(name, [
      ...getValues(name).filter(
        (x) => x !== removeWhiteSpacesBetweenWords(tag)
      ),
    ]);

  return { inputRef, tagRef: addToRefs, addTag, deleteTag, addExternalValue };
}

export default useTagsController;
