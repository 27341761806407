import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAssetUrl } from 'utils';
import { useTextOverflowDetector } from 'hooks';
import { useState } from 'react';
import classNames from 'classnames';
import { CaptionButton } from 'components';
import { BACKGROUND, EXPAND_DIRECTION } from 'components/CaptionButton';
import {
  BlurredLayer,
  blurredStyle,
  hasSensitiveTags,
} from 'components/BlurredLayer';

function GlossaryCardFooter({ credit, topic, onClick, name }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-smd-sm text-smd-gray-dark">{credit?.name}</div>
      {topic && (
        <Link
          to={topic?.url}
          className="smd-focus-visible-primary block rounded-sm text-smd-base text-smd-accent"
          onClick={onClick}
        >
          {t('library.search.glossary.learn-more', {
            title: name,
          })}
        </Link>
      )}
    </>
  );
}

function GlossaryCard({ content, onClick }) {
  const { t } = useTranslation();
  const { name, description, credit, linkages, thumbnail, featuredAsset } =
    content;
  const topic = linkages?.topics?.[0];

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descriptionRef, isOverflowing] = useTextOverflowDetector(
    4,
    description
  );

  const imageSrc = thumbnail || featuredAsset?.thumbnail || topic?.thumbnail;

  const isSensitive = hasSensitiveTags(topic?.featuredAsset || featuredAsset);

  const footerProps = {
    name,
    credit,
    topic,
    onClick,
  };

  return (
    <article className="mb-12 flex min-h-40 w-full flex-col justify-between space-y-4 rounded-sm bg-smd-accent-card p-4 text-smd-main antialiased shadow-smd-glossary duration-100 hover:scale-1025 sm:flex-row sm:space-x-4 md:p-6">
      <div className="space-y-2">
        <h3 className="text-smd-xs font-semibold uppercase text-smd-secondary">
          {t('library.search.glossary.label')}
        </h3>
        {name && (
          <h2 className="line-clamp-2 text-smd-base font-bold md:text-smd-h4">
            {name}
          </h2>
        )}
        <div
          className={classNames(
            isOverflowing && !showFullDescription && 'line-clamp-3',
            'prose max-w-none text-smd-sm md:text-smd-base'
          )}
          ref={descriptionRef}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {isOverflowing && showFullDescription && (
          <GlossaryCardFooter {...footerProps} />
        )}
        {isOverflowing && (
          <CaptionButton
            direction={EXPAND_DIRECTION.DOWN}
            background={BACKGROUND.LIGHT}
            inline
            expanded={showFullDescription}
            onClick={(e) => {
              e.preventDefault();
              setShowFullDescription((value) => !value);
            }}
          />
        )}
        {!isOverflowing && <GlossaryCardFooter {...footerProps} />}
      </div>

      {(thumbnail || featuredAsset || topic?.thumbnail) && (
        <div className="relative">
          <BlurredLayer
            isSensitive={isSensitive}
            className="md:h-[152px] md:w-[216px]"
          >
            {({ isBlurred }) => (
              <img
                src={getAssetUrl(imageSrc)}
                alt={name}
                style={isBlurred ? blurredStyle : {}}
                className="h-full w-full object-cover"
              />
            )}
          </BlurredLayer>
        </div>
      )}
    </article>
  );
}

export default GlossaryCard;
