import { useMutation, useQuery, useQueryClient } from 'react-query';
import { JournalsApi } from 'api';

const keys = {
  all: ['guides'],
};

export function useCompletedGuides(options = {}) {
  const { data = {}, ...rest } = useQuery(
    keys.all,
    JournalsApi.getCompletedGuides,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      select: (data) => JSON.parse(data?.completedGuides),
      ...options,
    }
  );
  return { data, ...rest };
}

export function useUpdateGuides() {
  const queryClient = useQueryClient();
  return useMutation((guides) => JournalsApi.updateGuides(guides), {
    mutationKey: keys.all,
    onSuccess: () => {
      queryClient.invalidateQueries(keys.all);
    },
  });
}
