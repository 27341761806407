import { communitiesApi as api } from './axios';

export default {
  getMyCommunities(params) {
    return api
      .get(`categories/my`, { params: params })
      .then(({ data }) => data);
  },

  getOtherCommunities(params) {
    return api.get(`categories`, { params: params }).then(({ data }) => data);
  },

  getCommunityTopics(cid, params) {
    return api
      .get(`categories/communities/${cid}/topics`, { params: params })
      .then(({ data }) => data);
  },

  getCommunity(id) {
    return api.get(`categories/communities/${id}`).then(({ data }) => data);
  },

  getCommunityByHJID(healthJournalID) {
    return api.get(`categories/${healthJournalID}`).then(({ data }) => data);
  },

  hasCommunityByHJKey(journalKey) {
    return api.get(`categories/exists/${journalKey}`).then(({ data }) => data);
  },

  createConversationFromHJ({ journalKey, containerKey, payload }) {
    return api
      .post(`topics/${journalKey}/${containerKey}`, payload)
      .then(({ data }) => data);
  },

  createConversation(communityId, payload) {
    return api.post(`topics/${communityId}`, payload).then(({ data }) => data);
  },

  updatePost(id, payload) {
    return api.put(`posts/${id}`, payload);
  },

  createReply({ conversationId, payload }) {
    return api.post(`posts/topic/${conversationId}`, payload);
  },

  getConversationByJHContainerKey(journalKey, containerKey) {
    return api
      .get(`topics/${journalKey}/${containerKey}`)
      .then(({ data }) => data);
  },

  hasConversationByJHContainerKey(journalKey, containerKey) {
    return api
      .get(`topics/exists/${journalKey}/${containerKey}`)
      .then(({ data }) => data);
  },

  getConversation(conversationId) {
    return api
      .get(`topics/communities/${conversationId}`)
      .then(({ data }) => data);
  },
  getConversationPosts(conversationId, params) {
    return api
      .get(`topics/${conversationId}/posts`, { params: params })
      .then(({ data }) => data);
  },
  upVotePost(postId) {
    return this.votePost(postId, 1);
  },

  downVotePost(postId) {
    return this.votePost(postId, 0);
  },

  followCommunity(communityId) {
    return api.put(`categories/${communityId}/follow`);
  },
  unfollowCommunity(communityId) {
    return api.delete(`categories/${communityId}/follow`);
  },
  votePost(postId, voteDelta) {
    return api
      .post(`posts/${postId}/vote`, {
        voteDelta: voteDelta,
      })
      .then(({ data }) => data);
  },

  deleteConversation(conversationId) {
    return api.delete(`topics/${conversationId}`);
  },

  deletePost(postId) {
    return api.delete(`posts/${postId}`);
  },

  uploadFile(file) {
    const formData = new FormData();
    if (file instanceof Blob) formData.append('files', file);
    return api
      .post('/posts/upload', formData, {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },

  reportPost(payload) {
    return api.post(`posts/flag`, payload);
  },

  getRelatedConversations(conversationId, { page, limit = 6 }) {
    return api
      .get(`/topics/${conversationId}/related`, {
        params: {
          limit,
          page,
        },
      })
      .then(({ data }) => data);
  },
  HTTP_NOT_FOUND: 404,
};
