import { useQueryClient } from 'react-query';
import { any, includes, pathOr, isEmpty, toLower } from 'ramda';
import { collectionKeys } from 'hooks';

export default function useCollectionFiltering(searchTerm, collections = []) {
  const queryClient = useQueryClient();
  const term = toLower(searchTerm?.trim?.());

  if (isEmpty(term) || isEmpty(collections)) {
    return collections;
  }

  const collectionsWithJournals = collections?.map?.(({ id, name }) => {
    const journals = queryClient.getQueriesData(
      collectionKeys.collectionJournalsById(id)
    );
    const healthJournalsNames = pathOr(
      [],
      [0, 1, 'pages', 0, 'content']
    )(journals).map((journal) => journal?.name);
    return {
      id,
      name,
      healthJournalsNames,
    };
  });

  return collections?.reduce?.((result, collection) => {
    const collectionNameMatches =
      Boolean(collection?.name?.trim?.().length) &&
      includes(term, toLower(collection.name));
    const targetCollection = collectionsWithJournals.find(
      (col) => col.id === collection.id
    );
    const hjNameMatchFound = any((name) => includes(term, toLower(name)))(
      targetCollection?.healthJournalsNames
    );

    if (collectionNameMatches || hjNameMatchFound) {
      result.push({
        ...collection,
        collectionNameMatch: collectionNameMatches,
      });
    }

    return result;
  }, []);
}
