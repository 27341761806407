import { useMemo } from 'react';

import {
  useCollectionsByJournal,
  useJournalAllocation,
  useThrottle,
} from 'hooks';
import { LoadingOverlay } from 'components';
import JournalCollectionsForm from './JournalCollectionsForm';
import { CollectionItem } from './JournalCollectionsForm';

function JournalCollectionsAllocator({
  journalKey,
  collections: availableCollections,
}) {
  const { collections: allocatedCollections, isFetching } =
    useCollectionsByJournal(journalKey);

  const {
    addJournalToCollection,
    removeJournalFromCollection,
    isLoading,
    loadingCollections,
  } = useJournalAllocation(journalKey);

  const add = useThrottle(addJournalToCollection);
  const remove = useThrottle(removeJournalFromCollection);

  const collections = useMemo(() => {
    if (!allocatedCollections) {
      return availableCollections;
    }
    return availableCollections.map((available) => {
      return {
        ...available,
        checked: allocatedCollections.some(
          (allocated) => allocated.id === available.id
        ),
      };
    });
  }, [allocatedCollections, availableCollections]);

  return (
    <LoadingOverlay isLoading={isFetching}>
      <JournalCollectionsForm
        collections={collections}
        onChange={({ id: collectionId, value }) =>
          value
            ? add({ collectionId, journalKey })
            : remove({ collectionId, journalKey })
        }
      >
        {(collection, onChange) => {
          const loading = isLoading && loadingCollections[collection.id];
          return <CollectionItem {...{ collection, loading, onChange }} />;
        }}
      </JournalCollectionsForm>
    </LoadingOverlay>
  );
}

export default JournalCollectionsAllocator;
