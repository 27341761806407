import { useTranslation } from 'react-i18next';
import { Trash } from 'components/icons';

function Delete() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-2">
      <Trash className="h-6 w-6 stroke-2" />
      <span>{t('common.delete')}</span>
    </div>
  );
}

export default Delete;
