/**
 * This code is from the "image-focus" package (https://github.com/third774/image-focus/blob/master/src/FocusedImage.ts#L96).
 * Our first implementation of "FocalPointImage" component was using the "image-focus" library,
 * but we had to implement a custom version to gain more control of the Image Element.
 */

import { allPass, compose, defaultTo, gte, is, lte, where, __ } from 'ramda';

function calculateShift(ratio, containerSize, imageSize, focusSize, toMinus) {
  const containerCenter = Math.floor(containerSize / 2); // Container center in px
  const focusFactor = (focusSize + 1) / 2; // Focus point of resize image in px
  const scaledImage = Math.floor(imageSize / ratio); // Can't use width() as images may be display:none
  let focus = Math.floor(focusFactor * scaledImage);
  if (toMinus) focus = scaledImage - focus;
  let focusOffset = focus - containerCenter; // Calculate difference between focus point and center
  const remainder = scaledImage - focus; // Reduce offset if necessary so image remains filled
  const containerRemainder = containerSize - containerCenter;
  if (remainder < containerRemainder)
    focusOffset -= containerRemainder - remainder;
  if (focusOffset < 0) focusOffset = 0;

  return (focusOffset * -100) / containerSize;
}

export function percentsToCoodinates(value, isYAxis) {
  const max = 1;
  const min = -1;
  const result = (value * (max - min)) / 100 + min;
  if (isYAxis) {
    return -result;
  }
  return result;
}

export function computeFocalPointStyle(
  x,
  y,
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight
) {
  let style = {};
  let hShift = 0;
  let vShift = 0;

  if (
    !(
      containerWidth > 0 &&
      containerHeight > 0 &&
      imageWidth > 0 &&
      imageHeight > 0
    )
  ) {
    return; //Need dimensions to proceed
  }

  let wR = imageWidth / containerWidth;
  let hR = imageHeight / containerHeight;

  if (imageWidth > containerWidth && imageHeight > containerHeight) {
    if (wR > hR) {
      style.maxHeight = '100%';
    } else {
      style.maxWidth = '100%';
    }
  }

  if (wR > hR) {
    hShift = calculateShift(
      hR,
      containerWidth,
      imageWidth,
      percentsToCoodinates(x)
    );
  } else if (wR < hR) {
    vShift = calculateShift(
      wR,
      containerHeight,
      imageHeight,
      percentsToCoodinates(y, true),
      true
    );
  }

  style.top = `${vShift.toFixed(2)}%`;
  style.left = `${hShift.toFixed(2)}%`;

  return style;
}

const isInFocalPointRange = allPass([is(Number), gte(__, 0), lte(__, 100)]);

export const isValidFocalPoint = compose(
  where({
    x: isInFocalPointRange,
    y: isInFocalPointRange,
  }),
  defaultTo({})
);
