import { JournalsPublicApi } from 'api';
import { useAuth } from 'Auth';
import { LibraryPublicApiProvider } from 'library';
import { EmbedProvider } from 'hooks/useIsEmbedded';

import { viewerPath } from '../const';
import { RouteJournalViewer } from '.';

const mapEmbeddedError = (err) =>
  Promise.reject({ ...err, redirectUrl: `/${viewerPath.EMBED404}` });

const defaultJournalApi = {
  getJournalByKey: (...args) =>
    JournalsPublicApi.getDefinitionByKey(...args).catch(mapEmbeddedError),
  getJournalTocByKey: (...args) =>
    JournalsPublicApi.getDefinitionTocByKey(...args).catch(mapEmbeddedError),
  getJournalContainerByKey: JournalsPublicApi.getDefinitionContainerByKey,
  markJournalContainerAsVisited: () => Promise.resolve(),
};

function EmbedJournalsViewer() {
  const { authenticated } = useAuth();
  return (
    <div className="relative z-0 h-full w-full">
      <EmbedProvider>
        <LibraryPublicApiProvider>
          <RouteJournalViewer
            journalApi={defaultJournalApi}
            collectionAllocation={authenticated}
          />
        </LibraryPublicApiProvider>
      </EmbedProvider>
    </div>
  );
}

export default EmbedJournalsViewer;
