export default {
  minScale: 0.5,
  maxScale: 5,
  initialScale: 0.5,
  disablePadding: true,
  wheel: {
    disabled: true,
  },
  pinch: {
    disabled: true,
  },
  doubleClick: {
    disabled: true,
  },
};
