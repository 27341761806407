import { useTranslation } from 'react-i18next';
import { Form } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

export function ReactionsInput(props) {
  const { t } = useTranslation();
  const newProps = useManualInputDefaultProps(props);
  return (
    <Form.TagsStack
      buttonAriaLabel={t('my-phr.manual-inputs.placeholders.reactions')}
      {...newProps}
    />
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.REACTIONS, ReactionsInput);
