import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { includes, isEmpty, startsWith } from 'ramda';
import FilePicker from 'components/FilePicker';
import FormController from 'components/forms/Form/FormController';

import { SelectedLibraryAttachments } from 'components';
import { Label } from 'components/forms';
import { Document, Trash } from 'components/icons';

import {
  getAttachmentExtension,
  getAttachmentFileNameWithoutExtension,
} from 'my-phr/components/PHRCard/utils';

import { registerFormElement } from 'components/forms/Form';
import { useLibraryContentByIds } from 'library/hooks';
import { useManualInputsDispatchContext } from '../../ManualInputsContext';

function isImage(attachment) {
  return startsWith('image', attachment?.type);
}

function DocumentPreview({ id, extension, name, onRemove }) {
  return (
    <div className="group relative flex h-28 w-40 items-center justify-center md:h-32 md:w-52">
      <button
        type="button"
        onClick={() => onRemove(id)}
        className="absolute right-1 top-1 z-10 hidden rounded-full bg-smd-error p-2 text-white filter-none group-hover:block"
      >
        <Trash className="h-5 w-5" />
      </button>
      <div className="border-smd-light flex h-28 w-full flex-col items-center justify-center space-y-2 truncate border px-4 text-smd-sm md:h-32">
        <Document className="h-12 w-12 text-smd-error" />
        <div className="flex w-full justify-center">
          <span className="truncate">{name}</span>
          <span>.{extension}</span>
        </div>
      </div>
    </div>
  );
}

function ImagePreview({ id, src, onRemove }) {
  return (
    <div className="group relative flex h-28 w-40 items-center justify-center bg-smd-gray-lightest md:h-32 md:w-52">
      <button
        type="button"
        onClick={() => onRemove(id)}
        className="absolute right-1 top-1 z-10 hidden rounded-full bg-smd-error p-2 text-white filter-none group-hover:block"
      >
        <Trash className="h-5 w-5" />
      </button>
      <img src={src} alt="" className="h-full object-contain" />
    </div>
  );
}

function passesStoryBlockValidations(rules, files) {
  const validationFns = {
    allowedMimeTypes: (input, validationValue) =>
      input.find((file) => !includes(file.type, validationValue)),
    maxFiles: (input, validationValue) => input?.length > validationValue,
    maxFileSize: (input, validationValue) => {
      return input.find(
        (file) => file.size > Number(validationValue * 1024 * 1024)
      );
    },
  };

  if (!rules || isEmpty(rules)) return;

  const failedValidation = Object.keys(rules)?.find((key) => {
    const callback = validationFns[key];
    const rule = rules[key];
    return callback?.(files, rule?.value) ? rule.message : undefined;
  });

  return failedValidation ? rules[failedValidation]?.message : undefined;
}

function FormAttachments({
  libraryAssets,
  onRemoveAsset,
  inEditMode = false,
  ...props
}) {
  const [attachments, setAttachments] = useState([]);
  const { rules, setError, clearErrors, ...rest } = props;

  const { t } = useTranslation();
  function handlePickerChanges(value) {
    const files = Array.from(value)
      .map((newFile) => {
        newFile.id = nanoid();
        return newFile;
      })
      .concat(attachments);

    const error = passesStoryBlockValidations(rules, files);

    if (!error) {
      clearErrors(rest?.name);
      setAttachments(files);
    } else {
      setError(rest?.name, { message: error, type: 'manual' });
    }
  }

  useEffect(() => {
    props?.setValue?.(props.name, { attachments, libraryAssets });
  }, [attachments, libraryAssets, props]);

  const previews = attachments?.map((file) => {
    const isAnImage = isImage(file);
    return {
      name: file?.name,
      type: file.type,
      id: file?.id,
      src: isAnImage ? URL.createObjectURL(file) : null,
    };
  });

  function removeSelection(id) {
    setAttachments((f) => f.filter((x) => x.id !== id));
  }

  const { handleLibraryPickButtonClick, handleSelectedElements } =
    useManualInputsDispatchContext();

  const existingLibraryAttachments = props?.defaultValues?.[props?.name];

  useLibraryContentByIds(existingLibraryAttachments, {
    onSuccess: (data) => {
      handleSelectedElements(
        Object.values(data)?.map?.((entry) => ({
          ...entry,
          title: entry?.name,
        }))
      );
    },
  });

  return (
    <FormController
      {...props}
      render={() => (
        <>
          {props.label ? (
            <Label htmlFor={props.id}>{props.label}</Label>
          ) : (
            <></>
          )}
          <div className="mb-4 flex gap-x-3 gap-y-1 md:flex-wrap">
            {previews.map((attachment) => {
              const Preview = isImage(attachment)
                ? ImagePreview
                : DocumentPreview;
              const name = getAttachmentFileNameWithoutExtension(
                attachment?.name
              );
              const extension = getAttachmentExtension(attachment?.name);
              return (
                <Preview
                  key={attachment?.id}
                  name={name}
                  id={attachment.id}
                  extension={extension}
                  src={attachment.src}
                  onRemove={removeSelection}
                />
              );
            })}
          </div>
          {libraryAssets && libraryAssets.length > 0 && (
            <SelectedLibraryAttachments
              attachments={libraryAssets}
              onRemoveAsset={onRemoveAsset}
            />
          )}
          <FilePicker
            onLibraryPickButtonClick={handleLibraryPickButtonClick}
            multiple
            hasFilePicker={!inEditMode}
            customLabel={
              inEditMode
                ? t('common.import-from')
                : t('communities.add-files-short')
            }
            accept={props?.rules?.allowedMimeTypes?.value?.join(',')}
            onFilesSelected={handlePickerChanges}
          />
        </>
      )}
    />
  );
}

registerFormElement(FormAttachments);

export default FormAttachments;
