import { useEffect, useState } from 'react';
import { getImageFileAsUrl } from '../components/forms/utils';

export default function useFileImageAsUrl(file) {
  const [src, setSrc] = useState();

  function clearState() {
    setSrc(null);
  }

  useEffect(() => {
    if (file) {
      getImageFileAsUrl(file).then(setSrc).catch(clearState);
    } else {
      clearState();
    }
  }, [file]);
  return src;
}
