import { useEffect } from 'react';

function useOnEsc(onKeyDown) {
  useEffect(() => {
    const handler = ({ keyCode }) => {
      if (keyCode === 27) {
        onKeyDown?.();
      }
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [onKeyDown]);
}

export default useOnEsc;
