import { useMemo } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { downloadFile } from 'utils';

import { AdminApi, JournalsApi } from '../../api';

export const journalRequestsConfig = {
  enabled: true,
  onError: (err) => console.log(err?.message),
};

export const journalKeys = {
  all: () => ['journals'],
  paginated: () => [...journalKeys.all(), 'paginated'],
  paginatedConfig: (page, size, searchTerm) => [
    ...journalKeys.paginated(),
    { page, size, searchTerm },
  ],
  user: () => [...journalKeys.all(), 'user'],
  recent: () => [...journalKeys.all(), 'recent'],
  grouped: () => [...journalKeys.all(), 'grouped'],
  groupedType: (type) => [...journalKeys.grouped(), type],
  statistics: () => [...journalKeys.all(), 'visitationStats'],
  definition: (id) => [...journalKeys.all(), id],
  definitionLink: (linkId) => [...journalKeys.all(), 'definitionLink', linkId],
};

const GROUPED_JOURNALS_QUERY_SIZE = 5;

export function useUserJournalInvalidator() {
  const queryClient = useQueryClient();
  return {
    invalidateUserJournals: () =>
      queryClient.invalidateQueries(journalKeys.user()),
  };
}

export function useJournalsInvalidator() {
  const queryClient = useQueryClient();
  return {
    invalidateJournals: () => queryClient.invalidateQueries(journalKeys.all()),
  };
}

export function useRecentJournalsInvalidator() {
  const queryClient = useQueryClient();
  return {
    invalidateRecentJournals: () =>
      queryClient.invalidateQueries(journalKeys.recent()),
  };
}

export function useGroupedJournalsInvalidator() {
  const queryClient = useQueryClient();
  return {
    invalidateGroupedJournals: () =>
      queryClient.invalidateQueries(journalKeys.grouped()),
  };
}

export function useUserJournals(config = {}) {
  const queryClient = useQueryClient();

  const { invalidateUserJournals } = useUserJournalInvalidator();
  const { data: userJournals, ...rest } = useQuery({
    queryKey: journalKeys.user(),
    queryFn: async () => JournalsApi.getAllJournals(),
    ...journalRequestsConfig,
    ...config,
  });

  const { mutateAsync: deleteJournalInstance, isLoading: isLoadingDelete } =
    useMutation(async (id) => JournalsApi.deleteJournalById(id));

  const onSuccessDelete = (id) => {
    queryClient.invalidateQueries('journalByKey', id);
    queryClient.invalidateQueries('journalTocByKey', id);
    invalidateUserJournals();
    queryClient.invalidateQueries('recentJournals');
    queryClient.invalidateQueries('groupedJournals');
  };

  const onErrorDelete = (err) => console.log(err?.message);

  const deleteJournal = async (id) => {
    await deleteJournalInstance(id).then(
      () => onSuccessDelete(id),
      onErrorDelete
    );
  };

  return {
    userJournals,
    ...rest,
    invalidate: invalidateUserJournals,
    deleteJournal,
    isLoadingDelete,
  };
}

export function useRecentJournals(api = JournalsApi) {
  const { invalidateRecentJournals } = useRecentJournalsInvalidator();
  const { data: { data: recentJournalsData = [] } = {}, ...rest } = useQuery({
    queryKey: journalKeys.recent(),
    queryFn: async () => api.getRecentDefinitions(),
    ...journalRequestsConfig,
  });

  return {
    recentJournalsData,
    ...rest,
    invalidate: invalidateRecentJournals,
  };
}

export function useGroupedJournals(api = JournalsApi, type = 'hj') {
  const { invalidateGroupedJournals } = useGroupedJournalsInvalidator();

  const { data, isFetched, hasNextPage, ...rest } = useInfiniteQuery(
    journalKeys.groupedType(type),
    async ({ pageParam }) => {
      const queryParams = {
        size: GROUPED_JOURNALS_QUERY_SIZE,
        page: pageParam,
      };
      return api.getAllJournalsGroupedByCategory(queryParams);
    },
    {
      getNextPageParam: (lastPage) => {
        const { last, totalPages, number } = lastPage;
        const nextPageNumber = number + 1;

        if (!last && totalPages > nextPageNumber) {
          return nextPageNumber;
        }
        return undefined;
      },
      ...journalRequestsConfig,
    }
  );

  const groupedJournalsData = useMemo(() => {
    if (data) {
      return data?.pages?.reduce(
        (prev, curr) => [...prev, ...curr?.content],
        []
      );
    }
    return [];
  }, [data]);

  const hasNoGroupedJournals = isFetched && data?.pages?.length === 0;
  const hasNoMoreGroupedJournals =
    isFetched && !hasNextPage && data?.pages?.length > 1;

  return {
    groupedJournalsData,
    ...rest,
    isFetched,
    hasNextPage,
    hasNoGroupedJournals,
    hasNoMoreGroupedJournals,
    invalidate: invalidateGroupedJournals,
  };
}

export function useJournals(params, api = AdminApi) {
  const { data, isFetching } = useQuery(
    journalKeys.paginatedConfig(params?.page, params?.size, params?.searchTerm),
    async () => {
      const queryParams = {
        size: params?.size,
        page: params?.page,
        searchTerm: params?.searchTerm,
      };
      return api.getAllJournals(queryParams);
    },
    {
      keepPreviousData: true,
      ...journalRequestsConfig,
    }
  );

  return {
    journals: data,
    isFetching,
  };
}

export function useJournalDefinition(id, onError) {
  const {
    data: journalDefinition,
    refetch: refetchJournalDefinition,
    ...rest
  } = useQuery(
    journalKeys.definition(id),
    async () => AdminApi.getJournalDefinition(id),
    {
      ...journalRequestsConfig,
      onError: onError ? onError : journalRequestsConfig.onError,
      enabled: !!id,
    }
  );

  return {
    journalDefinition,
    refetchJournalDefinition,
    ...rest,
  };
}

export function usePocStatisticsCsv() {
  const { refetch: downloadStatistics, isLoading: isLoadingDownload } =
    useQuery(journalKeys.statistics(), AdminApi.getPocVisitStatisticsCsv, {
      enabled: false,
      onSuccess: (res) => {
        downloadFile(res.toString(), 'audit.csv', downloadFile.MIME_TYPES.CSV);
      },
    });

  return {
    downloadStatistics,
    isLoadingDownload,
  };
}

export function useDeployJournal(params) {
  const queryClient = useQueryClient();

  const { mutate: deployJournal, isLoading: isLoadingDeploy } = useMutation(
    async ({ id, status }) => AdminApi.changeDeployStatus(id, status),
    {
      onSuccess: (res) => {
        const queryKey = journalKeys.paginatedConfig(
          params?.page,
          params?.size,
          params?.searchTerm
        );
        const currentState = queryClient.getQueryData(queryKey);

        if (currentState) {
          const updatedContent = currentState.content.map((x) => {
            if (x.journalKey === res.journalKey) {
              return { ...x, active: res.active };
            }
            return x;
          });

          queryClient.setQueryData(queryKey, (prev) => ({
            ...prev,
            content: updatedContent,
          }));
        }
      },
      onError: (err) => console.log(err?.message),
    }
  );
  return { deployJournal, isLoadingDeploy };
}

export function useDeleteJournal(onSuccess) {
  const { invalidateJournals } = useJournalsInvalidator();
  const { invalidateUserJournals } = useUserJournalInvalidator();
  const { invalidateRecentJournals } = useRecentJournalsInvalidator();
  const { invalidateGroupedJournals } = useGroupedJournalsInvalidator();

  const { mutate: deleteJournal, isLoading: isLoadingDelete } = useMutation(
    async (id) => AdminApi.deleteJournal(id),
    {
      onSuccess: () => {
        invalidateUserJournals();
        invalidateJournals();
        invalidateRecentJournals();
        invalidateGroupedJournals();
        onSuccess?.();
      },
      onError: (err) => console.log(err?.message),
    }
  );
  return { deleteJournal, isLoadingDelete };
}

export function useBulkDeleteJournals(onSuccess) {
  const { invalidateJournals } = useJournalsInvalidator();
  const { invalidateUserJournals } = useUserJournalInvalidator();
  const { invalidateRecentJournals } = useRecentJournalsInvalidator();
  const { invalidateGroupedJournals } = useGroupedJournalsInvalidator();

  const { mutate: bulkDeleteJournals, isLoading: isLoadingBulkDelete } =
    useMutation(async (ids) => AdminApi.bulkDeleteJournals(ids), {
      onSuccess: () => {
        invalidateUserJournals();
        invalidateJournals();
        invalidateRecentJournals();
        invalidateGroupedJournals();
        onSuccess?.();
      },
      onError: (err) => console.log(err?.message),
    });
  return { bulkDeleteJournals, isLoadingBulkDelete };
}

export function useGenerateReferralCode(params) {
  const queryClient = useQueryClient();

  const { mutate: generateReferralCode, isLoading: isLoadingGenerate } =
    useMutation(async (id) => AdminApi.generateReferralCode(id), {
      onSuccess: (res) => {
        const queryKey = journalKeys.paginatedConfig(
          params?.page,
          params?.size,
          params?.searchTerm
        );

        const currentState = queryClient.getQueryData(queryKey);

        if (currentState) {
          const updatedContent = currentState.content.map((x) => {
            if (x.journalKey === res.journalKey) {
              return { ...x, referralCode: res.referralCode };
            }
            return x;
          });

          queryClient.setQueryData(queryKey, (prev) => ({
            ...prev,
            content: updatedContent,
          }));
        }
      },
      onError: (err) => console.log(err?.message),
    });
  return { generateReferralCode, isLoadingGenerate };
}

export function useGetJournalUniqueId(journalKey, config) {
  const { data, isLoading } = useQuery(
    journalKeys.definitionLink(journalKey),
    () => JournalsApi.getDefinitionUniqueLink(journalKey),
    {
      ...journalRequestsConfig,
      enabled: Boolean(journalKey),
      ...config,
    }
  );

  return {
    data,
    isLoading,
  };
}

export function useGenerateJournalUniqueId() {
  const { mutateAsync, isLoading } = useMutation(
    JournalsApi.generateDefinitionUniqueLink
  );

  return {
    mutateAsync,
    isLoading,
  };
}
