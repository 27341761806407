import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from 'components';
import { Info } from 'components/icons';
import { useDefaultCollectionName } from '../hooks';

function CollectionWrapper({ children, collection = {} }) {
  const { name, defaultCollection } = collection;

  const { t } = useTranslation();

  const defaultTranslation = useDefaultCollectionName();

  return (
    <section>
      <h1 className="flex items-center text-smd-h3 font-bold">
        {name ?? defaultTranslation}
        {defaultCollection && (
          <div className="-mb-1 ml-2 flex select-none items-center space-x-1 text-smd-xs text-smd-gray-dark">
            <div className="hidden lg:block">
              {t('journals.collections.default')}
            </div>
            <Tooltip content={t('hints.default-collection')}>
              {(props, { isVisible }) => (
                <Info
                  className={classNames(
                    'h-4 w-4 shrink-0 cursor-help stroke-2',
                    isVisible ? 'text-smd-accent' : 'text-smd-gray-dark'
                  )}
                  {...props}
                />
              )}
            </Tooltip>
          </div>
        )}
      </h1>
      <div className="pt-4 pb-10">{children}</div>
    </section>
  );
}

export default CollectionWrapper;
