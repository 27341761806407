import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withCdnResources from 'library/withCdnResources';
import {
  Image as ImageIcon,
  Play,
  Document,
  Interactive,
  Healthjournal,
  BookOpen,
  Medication,
  Microscope,
  Trash,
} from 'components/icons';
import { Image } from 'components/media';

const wrapperStyle =
  'absolute bottom-0 flex h-8 w-full items-center pl-2 text-black';

function TypeIcon({ type }) {
  const styles = 'w-6 h-6 p-1.5 text-smd-accent';
  switch (type) {
    case 'image':
      return <ImageIcon className={styles} />;
    case 'video':
    case 'youtube':
    case 'vimeo':
      return <Play className={styles} />;
    case 'document':
      return <Document className={styles} />;
    case 'interactive':
      return <Interactive className={styles} />;
    case 'healthtopic':
      return <Healthjournal className={styles} fill="white" />;
    case 'event':
      return <BookOpen className={styles} />;
    case 'drug':
      return <Medication className={styles} />;
    case 'biomarker':
      return <Microscope className={styles} />;
    default:
      return null;
  }
}

function MediaAsset({ thumbnail, assetType }) {
  return (
    <>
      <Image src={thumbnail} className="max-h-full max-w-full object-contain" />
      <div className={wrapperStyle}>
        <div className="shrink-0 rounded-full bg-white">
          <TypeIcon type={assetType} />
        </div>
      </div>
    </>
  );
}

function ArticleAsset({ thumbnail, assetType, title }) {
  return (
    <>
      <Image src={thumbnail} className="max-h-full max-w-full object-contain" />
      <div
        className={classnames(wrapperStyle, 'space-x-1 bg-white bg-opacity-80')}
      >
        <div className="shrink-0 rounded-full bg-white">
          <TypeIcon type={assetType} />
        </div>
        <span className="truncate text-smd-xs">{title}</span>
      </div>
    </>
  );
}

function SelectedLibraryAttachmentCard({
  id,
  thumbnail,
  assetType,
  title,
  onRemoveAsset,
}) {
  const { t } = useTranslation();

  const isMedia = assetType === 'video' || assetType === 'image';

  const AssetComponent = isMedia ? MediaAsset : ArticleAsset;

  return (
    <div className="group relative m-2 flex h-28 w-48 shrink-0 content-center justify-center">
      <AssetComponent {...{ thumbnail, assetType, title }} />
      <button
        className="absolute top-2 right-4 hidden group-hover:block"
        aria-label={t('common.delete')}
        onClick={() => onRemoveAsset(id)}
      >
        <div className="rounded-full bg-smd-error-dark p-1">
          <Trash className="text-white" />
        </div>
      </button>
    </div>
  );
}

SelectedLibraryAttachmentCard.propTypes = {
  id: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  onRemoveAsset: PropTypes.func.isRequired,
};

export default withCdnResources(['thumbnail'], SelectedLibraryAttachmentCard);
