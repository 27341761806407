import { CommunitiesApi } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { callAll } from 'utils';
import { communitiesKeys } from '../keys';
import { buildConversationPayload } from '../utils';

export function useHJConversationCreate(options) {
  const { mutate: createConversationByHJID, ...rest } = useMutation(
    async ({ journalKey, containerKey, values }) => {
      const payload = buildConversationPayload(values);
      return CommunitiesApi.createConversationFromHJ({
        journalKey,
        containerKey,
        payload,
      });
    },
    options
  );
  return {
    createConversationByHJID,
    ...rest,
  };
}

export function useConversationCreate({ onSuccess, ...options }) {
  const queryClient = useQueryClient();
  const { mutate: createConversation, ...rest } = useMutation(
    async ({ communityId, values }) => {
      const payload = buildConversationPayload(values);
      return CommunitiesApi.createConversation(communityId, payload);
    },
    {
      onSuccess: callAll(
        () => queryClient.resetQueries(communitiesKeys.my()),
        () => queryClient.resetQueries(communitiesKeys.other()),
        onSuccess
      ),
      ...options,
    }
  );
  return {
    createConversation,
    ...rest,
  };
}

export function useConversationReport(options) {
  const { mutate: reportConversation, ...rest } = useMutation(
    async (payload) => CommunitiesApi.reportPost(payload),
    options
  );
  return { reportConversation, ...rest };
}

export function useConversationUpdate(communityId, conversationId, options) {
  const queryClient = useQueryClient();
  const { mutate: updateConversation, ...rest } = useMutation(
    async ({ postId, values }) =>
      CommunitiesApi.updatePost(postId, buildConversationPayload(values)),
    {
      ...options,

      onSettled: () => {
        const keys = communitiesKeys.conversationById(
          communityId,
          conversationId
        );
        queryClient.invalidateQueries(keys);
      },
    }
  );
  return {
    updateConversation,
    ...rest,
  };
}

export function useConversationDelete(communityId, { onSuccess, ...options }) {
  const queryClient = useQueryClient();
  const { mutate: deleteConversation } = useMutation(
    async (id) => CommunitiesApi.deleteConversation(id),
    {
      onSuccess: callAll(
        () =>
          queryClient.invalidateQueries(
            communitiesKeys.conversationsByCommunityId(communityId)
          ),
        onSuccess
      ),
      ...options,
    }
  );
  return {
    deleteConversation,
  };
}
