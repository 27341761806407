import { useState } from 'react';
import { getAssetUrl } from 'utils';
import { useMedia } from 'hooks';
import { Form, SelectTag } from 'components/forms';
import { getColorScheme } from 'components/range/utils';
import { useManualInputDefaultProps } from '../hooks';

function SelectIconInput({ options, ...props }) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8 w-3/4',
  });

  const isSmall = useMedia(useMedia.SMALL);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (event) => {
    const value = event.currentTarget.querySelector(
      'input[type="hidden"]'
    ).value;
    setSelectedOption(value);
    newProps.setValue(`${props.prefix}.${props.name}`, value);
  };

  const colorScheme = getColorScheme(newProps?.ranges?.items);

  return (
    <Form.Select as={SelectTag} {...newProps}>
      {options.map(({ value, rangeValue, icon, label, disabled }, index) => {
        const optionValue =
          rangeValue !== undefined || rangeValue !== null
            ? JSON.stringify({ value, rangeValue })
            : value;
        const areEqualValueAndLabel =
          typeof value === 'string' &&
          typeof label === 'string' &&
          value.toLowerCase() === label.toLowerCase();

        return (
          <div
            key={`${index}${value}`}
            onClick={handleSelect}
            value={value}
            {...disabled}
            className="flex w-10 flex-col items-center space-y-2 rounded-[4px] border-2 border-transparent pb-1 text-center text-smd-xs sm:h-full sm:w-24 sm:pt-2"
            style={{
              border:
                optionValue === selectedOption &&
                `2px solid ${colorScheme[index]}`,
            }}
          >
            <input type="hidden" value={optionValue} />
            <div className="h-10">
              <img src={getAssetUrl(icon)} alt={label} />
            </div>
            {isSmall && (
              <span
                style={{
                  color:
                    optionValue === selectedOption
                      ? colorScheme[index]
                      : '#A7A5A5',
                }}
              >
                {!areEqualValueAndLabel && <span>{`${value} - `}</span>}
                {`${label.toUpperCase()}`}
              </span>
            )}
          </div>
        );
      })}
    </Form.Select>
  );
}

export default SelectIconInput;
