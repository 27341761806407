import { Form } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

export function TextAreaInput(props) {
  const newProps = useManualInputDefaultProps({
    groupClassName: '[&_textarea]:min-h-[7.5rem]',
    ...props,
  });
  return <Form.TextArea {...newProps} />;
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.TEXTAREA, TextAreaInput);
