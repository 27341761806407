import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import Form from 'components/forms/Form';
import { namesWithSpacesAndSpecialChars, validEmailRegEx } from 'utils';

function NewsletterForm({ onSubmit, className, inputClassName, children }) {
  const { t } = useTranslation();

  const location = useLocation();
  const url = window.location.href;

  const utmHiddenFields = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const result = [];
    for (let [key, value] of params) {
      if (key.includes('utm')) {
        result.push(
          <Form.Input type="hidden" label="" name={key} value={value} />
        );
      }
    }
    return result;
  }, [location.search]);

  // Use random id for checkbox, because there might be 2 checkboxes with the same name/id at the same time on screen
  // 1: Newsletter Modal subscription
  // 2: Newsletter Section in Landing page
  const randomId = nanoid();

  return (
    <Form className={className} onSubmit={onSubmit} resetFormOnSuccess>
      <Form.Input
        groupClassName="mb-6"
        className={inputClassName}
        label=""
        placeholder={t('contact.label', {
          value: t('common.full-name'),
        })}
        name="name"
        rules={{
          required: t('errors.required'),
          pattern: {
            value: namesWithSpacesAndSpecialChars,
            message: t('errors.pattern', {
              field: t('common.full-name'),
            }),
          },
        }}
        aria-label={t('contact.enter', {
          value: t('common.full-name'),
        })}
      />
      <Form.Input
        groupClassName="mb-6"
        className={inputClassName}
        label=""
        placeholder={t('contact.label', {
          value: t('common.email-address'),
        })}
        name="email"
        type="email"
        rules={{
          required: t('errors.required'),
          pattern: {
            value: validEmailRegEx,
            message: t('errors.pattern', { field: t('common.email') }),
          },
        }}
        aria-label={t('contact.enter', {
          value: t('common.email-address'),
        })}
      />
      <Form.Checkbox
        groupClassName="mb-6"
        label={t('landing.newsletter-doctor-coach')}
        name="is_doctor"
        id={randomId}
        aria-label={t('labels.common.doctor-checkbox')}
      />
      <Form.Input type="hidden" label="" name="url" value={url} />
      {utmHiddenFields}
      {children}
    </Form>
  );
}

export default NewsletterForm;
