import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { ExpandableSection } from 'components';
import libraryArticlePropTypes from 'library/propTypes/article';
import LibraryAsset from 'journals/blocks/output/HtmlBlock/LibraryAsset';

function LibraryArticle({ withLinks, article }) {
  const { t } = useTranslation();
  const { name, featuredAsset, text, credit } = article;
  return (
    <article className="space-y-smd-base text-smd-main">
      {name && (
        <h1
          className="text-smd-h2 font-semibold"
          dangerouslySetInnerHTML={{ __html: name }}
        />
      )}
      {featuredAsset && <LibraryAsset config={featuredAsset} />}
      {text && (
        <div
          className={classnames(
            'prose max-w-none text-smd-main prose-a:inline-block',
            {
              'prose-no-anchors': !withLinks,
            }
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {credit && (
        <p className="text-smd-sm italic text-smd-gray">
          {t('common.source', { source: credit.name })}
        </p>
      )}
    </article>
  );
}

LibraryArticle.propTypes = {
  article: libraryArticlePropTypes.shape.isRequired,
};

export default LibraryArticle;

export function LibraryArticleWithSubarticles({
  article,
  subarticles,
  subarticleId,
}) {
  const [open, setOpen] = useState({});

  const scrollToRef = useRef();
  const refs = useRef({});
  const handleRef = useCallback((id, node) => (refs.current[id] = node), []);

  useEffect(() => {
    if (subarticleId) {
      setOpen((open) => ({ ...open, [subarticleId]: true }));
      refs.current[subarticleId]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [subarticles, subarticleId]);

  return (
    <>
      <div ref={scrollToRef} />
      <LibraryArticle article={article} />
      {subarticles?.map((subArticle) => {
        const subArticleId = subArticle?.id;
        const articleLinkages = article?.linkages?.subarticles;
        const subArticleLabel = articleLinkages?.find(
          ({ id }) => subArticleId === id
        )?.label;

        return (
          <div key={subArticle.id} className="mt-smd-xl">
            <div ref={(node) => handleRef(subArticle.id, node)} />
            <ExpandableSection
              title={subArticleLabel}
              open={open[subArticle.id]}
              onClick={() =>
                setOpen((open) => ({
                  ...open,
                  [subArticle.id]: !open[subArticle.id],
                }))
              }
            >
              <div className="ml-smd-xl">
                <LibraryArticle article={subArticle} />
              </div>
            </ExpandableSection>
          </div>
        );
      })}
    </>
  );
}

LibraryArticleWithSubarticles.propTypes = {
  article: libraryArticlePropTypes.shape.isRequired,
  // subarticles:
};
