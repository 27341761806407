import classnames from 'classnames';

import { useMedia } from 'hooks';
import { Aspect, CTA, Video } from 'components';
import { getAssetUrl } from 'utils';

const featureGroupClass =
  'flex flex-wrap md:flex-nowrap items-start space-y-4 md:space-y-0 mb-8 md:mb-0 py-smd-lg md:py-smd-3xl';

function FeatureEntry({ feature, isEven }) {
  const isMedium = useMedia(useMedia.MEDIUM);

  const type = feature?.type;

  return (
    <>
      <div className={classnames(featureGroupClass)}>
        <div
          className={classnames(
            'group relative order-2 flex max-h-96 w-full justify-center md:max-h-full md:w-1/2',
            isEven ? 'md:mr-8' : 'md:ml-8',
            {
              'md:order-1': isEven,
            }
          )}
          style={{
            height: isMedium ? '28rem' : 'auto',
          }}
        >
          <img
            src={
              type === 'video'
                ? getAssetUrl(feature?.thumbnail)
                : getAssetUrl(feature?.key)
            }
            alt={type === 'video' ? feature?.thumbnail : feature?.key}
            className="object-contain drop-shadow-2xl filter"
          />
          {type === 'video' && (
            <>
              <div className="absolute left-1/2 top-1/2 h-fit w-full -translate-x-1/2 -translate-y-1/2 transform">
                <Aspect.Fixed ratio={16 / 9} className="overflow-hidden">
                  <Video
                    {...{
                      thumbnail: getAssetUrl(feature?.thumbnail),
                      url: getAssetUrl(feature?.key),
                      controls: true,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Aspect.Fixed>
              </div>
            </>
          )}
        </div>
        <div
          className={classnames(
            'order-1 w-full py-smd-lg md:w-1/2 md:py-smd-3xl',
            {
              'md:order-2': isEven,
            }
          )}
        >
          <h2 className="relative mb-4 text-center text-smd-h4 font-semibold md:text-left md:text-smd-h3">
            {feature?.title}
          </h2>
          <p
            className="prose mx-auto max-w-130 text-center text-smd-sm md:mx-0 md:max-w-none md:text-left md:text-smd-h4"
            dangerouslySetInnerHTML={{ __html: feature?.text }}
          />
          {feature?.ctaText && (
            <div className="mt-smd-xl mb-smd-xl text-center md:mb-0 md:text-left">
              <CTA color="primary" ctaProps={feature?.ctaProps}>
                {feature?.ctaText}
              </CTA>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FeatureEntry;
