import { useState } from 'react';

import { useAuth } from 'Auth';
import { getJournalSlugPath } from 'journals/utils';
import { AddToCollectionModal, SmartJournalCard } from 'journals/components';
import { GridView } from 'journals/history/components';
import { AddToCollection } from 'journals/components/actions';

function LibraryJournalModule({ journals, gridRef }) {
  const { authenticated } = useAuth();

  const [actionJournal, setActionJournal] = useState();

  return (
    <>
      <GridView
        items={journals}
        ref={gridRef}
        childComponent={(entry) => (
          <div key={entry.id} className="smd-transform-duration">
            <SmartJournalCard
              actions={[
                {
                  onClick: () => setActionJournal(entry),
                  element: <AddToCollection />,
                },
              ]}
              journal={entry}
              to={getJournalSlugPath(entry)}
            />
          </div>
        )}
      />
      {authenticated && (
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      )}
    </>
  );
}

export default LibraryJournalModule;
