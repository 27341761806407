export const communitiesKeys = {
  my: (searchTerm = '') => [`my-communities${searchTerm.trim()}`],
  other: (searchTerm = '') => [`other-communities${searchTerm.trim()}`],

  healthJournalCommunity: (journalKey) => ['hj-community', journalKey],
  healthJournalConversation: (containerKey) => ['hj-container', containerKey],

  healthJournalCommunityExists: (journalKey) => [
    ...communitiesKeys.healthJournalCommunity(journalKey),
    'exists',
  ],

  healthJournalContainerConversationExists: (journalKey, containerKey) => [
    ...communitiesKeys.healthJournalCommunity(journalKey),
    ...communitiesKeys.healthJournalConversation(containerKey),
    'exists',
  ],

  healthJournalContainerConversation: (journalKey, containerKey) => [
    ...communitiesKeys.healthJournalCommunity(journalKey),
    ...communitiesKeys.healthJournalConversation(containerKey),
  ],

  community: () => ['community'],
  communityById: (communityId) => [...communitiesKeys.community(), communityId],
  conversationsByCommunityId: (communityId) => [
    ...communitiesKeys.communityById(communityId),
    'conversations',
  ],
  conversationById: (communityId, conversationId) => [
    ...communitiesKeys.conversationsByCommunityId(communityId),
    conversationId,
  ],
  conversationSummaryById: (communityId, conversationId) => [
    ...communitiesKeys.conversationById(communityId, conversationId),
    'summary',
  ],
  postsByConversationId: (communityId, conversationId) => [
    ...communitiesKeys.conversationById(communityId, conversationId),
    'posts',
  ],
};
