import { ContentContainer, CTA, Aspect, Video } from 'components';
import { getAssetUrl } from 'utils';

function PrivatePageGuestView({
  title,
  text,
  buttonText,
  image,
  video,
  testId,
}) {
  return (
    <section className="grow px-smd-base pt-smd-lg pb-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
      <ContentContainer className="flex h-full">
        <div className="flex flex-grow flex-col lg:flex-row lg:items-center lg:space-x-12">
          <div className="py-8 text-center lg:text-left">
            <h1 className="pb-8 text-smd-h2.5 font-semibold lg:text-smd-h1">
              {title}
            </h1>
            <p className="pb-8 text-smd-h4">{text}</p>
            <CTA
              color="primary"
              ctaProps={{ ctaAction: 'login' }}
              data-testid={testId}
            >
              {buttonText}
            </CTA>
          </div>
          <div className="relative w-full shrink-0 lg:w-1/2 xl:w-2/5">
            {video ? (
              <Aspect.Fixed ratio={16 / 9} className="overflow-hidden">
                <Video
                  {...{
                    thumbnail: getAssetUrl(video?.thumbnail),
                    url: getAssetUrl(video?.key),
                    controls: true,
                    className: 'border',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Aspect.Fixed>
            ) : (
              <img
                alt="test"
                src={image}
                className="w-full object-cover object-center"
              />
            )}
          </div>
        </div>
      </ContentContainer>
    </section>
  );
}

export default PrivatePageGuestView;
