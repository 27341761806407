import { useState } from 'react';
import { useMutation } from 'react-query';
import PHR_API from 'api/phr';

export function useSimulatedPaginationWithDelay(perPage = 20, delay = 500) {
  const [state, setState] = useState({
    isFetching: false,
    itemsToRender: perPage,
  });

  const fetchMore = () => {
    if (state.isFetching) return;
    setState((state) => ({ ...state, isFetching: true }));
    setTimeout(() => {
      setState((state) => ({
        isFetching: false,
        itemsToRender: state.itemsToRender + perPage,
      }));
    }, delay);
  };

  const reset = () =>
    setState({
      isFetching: false,
      itemsToRender: perPage,
    });

  return {
    reset,
    fetchMore,
    ...state,
  };
}

export function useDisconnectTerraSource(options) {
  const { mutate, isLoading } = useMutation(
    PHR_API.disconnectTerraSource,
    options
  );
  return {
    disconnectTerraSource: mutate,
    isDisconnecting: isLoading,
  };
}

export function useUpdateTerraSource(options) {
  const { mutate, isLoading } = useMutation(PHR_API.updateSource, options);
  return {
    updateTerraSource: mutate,
    isUpdatingTerraSource: isLoading,
  };
}

export function useDisconnectEMRSource(options) {
  const { mutate, isLoading } = useMutation(
    PHR_API.disconnectEMRSource,
    options
  );
  return {
    disconnectEMRSource: mutate,
    isDisconnecting: isLoading,
  };
}
