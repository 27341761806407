import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { ExternalLink } from 'components/icons';

function getFormattedDate(date, language) {
  const normalizedDate = date instanceof Date ? date : new Date(date);
  return Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(normalizedDate);
}

function AccessedDate({ date }) {
  const { t, i18n } = useTranslation();

  const dateLabel = useMemo(() => {
    if (isNil(date)) return null;

    return t('journals.blocks.html.library-card.accessed-date', {
      date: getFormattedDate(date, i18n.language),
    });
  }, [date, t, i18n]);

  return dateLabel && `[${dateLabel}]`;
}

function Reference({ name, url, date }) {
  return (
    <li className="my-3 text-sm italic leading-loose  text-smd-gray-dark lg:my-0 lg:text-smd-base lg:leading-relaxed">
      <a href={url} target="_blank" rel="noopener noreferrer" data-reference>
        {name || url}
        {date && (
          <>
            &nbsp;
            <AccessedDate date={date} />
          </>
        )}
        {url && (
          <>
            &nbsp;
            <ExternalLink className="mr-1 inline-block h-[1em] text-smd-gray-dark" />
          </>
        )}
      </a>
    </li>
  );
}

function LibraryReferences({ references }) {
  return (
    <ol className="list-inside list-decimal break-words px-6">
      {references?.map((reference, index) => {
        return <Reference {...reference} key={index} />;
      })}
    </ol>
  );
}

export default LibraryReferences;
