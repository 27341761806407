import { ContentContainer } from 'components';
import HeroCarousel from './components/HeroCarousel';

function HeroSection({ slides, ...props }) {
  return (
    <section>
      <ContentContainer className="px-4">
        {slides.length > 0 && <HeroCarousel slides={slides} {...props} />}
      </ContentContainer>
    </section>
  );
}

export default HeroSection;
