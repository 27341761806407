export default Object.freeze({
  PHR: {
    ENABLED: process.env.REACT_APP_PHR_STATE === 'true',
    EXCLUDE_INVALID_UNITS_DASHBOARD: false,
    EXCLUDE_INVALID_UNITS_TIMELINE: true,
    PHR_EMRS_ENABLED: Boolean(
      process.env.REACT_APP_PHR_EMRS_ENABLED === 'true'
    ),
    PHR_WEARABLES_ENABLED: Boolean(
      process.env.REACT_APP_PHR_WEARABLES_ENABLED === 'true'
    ),
    OCR_ENABLED: process.env.REACT_APP_PHR_OCR_ENABLED === 'true',
  },
  COOKIES_CONSENT: {
    ENABLED: process.env.REACT_APP_ENABLE_COOKIES_CONSENT === 'true',
  },
});
