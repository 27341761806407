import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Unblur } from 'components/icons';
import { SettingsContext } from 'SettingsContext';

export const blurredStyle = { filter: 'blur(18px)' };

export function hasSensitiveTags(resource) {
  return Boolean(resource?.tags?.['safe-search']?.length);
}

export default function BlurredLayer({
  children,
  className,
  isSensitive = false,
  alignmentClassNames = 'items-center justify-center',
  style = {},
  onUnblur,
  withDescription = true,
}) {
  const { t } = useTranslation();
  const settingsContext = useContext(SettingsContext);
  const sensitiveContent = settingsContext?.sensitiveContent;
  const [isBlurred, setIsBlurred] = useState(isSensitive);
  return (
    <>
      <div className={classnames('h-full w-full overflow-hidden', className)}>
        {sensitiveContent?.shouldBlur && isBlurred && (
          <div
            style={style}
            className={classnames(
              'absolute z-10 flex h-full w-full flex-col bg-black/70 px-4 text-center text-white',
              alignmentClassNames
            )}
          >
            <div className="flex items-center space-x-2">
              <Unblur className="h-4 w-4" />
              <h3 className="text-smd-base font-semibold">
                {t('common.sensitive-content')}
              </h3>
            </div>
            {withDescription && (
              <h4 className="mb-2 text-smd-sm">
                {t('common.sensitive-media')}
              </h4>
            )}
            <button
              className={classnames(
                'rounded border-1 py-1 px-2 text-smd-base hover:font-semibold',
                { 'mt-4': !withDescription }
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                sensitiveContent?.confirmSensiveContentViewing?.(() => {
                  setIsBlurred(false);
                  onUnblur?.();
                });
              }}
            >
              {t('common.view')}
            </button>
          </div>
        )}
        {children({ isBlurred: sensitiveContent?.shouldBlur && isBlurred })}
      </div>
    </>
  );
}
