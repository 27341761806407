import { useStoreLastLocation } from 'hooks';

export default function useTopicLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'topic-navigation-origin',
  });

  return {
    setTopicLastLocation: set,
    pushTopicLastLocation: push,
    popTopicLastLocation: pop,
    topicLastLocation: lastLocation,
  };
}
