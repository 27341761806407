import { useMutation, useQuery } from 'react-query';
import { UserApi } from 'api';

const keys = {
  main: () => ['user-configuration'],
  byKey: (key) => [...keys.main(), key],
};

export const ACCOUNT_CONFIGURATIONS_KEYS = Object.freeze({
  INTRODUCTION_SECTION_KEY: 'introduction',
  INTRODUCTORY_VIDEO_VIEWED_KEY: 'introductory-video-viewed',
});

export function useUserConfiguration(key, options) {
  const { data, ...rest } = useQuery(
    keys.byKey(key),
    async () => UserApi.getUserConfigurationRecord(key).catch(() => {}),
    options
  );
  return {
    data,
    ...rest,
  };
}

export function useUserConfigurationUpdate(options) {
  const { mutate: updateUserConfiguation } = useMutation(
    async (payload) =>
      UserApi.updateUserConfigurationRecord(payload).catch(() => {}),
    options
  );
  return {
    updateUserConfiguation,
  };
}
