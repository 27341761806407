import { Modal } from 'components';
import Glossary from './Glossary';

function LibraryGlossaryModal({ isOpen, onRequestClose, hashId }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div
        className="w-screen lg:max-w-3xl lg:py-8"
        role="dialog"
        aria-modal="true"
      >
        <Glossary hashId={hashId} onClose={onRequestClose} />
      </div>
    </Modal>
  );
}

export default LibraryGlossaryModal;
