import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFocalPosition } from 'utils';
import { PictureResource } from 'components/media';
import { Play } from 'components/icons';
import { AssetsLightBox } from 'journals/components';
import { TVideoAsset } from 'components/Guide';

type TGuideStepVideoProps = {
  videoAsset: TVideoAsset;
};

export function GuideStepVideo({ videoAsset }: TGuideStepVideoProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { thumbnail, alt, name, images, focalPoint } = videoAsset.featuredAsset;
  const asset = videoAsset.featuredAsset;
  const assetsList = [asset];

  const { position, isValid } = getFocalPosition(focalPoint);
  const pictureStyle = {
    objectPosition: isValid ? position : 'center',
    objectFit: isValid ? 'cover' : 'contain',
    width: '100%',
    height: '100%',
  };

  const lightBoxState = {
    toggler: true,
    index: 0,
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button className="relative cursor-pointer" onClick={openModal}>
        <PictureResource
          // @ts-ignore
          config={{
            url: thumbnail,
            alt: alt || name,
            images,
          }}
          style={pictureStyle}
          loading="lazy"
          title={alt || name}
        />

        <button
          className="flex-center smd-focus-visible-secondary absolute inset-1/2 h-16 w-16 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer rounded-full bg-smd-accent"
          aria-label={t('common.play')}
        >
          <Play className="relative -right-0.5 h-4 w-4 text-white" />
        </button>
      </button>
      {isOpen && (
        // @ts-ignore
        <AssetsLightBox
          assets={assetsList}
          lightBoxState={lightBoxState}
          onClose={closeModal}
        />
      )}
    </>
  );
}
