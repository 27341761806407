import { useCallback, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { JournalsApi, JournalsPublicApi } from 'api';
import { getSlugHead, slugifyObject } from 'utils/slug';
import { UrlResolverProvider } from 'UrlResolverContext';
import { useJournalLastLocation } from '../../hooks';
import Viewer from '../Viewer';
import {
  POC_SHARE_ID,
  RELATED_ID,
  RELATED_PATH,
  POC_SHARE_PATH,
  viewerPath,
} from '../const';
import { addGoogleAnalyticsTracking } from '../helpers';
import { useSearchParams } from '@/hooks/useSearchParams';

function RouteJournalViewerContent({ journalApi: api, ...props }) {
  const journalApi = useMemo(() => addGoogleAnalyticsTracking(api), [api]);
  const searchParams = useSearchParams();
  const shouldCloseBrowserTab = Boolean(searchParams.get('close'));

  const history = useHistory();

  const { popJournalLastLocation } = useJournalLastLocation({
    onEvent: ({ method, lastLocation }) => {
      if (method === 'pop') {
        shouldCloseBrowserTab ? window.close() : history.push(lastLocation);
      }
    },
  });

  const { journalKey: journalKeySlug } = useParams();
  const journalKey = getSlugHead(journalKeySlug);

  const { path, url } = useRouteMatch();

  const relatedPath = `${path}/${RELATED_PATH}`;
  const relatedMatch = useRouteMatch(relatedPath);
  const pocSharePath = `${path}/${POC_SHARE_PATH}`;
  const pocShareMatch = useRouteMatch(pocSharePath);

  const containerPath = `${path}/${viewerPath.PAGE}/:containerKey`;
  const containerMatch = useRouteMatch(containerPath);

  const containerKeySlug = relatedMatch?.isExact
    ? RELATED_ID
    : pocShareMatch?.isExact
    ? POC_SHARE_ID
    : containerMatch?.params?.containerKey;
  const containerKey = getSlugHead(containerKeySlug);

  const pageUrl = useCallback(
    (page) => {
      return `${url}/${viewerPath.PAGE}/${
        page?.slug || slugifyObject(page?.key, page?.title || page?.label)
      }`;
    },
    [url]
  );

  const resolvePageUrl = useCallback((page) => pageUrl(page), [pageUrl]);

  const navigateTo = useCallback(
    (path) => {
      if (path !== history.location.pathname) {
        history.push(path);
      }
    },
    [history]
  );

  const handleNavigation = useCallback(
    (container, options) => {
      if (container?.key) {
        navigateTo(pageUrl(container), options);
      } else {
        navigateTo(url, options);
      }
    },
    [url, pageUrl, navigateTo]
  );

  return (
    <UrlResolverProvider resolver={resolvePageUrl}>
      <Viewer
        journalApi={journalApi}
        journalKey={journalKey}
        containerKey={containerKey}
        onNavigate={handleNavigation}
        onClose={() => popJournalLastLocation()}
        collectionAllocation
        {...props}
      />
    </UrlResolverProvider>
  );
}

function setJournalApi(api) {
  return {
    getJournalByKey: api.getDefinitionByKey,
    getJournalTocByKey: api.getDefinitionTocByKey,
    getJournalContainerByKey: api.getDefinitionContainerByKey,
    markJournalContainerAsVisited: () => Promise.resolve(),
  };
}

export default function RouteJournalViewer({ isPublic, journalApi, ...props }) {
  const definedJournalApi = isPublic
    ? setJournalApi(JournalsPublicApi)
    : JournalsApi;

  return (
    <RouteJournalViewerContent
      journalApi={journalApi ?? definedJournalApi}
      {...props}
    />
  );
}
