import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useIsFeatureEnabled } from 'hooks';
import { Button } from 'components/forms';
import { AddEmail } from 'components/icons';
import NewsletterModal from './NewsletterModal';

function NewsletterSubscribeButton({ className, isFeatured = false }) {
  const { t } = useTranslation();

  const [newsLetterModal, setNewsletterModal] = useState(false);
  const showSubscriptionButton = useIsFeatureEnabled(
    useIsFeatureEnabled.NEWSLETTER
  );

  const [isHovered, setIsHovered] = useState(false);

  const style = {
    backgroundColor: isFeatured ? 'transparent' : '#0c6972',
    color: 'white',
  };

  if (isHovered) {
    style.backgroundColor = isFeatured ? '#0c6972' : '#f9fdfd';
    style.color = isFeatured ? 'white' : '#0c6972';
  }

  return (
    <>
      {showSubscriptionButton && (
        <Button.Negative
          className={classnames(
            className,
            'focus-visible:ring-offset-smd-accent-dark'
          )}
          style={style}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setNewsletterModal(true)}
        >
          <div className="mx-auto flex items-center space-x-2">
            <AddEmail className="h-4 w-4 fill-current" />
            <span>{t('common.subscribe')}</span>
          </div>
        </Button.Negative>
      )}
      <NewsletterModal
        isOpen={newsLetterModal}
        onRequestClose={() => {
          setNewsletterModal(false);
        }}
      />
    </>
  );
}

export default NewsletterSubscribeButton;
