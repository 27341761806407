import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { aboutPageUrl } from 'utils';
import useEnabledFeatures from '../useEnabledFeatures';
import ListMenu from '../ListMenu';

function ListMenuItems({ setNewsletterModal }) {
  const { t } = useTranslation();

  const { isMyLibraryEnabled, isNewsletterEnabled } = useEnabledFeatures();

  const menuItemsFocusStyles = 'smd-focus-visible-primary rounded-sm';

  const listMenuItems = (
    <>
      {isMyLibraryEnabled && (
        <li>
          <Link
            to="/journals"
            className={menuItemsFocusStyles}
            data-testid="menuMyLibraryLink"
          >
            {t('main-menu.library')}
          </Link>
        </li>
      )}
      <li>
        <a
          href={aboutPageUrl}
          rel="noopener noreferrer"
          className={menuItemsFocusStyles}
          data-testid="ourStoryHamburgerLink"
        >
          {t('shared-menu.our-story')}
        </a>
      </li>
      {isNewsletterEnabled && (
        <li>
          <button
            className={menuItemsFocusStyles}
            aria-label={t('labels.menu.newsletter')}
            data-testid="newsletterHamburgerLink"
            onClick={() => setNewsletterModal(true)}
          >
            {t('shared-menu.newsletter')}
          </button>
        </li>
      )}
      <li>
        <Link
          to="/help-center"
          className={menuItemsFocusStyles}
          data-testid="helpCenterHamburgerLink"
        >
          {t('common.help-center')}
        </Link>
      </li>
      <li>
        <Link
          to="/contact-us"
          className={menuItemsFocusStyles}
          data-testid="contactUsHamburgerLink"
        >
          {t('common.contact')}
        </Link>
      </li>
    </>
  );
  return (
    <>
      <ul className="grid list-none gap-4 p-4 text-base">{listMenuItems}</ul>
      <ListMenu.Footer />
    </>
  );
}

export default ListMenuItems;
