import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Avatar } from 'components';
import { Settings, SignOut } from 'components/icons';
import { Button } from 'components/forms';
import { useAuth } from 'Auth';
import { useProfile } from 'ProfileContext';
import { usePartner } from 'PartnerContext';
import { getProfileAvatarUrl } from 'utils';

import { MenuLink, menuLinkStyles } from './MenuNavLink';

function UserMenuItem({
  profileAvatarUrl,
  profileName,
  partnerName,
  className,
  onClick,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={classnames('flex w-full items-center px-6 py-2', className, {
        'cursor-pointer hover:bg-smd-gray-lightest': Boolean(onClick),
      })}
      onClick={onClick}
    >
      <Avatar className="mr-4 h-20 w-20" src={profileAvatarUrl} />
      <div>
        <span className="text-black">{profileName}</span>
        <span className="mt-1 block text-smd-xs text-smd-secondary">
          {partnerName || t('user-menu.personal-account')}
        </span>
      </div>
    </div>
  );
}

function UserMenu() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [profile] = useProfile();
  const { currentPartner, setCurrentPartner, removeCurrentPartner } =
    usePartner();
  const profileName = `${profile?.firstName} ${profile?.lastName}`;
  const profileAvatarUrl = profile && getProfileAvatarUrl(profile);
  const filteredPartners = profile?.partners?.filter(
    (partner) => partner.id !== currentPartner?.id
  );

  if (process.env.REACT_APP_PRO_ACCOUNTS_STATE === 'DISABLED') {
    return (
      <div className="flex flex-col items-start space-y-2 p-4" role="menu">
        <div className="ml-2 mb-2 flex items-center">
          <Avatar className="mr-2 h-16 w-16" src={profileAvatarUrl} />
          <span>{profileName}</span>
        </div>
        <MenuLink
          to="/account"
          className="smd-focus-visible-primary rounded-sm p-2 text-smd-accent"
          aria-label={t('labels.profile.settings')}
        >
          {t('user-menu.settings')}
        </MenuLink>
        <hr className="self-stretch" />
        <button
          onClick={logout}
          className={classnames(
            'smd-focus-visible-primary rounded-sm',
            menuLinkStyles
          )}
          role="menuitem"
        >
          {t('user-menu.signout')}
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start py-6" role="menu">
      <UserMenuItem
        profileName={profileName}
        profileAvatarUrl={currentPartner?.lightLogo || profileAvatarUrl}
        partnerName={currentPartner?.name}
        className="mb-5"
      />

      <div className="mb-6 flex w-full justify-between px-6">
        <MenuLink
          to="/account"
          className="flex items-center text-smd-accent"
          aria-label={t('labels.profile.settings')}
        >
          <Settings className="mr-2 h-5 w-5" />
          {t('user-menu.settings')}
        </MenuLink>
        <button
          onClick={logout}
          className={classnames('flex items-center', menuLinkStyles)}
        >
          <SignOut className="mr-2 h-5 w-5" />
          {t('user-menu.signout')}
        </button>
      </div>
      {profile.partners?.length > 0 ? (
        <>
          <hr className="mb-4 h-1 w-full" />
          {currentPartner && (
            <UserMenuItem
              profileName={profileName}
              profileAvatarUrl={profileAvatarUrl}
              onClick={removeCurrentPartner}
            />
          )}
          {filteredPartners.map((partner) => (
            <UserMenuItem
              key={partner.id}
              profileName={profileName}
              profileAvatarUrl={partner.lightLogo}
              partnerName={partner.name}
              onClick={() => setCurrentPartner(partner)}
            />
          ))}
        </>
      ) : (
        <div className="w-full px-6">
          <Link to="/pro-account" className="w-full">
            <Button.Primary className="w-full">
              {t('user-menu.create-pro-account')}
            </Button.Primary>
          </Link>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
