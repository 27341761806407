import { Link } from 'react-router-dom';
import { useHandleArticleLastLocation } from 'hooks';
import { ArticleCard, GlossaryCard } from 'journals/components';
import LibraryList, {
  LibraryListSkeleton,
} from 'library/components/LibraryList';
import { ArticleCardSkeleton } from 'journals/components/ArticleCard';

function LibraryArticleModule({
  articles,
  glossary,
  isLoadingArticles,
  isLoadingGlossary,
}) {
  const { handleOnClick } = useHandleArticleLastLocation();
  const glossaryUrl = glossary?.linkages?.topics[0]?.url;

  return (
    <>
      {isLoadingGlossary ? (
        <ArticleCardSkeleton />
      ) : glossary?.id ? (
        <GlossaryCard
          content={glossary}
          onClick={() => handleOnClick(glossaryUrl)}
        />
      ) : null}
      {isLoadingArticles ? (
        <LibraryListSkeleton.Article />
      ) : (
        <LibraryList
          list={articles}
          renderListItem={(item) => {
            const articleUrl = item?.url;

            return (
              <Link
                className="smd-focus-visible-primary block rounded-sm"
                onClick={() => handleOnClick(articleUrl)}
                aria-label={item.name}
                to={articleUrl}
              >
                <ArticleCard
                  article={item}
                  descriptionClassName="text-smd-base"
                />
              </Link>
            );
          }}
        />
      )}
    </>
  );
}

export default LibraryArticleModule;
