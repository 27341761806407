import PropTypes from 'prop-types';

const shape = PropTypes.objectOf(
  // image-type
  // license-type
  // safe-search
  PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
  })
);

const defaults = {};

export default {
  shape,
  defaults,
};
