import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isNil } from 'ramda';
import { nanoid } from 'nanoid';
import classnames from 'classnames';

import { useAuth } from 'Auth';
import keycloak from 'keycloak';

import { ContentContainer } from 'components';
import { Button } from 'components/forms';
import { ChevronRight } from 'components/icons';
import CommunityCard from 'communities/CommunityCard';

function CommunityOfTheDay({ data, className }) {
  const { authenticated } = useAuth();
  const { t } = useTranslation();

  const randomId = nanoid();

  if (isNil(data)) {
    return null;
  }

  const { communityCard } = data;

  const redirectUri = communityCard?.id
    ? `/communities/${communityCard?.id}`
    : '/communities';

  const login = () =>
    keycloak.login({ redirectUri: `${window.location.origin}${redirectUri}` });

  return (
    <section
      className={classnames('py-10', className)}
      aria-labelledby={randomId}
    >
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <div className="mb-2 flex flex-col items-start lg:flex-row lg:items-center lg:space-x-4">
            <h1 id={randomId} className="text-smd-h1 font-bold">
              {t('landing.community-day')}
            </h1>
            <Link
              to="/communities"
              className="lg:-mb-2"
              aria-label={t('labels.landing.join-share')}
            >
              <Button.Anchor size="none" tabIndex={-1}>
                <span className="font-bold">{t('landing.join-share')}</span>
                <ChevronRight className="h-4 w-4 stroke-2" />
              </Button.Anchor>
            </Link>
          </div>
          <p className="mb-8 text-smd-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
            officia commodi illo ipsam quae, possimus molestias reprehenderit
            recusandae repellendus praesentium!
          </p>

          {authenticated ? (
            <CommunityCard {...communityCard} />
          ) : (
            <button
              onClick={login}
              className="text-left duration-100 md:hover:scale-1025"
              aria-label={t('labels.menu.sign-in')}
            >
              <CommunityCard
                className="pointer-events-none"
                tabIndex={-1}
                {...communityCard}
              />
            </button>
          )}
        </ContentContainer>
      </div>
    </section>
  );
}

export default CommunityOfTheDay;
