import { Link } from 'react-router-dom';

import { useTopicLastLocation } from 'library/hooks';
import { LibraryTopicCard } from 'library/components/topic';
import LibraryList from 'library/components/LibraryList';
import { slugifyObject } from 'utils/slug';

function LibraryTopicModule({ topics }) {
  const { setTopicLastLocation } = useTopicLastLocation();

  return (
    <LibraryList
      list={topics}
      renderListItem={(item) => {
        const topicUrl =
          item.url ??
          `/topic/${encodeURIComponent(slugifyObject(item.id, item.title))}`;
        return (
          <Link
            to={topicUrl}
            onClick={() => setTopicLastLocation()}
            className="smd-focus-visible-primary block rounded-sm"
            aria-label={item.name}
          >
            <LibraryTopicCard topic={item} />
          </Link>
        );
      }}
    />
  );
}

export default LibraryTopicModule;
