import { isNil } from 'ramda';
import classnames from 'classnames';

import { slugifyObject } from 'utils/slug';
import { getAssetUrl } from 'utils';
import { viewerPath } from '../viewer/const';

export function isAbsolute(url) {
  return /^(?:[a-z]+:)?\/\//i.test(url);
}

// TODO: differentiate library assets from external urls
//    or parse and process library objects on the backend and return ui-fiendly model
export function normalizeAssetUrl(url) {
  if (url && !isAbsolute(url)) {
    return getAssetUrl(url);
  }
  return url;
}

export function getJournalSlugPath(journal, container, isEmbedded = false) {
  if (!journal) return;
  const journalSlug = slugifyObject(
    journal.journalKey,
    journal.preamble?.title
  );
  const containerSlug =
    container?.slug ||
    slugifyObject(container?.key, container?.title || container?.label);
  const getPathMethod = isEmbedded ? getJournalEmbeddedPath : getJournalPath;
  return getPathMethod(journalSlug, containerSlug);
}

export function getPOCJournalSlugPath(journalKey, container) {
  if (!journalKey) return;
  const containerSlug = slugifyObject(
    container?.key,
    container?.title || container?.label
  );
  return getPOCJournalPath(journalKey, containerSlug);
}

export function getJournalEmbeddedPath(journalKey, containerKey) {
  return isNil(containerKey)
    ? `/${viewerPath.EMBED}/${journalKey}`
    : `/${viewerPath.EMBED}/${journalKey}/${viewerPath.PAGE}/${containerKey}`;
}

export function getJournalPath(journalKey, containerKey) {
  return isNil(containerKey)
    ? `/${viewerPath.JOURNAL}/${journalKey}`
    : `/${viewerPath.JOURNAL}/${journalKey}/${viewerPath.PAGE}/${containerKey}`;
}

export function getPOCJournalPath(journalKey, containerKey) {
  return isNil(containerKey)
    ? `/share/${viewerPath.JOURNAL}/${journalKey}`
    : `/share/${viewerPath.JOURNAL}/${journalKey}/${viewerPath.PAGE}/${containerKey}`;
}

export function navigateToJournal(history, journalKey, containerKey) {
  history?.push(getJournalPath(journalKey, containerKey));
}

export function flattenNav(root) {
  const iterate = (node, acc) => {
    acc.push(node);
    if (node.children) {
      node.children.forEach((child) => iterate(child, acc));
    }
    return acc;
  };
  return iterate(root, []);
}

export function alternateColorsInTitle(name, evenColor, oddColor) {
  if (!name) {
    return [];
  }

  const words = name?.split(' ');

  return words?.map((word, index) => {
    const style = index % 2 === 0 ? evenColor : oddColor;

    return (
      <span className={classnames(style)} key={index}>
        {word.toUpperCase()}{' '}
      </span>
    );
  });
}
