import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';

import useLibraryAssetRoutedModal from 'library/hooks/useLibraryAssetRoutedModal';
import AssetContainer from '../AssetContainer';
import { InteractiveAsset } from '../LibraryInteractive';

function LibraryInteractive({ config }) {
  const { openLibraryAssetModal } = useLibraryAssetRoutedModal();
  return (
    <ErrorBoundary>
      <AssetContainer
        config={config}
        onExpandClick={() => openLibraryAssetModal(config)}
        showShare
      >
        <InteractiveAsset config={config} />
      </AssetContainer>
    </ErrorBoundary>
  );
}

LibraryInteractive.propTypes = {
  config: PropTypes.any, // TODO:
};

export default LibraryInteractive;
