import React from 'react';
import { isFormElement, registerFormElement } from './utils';

function FormInputWrapper({
  as: Wrapper = 'div',
  className,
  children,
  wrapperStyle,
  ...props
}) {
  return (
    <Wrapper className={className} style={wrapperStyle}>
      {React.Children.map(children, (child) => {
        return isFormElement(child) ? React.cloneElement(child, props) : child;
      })}
    </Wrapper>
  );
}

registerFormElement(FormInputWrapper);

export default FormInputWrapper;
