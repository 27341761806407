import classnames from 'classnames';
import { getProfileAvatarUrl } from 'utils';
import UserMenuToggle from '../UserMenuToggle';
import UserMenu from '../UserMenu';

function MenuUser({ profile, state, dispatch, isScrolled }) {
  const { isUserOpen } = state;

  const profileName = `${profile?.firstName} ${profile?.lastName}`;
  const profileAvatarUrl = profile && getProfileAvatarUrl(profile);

  return (
    <div>
      <UserMenuToggle
        open={isUserOpen}
        profileName={profileName}
        profileAvatarUrl={profileAvatarUrl}
        onClick={() =>
          dispatch({
            type: isUserOpen ? 'close' : 'open',
            menu: 'isUserOpen',
          })
        }
      />
      {isUserOpen && (
        <div
          className={classnames(
            'absolute left-0 right-0 bg-white lg:left-auto lg:right-10 lg:m-1 lg:w-screen lg:max-w-xs lg:border lg:border-smd lg:shadow-md',
            isScrolled ? 'top-12' : 'top-20'
          )}
          style={{ zIndex: 70 }}
        >
          <UserMenu />
        </div>
      )}
    </div>
  );
}

export default MenuUser;
