import classnames from 'classnames';

function ContentContainer({
  children,
  maxWidthClassName = 'max-w-7xl 2xl:max-w-screen-2xl',
  className,
  ...rest
}) {
  return (
    <div
      className={classnames(
        'relative mx-auto w-full',
        maxWidthClassName,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export default ContentContainer;
