import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { Tick, X } from './icons';

function ConfirmationAlert({ type, onClose }) {
  const { t } = useTranslation();
  return (
    <div className="mx-4 mt-4 flex select-none flex-nowrap justify-between bg-smd-accent-light p-2 text-smd-base">
      <div className="flex items-center space-x-4 pl-2">
        <span className="bg-smd flex h-4 w-4 shrink-0 rounded-full bg-smd-accent text-white">
          <Tick className="h-full w-full stroke-2 p-1" />
        </span>
        <span>
          {t('my-phr.manual-inputs.labels.successfully-added', {
            type: capitalizeFirstLetter(type),
          })}
        </span>
      </div>
      <button onClick={onClose}>
        <X className="h-6 w-6" />
      </button>
    </div>
  );
}

export default ConfirmationAlert;
