import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button } from 'components/forms';
import CreateCollectionWizard from './CreateCollectionWizard';

function CollectionsDashboardHeader() {
  const { t } = useTranslation();
  const [createFormVisible, setCreateFormVisible] = useState(false);

  return (
    <div className="py-8">
      <div
        className={classnames(
          'flex flex-wrap items-center space-y-2 lg:flex-nowrap lg:justify-between lg:space-y-0',
          createFormVisible && 'pb-4'
        )}
      >
        <h1 className="w-full text-smd-h3 font-semibold">
          {t('journals.collections.manage-collections')}
        </h1>

        <Button.Tertiary
          onClick={() => setCreateFormVisible(true)}
          className="shrink-0 font-semibold"
          disabled={createFormVisible}
        >
          {t('journals.collections.create')}
        </Button.Tertiary>
      </div>
      <CreateCollectionWizard
        isVisible={createFormVisible}
        onHide={() => setCreateFormVisible(false)}
      />
    </div>
  );
}

export default CollectionsDashboardHeader;
