import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ISharedHealthInfoState {
  name?: string;
  lastUpdated?: Date;
  shareId?: string;
}

interface ISharedHealthInfoDispatchProvider {
  (sharedHealthInfo: ISharedHealthInfoState): void;
}

const SharedHealthInfoStateProvider =
  createContext<ISharedHealthInfoState | null>(null);
const SharedHealthInfoDispatchProvider =
  createContext<ISharedHealthInfoDispatchProvider | null>(null);

export function SharedHealthInfoProvider({ children }) {
  const [name, setName] = useState<ISharedHealthInfoState['name']>('unknown');
  const [lastUpdated, setLastUpdated] =
    useState<ISharedHealthInfoState['lastUpdated']>();
  const [shareId, setShareId] = useState<ISharedHealthInfoState['shareId']>();
  const value = useMemo(
    () => ({ name, lastUpdated, shareId }),
    [name, lastUpdated, shareId]
  );

  const setSharedHealthInfo = useCallback(
    ({ name, lastUpdated, shareId }: ISharedHealthInfoState) => {
      setName(name);
      setLastUpdated(lastUpdated);
      setShareId(shareId);
    },
    []
  );

  return (
    <SharedHealthInfoStateProvider.Provider value={value}>
      <SharedHealthInfoDispatchProvider.Provider value={setSharedHealthInfo}>
        {children}
      </SharedHealthInfoDispatchProvider.Provider>
    </SharedHealthInfoStateProvider.Provider>
  );
}

const stateErrorMessage =
  'useSharedHealthInfoState must be used within a SharedHealthInfoProvider';
export function useSharedHealthInfoState() {
  const context = useContext(SharedHealthInfoStateProvider);
  if (!context) {
    throw new Error(stateErrorMessage);
  }

  return context;
}

const dispatchErrorMessage =
  'useSharedHealthInfoDispatch must be used within a SharedHealthInfoProvider';
export function useSharedHealthInfoDispatch() {
  const context = useContext(SharedHealthInfoDispatchProvider);
  if (!context) {
    throw new Error(dispatchErrorMessage);
  }

  return context;
}
