import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AuthorApi } from 'api';
import { callAll } from 'utils';

export const authorKeys = {
  all: () => ['author'],
  drafts: () => [...authorKeys.all(), 'drafts'],
  draftsById: () => [...authorKeys.drafts(), 'id'],
  draftById: (id) => [...authorKeys.draftsById(), id],
  draftsByKey: () => [...authorKeys.drafts(), 'key'],
  draftByKey: (key) => [...authorKeys.draftsByKey(), key],
  publishes: () => [...authorKeys.all(), 'publishes'],
  publishById: (id) => [...authorKeys.publishes(), id],
};

const defaultQueryOptions = {
  onError: console.log,
};

export function useDrafts() {
  const { data: drafts, ...rest } = useQuery(
    authorKeys.drafts(),
    async () => AuthorApi.getAllDraftsInProgress(),
    {
      ...defaultQueryOptions,
      keepPreviousData: true,
    }
  );

  return { drafts, ...rest };
}

export function useDraftById(id, config) {
  const { data: draft, ...rest } = useQuery(
    authorKeys.draftById(id),
    async () => AuthorApi.getDraftById(id),
    {
      ...defaultQueryOptions,
      ...config,
    }
  );

  return { draft, ...rest };
}

export function useDraftByJournalKey(key, config) {
  const { data: draft, ...rest } = useQuery(
    authorKeys.draftByKey(key),
    async () => AuthorApi.getDraftByJournalKey(key),
    {
      ...defaultQueryOptions,
      ...config,
    }
  );

  return { draft, ...rest };
}

export function useCreateDraft(onSuccess) {
  const queryClient = useQueryClient();

  const {
    mutate: createDraft,
    isLoading: isLoadingCreate,
    ...rest
  } = useMutation(async (draft) => AuthorApi.createDraft(draft), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(authorKeys.drafts()),
      onSuccess
    ),
    ...defaultQueryOptions,
  });

  return { createDraft, isLoadingCreate, ...rest };
}

export function useUpdateDraft() {
  const queryClient = useQueryClient();

  const {
    mutate: updateDraft,
    isLoading: isLoadingUpdate,
    ...rest
  } = useMutation(async ({ id, patch }) => AuthorApi.updateDraft(id, patch), {
    onSuccess: () => queryClient.invalidateQueries(authorKeys.drafts()),
    ...defaultQueryOptions,
  });

  return { updateDraft, isLoadingUpdate, ...rest };
}

export function useDeleteDraft(onSuccess) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteDraft,
    isLoading: isLoadingDelete,
    ...rest
  } = useMutation(async (id) => AuthorApi.deleteDraft(id), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(authorKeys.drafts()),
      onSuccess
    ),
    ...defaultQueryOptions,
  });

  return { deleteDraft, isLoadingDelete, ...rest };
}

export function usePublish(config) {
  const queryClient = useQueryClient();

  const {
    mutate: publish,
    isLoading: isLoadingPublish,
    ...rest
  } = useMutation(async (journal) => AuthorApi.publish(journal), {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(authorKeys.all()),
      config?.onSucces
    ),
    ...defaultQueryOptions,
    ...config,
  });

  return { publish, isLoadingPublish, ...rest };
}

export function useDeletePublishedJournal(onSuccess) {
  const queryClient = useQueryClient();

  const { mutate: deleteJournal, ...rest } = useMutation(
    async (id) => AuthorApi.deletePublishedJournal(id),
    {
      onSuccess: callAll(
        () => queryClient.invalidateQueries(authorKeys.publishes()),
        onSuccess
      ),
      ...defaultQueryOptions,
    }
  );

  return { deleteJournal, ...rest };
}

export function usePublishedJournals() {
  const { data: journals, ...rest } = useQuery(
    authorKeys.publishes(),
    async () => AuthorApi.getAllPublishedJournals(),
    {
      ...defaultQueryOptions,
      keepPreviousData: true,
    }
  );

  return { journals, ...rest };
}
