import { WideCard } from 'components';
import topic from 'library/propTypes/topic';

export function LibraryTopicCard({ topic = {} }) {
  const { featuredAsset = {} } = topic;
  return <WideCard {...topic} asset={featuredAsset} />;
}

LibraryTopicCard.propTypes = {
  topic: topic.shape,
};

export default Object.assign(LibraryTopicCard, { type: 'topic' });
