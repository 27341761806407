import { getIdMock, getImageMock, getParagraphMock } from './utils';

function create() {
  return {
    id: getIdMock(),
    name: getParagraphMock(1, 5),
    preamble: {
      title: getParagraphMock(1, 5),
      about: getParagraphMock(),
      heroes: [
        {
          thumbnailUrl: getImageMock(400, 300),
          url: getImageMock(800, 400),
        },
      ],
      logos: [
        {
          url: getImageMock(100, 50),
        },
      ],
    },
  };
}

export default { create };
