import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { CommunitiesApi } from 'api';
import { communitiesKeys } from './keys';

const POSTS_PER_PAGE = 30;
const COMMUNITIES_PER_PAGE = 5;
const TOPICS_PER_PAGE = 15;

export function useCommunity(communityId, options) {
  const { data: community, ...rest } = useQuery(
    communitiesKeys.communityById(communityId),
    async () => CommunitiesApi.getCommunity(communityId),
    { ...options }
  );
  return {
    community,
    ...rest,
  };
}

export function useCommunityConversations(communityId) {
  const { data: { pages = [] } = {}, ...rest } = useInfiniteQuery(
    communitiesKeys.conversationsByCommunityId(communityId),
    ({ pageParam = 1 }) =>
      CommunitiesApi.getCommunityTopics(communityId, {
        page: pageParam,
        limit: TOPICS_PER_PAGE,
      }),
    {
      getNextPageParam: (currentState, allPages) => {
        const hasNext = currentState.currentPage < currentState.totalPages;
        return hasNext ? currentState.currentPage + 1 : undefined;
      },
    }
  );
  return {
    pages,
    ...rest,
  };
}

export function useCommunityConversation(communityId, conversationId, options) {
  return useQuery(
    communitiesKeys.conversationSummaryById(communityId, conversationId),
    async () => CommunitiesApi.getConversation(conversationId),
    options
  );
}

export function useConversationPosts(communityId, conversationId) {
  return useInfiniteQuery(
    communitiesKeys.postsByConversationId(communityId, conversationId),
    ({ pageParam = 1 }) =>
      CommunitiesApi.getConversationPosts(conversationId, {
        page: pageParam,
        limit: POSTS_PER_PAGE,
      }),
    {
      getNextPageParam: (currentState) => {
        const hasNext = currentState.currentPage < currentState.totalPages;
        return hasNext ? currentState.currentPage + 1 : undefined;
      },
    }
  );
}

export function useOtherCommunities(searchTerm) {
  return useInfiniteQuery(
    communitiesKeys.other(searchTerm),
    ({ pageParam = 1 }) =>
      CommunitiesApi.getOtherCommunities({
        page: pageParam,
        limit: COMMUNITIES_PER_PAGE,
        searchTerm,
      }),
    {
      refetchOnMount: false,
      getNextPageParam: (currentState, allPages) => {
        const hasNext = currentState.currentPage < currentState.totalPages;
        return hasNext ? currentState.currentPage + 1 : undefined;
      },
    }
  );
}

export function useMyCommunities(searchTerm) {
  return useInfiniteQuery(
    communitiesKeys.my(searchTerm),
    ({ pageParam = 1 }) =>
      CommunitiesApi.getMyCommunities({
        page: pageParam,
        limit: COMMUNITIES_PER_PAGE,
        searchTerm,
      }),
    {
      refetchOnMount: false,
      getNextPageParam: (currentState) => {
        const hasNext = currentState.currentPage < currentState.totalPages;
        return hasNext ? currentState.currentPage + 1 : undefined;
      },
    }
  );
}

export function useResetQueries() {
  const queryClient = useQueryClient();
  return {
    resetMyCommunities: () => {
      queryClient.resetQueries(communitiesKeys.my());
    },
    resetOtherCommunities: () => {
      queryClient.resetQueries(communitiesKeys.other());
    },
  };
}

export function useHJHasExistingCommunity(journalKey, options) {
  return useQuery(
    communitiesKeys.healthJournalCommunityExists(journalKey),
    async () => CommunitiesApi.hasCommunityByHJKey(journalKey),
    options
  );
}

export function useHJCommunity(journalKey, options) {
  return useQuery(
    communitiesKeys.healthJournalCommunity(journalKey),
    async () => CommunitiesApi.getCommunityByHJID(journalKey),
    options
  );
}

export function useHJContainerHasExistingConversation(
  journalKey,
  containerKey,
  options
) {
  return useQuery(
    communitiesKeys.healthJournalContainerConversationExists(
      journalKey,
      containerKey
    ),
    async () =>
      CommunitiesApi.hasConversationByJHContainerKey(journalKey, containerKey),
    options
  );
}

export function useHJContainerConversation(journalKey, containerKey, options) {
  return useQuery(
    communitiesKeys.healthJournalContainerConversation(
      journalKey,
      containerKey
    ),
    async () =>
      CommunitiesApi.getConversationByJHContainerKey(journalKey, containerKey),
    options
  );
}
