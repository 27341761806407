import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'hooks';
import { ChevronDown, ChevronUp, ExpandArrows } from 'components/icons';
import { useOCRStateContext, useOCRUpdaterContext } from 'OCRContext';
import FileView from './FileView';

function OCRDialog() {
  const { t } = useTranslation();

  const [expandAttachmentList, setExpandAttachmentList] = useState(true);
  const { attachmentsList, isCompletedUploading } = useOCRStateContext();

  const { maximizeOcrDialog } = useOCRUpdaterContext();

  const handleToggleOpen = () => {
    setExpandAttachmentList((prev) => !prev);
  };

  useBeforeUnload(true);

  const iconClassName = 'h-4 w-4 stroke-2';

  return (
    <div className="fixed right-16 bottom-14 left-0 z-30 max-w-[60ch] rounded-sm pl-4 shadow-md md:left-auto md:bottom-0 md:right-20 md:min-w-[320px]">
      <header className="flex items-center justify-between bg-smd-accent-light p-4">
        <span>
          {!isCompletedUploading
            ? t('my-phr.ocr.dialog.analyzing')
            : t('my-phr.ocr.dialog.ready')}
        </span>
        <div className="flex items-center space-x-4">
          <button onClick={handleToggleOpen}>
            {expandAttachmentList ? (
              <ChevronDown className={iconClassName} />
            ) : (
              <ChevronUp className={iconClassName} />
            )}
          </button>
          <button onClick={maximizeOcrDialog}>
            <ExpandArrows className="h-3 w-3 stroke-2" />
          </button>
        </div>
      </header>
      {expandAttachmentList && (
        <div>
          <ul className="items-baseline space-y-4 bg-white p-4">
            {attachmentsList?.map((item) => (
              <li className="items-end border-0" key={item.id}>
                <FileView attachment={item.attachment} status={item.status} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default OCRDialog;
