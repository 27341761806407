import { createContext, useContext } from 'react';

const EmbedContext = createContext(false);

function EmbedProvider({ children }) {
  return <EmbedContext.Provider value={true}>{children}</EmbedContext.Provider>;
}

const useIsEmbedded = () => useContext(EmbedContext);
export { EmbedProvider, useIsEmbedded };
export default useIsEmbedded;
