import { useInfiniteQuery, useQuery } from 'react-query';

import { useLibraryApi } from '../libraryApiContext';

export function useLibraryContentByIds(ids, options) {
  const api = useLibraryApi();
  return useQuery(
    ['library', 'search', 'content-by-ids', ids],
    () => api.getLibraryContentByIds(ids),
    { enabled: Boolean(ids?.length), ...options }
  );
}

export function useLibrarySearch(params, options) {
  const api = useLibraryApi();

  return useInfiniteQuery(
    ['library', 'search', 'items', params],
    ({ pageParam: offset }) => api.getLibrarySearch({ ...params, offset }),
    {
      enabled: Boolean(params?.type),
      getNextPageParam: ({ data, meta }) => {
        const resultsCount = data?.length;
        const limit = meta.limit;
        const nextOffset = meta.offset + limit;
        if (resultsCount >= limit) {
          return nextOffset;
        }
      },
      ...options,
    }
  );
}

export function useLibraryCorrectSpelling(params, options) {
  const api = useLibraryApi();
  return useQuery(
    ['library', 'search', 'spelling-correction', params],
    async () => api.getSpellingCorrection(params),
    {
      retry: false,
      onError: () => {},
      ...options,
    }
  );
}
