import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
});

const defaults = {};

export default {
  shape,
  defaults,
};
