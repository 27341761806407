import React from 'react';

function Attention({ children }) {
  return (
    <div className="relative">
      {children}
      <span className="flex-center absolute top-0 right-0 h-3 w-3">
        <span className="absolute h-full w-full rounded-full bg-smd-accent opacity-75"></span>
        <span className="h-2 w-2 rounded-full bg-smd-accent"></span>
      </span>
    </div>
  );
}

export default Attention;
