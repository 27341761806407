import { useMemo } from 'react';
import { isNil } from 'ramda';
import { parseCtaAction } from 'landing/helpers/helpers';

function useParseLandingEnterprisePageData(data) {
  const parsedLandingData = useMemo(() => {
    if (isNil(data)) {
      return {
        slide: null,
        featuresSection: null,
        features: [],
        getStarted: null,
      };
    }

    const parsedData = {};

    //ENTERPRISE LANDING PAGE CONTENT

    //Enterprise Hero Jumbotron
    parsedData.slide =
      data?.['slideshow']?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          imageTitle: curr?.['image-title'],
          ctaText: curr?.['cta-text'],
          ctaProps: parseCtaAction(curr),
        };
        return [...prev, parsedCurr];
      }, [])?.[0] ?? null;

    //Enterprise Features Section
    parsedData.featuresSection =
      data?.['features']?.filter((feature) => feature?.type === 'text')[0] ??
      null;

    parsedData.features =
      data?.['features']
        ?.filter(
          (feature) => feature?.type === 'video' || feature?.type === 'image'
        )
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            ...curr,
            ctaText: curr?.['cta-text'],
            ctaProps: parseCtaAction(curr),
          };
          return [...prev, parsedCurr];
        }, []) ?? [];

    //Enterprise Get Started Section
    parsedData.getStarted =
      data?.['start']
        ?.filter((entry) => entry?.type === 'image')
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            ...curr,
            ctaText: curr?.['cta-text'],
            ctaProps: parseCtaAction(curr),
          };
          return [...prev, parsedCurr];
        }, [])?.[0] ?? null;

    return parsedData;
  }, [data]);

  return parsedLandingData;
}

export default useParseLandingEnterprisePageData;
