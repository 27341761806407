import { useTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import { ChevronDown } from 'components/icons';
import { InfiniteScrollTrigger } from 'components';

import { useGroupedJournals } from 'journals/hooks';
import { SkeletonCardCarousel } from 'journals/components';
import { CategoryView } from 'journals/history/components';

const MAX_HJ_TO_RENDER = 12;

function GroupedJournalsSection({
  onSelect,
  onAddTo,
  wrapperClassName,
  api,
  dataType,
}) {
  const { t } = useTranslation();

  const {
    groupedJournalsData,
    hasNoGroupedJournals,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    isFetchingNextPage,
  } = useGroupedJournals(api, dataType);

  return (
    <>
      {isFetching && !isFetched ? (
        <SkeletonCardCarousel className={wrapperClassName} />
      ) : (
        <>
          {groupedJournalsData?.map(({ category, journals }) => (
            <CategoryView
              key={category.id}
              category={category.name}
              journals={journals}
              onSelect={onSelect}
              onAddTo={onAddTo}
              className={wrapperClassName}
              journalsToRenderCount={MAX_HJ_TO_RENDER}
            />
          ))}
          <div className="mx-auto py-8">
            {hasNextPage && (
              <InfiniteScrollTrigger
                onFetch={fetchNextPage}
                isFetching={isFetchingNextPage}
              >
                <Button.Ghost
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                  className="font-semibold"
                >
                  <span>{t('common.show-more')}</span>

                  <ChevronDown className="h-5 w-5" strokeWidth="3" />
                </Button.Ghost>
              </InfiniteScrollTrigger>
            )}
            {hasNoGroupedJournals && <span>{t('common.no-results')}</span>}
          </div>
        </>
      )}
    </>
  );
}

export default GroupedJournalsSection;
