import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollResetOnNavigate(elementRef) {
  const location = useLocation();

  useEffect(() => {
    elementRef?.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);
}

export default useScrollResetOnNavigate;
