import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ContentContainer } from 'components';
import { useSocialMediaProviders } from 'hooks';
import { getDistributionConfig } from 'distribution';
import { aboutPageUrl } from 'utils';

function Footer() {
  const { t, i18n } = useTranslation();

  const slogan = getDistributionConfig([
    'modifications',
    'footer',
    i18n?.language,
  ]);

  const socials = useSocialMediaProviders();

  return (
    <footer className="bg-smd-gray-lightest px-smd-base text-smd-xs text-smd-gray-darker lg:px-smd-xxl lg:text-smd-base">
      <ContentContainer className="flex flex-col space-y-4 py-4 lg:flex-row lg:items-start lg:justify-between lg:space-y-0">
        <div className="flex flex-col space-y-4 lg:w-3/4">
          <div
            className="items-center text-smd-h3.5 italic lg:text-2xl"
            data-testid="storyMdInformationMessageFooter"
          >
            {slogan ?? t('legal.slogan')}
          </div>
          <ul className="grid w-full max-w-md grid-cols-3 gap-1 lg:grid-cols-2 lg:gap-3">
            <li>
              <a
                href={aboutPageUrl}
                rel="noopener noreferrer"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="ourStoryHamburgerLink"
              >
                {t('shared-menu.our-story')}
              </a>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="privacyPolicyFooterLink"
              >
                {t('legal.privacy-policy')}
              </Link>
            </li>
            <li>
              <Link
                to="/help-center"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="helpCenterFooterLink"
              >
                {t('common.help-center')}
              </Link>
            </li>
            <li>
              <Link
                to="/terms"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="termsOfServiceFooterLink"
              >
                {t('legal.terms-of-service')}
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="contactUsHamburgerLink"
              >
                {t('common.contact')}
              </Link>
            </li>
            <li>
              <Link
                to="/accessibility"
                className="smd-focus-visible-primary rounded-sm"
                data-testid="accessibilityFooterLink"
              >
                {t('legal.accessibility')}
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col space-y-4 lg:max-w-xs lg:items-end">
          <ul className="flex flex-wrap gap-y-4 gap-x-6 lg:gap-y-0">
            {socials.map(({ type, url, icon: Icon }) => (
              <li key={type}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smd-focus-visible-primary block rounded-sm"
                  data-testid={`${type}FooterIcon`}
                >
                  <Icon className="h-8 w-8 fill-smd-gray-dark hover:fill-smd-gray-darker lg:h-5 lg:w-5" />
                  <span className="sr-only">
                    {t('labels.common.find-us', {
                      social: type,
                    })}
                  </span>
                </a>
              </li>
            ))}
          </ul>
          <div className="pr-20 text-smd-sm italic lg:whitespace-nowrap lg:pr-0">
            &copy; {new Date().getFullYear()} {t('landing.reserved-rights')}
          </div>
        </div>
      </ContentContainer>
    </footer>
  );
}

export default Footer;
