import { useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';

import { ChevronLeft } from 'components/icons';

function GoBackLink({ to, label, className }) {
  const history = useHistory();

  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <Link
      onClick={handleGoBack}
      className={classnames(
        'flex items-center text-smd-sm text-smd-accent',
        className
      )}
      to={to}
    >
      <ChevronLeft className="h-4 w-4 stroke-2" />
      <span>{label}</span>
    </Link>
  );
}

export default GoBackLink;
