import { createContext, useContext, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { dissoc } from 'ramda';

import { useOnEsc } from 'hooks';
import Fade from '../Fade';
import Portal from '../Portal';
import { useModalClose } from './useModalClose';

function CenterWrapper(props) {
  const {
    isOpen,
    onRequestClose,
    className,
    childrenContainerClassName,
    children,
    ...rest
  } = props;

  const closeProps = useModalClose(onRequestClose);
  return (
    <div className="h-full w-full overflow-auto" {...rest}>
      <div className="flex min-h-full" {...closeProps}>
        <div className={classnames('m-auto', childrenContainerClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
}

function ResponsiveContainer(props) {
  const { isOpen, onRequestClose, className, ...rest } = props;
  const closeProps = useModalClose(onRequestClose);
  return (
    <div
      className={classnames(
        'flex min-h-screen w-full md:min-h-0 md:w-auto',
        className
      )}
      {...rest}
      {...closeProps}
    />
  );
}

const modalStyles = classnames(
  'fixed inset-0 z-10',
  'bg-black bg-opacity-50 backdrop-filter backdrop-blur'
);

const ModalContext = createContext(false);

export const useIsInModal = () => useContext(ModalContext);

function Modal({ className, contentWrapper: Wrapper, ...props }) {
  const MODAL_ROOT = useMemo(() => document.getElementById('modal-root'), []);

  const { isOpen, onRequestClose, prepend } = props;

  useEffect(() => {
    const count = getCountOfModalElementsWithContent(MODAL_ROOT);
    const body = document.body;

    // TODO: add padding right to body to prevent layout shifting

    if (count > 1 && !isOpen) {
      return (body.style.overflow = 'hidden');
    }

    switchBodyOverflow(isOpen);

    return () => {
      body.style.overflow = 'auto';
    };
  }, [isOpen, MODAL_ROOT]);

  useOnEsc(() => {
    if (isOpen) {
      onRequestClose();
    }
  });

  const closeProps = useModalClose(onRequestClose);
  return (
    <Portal container={MODAL_ROOT} prepend={prepend}>
      <Fade in show={isOpen}>
        <ModalContext.Provider value={true}>
          <div className={classnames(modalStyles, className)} {...closeProps}>
            {Wrapper ? (
              <Wrapper {...dissoc('prepend', props)} />
            ) : (
              props.children
            )}
          </div>
        </ModalContext.Provider>
      </Fade>
    </Portal>
  );
}

Modal.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  contentWrapper: CenterWrapper,
};

export default Object.assign(Modal, { CenterWrapper, ResponsiveContainer });

function getCountOfModalElementsWithContent(element) {
  if (element?.children?.length) {
    return [...element?.children].filter((x) => Boolean(x.innerHTML)).length;
  }
  return 0;
}

function switchBodyOverflow(isOpen) {
  const body = document.body;
  if (isOpen) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'auto';
  }
}
