import { createContext, useState, useEffect } from 'react';
import { enUS } from 'date-fns/locale';
import { useSensitiveContent } from 'hooks';

const DEFAULT_LOCALE = 'en-US';

export const SettingsContext = createContext();

// TOOD: Move the locale related code into a hook
export function SettingsProvider({ children }) {
  const [locale, setLocale] = useState();
  const sensitiveContent = useSensitiveContent();
  const language = navigator?.language || DEFAULT_LOCALE;
  useEffect(() => {
    const importLocaleFile = async () => {
      try {
        const localeToSet = await import(
          `date-fns/locale/${language}/index.js`
        );
        setLocale(localeToSet?.default || enUS);
      } catch {
        setLocale(enUS);
      }
    };
    if (!locale) importLocaleFile();
  }, [locale, language]);

  const value = {
    locale,
    sensitiveContent,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
