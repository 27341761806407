import PropTypes from 'prop-types';

import ReactCompareImage from 'react-compare-image';

function Handle() {
  return (
    <div className="flex h-12 w-12 items-center justify-evenly rounded-full border-5 border-smd-accent bg-white">
      <div className="h-0 w-0 border-6 border-transparent border-r-smd-accent-bright" />
      <div className="h-0 w-0 border-6 border-transparent border-l-smd-accent-bright" />
    </div>
  );
}

function CompareImage(props) {
  const { images, imageCss } = props;

  const leftImage = images?.[0];
  const rightImage = images?.[1];

  return (
    <ReactCompareImage
      leftImage={leftImage?.src}
      leftImageAlt={leftImage?.alt}
      leftImageCss={imageCss}
      rightImage={rightImage?.src}
      rightImageAlt={rightImage?.alt}
      rightImageCss={imageCss}
      sliderLineWidth={5}
      sliderLineColor="var(--smd-tertiary)"
      handle={<Handle />}
    />
  );
}

CompareImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ),
};

export default CompareImage;
