import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChatDouble } from 'components/icons';
import { useAuth } from 'Auth';
import { Button } from 'components/forms';

export default function StartConversation({ onButtonClick }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { authenticated } = useAuth();
  const onClick = () =>
    authenticated ? onButtonClick() : history.replace('/communities');

  return (
    <>
      <div className="flex-none">
        <ChatDouble className="mr-4 inline" />
        <span className="text-sm font-bold">{t('common.share-discuss')}</span>
      </div>
      <div className="mt-3 ml-11 flex whitespace-pre-line text-sm xl:m-0 xl:flex-grow xl:justify-center">
        {t('conversations.share-your-story')}
      </div>
      <div className="ml-11 mt-4 flex-none xl:m-0">
        <Button.Primary onClick={onClick}>
          {t('communities.create-conversation')}
        </Button.Primary>
      </div>
    </>
  );
}

StartConversation.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};
