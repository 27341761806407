import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { length } from 'ramda';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

import { useMedia } from 'hooks';
import { ChevronLeft, ChevronRight, X as CloseIcon } from 'components/icons';
import { Carousel, useCarousel, VirtualCarousel } from 'components/Carousel';

import Modal from '../Modal';
import Caption from './Caption';
import Element from './Element';

const classes = [
  'w-12 h-12',
  'absolute bottom-1/2 z-10',
  'rounded-full bg-smd-gray-darker bg-opacity-75 hover:bg-opacity-100',
  'text-white p-2',
  'focus:outline-none',
];

function PrevBtn() {
  const { carousel } = useCarousel();

  if (carousel.isBeginning || carousel.activeIndex === 0) {
    return null;
  }

  return (
    <Carousel.BasePrev className={classnames(classes, 'right-auto left-3')}>
      <ChevronLeft />
    </Carousel.BasePrev>
  );
}

function NextBtn() {
  const { carousel, slidesCount } = useCarousel();

  if (carousel.isEnd || carousel.activeIndex === slidesCount - 1) {
    return null;
  }

  return (
    <Carousel.BaseNext className={classnames(classes, 'left-auto right-3')}>
      <ChevronRight />
    </Carousel.BaseNext>
  );
}

function ForcedCarouselNavigation({ activeIndex }) {
  const { carousel } = useCarousel();
  useEffect(() => {
    carousel?.slideTo?.(activeIndex, 0);
  }, [carousel, activeIndex]);
  return null;
}

function InteractiveLightBox({
  openAtIndex,
  isOpen,
  onClose,
  slides,
  carouselProps,
  additionalActions,
}) {
  const { t } = useTranslation();
  const navigationEnabled = length(slides) > 1;

  const isLarge = useMedia(useMedia.LARGE);
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div
        className="flex h-screen w-screen flex-col bg-smd-gray-darker bg-opacity-80"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex w-full grow-0 justify-end bg-smd-gray-darker">
          {additionalActions}
          <button onClick={onClose} aria-label={t('common.close')}>
            <CloseIcon className="w-10 cursor-pointer p-1 text-white" />
          </button>
        </div>

        <VirtualCarousel
          className="flex flex-grow"
          carouselProps={{
            allowTouchMove: !isLarge && navigationEnabled,
            breakpoints: {
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
            },
            onResize: (swiper) => swiper.slides.css('width', '100vw'),
            ...carouselProps,
          }}
          slides={slides.map((item) => (
            <Carousel.Slide key={nanoid()} className="p-0">
              {(carouselSlideProps) => {
                if (typeof item === 'function') {
                  return item(carouselSlideProps);
                }
                return item;
              }}
            </Carousel.Slide>
          ))}
        >
          {navigationEnabled && (
            <>
              <PrevBtn />
              <NextBtn />
            </>
          )}
          <ForcedCarouselNavigation activeIndex={openAtIndex} />
        </VirtualCarousel>
      </div>
    </Modal>
  );
}

InteractiveLightBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openAtIndex: PropTypes.number,
};

InteractiveLightBox.defaultProps = {
  openAtIndex: 0,
};

InteractiveLightBox.Element = Element;
InteractiveLightBox.Caption = Caption;

export default InteractiveLightBox;
