import { useState } from 'react';
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCollections } from 'hooks';

import { ContentContainer } from 'components';
import { Button } from 'components/forms';

import EducationalVideos, {
  COLLECTIONS_HELP_KEY,
} from 'journals/viewer/EducationalVideos/EducationalVideos';

import { NoSearchResults, SearchBarSection } from '../components';
import {
  CollectionJournals,
  CollectionWrapper,
  CreateCollectionWizard,
} from './components';
import CollectionsDashboard from './CollectionsDashboard';
import CollectionEditDashboard from './CollectionEditDashboard';
import { useCollectionFiltering } from './hooks';

function JournalsCollections() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [createFormVisible, setCreateFormVisible] = useState(false);

  const { collections } = useCollections();
  const filteredCollections = useCollectionFiltering(searchTerm, collections);

  const hasOnlyDefaultCollection = collections?.every?.(
    (collection) => collection.defaultCollection === true
  );

  return (
    <section className="grow px-smd-base pb-smd-xl antialiased lg:px-smd-xxl lg:pb-smd-xxl">
      <ContentContainer>
        <SearchBarSection
          className="pb-8"
          withClearButton
          placeholder={t('journals.collections.search-placeholder')}
          onChange={(search) => setSearchTerm(search.trim().toLowerCase())}
          label={t('labels.common.search-button', {
            type: t('common.healthjournal_other'),
          })}
        >
          <div className="flex flex-wrap space-y-2 whitespace-nowrap sm:space-x-4 sm:space-y-0">
            {collections?.length > 0 && (
              <Link
                to={`${path}manage`}
                className="smd-focus-visible-primary flex w-full rounded-sm sm:w-auto"
                aria-label={t('labels.collections.manage-collections')}
              >
                <Button.Tertiary className="font-semibold" tabIndex={-1}>
                  {t('journals.collections.manage')}
                </Button.Tertiary>
              </Link>
            )}
            <Button.Primary
              onClick={() => setCreateFormVisible(true)}
              className="font-semibold"
              disabled={createFormVisible}
            >
              {t('journals.collections.create')}
            </Button.Primary>
          </div>
        </SearchBarSection>
        {createFormVisible && (
          <div className="pb-8">
            <CreateCollectionWizard
              isVisible={createFormVisible}
              onHide={() => setCreateFormVisible(false)}
            />
          </div>
        )}
        {!Boolean(filteredCollections?.length) && (
          <NoSearchResults title={t('journals.no-search-results')} />
        )}
        {filteredCollections?.map((collection) => (
          <CollectionWrapper key={collection?.id} collection={collection}>
            <CollectionJournals
              collection={collection}
              searchTerm={collection.collectionNameMatch ? '' : searchTerm}
            />
          </CollectionWrapper>
        ))}
        {hasOnlyDefaultCollection && (
          <EducationalVideos renderKey={COLLECTIONS_HELP_KEY} />
        )}
      </ContentContainer>
    </section>
  );
}

function Collections() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={JournalsCollections} />
      <Route exact path={`${path}/manage`} component={CollectionsDashboard} />
      <Route
        exact
        path={`${path}/manage/:id`}
        component={CollectionEditDashboard}
      />
    </Switch>
  );
}

export default Collections;
