import React from 'react';
import classnames from 'classnames';
import { isNil } from 'ramda';

function Toggle({ value, onChange, children }) {
  let activeIndex;
  const Buttons = React.Children.map(children, (child, index) => {
    if (child.type !== ToggleButton)
      throw new Error(
        'Only Toggle.Button compound components can be rendered inside the Toggle component'
      );

    const active = value === child.props.value;
    if (active) activeIndex = index;
    return React.cloneElement(child, {
      active,
      onClick: () => onChange(child.props.value),
    });
  });

  return (
    <div className="relative inline-flex overflow-hidden rounded border border-smd p-px">
      <div
        className="absolute h-8 w-14 rounded bg-smd-accent p-1 transition-transform"
        style={{
          transform: `translateX(${
            isNil(activeIndex) ? -110 : activeIndex * 100
          }%)`,
        }}
      />
      {Buttons}
    </div>
  );
}

function ToggleButton({ icon: Icon, active, onClick, label }) {
  return (
    <button
      className={classnames(
        'flex-center relative h-8 w-14 rounded p-1 transition-colors',
        { 'text-white': active }
      )}
      onClick={onClick}
      aria-label={label}
    >
      <Icon className="h-full w-full" strokeWidth="1.5" />
    </button>
  );
}

export default Object.assign(Toggle, { Button: ToggleButton });
