import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';

import { Button } from 'components/forms';
import Form from 'components/forms/Form';

import { namesWithSpacesAndSpecialChars, validEmailRegEx } from 'utils';
import { useContactUs } from './hooks';

export function ContactUsForm({ onSuccess, onError }) {
  const { t } = useTranslation();

  const reCaptchaRef = useRef();

  const { sendEmail, isLoading } = useContactUs({
    onMutate: () => reCaptchaRef.current.reset(),
    onSuccess,
    onError,
  });

  const onSubmit = (data) => {
    reCaptchaRef.current
      .executeAsync()
      .then((token) => {
        data['g-recaptcha-response'] = token;
        return data;
      })
      .then((dataWithReCaptcha) => {
        const formData = new FormData();
        for (const entry of Object.entries(dataWithReCaptcha)) {
          formData.append(entry[0], entry[1]);
        }
        sendEmail(formData);
      });
  };

  const firstNameValue = t('account-settings.manage-profile.first-name');
  const lastNameValue = t('account-settings.manage-profile.last-name');
  const emailValue = t('common.email');
  const messageValue = t('common.message');
  return (
    <div className="w-full max-w-130">
      <Form
        onSubmit={onSubmit}
        className={classnames('grid w-full grid-cols-2 gap-4 pb-4 pt-8', {
          'pointer-events-none': isLoading,
        })}
        resetFormOnSuccess
        defaultValues={{
          subject: t('contact.subject'),
        }}
        id="contact-form"
      >
        <Form.InputWrapper className="col-span-2 md:col-span-1">
          <Form.Input
            groupClassName="flex flex-col"
            placeholder={t('contact.placeholder-first-name')}
            data-testid="contactUsPageFirstNameField"
            label={t('contact.label', {
              value: firstNameValue,
            })}
            name="name"
            rules={{
              required: t('contact.enter', {
                value: firstNameValue,
              }),
              pattern: {
                value: namesWithSpacesAndSpecialChars,
                message: t('errors.pattern', {
                  field: firstNameValue,
                }),
              },
            }}
            aria-label={t('contact.enter-message', {
              value: firstNameValue,
            })}
          />
        </Form.InputWrapper>
        <Form.InputWrapper className="col-span-2 md:col-span-1">
          <Form.Input
            groupClassName="flex flex-col"
            placeholder={t('contact.placeholder-last-name')}
            data-testid="contactUsPageLastNameField"
            label={t('contact.label', {
              value: lastNameValue,
            })}
            name="lastName"
            rules={{
              required: t('contact.enter', {
                value: lastNameValue,
              }),
              pattern: {
                value: namesWithSpacesAndSpecialChars,
                message: t('errors.pattern', {
                  field: lastNameValue,
                }),
              },
            }}
            aria-label={t('contact.enter-message', {
              value: lastNameValue,
            })}
          />
        </Form.InputWrapper>
        <Form.InputWrapper className="col-span-2">
          <Form.Input
            groupClassName="flex flex-col"
            placeholder={t('contact.placeholder-email')}
            data-testid="contactUsPageEmailField"
            label={t('contact.label', {
              value: emailValue,
            })}
            name="email"
            type="email"
            rules={{
              required: t('contact.enter', {
                value: emailValue,
              }),
              pattern: {
                value: validEmailRegEx,
                message: t('contact.email-error-message'),
              },
            }}
            aria-label={t('contact.enter-message', {
              value: emailValue,
            })}
          />
        </Form.InputWrapper>
        <Form.InputWrapper className="col-span-2">
          <Form.TextArea
            groupClassName="flex flex-col"
            placeholder={t('contact.placeholder-message')}
            data-testid="contactUsPageMessageField"
            className="min-h-20"
            label={t('contact.label', {
              value: messageValue,
            })}
            name="message"
            rules={{
              required: t('contact.enter', {
                value: messageValue,
              }),
            }}
            aria-label={t('contact.enter-message', {
              value: messageValue,
            })}
          />
        </Form.InputWrapper>
      </Form>
      <Button.Primary
        size="lg"
        type="submit"
        form="contact-form"
        data-testid="contactUsPageSubmitFormButton"
        loading={isLoading}
      >
        {t('common.submit')}
      </Button.Primary>
      <div className="fixed z-10">
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          size="invisible"
        />
      </div>
    </div>
  );
}
