import { Modal } from 'components';
import LibraryTopicModalContainer from './LibraryTopicModalContainer';

function LibraryTopicModal({ isOpen, onRequestClose, hashId }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentWrapper={null}
    >
      <div
        className="min-w-5xl mx-auto h-full max-w-5xl lg:py-8"
        role="dialog"
        aria-modal="true"
      >
        <LibraryTopicModalContainer hashId={hashId} onClose={onRequestClose} />
      </div>
    </Modal>
  );
}

export default LibraryTopicModal;
