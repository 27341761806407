import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import IsoCountries from 'i18n-iso-countries';
import IsoCountriesEn from 'i18n-iso-countries/langs/en.json';

import { Select } from '.';

IsoCountries.registerLocale(IsoCountriesEn);
const countryMap = IsoCountries.getNames('en');
const countryList = Object.entries(countryMap).sort(sortByName);

function sortByName([, firstCountryName], [, secondCountryName]) {
  return firstCountryName > secondCountryName ? 1 : -1;
}

export default forwardRef(function SelectCountry(props, ref) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'account-settings.manage-profile',
  });
  const [usCode, usName] = countryList.find(([code]) => code === 'US');

  return (
    <Select ref={ref} {...props}>
      <option value="">{t('location-placeholder')}</option>
      <option value={usCode}>{usName}</option>
      {countryList.map(([code, name]) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </Select>
  );
});
