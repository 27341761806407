import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { ChevronDown } from 'components/icons';
import BetaStateDropdown from '../BetaStateDropdown';
import useMenuRefs from '../useMenuRefs';

function BetaDropDown({ state, dispatch, isScrolled }) {
  const { t } = useTranslation();

  const { betaDropdownRef } = useMenuRefs(state, dispatch);
  const { isBetaOpen } = state;

  return (
    <>
      <button
        className="smd-focus-visible-primary ml-1 hidden cursor-pointer select-none flex-nowrap items-center rounded-sm text-smd-sm sm:flex lg:ml-0"
        aria-label={t('labels.common.beta')}
        aria-haspopup="true"
        aria-expanded={isBetaOpen}
        data-testid="menuBetaButton"
        onClick={() => dispatch({ type: 'open', menu: 'isBetaOpen' })}
      >
        <span className="ml-1">Beta</span>
        <ChevronDown
          strokeWidth="2"
          className={classnames(
            'pointer-events-none ml-1 h-5 w-5 transform text-smd-main transition-transform duration-200',
            {
              'rotate-180': isBetaOpen,
            }
          )}
        />
      </button>
      {isBetaOpen && (
        <BetaStateDropdown ref={betaDropdownRef} isScrolled={isScrolled} />
      )}
    </>
  );
}

export default BetaDropDown;
