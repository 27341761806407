import classnames from 'classnames';

import { ContentContainer, Skeleton } from 'components';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { SkeletonCard } from 'journals/components';

function SkeletonCardCarousel({
  children,
  className,
  textProps,
  cardsCount = 6,
  title,
  isChannelCards = false,
  ...rest
}) {
  return (
    <section
      className={classnames(
        'mb-smd-xxl mt-smd-lg overflow-hidden px-4 lg:px-0',
        className
      )}
      {...rest}
    >
      <ContentContainer>
        {title ?? (
          <Skeleton.Text
            fontSize="1.375rem"
            lineHeight="1.5"
            style={{
              width: 'clamp(15rem, 20vw, 20rem)',
              marginBottom: '1rem',
            }}
            {...textProps}
          />
        )}
        <VirtualCarousel
          slides={[...Array(cardsCount).keys()].map((key) => (
            <Carousel.Slide key={key}>
              <div className="h-full w-full">
                <SkeletonCard
                  className="w-full"
                  additionalContent={
                    isChannelCards && (
                      <div className="absolute -top-12 h-16 w-16 rounded-full bg-white lg:-top-16 lg:h-20 lg:w-20">
                        <Skeleton.Circle className="h-full w-full border-2 border-smd-accent" />
                      </div>
                    )
                  }
                />
              </div>
            </Carousel.Slide>
          ))}
        />
        {children}
      </ContentContainer>
    </section>
  );
}

export default SkeletonCardCarousel;
