import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { registerFormElement } from 'components/forms/Form';
import { DATE_PRESETS } from 'my-phr/const';
import {
  MANUAL_INPUT_CATEGORY,
  MANUAL_INPUT_TYPE,
} from 'my-phr/layout/modules/ManualInputs/const';
import { getManualInput } from 'my-phr/layout/modules/ManualInputs/utils';
import { safeDateFormat } from 'utils';
import { Button } from 'components/forms';
import { useOCRStateContext, useOCRUpdaterContext } from 'OCRContext';

function EditOCRForm({ goToPreview, preview, ...props }) {
  const { t } = useTranslation();

  const { ocrTitle } = useOCRStateContext();
  const { setOcrTitle } = useOCRUpdaterContext();

  const fieldConfiguration = useMemo(() => {
    return preview?.observations?.map((item) => ({
      ...item,
      type: 'observation',
      fields: [
        {
          inputType: 'result',
          label: 'Result',
          fields: [
            {
              name: 'value',
              inputType: 'text',
              label: 'Value',
              rules: {
                required: true,
              },
            },
            {
              name: 'unit',
              inputType: 'text',
              label: 'Unit',
              placeholder: 'Unit',
              rules: {
                required: false,
                requiredLabel: true,
              },
            },
          ],
        },
      ],
    }));
  }, [preview]);

  const data = { observations: fieldConfiguration, type: 'lab_panel' };
  const prefix = MANUAL_INPUT_CATEGORY.OBSERVATION_PANEL;

  const date = preview?.date ? new Date(preview?.date) : new Date();

  return (
    <>
      <div className="border-b-2 px-4 pb-2 text-smd-h4 font-semibold">
        {t('my-phr.ocr.preview.title')}
      </div>

      <div className="space-y-4 p-4">
        {getManualInput(MANUAL_INPUT_TYPE.TEXT, {
          name: 'name',
          label: 'Name',
          defaultValue:
            ocrTitle ||
            `${t('my-phr.ocr.edit.title', {
              date: safeDateFormat({
                date: preview?.date ?? new Date().toString(),
                pattern: DATE_PRESETS.DATE_ONLY,
                fallback: '',
              }),
            })}`,
          onChange: (e) => setOcrTitle(e.target.value),
          ...props,
        })}
        {getManualInput(MANUAL_INPUT_TYPE.DATE, {
          name: 'date',
          label: t('my-phr.conditions.time-results'),
          defaultValue: date,
          ...props,
        })}
        {getManualInput(MANUAL_INPUT_TYPE.LIBRARY, {
          data,
          ...props,
          prefix,
        })}
      </div>
      <div className="flex w-full shrink-0 items-center justify-end border-t bg-white px-4 pt-4">
        <Button.Primary type="submit">
          {t('my-phr.ocr.upload.preview')}
        </Button.Primary>
      </div>
    </>
  );
}

registerFormElement(EditOCRForm);

export default EditOCRForm;
