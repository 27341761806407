import React, { forwardRef } from 'react';

import { Aspect, Video } from 'components';
import { getAssetUrl } from 'utils';

const VideoAsset = forwardRef(({ config, ...rest }, ref) => {
  switch (config.mimetype) {
    case 'vimeo':
    case 'youtube':
      return (
        <Player
          ref={ref}
          url={config.url}
          thumbnail={getThumbnail(config)}
          controls
          embedded
          {...rest}
        />
      );
    default:
      return (
        <Player
          ref={ref}
          url={getAssetUrl(config.url)}
          thumbnail={getThumbnail(config)}
          controls
          {...rest}
        />
      );
  }
});
export default VideoAsset;

function getThumbnail({ poster, thumbnail }) {
  return getAssetUrl(poster || thumbnail);
}

const Player = forwardRef((props, ref) => {
  return (
    <Aspect.Fixed ratio={16 / 9} className="overflow-hidden">
      <Video
        wrapperClassName="bg-smd-gray-darker"
        width="100%"
        height="100%"
        {...props}
        ref={ref}
      />
    </Aspect.Fixed>
  );
});
