import PropTypes from 'prop-types';

export function getLinkagesShape(propsTypes = {}) {
  return PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ...propsTypes,
    })
  );
}
