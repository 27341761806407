import { useEffect, useLayoutEffect, useState } from 'react';
import classnames from 'classnames';
import { includes } from 'ramda';

import { ContentContainer } from 'components';
import { useScroll } from 'components/Scroll';
import { Tabs } from 'components/navigation';
import { BrowseChannels } from 'journals/channels';

import { useParseSearchSummary, useSearchSummary } from '../hooks';

import { RESULTS_LARGE_CONTENT_CONTAINERS } from '../const';
import LibraryResourcesResults from './LibraryResourcesResults';

function LibrarySearchResults({ queryConfig, currentTab }) {
  const { backToTop, isScrolled } = useScroll();

  const [queryParams, setQueryParams] = useState(queryConfig);

  useLayoutEffect(() => {
    backToTop();
  }, [queryParams, backToTop]);

  useEffect(() => {
    if (queryConfig.query) {
      setQueryParams((prev) => ({
        ...prev,
        query: queryConfig.query,
      }));
    }
  }, [queryConfig.query]);

  useEffect(() => {
    if (currentTab) {
      setQueryParams((prev) => ({
        ...prev,
        type: currentTab,
      }));
    }
  }, [currentTab]);

  const {
    summaryResults,
    isFetching: isFetchingSummary,
    isFetched: isFetchedSummary,
  } = useSearchSummary(queryParams.query);
  const parsedSummaryResults = useParseSearchSummary(
    summaryResults,
    queryConfig.query
  );

  const largeContainer = includes(
    queryParams?.type,
    RESULTS_LARGE_CONTENT_CONTAINERS
  );

  return (
    <div className="isolate grow">
      <div
        className={classnames(
          isScrolled
            ? 'top-smd-sticky-spacer-small'
            : 'lg:top-smd-sticky-spacer-large',
          'sticky z-30 bg-white lg:mt-4 lg:px-smd-xxl'
        )}
      >
        <ContentContainer
          maxWidthClassName={largeContainer ? 'max-w-screen-2xl' : 'max-w-5xl'}
        >
          <Tabs.Router
            tabs={parsedSummaryResults}
            currentTab={queryParams.type}
            leftAlignTabs={false}
            fullWidth
          />
        </ContentContainer>
      </div>
      <main className="grow px-4 lg:px-smd-xxl">
        <ContentContainer
          maxWidthClassName={largeContainer ? 'max-w-screen-2xl' : 'max-w-5xl'}
        >
          <div className="py-5">
            {queryParams.type === 'channel' ? (
              <BrowseChannels
                groupClassName="px-0"
                searchTerm={queryParams.query}
              />
            ) : (
              <LibraryResourcesResults
                loading={isFetchingSummary && !isFetchedSummary}
                parsedSummaryResults={parsedSummaryResults}
                queryParams={queryParams}
              />
            )}
          </div>
        </ContentContainer>
      </main>
    </div>
  );
}

export default LibrarySearchResults;
