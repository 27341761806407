import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Switch from './Switch';

const sizeMap = {
  sm: 'scale-50',
  lg: 'scale-150',
};

const SideLabelsSwitch = forwardRef(
  ({ labels, checked, onChange, className, size }, ref) => {
    const LeftLabel =
      labels[true] instanceof Function ? labels[true] : () => labels[true];
    const RightLabel =
      labels[false] instanceof Function ? labels[false] : () => labels[false];

    const { t } = useTranslation();
    return (
      <div className={className}>
        <label className="inline-flex cursor-pointer items-center space-x-4">
          <span className="inline-flex items-center space-x-1">
            <LeftLabel />
          </span>
          <div
            className={classnames(
              'translate-y-1  transform',
              sizeMap[size] || ''
            )}
          >
            <Switch
              checked={checked}
              onChange={onChange}
              ref={ref}
              aria-label={t('labels.editor.editor-mode', {
                mode: t(
                  checked
                    ? 'common.edit'
                    : 'journals.visual-editor.toolbar.preview'
                ),
              })}
            />
          </div>
          <span className="inline-flex items-center space-x-1">
            <RightLabel />
          </span>
        </label>
      </div>
    );
  }
);

SideLabelsSwitch.propTypes = {
  labels: PropTypes.shape({
    true: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    false: PropTypes.oneOfType([PropTypes.func, PropTypes.func]).isRequired,
  }).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'base', 'lg']),
};

export default SideLabelsSwitch;
