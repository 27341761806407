import { Link, NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useMedia } from 'hooks';

const menuItemStyles = classnames(
  'flex items-center lg:px-2 py-1 truncate rounded-sm font-semibold',
  'smd-focus-visible-primary'
);
export const menuItemActiveStyles =
  'text-smd-h4 text-smd-accent font-semibold tracking-tight';
const menuItemIconStyles = 'w-6 h-6';

export function MenuNavLink({ icon: Icon, children, ...props }) {
  const isLarge = useMedia(useMedia.LARGE);

  return (
    <NavLink
      className={menuItemStyles}
      activeClassName={menuItemActiveStyles}
      {...props}
    >
      {Icon && isLarge && (
        <span className="relative mr-2 block lg:hidden 2xl:block">
          <Icon className={menuItemIconStyles} />
        </span>
      )}
      <span className="truncate">{children}</span>
    </NavLink>
  );
}

export const menuLinkStyles = classnames(
  'text-smd-accent px-2 py-1',
  'smd-focus-visible-primary'
);

export function MenuLink(props) {
  return <Link className={menuLinkStyles} {...props} role="menuitem" />;
}
