import { createContext, useContext } from 'react';

const CarouselContext = createContext();

function CarouselProvider(props) {
  return <CarouselContext.Provider {...props} />;
}

export function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used inside CarouselContext.Provider');
  }
  return context;
}

export default CarouselProvider;
