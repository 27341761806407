import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { not, prop } from 'ramda';

import { ClipboardList } from 'components/icons';
import { ViewerSidebar, ViewerTableOfContent } from 'components/viewer';

import ExpandableSection from 'components/ExpandableSection';
import { ExpandableSectionList, LibraryReferences } from 'components';
import { LibraryRange } from '../../../range';
import LibraryArticle, {
  LibraryArticleWithSubarticles,
} from '../../../article/LibraryArticle';
import { useLibraryQuery } from '../../../../hooks';

function TopicContent({ tocRoot, tocActive, tocSelected, setTocActive }) {
  const { t } = useTranslation();
  const [tocVisible, setTocVisible] = useState(true);

  const content = tocSelected?.dataRef;
  const subarticleHashIds = useMemo(
    () => content?.article?.linkages?.subarticles?.map(prop('id')),
    [content]
  );

  const { data: subarticles } = useLibraryQuery(subarticleHashIds);

  return (
    <div className="flex flex-grow">
      <div className="hidden w-14 shrink-0 bg-white shadow-xl lg:block">
        <div className="sticky top-14">
          <ViewerSidebar>
            <ViewerSidebar.Button
              onClick={() => setTocVisible(not)}
              active={true}
              aria-label={t('labels.journals.toc')}
              aria-haspopup="true"
              aria-expanded={tocVisible}
            >
              <ClipboardList className="w-5" /> {t('common.table-of-contents')}
            </ViewerSidebar.Button>
          </ViewerSidebar>
        </div>
      </div>
      <div className="z-10 hidden shrink-0 shadow-xl lg:block">
        <div
          className={
            tocVisible ? 'sticky top-14 w-72 overflow-auto px-6 py-4' : 'hidden'
          }
          style={{ height: 'calc(100vh - 7.5rem)' }} // 4 + 3.5 for lg screem
        >
          {tocRoot && (
            <ViewerTableOfContent
              root={tocRoot}
              activeId={tocActive?.id}
              onSelect={setTocActive}
              disableNavigation
            />
          )}
        </div>
      </div>
      <div className="flex-grow px-4 py-6 md:px-6 md:py-8">
        {content?.sections && (
          <ExpandableSectionList className="-my-6 md:-my-8">
            {content.sections.map((section, index) => {
              const { name, ...article } = section;
              return (
                <ExpandableSection key={index} title={name}>
                  <LibraryArticle article={article} />
                </ExpandableSection>
              );
            })}
          </ExpandableSectionList>
        )}
        {content?.references && (
          <LibraryReferences references={content?.references} />
        )}
        {content?.ranges && (
          <ExpandableSectionList className="-my-6 md:-my-8">
            {content.ranges.map((range, index) => (
              <ExpandableSection key={index} title={range.label}>
                <LibraryRange range={range} />
              </ExpandableSection>
            ))}
          </ExpandableSectionList>
        )}
        {content?.article && (
          <LibraryArticleWithSubarticles
            article={content.article}
            subarticles={subarticles}
            subarticleId={content.subarticleId}
          />
        )}
      </div>
    </div>
  );
}

export default TopicContent;
