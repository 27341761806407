import { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useContentOverflowScroll } from 'hooks';
import NoScrollContainer from 'components/NoScrollContainer';
import { ChevronLeft, ChevronRight } from 'components/icons';

const tabsNavClasses = 'absolute inset-0 w-10 h-full from-white via-white';

function TabsPrevBtn(props) {
  const { t } = useTranslation();
  return (
    <button
      className={classnames(tabsNavClasses, 'right-auto bg-gradient-to-r pr-4')}
      aria-label={t('labels.common.tabs-prev')}
      {...props}
    >
      <ChevronLeft />
    </button>
  );
}

function TabsNextBtn(props) {
  const { t } = useTranslation();
  return (
    <button
      className={classnames(tabsNavClasses, 'left-auto bg-gradient-to-l pl-4')}
      aria-label={t('labels.common.tabs-next')}
      {...props}
    >
      <ChevronRight />
    </button>
  );
}

function findActiveTabStart(tabRefs, tabKey) {
  if (!tabRefs.current) return;
  const activeTabRef = tabRefs.current?.find((tab) => tab?.id === tabKey);
  return activeTabRef?.offsetLeft;
}
function Tabs(props) {
  const {
    tabs,
    activeTab,
    onTabSelect,
    className,
    tabsContainerClassName,
    tabClassName,
    leftAlignTabs = true,
    wideTabs = false,
    fullWidth = false,
    tabAs: TabTag = 'button',
    children,
  } = props;

  const tabRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !tabRefs.current.includes(el)) {
      tabRefs.current.push(el);
    }
  };

  const [activeTabKey, setActiveTabKey] = useState(() => activeTab ?? '');

  const {
    containerRef,
    isBeginning,
    isEnd,
    scrollTo,
    scrollLeft,
    scrollRight,
  } = useContentOverflowScroll([tabs, activeTab]);

  useEffect(() => {
    setActiveTabKey(activeTab);
    scrollTo(findActiveTabStart(tabRefs, activeTab));
  }, [activeTab, scrollTo]);

  if (!Array.isArray(tabs)) {
    throw Error('tabs prop should be an array');
  }

  return (
    <div
      data-testid="tabsWrapper"
      className={classnames('relative w-full', className)}
    >
      <div className="relative">
        {!isBeginning && (
          <TabsPrevBtn onClick={scrollLeft} data-testid="tabScrollLeftBtn" />
        )}
        {!isEnd && (
          <TabsNextBtn onClick={scrollRight} data-testid="tabScrollRightBtn" />
        )}
        <NoScrollContainer
          role="tablist"
          data-full-size={fullWidth}
          data-left-align={leftAlignTabs}
          // using scroll snap + smooth scroll to focus on tab options. See tailwind scroll snap demos
          className={classnames(
            'flex snap-x snap-mandatory flex-nowrap items-center overflow-x-auto scroll-smooth',
            fullWidth && 'shadow-smd-tabs-full-width',
            tabsContainerClassName
          )}
          ref={containerRef}
        >
          {/* Using justify center in flex doesn't work well with horizontally overflowing content, so adding 2 <li> elements to 'hack' the centered Tabs */}
          {!leftAlignTabs && !wideTabs && (
            <div className="grow" data-testid="tabCenterPushStart" />
          )}
          {tabs.map(({ key, label, onSelect, path, ...tabProps }, index) => {
            const isActive = key === activeTabKey;
            const isFirst = index === 0;
            const firstTabStyles = isFirst &&
              leftAlignTabs && {
                paddingLeft: 0,
              };
            return (
              <TabTag
                type="button"
                key={key}
                id={key}
                role="tab"
                aria-selected={isActive}
                className={classnames(
                  'group cursor-pointer select-none snap-center whitespace-nowrap border-b-2 font-semibold focus-visible:outline-none',
                  TabTag !== 'button' && 'appearance-none',
                  isActive
                    ? 'border-smd-accent text-smd-accent'
                    : 'border-smd-gray-lighter text-smd-gray-dark',
                  tabClassName ?? 'py-4 px-6 text-smd-base',
                  wideTabs && 'grow text-center'
                )}
                style={firstTabStyles || {}}
                onClick={() => {
                  if (isActive) return;
                  setActiveTabKey(key);
                  onSelect ? onSelect?.(key, path) : onTabSelect?.(key, path);
                }}
                {...tabProps}
                ref={addToRefs}
              >
                <div className="smd-group-focus-visible-primary ml-2 inline-block rounded-sm">
                  {label}
                </div>
              </TabTag>
            );
          })}
          {/* See first <li> comment above */}
          {!leftAlignTabs && !wideTabs && (
            <div className="grow" data-testid="tabCenterPushEnd" />
          )}
        </NoScrollContainer>
      </div>
      <div>{children}</div>
    </div>
  );
}

const tabsBasePropTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ),
  activeTab: PropTypes.string,
  onTabSelect: PropTypes.func,
  className: PropTypes.string,
  tabClassName: PropTypes.string,
  leftAlignTabs: PropTypes.bool,
  fullWidth: PropTypes.bool,
  wideTabs: PropTypes.bool,
  children: PropTypes.element,
};

Tabs.propTypes = tabsBasePropTypes;

// ignoring onTabSelect for RouterTabs
function RouterTabs({ tabs, currentTab, onTabSelect, ...props }) {
  const location = useLocation();

  const activeTab =
    currentTab ??
    tabs.find(({ key, path, exact, strict }) =>
      matchPath(location.pathname, { path: path ?? key, exact, strict })
    )?.key;

  const routedTabs = tabs.map(({ path, key, ...rest }) => ({
    ...rest,
    path,
    key,
    to: path || key,
  }));

  return (
    <Tabs {...props} tabs={routedTabs} tabAs={Link} activeTab={activeTab} />
  );
}

RouterTabs.propTypes = {
  ...tabsBasePropTypes,
  currentTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ),
};

Tabs.Router = RouterTabs;

export default Tabs;
