export function getImageFileAsUrl(file) {
  return new Promise((resolve, reject) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.result);
      };
    } else {
      reject(file);
    }
  });
}

export const uploadFileValidatorFactory =
  (sizeInMB = defaultFileMaxSizeInMB, errorMessage) =>
  (value) => {
    if (!value || !value.size) return true;
    return value.size <= sizeInMB * 1024 * 1024
      ? true
      : errorMessage || `File should be smaller than ${sizeInMB} MB`;
  };

export const defaultFileMaxSizeInMB =
  process.env.REACT_APP_DEFAULT_FILE_MAX_SIZE_IN_MB;
