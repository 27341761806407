import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import { JournalCard } from 'journals/components';
import { GridView } from 'journals/history/components';
import { slugifyObject } from 'utils/slug';
import { useTopicLastLocation } from 'library/hooks';
import { useJournalLastLocation } from 'journals/hooks';
import { usePHRLinkage } from 'my-phr/components/PHRCard/hooks';

const SHOWN_TOPICS = 12;

function RelatedHealthTopics({ topics }) {
  const { t } = useTranslation();

  const { getLinkage } = usePHRLinkage();
  const { pushTopicLastLocation } = useTopicLastLocation();
  const { pushJournalLastLocation } = useJournalLastLocation();

  const [topicsShown, setTopicsShown] = useState(SHOWN_TOPICS);

  const paginatedRelatedTopics = topics?.slice(0, topicsShown);

  const hasNextPage = topicsShown < topics?.length;

  const showNext = () => {
    setTopicsShown((prev) => prev + SHOWN_TOPICS);
  };

  return (
    <>
      <h2 className="text-smd-h4 font-semibold md:px-smd-md md:text-smd-h4 lg:py-smd-lg lg:text-smd-h3">
        {t('journals.related.heading')}
      </h2>
      <GridView
        items={paginatedRelatedTopics}
        childComponent={(entry) => {
          const itemType = entry?.type?.toLowerCase();
          const { prefix } = getLinkage(itemType);
          const slug = entry.slug ?? slugifyObject(entry.id, entry.name);
          return (
            <div key={entry.id} className="smd-transform-duration">
              <Link
                to={`/${prefix}/${slug}`}
                onClick={
                  prefix === 'topic'
                    ? pushTopicLastLocation()
                    : pushJournalLastLocation()
                }
              >
                <JournalCard
                  preamble={{
                    title: entry.name,
                    about: entry.description,
                    heroes: [{ thumbnailUrl: entry.thumbnail }],
                    logos: entry.logos?.map((x) => ({ url: x })),
                  }}
                />
              </Link>
            </div>
          );
        }}
      />
      {hasNextPage && (
        <div className="flex justify-center">
          <Button onClick={showNext}>{t('common.show-more')}</Button>
        </div>
      )}
    </>
  );
}

export default RelatedHealthTopics;
