import PropTypes from 'prop-types';

const IMAGE = 'image';
const VIDEO = 'video';
const VIMEO = 'vimeo';
const YOUTUBE = 'youtube';
const DOCUMENT = 'document';
const INTERACTIVE = 'interactive';
const ARTICLE = 'all';

const shape = PropTypes.oneOf([
  IMAGE,
  VIDEO,
  VIMEO,
  YOUTUBE,
  DOCUMENT,
  INTERACTIVE,
]);

const defaults = {};

export default {
  shape,
  defaults,
  IMAGE,
  VIDEO,
  VIMEO,
  YOUTUBE,
  DOCUMENT,
  INTERACTIVE,
  ARTICLE,
};
