import classNames from 'classnames';
import { ChevronRight, UnorderedList } from 'components/icons';
import { useTranslation } from 'react-i18next';

export default function IndexButton({ onClick, className }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'h-18 relative mt-5 flex cursor-pointer flex-col items-center border border-smd p-4 text-smd-accent shadow-xl',
        className
      )}
      onClick={onClick}
    >
      <UnorderedList className="h-7 w-7 shrink-0 fill-smd-accent" />
      <span className="text-smd-xs">{t('common.index')}</span>
      <ChevronRight className="absolute -right-3 top-5 h-7 w-7 rounded-full border border-smd bg-white stroke-3 p-1 text-smd-accent shadow-md" />
    </div>
  );
}
