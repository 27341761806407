import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from 'Auth';
import { CTA, Modal } from 'components';
import { X } from 'components/icons';

function TrackJourneyModal({ isOpen, onClose }) {
  const { t } = useTranslation();
  const { authenticated } = useAuth();

  const TryComponent = authenticated ? Link : CTA;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div
        className="relative h-screen lg:h-[35rem] lg:max-w-2xl"
        role="dialog"
        aria-modal="true"
      >
        <div className="text-gray-darker flex h-full w-full grow flex-nowrap overflow-hidden bg-white lg:w-full">
          <img
            src="https://cdn.storymd.com/optimized/kAvL21ted1/original.jpg"
            className="hidden h-full w-64 shrink-0 object-cover lg:block"
            data-testid="newsletterModalHeroImage"
            alt="cover"
          />
          <div className="flex h-full grow flex-col lg:px-8 lg:pt-16 lg:pb-8">
            <div
              className="grow-0 bg-transparent p-4 text-smd-h3.5 font-semibold text-black lg:p-0"
              data-testid="newsletterModalTitle"
            >
              {t('journals.journeys.track.title')}
            </div>
            <p className="prose px-4 py-4 text-smd-sm leading-loose lg:px-0">
              <Trans
                i18nKey="journals.journeys.track.description"
                components={{ p: <p />, b: <b /> }}
              />
            </p>
            <div className="flex flex-col items-start p-4 lg:p-0">
              <TryComponent
                ctaProps={{ ctaAction: 'register' }}
                color="primary"
                toHealth
                to="/health/setup/profile"
                className="rounded-sm bg-smd-accent px-4 py-2 text-white"
              >
                {t('journals.journeys.track.try')}
              </TryComponent>
              <Link
                to="/health"
                className="py-4 text-smd-sm font-semibold text-smd-accent"
              >
                {t('common.learn-more')}
              </Link>
            </div>
            <p className="mt-auto px-4 text-smd-xs lg:pl-0 lg:pr-8">
              {t('journals.journeys.track.disclaimer')}
            </p>
          </div>
        </div>
        <button
          className="absolute right-2 top-4 cursor-pointer text-white lg:top-2 lg:text-black"
          onClick={onClose}
          aria-label={t('common.close')}
        >
          <X className="h-5 w-5 stroke-3" />
        </button>
      </div>
    </Modal>
  );
}

export default TrackJourneyModal;
