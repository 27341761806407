import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

export default function AdSense({
  adKey,
  slot,
  style,
  size,
  className,
  ...rest
}) {
  useEffect(() => {
    if (!slot || !adKey) return;
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    });
  }, [slot, adKey]);

  if (!slot || !adKey) return null;
  return (
    <div className={className}>
      <Helmet>
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adKey}`}
          crossorigin="anonymous"
        />
      </Helmet>
      <ins
        className={classnames('adsbygoogle', [
          '[&_ins]:!h-full [&_ins]:!w-full',
          '[&_iframe]:!h-full [&_iframe]:!w-full',
        ])}
        style={{
          display: 'block',
          ...style,
          ...size,
        }}
        data-ad-client={adKey}
        data-ad-slot={slot}
        // data-ad-format="auto"
        // data-full-width-responsive="true"
        {...rest}
      />
    </div>
  );
}
