import { useTranslation } from 'react-i18next';
import { Form } from 'components/forms';
import { useSearchSuggestions } from 'hooks';
import { STORYBLOCK_ENDPOINT } from 'my-phr/const';

import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import { useManualInputDefaultProps } from '../hooks';

const debounce = 0;

export function SelectSuggestionsInput({
  endpoint = STORYBLOCK_ENDPOINT,
  searchParams,
  ...rest
}) {
  const { t } = useTranslation();
  const newProps = useManualInputDefaultProps(rest);
  const { suggestions } = useSearchSuggestions(
    debounce,
    endpoint,
    searchParams
  );

  return (
    <Form.SearchDropdown
      options={suggestions}
      name={'suggestion'}
      placeholder={t('my-phr.manual-inputs.placeholders.measurement')}
      {...newProps}
    />
  );
}

manualInputsRegistry.register(
  MANUAL_INPUT_TYPE.SELECT_SUGGESTIONS,
  SelectSuggestionsInput
);
