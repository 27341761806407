import { useQuery } from 'react-query';
import { useLibraryApi } from 'library/libraryApiContext';

function useLibraryGlossary(params) {
  const api = useLibraryApi();

  const queryKey = ['libraryGlossary', params?.query, params?.type];
  const {
    data: glossary,
    isFetching: isFetchingGlossary,
    isFetched: isFetchedGlossary,
  } = useQuery(queryKey, () => api.getGlossaryBySearchTerm(params), {
    enabled: Boolean(params?.type) && Boolean(params?.query),
  });

  return { glossary, isFetchingGlossary, isFetchedGlossary };
}

export default useLibraryGlossary;
