import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'components/icons';

export const EXPAND_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

export const BACKGROUND = {
  LIGHT: 'light',
  DARK: 'dark',
};

const chevronClassName = 'm-2 inline h-4 w-4';

function Collapse({ direction }) {
  const { t } = useTranslation();
  return (
    <>
      {t('common.read-less')}
      {direction === EXPAND_DIRECTION.DOWN ? (
        <ChevronUp strokeWidth="2" className={chevronClassName} />
      ) : (
        <ChevronDown strokeWidth="2" className={chevronClassName} />
      )}
    </>
  );
}

function Expand({ direction }) {
  const { t } = useTranslation();
  return (
    <>
      {t('common.read-more')}

      {direction === EXPAND_DIRECTION.DOWN ? (
        <ChevronDown strokeWidth="2" className={chevronClassName} />
      ) : (
        <ChevronUp strokeWidth="2" className={chevronClassName} />
      )}
    </>
  );
}

export default function CaptionButton({
  expanded,
  onClick,
  inline,
  direction,
  background,
}) {
  return (
    <button
      onClick={onClick}
      className={classnames(
        'bg-transprent text-smd-sm font-semibold outline-none focus:outline-none',
        {
          'pl-3 pr-1 pb-4 pt-2': !inline,
          'flex-none': inline,
        },
        background === BACKGROUND.LIGHT
          ? 'text-smd-accent'
          : 'text-smd-accent-bright'
      )}
    >
      {expanded ? (
        <Collapse direction={direction} />
      ) : (
        <Expand direction={direction} />
      )}
    </button>
  );
}

CaptionButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};

CaptionButton.defaultProps = {
  inline: false,
};
