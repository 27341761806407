import { Form } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

export function TextInput(props) {
  const newProps = useManualInputDefaultProps(props);
  return <Form.Input {...newProps} />;
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.TEXT, TextInput);
