import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Spinner } from 'components';
import { Tick } from 'components/icons';
import { getPartAfterLastSlash } from 'utils';
import { FILE_STATUS } from './const';

function FileView({ status, attachment, className }) {
  const { t } = useTranslation();
  const inProgress = status !== FILE_STATUS.COMPLETED;
  const Icon = inProgress ? Spinner : Tick;

  const spinnerClassName = 'h-4 w-4 text-smd-accent';
  const completedClassName =
    'bg-smd-status-completed h-5 w-5 rounded-full p-1 text-white';

  return (
    <div
      className={classnames(
        'flex w-full items-center justify-between space-x-2 text-smd-accent',
        className
      )}
    >
      <div className="line-clamp-1">
        {attachment?.name ?? getPartAfterLastSlash(attachment?.storageKey)}
      </div>
      <div className="flex items-center space-x-2">
        <Icon className={inProgress ? spinnerClassName : completedClassName} />
        <span>{t(`my-phr.ocr.status.${status}`)}</span>
      </div>
    </div>
  );
}

export default FileView;
