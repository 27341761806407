import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import { getJournalSlugPath } from '../../utils';
import { AddToCollectionModal, SmartJournalCard } from '../../components';
import { AddToCollection, Remove } from '../../components/actions';
import GridView from './GridView';

function JournalsView({ journals, onDelete, hasFilter }) {
  const { t } = useTranslation();
  const [actionJournal, setActionJournal] = useState();

  return (
    <>
      {journals?.length || hasFilter ? (
        <GridView
          items={journals}
          childComponent={(entry) => (
            <div key={entry.id} className="smd-transform-duration">
              <SmartJournalCard
                actions={[
                  {
                    onClick: () => setActionJournal(entry),
                    element: <AddToCollection />,
                  },
                  {
                    onClick: () => onDelete(entry),
                    element: <Remove />,
                  },
                ]}
                journal={entry}
                to={getJournalSlugPath(entry)}
              />
            </div>
          )}
        />
      ) : (
        <>
          <p className="my-smd-lg max-w-xl text-smd-secondary">
            {t('journals.history.begin-hint')}
          </p>
          <Link to="/journeys">
            <Button.Primary>{t('journals.explore-library')}</Button.Primary>
          </Link>
        </>
      )}
      <AddToCollectionModal
        journal={actionJournal}
        onClose={() => setActionJournal()}
      />
    </>
  );
}

export default JournalsView;
