import { createContext, useContext, useState } from 'react';
import { AUTOCOMPLETE_TYPES } from './const';

const ManualInputsStateContext = createContext();
const ManualInputsDispatchContext = createContext();

const initialState = {
  libraryPickerOpen: false,
  libraryElements: [],
  inputValue: '',
  continueAdd: false,
  lastSavedDate: null,
  lastSavedType: null,
  showAlert: false,
  inputValuesByType: [],
};

function ManualInputsProvider({ payload, children }) {
  const [state, setState] = useState({
    inputType: payload?.inputType || AUTOCOMPLETE_TYPES.OBSERVATION,
    inputCategory: payload?.category || '',
    inputLabel: payload?.label || '',
    ...initialState,
  });

  function dispatch(action) {
    setState((prev) => ({
      ...prev,
      ...action,
    }));
  }

  const currentInputValue = state.inputValuesByType
    ?.filter((item) => item.inputType === state.inputType)
    .slice(-1);

  function setInputType(
    inputType,
    inputCategory = '',
    inputLabel,
    inputValue = ''
  ) {
    dispatch({ inputType, inputCategory, inputLabel, inputValue });
  }

  const methods = {
    onCategoryChange: setInputType,
    onSuggestionSelected: (suggestion, inputTypeOverride) =>
      setState((prevState) => {
        const inputType = inputTypeOverride || prevState.inputType;
        return {
          ...prevState,
          libraryElements: [],
          inputValue: suggestion,
          inputValuesByType: [
            ...prevState.inputValuesByType,
            { suggestion: suggestion, inputType },
          ],
        };
      }),
    setCategory: (category, label) => {
      dispatch({ inputCategory: category });
      dispatch({ inputLabel: label });
    },
    onSuggestionDeleted: (suggestion) => {
      setState((prevState) => {
        return {
          ...prevState,
          inputValuesByType: prevState.inputValuesByType.filter(
            (item) => item.suggestion !== suggestion
          ),
        };
      });
      dispatch({ libraryElements: [] });
      dispatch({ inputCategory: '' });
    },
    onTypeChange: (type, category, label) => {
      setInputType(type);
      dispatch({ inputValue: '' });
      dispatch({ inputCategory: category });
      dispatch({ inputLabel: label });
      dispatch({ currentInputValue });
      dispatch({ libraryElements: [] });
    },
    handleLibraryPickButtonClick: () => dispatch({ libraryPickerOpen: true }),
    onHide: () => dispatch({ libraryPickerOpen: false }),
    handleAttachmentDeletion: (deletedAssetId) =>
      dispatch({
        libraryElements: state.libraryElements.filter(
          ({ id }) => id !== deletedAssetId
        ),
      }),
    handleSelectedElements: (elements) => {
      dispatch({ libraryElements: elements });
      dispatch({ libraryPickerOpen: false });
    },
    setContinueAdd: (continueAdd) => dispatch({ continueAdd }),
    setLastSavedDate: (date) => dispatch({ lastSavedDate: date }),
    setLastSavedType: (type) => dispatch({ lastSavedType: type }),
    setShowAlert: (showAlert) => dispatch({ showAlert }),
  };

  return (
    <ManualInputsStateContext.Provider value={{ ...state }}>
      <ManualInputsDispatchContext.Provider value={{ ...methods }}>
        {children}
      </ManualInputsDispatchContext.Provider>
    </ManualInputsStateContext.Provider>
  );
}

export const useManualInputsStateContext = () => {
  const context = useContext(ManualInputsStateContext);

  if (!context) {
    throw new Error(
      'useManualInputsStateContext must be used within an ManualInputsStateProvider'
    );
  }
  return context;
};

export const useManualInputsDispatchContext = () => {
  const context = useContext(ManualInputsDispatchContext);

  if (!context) {
    throw new Error(
      'useManualInputsDispatchContext must be used within an ManualInputsDispatchProvider'
    );
  }
  return context;
};

export { ManualInputsProvider };
