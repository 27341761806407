import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { usePageTitle } from './hooks';
function SEOHead({ config = {} }) {
  const { meta, link, title } = config;
  const pageTitle = usePageTitle(title);
  return (
    <Helmet>
      {meta?.map((props, index) => (
        <meta key={index} {...props} />
      ))}
      {link?.map((props, index) => (
        <link key={index} {...props} />
      ))}
      <title>{pageTitle}</title>
    </Helmet>
  );
}

SEOHead.propTypes = {
  config: PropTypes.shape({
    meta: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  }),
};

export default SEOHead;
