import { forwardRef } from 'react';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'placeholder:text-smd-secondary',
  'smd-input-focus-primary',
];

export default forwardRef(function Input({ className, error, ...props }, ref) {
  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';
  const classes = base.concat(border, className).join(' ');

  return <input ref={ref} className={classes} type="text" {...props} />;
});
