import { useTranslation } from 'react-i18next';

import {
  ExpandableSection,
  ExpandableSectionList,
  LibraryReferences,
} from 'components';
import LibraryCarousel from 'components/LibraryCarousel';

import { LibraryRange } from '../../range';
import { LibraryArticle } from '../../article';
import LibraryTopicCover from '../LibraryTopicCover';

function LibraryTopic({ withLinks, topic }) {
  const { t } = useTranslation();

  return (
    <article className="space-y-smd-base text-smd-main">
      <LibraryTopicCover topic={topic} />

      {/* TODO: remove disclaimer from expandable list */}
      <ExpandableSectionList className="mt-6" disclaimer={topic?.disclaimer}>
        {topic?.ranges?.filter(Boolean).map((range, index) => (
          <ExpandableSection key={`${topic.id} ${index}`} title={range.label}>
            <LibraryRange withLinks={withLinks} range={range} />
          </ExpandableSection>
        ))}

        {topic.sections
          ?.filter((x) => x) // TODO: remove
          ?.map((section, index) => {
            const { name, ...article } = section;
            return (
              <ExpandableSection key={index} title={name}>
                <LibraryArticle withLinks={withLinks} article={article} />
              </ExpandableSection>
            );
          })}

        {topic.references && (
          <ExpandableSection title={t('library.topic.references')}>
            <LibraryReferences references={topic.references} />
          </ExpandableSection>
        )}
      </ExpandableSectionList>
      {topic?.linkages?.assets && (
        <LibraryCarousel
          config={{
            label: t('library.topic.additional-materials'),
            value: JSON.stringify(topic?.linkages?.assets),
          }}
        />
      )}
    </article>
  );
}

export default LibraryTopic;
