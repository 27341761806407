import React, { useEffect, useState } from 'react';

import { replaceSupTag } from 'utils';
import { Form } from 'components/forms';

import { ControlledCustomSelectInput } from 'my-phr/layout/modules/ManualInputs/inputs/CustomSelectInput';
import { useManualInputDefaultProps } from '../hooks';

export function SelectUnitInput(props) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8',
  });

  const defaultValue =
    newProps?.defaultValues?.[newProps?.name] || props.options?.[0]?.value;

  return (
    <Form.Select
      {...newProps}
      disabled={props.options?.length === 1}
      defaultValue={defaultValue}
      constorllerDefaultValue={defaultValue}
      as={SelectUnitControlled}
    />
  );
}

function SelectUnitValueComponent({ value, options = [] }) {
  const [resultLabels, setResultLabels] = useState({});

  useEffect(() => {
    const labels = options.reduce((acc, { value, label }) => {
      acc[value] = replaceSupTag(label);
      return acc;
    }, {});
    setResultLabels(labels);
  }, [options]);

  return <>{resultLabels[value]}</>;
}

function SelectUnitItemComponent({ option = {} }) {
  return (
    <>
      <div>{replaceSupTag(option.label)}</div>
      <div className="text-xs text-smd-gray-dark">{option.tooltip}</div>
    </>
  );
}

function SelectUnitControlled(props) {
  return (
    <ControlledCustomSelectInput
      name={props.name}
      control={props.control}
      disabled={props.disabled}
      options={props.options}
      defaultValue={props.constorllerDefaultValue}
      ItemComponent={SelectUnitItemComponent}
      ValueComponent={SelectUnitValueComponent}
    />
  );
}
