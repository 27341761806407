import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { spacing } from 'tailwindcss/defaultTheme';

import { useAds, useMedia } from 'hooks';
import { ChevronLeft, X as Close } from 'components/icons';

function ViewerAsideNav({ className, style, onClose, children }, ref) {
  const { t } = useTranslation();

  const isLarge = useMedia(useMedia.LARGE);
  const topAd = useAds(['placements', 'journal', 'page', 'top']);

  return (
    <div
      className={classnames(
        'h-full overflow-auto overflow-x-hidden',
        className
      )}
      style={
        style ?? {
          maxHeight: isLarge
            ? topAd
              ? `calc(100vh - calc(${spacing[52]} + ${spacing[3]}))`
              : `calc(100vh - ${spacing[28]})`
            : 'unset',
        }
      }
      ref={ref}
    >
      <div className="h-full w-72 overflow-auto px-6 py-4 lg:w-80">
        <div className="mb-4 flex justify-between lg:hidden">
          <span className="text-sm font-semibold text-smd-accent ">
            {t('common.table-of-contents')}
          </span>
          <button onClick={onClose} aria-label={t('common.close')}>
            <Close className="h-5 w-5 text-smd-secondary" />
          </button>
        </div>
        {children}
      </div>
      <button
        className="smd-focus-visible-primary absolute z-10 hidden h-7 w-7 rounded-full border border-smd bg-white p-1 text-smd-accent shadow-md lg:block"
        style={{ top: '1rem', right: '-1rem' }}
        onClick={onClose}
        aria-label={t('labels.journals.hide-toc')}
      >
        <ChevronLeft strokeWidth="3" className="h-4 w-4" />
      </button>
    </div>
  );
}

export default forwardRef(ViewerAsideNav);
