import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { LoadingOverlay } from 'components';
import { useQueryClient } from 'react-query';
import { communitiesKeys } from 'communities/hooks/keys';
import {
  useHJConversationCreate,
  useHJHasExistingCommunity,
  useHJContainerHasExistingConversation,
  useResetQueries,
  useHJCommunity,
  useHJContainerConversation,
  useCommunityLastLocation,
} from 'communities/hooks';
import { useAuth } from 'Auth';
import CreateConversationModal from 'communities/CreateConversationModal';
import JournalPropTypes from '../../propTypes/channel';
import SeeConversation from './SeeConversation';
import StartConversation from './StartConversation';

export default function ConversationBlock({
  journal,
  container: { key: containerKey },
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { journalKey } = journal;
  const { authenticated } = useAuth();
  const { resetMyCommunities } = useResetQueries();
  const { pushCommunityLastLocation } = useCommunityLastLocation();

  const { createConversationByHJID, isLoading: isCreatingConversation } =
    useHJConversationCreate({
      enabled: authenticated,
      onSuccess: (createdConversation) => {
        const { categoryId, topicId } = createdConversation;
        queryClient.invalidateQueries(
          communitiesKeys.healthJournalContainerConversation(
            journalKey,
            containerKey
          )
        );
        resetMyCommunities();
        pushCommunityLastLocation({
          args: { backButtonLabel: t('communities.back-to-hj') },
        });
        history.push(`/communities/${categoryId}/conversation/${topicId}`);
      },
    });

  const { data: hasCommunity } = useHJHasExistingCommunity(journalKey, {
    enabled: authenticated,
  });

  const { data: hasConversation } = useHJContainerHasExistingConversation(
    journalKey,
    containerKey,
    {
      enabled: authenticated && Boolean(hasCommunity),
      retry: false,
    }
  );

  const { data: existingCommunity } = useHJCommunity(journalKey, {
    enabled: authenticated && Boolean(hasCommunity),
    retry: false,
  });

  const {
    data: existingConversation,
    isFetched: conversationIsFetched,
    isFetching: isFetchingConversation,
  } = useHJContainerConversation(journalKey, containerKey, {
    enabled: authenticated && Boolean(hasConversation),
    retry: false,
  });

  if (authenticated && !hasCommunity) {
    return null;
  }

  const defaultValues = {};

  if (journal?.name) {
    defaultValues.tags = [journal.name];
  }

  const showStartConversationBlock =
    hasCommunity && existingCommunity && !hasConversation;
  const showSeeConversationBlock =
    hasConversation && hasCommunity && existingConversation;
  return (
    <>
      <LoadingOverlay
        isLoading={!conversationIsFetched && isFetchingConversation}
        className="bg-smd-accent-light"
      >
        <div
          className={classNames('flex w-full p-3', {
            'flex-col xl:flex-row xl:items-center':
              showStartConversationBlock || !authenticated,
            'flex-row items-center': showSeeConversationBlock,
          })}
        >
          {!authenticated && <StartConversation />}
          {showStartConversationBlock && (
            <StartConversation onButtonClick={() => setModalOpen(true)} />
          )}
          {showSeeConversationBlock && (
            <SeeConversation {...existingConversation} />
          )}
        </div>
      </LoadingOverlay>
      <CreateConversationModal
        isOpen={modalOpen}
        isSubmitButtonLoading={isCreatingConversation}
        onSubmit={(values) =>
          createConversationByHJID({ journalKey, containerKey, values })
        }
        defaultValues={defaultValues}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

ConversationBlock.propTypes = {
  journal: JournalPropTypes.shape,
};
