import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import Error from 'Error';

import { ShareModal } from 'components/Share';

import { getSlugHead } from 'utils/slug';
import { useStoryLastLocation } from 'library/hooks';

import LibraryStoryViewerPage from './LibraryStoryViewerPage';

function LibraryStoryViewerRoutedPage() {
  const history = useHistory();

  const { hashId: humanReadableHashId } = useParams();
  const hashId = getSlugHead(humanReadableHashId);

  const { popStoryLastLocation } = useStoryLastLocation({
    onEvent: ({ method, lastLocation }) => {
      if (method === 'pop') {
        history.push(lastLocation);
      }
    },
  });

  const [shareModal, setShareModal] = useState(false);

  const handleOnShare = () => setShareModal(true);

  return (
    <>
      <LibraryStoryViewerPage
        hashId={hashId}
        onShare={handleOnShare}
        onClose={popStoryLastLocation}
      />
      <ShareModal
        isOpen={shareModal}
        onClose={() => setShareModal(false)}
        shareUrl={window.location.href}
      >
        {/* Add content */}
      </ShareModal>
    </>
  );
}

function ErrorFallback() {
  const { t } = useTranslation();
  return <Error buttonLabel={t('common.back-to-homepage')} />;
}

export default withErrorBoundary(LibraryStoryViewerRoutedPage, {
  fallback: <ErrorFallback />,
});
