import { MutableRefObject } from 'react';

import {
  EGuideStepAlign,
  EGuideStepAlignDirection,
  EGuideStepPosition,
  TGuideStep,
  TGuideStepDirection,
} from 'components/Guide/types';
import { useMedia } from 'hooks';

export function setGuideStepPosition(
  stepPosition: EGuideStepPosition,
  tooltipRef: MutableRefObject<HTMLElement>,
  rect: DOMRect
) {
  switch (stepPosition) {
    case EGuideStepPosition.TOP:
      tooltipRef.current.style.bottom = `${
        rect.height + 30 + (window?.scrollY || 0)
      }px`;
      break;
    case EGuideStepPosition.BOTTOM:
      tooltipRef.current.style.bottom = 'auto';
      tooltipRef.current.style.top = `${
        rect.bottom + 20 + (window?.scrollY || 0)
      }px`;
      break;
    case EGuideStepPosition.LEFT:
      tooltipRef.current.style.left = `${rect.left - 200}px`;
      break;
    case EGuideStepPosition.RIGHT:
      tooltipRef.current.style.left = `${rect.right + 20}px`;
      break;
    default:
      // copy of right
      tooltipRef.current.style.left = `${rect.right + 20}px`;
      console.warn('Invalid position');
      break;
  }
}

export function setGuideStepAlign(
  stepAlign: EGuideStepAlign,
  stepPosition: EGuideStepPosition,
  tooltipRef: MutableRefObject<HTMLElement>,
  rect: DOMRect
) {
  const direction = getGuideStepAlignDirection(stepPosition);

  if (direction === null) {
    console.warn('Invalid position');
    return;
  }

  if (direction === EGuideStepAlignDirection.HORIZONTAL) {
    switch (stepAlign) {
      case EGuideStepAlign.START:
        tooltipRef.current.style.left = `${rect.left}px`;
        break;
      case EGuideStepAlign.CENTER:
        tooltipRef.current.style.left = `${rect.left}px`;
        break;
      case EGuideStepAlign.END:
        tooltipRef.current.style.right = `${window.innerWidth - rect.right}px`;
        tooltipRef.current.style.left = 'auto';
        break;
      default:
        // copy of start
        tooltipRef.current.style.left = `${rect.left}px`;
        console.warn('Invalid align');
        break;
    }
  }

  if (direction === EGuideStepAlignDirection.VERTICAL) {
    switch (stepAlign) {
      case EGuideStepAlign.START:
        tooltipRef.current.style.top = `${rect.top}px`;
        break;
      case EGuideStepAlign.CENTER:
        tooltipRef.current.style.top = `${rect.top}px`;
        break;
      case EGuideStepAlign.END:
        tooltipRef.current.style.top = `${rect.top}px`;
        break;
      default:
        // copy of start
        tooltipRef.current.style.top = `${rect.top}px`;
        console.warn('Invalid align');
        break;
    }
  }
}

const horizontalAlignmentPositions = [
  EGuideStepPosition.TOP,
  EGuideStepPosition.BOTTOM,
];
const verticalAlignmentPositions = [
  EGuideStepPosition.LEFT,
  EGuideStepPosition.RIGHT,
];

export function getGuideStepAlignDirection(
  stepPosition: EGuideStepPosition
): TGuideStepDirection {
  if (horizontalAlignmentPositions.includes(stepPosition)) {
    return EGuideStepAlignDirection.HORIZONTAL;
  } else if (verticalAlignmentPositions.includes(stepPosition)) {
    return EGuideStepAlignDirection.VERTICAL;
  } else {
    return null;
  }
}

export function usePosition(step: TGuideStep): EGuideStepPosition | undefined {
  const isXSmallScreen = useMedia(useMedia.XSMALL);
  const isSmallScreen = useMedia(useMedia.SMALL);
  const isMediumScreen = useMedia(useMedia.MEDIUM);
  const isLargeScreen = useMedia(useMedia.LARGE);
  const isXLargeScreen = useMedia(useMedia.XLARGE);
  const isXXLargeScreen = useMedia(useMedia.XXLARGE);

  if (!step) {
    return undefined;
  } else if (isXXLargeScreen && step.position.xxl) {
    return step.position.xxl;
  } else if (isXLargeScreen && step.position.xl) {
    return step.position.xl;
  } else if (isLargeScreen && step.position.lg) {
    return step.position.lg;
  } else if (isMediumScreen && step.position.md) {
    return step.position.md;
  } else if (isSmallScreen && step.position.sm) {
    return step.position.sm;
  } else if (isXSmallScreen && step.position.xs) {
    return step.position.xs;
  } else {
    return step.position.DEFAULT;
  }
}
