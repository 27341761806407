import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import {
  GuideModal,
  GuideTooltip,
  setGuideStepAlign,
  setGuideStepPosition,
  usePosition,
} from 'components/Guide';
import { useCompletedGuides, useUpdateGuides } from 'my-phr/hooks/guides';
import { usePublicResource } from 'hooks';
import { useAuth } from 'Auth';
import { cn } from 'lib/utils';

import {
  EGuideNames,
  EGuideStepType,
  TGuideContext,
  TGuideStep,
  TUseGuideReturn,
} from './types';

const GuideStepComponents = {
  [EGuideStepType.TOOLTIP]: GuideTooltip,
  [EGuideStepType.MODAL]: GuideModal,
};

const GuideContext = createContext<TGuideContext | null>(null);

export function GuideProvider({ children }) {
  const [hasStartedGuide, setHasStartedGuide] = useState(false);
  const location = useLocation();
  const { authenticated } = useAuth();
  const { mutate: updateCompletedGuides } = useUpdateGuides();
  const [currentGuide, setCurrentGuide] = useState<TGuideStep[] | null>(null);
  const [guideName, setGuideName] = useState<string | null>(null);
  const [guideLocation, setGuideLocation] = useState<string | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isClosed, setIsClosed] = useState(false);

  const { data: completedGuides } = useCompletedGuides({
    enabled: authenticated,
  });
  const { data: availableGuides = {}, isFetched } = usePublicResource(
    '/guides.json',
    {
      enabled: authenticated,
    }
  );
  const [isCompleted, setIsCompleted] = useState(false);

  const currentStep = currentStepIndex + 1;
  const tooltipRef = useRef<HTMLElement | null>(null);

  const step = currentGuide?.[currentStepIndex];
  const position = usePosition(step);
  const targetId = step?.targetId;
  const target = useMemo(() => {
    if (!currentGuide) {
      return null;
    }

    return targetId ? document.getElementById(targetId) : null;
  }, [currentGuide, targetId]);

  const rect = target?.getBoundingClientRect();

  useEffect(() => {
    setIsClosed(false);
  }, [location]);

  useEffect(() => {
    if (guideLocation !== location.pathname) {
      setCurrentGuide(null);
    }
  }, [location, guideLocation]);

  useEffect(() => {
    if (!currentGuide) {
      setCurrentStepIndex(0);
    }
    setHasStartedGuide(Boolean(currentGuide));
  }, [currentGuide, setHasStartedGuide]);

  useEffect(() => {
    if (tooltipRef.current && target) {
      setGuideStepPosition(position, tooltipRef, rect);
      setGuideStepAlign(step?.align, position, tooltipRef, rect);
    }
  }, [rect, target, step?.align, position]);

  const getGuideState = useCallback(
    (guideName: string, pathname: string) => {
      setIsCompleted(Boolean(completedGuides?.[guideName]));
      setGuideName(guideName);
      setGuideLocation(pathname);
    },
    [completedGuides]
  );

  const startGuide = useCallback(() => {
    const guide = availableGuides?.guides?.[guideName];
    if (guide && !isCompleted && !isClosed) {
      setCurrentGuide(guide);
    }
  }, [availableGuides, guideName, isCompleted, isClosed]);

  const contextValue = useMemo(
    () => ({
      getGuideState,
      startGuide,
      isCompleted,
      isReady: isFetched,
    }),
    [getGuideState, startGuide, isCompleted, isFetched]
  );

  const goToNextStep = useCallback(() => {
    setCurrentStepIndex((prevStep) => prevStep + 1);
  }, []);

  function closeGuide() {
    setIsClosed(true);
    setCurrentGuide(null);
    setHasStartedGuide(false);
  }

  function completeGuide() {
    updateCompletedGuides({
      ...completedGuides,
      [guideName]: true,
    });
    closeGuide();
  }

  const imageAsset = availableGuides?.assets?.[step?.imageKey];
  const videoAsset = availableGuides?.assets?.[step?.videoKey];

  if (!target) {
    targetId && console.warn(`Target id ${targetId} not found`);
  }

  const GuideComponent = GuideStepComponents[step?.type];

  return (
    <GuideContext.Provider value={contextValue}>
      <div
        className={cn(
          'relative flex h-screen flex-col',
          hasStartedGuide && 'overflow-hidden'
        )}
      >
        {children}
        {currentGuide && (
          <GuideComponent
            currentStep={currentStep}
            goToNextStep={goToNextStep}
            closeGuide={closeGuide}
            completeGuide={completeGuide}
            imageAsset={imageAsset}
            videoAsset={videoAsset}
            step={step}
            ref={tooltipRef}
            guideLength={currentGuide?.length}
            position={position}
            align={step.align}
          />
        )}

        {currentGuide && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50" />
        )}
      </div>
    </GuideContext.Provider>
  );
}

export function useGuide(guideKey: EGuideNames): TUseGuideReturn {
  const context = useContext(GuideContext);
  const { pathname } = useLocation();

  useEffect(() => {
    context.getGuideState(guideKey, pathname);
  }, [guideKey, context, pathname]);

  if (!context) {
    throw new Error('useGuide must be used within a GuideProvider');
  }

  return {
    startGuide: context.startGuide,
    isCompleted: context.isCompleted,
    isReady: context.isReady,
  };
}
