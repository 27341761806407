import api, { publicApi } from 'api/axios';
import { prop } from 'ramda';

const DEFINITIONS = 'definitions';
const CONTAINERS = 'containers';
const JOURNALS = 'journals';

const buildDefinitionsApi = (api) => ({
  getAllDefinitions() {
    return api.get(`/${DEFINITIONS}`).then(prop('data'));
  },
  getRecentDefinitions() {
    return api.get(`/${DEFINITIONS}/recent`).then(prop('data'));
  },
  getDefinitionByReferralCode(referralCode) {
    return api
      .get(`/${DEFINITIONS}/search`, { params: { referralCode } })
      .then(prop('data'))
      .catch(({ response }) => Promise.reject(response.data));
  },
  getDefinitionByLinkId(journalLinkId) {
    return api.get(`/${DEFINITIONS}/poc/${journalLinkId}`).then(prop('data'));
  },
  getDefinitionTocByLinkId(journalLinkId) {
    return api
      .get(`/${DEFINITIONS}/poc/${journalLinkId}/toc`)
      .then(prop('data'));
  },
  getDefinitionContainerByLinkId(journalLinkId, containerKey) {
    return api
      .get(`/${DEFINITIONS}/poc/${journalLinkId}/${CONTAINERS}/${containerKey}`)
      .then(prop('data'));
  },
  getDefinitionUniqueLink(journalKey) {
    return api.get(`/${DEFINITIONS}/links/${journalKey}`).then(prop('data'));
  },
  generateDefinitionUniqueLink(journalKey) {
    return api.post(`/${DEFINITIONS}/links/${journalKey}`).then(prop('data'));
  },
  getDefinitionByKey(journalKey) {
    return api.get(`/${DEFINITIONS}/${journalKey}`).then(prop('data'));
  },
  getDefinitionTocByKey(journalKey) {
    return api
      .get(`/${DEFINITIONS}/${journalKey}/toc`)
      .then(prop('data'))
      .catch(({ response }) => Promise.reject(response.data));
  },
  getDefinitionContainerByKey(journalKey, containerKey) {
    return api
      .get(`/${DEFINITIONS}/${journalKey}/${CONTAINERS}/${containerKey}`)
      .then(prop('data'));
  },
});

const buildJournalsApi = (api) => ({
  getAllJournals() {
    return api.get(`/${JOURNALS}`).then(prop('data'));
  },
  getJournalByKey(journalKey) {
    return api.get(`/${JOURNALS}/${journalKey}`).then(prop('data'));
  },
  getJournalTocByKey(journalKey) {
    return api.get(`/${JOURNALS}/${journalKey}/toc`).then(prop('data'));
  },
  getJournalContainerByKey(journalKey, containerKey) {
    return api
      .get(`/${JOURNALS}/${journalKey}/${CONTAINERS}/${containerKey}`)
      .then(prop('data'));
  },
  markJournalContainerAsVisited(journalKey, containerKey) {
    return api
      .put(`/${JOURNALS}/${journalKey}/${CONTAINERS}/${containerKey}/visited`)
      .then(prop('data'));
  },
  markJournalContainerAsVisitedByLinkId(journalLinkId, containerKey) {
    return api
      .put(
        `/${JOURNALS}/poc/${journalLinkId}/${CONTAINERS}/${containerKey}/visited`
      )
      .then(prop('data'));
  },
  deleteJournalById(journalId) {
    return api.delete(`/${JOURNALS}/${journalId}`).then(prop('data'));
  },
  addJournalAnalyticsEntry(journalKey) {
    return api.post(`/poc/audit/${journalKey}`).then(prop('data'));
  },
});

const buildCategorizedApi = (api) => ({
  getAllJournalsGroupedByCategory({ page, size } = {}) {
    return api
      .get('/category-membership', { params: { page, size } })
      .then(prop('data'));
  },
  getAllPOCJournalsGroupedByCategory({ page, size } = {}) {
    return api
      .get('/category-membership/poc', { params: { page, size } })
      .then(prop('data'));
  },
});

const buildGuidesApi = (api) => ({
  getCompletedGuides() {
    return api.get('/guides').then(({ data }) => data);
  },
  updateGuides(data) {
    return api
      .put('/guides', { completedGuides: JSON.stringify(data) })
      .then(({ data }) => data);
  },
});

const buildApi = (api) => ({
  ...buildDefinitionsApi(api),
  ...buildJournalsApi(api),
  ...buildCategorizedApi(api),
  ...buildGuidesApi(api),
});

export const JournalsApi = buildApi(api);

export const JournalsPublicApi = buildApi(publicApi);
