import { Skeleton } from 'components';

export default function NotificationSkeleton() {
  return (
    <div className="flex w-full flex-col">
      <Skeleton.Text className="w-full" lines={2} trimLastLine />
      <Skeleton.Text className="w-1/3" lines={1} fontSize={10} />
    </div>
  );
}
