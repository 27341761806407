import { useMedia } from 'hooks';

const NONCONTENT_AREA_HEIGHTS = {
  MOBILE_LANDSCAPE: 76,
  DESKTOP_TALL: 136,
  DESKTOP_SHORT: 96,
};

function useTopBottomBarHeight({ hasExpandableCaption = false }) {
  const isLarge = useMedia(useMedia.LARGE);
  const isLandscape = useMedia(useMedia.LANDSCAPE);
  const isSmallAndLandscape = !isLarge && isLandscape;

  const bottomTopBarHeight = isSmallAndLandscape
    ? NONCONTENT_AREA_HEIGHTS.MOBILE_LANDSCAPE
    : hasExpandableCaption
    ? NONCONTENT_AREA_HEIGHTS.DESKTOP_TALL
    : NONCONTENT_AREA_HEIGHTS.DESKTOP_SHORT;
  return bottomTopBarHeight;
}

export default function Element(props) {
  const bottomTopBarHeight = useTopBottomBarHeight(props);
  return (
    <div
      style={{
        maxHeight: `calc(100vh - ${bottomTopBarHeight}px)`,
        height: '100%',
      }}
      className="flex-center overflow-auto"
    >
      {props.children}
    </div>
  );
}
