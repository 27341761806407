import { useMemo, useEffect, useRef } from 'react';
import { isEmpty } from 'ramda';
import { useResolveUrl } from 'UrlResolverContext';
import { ChevronUp, ChevronDown } from 'components/icons';

import classes from './Toc.module.css';
import TocButton, {
  Completed,
  ExpandHandle,
  TocButtonSecondary,
  TocLink,
} from './TocButton';

export default function TocNode({
  node,
  onSelect,
  activeId,
  secondaryActiveId,
  openIds,
  completeIds,
  disableNavigation,
}) {
  const buttonRef = useRef();
  useEffect(() => {
    if (activeId === node.id && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [node, activeId]);

  const open = openIds.includes(node.id);
  const active = activeId === node.id;
  const secondaryActive = secondaryActiveId === node.id;
  const completed = completeIds.includes(node.id);

  let className = '';
  if (node.children) {
    className = open ? classes.open : classes.closed;
  }

  const pageUrl = useResolveUrl(node);

  const [ButtonComponent, buttonProps] = useMemo(
    () =>
      pageUrl && !disableNavigation
        ? [(props) => <TocLink active={active} {...props} />, { to: pageUrl }]
        : secondaryActive
        ? [
            (props) => (
              <TocButtonSecondary active={secondaryActive} {...props} />
            ),
          ]
        : [(props) => <TocButton active={active} {...props} />],
    [pageUrl, disableNavigation, active, secondaryActive]
  );

  return (
    <li key={node.id} className={className}>
      <ButtonComponent
        {...buttonProps}
        ref={buttonRef}
        onClick={() => onSelect(node)}
      >
        {node.label}
        {node.children && !isEmpty(node.children) && (
          <ExpandHandle active={active}>
            {open ? (
              <ChevronUp strokeWidth="4" />
            ) : (
              <ChevronDown strokeWidth="4" />
            )}
          </ExpandHandle>
        )}
        {completed && <Completed />}
      </ButtonComponent>
      {open && (
        <ul>
          {node.children.map((child) => (
            <TocNode
              key={child.id}
              node={child}
              onSelect={onSelect}
              activeId={activeId}
              openIds={openIds}
              completeIds={completeIds}
              secondaryActiveId={secondaryActiveId}
            />
          ))}
        </ul>
      )}
    </li>
  );
}
