import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { getDistributionConfig } from 'distribution';
import { Logo } from 'components/icons';

function MenuLogo({ className, isScrolled }) {
  const { t } = useTranslation();
  const mainLogo = useMemo(() => getDistributionConfig(['mainLogo']), []);
  const shortLogo = useMemo(() => getDistributionConfig(['shortLogo']), []);

  const showShortLogo = isScrolled && Boolean(shortLogo?.src);

  return (
    <Link
      to="/"
      className="smd-focus-visible-primary rounded-sm p-2"
      aria-label={t('labels.common.go-to-landing')}
      data-testid="menuStoryMdLogo"
    >
      {mainLogo?.src ? (
        <img
          className={classnames(className, 'sm:pl-6')}
          src={showShortLogo ? shortLogo.src : mainLogo.src}
          alt={showShortLogo ? shortLogo.alt : mainLogo.alt ?? 'logo'}
        />
      ) : (
        <Logo
          className={classnames(className, !isScrolled && 'lg:pt-2')}
          style={{
            transform: 'translateY(-13%)',
          }}
        />
      )}
    </Link>
  );
}

export default MenuLogo;
