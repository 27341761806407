import { useOnEsc, useOutsideClick } from 'hooks';

function useMenuRefs(state, dispatch) {
  const {
    isBetaOpen,
    isUserOpen,
    isNotificationOpen,
    isListOpen,
    isLargeScreen,
  } = state;

  const betaDropdownRef = useOutsideClick(() => {
    if (isBetaOpen && isLargeScreen) {
      dispatch({ type: 'close' });
    }
  });

  const userMenuRef = useOutsideClick(() => {
    if (isUserOpen && isLargeScreen) {
      dispatch({ type: 'close' });
    }
  });

  const notificationsRef = useOutsideClick(() => {
    if (isNotificationOpen && isLargeScreen) {
      dispatch({ type: 'close' });
    }
  });

  const listMenuRef = useOutsideClick(() => {
    if (isListOpen && isLargeScreen) {
      dispatch({ type: 'close' });
    }
  });

  useOnEsc(() => {
    if (isListOpen) {
      dispatch({ type: 'close' });
    }
  });

  return {
    betaDropdownRef,
    userMenuRef,
    notificationsRef,
    listMenuRef,
  };
}

export default useMenuRefs;
