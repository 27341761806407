import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRandomTip } from 'hooks';
import { Modal } from '../components';
import { SquareButton } from '../components/forms';
import { X, Heart } from '../components/icons';

const TipContainer = (tip) => {
  const { t } = useTranslation();
  return (
    <div key={tip} className="flex px-3 py-4 md:p-6">
      <Heart
        width="24"
        height="16"
        strokeWidth="2"
        className="mt-1 inline-block flex-none stroke-smd-accent text-white"
      />
      <span className="ml-2 flex-grow text-sm  font-semibold md:text-base">
        {t(`communities.conversation-tip.${tip}`)}
      </span>
    </div>
  );
};
export default function ConversationTipModal({ isOpen, onClose }) {
  const headerStyles = {
    backgroundImage:
      'url("https://cdn.storymd.com/optimized/1dmYkXcLdp/original.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '165px',
    backgroundSize: 'cover',
  };
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.ResponsiveContainer
        className="relative flex-col bg-white md:my-20 md:max-w-5xl md:bg-transparent"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex-col-center w-screen bg-white md:w-full md:max-w-3xl">
          <div className="relative flex w-full flex-row bg-smd-accent p-4 md:hidden">
            <p className="h-full flex-grow items-center text-smd-base font-semibold text-white">
              {t('communities.community-terms')}
            </p>
            <button
              onClick={onClose}
              className="appearance-none"
              aria-label={t('labels.common.close')}
            >
              <X className="h-6 w-6 text-white" strokeWidth="2" />
            </button>
          </div>

          <div style={headerStyles} className="h-20 w-full" />

          <div className="hidden flex-col px-20 py-10  md:flex">
            <div className="self-center px-4 font-sans text-2xl font-bold text-smd-gray-dark">
              STORY
              <span className="text-2xl font-bold text-smd-accent">MD</span>
            </div>
            <div className="my-4 w-3/4 self-center border border-smd-accent opacity-60" />
            <div className="flex flex-col self-center p-4 text-center">
              <span className="self-center text-xl font-semibold text-smd-gray-darker">
                {t('communities.community-terms')}
              </span>
              <span className="self-center pt-4 font-light text-smd-gray-dark">
                {t('communities.read-tc-cta')}
              </span>
            </div>
          </div>

          <div className="px-5 py-5 md:hidden">
            <span className="font-light text-smd-gray-dark">
              {t('communities.read-tc-cta')}
            </span>
          </div>
          <div className="divide-y divide-solid divide-smd-gray-lighter px-5 md:px-20">
            {useRandomTip.TIPS.map((tip) => TipContainer(tip))}
          </div>

          <div className="m-10 flex shrink-0 items-center">
            <label className="text-smd-gray-darker" htmlFor="agreed">
              {t('communities.review-terms')}{' '}
              <Link
                to="/account/legal"
                className="font-normal text-smd-accent underline"
              >
                {t('communities.community-rules')}
              </Link>
            </label>
          </div>
        </div>
        <SquareButton
          inverse
          className="absolute top-4 right-4 hidden md:block"
          onClick={onClose}
          aria-label={t('labels.common.close')}
        >
          <X strokeWidth="3" />
        </SquareButton>
      </Modal.ResponsiveContainer>
    </Modal>
  );
}
