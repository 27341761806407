import { useCallback, useEffect, useRef, useState } from 'react';

const INACTIVITY_INTERVAL = 1000 * 60 * 30; // 30min

function usePageInactivity(inactivityInterval = INACTIVITY_INTERVAL) {
  const lastInteractionTimestampRef = useRef(Date.now());
  const [update, setUpdate] = useState({ count: 0 });

  const handleInteractionChange = useCallback(() => {
    const now = Date.now();
    if (lastInteractionTimestampRef.current + inactivityInterval < now) {
      setUpdate((prev) => ({ count: prev.count + 1 }));
    }
    lastInteractionTimestampRef.current = now;
  }, [inactivityInterval]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleInteractionChange);
    document.addEventListener('click', handleInteractionChange);
    return () => {
      document.removeEventListener('visibilitychange', handleInteractionChange);
      document.removeEventListener('click', handleInteractionChange);
    };
  }, [handleInteractionChange]);

  return update;
}

export default usePageInactivity;
