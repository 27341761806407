import { useJournalLastLocation } from 'journals/hooks';
import { useAssetLastLocation, useTopicLastLocation } from 'library/hooks';

interface Linkage {
  prefix: string;
  onClick: Function;
}

export function usePHRLinkage() {
  const { setJournalLastLocation } = useJournalLastLocation();
  const { setTopicLastLocation } = useTopicLastLocation();
  const { setAssetLastLocation } = useAssetLastLocation();
  const assetLinkage = {
    prefix: 'asset',
    onClick: setAssetLastLocation,
  };
  const linkages = {
    healthtopic: {
      prefix: 'journal',
      onClick: setJournalLastLocation,
    },
    healthTopic: {
      prefix: 'journal',
      onClick: setJournalLastLocation,
    },
    topic: {
      prefix: 'topic',
      onClick: setTopicLastLocation,
    },
    video: assetLinkage,
    document: assetLinkage,
    image: assetLinkage,
    interactive: assetLinkage,
  };
  const getLinkage = (itemType: string): Linkage =>
    linkages[itemType] || linkages.topic;
  return {
    getLinkage,
  };
}
