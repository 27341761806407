import { useMemo } from 'react';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import { getDistributionConfig } from 'distribution';

export const useSiteName = () => {
  const { t } = useTranslation();
  return getDistributionConfig(['siteName']) ?? t('common.storymd');
};

export const usePageTitle = (pageName) => {
  const siteName = useSiteName();
  return pageName ? `${pageName} - ${siteName}` : siteName;
};

export const useMetaConfig = ({
  title,
  description,
  keywords,
  image,
  url: _url,
}) => {
  const location = useLocation();
  const siteName = useSiteName();
  const pageTitle = usePageTitle(title);
  const url = _url ?? location.href;
  return useMemo(
    () => ({
      // prettier-ignore
      meta: [
                      { name: 'title', content: pageTitle },
      description &&  { name: 'description', content: description },
      keywords &&     { name: 'keywords', content: keywords },

                      { property: 'og:type', content: 'website' },
                      { property: 'og:site_name', content: siteName },
                      { property: 'og:title', content: pageTitle },
      description &&  { property: 'og:description', content: description },
      image &&        { property: 'og:image', content: image },
      url &&          { property: 'og:url', content: url },

                      { property: 'twitter:title', content: pageTitle },
      description &&  { property: 'twitter:description', content: description },
      image &&        { property: 'twitter:image', content: image },
      image &&        { property: 'twitter:card', content: 'summary_large_image' },
      url &&          { property: 'twitter:url', content: url },
    ].filter(Boolean),
      // prettier-ignore
      link: [
      url &&          { rel: 'canonical', href: url }
    ].filter(Boolean),
      title,
    }),
    [title, description, keywords, image, url, pageTitle, siteName]
  );
};
