function Picture({ sources, children, sizes, ...props }) {
  return (
    <picture {...props}>
      {sources?.map((props) => (
        <source key={JSON.stringify(props)} sizes={sizes} {...props} />
      ))}
      {children}
    </picture>
  );
}

export default Picture;
