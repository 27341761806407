import { useStoreLastLocation } from 'hooks';

export default function useJournalLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'journal-navigation-origins',
  });

  return {
    setJournalLastLocation: set,
    pushJournalLastLocation: push,
    popJournalLastLocation: pop,
    journalLastLocation: lastLocation,
  };
}
