import { head, isNil } from 'ramda';

import FEATURES from 'features';
import { findRangeForValue, getColorScheme } from 'components/range/utils';
import { ucumConvertValues, validateUcumUnit } from 'my-phr/utils/conversion';
import { RESULT_STATUS } from 'my-phr/const';
import { hasRangeIcons } from 'components/range/RangeSlider';
import { RESULT_STATUS_COLOR, UCUM_TIME_UNITS } from './const';

export function initializeRangeValue(ranges, initialValue) {
  if (isNil(initialValue)) {
    const initialRange = head(ranges);
    return { value: initialRange.min, range: initialRange };
  }
  const initialRange = findRangeForValue(ranges, initialValue);
  return { value: initialValue, range: initialRange };
}

function checkIfConversionIsSuccessful({
  fromUnit,
  fromValue,
  toUnit,
  molecularWeight,
  charge,
}) {
  const { conversionResult } = ucumConvertValues({
    fromUnit,
    fromValue,
    toUnit,
    molecularWeight,
    charge,
  });

  return fromUnit === toUnit || conversionResult?.status === 'succeeded';
}

export function convertMinMaxValues({
  entry,
  fromUnit,
  toUnit,
  molecularWeight,
  charge,
} = {}) {
  const { min, max } = entry ?? {};

  const isSuccessfullyConverted = checkIfConversionIsSuccessful({
    fromUnit,
    fromValue: max,
    toUnit,
    molecularWeight,
    charge,
  });

  if (FEATURES.PHR.EXCLUDE_INVALID_UNITS_TIMELINE && !isSuccessfullyConverted) {
    return {};
  }

  if (!isSuccessfullyConverted || fromUnit === toUnit) {
    return { min, max };
  }

  const [convertedMin, convertedMax] = [min, max].map((x) =>
    ucumConvertValues({
      fromUnit,
      fromValue: x,
      toUnit,
      molecularWeight,
      charge,
    })
  );

  return {
    min: convertedMin.convertedValue,
    max: convertedMax.convertedValue,
  };
}

export function getConvertedReferenceRange(
  range,
  rawUnit,
  molecularWeight,
  charge
) {
  const parsedRange = getReferenceRange(range);

  if (!rawUnit) {
    return parsedRange;
  }

  const { isValidUnit: isValidEntryUnit } = validateUcumUnit(rawUnit);

  if (FEATURES.PHR.EXCLUDE_INVALID_UNITS_TIMELINE && !isValidEntryUnit) {
    return {};
  }

  const isSuccessfullyConverted = checkIfConversionIsSuccessful({
    fromUnit: parsedRange.unit,
    fromValue: parsedRange.ranges?.[0]?.max,
    toUnit: rawUnit,
    molecularWeight,
    charge,
  });

  if (FEATURES.PHR.EXCLUDE_INVALID_UNITS_TIMELINE && !isSuccessfullyConverted) {
    return {};
  }

  return {
    ...parsedRange,
    convertToUnit: rawUnit, // for debugging purposes
    step: 0.001, // Hardcoding the step because we don't have interaction with the slider for now. Might change in the future.
    ranges: parsedRange.ranges?.map((x) => {
      const { min, max } = convertMinMaxValues({
        entry: x,
        fromUnit: parsedRange.unit,
        toUnit: rawUnit,
        molecularWeight,
        charge,
      });
      return {
        ...x,
        min,
        max,
      };
    }),
  };
}

export function getReferenceRange(range) {
  if (!range) return {};

  const { units, scale, step, hideunits: hideUnits, items } = range;
  const hasIcons = hasRangeIcons(items);

  const colorScheme = getColorScheme(items);

  const ranges = items?.map((item, index) => {
    const { values, flag, icon } = item;
    const { min, max } = values;

    const label = hideUnits
      ? {
          content: item.label?.short,
          orientation: item.label?.orientation,
        }
      : {
          orientation: item.label?.orientation,
        };

    return {
      min,
      max,
      label,
      flag,
      icon,
      color: colorScheme[index],
      output: {
        result: item.label?.long,
        resultShort: item.label?.short,
        description: item.text,
        conditions: item.conditions,
      },
    };
  });

  return {
    ranges,
    unit: units?.[0]?.code ?? '',
    logScale: scale === 'log',
    step,
    centerLabels: hideUnits || hasIcons,
    snapToCenter: hideUnits,
  };
}

export function determineResultStatus(currentRange) {
  if (!currentRange) return null;
  const currentFlag = RESULT_STATUS?.[currentRange?.range?.flag?.toUpperCase()];
  const currentResult = currentRange?.range?.output?.result?.toUpperCase();
  return {
    flag: currentFlag ?? RESULT_STATUS.INDETERMINATE,
    result: currentResult ?? null,
  };
}

export function getRangesResultStatusColor({
  topics,
  rawUnit,
  value,
  molecularWeight,
  charge,
}) {
  const { ranges } = getConvertedReferenceRange(
    topics?.[0]?.ranges?.[0] ?? topics?.[0]?.ranges ?? topics,
    rawUnit,
    molecularWeight,
    charge
  ); // using only the first range found in topic

  const currentRange =
    ranges?.length && value !== undefined && value !== null
      ? initializeRangeValue(ranges, value)
      : null;

  const resultStatus = currentRange
    ? determineResultStatus(currentRange)
    : null;

  return currentRange?.range?.color
    ? currentRange?.range?.color
    : RESULT_STATUS_COLOR[resultStatus?.flag];
}

export function parseTimeUnits(data) {
  if (data.unit === UCUM_TIME_UNITS.SECONDS) {
    return { ...data, unit: UCUM_TIME_UNITS.SECOND };
  } else {
    return data;
  }
}
