import { CommunitiesApi } from 'api';
import { useQueryClient, useMutation } from 'react-query';
import { communitiesKeys } from '../keys';

export function usePostCreate(communityId, conversationId, options) {
  const queryClient = useQueryClient();
  const { mutate: createPost, ...rest } = useMutation(
    async ({ content, conversationId, toPostId, additionalFields }) => {
      const payload = { content };

      if (toPostId) {
        payload.toPostId = toPostId;
      }

      if (additionalFields) {
        payload.additionalFields = additionalFields;
      }

      return CommunitiesApi.createReply({
        conversationId,
        payload,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          communitiesKeys.conversationById(communityId, conversationId)
        );
      },
      ...options,
    }
  );
  return {
    createPost,
    ...rest,
  };
}

export function useCommunityPostDelete(communityId, conversationId, options) {
  const queryClient = useQueryClient();
  const { mutate: deletePost, ...rest } = useMutation(
    async (postId) => CommunitiesApi.deletePost(postId),
    {
      onMutate: (postId) => {
        const postKeys = communitiesKeys.postsByConversationId(
          communityId,
          conversationId
        );
        queryClient.cancelQueries(postKeys);
        const previousPosts = queryClient.getQueryData(postKeys);
        queryClient.setQueryData(
          communitiesKeys.postsByConversationId(communityId, conversationId),
          (oldData) => {
            const { pages } = oldData;
            const updatedPages = pages.map(({ items, ...rest }) => {
              const updatedItems = items.filter(
                (item) => item?.postId !== postId
              );
              return {
                ...rest,
                items: updatedItems,
              };
            });
            return {
              ...oldData,
              pages: updatedPages,
            };
          }
        );
        return { previousPosts };
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          communitiesKeys.conversationById(communityId, conversationId)
        );
      },
      ...options,
    }
  );
  return { deletePost, ...rest };
}

export function usePostReport(communityId, conversationId, options) {
  const queryClient = useQueryClient();
  const { mutate: reportPost, ...rest } = useMutation(
    async (payload) => CommunitiesApi.reportPost(payload),
    {
      onSettled: () =>
        queryClient.invalidateQueries(
          communitiesKeys.postsByConversationId(communityId, conversationId)
        ),
      ...options,
    }
  );
  return { reportPost, ...rest };
}
