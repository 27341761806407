import { getAssetUrl } from 'utils';
import { TImageAsset } from 'components/Guide';

type TGuideStepImageProps = {
  imageAsset: TImageAsset;
};

export const GuideStepImage = ({ imageAsset }: TGuideStepImageProps) => {
  return (
    <img
      src={getAssetUrl(imageAsset.key)}
      alt={imageAsset.featuredAsset.alt}
      className="h-auto w-full"
    />
  );
};
