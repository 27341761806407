import { alternateColorsInTitle } from 'journals/utils';

function AlternatingTextColorElement({
  as: Wrapper = 'span',
  name,
  evenColor,
  oddColor,
}) {
  return <Wrapper>{alternateColorsInTitle(name, evenColor, oddColor)}</Wrapper>;
}

export default AlternatingTextColorElement;
