import { useState } from 'react';
import useBeforeUnload from './useBeforeUnload';

function useCloseConfirmation(onClose) {
  const [confirmationIsOpened, setConfirmationIsOpened] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const attemptClose = () => {
    if (formIsDirty) {
      setConfirmationIsOpened(true);
    } else {
      setConfirmationIsOpened(false);
      onClose();
    }
  };

  useBeforeUnload(formIsDirty);

  return {
    confirmationIsOpened,
    setConfirmationIsOpened,
    attemptClose,
    formIsDirty,
    setFormIsDirty,
  };
}

export default useCloseConfirmation;
