import { PictureResource } from 'components/media';
import { extractWebpThumbnail } from 'utils';

export default function ImageAsset({ config }) {
  const { images, thumbnail, alt } = config;
  const webpThumbnail = extractWebpThumbnail(images);
  return (
    <PictureResource
      config={{
        url: webpThumbnail?.path || thumbnail,
        alt: alt,
        images,
      }}
      className="mx-auto max-h-[400px] max-w-full object-contain md:max-h-[600px]"
      sizes="(min-width: 1024px) 56rem, 100vw" //TODO: Add breakpoint as css variable to prevent mistakes
    />
  );
}
