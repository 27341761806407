import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'components';
import ContentContainer from 'components/ContentContainer';

import { ContactUsForm } from '../components';

function Contact() {
  const { t } = useTranslation();

  const [mailState, setMailState] = useState();

  function handleSuccess(result) {
    setMailState({
      type: 'success',
      msg: result.data?.response ?? result.data,
    });
  }

  function handleError(error) {
    setMailState({ type: 'error', msg: error?.message });
  }

  return (
    <ContentContainer className="grow px-4">
      <div className="max-w-130 pb-8 pt-16">
        <h1
          className="mb-4 text-smd-t2 font-semibold text-smd-main"
          data-testid="contactUsPageTitle"
        >
          {t('common.contact')}
        </h1>
        <p className="text-smd-base" data-testid="contactUsPageDescription">
          {t('landing.contact-feedback')}
        </p>
      </div>
      <div className="flex flex-nowrap items-start space-x-8 pb-16">
        <ContactUsForm onSuccess={handleSuccess} onError={handleError} />
        {mailState && <Alert res={mailState} destroy={() => setMailState()} />}
        <div className="hidden flex-1 lg:block">
          <img
            src="/images/empty-gallery.png"
            alt=""
            data-testid="contactUsPageHeroImage"
          />
        </div>
      </div>
    </ContentContainer>
  );
}

export default Contact;
