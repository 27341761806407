import { useEffect, useRef } from 'react';

function useRenderCounter() {
  const ref = useRef();

  useEffect(() => {
    ref.current.textContent = Number(ref.current.textContent || '0') + 1;
  });

  return <span ref={ref} />;
}

export default useRenderCounter;
