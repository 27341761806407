import { useMemo } from 'react';
import { Roles, useAuth } from 'Auth';
import { useTranslation } from 'react-i18next';
import { TabTooltip } from 'search/components';

function useAllResourceTypesTabs() {
  const { t } = useTranslation();

  const { hasRole } = useAuth();
  const isHealthProfessional = hasRole(Roles.HP);

  return useMemo(() => {
    return [
      {
        key: 'all',
        label: t('library.search.all'),
      },
      isHealthProfessional && {
        key: 'poc',
        label: (
          <TabTooltip tKey="poc">{t('library.search.poc_other')}</TabTooltip>
        ),
      },
      {
        key: 'healthtopic',
        label: (
          <TabTooltip tKey="healthjournal">
            {t('library.search.healthjournal_other')}
          </TabTooltip>
        ),
      },
      {
        key: 'event',
        label: (
          <TabTooltip tKey="event">
            {t('library.search.event_other')}
          </TabTooltip>
        ),
      },
      {
        key: 'biomarker',
        label: (
          <TabTooltip tKey="biomarker">
            {t('library.search.biomarker_other')}
          </TabTooltip>
        ),
      },
      {
        key: 'drug',
        label: <TabTooltip tKey="drug">{t('library.drug_other')}</TabTooltip>,
      },
      {
        key: 'image',
        label: <TabTooltip tKey="image">{t('common.image_other')}</TabTooltip>,
      },
      {
        key: 'video',
        label: <TabTooltip tKey="video">{t('common.video_other')}</TabTooltip>,
      },
    ].filter(Boolean);
  }, [isHealthProfessional, t]);
}

export default useAllResourceTypesTabs;
