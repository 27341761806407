import { useTranslation } from 'react-i18next';
import { Spinner } from 'components';
import { SEOMeta } from 'components/SEO';
import { useParseLandingEnterprisePageData, usePublicResource } from 'hooks';
import { OG_IMAGES } from 'landing/const';
import { FeaturesSection } from 'components/PageSections';
import { HeroJumbotron } from '../components';

import { GetStartedSection } from '../sections';

function POCLanding() {
  const { data, isFetching, isFetched } = usePublicResource(
    '/point-of-care.json'
  );

  const { t } = useTranslation();

  const parsedData = useParseLandingEnterprisePageData(data);

  const metaValues = {
    title: t('common.poc'),
    description: t('journals.poc.meta-description'),
    image: OG_IMAGES.POINT_OF_CARE,
  };

  if (isFetching && !isFetched) {
    return (
      <div className="min-h-screen grow">
        <div className="flex-center h-full">
          <Spinner className="h-16 w-16" />
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOMeta values={metaValues} />
      {isFetched && (
        <div className="grow">
          {parsedData?.slide && <HeroJumbotron content={parsedData?.slide} />}
          {parsedData?.features?.length > 0 && (
            <FeaturesSection
              header={parsedData?.featuresSection}
              features={parsedData?.features}
            />
          )}
          {parsedData?.getStarted && (
            <GetStartedSection content={parsedData?.getStarted} />
          )}
        </div>
      )}
    </>
  );
}

export default POCLanding;
