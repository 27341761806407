import {
  getDateMock,
  getIdMock,
  getImageMock,
  getParagraphMock,
  getRandomBool,
} from './utils';
import partnerMock from './partner';
import categoryMock from './category';
import journalMock from './journal';

function create(withCategories) {
  const partner = partnerMock.create();

  const categories = Array(3)
    .fill()
    .map(() => {
      return {
        category: categoryMock.create(),
        journals: Array(10)
          .fill()
          .map(() => {
            const journal = journalMock.create();
            journal.preamble.logos = [{ url: partner.logo }];
            return journal;
          }),
      };
    });

  return {
    id: getIdMock(),
    name: getParagraphMock(1, 3),
    description: getParagraphMock(),
    cover: getImageMock(800, 400),
    avatar: getImageMock(200, 200),
    lastChange: getDateMock(),
    partner,
    categories: withCategories ? categories : [],
    subscribed: getRandomBool(),
  };
}

export default { create };
