import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMedia } from 'hooks';
import { CaptionButton } from 'components';
import { EXPAND_DIRECTION } from 'components/CaptionButton';

export default function Caption({ name, description, type, credit }) {
  const { t } = useTranslation();
  const canExpand =
    Boolean(description?.trim?.().length) ||
    Boolean(credit?.name?.trim?.().length);

  const isLarge = useMedia(useMedia.LARGE);
  const isLandscape = useMedia(useMedia.LANDSCAPE);
  const isSmallLandscape = !isLarge && isLandscape;

  const [expanded, setExpanded] = useState(canExpand && isLarge);

  return (
    <div
      className={classnames(
        'bg-blur absolute bottom-0 z-10 max-h-[calc(100vh-40px)] w-full overflow-auto bg-smd-gray-darker bg-opacity-80 text-white',
        {
          'px-1/8 text-center': !isSmallLandscape,
          'px-12': isSmallLandscape,
        }
      )}
    >
      <div className="flex max-h-screen items-start space-x-4 overflow-y-auto">
        <h2
          className={classnames('grow text-smd-base', {
            'pb-4': !canExpand,
            'pt-4': !isSmallLandscape,
            'py-1.5': isSmallLandscape,
            'line-clamp-1': isSmallLandscape && !expanded,
          })}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        {isSmallLandscape && (
          <CaptionButton
            direction={EXPAND_DIRECTION.UP}
            inline
            expanded={expanded}
            onClick={() => setExpanded((value) => !value)}
            className="flex-none"
          />
        )}
      </div>

      {canExpand && (
        <div className="h-full text-center">
          {expanded && (
            <>
              <div
                className="lightbox-caption pt-2 text-left text-smd-sm lg:text-center"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              {Boolean(credit?.name?.trim?.()) && (
                <div className="py-2 text-smd-xs italic text-smd-tertiary">
                  {type && t('journals.asset.source.label-' + type)}{' '}
                  {credit.name}
                </div>
              )}
            </>
          )}
          {!isSmallLandscape && (
            <CaptionButton
              direction={EXPAND_DIRECTION.UP}
              expanded={expanded}
              onClick={() => setExpanded((value) => !value)}
            />
          )}
        </div>
      )}
    </div>
  );
}

Caption.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string.isRequired,
  credit: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

Caption.defaultProps = {
  name: '',
  description: '',
};
