import { forwardRef } from 'react';
import classnames from 'classnames';

import styles from './NoScrollContainer.module.css';

const NoScrollContainer = forwardRef(
  ({ container: Container = 'div', className, ...props }, ref) => {
    return (
      <Container
        className={classnames(styles.container, className)}
        {...props}
        ref={ref}
      />
    );
  }
);

export default NoScrollContainer;
