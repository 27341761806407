import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import useIsEmbedded from 'hooks/useIsEmbedded';
import NoContentLayout from './NoContentLayout';

function Error({ title, description, buttonLabel }) {
  const { t } = useTranslation();
  const isEmbedded = useIsEmbedded();

  const reload = () => window.location.replace(window.location.origin);
  let titleDefault = t('errors.default-error-page.title');
  let descriptionDefault = t('errors.default-error-page.description');
  let buttonLabelDefault = t('common.reload');
  return (
    <NoContentLayout>
      <div className="flex-center h-full w-full p-6">
        <div className="flex max-w-md flex-col items-center space-y-6 text-center">
          <h1 className="text-2xl">{title || titleDefault}</h1>
          {/* TODO: Sentry.showReportDialog({ eventId })} */}
          <p>{description || descriptionDefault}</p>
          {!isEmbedded && (
            <Button.Primary onClick={reload}>
              {buttonLabel || buttonLabelDefault}
            </Button.Primary>
          )}
        </div>
      </div>
    </NoContentLayout>
  );
}

export default Error;
