import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LIBRARY_SEARCH_KEY } from 'search/const';
import { TabTooltip } from '../components';
import useAllResourceTypesTabs from './useAllResourceTypesTabs';

export function useParseSearchSummary(data, query) {
  const { t } = useTranslation();
  const tabs = useAllResourceTypesTabs();

  return useMemo(() => {
    if (!query) return [];

    return tabs.map(({ key }) => {
      const count = data ? data[key] : null;
      const label = t(`library.search.${key}_other`);
      const isAllTab = key === 'all';
      const isHealthJournal = key === 'healthtopic';
      const shouldShowCount = Boolean(count) || count === 0;

      return {
        key,
        label: isAllTab ? (
          label
        ) : (
          <TabTooltip tKey={key}>{`${
            isHealthJournal ? t('common.healthjournal_other') : label
          }${shouldShowCount ? ` (${count})` : ''}`}</TabTooltip>
        ),
        count,
        path: `/search/${LIBRARY_SEARCH_KEY[key]}?query=${query}`,
      };
    });
  }, [data, t, tabs, query]);
}

export function useParseSearch(data, params) {
  return useMemo(() => {
    const hasSearchResults = data && Boolean(params.type);
    if (!hasSearchResults) return [];

    return data.pages.reduce((prev, curr) => {
      const pageContent = curr?.data?.map((item) => {
        return {
          ...item.featuredAsset,
          ...item,
          ...(item?.type === 'topic' ? { subtype: params?.type } : {}),
          type: item.subtype,
          link: item.url,
        };
      });
      return [...prev, ...pageContent].filter((x) => x?.id);
    }, []);
  }, [data, params.type]);
}
