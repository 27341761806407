import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Share, UnorderedList } from 'components/icons';
import { ViewerSidebar } from 'components/viewer';

export default function MobileIndexTrigger({
  isTOCOpen,
  onTOCStateToggle,
  onShare,
}) {
  const { t } = useTranslation();
  return (
    <div className="fixed bottom-0 z-50  flex h-14 w-full flex-row items-center justify-center space-x-24 space-y-1 border-t bg-white py-2">
      <ViewerSidebar.Button onClick={onTOCStateToggle} active={isTOCOpen}>
        <UnorderedList
          className={classnames('h-7 w-7 shrink-0', {
            'fill-smd-accent': isTOCOpen,
          })}
        />
        <span className="text-xs">{t('common.index')}</span>
      </ViewerSidebar.Button>
      <ViewerSidebar.Button onClick={onShare}>
        <Share className="h-7 w-7 shrink-0" />
        <span className="text-xs">{t('common.share')}</span>
      </ViewerSidebar.Button>
    </div>
  );
}
