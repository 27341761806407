import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { X } from 'components/icons';
import { OutsideClickConfirmationModal } from 'components/OutsideClickConfirmationModal';
import { Button, Form } from 'components/forms';

import { useCloseConfirmation } from 'hooks';
import { useCreateDraft, useGetOcrPreview } from 'hooks/ocr';
import { useOCRStateContext, useOCRUpdaterContext } from 'OCRContext';

import { ManualInputsResolver } from 'my-phr/layout/modules/ManualInputs/inputs';
import GettingStartedOcr from './GettingStartedOcr';
import OCRForm from './OCRForm';
import { STEPS } from './const';
import ResultPreview from './ResultPreview';
import EditOCRForm from './EditOCRForm';

function StepContent({
  step,
  onClick,
  onPreview,
  onEdit,
  handleEdit,
  isLoading,
  preview,
  props,
}) {
  switch (step) {
    case STEPS.GET_STARTED:
      return <GettingStartedOcr {...{ onClick, isLoading }} />;
    case STEPS.UPLOAD_FILE:
      return <OCRForm onPreview={onPreview} />;
    case STEPS.RESULT_PREVIEW:
      return <ResultPreview onSubmit={handleEdit} {...{ onEdit, preview }} />;
    case STEPS.EDIT_FORM:
      return (
        <ManualInputsResolver>
          <Form onSubmit={handleEdit}>
            <EditOCRForm goToPreview={onPreview} preview={preview} {...props} />
          </Form>
        </ManualInputsResolver>
      );
    default:
      return;
  }
}

function OCRModal({ onClose }) {
  const { t } = useTranslation();

  const { setOcrId, setShownAttachments, onCancel, closeOcrModal } =
    useOCRUpdaterContext();
  const { ocrId, shownAttachments } = useOCRStateContext();

  const [step, setStep] = useState(STEPS.GET_STARTED);
  const [preview, setPreview] = useState();
  const [ocr, setOcr] = useState('');

  const { createDraft, isLoading } = useCreateDraft((res) => {
    setStep(STEPS.UPLOAD_FILE);
    setOcrId(res.id);
    setOcr(res);
  });

  const { resultData } = useGetOcrPreview?.(ocrId, {
    enabled: !preview && step === 3,
  });

  useEffect(() => {
    if (resultData) {
      setPreview({
        observations: resultData?.observations,
        date: new Date(resultData?.date) ?? new Date(),
        name:
          resultData?.name ??
          `${t('my-phr.ocr.preview.lab-report')} - ${resultData?.date}`,
      });
    }
  }, [resultData, t]);

  function handleOnEdit(props) {
    setPreview({
      observations: props?.OBSERVATION_PANEL.observations,
      date: props?.date,
      name: props?.name,
    });
    setStep(STEPS.RESULT_PREVIEW);
  }

  const {
    confirmationIsOpened,
    setConfirmationIsOpened,
    attemptClose,
    formIsDirty,
    setFormIsDirty,
  } = useCloseConfirmation(onClose);

  useEffect(() => {
    if (Boolean(shownAttachments?.length)) {
      setFormIsDirty(true);
    }
  }, [shownAttachments, setFormIsDirty]);

  const confirmMessage = (
    <div className="space-y-4 text-left">
      <div className="text-smd-h3.5 font-semibold">
        {t('my-phr.ocr.close-confirmation-title')}
      </div>
      <div>{t('my-phr.ocr.close-confirmation-body')}</div>
    </div>
  );

  return (
    <Modal isOpen prepend>
      <OutsideClickConfirmationModal
        renderConfirm={
          <Button.Alert
            onClick={() => {
              closeOcrModal();
              onCancel();
            }}
            size="lg"
          >
            {t('my-phr.ocr.discard')}
          </Button.Alert>
        }
        onConfirm={closeOcrModal}
        onCancel={() => setConfirmationIsOpened(false)}
        message={confirmMessage}
        skip={!formIsDirty}
        isOpen={confirmationIsOpened}
      >
        <div
          className="relative flex h-screen w-full flex-col overflow-auto bg-white py-4 md:w-[40rem] lg:h-full lg:max-w-4xl"
          role="dialog"
          aria-modal="true"
        >
          <StepContent
            onClick={() => createDraft()}
            onPreview={() => {
              setStep(STEPS.RESULT_PREVIEW);
              setShownAttachments([]);
            }}
            onEdit={() => {
              setStep(STEPS.EDIT_FORM);
            }}
            preview={preview}
            handleEdit={(props) => handleOnEdit(props)}
            {...{ ocr, isLoading }}
            step={
              ocrId && Boolean(shownAttachments?.length)
                ? STEPS.UPLOAD_FILE
                : step
            }
          />
          <button
            className="absolute right-3 top-4 cursor-pointer text-black"
            onClick={attemptClose}
            aria-label={t('common.close')}
          >
            <X className="h-6 w-6 stroke-2" />
          </button>
        </div>
      </OutsideClickConfirmationModal>
    </Modal>
  );
}

export default OCRModal;
