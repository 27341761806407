import { useQuery } from 'react-query';

import { ExternalResource } from 'api';
import { getPublicResourceUrl } from 'utils';

function usePublicResource(key, options) {
  return useQuery(
    ['publicResource', key],
    async () => {
      const { data } = await ExternalResource.get(getPublicResourceUrl(key));
      return data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: Boolean(key),
      onError: (err) => console.log(err),
      ...options,
    }
  );
}

export default usePublicResource;
