import { useMemo } from 'react';

function usePrevNextNavigation(nodes, activeId) {
  const prevNextMap = useMemo(
    () =>
      (nodes ?? []).reduce((acc, node, i, source) => {
        acc[node.id] = {
          prev: source[i - 1],
          next: source[i + 1],
        };
        return acc;
      }, {}),
    [nodes]
  );

  return {
    prev: prevNextMap[activeId]?.prev,
    next: prevNextMap[activeId]?.next,
  };
}

export default usePrevNextNavigation;
