import { useTranslation } from 'react-i18next';

import { ErrorAlert, UploadAlert } from 'components';
import { Image, Minimize, PDFCopy, Upload } from 'components/icons';
import { Button } from 'components/forms';
import FilePicker from 'components/FilePicker';

import { useOCRStateContext, useOCRUpdaterContext } from 'OCRContext';

import FileView from './FileView';

function OCRForm({ onPreview }) {
  const { t } = useTranslation();
  const {
    attachmentsList,
    error,
    showAlert,
    shownAttachments,
    isCompletedUploading,
  } = useOCRStateContext();

  const { setError, handleFileSelected, minimizeOcrModal } =
    useOCRUpdaterContext();

  const iconClassName = 'h-6 w-6';
  const gettingStartedItems = [
    {
      translation: 'my-phr.ocr.upload.upload-max',
      icon: (props) => <Upload {...props} />,
    },
    {
      translation: 'my-phr.ocr.upload.upload-digital',
      icon: (props) => <PDFCopy {...props} />,
    },
    {
      translation: 'my-phr.ocr.upload.upload-picture',
      icon: (props) => <Image {...props} />,
    },
  ];

  return (
    <>
      <div className="flex justify-between border-b-2 px-4 pb-2 text-smd-h4 font-semibold">
        <span>{t('my-phr.ocr.upload.title')}</span>
        {Boolean(shownAttachments?.length) && (
          <button onClick={minimizeOcrModal}>
            <Minimize className="absolute right-12 top-6 h-4 w-4" />
          </button>
        )}
      </div>
      {error && <ErrorAlert error={error} onClose={() => setError(null)} />}
      {showAlert && <UploadAlert />}
      <ul className="space-y-4 py-4">
        {gettingStartedItems.map(({ icon: Icon, translation }, index) => {
          return (
            <div
              className="flex items-center space-x-3 px-4"
              key={`list-item-${index}`}
            >
              <Icon className={iconClassName} />
              <div className="flex flex-col">
                <span className="text-smd-sm">{t(translation)}</span>
              </div>
            </div>
          );
        })}
      </ul>
      <div className="px-4">
        <FilePicker
          hasLibraryPicker={false}
          onFilesSelected={handleFileSelected}
          accept="image/jpg, image/jpeg, image/png, application/pdf"
        />
      </div>
      <ul className="items-baseline space-y-4 p-4">
        {attachmentsList?.map((item, index) => (
          <li className="items-end" key={`attachment-${index}`}>
            <FileView
              attachment={item.attachment}
              status={item.status}
              className="h-12 border border-smd px-4"
            />
          </li>
        ))}
      </ul>
      {Boolean(shownAttachments?.length) && (
        <div className="border-bt-2 flex justify-end space-x-2 px-4 pt-2 font-semibold">
          <Button.Ghost onClick={minimizeOcrModal} className="text-smd-sm">
            {t('my-phr.ocr.upload.run-background')}
          </Button.Ghost>
          <Button.Primary
            onClick={() => onPreview()}
            disabled={!isCompletedUploading}
          >
            {t('my-phr.ocr.upload.preview')}
          </Button.Primary>
        </div>
      )}
    </>
  );
}

export default OCRForm;
