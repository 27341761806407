import React, { forwardRef } from 'react';

const base = [
  'form-radio',
  'text-smd-accent',
  'border border-smd',
  'smd-focus-visible-primary',
];

export default forwardRef(function Radio({ className, type, ...props }, ref) {
  return (
    <input
      type="radio"
      ref={ref}
      className={base.concat(className).join(' ')}
      {...props}
    />
  );
});
