import { useCallback, useEffect, useState } from 'react';
import useCopyToClipboard from './useCopyToClipboard';

export default function useCopyToClipboardFeedback(delay = 2000) {
  const { copy, isSupported } = useCopyToClipboard();

  const [isCopied, setIsCopied] = useState(false);

  const _copy = useCallback(
    (value) => {
      copy(value);
      setIsCopied(true);
    },
    [copy]
  );

  useEffect(() => {
    if (!isCopied) return;

    const handler = setTimeout(() => {
      setIsCopied(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [isCopied, delay]);

  return {
    isSupported,
    copy: _copy,
    isCopied,
  };
}
