import { useStoreLastLocation } from 'hooks';

export default function useCommunityLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'community-navigation-origins',
    defaultOrigin: '/communities',
  });

  return {
    setCommunityLastLocation: set,
    pushCommunityLastLocation: push,
    popCommunityLastLocation: pop,
    communityLastLocation: lastLocation,
  };
}
