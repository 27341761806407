import { useTranslation } from 'react-i18next';

import { Button } from 'components/forms';

function SubscriptionButton({ subscribed, ...props }) {
  const { t } = useTranslation();

  return subscribed ? (
    <Button.Tertiary {...props} aria-label={t('labels.channels.unfollow')}>
      <span>{t('common.unfollow')}</span>
    </Button.Tertiary>
  ) : (
    <Button.Primary {...props} aria-label={t('labels.channels.follow')}>
      <span>{t('common.follow')}</span>
    </Button.Primary>
  );
}

export default SubscriptionButton;
