import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Form, SelectTag } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';

function SelectChipInput({ options, ...props }) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8 w-3/4',
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const optionClassName = (optionValue, complexValue) => {
    return classnames(
      'relative min-w-[44px] cursor-pointer select-none whitespace-nowrap rounded-full border py-1 px-3 text-smd-xs focus-visible:ring-offset-0',
      optionValue === selectedOption || complexValue === selectedOption
        ? 'bg-smd-accent text-white'
        : 'bg-white'
    );
  };

  const handleSelect = (event) => {
    const value = event.currentTarget.querySelector(
      'input[type="hidden"]'
    ).value;
    setSelectedOption(value);
    newProps.setValue(`${props.prefix}.${props.name}`, value);
  };

  useEffect(() => {
    const value = newProps?.defaultValues?.[newProps.name];
    if (value) {
      setSelectedOption(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Select as={SelectTag} {...newProps} labelProps={newProps.labelProps}>
      {options.map(({ value, rangeValue, label, disabled }, index) => {
        const optionValue = rangeValue
          ? JSON.stringify({ value, rangeValue })
          : value;

        return (
          <div
            key={`${index}${value}`}
            className={optionClassName(optionValue, value)}
            onClick={handleSelect}
            {...disabled}
          >
            <input type="hidden" value={optionValue} />
            {label}
          </div>
        );
      })}
    </Form.Select>
  );
}

export default SelectChipInput;
