import classnames from 'classnames';
import manualInputsRegistry from '../manualInputsRegistry';
import { MANUAL_INPUT_TYPE } from '../const';
import { getManualInput } from '../utils';
import { useManualInputDefaultProps } from '../hooks';

export function ResultInput({
  prefix,
  fields,
  label,
  watch,
  namePrefix,
  labelProps,
  ...props
}) {
  const newProps = useManualInputDefaultProps(props);

  const valueInputName = fields?.[0]?.name;
  const unitInputName = fields?.[1]?.name;

  function getFieldName(field) {
    return namePrefix ? namePrefix + field : field;
  }

  const isHiddenUnit = fields?.[1]?.inputType?.toUpperCase?.();

  return (
    <div className="flex-cols-2 flex items-end gap-2">
      {getManualInput(fields?.[0]?.inputType?.toUpperCase?.(), {
        ...newProps,
        ...fields?.[0],
        prefix,
        watch,
        field: fields?.[0],
        name: getFieldName(valueInputName),
        label: label,
        placeholder: fields?.[0]?.placeholder,
        className: 'w-full',
        labelProps: labelProps,
        groupClassName: classnames(
          'w-full',
          !isHiddenUnit && 'max-w-[11.5rem] md:max-w-[16rem]'
        ),
      })}
      {getManualInput(fields?.[1]?.inputType?.toUpperCase?.(), {
        ...newProps,
        ...fields[1],
        prefix,
        name: getFieldName(unitInputName),
        options: fields[1]?.options,
        label: fields[1]?.label,
        className: 'w-full',
        groupClassName: 'w-[9.5rem] max-w-[11.5rem] md:w-full',
      })}
    </div>
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.RESULT, ResultInput);
