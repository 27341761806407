import { useMemo } from 'react';

import { useTags } from 'hooks';
import { getAssetUrl } from 'utils';
import { NewsletterSubscribeButton } from 'components/Newsletter';

function InitiativeCard({ title, content, cover }) {
  return (
    <div className="flex flex-col items-stretch overflow-hidden bg-smd-accent text-white md:flex-row">
      <div
        role="img"
        className="h-64 w-full shrink-0 bg-cover bg-center bg-no-repeat md:h-auto md:max-w-sm"
        style={{
          backgroundImage: `url(${cover})`,
        }}
      />
      <div className="flex-grow p-4 md:mb-smd-3xl md:py-16 md:px-10">
        <h1 className="mb-2 text-smd-h2.5 font-bold leading-tight md:text-smd-t2">
          {title}
        </h1>
        <p
          className="mb-4 space-y-2 text-smd-h4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <NewsletterSubscribeButton className="smd-focus-visible-secondary" />
      </div>
    </div>
  );
}

function FromTag({ tag }) {
  const { tags, isFetched } = useTags();

  const currentTag = useMemo(
    () => tags.find((x) => x.tag === tag),
    [tag, tags]
  );
  return isFetched && currentTag ? (
    <InitiativeCard
      title={currentTag.name}
      content={currentTag.description}
      cover={getAssetUrl(currentTag.key)}
    />
  ) : null;
}

export default Object.assign(InitiativeCard, { FromTag });
