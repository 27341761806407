import classnames from 'classnames';

import { Checkbox } from 'components/forms';
import { Spinner } from 'components';

function JournalCollectionsForm({ collections, onChange, children }) {
  return (
    <ul className="flex flex-grow flex-col pt-4">
      {collections.map((collection) => {
        return (
          <li key={collection.id}>
            {children(collection, (value) =>
              onChange?.({ id: collection.id, value })
            )}
          </li>
        );
      })}
    </ul>
  );
}

JournalCollectionsForm.defaultProps = {
  children: (collection, onChange) => (
    <CollectionItem {...{ collection, onChange }} />
  ),
};

export function CollectionItem({ collection, loading, onChange }) {
  const { id, name, checked } = collection;

  return (
    <label
      htmlFor={id}
      className={classnames(
        '-mx-2 flex cursor-pointer select-none items-center space-x-2 p-2 hover:bg-smd-gray-lightest',
        loading && 'opacity-50'
      )}
    >
      {loading ? (
        <Spinner className="h-6 w-6" />
      ) : (
        <Checkbox
          id={id}
          checked={checked ?? false}
          onChange={({ target }) => onChange?.(target.checked)}
          className="h-6 w-6 cursor-pointer"
        />
      )}
      <span>{name}</span>
    </label>
  );
}

export default JournalCollectionsForm;
