import { UserApi } from 'api';
import { useMutation } from 'react-query';

export function useOTPState(options) {
  const { mutate: updateOTPState } = useMutation(
    UserApi.updateOTPState,
    options
  );
  return { updateOTPState };
}
