import { useEffect, useState } from 'react';

function Expire({ delay = 2000, children }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handler = setTimeout(() => {
      setVisible(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay]);

  return visible ? children : null;
}

export default Expire;
