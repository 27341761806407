import React, { useEffect, useRef, useState } from 'react';
import { compose, flatten, isNil, pluck, prop, uniqBy } from 'ramda';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'Auth';

import { ChevronRight } from 'components/icons';
import CHANNELS from 'hooks/channels';
import { ContentContainer, InfiniteScrollTrigger, Skeleton } from 'components';
import { Button } from 'components/forms';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { SEOMeta } from 'components/SEO';
import {
  AddToCollectionModal,
  NoSearchResults,
  SkeletonCardCarousel,
  SmartJournalCard,
} from 'journals/components';
import { AddToCollection } from 'journals/components/actions';
import { useChannelLastLocation } from 'journals/hooks';
import Avatar from 'components/Avatar';
import { OG_IMAGES } from '../const';

import { getJournalSlugPath } from '../utils';
import SubscriptionButton from './SubscriptionButton';

function BrowseChannels({
  className,
  groupClassName = 'px-4 lg:px-0',
  groupWrapperClassName = 'space-y-8',
  tag,
  searchTerm,
  onLoadingStateChanged,
}) {
  const { t } = useTranslation();

  const { authenticated } = useAuth();

  const { setChannelLastLocation } = useChannelLastLocation();

  const buttonRef = useRef();

  const { subscriptionsById } = CHANNELS.useChannelsSubscriptions({
    enabled: Boolean(authenticated),
  });

  const previousTag = useRef(null);

  const {
    channelsData,
    hasNoChannels,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    isFetchingNextPage,
    removeQueryByTag,
  } = CHANNELS.usePaginatedChannels({
    searchTerm,
    active: true,
    tag: tag,
  });

  useEffect(() => {
    if (previousTag.current && previousTag.current !== tag) {
      removeQueryByTag(previousTag.current);
    }
    previousTag.current = tag;
  }, [tag, removeQueryByTag]);

  useEffect(() => {
    onLoadingStateChanged?.(isFetchingNextPage || isFetching);
  }, [isFetching, isFetchingNextPage, onLoadingStateChanged]);

  const noSearchResults =
    searchTerm?.length > 0 && isFetched && !channelsData.length;

  const { changeSubscription, isLoading } = CHANNELS.useSubscription();
  function handleSubscription(id, subscription) {
    buttonRef.current = id;
    changeSubscription({ id, subscription });
  }

  const [actionJournal, setActionJournal] = useState();

  const mapToUniqueJournalsList = compose(
    uniqBy(prop('id')),
    flatten,
    pluck('journals')
  );

  const metaValues = {
    image: OG_IMAGES.CHANNELS,
  };
  return (
    <>
      <SEOMeta values={metaValues} />
      <ContentContainer className={className}>
        {isFetching && !isFetched && (
          <SkeletonCardCarousel
            className="!mt-smd-xxs"
            title={
              <div className="mb-4 flex items-center justify-between">
                <div className="flex items-center">
                  <Skeleton.Circle className="h-12 w-12 shrink-0 border-2 border-smd-accent lg:h-16 lg:w-16" />
                  <Skeleton.Text
                    fontSize="1.375rem"
                    lineHeight="1.2"
                    style={{ width: 'clamp(5rem, 20vw, 20rem)' }}
                    className=" ml-4 shrink"
                  />
                </div>
                <Skeleton
                  style={{ width: '5rem', height: '2.375rem' }}
                  className="shrink rounded"
                />
              </div>
            }
          />
        )}
        {isFetched && (
          <>
            <div className={groupWrapperClassName}>
              {channelsData.map(({ id, name, categories, avatar }, index) => {
                const lastItem = index === channelsData.length - 1;
                const subscribed = subscriptionsById?.[id];

                if (isNil(categories)) {
                  return null;
                }

                return (
                  <React.Fragment key={id}>
                    <div className={groupClassName}>
                      <div className="mb-4 flex items-center justify-between">
                        <Link
                          to={`/collections/${id}`}
                          onClick={() => setChannelLastLocation()}
                          className="group flex cursor-pointer flex-col items-start outline-none lg:flex-row lg:items-center"
                          aria-label={t('labels.channels.channel', {
                            channel: name,
                          })}
                        >
                          <div className="flex items-center">
                            <div className="-top-12 h-8 w-8 shrink-0 overflow-hidden rounded-full border-2 border-smd-accent bg-white lg:-top-16 lg:h-16 lg:w-16">
                              <Avatar
                                className="h-full w-full rounded-full"
                                src={avatar}
                                alt={name}
                              />
                            </div>
                            <h2 className="ml-4 break-normal text-smd-h3 font-bold text-smd-main line-clamp-2 lg:text-smd-h2">
                              {name}
                            </h2>
                          </div>
                          <Button.Anchor
                            className="smd-group-focus-visible-primary ml-6 whitespace-nowrap font-semibold lg:ml-auto"
                            tabIndex={-1}
                          >
                            {t('journals.channels.go-to-collection')}
                            <ChevronRight
                              className="ml-1 h-4 w-4"
                              strokeWidth={3}
                            />
                          </Button.Anchor>
                        </Link>
                        {authenticated && (
                          <SubscriptionButton
                            className="pointer-events-auto hidden lg:block"
                            loading={buttonRef.current === id && isLoading}
                            subscribed={subscribed}
                            onClick={() => handleSubscription(id, !subscribed)}
                          />
                        )}
                      </div>

                      <VirtualCarousel
                        className="relative -mx-4 lg:-mx-12 lg:px-10"
                        withNavigation
                        slides={mapToUniqueJournalsList(categories).map(
                          (journal) => (
                            <Carousel.Slide key={journal.id}>
                              <div className="smd-transform-duration">
                                <SmartJournalCard
                                  actions={[
                                    {
                                      onClick: () => setActionJournal(journal),
                                      element: <AddToCollection />,
                                    },
                                  ]}
                                  journal={journal}
                                  to={getJournalSlugPath(journal)}
                                />
                              </div>
                            </Carousel.Slide>
                          )
                        )}
                      />
                    </div>
                    {!lastItem && (
                      <div className="h-0.5 w-full bg-gradient-to-r from-transparent via-smd-gray-lighter to-transparent" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            <div className="pt-8 text-center">
              {hasNextPage && (
                <InfiniteScrollTrigger
                  onFetch={fetchNextPage}
                  isFetching={isFetchingNextPage}
                ></InfiniteScrollTrigger>
              )}

              {hasNoChannels && <span>{t('common.no-results')}</span>}
            </div>
          </>
        )}
        {noSearchResults && <NoSearchResults title={t('common.no-results')} />}
        {authenticated && (
          <AddToCollectionModal
            journal={actionJournal}
            onClose={() => setActionJournal()}
          />
        )}
      </ContentContainer>
    </>
  );
}

export default BrowseChannels;
