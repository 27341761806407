import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from 'SettingsContext';
import { Modal } from 'components';
import { Button, Checkbox, Label, SquareButton } from 'components/forms';
import { X } from 'components/icons';

export default function UnblurModal() {
  const { t } = useTranslation();
  const { sensitiveContent } = useContext(SettingsContext);
  const [unblurAll, setUnblurAll] = useState(false);
  const onClose = () => sensitiveContent?.closeConfirmationModal?.();
  return (
    <Modal
      isOpen={sensitiveContent?.confirmationModalOpen}
      onRequestClose={onClose}
      contentWrapper={Modal.ResponsiveContainer}
    >
      <div className="flex h-screen w-screen flex-col overflow-hidden bg-white md:mx-auto md:mt-5 md:h-auto md:max-h-90vh md:max-w-xl lg:mt-20 lg:max-h-70vh">
        <header className="flex shrink-0 items-center justify-between p-4">
          <p className="text-smd-h3.5 font-semibold">
            {t('sensitive-content-settings.view-image')}?
          </p>
          <SquareButton onClick={onClose} aria-label={t('labels.common.close')}>
            <X strokeWidth="3" />
          </SquareButton>
        </header>
        <div className="space-y-3 py-2 px-4 text-smd-gray-dark">
          <p className="text-smd-base">
            {t('sensitive-content-settings.explanation')}
          </p>
          <div>
            <Label htmlFor="unblur-all" className="text-normal text-smd-base">
              <Checkbox
                className="mr-3 mb-1 border-smd-gray-dark"
                id="unblur-all"
                checked={unblurAll}
                onChange={(event) => setUnblurAll(event?.target?.checked)}
              />
              {t('sensitive-content-settings.apply-to-all-images')}
            </Label>
          </div>
        </div>
        <div className="flex shrink-0 items-center space-x-2 p-4">
          <Button.Primary
            onClick={() =>
              sensitiveContent?.onSensiviteContentViewConfirmed?.(unblurAll)
            }
          >
            {t('common.view')}
          </Button.Primary>
          <Button.Secondary onClick={onClose}>
            {t('common.cancel')}
          </Button.Secondary>
        </div>
      </div>
    </Modal>
  );
}
