import slugify from 'slugify';

export function getSlugHead(slug: string) {
  return slug?.split('-')?.[0];
}

export function slugifyObject(id: string, label?: string, maxLength = 100) {
  if (id && label) {
    const fullSizeSlug = slugify(label, { strict: true, lower: true });
    const match = fullSizeSlug.match(new RegExp(`(^.{1,${maxLength}})(-|$)`));

    if (match?.[1]) {
      return `${id}-${match[1]}`;
    }
  }
  return id;
}
