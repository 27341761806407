import { filter, head, identity, isNil, props } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';
import { getDistributionConfig } from 'distribution';

import { useLibraryApi } from '../libraryApiContext';

const getQueryKey = (query) => ['library', 'metadata', query];

export function useLibraryMeta(query, options) {
  const api = useLibraryApi();
  const queryClient = useQueryClient();

  const isMulti = Array.isArray(query);
  const ids = isMulti ? query : [query];
  const isSmdDistribution = isNil(getDistributionConfig());

  return useQuery(
    getQueryKey(ids),
    () => api.getLibraryMetaByIds(ids).then(props(ids)).then(filter(Boolean)),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: ids?.filter(Boolean).length > 0,
      select: isMulti ? identity : head,
      onSuccess: (data) => {
        const hasAmplLink = data?.link?.some((item) => item?.rel === 'amphtml');
        if (hasAmplLink && !isSmdDistribution) {
          const updatedData = {
            ...data,
            link: data?.link?.filter((item) => item.rel !== 'amphtml'),
          };
          queryClient.setQueryData(getQueryKey(ids), updatedData);
        }
        if (isMulti) {
          data.filter(Boolean).forEach((item) => {
            const key = getQueryKey([item.id]);
            if (isNil(queryClient.getQueryData(key))) {
              queryClient.setQueryData(key, [item]);
            }
          });
        }
      },
      ...options,
    }
  );
}
