import { lazy, Suspense } from 'react';

import useMedia from 'hooks/useMedia';

const PDFDocument = lazy(() => import('./PDFDocument'));

export default function DocumentAsset(props) {
  const height = useMedia(useMedia.LARGE) ? '600px' : '200px';
  return (
    <Suspense fallback={'...'}>
      <PDFDocument {...props} height={height} />
    </Suspense>
  );
}

export function LightBoxDocumentAsset(props) {
  return (
    <Suspense fallback={'...'}>
      <div className="h-full w-full text-white md:w-8/12">
        <PDFDocument {...props} hasZoomControls />
      </div>
    </Suspense>
  );
}
