import { useTranslation } from 'react-i18next';

import Button from 'components/forms/Button';
import Form from 'components/forms/Form';

const MAX_LENGTH = 100;

function CreateCollectionForm({ onSubmit, onCancel, loading }) {
  const { t } = useTranslation();

  const actions = (
    <>
      <Button.Tertiary type="button" onClick={onCancel}>
        {t('common.cancel')}
      </Button.Tertiary>
      <Button.Primary type="submit" loading={loading}>
        {t('journals.collections.create')}
      </Button.Primary>
    </>
  );

  return (
    <Form className="flex w-full flex-col space-y-4" onSubmit={onSubmit}>
      <h1 className="text-smd-h1 font-bold">
        {t('journals.collections.create')}
      </h1>
      <Form.Input
        groupClassName="flex flex-col max-w-md"
        label={t('journals.collections.name')}
        placeholder={t('journals.collections.placeholder-collection')}
        name="name"
        autoFocus
        rules={{
          required: t('errors.required'),
          maxLength: {
            value: MAX_LENGTH,
            message: t('errors.max-length', {
              field: t('journals.collections.create'),
              value: MAX_LENGTH,
            }),
          },
        }}
      />
      <div className="flex items-center space-x-4">{actions}</div>
    </Form>
  );
}

export default CreateCollectionForm;
