import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/forms';

function TableSelectionSummary({
  children,
  selections,
  detailsComponent,
  ...rest
}) {
  const { t } = useTranslation();
  const [fullList, setFullList] = useState(false);

  if (selections?.length === 0) return null;
  return (
    <div {...rest}>
      {fullList && (
        <ul className="max-h-half-screen min-h-20 overflow-y-auto border-b-1">
          {selections.map((selection, index) => {
            return (
              <li key={index}>
                {detailsComponent?.(selection) ?? (
                  <div className="px-4 py-2 font-semibold">
                    - {selection?.name ?? selection?.id}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      <div className="flex w-full flex-col items-center border-smd-accent md:flex-row">
        <div className="flex-grow px-4 py-2">
          <span className="font-semibold">{selections.length}</span>{' '}
          {t('common.row-selected', { count: selections.length })}
        </div>
        <div className="flex flex-col items-center space-y-2 p-2 sm:flex-row sm:space-y-0 sm:space-x-2">
          <Button.Tertiary
            onClick={() => setFullList((prev) => !prev)}
            className="font-semibold"
          >
            <span>{t(fullList ? 'common.show-less' : 'common.show-more')}</span>
          </Button.Tertiary>
          {children}
        </div>
      </div>
    </div>
  );
}

TableSelectionSummary.propTypes = {
  selections: PropTypes.arrayOf(PropTypes.object),
  detailsComponent: PropTypes.func,
};

export default TableSelectionSummary;
