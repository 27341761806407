import SelectDropdownInput from './SelectDropdownInput';
import TypeaheadInput from './TypeaheadInput';

export default function StrengthInput(props) {
  const forceSelection = props?.rules?.forceSelection;

  // Temporary: "Strength" now requires the label to be sent instead of value
  const options = props?.options?.map?.((entry) => ({
    ...entry,
    value: entry.label,
  }));

  const Component = forceSelection ? SelectDropdownInput : TypeaheadInput;

  return <Component options={options} {...props} />;
}
