import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  useNewsletter,
  useParseLandingPageData,
  usePublicResource,
} from 'hooks';

import { Alert, Modal } from 'components';
import { X } from 'components/icons';
import { DEFAULT_CONTEXT } from 'landing/const';

import Newsletter from './Newsletter';

function NewsletterModal({ isOpen, onRequestClose, forceOpen }) {
  const { t } = useTranslation();
  const { context } = useParams();

  const contentContext = context ?? DEFAULT_CONTEXT;
  const { data } = usePublicResource('/landing-page.json');
  const parsedLandingData = useParseLandingPageData(data, contentContext);

  const [lockModal, setLockModal] = useState(Boolean(forceOpen));
  const [mailState, setMailState] = useState();

  const { subscribe, isLoading } = useNewsletter({
    onSuccess: (res) => {
      setMailState({ type: res?.data?.status, msg: res?.data?.response });
      setLockModal(false);
    },
    onError: (err) => {
      setMailState({
        type: 'error',
        msg: err?.message || t('errors.default-error-page.title'),
      });
      setLockModal(false);
    },
  });

  const reCaptchaRef = useRef();

  const onClose = () => {
    if (lockModal) {
      setMailState({
        type: 'info',
        msg: t('landing.newsletter-to-proceed'),
      });
    } else {
      setMailState();
      onRequestClose();
    }
  };

  const onSubmit = (data) => {
    reCaptchaRef.current
      .executeAsync()
      .then((token) => {
        data['g-recaptcha-response'] = token;
        return data;
      })
      .then(subscribe);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div
        className="relative h-screen lg:h-[35rem] lg:max-w-2xl"
        role="dialog"
        aria-modal="true"
      >
        <Newsletter
          parsedData={parsedLandingData}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          {mailState && (
            <div className="px-4 lg:px-0">
              <Alert res={mailState} destroy={() => setMailState()} />
            </div>
          )}
        </Newsletter>
        <div className="invisible fixed z-10">
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size="invisible"
          />
        </div>
        {!lockModal && (
          <button
            className="absolute right-2 top-4 cursor-pointer text-white lg:top-2 lg:text-black"
            onClick={onClose}
            aria-label={t('common.close')}
          >
            <X className="h-5 w-5 stroke-3" />
          </button>
        )}
      </div>
    </Modal>
  );
}

export default NewsletterModal;
