import { useMutation, useQueryClient } from 'react-query';
import { phrKeys } from 'my-phr/hooks';
import { PHRApi } from 'api';
import { phrApiEndpointMap } from 'my-phr/const';

export const manualInputKeys = {
  // load specific manual input data for editing
};

export function useAddManualInputEntry(type, onSuccess) {
  const queryClient = useQueryClient();
  const dataType = phrApiEndpointMap[type];

  if (!dataType) {
    throw new Error(`No data type with value '${type}' found!`);
  }

  const { mutate: addEntry, isLoading } = useMutation(
    (data) => PHRApi.addManualInputEntry(dataType, data),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(phrKeys.all());
        onSuccess?.(data);
      },
    }
  );

  const { mutate: editEntry, isLoading: isEditing } = useMutation(
    (data) => PHRApi.editManualInputEntry(dataType, data),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(phrKeys.all());
        onSuccess?.(data);
      },
    }
  );

  return { addEntry, editEntry, isEditing, isLoading };
}
