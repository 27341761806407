import { useMemo } from 'react';

const useChannelsFiltering = (channels, searchTerm) => {
  return useMemo(
    () =>
      channels?.filter(
        ({ name, description, partner }) =>
          name?.toLowerCase().indexOf(searchTerm) > -1 ||
          description?.toLowerCase().indexOf(searchTerm) > -1 ||
          partner?.name?.toLowerCase().indexOf(searchTerm) > -1
      ),
    [channels, searchTerm]
  );
};

export default useChannelsFiltering;
