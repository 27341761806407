import { ZoomIn, ZoomOut } from 'components/icons';

const iconClassName = 'w-5 h-5';

export default function Controls({ zoomIn, zoomOut }) {
  return (
    <div className="absolute right-2 bottom-28 z-20 flex flex-col space-y-2">
      <button
        onClick={() => zoomIn()}
        className="rounded-full bg-smd-gray-darker bg-opacity-75 p-4 text-white hover:bg-opacity-100 focus:outline-none"
      >
        <ZoomIn className={iconClassName} />
      </button>
      <button
        onClick={() => zoomOut()}
        className="rounded-full bg-smd-gray-darker bg-opacity-75 p-4 text-white hover:bg-opacity-100 focus:outline-none"
      >
        <ZoomOut className={iconClassName} />
      </button>
    </div>
  );
}
