import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { mergeRefs } from 'utils';

import { Search as Icon, X } from '../icons';

const Search = forwardRef(
  (
    {
      value,
      onChange,
      onSearch,
      className,
      hideButton,
      autoFocus = false,
      children,
      withClearButton,
      resetsOnSearch = false,
      onClear,
      label,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef();

    const [searchTerm, setSearchTerm] = useState(value ?? '');
    const [showClearButton, setShowClearButton] = useState(false);

    useEffect(() => setSearchTerm(value ?? ''), [value]);
    useEffect(() => {
      setShowClearButton(withClearButton && searchTerm?.length > 0);
      onChange?.(searchTerm);
    }, [searchTerm, onChange, withClearButton]);

    const handleChange = ({ target }) => {
      setSearchTerm(target.value);
    };

    const handleKeyDown = ({ key }) => {
      if (key === 'Enter') {
        onSearch?.(searchTerm);
        if (resetsOnSearch) {
          setSearchTerm('');
        }
      }
    };

    return (
      <div
        className={classnames(
          'smd-input-focus-within-primary inline-flex flex-nowrap rounded-sm bg-white ring-1 ring-smd-gray-lighter',
          className
        )}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          autoFocus={autoFocus}
          onKeyDown={handleKeyDown}
          className={classnames(
            'h-full w-full rounded-l bg-transparent px-4',
            'indent-0.5 italic text-smd-main antialiased placeholder:italic placeholder:text-smd-gray-dark',
            'border-0 focus:ring-0',
            { 'rounded-r text-xs': hideButton }
          )}
          ref={mergeRefs([ref, inputRef])}
          {...props}
        />
        {showClearButton && (
          <button
            type="button"
            data-testid="clear-button"
            onClick={() => setSearchTerm('')}
            className="group px-2 text-smd-gray-dark outline-none"
            aria-label={t('labels.common.clear')}
          >
            <X className="smd-input-group-focus-visible-primary h-6 w-6 rounded-sm stroke-2" />
          </button>
        )}
        <button
          type="button"
          data-testid="search-button"
          onClick={(e) => {
            e.preventDefault();
            onSearch?.(searchTerm);
            inputRef.current?.focus();
          }}
          className={classnames(
            'h-full rounded-r-sm px-3',
            'select-none text-white focus:outline-none',
            'smd-input-focus-secondary border-1 bg-smd-accent ring-0',
            { hidden: hideButton, 'flex-center': !hideButton }
          )}
          aria-label={label}
        >
          {children ?? <Icon className="h-5 w-5 stroke-2" />}
        </button>
      </div>
    );
  }
);

Search.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  withClearButton: PropTypes.bool,
};

Search.defaultProps = {
  withClearButton: false,
};

export default Search;
