import { defaultQueryStaleTimeInMinutes } from 'api/utils';
import {
  useLibraryGlossary,
  useLibrarySearch,
  useLibrarySearchSummary,
} from 'library/hooks';

export function useSearchSummary(search, config = {}) {
  const {
    data: summaryResults,
    isFetching,
    isFetched,
    refetch,
  } = useLibrarySearchSummary(search, {
    staleTime: defaultQueryStaleTimeInMinutes,
    ...config,
  });

  const hasSummary = Boolean(summaryResults);

  return { summaryResults, hasSummary, isFetching, isFetched, refetch };
}

export function useSearch(params, enabled) {
  const {
    data: searchResults,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
  } = useLibrarySearch(params, {
    enabled,
    staleTime: defaultQueryStaleTimeInMinutes,
  });

  const { glossary, isFetchingGlossary, isFetchedGlossary } =
    useLibraryGlossary({
      ...params,
      type: 'glossary',
    });

  return {
    searchResults,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    glossary,
    isFetchingGlossary,
    isFetchedGlossary,
  };
}
