import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { always, isNil, prop } from 'ramda';
import classnames from 'classnames';

import { useMedia, useOutsideClick } from 'hooks';
import { ShareModal, SharePreview } from 'components/Share';
import { ClipboardList, Share } from 'components/icons';
import {
  ViewerAsideNav,
  ViewerLayout,
  ViewerPageContainer,
  ViewerPageNavigation,
  ViewerPageSkeleton,
  ViewerSidebar,
  ViewerTableOfContent,
  ViewerTopbar,
} from 'components/viewer';
import NotFound from 'NotFound';

// TODO: move to /library
import { useLibraryPopOver } from 'journals/blocks/output/HtmlBlock/LibraryTopic';
import ParentContext from 'journals/viewer/ParentContext';
import usePrevNextNavigation from 'components/viewer/ViewerPageNavigation/usePrevNextNavigation';
import { RELATED_ID } from 'journals/viewer/const';

import { useLibraryQuery } from '../hooks';
import { LibraryArticle } from '../components/article';
import { LibraryTopic } from '../components/topic';
import RelatedHealthTopics from './RelatedHealthTopics';

export const ROOT_ID = 'root';

function LibraryTopicViewerPage({ hashId, linkageId, onClose, onNavigate }) {
  const { t } = useTranslation();

  const { url } = useRouteMatch();

  const [share, setShare] = useState(false);
  const [tocVisible, setTocVisible] = useState(true);
  const [tocRoot, setTocRoot] = useState();

  const LibraryCardPopOverProvider = useLibraryPopOver();

  // hide ToC on mobile
  const isLarge = useMedia(useMedia.LARGE);
  useEffect(() => {
    setTocVisible(isLarge);
  }, [isLarge]);

  const closeTocOnOutsideClick = useCallback(() => {
    if (!isLarge && tocVisible) {
      setTocVisible(false);
    }
  }, [isLarge, tocVisible]);

  const tocRef = useOutsideClick(closeTocOnOutsideClick);

  const { data: topic, isFetching: isFetchingTopic } = useLibraryQuery(hashId);

  const articleHashIds = topic?.linkages?.articles?.map(prop('id'));
  const { data: articles, isFetching: isFetchingArticles } =
    useLibraryQuery(articleHashIds);

  const biomarkerHashIds = topic?.linkages?.biomarkers?.map(prop('id'));
  const { data: biomarkers, isFetching: isFetchingBiomarkers } =
    useLibraryQuery(biomarkerHashIds);

  const { data: linkedItem, isFetching: isFetchingLinkedItem } =
    useLibraryQuery(linkageId);

  const relatedTopics = topic?.linkages?.topics;

  const isFetchingTopicRelatedItems =
    isFetchingTopic || isFetchingArticles || isFetchingBiomarkers;

  const isFetching = isFetchingTopicRelatedItems || isFetchingLinkedItem;

  useEffect(() => {
    if (Boolean(relatedTopics?.length)) {
      if (!tocRoot?.children?.find((child) => child?.id === RELATED_ID)) {
        tocRoot?.children.push({
          id: RELATED_ID,
          key: RELATED_ID,
          label: t('library.topic.related'),
          complete: () => false,
          completed: false,
          rendering: true,
          slug: t('library.topic.slug'),
        });
      }
    }
  }, [relatedTopics, tocRoot, t]);

  const showNavigation =
    articles?.length > 0 || biomarkers?.length > 0 || relatedTopics?.length > 0;

  useEffect(() => {
    if (isFetchingTopicRelatedItems || isNil(topic)) return;

    const children = [
      {
        id: ROOT_ID,
        label: topic.name,
        complete: always(false),
      },
    ];

    const topicArticles = topic?.linkages?.articles;
    if (topicArticles?.length) {
      const articleChildren = topicArticles.map((article) => ({
        id: article.id,
        label: article.label,
        dataRef: { article },
        complete: always(false),
      }));

      children.push(...articleChildren);
    }

    if (biomarkers?.length) {
      const biomarkerChildren = biomarkers.map((biomarker) => ({
        id: biomarker.id,
        label: biomarker.name,
        dataRef: { biomarker },
        complete: always(false),
      }));

      children.push(...biomarkerChildren);
    }

    setTocRoot({ children });
  }, [topic, biomarkers, isFetchingTopicRelatedItems]);

  const activeId = linkageId ?? ROOT_ID;
  const flattenNavNodes = tocRoot?.children ?? [];
  const handleNavigation = (page) =>
    page?.id !== ROOT_ID ? onNavigate(page) : onNavigate();

  const { prev: prevNode, next: nextNode } = usePrevNextNavigation(
    flattenNavNodes,
    activeId
  );

  const prevUrl = topic && `/topic/${topic?.id}/page/${prevNode?.id}`;
  const nextUrl = topic && `/topic/${topic?.id}/page/${nextNode?.id}`;

  if (isFetchingTopicRelatedItems) {
    return (
      <div className="m-4 lg:mx-8">
        <div className="mx-auto max-w-4xl space-y-6">
          <ViewerPageSkeleton />
        </div>
      </div>
    );
  }

  if (isNil(topic)) {
    return <NotFound />;
  }

  return (
    <>
      <ViewerLayout
        topbar={
          <ViewerTopbar
            title={topic.name}
            url={url}
            logos={[ViewerTopbar.smdLogo]}
            onCloseClick={() => onClose()}
            onShareClick={() => setShare(true)}
            onMenuToggle={showNavigation && (() => setTocVisible((v) => !v))}
          />
        }
        sidebar={
          <ViewerSidebar>
            {showNavigation && (
              <ViewerSidebar.Button
                onClick={() => setTocVisible((v) => !v)}
                active={tocVisible}
                aria-haspopup="true"
                aria-expanded={tocVisible}
              >
                <ClipboardList className="w-5" />
                {t('common.table-of-contents')}
              </ViewerSidebar.Button>
            )}
            <ViewerSidebar.Button
              onClick={() => setShare(true)}
              aria-haspopup="dialog"
            >
              <Share className="w-5" /> {t('common.share')}
            </ViewerSidebar.Button>
          </ViewerSidebar>
        }
        aside={
          showNavigation && (
            <ViewerAsideNav
              className={classnames({ hidden: !tocVisible })}
              onClose={() => setTocVisible(false)}
              ref={tocRef}
            >
              {tocRoot && (
                <ViewerTableOfContent
                  root={tocRoot}
                  activeId={activeId}
                  onSelect={handleNavigation}
                />
              )}
            </ViewerAsideNav>
          )
        }
        main={
          <div className="m-4 lg:mx-8">
            <ParentContext.Provider className="relative mx-auto max-w-4xl space-y-6">
              {activeId === RELATED_ID ? (
                <RelatedHealthTopics topics={relatedTopics} />
              ) : (
                <LibraryCardPopOverProvider>
                  {isFetching ? (
                    <ViewerPageSkeleton />
                  ) : linkedItem?.type === 'article' ? (
                    <ViewerPageContainer label={linkedItem.name}>
                      <LibraryArticle withLinks article={linkedItem} />
                    </ViewerPageContainer>
                  ) : linkedItem?.type === 'topic' ? (
                    <ViewerPageContainer label={linkedItem.name}>
                      <LibraryTopic withLinks topic={linkedItem} />
                    </ViewerPageContainer>
                  ) : (
                    <ViewerPageContainer label={topic.name}>
                      <LibraryTopic withLinks topic={topic} />
                    </ViewerPageContainer>
                  )}
                  {showNavigation && (
                    <ViewerPageNavigation
                      nodes={flattenNavNodes}
                      prevLink={prevUrl}
                      nextLink={nextUrl}
                      activeId={activeId}
                    />
                  )}
                </LibraryCardPopOverProvider>
              )}
            </ParentContext.Provider>
          </div>
        }
      />
      <ShareModal
        isOpen={share}
        onClose={() => setShare(false)}
        shareUrl={window.location.href}
      >
        <SharePreview {...topic} />
      </ShareModal>
    </>
  );
}

export default LibraryTopicViewerPage;
