import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  title: PropTypes.string,
  about: PropTypes.string,
  heroes: PropTypes.arrayOf(PropTypes.shape({})),
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
});

const defaults = {
  title: null,
  about: null,
  heroes: [],
  logos: [],
};

export default {
  shape,
  defaults,
};
