import classnames from 'classnames';
import { nth } from 'ramda';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useMedia, useScrollDirection } from 'hooks';

function Wrapper({ children, className = 'h-14' }) {
  return (
    <div
      className={classnames(
        'top-14 z-[25] flex w-full flex-row items-center space-x-2 bg-smd-accent px-7 text-white',
        className
      )}
    >
      {children}
    </div>
  );
}

export default function Breadcrumb({ breadcrumb = [], onItemClick }) {
  const isMedium = useMedia(useMedia.MEDIUM);

  const scrollDirection = useScrollDirection();
  const isScrollingUp = scrollDirection === 'up';

  if (!isMedium) {
    const backItem = nth(-2, breadcrumb);
    return backItem ? (
      <Wrapper className={classnames('h-9', { fixed: isScrollingUp })}>
        <div
          className="flex items-center space-x-2 hover:cursor-pointer hover:underline"
          onClick={() => onItemClick(backItem.href)}
        >
          <ChevronLeft className="w-6 shrink-0 stroke-2" />
          <span className="text-smd-xs line-clamp-1">{backItem.label}</span>
        </div>
      </Wrapper>
    ) : null;
  }

  return (
    <Wrapper className="sticky z-[25] h-14">
      {breadcrumb.map((element, index) => {
        const isLast = index + 1 === breadcrumb.length;
        if (isLast) {
          return (
            <span className={classnames({ 'font-bold': isLast })} key={index}>
              {element.label}
            </span>
          );
        }
        return (
          <div
            onClick={() => onItemClick(element.href)}
            className="flex hover:cursor-pointer hover:underline"
            key={index}
          >
            <span className={classnames({ 'font-bold': isLast })}>
              {element.label}
            </span>
            <ChevronRight className="w-4 shrink-0 stroke-4" />
          </div>
        );
      })}
    </Wrapper>
  );
}
