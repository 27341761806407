import classnames from 'classnames';
import { useScroll } from 'components/Scroll';
import { useMedia } from 'hooks';
import classes from './DropDown.module.css';

function DropDownContainer({ children, className }) {
  const { isScrolled } = useScroll();
  const isLarge = useMedia(useMedia.LARGE);
  return (
    <div
      className={classnames(
        isScrolled && isLarge
          ? classes['szh-menu-custom']
          : isLarge
          ? classes['szh-menu']
          : classes['szh-menu-mobile'],
        className
      )}
    >
      {children}
    </div>
  );
}

export default DropDownContainer;
