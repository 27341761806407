import React from 'react';
import PropTypes from 'prop-types';

import NumericField from '../NumericField';

import { range } from './types';
import { findRangeForValue } from './utils';

function RangeNumeric({ ranges, value, step, onChange }) {
  const range = findRangeForValue(ranges, value);

  const handleChange = (value) => {
    onChange?.(value, findRangeForValue(ranges, value));
  };

  return (
    <div style={{ color: range?.color }}>
      <NumericField value={value} step={step} onChange={handleChange} />
    </div>
  );
}

RangeNumeric.propTypes = {
  ranges: PropTypes.arrayOf(range).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default RangeNumeric;
