import React, { forwardRef } from 'react';

import { useDefaultToRandomId } from 'hooks';

const Upload = forwardRef(function Upload(props, ref) {
  return <input ref={ref} type="file" {...props} />;
});

function UploadImage({ ...props }) {
  return <UploadFile accept="image/*" {...props} />;
}

// controlled prop
function UploadFile({
  id,
  value,
  label,
  onChange,
  className,
  accept,
  ...props
}) {
  const safeId = useDefaultToRandomId(id);
  const handleUploadChange = (event) => {
    const [file] = event.target.files;
    if (file) {
      onChange(file);
    }
  };

  return (
    <div className={`shrink-0 ${className ?? ''}`}>
      <Upload
        id={safeId}
        onChange={handleUploadChange}
        className="hidden"
        accept={accept}
        {...props}
      />
    </div>
  );
}

export default Object.assign(Upload, { Image: UploadImage, File: UploadFile });
