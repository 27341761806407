import { useMemo, useState } from 'react';

const INITIAL_STATE = {
  sourceModalVisible: false,
  showConfirm: false,
  sourceType: null,
};

function useSourceModal() {
  const [state, setState] = useState(INITIAL_STATE);
  const methods = useMemo(() => {
    return {
      closeAll() {
        setState((oldState) => ({
          ...INITIAL_STATE,
          sourceType: oldState?.sourceType,
        }));
      },
      showConfirmModal() {
        setState((oldState) => ({
          sourceModalVisible: false,
          showConfirm: true,
          sourceType: oldState?.sourceType,
        }));
      },
      showSourceModal(sourceType) {
        setState({
          sourceModalVisible: true,
          showConfirm: false,
          sourceType,
        });
      },
    };
  }, []);

  return { state, methods };
}

export default useSourceModal;
