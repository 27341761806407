import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const cachedAdIds = new Set();

export default function AdManager({ adKey, slot, size, style, className }) {
  const [show, setShow] = useState(true);

  // TODO: Add resize handling and control the closest size manually
  useEffect(() => {
    if (!slot || !adKey) return;

    window.googletag = window.googletag || { cmd: [] };
    const googletag = window.googletag;
    let adSlot;

    if (!cachedAdIds.has(slot)) {
      // define ad slot and enable ad without loading it
      googletag.cmd.push(() => {
        adSlot = googletag
          .defineSlot(adKey, size, slot)
          .addService(googletag.pubads());
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });

      // check if the ad returns a response and hide it if it doesn't
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .addEventListener('slotResponseReceived', (e) =>
            setShow(Boolean(e.slot.getResponseInformation()))
          );
      });

      // set the ad as ready to be displayed and load it by refreshing it
      googletag.cmd.push(() => {
        googletag.display(slot);
        googletag.pubads().refresh([adSlot]);
        // googletag.openConsole();
      });

      // add ad slot to the cache, to trigger refresh only if already loaded before
      cachedAdIds.add(slot);
    } else {
      // check if the ad returns a response and hide it if it doesn't
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .addEventListener('slotResponseReceived', (e) =>
            setShow(Boolean(e.slot.getResponseInformation()))
          );
      });
      // refresh ad from the cache
      googletag.cmd.push(() => {
        googletag.pubads().refresh();
      });
    }

    return () => {
      if (adSlot && cachedAdIds.has(slot)) {
        googletag.cmd.push(() => {
          googletag.pubads().clear([adSlot]);
        });
      }
    };
  }, [adKey, size, slot]);

  if (!show) return null;
  if (!slot || !adKey) return null;
  return (
    <>
      <Helmet>
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        />
      </Helmet>
      <div className={className}>
        <div id={slot} style={style} />
      </div>
    </>
  );
}
