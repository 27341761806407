import { ContentContainer } from 'components';
import HeroContentItem from 'components/PageSections/components/HeroContentItem';

function HeroJumbotron({ content }) {
  return (
    <section>
      <ContentContainer className="px-4">
        <HeroContentItem content={content} />
      </ContentContainer>
    </section>
  );
}

export default HeroJumbotron;
