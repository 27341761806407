import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Form } from 'components/forms';
import { ucumConvertValues } from 'my-phr/utils/conversion';

import { DEFAULT_RANGE_STEP } from 'my-phr/const';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

export function NumberInput({ field, ...props }) {
  const { t } = useTranslation();
  const newProps = useManualInputDefaultProps(props);

  const watchMaxValue = props?.watch(`${props?.prefix}.numerator`);

  const minValueAllowed = field?.rules?.min?.value;
  const maxValueAllowed = !isNaN(field?.rules?.max?.value)
    ? field?.rules?.max?.value
    : watchMaxValue;
  const unit = field?.rules?.max?.unit || field?.rules?.min?.unit;

  const watchUnit = props?.watch(`${props?.prefix}.unit`);

  const [maxAllowed, setMaxAllowed] = useState(null);
  const [minAllowed, setMinAllowed] = useState(null);
  const [minUnitSymbol, setMinUnitSymbol] = useState(null);
  const [maxUnitSymbol, setMaxUnitSymbol] = useState(null);

  useEffect(() => {
    const { convertedValue: max, convertedUnitSymbol: maxSymbol } =
      ucumConvertValues({
        fromUnit: unit,
        fromValue: maxValueAllowed,
        toUnit: watchUnit ?? unit,
      });

    const { convertedValue: min, convertedUnitSymbol: minSymbol } =
      ucumConvertValues({
        fromUnit: unit,
        fromValue: minValueAllowed,
        toUnit: watchUnit ?? unit,
      });

    setMaxAllowed(max);
    setMinAllowed(min);
    setMaxUnitSymbol(maxSymbol);
    setMinUnitSymbol(minSymbol);
  }, [watchUnit, minValueAllowed, maxValueAllowed, unit]);

  const isMinPresent = Boolean(field?.rules?.min);
  const isMaxPresent = Boolean(field?.rules?.max);

  const step = newProps?.step || newProps?.ranges?.step || DEFAULT_RANGE_STEP;

  return (
    <Form.Input
      {...newProps}
      name={newProps.name}
      type="number"
      className={classnames({ 'max-w-[21.6rem] md:max-w-md': !unit })}
      step={step}
      labelProps={newProps.labelProps}
      onWheel={(e) => e.target.blur()}
      rules={{
        required: t('errors.required'),
        ...(isMinPresent && {
          min: {
            value: minAllowed,
            message: t('errors.min-value', {
              field: field?.label,
              value: minAllowed?.toFixed(2),
              unit: minUnitSymbol,
            }),
          },
        }),
        ...(isMaxPresent && {
          max: {
            value: maxAllowed,
            message: t('errors.max-value', {
              field: field?.label,
              value: maxAllowed?.toFixed(2),
              unit: maxUnitSymbol,
            }),
          },
        }),
      }}
    />
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.NUMBER, NumberInput);
