import { forwardRef, useEffect, useRef } from 'react';

function useOutsideClickSynthetic(onClick = () => {}) {
  const frameRequestRef = useRef();

  function cancelAnimationFrameRequest() {
    if (typeof frameRequestRef.current === 'number') {
      cancelAnimationFrame(frameRequestRef.current);
      frameRequestRef.current = undefined;
    }
  }

  const onClickRef = useRef(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useEffect(() => {
    onClickRef.current = onClick;
  });

  useEffect(() => {
    function onOutsideClick() {
      frameRequestRef.current = undefined;
      onClickRef.current();
    }

    function onWindowCaptureClick() {
      cancelAnimationFrameRequest();
      frameRequestRef.current = requestAnimationFrame(onOutsideClick);
    }

    window.addEventListener('click', onWindowCaptureClick, { capture: true });

    return () => {
      window.removeEventListener('click', onWindowCaptureClick, {
        capture: true,
      });
      cancelAnimationFrameRequest();
    };
  }, []);

  return cancelAnimationFrameRequest;
}

function OutsideClickContainer({ onOutsideClick = () => {}, ...props }, ref) {
  const onClickCapture = useOutsideClickSynthetic(onOutsideClick);
  return (
    <div
      ref={ref}
      {...props}
      onClickCapture={(...args) => {
        onClickCapture(...args);
        props.onClickCapture?.(...args);
      }}
    />
  );
}

export default forwardRef(OutsideClickContainer);
