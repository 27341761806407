function ViewerPageTopBar({ left, right }) {
  return (
    <div className="flex justify-between bg-smd-accent py-2 px-4 text-white">
      <div className="flex items-center space-x-2">{left}</div>
      <div className="flex items-center space-x-2">{right}</div>
    </div>
  );
}

export default ViewerPageTopBar;
