import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { JournalsApi } from 'api';
import {
  Button,
  ErrorLabel,
  Input,
  Label,
  SquareButton,
} from 'components/forms';
import { X } from 'components/icons';

export default function ReferralCode({ onComplete, onCancel }) {
  const { t } = useTranslation();
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const { isFetching } = useQuery(
    ['definitions', 'referralCode', code?.referralCode],
    () => JournalsApi.getDefinitionByReferralCode(code?.referralCode),
    {
      enabled: !!code?.referralCode,
      onSuccess: (res) => onComplete?.(res),
      onError: (err) => setError(err),
    }
  );

  const handleReferralCodeChange = (code) => {
    setCode(code);
  };

  return (
    <div className="w-screen bg-white p-4 md:w-auto md:p-8">
      <div className="relative flex flex-col items-center md:px-8">
        <SquareButton
          className="absolute top-0 right-0"
          onClick={onCancel}
          aria-label={t('common.close')}
        >
          <X strokeWidth="3" />
        </SquareButton>

        <ReferralCodeForm
          onSubmit={handleReferralCodeChange}
          serverError={error}
          loading={isFetching}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
}

const uuidRe = /^[a-z0-9]{5}-[a-z0-9]{5}$/;
const uuidFormat = 'XXXXX-XXXXX';

function ReferralCodeForm({ onSubmit, serverError, loading, onCancel }) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      referralCode: '',
    },
  });

  useEffect(() => {
    if (serverError) {
      setError('referralCode', {
        type: 'manual',
        message: serverError.message,
      });
    }
  }, [serverError, setError]);

  return (
    <>
      <h2 className="mb-8 text-center text-lg font-bold">
        {t('journals.referral-code.enter')}
      </h2>
      <p className="text-center">{t('journals.referral-code.sub-title')}</p>
      <form
        className="mt-12 flex w-full max-w-sm flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Label htmlFor="referralCode">
          {t('journals.referral-code.label')}
        </Label>
        <Controller
          control={control}
          name="referralCode"
          rules={{
            required: t('journals.referral-code.enter'),
            pattern: {
              value: uuidRe,
              message: t('journals.referral-code.enter-valid'),
            },
          }}
          render={({ field: { onChange, ...props } }) => (
            <Input
              id="referralCode"
              placeholder={uuidFormat}
              onChange={({ target: { value } }) =>
                onChange(value.trim().toLowerCase())
              }
              autoFocus
              aria-label={t('journals.referral-code.enter')}
              {...props}
            />
          )}
        />
        <ErrorLabel error={errors.referralCode} />
        <div className="mt-6 flex flex-wrap justify-center space-y-2 sm:space-y-0 sm:space-x-2">
          <Button.Primary
            size="md"
            type="submit"
            className="w-full sm:w-auto"
            loading={loading}
          >
            {t('journals.referral-code.search-healthjournals')}
          </Button.Primary>
          <Button.Tertiary
            size="lg"
            className="w-full sm:w-auto"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </Button.Tertiary>
        </div>
      </form>
    </>
  );
}
