import { useTranslation } from 'react-i18next';
import { dissoc } from 'ramda';
import { isBefore } from 'date-fns';
import { Form } from 'components/forms';
import { safeDateFormat, toMidnightTime } from 'utils';
import { DATE_PRESETS } from 'my-phr/const';
import { MANUAL_INPUT_TYPE } from '../const';
import { useManualInputDefaultProps } from '../hooks';
import manualInputsRegistry from '../manualInputsRegistry';
import { getManualInput, maxAllowedDate, minAllowedDate } from '../utils';

export function DateRangeInput({
  startInputProps,
  endInputProps,
  watch,
  prefix,
  ...props
}) {
  const { t } = useTranslation();
  const watchStartDate = watch(`${prefix}.${startInputProps?.name}`);
  const dateTimeValue =
    startInputProps?.inputType === MANUAL_INPUT_TYPE.DATE_TIME;

  return (
    <div className="flex flex-wrap items-baseline gap-2">
      {getManualInput(startInputProps?.inputType ?? MANUAL_INPUT_TYPE.DATE, {
        ...props,
        ...dissoc('inputType', startInputProps),
        prefix,
        max: new Date(),
        groupClassName: 'grow',
      })}
      {getManualInput(endInputProps?.inputType ?? MANUAL_INPUT_TYPE.DATE, {
        ...props,
        ...dissoc('inputType', endInputProps),
        min: watchStartDate ? new Date(watchStartDate) : new Date(),
        prefix,
        defaultValue: '',
        rules: {
          validate: {
            min: (v) =>
              Boolean(v)
                ? !isBefore(v, new Date(watchStartDate)) ||
                  t('errors.min-date', {
                    field: endInputProps.label,
                    value: safeDateFormat({
                      date: new Date(watchStartDate),
                      pattern: dateTimeValue
                        ? DATE_PRESETS.DATE_TIME
                        : DATE_PRESETS.DATE_ONLY,
                      fallback: '-',
                    }),
                  })
                : true,
          },
        },
        groupClassName: 'grow',
      })}
    </div>
  );
}
manualInputsRegistry.register(MANUAL_INPUT_TYPE.DATE_RANGE, DateRangeInput);

export function DateInput({ max = maxAllowedDate, ...props }) {
  const newProps = useManualInputDefaultProps(props);
  return (
    <Form.InputDate
      dateFormat={DATE_PRESETS.DATE_ONLY}
      placeholder="mm/dd/yyyy"
      showYearDropdown
      showMonthDropdown
      defaultValue={newProps?.getValues()?.date ?? new Date()}
      min={minAllowedDate}
      max={max}
      className="w-3/4"
      {...newProps}
    />
  );
}
manualInputsRegistry.register(MANUAL_INPUT_TYPE.DATE, DateInput);

export function DateTimeInput(props) {
  const newProps = useManualInputDefaultProps(props);
  return (
    <Form.InputDateTime
      {...newProps}
      className="w-auto"
      defaultValue={newProps?.getValues()?.date ?? toMidnightTime(new Date())}
      min={minAllowedDate}
      max={new Date()}
    />
  );
}
manualInputsRegistry.register(MANUAL_INPUT_TYPE.DATE_TIME, DateTimeInput);
