import { useState, useEffect, useRef } from 'react';

const useTextOverflowDetector = (initiallyShownLines, description) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(descriptionRef.current).lineHeight
      );
      const maxHeight = lineHeight * initiallyShownLines;
      setIsOverflowing(descriptionRef.current.clientHeight >= maxHeight);
    }
  }, [description, initiallyShownLines]);

  return [descriptionRef, isOverflowing];
};

export default useTextOverflowDetector;
