import { useQuery } from 'react-query';

import { useLibraryApi } from '../libraryApiContext';

export function useLibrarySearchSummary(query, options) {
  const api = useLibraryApi();

  return useQuery(
    ['library', 'search', 'summary', query],
    () => api.getLibrarySearchSummary(query),
    {
      ...options,
    }
  );
}
