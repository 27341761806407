import useDistribution from './useDistribution';

const FEATURES = {
  BETA: 'beta',
  COMMUNITIES: 'communities',
  MYLIBRARY: 'myLibrary',
  NEWSLETTER: 'newsletter',
  SIGNUP: 'signup',
  STATICPAGES: 'staticPages',
  SMD_HEALTH: 'smdHealth',
};

const envToggles = {
  [FEATURES.BETA]: true,
  [FEATURES.COMMUNITIES]: process.env.REACT_APP_COMMUNITIES_STATE === 'ENABLED',
  [FEATURES.MYLIBRARY]: true,
  [FEATURES.NEWSLETTER]: true,
  [FEATURES.SIGNUP]: true,
  [FEATURES.STATICPAGES]: true,
  [FEATURES.SMD_HEALTH]: true,
};

export default function useIsFeatureEnabled(feature) {
  const distributionToggles = useDistribution(['features']);

  const envToggle = envToggles[feature] ?? false;
  const distributionToggle = distributionToggles?.[feature] ?? true;

  return envToggle && distributionToggle;
}

Object.assign(useIsFeatureEnabled, FEATURES, { Feature: FEATURES });
