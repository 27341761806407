import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { nanoid } from 'nanoid';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, ContentContainer } from 'components';
import { NewsletterForm } from 'components/Newsletter';
import { Button } from 'components/forms';
import { useNewsletter } from 'hooks';
import { PictureResource } from 'components/media';
import { extractWebpThumbnail, getFocalPosition } from 'utils';

function KeepInTouch({ data }) {
  const { t } = useTranslation();
  const webpThumbnail = extractWebpThumbnail(data?.featuredAsset?.images);

  const randomId = nanoid();

  const { position, isValid } = getFocalPosition(
    data?.featuredAsset?.focalPoint
  );

  if (isNil(data)) {
    return null;
  }

  return (
    <section aria-labelledby={randomId} className="bg-smd-accent-light">
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <div className="grid lg:grid-cols-2">
            <div className="flex flex-col py-12 md:max-w-lg lg:max-w-none lg:pr-10 xl:pr-20">
              <h2
                id={randomId}
                className="mb-2 text-smd-h2.5 font-bold lg:text-smd-h1"
              >
                {data?.title || t('landing.keep-touch')}
              </h2>
              <p className="mb-2 text-smd-sm">{data.description}</p>

              <NewsletterSubscribe btnText={data.ctaText} />
            </div>
            <div className="relative hidden overflow-hidden lg:inline-block">
              <PictureResource
                config={{
                  url:
                    webpThumbnail?.path ||
                    data?.featuredAsset?.thumbnail ||
                    data?.featuredAsset?.url,
                  alt: data?.featuredAsset?.alt,
                  images: data?.featuredAsset?.images,
                }}
                style={{
                  objectPosition: isValid ? position : 'center',
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
                loading="lazy"
                sizes="22rem"
              />
            </div>
          </div>
        </ContentContainer>
      </div>
    </section>
  );
}

function NewsletterSubscribe({ btnText }) {
  const { t } = useTranslation();

  const [mailState, setMailState] = useState();

  const reCaptchaRef = useRef();

  const { subscribe, isLoading } = useNewsletter({
    onSuccess: (res) => {
      setMailState({ type: res?.data?.status, msg: res?.data?.response });
    },
    onError: (err) => {
      setMailState({
        type: 'error',
        msg: err?.message || t('errors.default-error-page.title'),
      });
    },
  });

  const onSubmit = (data) => {
    reCaptchaRef.current
      .executeAsync()
      .then((token) => {
        data['g-recaptcha-response'] = token;
        return data;
      })
      .then(subscribe);
  };

  return (
    <>
      <NewsletterForm
        className="w-full py-4"
        inputClassName="w-full bg-smd-accent-light border-smd-gray-dark placeholder:text-smd-gray-dark focus-visible:bg-white"
        onSubmit={onSubmit}
      >
        <Button.Primary
          className="font-semibold"
          data-testid="landingPageNewsletterSubscribeButton"
          loading={isLoading}
          aria-label={t('labels.menu.newsletter')}
        >
          {btnText || t('common.subscribe')}
        </Button.Primary>
        <div className="invisible fixed z-10">
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size="invisible"
          />
        </div>
      </NewsletterForm>
      {mailState && <Alert res={mailState} destroy={() => setMailState()} />}
    </>
  );
}

export default KeepInTouch;
