import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronUp } from 'components/icons';
import { ErrorBoundary } from 'react-error-boundary';

function Toggle({ open }) {
  return (
    <span className="inline-block rounded-full bg-smd-accent p-1 text-white">
      <ChevronUp
        className={`h-2.5 w-2.5 transform stroke-4 duration-200 ${
          open ? 'rotate-0' : 'rotate-180'
        }`}
      />
    </span>
  );
}

export default function ExpandableSection({ title, open, onClick, children }) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className="smd-focus-visible-primary relative rounded-sm pl-8 text-left"
        style={{ zIndex: 1 }} // for Safari lazy repaint bug
        onClick={onClick}
        aria-label={t('labels.journals.expand-section', {
          article: title,
        })}
      >
        <span className="absolute top-1.5 left-0 -mt-px flex items-center">
          <Toggle open={open} />
        </span>
        <span
          className="empty text-lg font-semibold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </button>
      {open && (
        <ErrorBoundary fallbackRender={() => null}>
          <div className="mt-1">{children}</div>
        </ErrorBoundary>
      )}
    </>
  );
}
