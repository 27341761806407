import { replaceSupTag } from 'utils';
import { Form } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';

function SelectDropdownInput({ options, ...props }) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8',
  });

  const selectedValue = newProps?.defaultValues?.[newProps?.name];

  return (
    <Form.Select
      {...newProps}
      disabled={options?.length === 1}
      defaultValue={selectedValue || options?.[0]?.value}
    >
      {options.map(({ value, rangeValue, label, disabled }, index) => {
        return (
          <option
            key={`${index}${value}`}
            value={rangeValue ? JSON.stringify({ value, rangeValue }) : value}
            {...disabled}
          >
            {replaceSupTag(label)}
          </option>
        );
      })}
    </Form.Select>
  );
}

export default SelectDropdownInput;
