import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Aspect, ContentContainer, CTA, Video } from 'components';
import { Carousel, carouselBaseConfig, useCarousel } from 'components/Carousel';
import { extractWebpThumbnail, getAssetUrl } from 'utils';
import { normalizeAssetUrl } from 'journals/utils';
import { PictureResource } from 'components/media';

function EducationalSection({ data = [], carouselProps, className }) {
  const props = {
    ...carouselBaseConfig,
    spaceBetween: 30,
    autoHeight: false,
    ...carouselProps,
  };

  return (
    <section
      className={classnames('py-12', className)}
      aria-label="Educational Videos"
    >
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <Carousel
            className="relative -mx-4 lg:-mx-12 lg:px-12"
            carouselProps={props}
            slides={data.map((item, index) => (
              <Carousel.Slide key={JSON.stringify(item)}>
                <EducationalSlideContent item={item} index={index} />
              </Carousel.Slide>
            ))}
          >
            <Carousel.Prev className="top-auto -bottom-2 lg:bottom-0 lg:top-0" />
            <Carousel.Next className="top-auto -bottom-2 lg:bottom-0 lg:top-0" />
            <Carousel.Pagination className="pt-6" />
          </Carousel>
        </ContentContainer>
      </div>
    </section>
  );
}

function EducationalSlideContent({ item, index }) {
  const { carousel } = useCarousel();
  const webpThumbnail = extractWebpThumbnail(item?.featuredAsset?.images);
  const isActive = Boolean(carousel?.activeIndex === index);
  const [playPause, setPlayPause] = useState({ playing: false });

  useEffect(() => {
    if (!isActive) {
      setPlayPause({ playing: false });
    }
  }, [isActive]);

  return (
    <div className="flex flex-col items-center px-4 lg:flex-row lg:space-x-8 lg:px-0">
      <div className="order-2 mt-4 grid w-full flex-1 justify-start lg:order-1 lg:mt-0 lg:content-center">
        <div>
          <h2 className="mb-2 hidden text-smd-h1 font-bold lg:block">
            {item.section.title}
          </h2>
          <p
            className="mb-4 text-smd-base"
            dangerouslySetInnerHTML={{
              __html: item.section.description,
            }}
          />
          <CTA
            color="primary"
            className="font-bold"
            ctaProps={item.section.ctaProps}
          >
            {item.section.ctaText}
          </CTA>
        </div>
      </div>
      <div className="relative order-1 w-full flex-1 lg:order-2">
        <h2 className="mb-4 block text-smd-h2.5 font-bold lg:hidden">
          {item.section.title}
        </h2>
        {item?.type === 'video' && (
          <Aspect.Fixed ratio={16 / 9} style={{ overflow: 'hidden' }}>
            <Video
              {...{
                thumbnail:
                  getAssetUrl(webpThumbnail?.path) ||
                  getAssetUrl(item?.poster) ||
                  getAssetUrl(item?.thumbnail),
                url: getAssetUrl(item?.key),
                controls: true,
                className: 'prevent-swiper border',
                width: '100%',
                height: '100%',
                playPause,
              }}
            />
          </Aspect.Fixed>
        )}
        {item?.type === 'image' && (
          <Aspect.Fixed ratio={16 / 9} style={{ overflow: 'hidden' }}>
            <PictureResource
              config={{
                url:
                  normalizeAssetUrl(webpThumbnail?.path) ||
                  normalizeAssetUrl(item?.featuredAsset?.thumbnail) ||
                  normalizeAssetUrl(item?.key),
                alt: item?.featuredAsset?.alt,
                images: item?.featuredAsset?.images,
              }}
              style={{
                objectPosition: 'center',
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
              loading="lazy"
              sizes="22rem"
            />
          </Aspect.Fixed>
        )}
      </div>
    </div>
  );
}

export default EducationalSection;
