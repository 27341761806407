import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'ProfileContext';
import { Avatar, Modal } from 'components';
import { useModalClose } from 'components/Modal/useModalClose';
import { getProfileAvatarUrl } from 'utils';
import { ChevronLeft, X } from 'components/icons';
import { useState } from 'react';
import assetType from 'library/propTypes/assetType';
import { default as LibraryElementPicker } from 'library/components/element-picker';
import ConversationForm from './ConversationForm';

function ConversationModalWrapper(props) {
  const { isOpen, onRequestClose, className, children, ...rest } = props;

  const closeProps = useModalClose(onRequestClose);
  return (
    <div
      className="h-full w-full overflow-y-auto overflow-x-hidden bg-white md:bg-transparent"
      {...rest}
    >
      <div className="flex min-h-full" {...closeProps}>
        <div className="m-auto md:w-10/12 lg:w-6/12">{children}</div>
      </div>
    </div>
  );
}

export default function CreateConversationModal({
  isOpen,
  isSubmitButtonLoading,
  onSubmit,
  onClose,
  inEditMode,
  defaultValues,
}) {
  const [profile] = useProfile();
  const { t } = useTranslation();
  const [libraryPickerOpen, setLibraryPickerOpen] = useState(false);
  const [libraryAssets, setLibraryAssets] = useState(
    inEditMode ? defaultValues?.libraryAssets : []
  );

  const url = getProfileAvatarUrl(profile);

  const onCloseCallback = () => {
    onClose();
    setLibraryPickerOpen(false);
    if (!inEditMode) {
      setLibraryAssets([]);
    }
  };

  const onRemoveAsset = (assetId) =>
    setLibraryAssets((old) => old.filter(({ id }) => id !== assetId));

  const handleAssetsSelected = (assets) => {
    setLibraryAssets(assets);
    setLibraryPickerOpen(false);
  };

  const handleFormSubmit = (values) => {
    onSubmit({ ...values, additionalFields: { libraryAssets } });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseCallback}
      contentWrapper={ConversationModalWrapper}
    >
      <Modal.ResponsiveContainer
        className="relative h-screen w-screen flex-col items-center md:h-auto md:w-full md:max-w-full"
        role="dialog"
        aria-modal="true"
      >
        {/* Header */}
        <div className="flex w-full flex-row items-center bg-smd-accent p-4 text-white md:bg-white md:px-8 md:pt-8 md:pb-0 md:text-smd-gray-darker">
          {!libraryPickerOpen && (
            <>
              <div className="hidden flex-none md:block">
                <Avatar className="mr-4 h-10 w-10" src={url} />
              </div>
              <div className="flex-grow">
                <p className="text-smd-base font-semibold">
                  {inEditMode
                    ? t('communities.edit-conversation')
                    : t('conversations.create-new')}
                </p>
              </div>
            </>
          )}
          {libraryPickerOpen && (
            <>
              <div className="flex-none">
                <ChevronLeft
                  className="text-bold mr-4 h-6 w-6 cursor-pointer"
                  onClick={() => setLibraryPickerOpen(false)}
                />
              </div>
              <div className="flex-grow">
                <p className="text-smd-base font-semibold">
                  {t('communities.import-from-storymd-library')}
                </p>
              </div>
            </>
          )}
          <X
            strokeWidth="2"
            className="text-bold h-6 w-6 cursor-pointer"
            onClick={onCloseCallback}
          />
        </div>
        {/* Header end */}
        <div className="h-full w-full bg-white p-4 md:px-10 md:pb-10">
          <div className="relative w-full pb-4 md:pb-0">
            <ConversationForm
              libraryAssets={libraryAssets}
              onRemoveAsset={onRemoveAsset}
              defaultValues={defaultValues}
              onSubmit={handleFormSubmit}
              onClose={onCloseCallback}
              className={libraryPickerOpen ? 'hidden' : ''}
              handleLibraryPickButtonClick={() => setLibraryPickerOpen(true)}
              inEditMode={inEditMode}
              isLoading={isSubmitButtonLoading}
            />
            {libraryPickerOpen && (
              <LibraryElementPicker
                onSelect={handleAssetsSelected}
                onHide={() => setLibraryPickerOpen(false)}
                assetTypes={[assetType.IMAGE, assetType.VIDEO]}
                selectedAssets={libraryAssets}
              />
            )}
          </div>
        </div>
      </Modal.ResponsiveContainer>
    </Modal>
  );
}

CreateConversationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitButtonLoading: PropTypes.bool.isRequired,
  defaultTags: PropTypes.string,
  inEditMode: PropTypes.bool,
};

CreateConversationModal.defaultProps = {
  defaultTags: '',
  inEditMode: false,
};
