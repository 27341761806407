import React, { useCallback, useState } from 'react';

import { useDefaultToRandomId } from 'hooks';
import { CropImage } from '..';
import Avatar from '../Avatar';
import Upload from './Upload';

const initialCrop = { width: 100, unit: '%', aspect: 1 };

// controlled prop only
export default function UploadAvatar({
  id,
  avatarUrl,
  className,
  label,
  value,
  onChange,
  returnType,
  ...props
}) {
  const safeId = useDefaultToRandomId(id);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleChange = useCallback((file) => {
    onChange(file);
  }, []); // eslint-disable-line

  return (
    <div className="flex flex-col items-center md:flex-row md:space-x-2">
      {uploadedImage ? (
        <CropImage
          file={uploadedImage}
          returnType={returnType}
          initialCrop={initialCrop}
          onChange={handleChange}
        />
      ) : (
        <label htmlFor={safeId} className="cursor-pointer text-smd-accent ">
          <Avatar src={avatarUrl || value} className={className} />
        </label>
      )}
      <label
        htmlFor={safeId}
        className="shrink-0 cursor-pointer text-smd-accent"
      >
        <span className="m-2 text-sm ">{label}</span>
      </label>
      <Upload.Image id={safeId} onChange={setUploadedImage} {...props} />
    </div>
  );
}
