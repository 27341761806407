import { prop } from 'ramda';

import axios from './axios';
import partner from './mock/partner';

const partnersMock = Array(10)
  .fill()
  .map(() => {
    return partner.create();
  });

export default {
  getAll() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(partnersMock);
      }, 200);
    });
  },
  create(partner) {
    return axios
      .post('/partners', getPartnerFormData(partner), {
        headers: Headers.FORMDATA,
      })
      .then(prop('data'));
  },
  addUsers(users) {
    return axios.put('/partners/accounts', users).then(prop('data'));
  },
  getDetails(partnerId) {
    return axios.get(`/partners/${partnerId}`).then(prop('data'));
  },
};

function getPartnerFormData(partner) {
  const { lightLogo, darkLogo, ...rest } = partner;

  const formData = new FormData();
  if (lightLogo instanceof Blob) formData.append('lightLogo', lightLogo);
  if (darkLogo instanceof Blob) formData.append('darkLogo', darkLogo);
  const body = new Blob([JSON.stringify(rest)], {
    type: 'application/json',
  });
  formData.append('body', body);
  return formData;
}
