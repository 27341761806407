import { Link } from 'react-router-dom';
import { Card } from 'components';
import { useCardSize } from 'hooks';
import { SmartJournalCard } from 'journals/components';
import { getJournalSlugPath } from 'journals/utils';
import { useStoryLastLocation } from 'library/hooks';
import { Clock, Play } from 'components/icons';

export function JourneyHealthTopicCard({ card }) {
  return <SmartJournalCard journal={card} to={getJournalSlugPath(card)} />;
}

export function JourneyVideoCard({ card, onClick }) {
  const { imgHeight, bodyHeight } = useCardSize({
    image: {
      large: 0.75,
      small: 0.8,
    },
    body: {
      large: 0.25,
      small: 0.2,
    },
  });
  const props = {
    imgStyle: {
      height: imgHeight,
      objectFit: 'contain',
      background: 'rgb(245, 245, 245)',
    },
    bodyStyle: {
      height: bodyHeight,
    },
  };
  return (
    <div className="relative cursor-pointer">
      <Card
        {...props}
        heading={card?.name}
        cover={{ url: card?.thumbnail }}
        onClick={onClick}
      />
      <div className="pointer-events-none absolute inset-x-0 top-20 z-50 flex justify-center">
        <div className="pointer-events-none rounded-full bg-black/50 p-4 text-white md:p-7">
          <Play className="pointer-events-none h-6 w-6" />
        </div>
      </div>
      {card?.duration && (
        <div className="absolute top-40 right-3 z-30 flex items-center space-x-2 rounded-md bg-black/50  px-2 py-1 text-smd-sm text-white md:top-52">
          <Clock className="h-4 w-4 shrink-0 stroke-2" />
          <span>{card.duration}</span>
        </div>
      )}
    </div>
  );
}

export function JourneyEventCard({ card }) {
  const { imgHeight, bodyHeight } = useCardSize();
  const { setStoryLastLocation } = useStoryLastLocation();
  const props = {
    imgStyle: {
      height: imgHeight,
    },
    bodyStyle: {
      height: bodyHeight,
    },
  };
  return (
    <Link to={`/story/${card?.slug}`}>
      <Card
        {...props}
        content={card?.description}
        heading={card?.name}
        preamble={{ title: card?.name }}
        cover={{ url: card?.thumbnail }}
        onClick={setStoryLastLocation}
      />
    </Link>
  );
}
