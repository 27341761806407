import { isNil, lensPath, over, split, type, view } from 'ramda';

import { getAssetUrl } from 'utils';

export default function withCdnResources(paths = [], Component) {
  if (type(paths) !== 'Array') {
    throw new Error('WithCdnResources error - missing or invalid paths');
  }
  const lenses = paths.map(split('.')).map(lensPath);
  return function WithCdnResources(props) {
    const newProps = lenses.reduce((props, lens) => {
      if (isNil(view(lens, props))) return props;
      return over(lens, getAssetUrl, props);
    }, props);
    return <Component {...newProps} />;
  };
}
