import { communitiesApi, default as journalsApi } from './axios';

export default {
  getAll({ page, size }) {
    return communitiesApi
      .get('/notifications', { params: { page, size } })
      .then(({ data }) => data);
  },

  getUnread() {
    return communitiesApi.get(`/notifications/exists`).then(({ data }) => data);
  },

  delete(notificationId) {
    return communitiesApi.delete(`/notifications/${notificationId}`);
  },

  deleteAll() {
    return communitiesApi.delete(`/notifications`);
  },

  getConfig() {
    return journalsApi.get('/user-notification').then(({ data }) => data);
  },

  updateConfig(data) {
    return journalsApi.post('/user-notification', data);
  },

  toggleNotifications(value) {
    return journalsApi.post(`/user-notification/${value}`);
  },
};
