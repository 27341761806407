import classnames from 'classnames';
import { BtnSpinner } from 'components/icons';
import { ButtonFlavor, ButtonProps, ButtonSize } from './Button.types';

const classBase = [
  'relative',
  'text-smd-base',
  'focus:outline-none focus-visible:outline-none select-none',
  'disabled:cursor-auto',
  'antialiased',
];

const btnDefaultClassNames = [
  'inline-flex space-x-2 items-center justify-center',
  ...classBase,
];

const btnRoundDefaultClassNames = [
  'inline-grid items-center justify-center',
  ...classBase,
];

const styles = {
  primary:
    'text-white bg-smd-accent hover:bg-smd-accent-dark disabled:bg-smd-gray-light disabled:text-white',
  secondary:
    'text-smd-accent bg-smd-accent-light hover:bg-smd-accent-lighter disabled:bg-smd-gray-light disabled:text-white',
  tertiary:
    'text-smd-accent bg-transparent shadow-smd-inset hover:bg-smd-accent-light disabled:bg-smd-gray-light disabled:text-white disabled:shadow-none',
  negative:
    'text-smd-white bg-transparent shadow-smd-inset-negative hover:bg-smd-accent hover:shadow-none disabled:bg-smd-gray-light disabled:text-white disabled:shadow-none',
  ghost:
    'text-smd-accent bg-transparent hover:bg-smd-accent-light disabled:bg-smd-gray-light disabled:text-white',
  anchor:
    'text-smd-accent bg-transparent hover:underline disabled:text-smd-gray',
  alert:
    'text-white bg-smd-error-dark hover:bg-smd-error-darker disabled:bg-smd-gray-light disabled:text-white',
};

const sizes = {
  round: {
    sm: 'p-0 w-smd-btn-sm h-smd-btn-sm',
    md: 'p-0 w-smd-btn-md h-smd-btn-md',
    lg: 'p-0 w-smd-btn-lg h-smd-btn-lg',
  },
  anchor: 'p-0',
  sm: 'px-smd-xs py-smd-xxs',
  md: 'px-smd-lg py-smd-xs',
  lg: 'px-smd-xxl py-smd-xs',
};

const flavors = {
  round: 'rounded-full overflow-hidden',
  rounded: 'rounded',
  flat: 'rounded-none',
  anchor: 'rounded-sm',
};

const determineBtnSizing = (flavor: ButtonFlavor, size: ButtonSize) => {
  if (flavor === 'round') {
    return sizes.round[size];
  }
  if (flavor === 'anchor') {
    return sizes.anchor;
  }
  return sizes[size];
};

const determineBtnDefaults = (flavor: ButtonFlavor) => {
  let defaults = [];
  if (flavor === 'round') {
    defaults = btnRoundDefaultClassNames;
  } else {
    defaults = btnDefaultClassNames;
  }
  return defaults.join(' ');
};

function Button({
  color = 'secondary',
  flavor = 'rounded',
  size = 'md',
  loading,
  disabled,
  className,
  focusClassName = 'smd-focus-visible-primary',
  children,
  as: Element = 'button',
  ...props
}: ButtonProps) {
  return (
    <Element
      className={classnames(
        classBase,
        determineBtnDefaults(flavor),
        styles[color],
        flavors[flavor],
        determineBtnSizing(flavor, size),
        {
          'pointer-events-none overflow-hidden': loading,
        },
        className,
        focusClassName
      )}
      disabled={disabled}
      aria-disabled={disabled}
      {...props}
    >
      {loading && <BtnLoader color={color} />}
      {children}
    </Element>
  );
}

function BtnLoader({ color }) {
  const isTransparentBg = ['ghost', 'anchor', 'tertiary'].includes(color);
  return (
    <span
      className={classnames(
        'absolute inset-1 flex items-center justify-center',
        {
          'bg-inherit': !isTransparentBg,
          'bg-white': isTransparentBg,
        }
      )}
    >
      <BtnSpinner className={classnames('animate-spin')} />
    </span>
  );
}

function PrimaryBtn(props: ButtonProps) {
  const { color = 'primary', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function SecondaryBtn(props: ButtonProps) {
  const { color = 'secondary', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function TertiaryBtn(props: ButtonProps) {
  const { color = 'tertiary', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function NegativeBtn(props: ButtonProps) {
  const { color = 'negative', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function GhostBtn(props: ButtonProps) {
  const { color = 'ghost', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function AnchorBtn(props: ButtonProps) {
  const { color = 'anchor', ...rest } = props;
  return <Button color={color} {...rest} />;
}

function AlertBtn(props: ButtonProps) {
  const { color = 'alert', ...rest } = props;
  return <Button color={color} {...rest} />;
}

Button.Primary = PrimaryBtn;
Button.Secondary = SecondaryBtn;
Button.Tertiary = TertiaryBtn;
Button.Negative = NegativeBtn;
Button.Ghost = GhostBtn;
Button.Anchor = AnchorBtn;
Button.Alert = AlertBtn;

export default Button;
