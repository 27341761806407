import { useMutation, useQueryClient } from 'react-query';
import { CommunitiesApi } from 'api';
import { communitiesKeys } from '../keys';

export function useCommunitiesFollow(searchTerm) {
  const queryClient = useQueryClient();
  const {
    mutate: toggleOtherCommunityFollow,
    isLoading: isOtherCommunityFollowLoading,
  } = useMutation(
    async ({ communityId, following }) => {
      if (following) {
        return CommunitiesApi.unfollowCommunity(communityId).then(() => ({
          communityId,
        }));
      }
      return CommunitiesApi.followCommunity(communityId).then(() => ({
        communityId,
      }));
    },
    {
      onMutate: async ({ communityId, following }) => {
        const queryKey = communitiesKeys.other(searchTerm);
        return updateQueryData(communityId, queryKey, following);
      },
      onError: console.error,
      onSettled: () => {
        queryClient.invalidateQueries(communitiesKeys.other(searchTerm));
        queryClient.resetQueries(communitiesKeys.my());
      },
    }
  );

  const {
    mutate: toggleMyCommunityFollow,
    isLoading: isMyCommunityFollowLoading,
  } = useMutation(
    async ({ communityId, following }) => {
      if (following) {
        return CommunitiesApi.unfollowCommunity(communityId).then(() => ({
          communityId,
        }));
      }
      return CommunitiesApi.followCommunity(communityId).then(() => ({
        communityId,
      }));
    },
    {
      onMutate: async ({ communityId, following }) => {
        const queryKey = communitiesKeys.my(searchTerm);
        return updateQueryData(communityId, queryKey, following);
      },
      onError: console.error,
      onSettled: () => {
        queryClient.invalidateQueries(communitiesKeys.my(searchTerm));
        queryClient.resetQueries(communitiesKeys.other());
      },
    }
  );

  const { mutate: toggleCommunityFollow, isLoading: isCommunityFollowLoading } =
    useMutation(
      async ({ communityId, following }) => {
        if (following) {
          return CommunitiesApi.unfollowCommunity(communityId).then(() => ({
            communityId,
          }));
        }
        return CommunitiesApi.followCommunity(communityId).then(() => ({
          communityId,
        }));
      },
      {
        onMutate: async ({ communityId, following }) => {
          const previousState = queryClient.getQueryData(
            communitiesKeys.communityById(`${communityId}`)
          );
          previousState.followed = !following;
          return previousState;
        },
        onError: console.error,
        onSettled: () => {
          resetCommunitiesQueries();
        },
      }
    );

  const resetCommunitiesQueries = () => {
    queryClient.resetQueries(communitiesKeys.other());
    queryClient.resetQueries(communitiesKeys.my());
  };
  const updateQueryData = (communityId, key, following) => {
    const previousState = queryClient.getQueryData(key);
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatedPages = pages.map(({ items, ...rest }) => {
        const updatedItems = items.map((item) => {
          if (item.id === communityId) {
            return {
              ...item,
              followed: !following,
            };
          }
          return item;
        });
        return {
          items: updatedItems,
          ...rest,
        };
      });
      return { pageParams, pages: updatedPages };
    });
    return { previousState };
  };

  return {
    toggleOtherCommunityFollow,
    toggleMyCommunityFollow,
    toggleCommunityFollow,
    isLoading:
      isOtherCommunityFollowLoading ||
      isMyCommunityFollowLoading ||
      isCommunityFollowLoading,
  };
}
