import { Form } from 'components/forms';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import styles from './RichTextAreaInput.module.css';

export function RichTextAreaInput(props) {
  const newProps = useManualInputDefaultProps(props);

  return <Form.QuillInput {...newProps} className={styles.note} />;
}

manualInputsRegistry.register(
  MANUAL_INPUT_TYPE.RICH_TEXTAREA,
  RichTextAreaInput
);
