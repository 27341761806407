import classnames from 'classnames';
import TagList from 'components/TagList';
import { mergeRefs } from 'utils';
import { useEffect } from 'react';
import { isEmpty } from 'ramda';
import useTagsController from '../hooks/useTagsController';
import Label from '../Label';
import MultiSelect from '../MultiSelect';
import Form from './Form';
import { registerFormElement } from './utils';

function FormMultiSelect(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    setValue,
    getValues,
    options,
    onChange,
    ...rest
  } = props;

  const { inputRef, tagRef, deleteTag } = useTagsController(
    getValues,
    setValue,
    name
  );

  const handleOnAdd = (value, onChange) => {
    onChange(value);
  };

  const handleCancel = () => {
    setValue(name, []);
  };

  useEffect(() => {
    const values = rest?.defaultValues?.[name];
    if (values && !isEmpty(values)) {
      setValue(name, values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValue = getValues(name)?.length > 0 ? [...getValues(name)] : [];

  return (
    <Form.Controller
      {...rest}
      errors={errors}
      withError={withError}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, ref, onChange }, ...rest }) => {
        const error = rest?.fieldState?.error;

        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <TagList {...{ value, deleteTag, tagRef }} className="border-0" />
            <div className="group relative w-full">
              <MultiSelect
                id={id}
                name={name}
                className={classnames(className, 'w-full')}
                options={options}
                ref={mergeRefs([ref, inputRef])}
                value={value}
                error={error}
                {...register(name, rules)}
                {...rest}
                onChange={(value) => handleOnAdd(value, onChange)}
                onCancel={handleCancel}
              />
            </div>
          </>
        );
      }}
    />
  );
}

registerFormElement(FormMultiSelect);

export default FormMultiSelect;
