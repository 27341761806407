import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Checks, ChevronDown, Cog } from 'components/icons';
import { InfiniteScrollTrigger } from 'components';
import { Button } from 'components/forms';

import {
  useHasUnreadNotifications,
  useNotificationDelete,
  useNotifications,
} from 'hooks';

import Notification from './Notification';
import NotificationSkeleton from './NotificationSkeleton';

function MarkAllAsRead(props) {
  const { t } = useTranslation();
  return (
    <Button
      color="anchor"
      className="!text-smd-sm"
      {...props}
      aria-label={t('labels.notifications.mark-all')}
    >
      <span>{t('notifications.mark-all-as-read')}</span>
      <Checks className="h-6 w-6" />
    </Button>
  );
}

function SettingsLink() {
  const { t } = useTranslation();
  return (
    <Link
      to="/account/notifications"
      className="smd-focus-visible-primary grow-0 rounded-sm stroke-3 hover:text-smd-accent"
      aria-label={t('labels.notifications.go-to-settings')}
    >
      <Cog className="h-5 w-5" />
    </Link>
  );
}

export default function NotificationList() {
  const { t } = useTranslation();

  const {
    data,
    isFetched,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useNotifications();

  const { deleteNotification, deleteAllNotifications } =
    useNotificationDelete();

  const { data: hasUnread, isFetched: isFetchedHasUnread } =
    useHasUnreadNotifications();

  const notifications =
    data?.pages && Array.isArray(data.pages)
      ? data.pages.reduce((prev, curr) => [...prev, ...(curr?.items || [])], [])
      : [];

  const showSkeletons = isLoading;
  const hasUnreadNotifications = isFetchedHasUnread && hasUnread;

  return (
    <div className="flex flex-col space-y-2 p-4">
      <div
        className={classnames('flex items-center px-2', {
          'mb-2': !hasUnreadNotifications,
        })}
      >
        <h1 className="grow font-semibold">{t('common.notifications')}</h1>
        {hasUnreadNotifications && (
          <MarkAllAsRead onClick={deleteAllNotifications} />
        )}
        <SettingsLink />
      </div>
      {hasUnreadNotifications && (
        <div className="pl-2 text-smd-sm font-semibold text-smd-gray-dark">
          {t('common.new')}
        </div>
      )}
      <div className="relative max-h-[60vh] w-full max-w-full space-y-4 overflow-y-scroll p-2 lg:max-h-[13.07rem]">
        {showSkeletons && (
          <>
            <NotificationSkeleton />
            <NotificationSkeleton />
          </>
        )}
        {isFetched &&
          notifications.map((notification) => (
            <Notification
              key={notification.notificationId}
              onDelete={deleteNotification}
              {...notification}
            />
          ))}
        {isFetched && hasNextPage && (
          <div className="flex items-center justify-center">
            <InfiniteScrollTrigger
              onFetch={fetchNextPage}
              isFetching={isFetchingNextPage}
            >
              <Button.Ghost
                onClick={fetchNextPage}
                loading={isFetchingNextPage}
                className="font-semibold"
              >
                <span>{t('common.show-more')}</span>

                <ChevronDown className="h-5 w-5" strokeWidth="3" />
              </Button.Ghost>
            </InfiniteScrollTrigger>
          </div>
        )}
      </div>

      {isFetched && !Boolean(notifications?.length) && (
        <span className="italic text-smd-gray">
          {t('notifications.no-results')}
        </span>
      )}
    </div>
  );
}
