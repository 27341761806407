import { useGetJourneys } from 'hooks/journeys';
import { useMemo } from 'react';

function useJourneysData() {
  const { data: journeys, isLoading, isFetched } = useGetJourneys();

  const journeysArray = useMemo(
    () => (journeys ? Object.values(journeys) : []),
    [journeys]
  );

  const featured = journeysArray?.filter((journey) => journey.isFeatured);

  const groupedJourneysByCategory = useMemo(() => {
    const journeys = {};
    for (const journey of journeysArray) {
      if (journey?.tags?.category) {
        for (const category of journey?.tags?.category) {
          const categoryName = category.name;
          if (!journeys[categoryName]) {
            journeys[categoryName] = [];
          }
          journeys[categoryName].push(journey);
          journeys[categoryName].sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    }
    return journeys;
  }, [journeysArray]);

  return {
    journeys,
    isLoading,
    isFetched,
    featured,
    groupedJourneysByCategory,
  };
}

export default useJourneysData;
