import ContainerBlock from './ContainerBlock';
import HtmlBlock from './output/HtmlBlock';
import LibraryBlock from './output/LibraryBlock';

export { ContainerBlock, HtmlBlock, LibraryBlock };

export default {
  Container: ContainerBlock,
  Html: HtmlBlock,
  Library: LibraryBlock,
};
