import { useTranslation } from 'react-i18next';
import { Spinner } from 'components';
import { SEOMeta } from 'components/SEO';
import { OG_IMAGES } from 'landing/const';

import {
  FeaturesSection,
  HeroSection,
  IntroSection,
} from 'components/PageSections';
import { ExploreSection, GetStartedSection } from '../sections';

function Landing({ parsedData, isFetched }) {
  const { t } = useTranslation();
  const metaValues = {
    title: t('landing.why-storymd'),
    description: t('landing.description'),
    image: OG_IMAGES.WHY_STORYMD,
  };

  if (!isFetched) {
    return (
      <div className="min-h-screen grow">
        <div className="flex-center h-full">
          <Spinner className="h-16 w-16" />
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOMeta values={metaValues} />
      <div className="grow">
        {parsedData?.slides?.length > 0 && (
          <HeroSection slides={parsedData?.slides} />
        )}
        {parsedData?.videos?.background && parsedData?.videos?.full && (
          <IntroSection videos={parsedData?.videos} />
        )}
        {parsedData?.journals?.length > 0 && (
          <ExploreSection
            journalSection={parsedData?.journalSection}
            journals={parsedData?.journals}
          />
        )}
        {parsedData?.features?.length > 0 && (
          <FeaturesSection
            features={parsedData?.features}
            maxWidthClassName="max-w-4xl"
          />
        )}
        {parsedData?.getStarted && (
          <GetStartedSection content={parsedData?.getStarted} />
        )}
      </div>
    </>
  );
}

export default Landing;
