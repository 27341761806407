import PropTypes from 'prop-types';

import credit from './credit';
import focalPoint from './focalPoint';
import tags from './tags';
import assetType from './assetType';

const shape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: assetType.shape.isRequired,

  name: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  credit: credit.shape,
  tags: tags.shape,

  thumbnail: PropTypes.string,
  url: PropTypes.string, // image, video, document
  mimetype: PropTypes.string, // image, video, document

  // image
  focalPoint: focalPoint.shape,

  // video
  poster: PropTypes.string,
  duration: PropTypes.string,

  // document
  pages: PropTypes.string,

  // interactive
  subtype: PropTypes.oneOf(['comparison', 'slider']),
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['image']),
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),

  // published: PropTypes.bool,
  // etag: PropTypes.string,
});

const defaults = {};

export default {
  shape,
  defaults,
};
