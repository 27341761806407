import { createContext, useContext } from 'react';

import { JournalsApi, JournalsPublicApi } from 'api';

const JournalsApiContext = createContext(JournalsApi);

export function useJournalsApi() {
  return useContext(JournalsApiContext);
}

export function JournalsPublicApiProvider({
  children,
  value = JournalsPublicApi,
}) {
  return (
    <JournalsApiContext.Provider value={value}>
      {children}
    </JournalsApiContext.Provider>
  );
}
