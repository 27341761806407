import PropTypes from 'prop-types';

import { getLinkagesShape } from './utils';

import credit from './credit';
import asset from './asset';

const shape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['article']),
  name: PropTypes.string,
  description: PropTypes.string, // html
  text: PropTypes.string, // html

  keywords: PropTypes.string,
  // synonyms: PropTypes.string,
  credit: credit.shape,

  thumbnail: PropTypes.string,
  featuredAsset: asset.shape,

  linkages: PropTypes.shape({
    subarticles: getLinkagesShape({
      label: PropTypes.string,
    }),
  }),

  // published: PropTypes.bool,
  // etag: PropTypes.string,
});

const defaults = {};

export default {
  shape,
  defaults,
};
