import { forwardRef } from 'react';

import classnames from 'classnames';
import { useCardSize, useMedia } from 'hooks';

function GridView({ items, childComponent, className }, ref) {
  const { width } = useCardSize();

  const isXSmall = useMedia(useMedia.XSMALL);
  const isSmall = useMedia(useMedia.SMALL);

  const containerClasses = isXSmall && !isSmall ? 'w-2/3 mx-auto' : 'w-full';

  return (
    <section
      className={classnames('grid gap-4', containerClasses, className)}
      ref={ref}
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(min(${width}px, 100%), 1fr))`,
      }}
    >
      {items.map((entry) => childComponent(entry))}
    </section>
  );
}

export default forwardRef(GridView);
