import { useEffect } from 'react';
import { isEmpty, uniq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { TypeaheadDropdown } from 'components';
import TagList from 'components/TagList';

import { useExistingTags } from 'my-phr/hooks';
import useTagsController from '../hooks/useTagsController';
import { Label, Form, ErrorLabel } from '..';
import { registerFormElement } from './utils';

const LIST_POSITION = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
});

const MAX_TAG_LENGTH = 48;

function FormTagsStackInput(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    className,
    errors,
    withError = true,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    resetField,
    placeholder,
    buttonAriaLabel,
    tagListPosition = LIST_POSITION.BOTTOM,
    default: preselectedOptions = [],
    inEditMode,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { tagRef, addExternalValue, deleteTag } = useTagsController(
    getValues,
    setValue,
    name
  );

  useEffect(() => {
    const value = getValues(name) || [];
    if (isEmpty(value) && !inEditMode) {
      setValue(
        name,
        preselectedOptions?.map?.((entry) => entry?.label)
      );
    }
    return () => resetField(name);
  }, [getValues, name, preselectedOptions, resetField, setValue, inEditMode]);

  const handleOnAdd = (value, onChange) => {
    if (value?.length <= MAX_TAG_LENGTH) addExternalValue(value, onChange);
  };

  const { data: tags = [], isLoading } = useExistingTags();

  const tagsOptions = isLoading
    ? []
    : uniq(tags)?.map((tag) => ({ label: tag, value: tag }));

  const handleOnChange = (event) => {
    const value = event?.target?.value;
    if (value?.length > MAX_TAG_LENGTH) {
      setError(name, {
        message: t('errors.max-length', {
          field: t('common.tag'),
          value: MAX_TAG_LENGTH,
        }),
      });
    } else {
      clearErrors(name);
    }
  };

  return (
    <Form.Controller
      {...rest}
      name={name}
      render={({ field: { value, ref, onChange }, ...rest }) => {
        const error = rest?.fieldState?.error;
        const suggestions = tagsOptions?.filter(
          // Do not render already added tags as suggestions
          (tag) => value?.indexOf?.(tag?.value) === -1
        );
        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            {withError && <ErrorLabel className="mb-2" error={error} />}
            {tagListPosition === LIST_POSITION.TOP && (
              <TagList
                {...{ value, deleteTag, tagRef }}
                className="border-0 px-0 pb-2 pt-1"
              />
            )}
            <div className="group relative">
              <TypeaheadDropdown
                onInputChange={handleOnChange}
                onValueReceived={(value) => handleOnAdd(value, onChange)}
                placeholder={placeholder}
                options={suggestions}
              />
            </div>
            {tagListPosition === LIST_POSITION.BOTTOM && (
              <TagList {...{ value, deleteTag, tagRef }} />
            )}
          </>
        );
      }}
    />
  );
}

registerFormElement(FormTagsStackInput);

export default FormTagsStackInput;
