import { cloneElement } from 'react';
import classnames from 'classnames';

import { useMedia, useWindowSize } from 'hooks';
import { callAll } from 'utils';
import { virtualCarouselConfig } from './const';
import { Carousel } from '.';

import 'swiper/css/virtual';

function VirtualCarousel({
  className = 'relative -mx-4 lg:-mx-12 lg:px-10',
  children,
  slides,
  carouselProps,
  withNavigation = false,
}) {
  // Forcing re-render on window resize to show / hide navigation arrows
  useWindowSize();

  const breakpoints = {
    [useMedia.xxl]: {
      slidesPerView: 5,
      slidesPerGroup: 5,
    },
    [useMedia.xl]: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    [useMedia.md]: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    [useMedia.sm]: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  const virtualSlides = slides?.map((slide, index) => {
    if (slide) {
      return cloneElement(slide, {
        className: classnames(slide.props.className || 'p-2'),
        virtualIndex: index,
      });
    }
    return null;
  });

  const allCarouselProps = {
    ...virtualCarouselConfig,
    breakpoints,
    onAfterInit: callAll(
      (swiper) => swiper?.virtual.update(),
      carouselProps?.onAfterInit
    ),
    ...carouselProps,
  };

  return (
    <Carousel
      className={className}
      slides={virtualSlides}
      carouselProps={allCarouselProps}
    >
      {withNavigation && (
        <>
          <Carousel.Prev className="top-1/3 lg:top-0" />
          <Carousel.Next className="top-1/3 lg:top-0" />
        </>
      )}
      {children}
    </Carousel>
  );
}

export default VirtualCarousel;
