import { useStoreLastLocation } from 'hooks';

export default function useAssetLastLocation(config) {
  const { set, push, pop, lastLocation } = useStoreLastLocation({
    ...config,
    key: 'asset-navigation-origin',
  });

  return {
    setAssetLastLocation: set,
    pushAssetLastLocation: push,
    popAssetLastLocation: pop,
    assetLastLocation: lastLocation,
  };
}
