import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CarouselPagination } from '../../';

function CarouselPaginationWithGroups({ items = [], currentItem, onSelect }) {
  let currentGroupPage = useRef(() => {
    return { currentGroup: 0, currentPage: 0 };
  });
  const handleClick = (item, groupIndex, pageIndex) => {
    if (
      groupIndex !== currentGroupPage.currentGroup ||
      pageIndex !== currentGroupPage.currentPage
    ) {
      onSelect(item, { currentGroup: groupIndex, currentPage: pageIndex });
    }
  };
  return (
    <div className="flex flex-wrap">
      {items?.map((group, groupIndex) => {
        let onlyOnePage = group.length > 1;
        let lastPage = groupIndex === items.length - 1;
        return (
          <div key={groupIndex} className="flex flex-wrap">
            <CarouselPagination
              items={group}
              currentItem={currentItem}
              onSelect={(item, itemIndex) =>
                handleClick(item, groupIndex, itemIndex)
              }
            />
            {onlyOnePage && !lastPage && (
              <div className="text-smd-gray">{'|'}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}

CarouselPaginationWithGroups.propTypes = {
  items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  currentItem: PropTypes.any,
  onSelect: PropTypes.func,
};
CarouselPaginationWithGroups.defaultProps = {
  onSelect: () => {},
};
export default CarouselPaginationWithGroups;
