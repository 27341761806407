import { Link } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useJournalLastLocation } from 'journals/hooks';
import { applyDistributionConfig } from 'journals/utils/distribution';
import { JournalCardActionsWrapper } from './actions';
import { JournalCard, SkeletonCard } from '.';

function SmartJournalCardContent({
  journal,
  to,
  pushLocation = false,
  onClick,
  actions = [],
}) {
  const { setJournalLastLocation, pushJournalLastLocation } =
    useJournalLastLocation();

  const customizedJournal = applyDistributionConfig(journal);

  const card = (
    <Link
      to={to}
      onClick={() => {
        pushLocation ? pushJournalLastLocation() : setJournalLastLocation();
        onClick?.();
      }}
      className="group outline-none"
    >
      <JournalCard
        preamble={customizedJournal?.preamble}
        className="w-full cursor-pointer"
      />
    </Link>
  );

  return (
    <JournalCardActionsWrapper actions={actions}>
      {card}
    </JournalCardActionsWrapper>
  );
}

export default function SmartJournalCard(props) {
  return (
    <ErrorBoundary fallback={<SkeletonCard className="w-full" />}>
      <SmartJournalCardContent {...props} />
    </ErrorBoundary>
  );
}
