import { Buffer } from 'buffer';
import { createContext, useCallback, useContext, useState } from 'react';
import { measurementUnit } from 'utils';

async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return Buffer.from(str)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

const ProfilStateContext = createContext();
const ProfilUpdaterContext = createContext();

function ProfileProvider({ children }) {
  const [profile, setProfile] = useState(null);

  const accountResponse = useCallback(
    async (account) => {
      const pkceFlowChallenge = base64urlencode(await sha256(`${account?.id}`));

      setProfile(
        account
          ? {
              ...account,
              ...(account.profiles?.[0] ?? {}),
              id: account.id,
              unit: account.profiles?.[0]?.unit ?? measurementUnit.IMPERIAL,
              pkceFlowChallenge,
            }
          : null
      );
    },
    [setProfile]
  );

  return (
    <ProfilStateContext.Provider value={profile}>
      <ProfilUpdaterContext.Provider value={accountResponse}>
        {children}
      </ProfilUpdaterContext.Provider>
    </ProfilStateContext.Provider>
  );
}

function useProfileState() {
  const context = useContext(ProfilStateContext);
  if (typeof context === 'undefined') {
    throw new Error('useProfileState must be used within a ProfileProvider');
  }
  return context;
}

function useProfileUpdater() {
  const context = useContext(ProfilUpdaterContext);
  if (typeof context === 'undefined') {
    throw new Error('useProfileUpdater must be used within a ProfileProvider');
  }
  return context;
}

function useProfile() {
  return [useProfileState(), useProfileUpdater()];
}

export { ProfileProvider, useProfile, useProfileState, useProfileUpdater };
export default { Provider: ProfileProvider };
