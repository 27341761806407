import { useRef } from 'react';

import { ContentContainer } from 'components';
import FeatureConnector from './components/FeatureConnector';
import FeatureEntry from './components/FeatureEntry';

function FeaturesSection({ header, features, maxWidthClassName }) {
  const containerRef = useRef();

  return (
    <section className="py-12 md:py-smd-3xl">
      <ContentContainer className="px-4">
        {header && (
          <>
            <h1 className="mb-smd-xl text-center text-smd-h3 font-semibold md:text-smd-h1">
              {header?.title}
            </h1>
            <p className="mx-auto mb-smd-xxl text-center text-smd-base leading-relaxed md:max-w-3xl md:text-smd-h4">
              {header?.text}
            </p>
          </>
        )}
        <ContentContainer
          maxWidthClassName={maxWidthClassName}
          className="mt-smd-xl md:mt-smd-3xl"
        >
          <FeatureConnector containerRef={containerRef} />
          <div ref={containerRef}>
            {features.map((feature, index) => {
              const isEven = index % 2 === 0;

              return (
                <FeatureEntry
                  key={feature?.key}
                  feature={feature}
                  isEven={isEven}
                />
              );
            })}
          </div>
        </ContentContainer>
      </ContentContainer>
    </section>
  );
}

export default FeaturesSection;
