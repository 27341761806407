import { useAds, useMedia } from 'hooks';
import AdSense from './AdSense';
import AdManager from './AdManager';

const ADSENSE_KEY = process.env.REACT_APP_GOOGLE_ADSENSE_KEY;
const ADMANAGER_KEY = process.env.REACT_APP_GOOGLE_ADMANAGER_KEY;

const AD_PROVIDERS = {
  ADSENSE: 'adsense',
  ADMANAGER: 'admanager',
};

function Ad({ dimensions, ...rest }) {
  const sm = useMedia(useMedia.SMALL);
  const md = useMedia(useMedia.MEDIUM);
  const lg = useMedia(useMedia.LARGE);
  const xl = useMedia(useMedia.XLARGE);

  const adsKey = useAds(['key']);
  const adsEnabled = useAds(['enabled']);
  const adsProvider = useAds(['provider']);

  if (!adsEnabled) {
    return null;
  }

  if (
    adsProvider !== AD_PROVIDERS.ADSENSE &&
    adsProvider !== AD_PROVIDERS.ADMANAGER
  ) {
    console.error(
      `Please provide a valid ad service provider! Accepted values include: "${Object.values(
        AD_PROVIDERS
      ).join('", "')}"!`
    );
    return null;
  }

  const adSize = (() => {
    if (dimensions?.xl && xl) return dimensions.xl;
    if (dimensions?.lg && lg) return dimensions.lg;
    if (dimensions?.md && md) return dimensions.md;
    if (dimensions?.sm && sm) return dimensions.sm;
    return dimensions?.base ?? {};
  })();

  if (adsProvider === AD_PROVIDERS.ADSENSE) {
    const adSenseKey = adsKey || ADSENSE_KEY;
    return <AdSense adKey={adSenseKey} size={adSize} {...rest} />;
  }

  if (adsProvider === AD_PROVIDERS.ADMANAGER) {
    // TODO: Check config for ad manager, make ads responsive (currently fixed sizes)
    const adManagerKey = adsKey || ADMANAGER_KEY;
    const currentAdSize = [adSize.width, adSize.height];
    return (
      <AdManager
        adKey={adManagerKey}
        size={currentAdSize}
        style={adSize}
        {...rest}
      />
    );
  }

  return null;
}

export default Ad;
