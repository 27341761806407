import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLibraryCorrectSpelling } from 'library/hooks';
import Button from 'components/forms/Button';

function SpellingCorrection({ query, onSearch }) {
  const { t } = useTranslation();
  const [previousSuggestion, setPreviousSuggestion] = useState();

  const { data = [] } = useLibraryCorrectSpelling(
    {
      query,
    },
    {
      enabled: query?.length > 2,
    }
  );

  const suggestions = data.map((item) => item.text);

  const onSuggestionClick = (suggestion) => {
    onSearch({ search: suggestion });
    setPreviousSuggestion(suggestion);
  };

  const previousSuggestionUsed = query === previousSuggestion;

  if (!Boolean(suggestions?.length) || previousSuggestionUsed) {
    return null;
  }

  return (
    <div className="mb-5">
      <span className="italic">{t('library.search.did-you-mean')}</span>
      {suggestions.map((suggestion) => (
        <Button.Anchor
          key={suggestion}
          onClick={() => onSuggestionClick(suggestion)}
          className="px-1 capitalize underline"
        >
          {suggestion}
        </Button.Anchor>
      ))}
      ?
    </div>
  );
}

SpellingCorrection.propTypes = {
  query: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SpellingCorrection;
