import JourneysView from './JourneysView';
import { JourneyCover } from './components';

function Journeys() {
  return (
    <section className="relative grow">
      <JourneyCover />
      <JourneysView />
    </section>
  );
}

export default Journeys;
