import { useContext } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { SettingsContext } from 'SettingsContext';
import { safeDateFormat } from 'utils';

export default function useLocalization() {
  const settingsContext = useContext(SettingsContext);
  const locale = settingsContext?.locale;
  return {
    formatDate: (args) => {
      return safeDateFormat({ ...args, locale });
    },
    formatDateRelative: (date) => {
      return formatDistance(parseISO(date), new Date(), {
        addSuffix: true,
      });
    },
  };
}
