import { Form } from 'components/forms';
import FormTagsStackInput from 'components/forms/Form/FormTagsStackInput';
import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';

const COMPONENT_MAP = {
  tags: (props) => (
    <FormTagsStackInput tagListPosition="top" {...props} className="w-full" />
  ),
};

export function MultiSelectInput({
  options = [],
  name,
  onChange,
  children,
  ...props
}) {
  const newProps = useManualInputDefaultProps({
    ...props,
    className: 'pr-8 max-w-[345px] md:max-w-[450px]',
    name: name,
  });

  const Component = COMPONENT_MAP[name] ?? Form.MultiSelect;

  return (
    <Component
      options={options}
      name={name}
      onChange={onChange}
      {...newProps}
    />
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.MULTISELECT, MultiSelectInput);
