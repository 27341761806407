import PropTypes from 'prop-types';

import { WideCardSkeleton } from 'components/WideCard';
import { ArticleCardSkeleton } from 'journals/components/ArticleCard';

const SKELETONS_COUNT = 3;

export function LibraryListSkeleton({ children }) {
  return children;
}

function LibraryListSkeletonWideCard() {
  return (
    <LibraryList
      renderListItem={() => <WideCardSkeleton />}
      list={Array(SKELETONS_COUNT).fill()}
    />
  );
}

function LibraryListSkeletonArticleCard() {
  return (
    <LibraryList
      renderListItem={() => <ArticleCardSkeleton />}
      list={Array(SKELETONS_COUNT).fill()}
    />
  );
}

LibraryListSkeleton.Article = LibraryListSkeletonArticleCard;
LibraryListSkeleton.Wide = LibraryListSkeletonWideCard;

function LibraryList({ list, renderListItem }) {
  return (
    <ul className="flex flex-col space-y-4">
      {list.map((item, index) => {
        return (
          <li key={index} className="cursor-pointer">
            {renderListItem(item)}
          </li>
        );
      })}
    </ul>
  );
}

LibraryList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  renderListItem: PropTypes.func.isRequired,
};

LibraryList.defaultProps = {
  list: [],
};

export default LibraryList;
