import { useTranslation } from 'react-i18next';
import { path } from 'ramda';

import { measurementUnit } from 'utils';
import { useDefaultToRandomId } from 'hooks';
import { ErrorLabel } from '..';
import { Radio } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

const selectUnitBase = [
  'flex flex-col space-y-2',
  'sm:flex-row sm:space-y-0 sm:space-x-8',
  'py-1',
];

function FormSelectUnit(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    getValues,
    setValue,
    setError,
    watch,
    resetField,
    ...rest
  } = props;

  const { t } = useTranslation();
  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const imperial = useDefaultToRandomId();
  const metric = useDefaultToRandomId();

  const { ref: unitInputRef, ...unitInputProps } = register(name, rules);

  const labelStyles = 'flex items-start space-x-1';

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={selectUnitBase.concat(className).join(' ')}>
        <label className={labelStyles} htmlFor={imperial}>
          <Radio
            ref={unitInputRef}
            {...unitInputProps}
            value={measurementUnit.IMPERIAL}
            id={imperial}
            className="m-1"
            aria-label={t('labels.profile.select-unit', {
              unit: t('account-settings.manage-profile.unit.IMPERIAL'),
            })}
            {...rest}
          />
          <div>
            <div>{t('account-settings.manage-profile.unit.IMPERIAL')}</div>
            <div className="text-smd-xs text-smd-gray-dark">
              ({t('account-settings.manage-profile.unit.imperial-values')})
            </div>
          </div>
        </label>
        <label className={labelStyles} htmlFor={metric}>
          <Radio
            ref={unitInputRef}
            {...unitInputProps}
            value={measurementUnit.METRIC}
            id={metric}
            className="m-1"
            aria-label={t('labels.profile.select-unit', {
              unit: t('account-settings.manage-profile.unit.METRIC'),
            })}
            {...rest}
          />
          <div>
            <div>{t('account-settings.manage-profile.unit.METRIC')}</div>
            <div className="text-smd-xs text-smd-gray-dark">
              ({t('account-settings.manage-profile.unit.metric-values')})
            </div>
          </div>
        </label>
      </div>
      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormSelectUnit);

export default FormSelectUnit;
