import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CarouselPaginationButton({ active, index, ...props }) {
  const { t } = useTranslation();
  const buttonStyles = active
    ? 'bg-smd-accent border-smd-accent'
    : 'bg-white border-smd-gray-light';
  return (
    <button
      className={classnames(
        'smd-focus-visible-primary h-3 w-3 shrink-0 rounded-full border-2',
        buttonStyles
      )}
      aria-label={t('labels.carousel.bullet-button', {
        number: index,
      })}
      {...props}
    />
  );
}

CarouselPaginationButton.propTypes = {
  active: PropTypes.bool,
};

CarouselPaginationButton.defaultProps = {
  active: false,
};

export default CarouselPaginationButton;
