import { path } from 'ramda';

const empty = Symbol('empty');

let distributionConfigCache = empty;
export function getDistributionConfig(index = []) {
  if (distributionConfigCache === empty) {
    distributionConfigCache = undefined;
    const distributionJson = document.getElementById('smd-distribution');
    if (distributionJson) {
      try {
        distributionConfigCache = JSON.parse(distributionJson.textContent);
      } catch (err) {
        console.error('Failed to parse distribution customizations', err);
      }
    }
  }
  return path(index, distributionConfigCache);
}

export function applyDistributionColorPalette() {
  const palette = getDistributionConfig(['palette']);
  let root = document.documentElement;
  if (root && palette) {
    try {
      Object.entries(palette).forEach(([colorName, hexCode]) => {
        root.style.setProperty(`--palette-${colorName}`, hexCode);
      });
    } catch (err) {
      console.error(
        'Failed to apply distribution color palette customizations',
        err
      );
    }
  }
}
