import classnames from 'classnames';
import * as propTypes from './propTypes';
import { getPadding } from './utils';
export default function AspectMin({ ratio, className, children, ...props }) {
  const paddingTop = getPadding(ratio);

  return (
    <div className={classnames('flex', className)} {...props}>
      <div className="float-left -ml-px h-0 w-px" style={{ paddingTop }} />
      <div className="flex-1">{children}</div>
      <div className="clear-both table" />
    </div>
  );
}

AspectMin.propTypes = {
  ratio: propTypes.ratio,
};

AspectMin.defaultProps = {
  ratio: 1,
  className: '',
};
