import axios from 'axios';
import { useMutation } from 'react-query';

export function useContactUs(options) {
  const { mutate: sendEmail, isLoading } = useMutation(
    async (data) =>
      axios.post(`${process.env.REACT_APP_LIBRARY_URL}/api/contact`, data),
    options
  );
  return {
    isLoading,
    sendEmail,
  };
}
