import LibraryAssetList from 'library/components/asset/LibraryAssetList';

function LibraryAssetModule({ assets, onAssetClick }) {
  return (
    <LibraryAssetList
      assets={assets}
      onClick={onAssetClick}
      gridTemplateColumns="repeat(auto-fill, minmax(min(280px, 100%), 1fr))"
    />
  );
}

export default LibraryAssetModule;
