import { tag } from 'utils/analytics';

export function addGoogleAnalyticsTracking(journalApi) {
  const {
    getJournalByKey: initialGetJournalByKey,
    getJournalContainerByKey: initialGetJournalContainerByKey,
    ...rest
  } = journalApi;

  const getJournalByKey = (...args) =>
    initialGetJournalByKey(...args).then((response) => {
      tag('event', 'hj_view', {
        journal_key: response?.journalKey,
        journal_title: response?.name,
      });
      return response;
    });

  const getJournalContainerByKey = async (...args) => {
    const journalKey = args[0];
    const journal = await initialGetJournalByKey(journalKey);
    return initialGetJournalContainerByKey(...args).then((container) => {
      tag('event', 'hj_page_view', {
        journal_key: journal?.journalKey,
        journal_title: journal?.name,
        page_key: container?.key,
        page_title: container?.title || container?.label,
      });
      return container;
    });
  };

  return {
    getJournalByKey,
    getJournalContainerByKey,
    ...rest,
  };
}
