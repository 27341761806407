import { useMemo } from 'react';
import { head, isNil } from 'ramda';
import { parseCtaAction } from 'landing/helpers/helpers';
import { getAssetUrl } from 'utils';

function useParseLandingPageData(data, contentContext) {
  return useMemo(() => {
    const isStagingEnv = process.env.REACT_APP_ENVIRONMENT === 'staging';

    if (isNil(data)) {
      return {
        slides: [],
        videos: {
          full: null,
          background: null,
        },
        journalSection: null,
        journals: [],
        features: [],
        getStarted: null,
        footer: {
          motto: null,
          social: [],
          mailing: null,
        },
      };
    }

    const parsedData = {};

    // PUBLIC / DEFAULT LANDING PAGE CONTENT

    //Hero Slideshow slides
    parsedData.slides =
      data?.slideshow
        ?.filter((slide) =>
          slide?.['featured-journals']?.includes(contentContext)
        )
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            ...curr,
            contextDefault: curr?.['context-default'],
            imageTitle: curr?.['image-title'],
            ctaText: curr?.['cta-text'],
            ctaProps: parseCtaAction(curr),
          };
          if (parsedCurr?.contextDefault === contentContext) {
            return [parsedCurr, ...prev];
          }
          return [...prev, parsedCurr];
        }, []) ?? [];

    //Intro section videos
    parsedData.videos = data?.video
      ?.filter((feature) =>
        feature?.['featured-journals']?.includes(contentContext)
      )
      ?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          ctaText: curr?.['cta-text'],
          ctaProps: parseCtaAction(curr),
        };
        return { ...prev, [curr?.version]: parsedCurr };
      }, {}) ?? {
      full: null,
      background: null,
    };

    //Explore section pinned healthjournals + journal section content
    parsedData.journalSection =
      data?.journals?.filter((journal) => journal?.type === 'text')?.[0] ??
      null;

    parsedData.journals =
      data?.journals
        ?.filter((journal) => journal?.type === 'image')
        ?.filter((feature) =>
          feature?.['featured-journals']?.includes(contentContext)
        )
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            journalKey: isStagingEnv
              ? curr?.['journalKey-stage']
              : curr?.journalKey,
            heading: curr?.title,
            content: curr?.text,
            cover: {
              url: getAssetUrl(curr?.key),
              focalPoint: {
                x: 0,
                y: 0,
              },
            },
            logos: [
              {
                url: curr?.logo,
                alt: curr?.['logo-alt'] ?? 'company logo',
              },
            ],
          };
          return [...prev, parsedCurr];
        }, []) ?? [];

    //Features section
    parsedData.featuresSection =
      data?.['features']?.filter(
        (feature) =>
          feature?.['featured-journals']?.includes(contentContext) &&
          feature?.type === 'text'
      )[0] ?? null;

    parsedData.features =
      data?.features
        ?.filter(
          (feature) =>
            feature?.['featured-journals']?.includes(contentContext) &&
            (feature?.type === 'video' || feature?.type === 'image')
        )
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            ...curr,
            ctaText: curr?.['cta-text'],
            ctaProps: parseCtaAction(curr),
          };
          return [...prev, parsedCurr];
        }, []) ?? [];

    //Get Started content
    parsedData.getStarted =
      data?.start
        ?.filter((entry) => entry?.type === 'image')
        ?.reduce((prev, curr) => {
          const parsedCurr = {
            ...curr,
            ctaText: curr?.['cta-text'],
            ctaProps: parseCtaAction(curr),
          };
          return [...prev, parsedCurr];
        }, [])?.[0] ?? null;

    //Social Links
    parsedData.footer = data?.footer
      ? {
          motto:
            data?.footer?.filter((entry) => entry?.type === 'text')?.[0]
              ?.text ?? null,
          social:
            head(data?.footer?.filter((entry) => entry?.type === 'social')) ??
            [],
          mailing:
            data?.footer
              ?.filter((entry) => entry?.type === 'mail')
              ?.reduce((prev, curr) => {
                const parsedCurr = {
                  ...curr,
                  ctaText: curr?.['cta-text'],
                };
                return [...prev, parsedCurr];
              }, [])?.[0] ?? null,
        }
      : {
          motto: null,
          social: [],
          mailing: null,
        };

    return parsedData;
  }, [data, contentContext]);
}

export default useParseLandingPageData;
