import { BroadcastChannel } from 'broadcast-channel';
const PHR_AUTH_CONNECT_CHANEL_NAME = 'PHR_AUTH_CONNECT';

export default function usePhrSourceAuthConnectBroadcastChannel() {
  return {
    postMessage: (connectDto, organizationId) =>
      new BroadcastChannel(
        organizationId + PHR_AUTH_CONNECT_CHANEL_NAME
      ).postMessage(connectDto),
    addNewListener: (onMessage, prefix) => {
      const bc = new BroadcastChannel(prefix + PHR_AUTH_CONNECT_CHANEL_NAME);
      bc.onmessage = onMessage;
      return { clean: () => bc.close() };
    },
    clean: (prefix) => {
      new BroadcastChannel(prefix + PHR_AUTH_CONNECT_CHANEL_NAME).close();
    },
  };
}
