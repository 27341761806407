import React, { createContext, useContext, useRef } from 'react';

const ParentElementContext = createContext();

function ParentProvider({ className, id, children }) {
  const parentRef = useRef();
  return (
    <ParentElementContext.Provider value={parentRef}>
      <div className={className} id={id} ref={parentRef}>
        {children}
      </div>
    </ParentElementContext.Provider>
  );
}

function useParentElementContext() {
  const parentContext = useContext(ParentElementContext);
  if (!parentContext) {
    throw new Error(
      'useParentElementContext must be used within a ParentElementContext'
    );
  }
  return parentContext;
}

export { useParentElementContext };
export default { Provider: ParentProvider };
