import { Button } from 'components/forms';
import NewsletterForm from './NewsletterForm';

function Newsletter({ parsedData, onSubmit, children, isLoading }) {
  const { mailing } = parsedData?.footer;
  return (
    <div className="text-gray-darker flex h-full w-full flex-nowrap overflow-hidden bg-white lg:w-full">
      <img
        src="https://cdn.storymd.com/optimized/kAvL21ted1/original.jpg"
        className="hidden h-full w-64 shrink-0 object-cover lg:block"
        data-testid="newsletterModalHeroImage"
        alt="cover"
      />

      {mailing && (
        <div className="flex h-full grow flex-col lg:px-8 lg:py-16">
          <div
            className="grow-0 bg-smd-accent py-4 px-6 text-smd-sm font-semibold text-white lg:bg-transparent lg:p-0 lg:text-smd-h3.5 lg:text-black"
            data-testid="newsletterModalTitle"
          >
            {mailing?.title}
          </div>
          <div className="grow overflow-y-auto lg:overflow-y-visible">
            <p
              className="px-4 py-4 text-smd-sm leading-loose lg:px-0"
              data-testid="newsletterModalDescription"
            >
              {mailing?.text}
            </p>
            <NewsletterForm
              className="w-full py-4 px-4 lg:px-0"
              inputClassName="w-full bg-white md:max-w-sm placeholder:text-smd-gray-dark"
              onSubmit={onSubmit}
              formLocatorPrefix="modal"
            >
              <Button.Primary
                size="lg"
                type="submit"
                loading={isLoading}
                data-testid="newsletterSubscribeButton"
              >
                {mailing?.ctaText}
              </Button.Primary>
            </NewsletterForm>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default Newsletter;
