import { AttachmentsInput } from './AttachmentsInput';
import { DateInput, DateRangeInput, DateTimeInput } from './DateInputs';
import { HiddenInput } from './HiddenInput';
import { LabPanelFieldArray, LibraryInput } from './LibraryInput';
import { MultiSelectInput } from './MultiselectInput';
import { NumberInput } from './NumberInput';
import { ReactionsInput } from './ReactionsInput';
import { ResultInput } from './ResultInput';
import { RichTextAreaInput } from './RichTextAreaInput';
import { SearchInput } from './SearchInput';
import { SelectInput } from './SelectInput';
import { SelectSuggestionsInput } from './SelectSuggestionsInput';
import { TextAreaInput } from './TextAreaInput';
import { TextInput } from './TextInput';
import { UnitSuggestions } from './UnitSuggestions';

export default function ManualInputsResolver({ children }) {
  return children;
}

ManualInputsResolver.AttachmentsInput = AttachmentsInput;
ManualInputsResolver.Number = NumberInput;
ManualInputsResolver.Text = TextInput;
ManualInputsResolver.TextArea = TextAreaInput;
ManualInputsResolver.RichTextArea = RichTextAreaInput;
ManualInputsResolver.Result = ResultInput;
ManualInputsResolver.Date = DateInput;
ManualInputsResolver.DateTime = DateTimeInput;
ManualInputsResolver.DateRange = DateRangeInput;
ManualInputsResolver.Search = SearchInput;
ManualInputsResolver.Select = SelectInput;
ManualInputsResolver.SelectSuggestions = SelectSuggestionsInput;
ManualInputsResolver.MultiSelect = MultiSelectInput;
ManualInputsResolver.Reactions = ReactionsInput;
ManualInputsResolver.Library = LibraryInput;
ManualInputsResolver.LibraryArray = LabPanelFieldArray;
ManualInputsResolver.Hidden = HiddenInput;
ManualInputsResolver.UnitSuggestions = UnitSuggestions; // TODO: Temporary
