import { allPass, is, propIs } from 'ramda';

const isUrlValid = is(String);

const isFocalPointValid = allPass([
  is(Object),
  propIs(Number, 'x'),
  propIs(Number, 'y'),
]);

export default function useFocalPointBackground({
  url,
  focalPoint,
  backgroundSize = 'cover',
}) {
  const background = {
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize,
  };

  if (isUrlValid(url)) {
    background.backgroundImage = `url(${url})`;
  }

  if (isFocalPointValid(focalPoint)) {
    const { x, y } = focalPoint;
    background.backgroundPosition = `${x}% ${y}%`;
  }

  return background;
}
