import { useTranslation } from 'react-i18next';
import { Button } from 'components/forms';
export default function CommunityFollowButton({
  className,
  onClick,
  following,
  isLoading,
}) {
  const { t } = useTranslation();
  const buttonLabel = following ? t('common.unfollow') : t('common.follow');
  return (
    <Button
      loading={isLoading}
      color={following ? 'tertiary' : 'primary'}
      className={className}
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
}
