import classnames from 'classnames';

import { ContentContainer, Skeleton } from 'components';
import { useMedia } from 'hooks';
import { SkeletonCard } from 'journals/components';
import { GridView } from '../history/components';

function SkeletonCardGrid({
  children,
  className = 'mx-smd-base mt-smd-xl lg:mx-smd-xxl lg:mt-smd-xxl antialiased',
  gridClassName = 'mt-smd-xl lg:mt-smd-xxl',
  containerClassName,
  textProps,
  cardsCount,
  title,
  isChannelCards = false,
  ...rest
}) {
  const isXLarge = useMedia(useMedia.XLARGE);
  const isLarge = useMedia(useMedia.LARGE);
  const responsiveCardsCount = cardsCount ?? (isXLarge ? 7 : isLarge ? 5 : 3);

  return (
    <section className={classnames(className)} {...rest}>
      <ContentContainer maxWidthClassName={containerClassName}>
        {title ?? (
          <Skeleton.Text
            fontSize="1.375rem"
            lineHeight="1.2"
            style={{ width: 'clamp(15rem, 20vw, 20rem)' }}
            {...textProps}
          />
        )}
        <GridView
          items={[...Array(responsiveCardsCount).keys()]}
          childComponent={(entry) => (
            <div key={entry} className="smd-transform-duration">
              <SkeletonCard
                additionalContent={
                  isChannelCards && (
                    <div className="absolute -top-12 h-16 w-16 rounded-full bg-white lg:-top-16 lg:h-20 lg:w-20">
                      <Skeleton.Circle className="h-full w-full border-2 border-smd-accent" />
                    </div>
                  )
                }
              />
            </div>
          )}
          className={gridClassName}
        />
        {children}
      </ContentContainer>
    </section>
  );
}

export default SkeletonCardGrid;
