import { useCallback, useState } from 'react';

function useThrottle(callback, delay = 500) {
  const [lastTime, setLastTime] = useState(0);

  const throttledFunc = useCallback(
    (...args) => {
      const now = Date.now();

      if (now - lastTime >= delay) {
        callback(...args);
        setLastTime(now);
      }
    },
    [callback, lastTime, delay]
  );

  return throttledFunc;
}

export default useThrottle;
