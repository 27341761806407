import { usePublicResource } from 'hooks';
import {
  Facebook,
  Instagram,
  InstagramGradient,
  LinkedIn,
  Twitter,
  Pinterest,
  YouTube,
} from 'components/icons';
import { useParseLandingPageData } from '.';

const orderedSocialMedia = [
  'facebook',
  'instagram',
  'linkedin',
  'pinterest',
  'twitter',
  'youtube',
];

function useSocialMediaProviders() {
  const { data } = usePublicResource('/landing-page.json');
  const {
    footer: { social },
  } = useParseLandingPageData(data);

  return orderedSocialMedia.map((key) => ({
    type: key,
    url: social[key],
    ...socialUI[key],
  }));
}

const socialUI = {
  twitter: {
    color: '#000',
    icon: Twitter,
  },
  facebook: {
    color: '#4267B2',
    icon: Facebook,
  },
  linkedin: {
    color: '#0072B1',
    icon: LinkedIn,
  },
  instagram: {
    color: '#F44336',
    icon: Instagram,
    mintIcon: InstagramGradient,
  },
  pinterest: {
    color: '#BD081C',
    icon: Pinterest,
  },
  youtube: {
    color: '#FF0000',
    icon: YouTube,
  },
};

export default useSocialMediaProviders;
