import { useState, useEffect, useRef } from 'react';

function useMouseDrag() {
  const [overlay] = useState(() => document.createElement('div'));

  const dragContainerRef = useRef();
  const dragState = useRef({
    isMouseDown: false,
    isDragging: false,
    clientX: 0,
    scrollX: 0,
  });

  useEffect(() => {
    const container = dragContainerRef.current;
    if (container) {
      function handleMouseDown(e) {
        const container = dragContainerRef.current;
        if (container) {
          dragState.current.isMouseDown = (e.buttons & 1) === 1;
          dragState.current.clientX = e.clientX;
          dragState.current.scrollX = container.scrollLeft;
        }
      }

      container.addEventListener('mousedown', handleMouseDown);
      return () => {
        container.removeEventListener('mousedown', handleMouseDown);
      };
    }
  }, []);

  useEffect(() => {
    const container = dragContainerRef.current;

    function handleMouseUp() {
      if (overlay && dragState.current.isDragging) {
        document.body?.removeChild(overlay);
      }
      dragState.current.isMouseDown = false;
      dragState.current.isDragging = false;
    }

    function handleMouseMove(e) {
      const state = dragState.current;
      const scrollDiff = state.clientX - e.clientX;
      if (container && state.isMouseDown && Boolean(scrollDiff)) {
        e.preventDefault();
        if (!document.body.contains(overlay)) {
          overlay.style.cursor = 'grabbing';
          overlay.style.position = 'fixed';
          overlay.style.inset = '0';
          document.body.appendChild(overlay);
        }
        state.isDragging = true;
        container.scrollLeft = state.scrollX + scrollDiff;
      }
    }

    function handleWheel(e) {
      if (container) {
        container.scrollLeft += e.deltaX || e.deltaY;
      }
    }

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    container?.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      container?.removeEventListener('wheel', handleWheel);
    };
  }, [overlay]);

  return { dragContainerRef };
}

export default useMouseDrag;
