import { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { none, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { LoadingOverlay } from 'components';

import { ChevronDown } from 'components/icons';
import { Button, Checkbox, Label, Search } from 'components/forms';
import { Tabs } from 'components/navigation';

import { ArticleCard } from 'journals/components';
import { useLibrarySearch, useLibrarySearchSummary } from 'library/hooks';
import { adultContentTags } from 'library/constants';

import assetType from '../../propTypes/assetType';
import LibraryAssetCard from './../asset/LibraryAssetCard';
import LibraryElementWrapper from './ElementWrapper';

export default function ElementPicker({
  onSelect,
  onHide,
  multiple,
  assetTypes,
  selectedAssets,
  query,
  maxAllowedAssets,
  showSafeSearch = true,
}) {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState('all');
  const [queryParams, setQueryParams] = useState({
    type: 'all',
    query: query ?? null,
  });
  const numberInitiallySelectedAssets = () =>
    selectedAssets?.map?.((asset, index) => ({
      order: index + 1,
      ...asset,
    }));
  const [selectedAssetsIds, setSelectedAssetsIds] = useState(
    numberInitiallySelectedAssets
  );
  const [safeSearchEnabled, setSafeSearchEnabled] = useState(true);

  const updateTab = (assetType) => {
    setTabKey(assetType);
    setQueryParams((params) => ({ ...params, type: assetType }));
  };

  const tabs = assetTypes.map((key) => {
    const articleTypes = [
      'article',
      'healthtopic',
      'drug',
      'event',
      'biomarker',
    ];

    const allTab = key === 'all';

    const count = selectedAssetsIds.filter(
      ({ type, subtype }) =>
        type === key ||
        subtype === key ||
        (allTab &&
          (articleTypes.includes(type) || articleTypes.includes(subtype)))
    ).length;

    return {
      key,
      label: `${t(`common.${key}`, {
        count: 0,
      })}${count ? ` (${count})` : ''}`,
    };
  });

  const { data: elementsCounts } = useLibrarySearchSummary(queryParams?.query, {
    enabled: Boolean(queryParams?.query?.trim?.().length),
  });

  const totalElements = propOr('image', queryParams?.type)(elementsCounts);

  const {
    data,
    isFetching: isSearching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useLibrarySearch(queryParams, totalElements);

  const allElements = data?.pages?.map(({ data }) => data).flat() || [];

  const onSearch = async (input) => {
    setQueryParams((params) => ({
      ...params,
      query: input,
      type: tabKey,
    }));
  };

  const handleAssetClick = ({
    id: selectedId,
    type: selectedType,
    ...rest
  }) => {
    const existingAsset = selectedAssetsIds.find(({ id }) => id === selectedId);

    // "Remove" the asset if it is already selected
    if (existingAsset) {
      setSelectedAssetsIds((old) =>
        old
          .filter(({ id }) => selectedId !== id)
          .map(({ order, ...rest }, index) => ({
            ...rest,
            order: index + 1,
          }))
      );
      return;
    }

    if (selectedAssetsIds.length >= maxAllowedAssets) {
      return;
    }

    if (multiple) {
      setSelectedAssetsIds((old) => [
        ...old,
        {
          id: selectedId,
          type: selectedType,
          order: old.length + 1,
          url: rest?.thumbnail?.url,
          ...rest,
        },
      ]);
    } else {
      setSelectedAssetsIds([
        {
          id: selectedId,
          type: selectedType,
          order: 1,
          url: rest?.thumbnail?.url,
          ...rest,
        },
      ]);
    }
  };

  const handleSafeSearchChange = (event) =>
    setSafeSearchEnabled(event?.target?.checked);

  const isSafeAsset = (asset) => {
    if (!safeSearchEnabled) return true;
    return none(
      (adultContentTag) =>
        asset?.tags?.['safe-search']?.key === adultContentTag,
      adultContentTags
    );
  };

  const isAllTab = tabKey === 'all';

  const AssetComponent = isAllTab ? ArticleCard : LibraryAssetCard;

  return (
    <div className="flex-grow bg-white">
      <div className="relative flex flex-col">
        <Tabs
          tabs={tabs}
          activeTab={tabKey}
          onTabSelect={updateTab}
          fullWidth
        />

        <div className="my-4 flex w-full flex-wrap lg:flex-nowrap">
          <Search
            className="h-9 w-full flex-grow"
            onSearch={onSearch}
            placeholder={t('common.search')}
            label={t('labels.common.search-button', {
              type: t('common.images-videos'),
            })}
            value={queryParams?.query}
          />
          {showSafeSearch && (
            <div className="mt-4 flex-none lg:mt-0 lg:px-10">
              <Label>
                <Checkbox
                  checked={safeSearchEnabled}
                  onChange={handleSafeSearchChange}
                  className="mr-2"
                />{' '}
                {t('common.safe-search')}
              </Label>
            </div>
          )}
        </div>
        <LoadingOverlay isLoading={isSearching && !isFetchingNextPage}>
          <div className="static -mx-4 overflow-y-scroll  p-4 md:h-[343px]">
            <ul
              style={
                isAllTab
                  ? { display: 'block' }
                  : {
                      display: 'grid',
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
                      gap: '1rem',
                    }
              }
            >
              {allElements.filter(isSafeAsset).map((asset) => (
                <li
                  key={asset.id}
                  className={classnames(
                    'smd-focus-visible-primary cursor-pointer rounded-sm',
                    'aspect-w-6',
                    isAllTab ? 'aspect-h-4 sm:aspect-h-[1.5]' : 'aspect-h-5'
                  )}
                >
                  <LibraryElementWrapper
                    asset={asset}
                    selectedAssets={selectedAssetsIds}
                    onClick={() => handleAssetClick(asset)}
                    hasNumericMarking={multiple}
                  >
                    <AssetComponent
                      article={asset}
                      asset={asset?.featuredAsset}
                    />
                  </LibraryElementWrapper>
                </li>
              ))}
            </ul>

            {hasNextPage && (
              <div className="flex w-full justify-center pt-4">
                <Button.Ghost
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                  className="font-semibold"
                >
                  <span>{t('common.show-more')}</span>
                  <ChevronDown className="h-5 w-5" strokeWidth="3" />
                </Button.Ghost>
              </div>
            )}
          </div>
        </LoadingOverlay>
        <div
          className="fixed bottom-0 w-full border-t-1
             border-smd-gray bg-white p-4 md:relative md:pb-0"
        >
          <Button.Primary
            disabled={!selectedAssetsIds || selectedAssetsIds.length === 0}
            className="mr-3"
            onClick={(e) => {
              e.preventDefault();
              onSelect(selectedAssetsIds);
            }}
          >
            {t('common.select')}
          </Button.Primary>
          <Button.Tertiary
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
          >
            {t('common.cancel')}
          </Button.Tertiary>
        </div>
      </div>
    </div>
  );
}

ElementPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  assetTypes: PropTypes.array,
  selectedAssets: PropTypes.array,
};

ElementPicker.defaultProps = {
  multiple: true,
  assetTypes: [assetType.IMAGE],
  selectedAssets: [],
};
