import axios from './axios';

export default {
  create(collection) {
    return axios.post('/collections', collection).then(({ data }) => data);
  },
  edit(collection) {
    return axios.patch(`/collections`, collection).then(({ data }) => data);
  },
  get(collectionId) {
    return axios.get(`/collections/${collectionId}`).then(({ data }) => data);
  },
  delete(collectionId) {
    return axios
      .delete(`/collections/${collectionId}`)
      .then(({ data }) => data);
  },
  getAllCollectionsSummary() {
    return axios.get(`/collections/summary`).then(({ data }) => data);
  },
  getAllCollections() {
    return axios.get(`/collections`).then(({ data }) => data);
  },
  getCollections(journalId) {
    return axios
      .get(`/collections${journalId ? '?journal=' + journalId : ''}`)
      .then(({ data }) => data);
  },
  getAllJournalsByCollectionId(collectionId) {
    return axios
      .get(`/collections/${collectionId}/all-journals`)
      .then(({ data }) => data);
  },
  getJournalsByCollectionId(params) {
    return axios
      .get(`/collections/${params.collectionId}/journals`, {
        params: {
          page: params?.page,
          size: params?.size,
        },
      })
      .then(({ data }) => data);
  },
  removeJournal(collectionId, journalKey) {
    return axios
      .delete(`/collections/${collectionId}/journals/${journalKey}`)
      .then(({ data }) => data);
  },
  addJournal(collectionId, journalKey) {
    return axios
      .post(`/collections/${collectionId}/journals/${journalKey}`)
      .then(({ data }) => data);
  },
  reorder(orderMap) {
    return axios
      .patch(`/collections/reorder`, orderMap)
      .then(({ data }) => data);
  },
};
