import { useIsFeatureEnabled } from 'hooks';

function useEnabledFeatures() {
  const Feature = useIsFeatureEnabled.Feature;
  const showBeta = useIsFeatureEnabled(Feature.BETA);
  const isCommunitiesEnabled = useIsFeatureEnabled(Feature.COMMUNITIES);
  const isMyLibraryEnabled = useIsFeatureEnabled(Feature.MYLIBRARY);
  const isNewsletterEnabled = useIsFeatureEnabled(Feature.NEWSLETTER);
  const isSignUpEnabled = useIsFeatureEnabled(Feature.SIGNUP);
  const isStaticPagesEnabled = useIsFeatureEnabled(Feature.STATICPAGES);

  return {
    showBeta,
    isCommunitiesEnabled,
    isMyLibraryEnabled,
    isNewsletterEnabled,
    isSignUpEnabled,
    isStaticPagesEnabled,
  };
}

export default useEnabledFeatures;
