import { useEffect } from 'react';
import { path } from 'ramda';
import { ErrorLabel } from '..';
import { Label, Select } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

function FormSelect({ as: Component = Select, ...props }) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    errors,
    withError = true,
    getValues,
    setValue,
    setError,
    watch,
    resetField,
    defaultValue,
    ...rest
  } = props;

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label ? (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      ) : (
        <></>
      )}
      <Component
        id={id}
        className={className}
        {...register(name, rules)}
        {...rest}
        error={error}
      />
      {withError && <ErrorLabel error={error} />}
    </FormInputWrapper>
  );
}

registerFormElement(FormSelect);

export default FormSelect;
