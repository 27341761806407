import { forwardRef } from 'react';
import classnames from 'classnames';
import { X } from 'components/icons';

function Tag({ className, tag, onDelete }, ref) {
  return (
    <div
      className={classnames(
        'inline-flex items-center space-x-1 rounded px-2.5 py-0.5',
        className || 'bg-smd-accent-light text-smd-accent-dark'
      )}
      id={tag}
      ref={ref}
    >
      <span className="word-break line-clamp-1" title={tag}>
        {tag}
      </span>
      <button
        className="smd-focus-visible-primary"
        type="button"
        onClick={() => onDelete?.(tag)}
      >
        <X className="h-4 w-4 stroke-2" />
      </button>
    </div>
  );
}

export default forwardRef(Tag);
