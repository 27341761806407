import { Button } from 'components/forms';
import { getDistributionConfig } from 'distribution';
import { isAbsolute } from 'journals/utils';

export default function BackToMainButton(props) {
  const button = getDistributionConfig(['backButton']);
  if (!button || !button.caption || !button.url) return null;

  const { url, caption } = button;
  const redirectURL = isAbsolute(url) ? url : `http://${url}`;
  return (
    <Button
      onClick={() => (window.location.href = redirectURL)}
      color="tertiary"
      {...props}
    >
      {caption}
    </Button>
  );
}
