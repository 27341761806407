// import { useCallback, useEffect, useState } from 'react';
import { compose } from 'ramda';
import { getFocalPosition } from 'utils';
// import classnames from 'classnames';

// import { Eye } from '../../icons';
// import { Skeleton } from '../..';

import Image from './Image';
// import withLoFiPreview from './withLoFiPreview';
import withFallbackImage from './withFallbackImage';

// const SkeletonWrapper = ({ children, ...props }) => {
//   const [loaded, setLoaded] = useState(false);

//   const onLoadStart = () => setLoaded(false);
//   const onLoad = () => setLoaded(true);

//   return (
//     <figure className="pointer-events-none relative" {...props}>
//       {children({ onLoadStart, onLoad })}
//       <div
//         className={classnames(
//           'bg-white',
//           'absolute inset-0',
//           'transition-opacity duration-500',
//           loaded ? 'opacity-0' : 'opacity-100'
//         )}
//       >
//         <Skeleton className="h-full w-full" />
//         <div className="flex-center absolute inset-0">
//           <Eye className="h-1/4 w-1/4 text-gray-300" />
//         </div>
//       </div>
//     </figure>
//   );
// };

// const withSkeletonPreview =
//   (Image) =>
//   ({ src, onLoad, style, ...props }) => {
//     const [loaded, setLoaded] = useState(false);

//     useEffect(() => setLoaded(false), [src]);

//     const handleOnLoad = useCallback(
//       (...args) => {
//         setLoaded(true);
//         onLoad?.(...args);
//       },
//       [onLoad]
//     );

//     return (
//       // TODO: style
//       <figure className="pointer-events-none relative" style={style}>
//         <Image src={src} onLoad={handleOnLoad} {...props} />
//         <div
//           className={classnames(
//             'bg-white',
//             'absolute inset-0',
//             'transition-opacity duration-500',
//             loaded ? 'opacity-0' : 'opacity-100'
//           )}
//         >
//           <Skeleton className="h-full w-full" />
//         </div>
//       </figure>
//     );
//   };

// const withFocalPoint =
//   (Image) =>
//   ({ focalPoint, style, ...props }) => {
//     const focal = focalPoint
//       ? {
//           objectFit: 'cover',
//           objectPosition: `${focalPoint.x}% ${focalPoint.y}%`,
//           height: '100%',
//           width: '100%',
//         }
//       : {};
//     return (
//       // TODO: style
//       <figure className="overflow-hidden" style={style}>
//         <Image style={{ ...focal }} {...props} />
//       </figure>
//     );
//   };

export const useFocalPoint = (focalPoint) => {
  const wrapper = focalPoint ? { overflow: 'hidden' } : {};

  const { position, isValid } = getFocalPosition(focalPoint);

  const image = focalPoint
    ? {
        objectPosition: isValid ? position : '50% 50%',
        objectFit: 'cover',
        height: '100%',
        width: '100%',
      }
    : {};
  return { wrapper, image };
};

export default compose(
  // withFocalPoint
  // withSkeletonPreview
  // withLoFiPreview,
  withFallbackImage
)(Image);
