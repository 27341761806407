import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp, X } from 'components/icons';
import { Label, Form } from '..';

function FormSearchDropdown(props) {
  const {
    label,
    labelProps,
    register,
    name,
    rules,
    className,
    errors,
    withError = true,
    setValue,
    getValues,
    options,
    onChange,
    placeholder,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleInputClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    setSearchValue(inputValue);
  };

  const handleOptionClick = (option) => {
    setIsOpen(false);
    setValue(name, { suggestion: option, value: option.name });
    setInputValue(option.name);
    setSearchValue('');
    onChange(option);
  };

  const filteredOptions = options?.filter((option) =>
    option?.name?.toLowerCase().includes(searchValue)
  );

  return (
    <Form.Controller
      {...rest}
      name={name}
      errors={errors}
      withError={withError}
      render={({ field: { value, ref }, ...rest }) => {
        return (
          <>
            {label ? (
              <Label htmlFor={name} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <div className="relative flex items-center lg:w-3/4">
              <input
                onClick={handleInputClick}
                ref={ref}
                placeholder={placeholder}
                name="name"
                type="text"
                value={inputValue}
                onChange={handleSearchInputChange}
                readOnly={!isOpen}
                className="smd-input-focus-within-primary grow border-smd-gray-light p-2 pr-20 leading-normal outline-none placeholder:text-smd-tertiary  focus:border-transparent"
                {...rest}
                {...props}
              />
              {!inputValue && (
                <span className="absolute right-5">
                  {!isOpen ? (
                    <ChevronDown className="h-4 w-4 stroke-2" />
                  ) : (
                    <ChevronUp className="h-4 w-4 stroke-2" />
                  )}
                </span>
              )}
              {(value?.value || searchValue) && (
                <button
                  type="button"
                  className="smd-input-focus-primary absolute right-5 select-none rounded"
                  onClick={() => {
                    setSearchValue('');
                    setInputValue('');
                    setValue(name, { suggestion: null, value: '' });
                    setIsOpen(false);
                  }}
                  aria-label={t('labels.common.clear')}
                >
                  <X className="h-6 w-6 stroke-2" />
                </button>
              )}
            </div>
            {isOpen && (
              <ul className="absolute z-10 mt-[4.5rem] mr-4 max-h-72 min-w-[340px] max-w-md overflow-y-auto rounded border bg-white lg:mr-0">
                {filteredOptions.map(({ name, id }) => {
                  return (
                    <li
                      key={id}
                      value={{ id, name }}
                      onClick={() => handleOptionClick({ id, name })}
                      className="px-4 py-1 text-smd-sm text-smd-gray-darker hover:bg-smd-gray-lightest"
                    >
                      {name}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      }}
    />
  );
}

export default FormSearchDropdown;
