import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { none } from 'ramda';

import { Checkbox, Search } from 'components/forms';

import libraryAssetPropTypes from '../../../../propTypes/asset';
import { useLibraryQuery } from '../../../../hooks';
import LibraryAssetList, {
  LibraryAssetListSkeleton,
} from '../../../asset/LibraryAssetList';

import { adultContentTags } from '../../../../constants';
import useLibraryAssetRoutedModal from '../../../../hooks/useLibraryAssetRoutedModal';

function getAssetFilter(safeSearch, searchText) {
  if (!safeSearch && !searchText) {
    return () => true;
  }
  return (asset) => {
    let hasSearchedText = asset?.name
      ?.toLowerCase()
      .includes(searchText?.toLowerCase() || '');
    let filterSafeSearch =
      !safeSearch ||
      (safeSearch &&
        none(
          (adultContentTag) =>
            asset?.tags?.['safe-search']?.key === adultContentTag,
          adultContentTags
        ));
    let isValidInteractive = Boolean(
      asset?.subtype && asset?.assets?.length > 0
    );
    return asset?.type === 'interactive'
      ? isValidInteractive && hasSearchedText && filterSafeSearch
      : hasSearchedText && filterSafeSearch;
  };
}

function AssetsGridHeading({
  className,
  label,
  assetsCount,
  onFilterChange,
  placeholder,
}) {
  const { t } = useTranslation();

  const [safeSearch, setSafeSearch] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    onFilterChange?.({
      text: searchText,
      safe: safeSearch,
    });
  }, [safeSearch, searchText, onFilterChange]);

  return (
    <div
      className={classnames(
        'flex flex-col md:flex-row md:items-center md:justify-between',
        className
      )}
    >
      <h2 className="mb-smd-sm font-semibold md:mb-0">
        <span className="md:text-lg">{label}</span>
        <span className="ml-smd-xs text-sm text-smd-gray md:text-base">
          ({assetsCount ?? 0})
        </span>
      </h2>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md: mb-smd-sm mr-smd-lg flex items-center md:mb-0">
          <Checkbox
            id="safe-search"
            checked={safeSearch}
            onChange={(x) => setSafeSearch(x.target.checked)}
          />
          <label htmlFor="safe-search" className="ml-smd-sm">
            {t('common.safe-search')}
          </label>
        </div>
        <Search
          className="mb-smd-sm h-9 md:mb-0"
          onChange={setSearchText}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

function TopicAssets({ hashIds, section }) {
  const { t } = useTranslation();
  const { data: assets, isFetching } = useLibraryQuery(hashIds);

  const [filter, setFilter] = useState();
  const filtered = useMemo(
    () => assets?.filter(getAssetFilter(filter?.safe, filter?.text)),
    [assets, filter?.text, filter?.safe]
  );

  const { openLibraryAssetModal } = useLibraryAssetRoutedModal({
    assets: filtered,
    isFetching,
  });

  const label = t(`journals.related.${section}`, { count: hashIds?.length });
  const placeholder = t(`assets.filter.${section}`);

  return (
    <div className="w-full p-smd-sm md:p-smd-md xl:p-smd-lg">
      <AssetsGridHeading
        className="md:mb-smd-md xl:mb-smd-lg"
        label={label}
        placeholder={placeholder}
        assetsCount={filtered?.length}
        onFilterChange={setFilter}
      />
      {isFetching ? (
        <LibraryAssetListSkeleton />
      ) : (
        <LibraryAssetList assets={filtered} onClick={openLibraryAssetModal} />
      )}
    </div>
  );
}

TopicAssets.propTypes = {
  assets: PropTypes.arrayOf(libraryAssetPropTypes.shape),
};

export default TopicAssets;
