import React, { useState } from 'react';

import { useDefaultToRandomId } from 'hooks';
import { FocalPointBackground } from '../media';
import Upload from './Upload';
import { getImageFileAsUrl } from './utils';

// controlled prop
function UploadCover({ id, label, className, ...props }) {
  const safeId = useDefaultToRandomId(id);
  const {
    fieldState: { error },
    field: { ref, value, onChange, ...rest },
  } = props;

  const [image, setImage] = useState(value);

  function handleImageChange(file) {
    getImageFileAsUrl(file)
      .then((result) => {
        setImage(result);
        onChange(file);
      })
      .catch();
  }

  return (
    <div
      className={`relative h-full w-full ${image ? '' : 'border-2'}
            ${error ? 'border-smd-error' : 'border-smd-accent'}
            ${className ?? ''}`}
    >
      <label
        htmlFor={safeId}
        className={`h-full ${image ? 'text-white' : 'text-smd-accent'}`}
      >
        <FocalPointBackground
          className={`flex-center h-full w-full cursor-pointer space-y-smd-base`}
          url={image}
        >
          {image && (
            <div className={'absolute h-full w-full bg-black opacity-30'} />
          )}
          <div className="relative text-smd-sm ">
            <div className="relative m-2 text-sm ">{label}</div>
            <Upload.Image id={safeId} onChange={handleImageChange} {...rest} />
          </div>
        </FocalPointBackground>
      </label>
    </div>
  );
}

export default UploadCover;
