import { phr as api } from './axios';
import { getUpdateFormData } from './utils';

const endpoint = (path) => `/phr/ocr/${path ?? ''}`;

const buildLibraryApi = (axios) => ({
  createDraft(draft) {
    return axios.post(endpoint(), draft).then(({ data }) => data);
  },

  updateOCR(data) {
    return axios
      .put(endpoint(), getUpdateFormData(data), {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },

  getOCR(id) {
    return axios.get(endpoint(`${id}`)).then(({ data }) => data);
  },

  getOCRPreview(id) {
    return axios.get(endpoint(`preview/${id}`)).then(({ data }) => data);
  },

  postOcrData({ id, preview }) {
    return axios
      .post(endpoint(`preview/${id}`), preview)
      .then(({ data }) => data);
  },
});

export default buildLibraryApi(api);
