import { useTranslation } from 'react-i18next';

import {
  AlternatingTextColorElement,
  Aspect,
  CTA,
  ContentContainer,
  Video,
} from 'components';

import { getAssetUrl } from 'utils';
import { Carousel, carouselBaseConfig } from 'components/Carousel';
import { PictureResource } from 'components/media';
import { normalizeAssetUrl } from 'journals/utils';
import { slugifyObject } from 'utils/slug';

function FeaturedJourney({
  title,
  description,
  type,
  alt,
  url,
  name,
  id,
  thumbnail,
  images,
}) {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="px-4 pb-6 font-bold lg:px-0 lg:text-smd-h1">
        {t('journeys.featured-journeys')}
      </h1>
      <div className="flex flex-col items-center px-4 lg:flex-row lg:space-x-8 lg:px-0">
        <div className="order-2 mt-4 grid w-full flex-1 justify-start lg:order-1 lg:mt-0 lg:content-center">
          <div>
            <h2 className="mb-2 hidden text-smd-h1 font-bold lg:block">
              <i className="storymd-plus" />
              <AlternatingTextColorElement
                name={title}
                evenColor="text-smd-gray"
                oddColor="text-smd-accent-bright"
              />
            </h2>
            <p
              className="mb-4 text-smd-base line-clamp-3 lg:line-clamp-6"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <CTA
              color="primary"
              className="my-6 font-bold lg:my-0"
              ctaProps={{
                ctaInternal: `/journey/${slugifyObject(id, title)}`,
              }}
            >
              {t('journeys.explore')}
            </CTA>
          </div>
        </div>
        <div className="relative order-1 w-full flex-1 lg:order-2">
          <h2 className="mb-4 block text-smd-h2.5 font-bold lg:hidden">
            <i className="storymd-plus" />
            <AlternatingTextColorElement
              name={title}
              evenColor="text-smd-gray"
              oddColor="text-smd-accent-bright"
            />
          </h2>
          {type === 'video' && (
            <Aspect.Fixed ratio={16 / 9} style={{ overflow: 'hidden' }}>
              <Video
                {...{
                  thumbnail: getAssetUrl(thumbnail),
                  url: getAssetUrl(url),
                  controls: true,
                  className: 'prevent-swiper border',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Aspect.Fixed>
          )}
          {type === 'image' && (
            <Aspect.Fixed ratio={16 / 9} style={{ overflow: 'hidden' }}>
              <PictureResource
                config={{
                  url: normalizeAssetUrl(thumbnail),
                  alt: alt,
                  images: images,
                }}
                style={{
                  objectPosition: 'center',
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
                loading="lazy"
                sizes="22rem"
              />
            </Aspect.Fixed>
          )}
        </div>
      </div>
    </>
  );
}

export default function FeaturedJourneysCarousel({ journeys }) {
  const slides = journeys.map((journey) => {
    const journeyVideo = journey?.linkages?.topics?.find(
      (entry) => entry?.type === 'video'
    );

    const carouselSlideAsset = journeyVideo
      ? {
          thumbnail: journeyVideo.thumbnail,
          url: journeyVideo.url,
          images: journeyVideo.images,
          type: journeyVideo.type,
        }
      : {
          thumbnail: journey?.featuredAsset?.thumbnail,
          url: journey?.featuredAsset?.url,
          images: journey?.featuredAsset?.images,
          type: journey?.featuredAsset?.type,
        };

    return {
      id: journey?.id,
      title: journey?.name,
      description: journey?.description,
      ...carouselSlideAsset,
    };
  });

  const props = {
    ...carouselBaseConfig,
    spaceBetween: 30,
    autoHeight: false,
  };
  return (
    <div className="my-8 w-full bg-smd-accent-light pt-10 pb-8">
      <ContentContainer>
        <Carousel
          className="relative w-full lg:px-12"
          carouselProps={props}
          slides={slides.map((slide) => (
            <Carousel.Slide key={JSON.stringify(slide?.id)}>
              <FeaturedJourney {...slide} />
            </Carousel.Slide>
          ))}
        >
          <Carousel.Prev className="top-auto -bottom-2 mx-2 lg:bottom-0 lg:top-0" />
          <Carousel.Next className="top-auto -bottom-2 mx-2 lg:bottom-0 lg:top-0" />
          <Carousel.Pagination className="pt-4" />
        </Carousel>
      </ContentContainer>
    </div>
  );
}
