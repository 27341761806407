import React, { useMemo, useRef } from 'react';

import { Slider } from 'components/range';

const step = 1;

function SliderNavigation({ count, initialValueState = 0, onChange }) {
  const currentIndex = useRef(initialValueState);
  const ranges = useMemo(() => {
    const firstElement = {
      color: 'lightgray',
      min: 0,
      max: 1,
      label: { content: 1 },
    };
    const lastElement = {
      color: 'lightgray',
      min: count - 1,
      max: count - 1,
      label: { content: count },
    };
    const ranges = Array(count)
      .fill()
      .map((x, i) => {
        return {
          color: 'lightgray',
          min: i,
          max: i + 1,
          label: { content: i + 1 },
        };
      });
    ranges.unshift(firstElement);
    ranges.push(lastElement);
    return ranges;
  }, [count]);

  const handleChange = (value) => {
    if (onChange) {
      if (currentIndex.current !== value) {
        currentIndex.current = value;
        onChange(value);
      }
    }
  };
  return (
    <Slider
      ranges={ranges}
      value={initialValueState}
      onChange={handleChange}
      centerLabels={false}
      step={step}
    />
  );
}

export default SliderNavigation;
