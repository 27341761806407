import { useState } from 'react';
import classnames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// eslint-disable-next-line
import QuillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import QuillToolbar, { modules } from 'communities/QuillInput/QuillToolbar';

import { path } from 'ramda';
import { Form } from '..';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormInputWrapper from './FormInputWrapper';

const EMPTY_QUILL_ELEMENT_CONTENT = `<p><br></p>`;

function FormQuillInput(props) {
  const {
    groupClassName,
    groupProps,
    label,
    labelProps,
    register,
    name,
    id = name,
    className,
    errors,
    setValue,
    getValues,
    setError,
    watch,
    resetField,
    ...rest
  } = props;

  const [toolbar, setToolbar] = useState();

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  return (
    <FormInputWrapper className={groupClassName} {...groupProps}>
      {label ? (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      ) : (
        <></>
      )}
      <Form.Controller
        {...rest}
        errors={errors}
        withError={error}
        name={name}
        render={({ field: { value, ref, onChange }, ...rest }) => {
          return (
            <div
              className={classnames('border border-smd-gray-light', className)}
            >
              <QuillToolbar ref={setToolbar} />
              {toolbar && (
                <ReactQuill
                  ref={ref}
                  value={value}
                  theme="snow"
                  modules={modules}
                  onChange={(content, delta, source, editor) => {
                    if (source === 'user') {
                      onChange?.(
                        content === EMPTY_QUILL_ELEMENT_CONTENT ? '' : content
                      );
                    }
                  }}
                />
              )}
            </div>
          );
        }}
      />
    </FormInputWrapper>
  );
}

registerFormElement(FormQuillInput);

export default FormQuillInput;
