import { useEffect } from 'react';

function useBeforeUnload(enabled) {
  useEffect(() => {
    const preventUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (enabled) {
      window.addEventListener('beforeunload', preventUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [enabled]);
}

export default useBeforeUnload;
