import { extractWebpThumbnail, getFocalPosition } from 'utils';

import { useHoverMenu } from 'hooks';
import { ContentContainer } from 'components';
import { GradientBackground, PictureResource } from 'components/media';

function FeaturedJourney() {
  const data = useHoverMenu();
  const journeyConfig = data?.menu?.journeysHero ?? {};
  const { position, isValid } = getFocalPosition(journeyConfig.focalPoint);
  const webpThumbnail = extractWebpThumbnail(journeyConfig?.images);

  return (
    <div className="relative">
      <PictureResource
        config={{
          url:
            webpThumbnail?.path || journeyConfig.thumbnail || journeyConfig.url,
          alt: journeyConfig.alt,
          images: journeyConfig.images,
        }}
        style={{
          objectPosition: isValid ? position : 'center',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        pictureClassName="absolute inset-0"
      />
      <GradientBackground className="absolute inset-0" />
      <div className="px-4 lg:px-12">
        <ContentContainer className="relative space-y-8 pt-64 pb-8 text-white md:py-32">
          <div className="text-smd-h2 font-bold">{journeyConfig.title}</div>
          <div className="max-w-md">{journeyConfig.description}</div>
        </ContentContainer>
      </div>
    </div>
  );
}

export default FeaturedJourney;
