import { useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import { includes, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Plus } from 'components/icons';
import theme from 'my-phr/layout/modules/ManualInputs/inputs/TypeaheadInput.module.css';

function Option({ label, addsNew, value }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'flex items-center space-x-2 p-2 hover:cursor-pointer hover:bg-smd-gray-lightest',
        {
          'text-smd-accent': addsNew,
        }
      )}
    >
      {addsNew ? (
        <>
          <Plus className="pointer-events-none h-4 w-4 shrink-0 rounded-full bg-smd-accent text-white" />
          <span className="truncate">
            {t('my-phr.manual-inputs.add-custom-tag', { value })}
          </span>
        </>
      ) : (
        <span className="pointer-events-none truncate">{label}</span>
      )}
    </div>
  );
}

export default function TypeaheadDropdown({
  options = [],
  placeholder,
  onValueReceived,
  onInputChange,
}) {
  const [suggestions, setSuggestions] = useState(options);
  const [value, setValue] = useState('');

  const ref = useRef();

  const inputProps = {
    placeholder,
    ref: ref,
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
      onInputChange(event);
    },
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.target.blur();
        const selectedSuggestion = suggestions.find(
          (suggestion) => suggestion.label === value
        );
        if (selectedSuggestion) {
          onValueReceived(selectedSuggestion?.value);
        } else {
          onValueReceived(value);
        }
        setValue('');
      }
    },
    className:
      'w-full leading-normal smd-input-focus-primary border-smd-gray-light placeholder:text-smd-tertiary border-smd focus:border-smd-accent',
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const matches = options?.filter?.((record) =>
      includes(value?.toLowerCase(), record?.label?.toLowerCase())
    );

    const addNewOption = {
      label: value,
      addsNew: true,
      value,
    };

    const isExactMatch = matches.some((entry) => entry?.label === value);

    if (Boolean(value?.length) && !isExactMatch) {
      matches.push(addNewOption);
    }

    setSuggestions(matches);
  };

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(_, { suggestionValue }) {
    onValueReceived(suggestionValue);
    setValue('');
    ref?.current?.blur?.();
  }

  return (
    <Autosuggest
      focusInputOnSuggestionClick={false}
      theme={theme}
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={prop('label')}
      renderSuggestion={(props) => <Option {...props} />}
      shouldRenderSuggestions={() => true}
      inputProps={inputProps}
    />
  );
}
