import { isNil } from 'ramda';
import { ComparisonInteractiveWithLimitedHeight } from 'journals/blocks/output/HtmlBlock/LibraryInteractive/interactives/ComparisonInteractive';
import Slideshow from 'journals/blocks/output/HtmlBlock/LibraryInteractive/interactives/Slideshow';

export const interactiveTypeMap = {
  comparison: ComparisonInteractiveWithLimitedHeight,
  slider: Slideshow,
};

export default function InteractiveAsset({ config, ...props }) {
  const Interactive = interactiveTypeMap[config?.subtype];
  if (isNil(Interactive)) {
    // throw new Error(`Invalid interactive asset subtype: ${config?.subtype}`);
    console.error(`Invalid interactive asset subtype: ${config?.subtype}`);
    return null;
  }
  return <Interactive config={config} {...props} />;
}
