import { cn } from 'lib/utils';

function SquareButton({ inverse = false, className = '', ...props }) {
  return (
    <button
      className={cn(
        'h-7 w-7 transform select-none bg-opacity-40 p-1 opacity-75 hover:scale-105 hover:bg-opacity-60 hover:opacity-90 focus:outline-none',
        `${inverse ? 'bg-black text-white' : 'bg-white text-black'}`,
        className
      )}
      {...props}
    />
  );
}

export default SquareButton;
