import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMedia } from 'hooks';
import useMenuReducer from './useMenuReducer';

export default function useMenu() {
  const [
    { isUserOpen, isListOpen, isNotificationOpen, isJourneysOpen, isBetaOpen },
    dispatch,
  ] = useMenuReducer();

  const isLargeScreen = useMedia(useMedia.LARGE);
  useEffect(() => dispatch({ type: 'close' }), [isLargeScreen, dispatch]);

  const location = useLocation();
  useEffect(() => dispatch({ type: 'close' }), [location, dispatch]);

  const showBackdrop =
    ((isUserOpen || isListOpen || isNotificationOpen || isBetaOpen) &&
      !isLargeScreen) ||
    (isLargeScreen && isJourneysOpen);

  return [
    {
      isUserOpen,
      isListOpen,
      isLargeScreen,
      isJourneysOpen,
      isNotificationOpen,
      isBetaOpen,
      showBackdrop,
    },
    dispatch,
  ];
}
