import PropTypes from 'prop-types';

import { FocalPointBackground, GradientBackground } from 'components/media';
import { Aspect, Credits } from 'components';

import { BlurredLayer, blurredStyle } from 'components/BlurredLayer';
import credit from '../../../propTypes/credit';

function LibraryItemCover({
  label,
  title,
  subtitle,
  content,
  imageSrc,
  imageName,
  imageFocalPoint,
  credit,
  isSensitive,
}) {
  return (
    <div>
      <BlurredLayer isSensitive={isSensitive} className="relative">
        {({ isBlurred }) => (
          <FocalPointBackground
            focalPoint={imageFocalPoint}
            url={imageSrc}
            className="relative"
            style={isBlurred ? blurredStyle : {}}
          >
            <Aspect.Min ratio={16 / 9}>
              <GradientBackground className={'absolute h-full w-full'} />
              <div className="flex h-full flex-col">
                <div className="relative hidden h-full flex-1 flex-col justify-center break-words p-smd-xxl text-white lg:flex">
                  <h2 className="break-word overflow-hidden text-smd-t2 font-semibold xl:w-2/3">
                    {title}
                  </h2>
                  <p className="break-word w-2/3 pt-smd-xs text-smd-sm line-clamp-2">
                    {subtitle}
                  </p>
                  <p
                    className=" break-word w-2/3 overflow-hidden pt-smd-lg text-smd-h4"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
                <div className="relative hidden px-smd-base pb-smd-base text-white lg:block">
                  <Credits name={imageName} source={credit?.name} />
                </div>
                <div
                  className="relative flex h-full flex-col justify-end p-smd-lg text-white lg:hidden"
                  style={{ minHeight: '20rem' }}
                >
                  <p className=" text-smd-base font-semibold uppercase tracking-smd-wide">
                    {label}
                  </p>
                  <p className="pt-smd-xxs text-smd-h3 font-semibold line-clamp-2">
                    {title}
                  </p>
                </div>
              </div>
            </Aspect.Min>
          </FocalPointBackground>
        )}
      </BlurredLayer>
      <div className="block px-smd-lg lg:hidden">
        <p className=" break-word w-full pt-smd-base text-smd-base italic text-smd-secondary">
          {subtitle}
        </p>
        <p
          className="break-word overflow-hidden pt-smd-base text-smd-base text-smd-main"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="pt-smd-base text-smd-sm italic text-smd-gray-light">
          <p>{imageName}</p>
          <p>{credit?.name}</p>
        </div>
      </div>
    </div>
  );
}

LibraryItemCover.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  imageSrc: PropTypes.string,
  imageName: PropTypes.string,
  credit: credit.shape,
};

export default LibraryItemCover;
