export const RELATED_ID = 'related';
export const RELATED_PATH = 'related';
export const POC_SHARE_ID = 'share';
export const POC_SHARE_PATH = 'share';

export const viewerPath = Object.freeze({
  EMBED: 'embed',
  EMBED404: 'embed-404',
  POC_JOURNAL: 'share/journal',
  JOURNAL: 'journal',
  PAGE: 'page',
});
