import { useTranslation } from 'react-i18next';

import { useCopyToClipboardFeedback } from 'hooks';
import { Duplicate } from 'components/icons';

function ShareUrl({ url }) {
  const { t } = useTranslation();
  const { copy, isSupported, isCopied } = useCopyToClipboardFeedback(1000);

  return (
    <div className="mt-6 w-full">
      <label className="relative text-smd-xs font-semibold text-smd-tertiary">
        {t('journals.share.copy-link')}
        <div className="flex-center mt-2">
          <input
            className="flex-grow px-2 py-2.5 text-smd-xs font-light italic text-smd-tertiary"
            placeholder={t('labels.common.copy-url')}
            disabled
            value={url}
          />
          {isSupported && (
            <button
              className="ml-2 h-8 w-8 shrink-0 text-smd-accent hover:text-smd-accent-dark focus:outline-none"
              aria-label={t('labels.common.copy-url')}
              onClick={() => copy(url)}
            >
              <Duplicate />
            </button>
          )}
        </div>
        {isCopied && (
          <p
            role="status"
            className="absolute whitespace-nowrap pt-1 font-normal italic text-smd-accent"
          >
            {t('journals.share.link-copied')}
          </p>
        )}
      </label>
    </div>
  );
}

export default ShareUrl;
