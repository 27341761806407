import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const INFINTE_SCROLL_ENABLED =
  process.env.REACT_APP_ENABLE_INFINITE_SCROLL === 'true';

function useInfiniteScrollTrigger(fetch) {
  const { ref, inView } = useInView();
  const infiniteScrollEnabled = INFINTE_SCROLL_ENABLED ?? false;

  useEffect(() => {
    if (infiniteScrollEnabled && inView) {
      fetch?.();
    }
  }, [inView, infiniteScrollEnabled, fetch]);

  return {
    ref,
    infiniteScrollEnabled,
  };
}

export default useInfiniteScrollTrigger;
