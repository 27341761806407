import { useCallback } from 'react';

import { measurementUnit } from 'utils';
import { useProfile } from 'ProfileContext';

export default function usePhrUnitValuePreference() {
  const [profile] = useProfile();
  const profileUnit = profile?.unit || measurementUnit.IMPERIAL;
  const getUnit = useCallback(
    (phr) => phr?.[profileUnit + '_unitLabel'] || phr?.unitLabel || phr?.unit,
    [profileUnit]
  );
  const getRawUnit = useCallback(
    (phr) => phr?.[profileUnit + '_unit'] || phr?.unit,
    [profileUnit]
  );
  const getValue = useCallback(
    (phr) => phr?.[profileUnit + '_value'] || phr?.value,
    [profileUnit]
  );
  const getValueFieldName = useCallback(
    (phr) => (phr?.[profileUnit + '_value'] ? profileUnit + '_value' : 'value'),
    [profileUnit]
  );
  return {
    getUnit,
    getValue,
    getRawUnit,
    getValueFieldName,
  };
}
