import { useEffect, useState, useCallback } from 'react';
import { append, concat, ifElse, includes, is, uniq, without } from 'ramda';

const toggle = ifElse(includes, without, append);

const getPathTo = (id, root) => {
  if (root.id === id) {
    return root.children ? [root.id] : [];
  }
  if (root.children) {
    for (let child of root.children) {
      const path = getPathTo(id, child);
      if (path) {
        return [root.id, ...path];
      }
    }
  }
  return null;
};

const traverse = (root, cb) => {
  const iterate = (node, parent, cb) => {
    cb(node, parent);
    if (node.children) {
      node.children.forEach((child) => iterate(child, node, cb));
    }
  };
  iterate(root, null, cb);
};

const useTocState = (root, activeId) => {
  const [openIds, setOpenIds] = useState([]);
  const [completeIds, setCompleteIds] = useState([]);

  const [nodes, setNodes] = useState([]);
  const [nodeMap, setNodeMap] = useState({});

  useEffect(() => {
    const nodes = [];
    const nodeMap = {};
    traverse(root, (node, parent) => {
      nodes.push(node);
      nodeMap[node.id] = node;
    });
    setNodes(nodes);
    setNodeMap(nodeMap);
  }, [root]);

  useEffect(() => {
    const activePath = getPathTo(activeId, root);
    if (activePath) {
      // eslint-disable-next-line no-unused-vars
      const [rootId, firstLevelId] = activePath;
      setOpenIds((openIds) => {
        const isBranchOpen = openIds.includes(firstLevelId);

        return isBranchOpen ? uniq(concat(openIds, activePath)) : activePath;
      });
    }
  }, [root, activeId, setOpenIds]);

  useEffect(() => {
    const completeIds = nodes
      .filter((node) => node.completed)
      .map((node) => node.id);
    setCompleteIds(completeIds);
  }, [nodes]);

  const markAsComplete = useCallback(
    (node) => {
      setCompleteIds((completeIds) => {
        if (completeIds.includes(node.id)) {
          return completeIds;
        }

        if (is(Function, node.complete) && !node.complete()) {
          return completeIds;
        }

        const newIds = [node.id];

        const path = getPathTo(node.id, root)?.reverse();
        if (path) {
          for (let parentId of path) {
            const parent = nodeMap[parentId];
            const allChildrenAreComplete = parent?.children?.reduce(
              (acc, { id }) =>
                acc && (completeIds.includes(id) || newIds.includes(id)),
              true
            );

            if (allChildrenAreComplete) {
              newIds.push(parent.id);
            } else {
              break;
            }
          }
        }

        return [...completeIds, ...newIds];
      });
    },
    [root, nodeMap, setCompleteIds]
  );

  const selectNode = useCallback(
    (node) => {
      if (node.children) {
        setOpenIds((openIds) => {
          const isOpen = openIds.includes(node.id);
          const isFirstLevel = root.children?.includes(node);

          return isFirstLevel && !isOpen ? [node.id] : toggle(node.id, openIds);
        });
      } else {
        markAsComplete(node);
      }
    },
    [root, setOpenIds, markAsComplete]
  );

  useEffect(() => {
    const node = nodeMap[activeId];
    if (node && !node.children) {
      markAsComplete(node);
    }
  }, [activeId, nodeMap, markAsComplete]);

  return { openIds, completeIds, selectNode };
};

export { useTocState as default };
