import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'components/icons';

const baseClasses = [
  'relative',
  'w-full flex justify-between items-center',
  'py-2 px-3',
  'border',
  'text-sm text-left leading-4 antialiased',
  'focus-visible:rounded-sm smd-focus-visible-primary',
];

const activeClasses = baseClasses
  .concat([
    'active',
    'text-white font-semibold',
    'bg-smd-accent border-smd-accent',
  ])
  .join(' ');

const secondaryActiveClasses = baseClasses
  .concat([
    'secondaryactive',
    'text-smd-accent font-bold',
    'bg-white border-smd-accent',
  ])
  .join(' ');

const inactiveClasses = baseClasses
  .concat(['text-smd-gray-darker', 'bg-white border-smd'])
  .join(' ');

const TocLink = forwardRef(function TocButton(
  { active, className, ...props },
  ref
) {
  return (
    <Link
      ref={ref}
      className={active ? activeClasses : inactiveClasses}
      {...props}
    />
  );
});

const TocButton = forwardRef(function TocButton(
  { active, className, activeClassName = activeClasses, ...props },
  ref
) {
  return (
    <button
      ref={ref}
      className={active ? activeClassName : inactiveClasses}
      {...props}
    />
  );
});

const TocButtonSecondary = (props) => (
  <TocButton activeClassName={secondaryActiveClasses} {...props} />
);

function Completed(props) {
  return (
    <div
      className="flex-center absolute h-4 w-4 rounded-full border border-white bg-smd-accent text-white"
      style={{ left: '-.5rem' }}
      {...props}
    >
      <Check strokeWidth="4" className="h-2.5 w-2.5" />
    </div>
  );
}

function ExpandHandle({ active, ...props }) {
  return (
    <span
      className={`ml-2 w-2.5 shrink-0 ${active ? null : 'text-smd-gray'}`}
      {...props}
    />
  );
}

export {
  TocButton as default,
  TocButtonSecondary,
  TocLink,
  Completed,
  ExpandHandle,
};
