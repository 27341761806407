import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { slice, values } from 'ramda';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AddToCollectionModal, JournalCard } from 'journals/components';
import { Button } from 'components/forms';
import { ChevronDown, ChevronUp, Share } from 'components/icons';
import { ContentContainer } from 'components';
import { parseJournals } from 'landing/utils';
import { debounce } from 'utils';
import { useScrollIntoView } from 'hooks';
import { useJournalLastLocation } from 'journals/hooks';
import classNames from 'classnames';
import {
  AddToCollection,
  JournalCardActionsWrapper,
} from 'journals/components/actions';
import { useAuth } from 'Auth';

const SHOW_MORE_THRESHOLD = 8;

function Subcollection({
  name,
  journals = [],
  inSingleCollectionView,
  setCollectionJournal,
  className,
  titleClassName = 'pb-6 pt-8',
}) {
  const { t } = useTranslation();
  const parsedJournals = parseJournals(journals);
  const [showsMore, setShowsMore] = useState(false);
  const withShowMoreButton = parsedJournals?.length > SHOW_MORE_THRESHOLD;
  const { setJournalLastLocation } = useJournalLastLocation({
    locationProvider: () => ({ pathname: window?.location?.pathname }),
  });
  const journalsToRender = showsMore
    ? parsedJournals
    : slice(0, SHOW_MORE_THRESHOLD, parsedJournals);
  return (
    <article className={classNames('flex flex-col pb-8', className)}>
      {!inSingleCollectionView && (
        <h2 className={classNames('text-smd-h3 font-bold', titleClassName)}>
          {name}
        </h2>
      )}
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 2xl:grid-cols-4">
        {journalsToRender?.map?.((journal) => (
          <JournalCardActionsWrapper
            key={journal.id}
            actions={[
              {
                onClick: () => setCollectionJournal(journal),
                element: <AddToCollection />,
              },
            ]}
          >
            <Link to={`/journal/${journal.slug}`}>
              <JournalCard
                className="smd-transform-duration cursor-pointer"
                preamble={journal?.preamble}
                onClick={setJournalLastLocation}
              />
            </Link>
          </JournalCardActionsWrapper>
        ))}
      </div>
      {withShowMoreButton && (
        <Button.Tertiary
          className="mt-8 place-self-center"
          onClick={() => setShowsMore((value) => !value)}
        >
          {showsMore ? (
            <>
              <span>{t('common.show-less')}</span>
              <ChevronUp className="h-6 w-6 stroke-2" />
            </>
          ) : (
            <>
              <span>{t('common.show-more')}</span>
              <ChevronDown className="h-6 w-6 stroke-2" />
            </>
          )}
        </Button.Tertiary>
      )}
    </article>
  );
}

const CollectionContent = forwardRef(
  ({ name, subcollections = [], onShare, onSectionChanged }, ref) => {
    const { t } = useTranslation();

    const scrollIntoView = useScrollIntoView();

    const { authenticated } = useAuth();
    const [collectionJournal, setCollectionJournal] = useState();

    const refs = useRef({});
    const handleRef = useCallback((id, node) => (refs.current[id] = node), []);

    useImperativeHandle(ref, () => ({
      scrollTo: (id) => scrollIntoView?.(refs?.current?.[id]),
    }));

    useEffect(() => {
      let positions = {};
      const f = debounce((value) => onSectionChanged(value), 700);
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            positions[entry.target.id] = entry.intersectionRatio;
          });
          const actualValue = Object.keys(positions).reduce(function (a, b) {
            return positions[a] >= positions[b] ? a : b;
          });
          f(actualValue);
        },
        {
          root: null,
          threshold: [0.1, 0.5, 0.8, 1],
        }
      );
      values(refs?.current).forEach?.((section) => {
        if (section instanceof Element) {
          observer.observe(section);
        }
      });
      return () => observer.disconnect();
    }, [onSectionChanged, name]);

    const inSingleCollectionView = subcollections?.length === 1;

    return (
      <ContentContainer className="flex items-center justify-center">
        <main className="p-8">
          <div className="flex flex-col border-b lg:flex-row">
            <div className="grow">
              <h3 className="my-5 text-smd-h2.5 font-bold md:my-0 md:py-4">
                {name}
              </h3>
            </div>
            <div className="hidden grow-0 md:block">
              <Button.Tertiary onClick={onShare}>
                <Share className="h-5 w-5 shrink-0" />
                <span>{t('common.share')}</span>
              </Button.Tertiary>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col pb-12 lg:pb-0">
            {subcollections.map((record, index) => (
              <>
                <div
                  ref={(node) => {
                    handleRef(record.id, node);
                  }}
                  key={record?.id}
                  id={record.id}
                  className="scroll-mt-14 md:scroll-mt-28"
                >
                  <Subcollection
                    key={record?.id}
                    {...record}
                    className={classnames({
                      'border-b': index !== subcollections.length - 1,
                    })}
                    titleClassName={index === 0 ? 'pb-6' : undefined}
                    inSingleCollectionView={inSingleCollectionView}
                    setCollectionJournal={setCollectionJournal}
                  />
                </div>
              </>
            ))}
          </div>
        </main>
        {authenticated && (
          <AddToCollectionModal
            journal={collectionJournal}
            onClose={() => setCollectionJournal()}
          />
        )}
      </ContentContainer>
    );
  }
);

export default CollectionContent;
