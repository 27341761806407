import { forwardRef, useMemo } from 'react';

import { normalizeAssetUrl } from 'journals/utils'; // TODO
import { cn } from 'lib/utils';
import { getSourceProps } from './utils';

import Picture from './Picture';
import Image from './Image';

const PictureResource = forwardRef(function PictureResource(
  { config, sizes, pictureClassName, ...props },
  ref
) {
  const { url, alt, images } = config ?? {};
  const { width, height } = images?.[0] ?? {};

  const sources = useMemo(() => getSourceProps(images), [images]);

  return (
    <Picture
      sources={sources}
      sizes={sizes}
      className={cn('h-full w-full', pictureClassName)}
    >
      <Image
        ref={ref}
        src={normalizeAssetUrl(url)}
        alt={alt}
        sizes={sizes}
        width={width}
        height={height}
        {...props}
      />
    </Picture>
  );
});

export default PictureResource;
