import { useEffect, useState } from 'react';
import VideoAsset from 'journals/blocks/output/HtmlBlock/LibraryAsset/VideoAsset';

export default function InteractiveLightBoxVideo({ config, isActive }) {
  const [playPause, setPlayPause] = useState({ playing: false });

  useEffect(() => {
    if (!isActive) {
      setPlayPause({ playing: false });
    }
  }, [isActive]);

  return (
    <div className="max-h-full w-full text-white md:w-8/12">
      <VideoAsset playPause={playPause} config={config} />
    </div>
  );
}
