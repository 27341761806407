import { useMutation, useQueryClient, useQuery } from 'react-query';
import { UserApi } from 'api';
import { safeDateFormat } from 'utils';
import { DATE_PRESETS } from 'my-phr/const';

const keys = {
  root: () => ['userProfile'],
};

export function useUserProfileUpdate({ onSuccess }) {
  const { refetch } = useUserProfile();
  const { mutate: updateProfile, isLoading } = useMutation(
    async (profileData) => {
      const sanitizedData = Object.entries(profileData).reduce((prev, curr) => {
        const trimmedString =
          typeof curr[1] === 'string' ? curr[1].trim() : curr[1];
        return { ...prev, [curr[0]]: trimmedString };
      }, {});

      sanitizedData.birthdate = safeDateFormat({
        date: sanitizedData?.birthdate,
        pattern: DATE_PRESETS.TIMESTAMP,
      });

      return UserApi.updateProfile(sanitizedData);
    },
    {
      onSuccess: (res) => {
        refetch().then(() => onSuccess?.(res));
      },
      onError: (err) => console.log(err),
    }
  );
  return { updateProfile, isLoading };
}

export function useUserProfile(options) {
  return useQuery(['userProfile'], async () => UserApi.getProfile(), {
    onError: (err) => console.log(err?.message),
    ...options,
  });
}

export function useInvalidateUserProfile() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(keys.root);
  };
}
