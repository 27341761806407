import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

function Credits({ name, source, contentClassName = 'w-full lg:w-[30rem]' }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:items-end">
      <div className={classnames('break-word', contentClassName)}>
        <p className="text-center text-sm lg:text-right ">{name}</p>
        {source && (
          <p className="text-center text-sm italic lg:text-right">
            {t('journals.hero.label-image') + ' '}
            <span className="w-full font-semibold">{source}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default Credits;
