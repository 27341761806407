import axios, { Headers } from './axios';

export default {
  getProfile() {
    return axios.get('/users').then(({ data }) => data);
  },

  updateProfile(withData) {
    const { avatar, ...rest } = withData;

    const formData = new FormData();
    if (avatar instanceof Blob) formData.append('avatar', avatar);
    const body = new Blob([JSON.stringify(rest)], {
      type: 'application/json',
    });
    formData.append('body', body);

    return axios
      .patch('/users', formData, { headers: Headers.FORMDATA })
      .then(({ data }) => data);
  },

  completeProfile(data) {
    return axios.patch('/users/complete', data).then(({ data }) => data);
  },

  updateDoctorInfo(data) {
    return axios
      .put('/users/roles/health-professional', data)
      .then(({ data }) => data);
  },

  getTermsAndConditions() {
    return axios.get('/users/terms-and-conditions').then(({ data }) => data);
  },

  getPrivacyPolicy() {
    return axios.get('/users/privacy-policy').then(({ data }) => data);
  },

  updateOTPState(enabled) {
    return axios.put(`/users/two-factor/authenticator?enabled=${enabled}`);
  },

  getUserConfigurationRecord(key) {
    return axios.get(`/user-configuration/${key}`).then(({ data }) => data);
  },

  updateUserConfigurationRecord(payload) {
    return axios.post(`/user-configuration`, payload);
  },
};
