import { getAssetUrl } from 'utils';

import { hasSensitiveTags } from 'components/BlurredLayer';
import withCdnResources from '../../../withCdnResources';
import { LibraryItemCover } from '../../common';

function LibraryTopicCover({ topic }) {
  let alsoCalled;
  if (topic?.synonyms?.length > 0) {
    alsoCalled = topic.synonyms.join(', ');
  }

  const cardProps = {
    label: topic?.label,
    title: topic?.name,
    subtitle: alsoCalled,
    content: topic?.description,
    imageSrc: getAssetUrl(topic?.featuredAsset?.url),
    imageFocalPoint: topic?.featuredAsset?.focalPoint,
    isSensitive: hasSensitiveTags(topic?.featuredAsset),
    // imageName: topic?.featuredAsset?.name,
    // credit: topic?.featuredAsset?.credit,
  };

  return <LibraryItemCover {...cardProps} />;
}

export default withCdnResources(
  ['topic.featuredAsset.thumbnail'],
  LibraryTopicCover
);
