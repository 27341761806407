import classnames from 'classnames';

function SuggestionsContainer({ containerProps, className, children }) {
  if (!children) {
    return <div {...containerProps} className="invisible" />;
  }

  return (
    <div
      {...containerProps}
      className={classnames(
        'overflow-y-auto rounded border bg-white',
        className
      )}
    >
      {children}
    </div>
  );
}

export default SuggestionsContainer;
