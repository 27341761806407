import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isNil } from 'ramda';
import { nanoid } from 'nanoid';

import { useAuth } from 'Auth';
import { useMedia } from 'hooks';

import { ContentContainer, CTA, Credits } from 'components';
import { Button } from 'components/forms';
import { PictureResource, GradientBackground } from 'components/media';
import { ChevronLeft, ChevronRight, Share } from 'components/icons';
import { ShareModal } from 'components/Share';
import { Carousel, VirtualCarousel } from 'components/Carousel';

import { AddToCollectionModal, SmartJournalCard } from 'journals/components';
import { AddToCollection } from 'journals/components/actions';
import { getJournalSlugPath } from 'journals/utils';
import { useStoryLastLocation } from 'library/hooks';

import { extractWebpThumbnail, getFocalPosition } from 'utils';
import { parseJournals } from '../../utils';

// TODO: Rework this and find a proper solution
function getTitleFontSizeClasses(story) {
  const fontResizeThreshold = 96;
  const fontResizeMobileThreshold = 30;
  const titleLength = story?.title?.length;

  if (!Boolean(titleLength)) {
    return '';
  }

  return classnames(
    'mb-4 max-w-xl font-semibold leading-tight xl:max-w-[75%]',
    {
      'lg:text-smd-t1': titleLength < fontResizeThreshold,
      'lg:text-smd-h1': titleLength >= fontResizeThreshold,
      'text-smd-h2': titleLength < fontResizeMobileThreshold,
      'text-smd-h3': titleLength >= fontResizeMobileThreshold,
    }
  );
}

function StoryOfTheDay({ data }) {
  const { t } = useTranslation();

  const randomId = nanoid();
  const { setStoryLastLocation } = useStoryLastLocation();

  const [shareModal, setShareModal] = useState();

  if (isNil(data)) {
    return null;
  }

  const { story, journals } = data;

  const hasJournals = Boolean(journals?.journals?.length);
  const hasCtaText = Boolean(journals?.ctaText);
  const shareUrl = `${window.location.origin}${story.shareUrl}`;
  const storyAsset = story?.asset ?? {};

  const webpThumbnail = extractWebpThumbnail(storyAsset?.images);

  const titleClassNames = getTitleFontSizeClasses(story);
  const { position, isValid } = getFocalPosition(storyAsset.focalPoint);

  return (
    <section aria-labelledby={randomId}>
      <div className="relative">
        <PictureResource
          config={{
            url: webpThumbnail?.path || storyAsset.thumbnail || storyAsset.url,
            alt: storyAsset.alt,
            images: storyAsset.images,
          }}
          style={{
            objectPosition: isValid ? position : 'center',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          loading="eager"
          pictureClassName="absolute inset-0"
        />
        <GradientBackground className="absolute inset-0" />
        <div className="px-4 antialiased lg:px-12">
          <ContentContainer
            className={classnames('relative pt-20 text-white lg:pt-32', {
              'pb-40 lg:pb-80': hasJournals && hasCtaText,
              'pb-20 lg:pb-72': hasJournals && !hasCtaText,
              'pb-36 lg:pb-48': !hasJournals && hasCtaText,
              'pb-16 lg:pb-28': !hasJournals && !hasCtaText,
            })}
          >
            <h1 id={randomId} className={titleClassNames}>
              {story.title}
            </h1>
            <p
              className="mb-6 max-w-xl text-smd-sm leading-relaxed lg:text-smd-base xl:max-w-2xl"
              dangerouslySetInnerHTML={{ __html: story.description }}
            />
            <div className="flex items-center space-x-4">
              <Link
                to={story.linkUrl}
                className="smd-focus-visible-secondary rounded focus-visible:ring-offset-black"
                onClick={() => setStoryLastLocation()}
                data-testid="readTheStory"
              >
                <Button.Primary className="font-bold" tabIndex={-1}>
                  {story.linkText || t('landing.read-the-story')}
                </Button.Primary>
              </Link>
              <Button.Negative
                className="font-bold"
                focusClassName="smd-focus-visible-secondary focus-visible:ring-offset-black"
                data-testid="shareStoryButton"
                onClick={() => setShareModal(story)}
                aria-label={t('labels.common.share-story', {
                  story: story?.title,
                })}
              >
                <Share className="h-5 w-5" />
                <span>{story.shareName || t('common.share')}</span>
              </Button.Negative>
            </div>
            <div className="mt-8 flex flex-col space-y-1 lg:absolute lg:right-0 lg:bottom-8 lg:z-10 lg:mt-0 lg:items-end">
              {Array.isArray(story?.sponsors) &&
                Boolean(story?.sponsors.length) && (
                  <div className="flex flex-col space-x-2 lg:flex-row lg:items-center xl:space-x-4 xl:space-y-0">
                    <span className="shrink-0">
                      {t('landing.sponsored-by')}
                    </span>
                    <div className="flex grow  space-x-4 overflow-x-auto overscroll-contain lg:max-w-[17.5rem] xl:max-w-sm">
                      {story.sponsors.map((sponsor, index) => (
                        <img
                          key={index}
                          src={sponsor?.logo}
                          alt={sponsor?.name}
                          className="h-20 w-20 object-contain lg:h-24 lg:w-24"
                        />
                      ))}
                    </div>
                  </div>
                )}
              {story?.credit?.author && (
                <Credits
                  name={story.credit.name}
                  source={story.credit.author}
                  contentClassName="w-full lg:max-w-[17.5rem] xl:max-w-sm mt-1"
                />
              )}
            </div>
          </ContentContainer>
        </div>
      </div>

      {(hasJournals || hasCtaText) && (
        <StoryOfTheDayRelatedJournals data={journals} />
      )}
      <ShareModal
        isOpen={Boolean(shareModal)}
        onClose={() => setShareModal()}
        shareUrl={shareUrl}
      >
        {/* Add content */}
      </ShareModal>
    </section>
  );
}

const carouselNavStyles = ['grid place-items-center', 'top-1/3 lg:top-0'];
const carouselNavBtnStyles = [
  'w-6 h-6',
  'text-white lg:text-smd-main lg:stroke-2',
  'lg:-mb-24',
];

function StoryOfTheDayRelatedJournals({ data }) {
  const { authenticated } = useAuth();

  const [actionJournal, setActionJournal] = useState();
  const isLarge = useMedia(useMedia.LARGE);

  if (isNil(data)) {
    return null;
  }
  const journals = parseJournals(data?.journals);

  const breakpoints = {
    [useMedia.xxl]: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    [useMedia.lg]: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    [useMedia.md]: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    [useMedia.sm]: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  const hasCtaProps = Boolean(data.ctaProps);
  const hasCtaText = Boolean(data.ctaText);
  const hasJournals = Boolean(data.journals.length);

  return (
    <section aria-label={data.ctaText}>
      <div className="px-4 lg:px-12">
        <ContentContainer>
          <div
            className={classnames(
              !hasJournals && hasCtaText ? 'h-0' : 'h-[15.5rem] lg:h-[12.25rem]'
            )}
          >
            <div
              className={classnames({
                '-translate-y-[8rem] lg:-translate-y-[18rem]':
                  hasJournals && hasCtaText,
                '-translate-y-[2rem] lg:-translate-y-[12rem]':
                  hasJournals && !hasCtaText,
                '-translate-y-[7rem] lg:-translate-y-[13rem]':
                  !hasJournals && hasCtaText,
              })}
            >
              {hasCtaText && (
                <CTA
                  color="none"
                  size="none"
                  className={classnames(
                    'mt-0 mb-2 inline-flex h-[5rem] select-none !items-end space-x-2 text-smd-h2.5 font-semibold text-white lg:mt-10 lg:mb-4 lg:h-auto lg:max-w-2xl',
                    hasCtaProps &&
                      'smd-group-focus-visible-secondary hover:underline focus-visible:underline group-focus-visible:ring-offset-black',
                    hasCtaProps ? 'cursor-pointer' : 'cursor-default'
                  )}
                  linkClassName="group outline-none"
                  ctaProps={data.ctaProps}
                >
                  <span
                    className={classnames('text-left', {
                      'line-clamp-2': !isLarge,
                      truncate: isLarge,
                    })}
                    title={data.ctaText}
                  >
                    {data.ctaText}
                    {hasCtaProps && (
                      <ChevronRight className="inline h-6 w-6 flex-none stroke-2 lg:hidden" />
                    )}
                  </span>
                  <ChevronRight className="hidden h-9 w-6 flex-none stroke-2 lg:block" />
                </CTA>
              )}
              {hasJournals && (
                <VirtualCarousel
                  className="relative -mx-4 lg:-mx-8 lg:max-w-[72%] lg:px-6 xl:max-w-[54.5%] 2xl:max-w-[64%]"
                  carouselProps={{
                    breakpoints,
                  }}
                  slides={journals.map((journal) => (
                    <Carousel.Slide key={journal.id}>
                      <div className="smd-transform-duration border-2 border-white">
                        <SmartJournalCard
                          actions={[
                            {
                              onClick: () => setActionJournal(journal),
                              element: <AddToCollection />,
                            },
                          ]}
                          journal={journal}
                          to={getJournalSlugPath(journal)}
                        />
                      </div>
                    </Carousel.Slide>
                  ))}
                >
                  <Carousel.Prev
                    className={classnames(
                      'right-auto lg:-left-1',
                      carouselNavStyles
                    )}
                  >
                    <ChevronLeft className={classnames(carouselNavBtnStyles)} />
                  </Carousel.Prev>
                  <Carousel.Next
                    className={classnames(
                      'left-auto lg:-right-1',
                      carouselNavStyles
                    )}
                  >
                    <ChevronRight
                      className={classnames(carouselNavBtnStyles)}
                    />
                  </Carousel.Next>
                </VirtualCarousel>
              )}
            </div>
          </div>
        </ContentContainer>
      </div>
      {authenticated && (
        <AddToCollectionModal
          journal={actionJournal}
          onClose={() => setActionJournal()}
        />
      )}
    </section>
  );
}

export default StoryOfTheDay;
