import { useMutation, useQuery, useQueryClient } from 'react-query';

import { OCRApi } from 'api';
import { phrKeys } from 'my-phr/hooks';
import { OCR_STATUS } from 'my-phr/components/OCR/const';

const REFETCH_INTERVAL = 1000;

const OCR_KEYS = {
  all: () => ['all-ocr'],
  ocrById: (id) => [...OCR_KEYS.all(), 'ocr', id],
  preview: (id) => [...OCR_KEYS.ocrById(id), 'preview', id],
};

export function useCreateDraft(onSuccess) {
  const {
    mutate: createDraft,
    isLoading,
    ...rest
  } = useMutation(
    async (data) => OCRApi.createDraft({ ...data, status: OCR_STATUS.DRAFT }),
    {
      onSuccess,
    }
  );

  return { createDraft, isLoading, ...rest };
}

export function useUploadFile(onSuccess) {
  const {
    mutate: uploadFile,
    isLoading: isLoadingUpload,
    ...rest
  } = useMutation(OCRApi.updateOCR, {
    onSuccess: () => onSuccess(),
  });
  return {
    uploadFile,
    isLoadingUpload,
    ...rest,
  };
}

export function useGetOcr(id, isAnalyzing) {
  const { data, isLoading } = useQuery(
    OCR_KEYS.ocrById(id),
    async () => OCRApi.getOCR(id),
    {
      refetchInterval: isAnalyzing ? REFETCH_INTERVAL : 0,
      enabled: Boolean(id),
      cacheTime: 0,
    }
  );

  return { data, isLoading };
}

export function useGetOcrPreview(id, config) {
  const {
    data: resultData,
    isFetching: isFetchingPreview,
    isFetched,
  } = useQuery(OCR_KEYS.preview(id), async () => OCRApi.getOCRPreview(id), {
    enabled: Boolean(id),
    ...config,
  });

  return { resultData, isFetchingPreview, isFetched };
}

export function usePostOCR(onSuccess) {
  const queryClient = useQueryClient();
  const {
    mutate: postOCR,
    isLoading: isLoadingPost,
    ...rest
  } = useMutation(async (data) => OCRApi.postOcrData(data), {
    onSuccess: () => {
      queryClient.resetQueries(phrKeys.all());
      onSuccess();
    },
  });

  return { postOCR, isLoadingPost, ...rest };
}
