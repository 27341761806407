import classnames from 'classnames';
import { take } from 'ramda';
import { useTranslation } from 'react-i18next';
import { isAbsolute } from 'journals/utils';

const NUMBER_OF_LOGOS_TO_RENDER = 4;

function SponsorLogo({ url, relatedUrl, alt }) {
  const { t } = useTranslation();
  const logoImg = (
    <img
      src={url}
      alt={alt}
      className="h-[4rem] w-[11.125rem] object-contain"
    />
  );
  return relatedUrl && isAbsolute(relatedUrl) ? (
    <a
      href={relatedUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={t('labels.journals.sponsor-logo')}
    >
      {logoImg}
    </a>
  ) : (
    logoImg
  );
}

export default function HealthJournalSponsors({
  logos = [],
  wrapperClassName,
}) {
  const { t } = useTranslation();
  const sponsorLogos = logos.filter((logo) => Boolean(logo.sponsor));
  const authorLogo = logos.find(
    (logo) => Boolean(logo.author) && logo.author !== 'null'
  );

  if (!Boolean(sponsorLogos.length) && !Boolean(authorLogo)) return null;

  return (
    <div
      className={classnames(
        'flex max-w-full flex-wrap content-center items-center space-x-4 py-1',
        wrapperClassName
      )}
    >
      <div className="text-smd-sm italic">
        {t(
          Boolean(authorLogo)
            ? 'common.created-by'
            : Boolean(sponsorLogos)
            ? 'common.sponsored-by'
            : null
        )}
      </div>
      <div className="flex flex-wrap justify-around space-x-0 lg:space-x-2">
        {Boolean(authorLogo) ? (
          <SponsorLogo
            key={`logo-${authorLogo.author}`}
            {...authorLogo}
            alt=""
          />
        ) : (
          take(NUMBER_OF_LOGOS_TO_RENDER, sponsorLogos).map((logo, id) => (
            <SponsorLogo key={`logo-${id}`} {...logo} alt="" />
          ))
        )}
      </div>
    </div>
  );
}
