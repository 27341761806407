import classnames from 'classnames';
import { Exclamation } from '../icons';

export default function ErrorLabel({ className, error, icon, ...props }) {
  return error ? (
    <p
      className={classnames(
        'mt-1 flex items-center text-xs font-semibold text-smd-error-darker',
        className
      )}
      data-testid={`${error.ref?.dataset?.testid}Error`}
      {...props}
    >
      {icon}
      {error.message}
    </p>
  ) : null;
}

ErrorLabel.defaultProps = {
  icon: <Exclamation className="mr-1 h-3.5 w-3.5" />,
};
