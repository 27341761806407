import React from 'react';

import Blocks from '.';

const createComponent = (config) => {
  switch (config.type) {
    case 'container':
      return (
        <Blocks.Container key={config.id} {...config}>
          {config.children && config.children.map(createComponent)}
        </Blocks.Container>
      );
    case 'html':
      return <Blocks.Html key={config.id} config={config} />;
    case 'library':
      return <Blocks.Library config={config} key={config.id} />;
    default:
      return null;
  }
};

export default Object.freeze({
  fromJson: createComponent,
});
