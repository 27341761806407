import { useRef, useState } from 'react';
import { Folder, Plus } from 'components/icons';
import { Button } from 'components/forms';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function FilePicker({
  onFilesSelected,
  onLibraryPickButtonClick,
  accept = 'image/*',
  hasFilePicker = true,
  hasLibraryPicker = true,
  multiple,
  customLabel,
}) {
  const inputFile = useRef();
  const { t } = useTranslation();

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setInDragState(true);
  };

  const [inDragState, setInDragState] = useState();

  const handleDragOver = (event) => {
    event.preventDefault();
    setInDragState(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    let files = [...event.dataTransfer.files];
    onFilesSelected(files);
    setInDragState(false);
  };

  const handleDragEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setInDragState(false);
  };

  const dragAndDropProps = hasFilePicker
    ? {
        onDrop: handleDrop,
        onDragOver: handleDragOver,
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragEnd,
      }
    : {};

  return (
    <div
      className={classnames(
        'border border-dashed border-smd-accent bg-smd-accent-light  p-2',
        { 'bg-opacity-30': inDragState, 'bg-opacity-60': !inDragState }
      )}
      {...dragAndDropProps}
    >
      <div className="hidden text-center text-smd-sm text-smd-gray-dark lg:block">
        {customLabel ? customLabel : t('communities.add-files')}
      </div>

      <div className="mt-4 flex justify-center space-x-10 text-center">
        {hasFilePicker && (
          <div className="h-full">
            <Button.Primary
              onClick={() => inputFile.current.click()}
              flavor="round"
              type="button"
              aria-label={t('labels.communities.my-device')}
            >
              <Folder className="mx-auto h-full w-full p-2" />
            </Button.Primary>
            <input
              type="file"
              accept={accept}
              multiple={multiple}
              ref={inputFile}
              onChange={(event) => {
                onFilesSelected(event?.target?.files);
                event.target.value = null;
              }}
              className="hidden"
            />
            <div className="text-smd-gray-dark">{t('common.my-device')}</div>
          </div>
        )}

        {hasLibraryPicker && (
          <div className="h-full">
            <Button.Primary
              flavor="round"
              type="button"
              onClick={() => onLibraryPickButtonClick()}
              aria-label={t('labels.communities.storymd-library')}
            >
              <Plus className="mx-auto h-full w-full" />
            </Button.Primary>
            <div className="text-smd-gray-dark">
              {t('common.storymd-library')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FilePicker.propTypes = {
  onFilesSelected: PropTypes.func.isRequired,
  onLibraryPickButtonClick: PropTypes.func.isRequired,
};
