import { Trans } from 'react-i18next';
import { cn } from 'lib/utils';

export default function Steps({ total, activeStep, className }) {
  return (
    <div className={cn('relative text-smd-base', className)}>
      <Trans
        i18nKey={'my-phr.setup.step-progress'}
        components={{ b: <b /> }}
        values={{ current: activeStep, total }}
      />
    </div>
  );
}
