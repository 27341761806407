import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Heart } from './icons';

export default function HeartComponent({
  strokeWidth,
  isClickable,
  isFilled,
  onClick,
  className,
  label,
}) {
  const stateClasses = {
    'text-smd-red': isFilled,
    'text-white': !isFilled,
    'cursor-pointer': isClickable,
    'cursor-default': !isClickable,
  };

  return (
    <button
      onClick={isClickable ? onClick : null}
      className={classnames(
        'mr-1 appearance-none focus:outline-none',
        isClickable && 'smd-focus-visible-primary rounded-sm'
      )}
      tabIndex={isClickable ? 0 : -1}
      aria-label={label}
    >
      <Heart
        className={classnames(
          'inline stroke-smd-red p-1 md:transform md:duration-200',
          stateClasses,
          className
        )}
        strokeWidth={strokeWidth}
        strokeAlignment="inner"
      />
    </button>
  );
}

HeartComponent.propTypes = {
  strokeWidth: PropTypes.number,
  isClickable: PropTypes.bool,
  isFilled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

HeartComponent.defaultProps = {
  strokeWidth: 3,
  isFilled: false,
  onClick: null,
  isClickable: false,
  className: 'w-7 h-7',
};
