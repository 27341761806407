import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Roles, useAuth } from 'Auth';
import { ShareModal, ShareUrl } from 'components/Share';
import { Button } from 'components/forms';
import { useCopyToClipboard } from 'hooks';
import {
  useGenerateJournalUniqueId,
  useGetJournalUniqueId,
} from 'journals/hooks';
import { getJournalSlugPath } from '../utils';
import { JournalCard } from '.';

function createPOCSharedJournalLink({ linkId }) {
  if (linkId) {
    return `${window.location.origin}/share/journal/${linkId}`;
  }
  return undefined;
}

function JournalShareModal({
  isOpen,
  journal,
  container,
  onClose,
  socialMediaSharing,
  linkSharing,
  isSharedJournal,
}) {
  const { t } = useTranslation();

  const { copy, isSupported } = useCopyToClipboard();

  const { journalLinkId } = useParams();
  const { hasRole } = useAuth();
  const isHealthProfessional = hasRole(Roles.HP);

  const [uniqueLinkId, setUniqueLinkId] = useState();
  const isPOCJournal = Boolean(journal.preamble?.parent);

  const { mutateAsync: generate, isLoading: isLoadingGenerate } =
    useGenerateJournalUniqueId();

  const { data: generatedUrlKey, isLoading } = useGetJournalUniqueId(
    journal.journalKey,
    {
      enabled: Boolean(
        isPOCJournal && isHealthProfessional && journal.journalKey
      ),
      retry: 0,
    }
  );

  useEffect(() => {
    if (generatedUrlKey) {
      setUniqueLinkId(generatedUrlKey);
    }
  }, [generatedUrlKey]);

  const getShareUrl = () => {
    if (!isSharedJournal && !isPOCJournal) {
      let url;
      if (journal) {
        const path = getJournalSlugPath(journal, container);
        url = `${window.location.origin}${path}`;
      }
      return url;
    }
    return createPOCSharedJournalLink({
      linkId: journalLinkId || uniqueLinkId?.id,
    });
  };

  const handleLinkGenerate = () =>
    generate(journal.journalKey).then((res) => {
      setUniqueLinkId(res);
      if (isSupported) {
        copy(
          createPOCSharedJournalLink({
            linkId: res?.id,
          })
        );
      }
    });

  return (
    <ShareModal
      isOpen={isOpen}
      onClose={onClose}
      title={isPOCJournal && t('journals.share.share-with-team')}
      shareUrl={getShareUrl()}
      socialMediaSharing={socialMediaSharing}
      linkSharing={linkSharing}
    >
      <p className="mt-6 max-w-xs text-center text-smd-sm text-smd-secondary">
        {isPOCJournal
          ? t('journals.share.send-team', {
              type: t('common.poc_healthjournal_one'),
            })
          : t('journals.share.send', { type: t('common.healthjournal_one') })}
      </p>
      <JournalCard
        preamble={journal?.preamble}
        className="mt-8 w-full max-w-none md:max-w-sm"
      />

      {isSharedJournal && <ShareUrl url={getShareUrl()} />}

      {!isSharedJournal &&
        isPOCJournal &&
        (!getShareUrl() ? (
          <div className="mt-6">
            <Button.Primary
              onClick={handleLinkGenerate}
              loading={isLoadingGenerate || isLoading}
            >
              {t('journals.share.generate-link')}
            </Button.Primary>
          </div>
        ) : (
          <ShareUrl url={getShareUrl()} />
        ))}
    </ShareModal>
  );
}

export default JournalShareModal;
