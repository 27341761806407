import { Brain, Share } from 'components/icons';

const ContainerBlock = ({ children, label, title, onShare }) => (
  <>
    <div className="mb-5 flex justify-between bg-smd-accent py-2 px-4 text-white">
      <div className="flex items-center space-x-2">
        <Brain className="w-5" />
        <h2 className="text-sm font-semibold sm:text-base">
          {title || label || ''}
        </h2>
      </div>
      <div className="flex items-center space-x-2">
        {onShare && (
          <button
            className="smd-focus-visible-secondary w-5 rounded-sm"
            onClick={onShare}
            aria-label={`Share ${title || label}`}
          >
            <Share />
          </button>
        )}
      </div>
    </div>
    {children}
  </>
);
export default ContainerBlock;
