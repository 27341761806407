import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { getAssetUrl } from 'utils';
import CaptionButton, { BACKGROUND, EXPAND_DIRECTION } from './CaptionButton';

const INITIALLY_SHOWN_LINES = 5;

function RelatedTopicCard({ title, description, synonyms, image }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const descriptionRef = useRef(null);
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(descriptionRef.current).lineHeight
      );
      const maxHeight = lineHeight * INITIALLY_SHOWN_LINES;
      setIsDescriptionOverflowing(
        descriptionRef.current.clientHeight >= maxHeight
      );
    }
  }, [description]);

  if (!description) {
    return null;
  }

  return (
    <article className="relative my-2 flex w-full flex-col bg-white text-smd-main antialiased sm:flex-row ">
      {image && (
        <img
          src={getAssetUrl(image)}
          alt={title}
          className="object-cover md:h-[6rem] md:min-w-[10rem] md:max-w-[10rem]"
        />
      )}
      <div className="flex flex-col items-start justify-center px-0 py-2 md:px-2 md:py-0">
        <div
          ref={descriptionRef}
          className={classNames(
            'text-smd-sm text-smd-secondary',
            isDescriptionOverflowing && !showFullDescription && 'line-clamp-3'
          )}
          dangerouslySetInnerHTML={{
            __html: description ?? synonyms,
          }}
        />
        {isDescriptionOverflowing && (
          <CaptionButton
            direction={EXPAND_DIRECTION.DOWN}
            background={BACKGROUND.LIGHT}
            inline
            expanded={showFullDescription}
            onClick={(e) => {
              e.preventDefault();
              setShowFullDescription((value) => !value);
            }}
          />
        )}
      </div>
    </article>
  );
}

export default RelatedTopicCard;
