import { AUTOCOMPLETE_CATEGORIES } from 'my-phr/layout/modules/ManualInputs/const';

export function isObservationCategory(entry) {
  return OBSERVATION_CATEGORIES.includes(entry?.category?.[0]);
}

export function isMeasurement(entry) {
  return entry?.category?.includes(AUTOCOMPLETE_CATEGORIES.MEASUREMENT);
}

export const OBSERVATION_CATEGORIES = [
  AUTOCOMPLETE_CATEGORIES.LAB_TEST,
  AUTOCOMPLETE_CATEGORIES.LAB_PANEL,
  AUTOCOMPLETE_CATEGORIES.ACTIVITY,
];
