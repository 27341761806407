import { useTranslation } from 'react-i18next';
import { safeDateFormat } from 'utils';
import { toast } from 'utils/toast';
import { Button } from 'components/forms';
import { Skeleton } from 'components';
import { useGetOcrPreview, usePostOCR } from 'hooks/ocr';
import { DATE_PRESETS } from 'my-phr/const';
import { useOCRStateContext, useOCRUpdaterContext } from 'OCRContext';

function ResultPreview({ onEdit, preview }) {
  const { t } = useTranslation();

  const { ocrId } = useOCRStateContext();
  const { closeOcrModal } = useOCRUpdaterContext();

  const { isFetchingPreview } = useGetOcrPreview(ocrId);

  const { postOCR, isLoadingPost } = usePostOCR(() => {
    closeOcrModal();
    toast.success(
      t('my-phr.ocr.preview.you-added', {
        date: formattedDate,
      })
    );
  });

  function createOCR() {
    postOCR({ id: ocrId, preview });
  }

  const formattedDate = safeDateFormat({
    date: preview?.date ?? new Date(),
    pattern: DATE_PRESETS.DATE_ONLY,
    fallback: '',
  });

  return (
    <div>
      <header className="border-b-2 pr-10 pl-4 pb-2 text-smd-h4 font-semibold">
        {t('my-phr.ocr.preview.title')}
      </header>
      {isFetchingPreview ? (
        <div className="space-y-2 p-4">
          <Skeleton.Text className="w-1/2" fontSize="6rem" />
          <Skeleton.Text lines={3} className="mr-4 w-full text-smd-h2" />
          <Skeleton.Text className="mr-4 w-full text-smd-h2" />
        </div>
      ) : (
        <div>
          <div className="flex justify-between p-4 text-smd-h4">
            <div>
              <div className="flex space-x-2 font-bold">
                <span>{preview?.name}</span>
              </div>
              <div className="font-semibold">
                {t('my-phr.ocr.preview.time-results')}
              </div>
              <div className="text-smd-base">{formattedDate}</div>
            </div>
            <Button.Anchor onClick={onEdit} className="font-semibold">
              {t('common.edit')}
            </Button.Anchor>
          </div>
          <ul className="items-baseline space-y-4 p-4">
            {preview?.observations?.map((item, index) => (
              <li className="items-end" key={`observation-${index}`}>
                <PreviewElement entry={item} />
              </li>
            ))}
          </ul>
          <div className="flex justify-end space-x-2 border-t-2 bg-white px-4 pt-4 text-smd-h4 font-semibold">
            <Button.Primary onClick={createOCR} loading={isLoadingPost}>
              {t('my-phr.ocr.preview.import')}
            </Button.Primary>
          </div>
        </div>
      )}
    </div>
  );
}

function PreviewElement({ entry }) {
  return (
    <div className="flex h-12 w-full items-center justify-between space-x-2 border border-smd p-2 font-semibold">
      <div>{entry?.name}</div>
      <div className="flex items-center space-x-1">
        <span>{entry?.value}</span>
        <span>{entry?.unit}</span>
      </div>
    </div>
  );
}

export default ResultPreview;
