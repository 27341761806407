import { matchPath, useLocation } from 'react-router-dom';
import { PHR_ROUTES } from '../../const';

const checkIfCurrentRoute = (pathname, path) =>
  Boolean(matchPath(pathname, { path, exact: true, strict: false }));

export default function usePhrRouteMatch() {
  const { pathname } = useLocation();

  const isDashboard = checkIfCurrentRoute(pathname, PHR_ROUTES.DASHBOARD);
  const isTimeline = checkIfCurrentRoute(pathname, PHR_ROUTES.TIMELINE);
  const isDetails =
    checkIfCurrentRoute(pathname, PHR_ROUTES.DETAILS) ||
    checkIfCurrentRoute(pathname, PHR_ROUTES.DETAILS_ID);
  const isSharing = checkIfCurrentRoute(pathname, PHR_ROUTES.SHARING);
  const isShared =
    checkIfCurrentRoute(pathname, PHR_ROUTES.SHARING_VIEW_LINK) ||
    checkIfCurrentRoute(pathname, PHR_ROUTES.SHARED_DASHBOARD) ||
    checkIfCurrentRoute(pathname, PHR_ROUTES.SHARED_DETAILS);

  return {
    isDashboard,
    isTimeline,
    isDetails,
    isSharing,
    isShared,
  };
}
