// TODO: Temporary file, remove after integration with library

import { Form } from 'components/forms';
import { MANUAL_INPUT_TYPE } from '../const';
import { useManualInputDefaultProps, useUnitSuggestions } from '../hooks';
import manualInputsRegistry from '../manualInputsRegistry';

export function UnitSuggestions(props) {
  const newProps = useManualInputDefaultProps(props);

  const { suggestions, fetch, clear } = useUnitSuggestions();

  return (
    <Form.Suggestions
      {...newProps}
      searchSuggestionsProps={{
        suggestions,
        onFetch: fetch,
        onClear: clear,
      }}
    />
  );
}

manualInputsRegistry.register(
  MANUAL_INPUT_TYPE.UNIT_SUGGESTIONS,
  UnitSuggestions
);
