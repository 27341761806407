import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useGetOcr, useUploadFile } from 'hooks/ocr';
import { FILE_STATUS } from 'my-phr/components/OCR/const';
import { OCRDialog, OCRModal } from 'my-phr/components/OCR';
import { getPartAfterLastSlash } from 'utils';

export const INITIAL_OCR_STATE = {
  ocrModal: false,
  ocrDialog: false,
};

const MAX_FILE_SIZE = 10 * 1024 * 1024; //10MB

const OCRStateContext = createContext();
const OCRUpdaterContext = createContext();

const initialState = {
  ocrId: null,
  attachmentsList: [],
  isCompletedUploading: false,
  isLoadingUpload: false,
  isAnalyzing: false,
  error: null,
  showAlert: false,
  shownAttachments: [],
  ocrTitle: '',
  state: INITIAL_OCR_STATE,
};

const ACTION_TYPES = {
  SET_OCR_MODAL: 'SET_OCR_MODAL',
  SET_OCR_DIALOG: 'SET_OCR_DIALOG',
  SET_OCR_ID: 'SET_OCR_ID',
  SET_FINAL_LIST_SHOWN: 'SET_FINAL_LIST_SHOWN',
  SET_COMPLETED_UPLOADING: 'SET_COMPLETED_UPLOADING',
  SET_LOADING_UPLOAD: 'SET_LOADING_UPLOAD',
  SET_IS_ANALYZING: 'SET_IS_ANALYZING',
  SET_ERROR: 'SET_ERROR',
  SET_SHOW_ALERT: 'SET_SHOW_ALERT',
  SET_SHOWN_ATTACHMENTS: 'SET_SHOWN_ATTACHMENTS',
  SET_OCR_TITLE: 'SET_OCR_TITLE',
};

const errors = {
  maxSize: 'max-size',
};

function ocrReducer(state, actions) {
  for (const action of actions) {
    switch (action.type) {
      case ACTION_TYPES.SET_OCR_MODAL:
        state = {
          ...state,
          state: { ...state.state, ocrModal: action.payload },
        };
        break;
      case ACTION_TYPES.SET_OCR_DIALOG:
        state = {
          ...state,
          state: { ...state.state, ocrDialog: action.payload },
        };
        break;
      case ACTION_TYPES.SET_OCR_ID:
        state = { ...state, ocrId: action.payload };
        break;
      case ACTION_TYPES.SET_FINAL_LIST_SHOWN:
        state = { ...state, attachmentsList: action.payload };
        break;
      case ACTION_TYPES.SET_COMPLETED_UPLOADING:
        state = { ...state, isCompletedUploading: action.payload };
        break;
      case ACTION_TYPES.SET_LOADING_UPLOAD:
        state = { ...state, isLoadingUpload: action.payload };
        break;
      case ACTION_TYPES.SET_IS_ANALYZING:
        state = { ...state, isAnalyzing: action.payload };
        break;
      case ACTION_TYPES.SET_ERROR:
        state = { ...state, error: action.payload };
        break;
      case ACTION_TYPES.SET_SHOW_ALERT:
        state = { ...state, showAlert: action.payload };
        break;
      case ACTION_TYPES.SET_SHOWN_ATTACHMENTS:
        state = {
          ...state,
          shownAttachments: [...action.payload],
          attachmentsList: [...action.payload],
        };
        break;
      case ACTION_TYPES.SET_OCR_TITLE:
        state = { ...state, ocrTitle: action.payload };
        break;
      default:
        break;
    }
  }
  return state;
}

const OCRProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ocrReducer, initialState);

  function validateFile(files) {
    const isFileValidSize = Array.from(files).every(
      (file) => file.size <= MAX_FILE_SIZE
    );
    if (!isFileValidSize) {
      dispatch([{ type: ACTION_TYPES.SET_ERROR, payload: errors.maxSize }]);
    }
    return isFileValidSize;
  }

  const methods = {
    openOcrModal: () =>
      dispatch([{ type: ACTION_TYPES.SET_OCR_MODAL, payload: true }]),
    minimizeOcrModal: () => {
      dispatch([
        { type: ACTION_TYPES.SET_OCR_DIALOG, payload: true },
        { type: ACTION_TYPES.SET_OCR_MODAL, payload: false },
      ]);
    },
    maximizeOcrDialog: () => {
      dispatch([
        { type: ACTION_TYPES.SET_OCR_DIALOG, payload: false },
        { type: ACTION_TYPES.SET_OCR_MODAL, payload: true },
      ]);
    },
    closeOcrModal: () => {
      dispatch([
        { type: ACTION_TYPES.SET_OCR_MODAL, payload: false },
        { type: ACTION_TYPES.SET_IS_ANALYZING, payload: false },
        { type: ACTION_TYPES.SET_OCR_TITLE, payload: '' },
      ]);
    },
    setOcrId: (ocrId) =>
      dispatch([{ type: ACTION_TYPES.SET_OCR_ID, payload: ocrId }]),
    setAttachmentsList: (finalList) =>
      dispatch([
        { type: ACTION_TYPES.SET_FINAL_LIST_SHOWN, payload: finalList },
      ]),
    setOcrTitle: (title) =>
      dispatch([{ type: ACTION_TYPES.SET_OCR_TITLE, payload: title }]),
    setIsAnalyzing: (isAnalyzing) =>
      dispatch([{ type: ACTION_TYPES.SET_IS_ANALYZING, payload: isAnalyzing }]),
    setError: (error) =>
      dispatch([{ type: ACTION_TYPES.SET_ERROR, payload: error }]),
    setShowAlert: (showAlert) =>
      dispatch([{ type: ACTION_TYPES.SET_SHOW_ALERT, payload: showAlert }]),
    setShownAttachments: (attachments) =>
      dispatch([
        {
          type: ACTION_TYPES.SET_SHOWN_ATTACHMENTS,
          payload: attachments,
        },
      ]),
    handleFileSelected: (value) => {
      if (validateFile(value)) {
        dispatch([
          { type: ACTION_TYPES.SET_ERROR, payload: null },
          { type: ACTION_TYPES.SET_LOADING_UPLOAD, payload: true },
          {
            type: ACTION_TYPES.SET_COMPLETED_UPLOADING,
            payload: false,
          },
        ]);
        const selectedFiles = Array.from(value).map((file) => ({
          attachment: {
            name: file.name,
          },
          status: FILE_STATUS.UPLOADING,
        }));
        dispatch([
          {
            type: ACTION_TYPES.SET_SHOWN_ATTACHMENTS,
            payload: [...state.shownAttachments, ...selectedFiles],
          },
        ]);
        uploadFile({ id: state.ocrId, files: Array.from(value) });
      }
    },
    onCancel: () => {
      dispatch([
        { type: ACTION_TYPES.SET_OCR_ID, payload: null },
        { type: ACTION_TYPES.SET_SHOWN_ATTACHMENTS, payload: [] },
        { type: ACTION_TYPES.SET_FINAL_LIST_SHOWN, payload: [] },
        { type: ACTION_TYPES.SET_SHOW_ALERT, payload: false },
        { type: ACTION_TYPES.SET_IS_ANALYZING, payload: false },
        { type: ACTION_TYPES.SET_OCR_TITLE, payload: '' },
      ]);
    },
  };

  const { uploadFile, isLoadingUpload } = useUploadFile(() => {
    dispatch([
      { type: ACTION_TYPES.SET_LOADING_UPLOAD, payload: false },
      { type: ACTION_TYPES.SET_IS_ANALYZING, payload: true },
    ]);
  });

  const { data } = useGetOcr(state.ocrId, state.isAnalyzing);

  useEffect(() => {
    if (isLoadingUpload) {
      dispatch([{ type: ACTION_TYPES.SET_SHOW_ALERT, payload: true }]);
    }
  }, [isLoadingUpload]);

  useEffect(() => {
    const finalArray = state?.shownAttachments.map((item) => {
      return (
        data?.attachments.find(
          (attachment) =>
            getPartAfterLastSlash(attachment.attachment.storageKey) ===
            item.attachment.name
        ) || item
      );
    });
    dispatch([
      {
        type: ACTION_TYPES.SET_FINAL_LIST_SHOWN,
        payload: finalArray,
      },
    ]);
  }, [data?.attachments, state.shownAttachments]);

  useEffect(() => {
    if (data?.attachments?.every((x) => x.status === FILE_STATUS.COMPLETED)) {
      dispatch([
        { type: ACTION_TYPES.SET_COMPLETED_UPLOADING, payload: true },
        { type: ACTION_TYPES.SET_SHOW_ALERT, payload: false },
        { type: ACTION_TYPES.SET_IS_ANALYZING, payload: false },
      ]);
    }
  }, [data?.attachments]);

  return (
    <OCRStateContext.Provider value={state}>
      <OCRUpdaterContext.Provider value={methods}>
        {state.state.ocrModal && <OCRModal onClose={methods.closeOcrModal} />}
        {state.state.ocrDialog && <OCRDialog />}
        {children}
      </OCRUpdaterContext.Provider>
    </OCRStateContext.Provider>
  );
};

export const useOCRStateContext = () => {
  const context = useContext(OCRStateContext);

  if (!context) {
    throw new Error(
      'useOCRStateContext must be used within an OCRStateContextProvider'
    );
  }
  return context;
};

export const useOCRUpdaterContext = () => {
  const contextUpdater = useContext(OCRUpdaterContext);

  if (!contextUpdater) {
    throw new Error(
      'useOCRUpdaterContext must be used within an OCRUpdaterContextProvider'
    );
  }
  return useCallback(contextUpdater, [contextUpdater]);
};

export { OCRProvider };
