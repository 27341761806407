import PropTypes from 'prop-types';

export default function GradientBackground({
  angle,
  colors,
  as: Wrapper,
  style,
  ...rest
}) {
  const colorStops = colors
    .map(({ color, position }) => `${color} ${position}%`)
    .join(', ');
  const background = {
    background: `linear-gradient(${angle}deg, ${colorStops})`,
  };
  return <Wrapper style={{ ...background, ...style }} {...rest} />;
}

GradientBackground.propTypes = {
  angle: PropTypes.number,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  as: PropTypes.string,
};

GradientBackground.defaultProps = {
  angle: 45,
  colors: [
    { position: 0, color: 'rgba(0, 0, 0, 0.75)' },
    { position: 100, color: 'rgba(0, 0, 0, 0.10)' },
  ],
  as: 'div',
};
