import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SearchSuggestions } from 'components';
import SpellingCorrection from './SpellingCorrection';

function SearchResultsHeader({ search }) {
  const { t } = useTranslation();

  return (
    <h1 className="mb-5 mt-10 w-full text-center text-xl font-semibold md:text-2xl">
      <span className="max-w-xl break-all lg:max-w-5xl">
        <span>{t('library.search.results-for')}</span>{' '}
        <strong>"{search}"</strong>
      </span>
    </h1>
  );
}

function AdvancedSearch({
  query,
  onSearch,
  className,
  hideSearch,
  hideSuggestions,
  children,
  placeholder,
  autoFocus,
}) {
  const { t } = useTranslation();

  return (
    <section className={className}>
      <div
        className={classnames(
          'flex-col-center mb-2 flex-wrap',
          'px-smd-base pt-smd-base lg:px-smd-xxl lg:pt-smd-lg'
        )}
      >
        <div className="w-full max-w-xl space-y-4">
          {!Boolean(hideSearch) && (
            <SearchSuggestions.Uncontrolled
              value={query.search}
              onSearch={(search) => onSearch?.({ search })}
              autoFocus={autoFocus}
              testId="searchPageSearchBarField"
              placeholder={
                placeholder || t('library.search.search-topics-drugs')
              }
              hideSuggestions={hideSuggestions}
              className="z-20"
              wrapperClassName="h-10"
              showLoader
            />
          )}
        </div>

        {Boolean(query.search) && (
          <>
            <SearchResultsHeader search={query.search} />
            {!hideSuggestions && (
              <SpellingCorrection query={query.search} onSearch={onSearch} />
            )}
          </>
        )}
      </div>
      {children}
    </section>
  );
}

AdvancedSearch.propTypes = {
  query: PropTypes.shape({
    search: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default AdvancedSearch;
