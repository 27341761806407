import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from 'lib/utils';
import {
  EGuideStepAlign,
  EGuideStepAlignDirection,
  EGuideStepPosition,
  getGuideStepAlignDirection,
  GuideStepFooter,
  GuideStepImage,
  GuideStepVideo,
  TGuideStep,
  TImageAsset,
  TVideoAsset,
} from 'components/Guide';
import { X } from 'components/icons';
import { SquareButton } from '../forms';

type GuideTooltipProps = {
  currentStep: number;
  goToNextStep: () => void;
  completeGuide: () => void;
  closeGuide: () => void;
  step: TGuideStep;
  imageAsset?: TImageAsset;
  videoAsset?: TVideoAsset;
  position: EGuideStepPosition;
  align: EGuideStepAlign;
  guideLength: number;
};

const arrowStyle = {
  right: 'border-r-white right-full',
  left: ' border-l-white left-full',
  top: 'border-t-white top-full',
  bottom: 'border-b-white bottom-full',
};

function GuideTooltipComponent(
  props: GuideTooltipProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { t } = useTranslation();
  const direction = getGuideStepAlignDirection(props.position);

  return (
    <div
      ref={ref}
      className={cn(
        'absolute z-[60] flex min-w-52 max-w-96 flex-col gap-4 rounded bg-white p-4 shadow-md',
        {
          'mb-2': props.position === EGuideStepPosition.TOP,
          'mt-2': props.position === EGuideStepPosition.BOTTOM,
          'ml-2': props.position === EGuideStepPosition.RIGHT,
          'mr-2': props.position === EGuideStepPosition.LEFT,
        }
      )}
      id="guide"
    >
      <div className="flex justify-between gap-5">
        <p className="text-smd-sm">{props.step.text}</p>
        <SquareButton
          onClick={props.closeGuide}
          aria-label={t('labels.common.close')}
        >
          <X strokeWidth="3" className="h-6 w-6" />
        </SquareButton>
      </div>

      {props.imageAsset && <GuideStepImage imageAsset={props.imageAsset} />}

      {props.videoAsset && <GuideStepVideo videoAsset={props.videoAsset} />}

      <GuideStepFooter
        currentStep={props.currentStep}
        guideLength={props.guideLength}
        isLastStep={props.step.isLastStep}
        completeGuide={props.completeGuide}
        goToNextStep={props.goToNextStep}
      />
      <div
        className={cn(
          'absolute size-0 border-10 border-transparent',
          arrowStyle[props.position],
          direction === EGuideStepAlignDirection.HORIZONTAL &&
            props.align === EGuideStepAlign.END &&
            `right-4`
        )}
      />
    </div>
  );
}

export const GuideTooltip = forwardRef<HTMLElement, GuideTooltipProps>(
  GuideTooltipComponent
);
